<template>

  <div id="journey-participants" v-if="hasContentLoaded && !isCreateNew()">

    <h2>Journey Participants</h2>

    <table id="participants-table">

      <thead>

        <tr>

          <th>

            <div class="table-header">

              <span>Name</span>

              <div class="icon">

                <font-awesome-icon
                    icon="arrow-up-short-wide"
                    title="Sort Descending"
                    @click="sortParticipants($event, 'family_name', 'desc')"
                />

                <font-awesome-icon
                    class="hidden"
                    icon="arrow-down-short-wide"
                    title="Sort Ascending"
                    @click="sortParticipants($event, 'family_name', 'asc')"
                />

              </div>

            </div>

          </th>

          <th>

            <div class="table-header">

              <span>Progress</span>

              <div class="icon">

                <font-awesome-icon
                    icon="arrow-up-short-wide"
                    title="Sort Descending"
                    @click="sortParticipants($event, 'JourneyCompletionPercentage', 'desc')"
                />

                <font-awesome-icon
                    class="hidden"
                    icon="arrow-down-short-wide"
                    title="Sort Ascending"
                    @click="sortParticipants($event, 'JourneyCompletionPercentage', 'asc')"
                />

              </div>

            </div>

          </th>

        </tr>

      </thead>

      <tbody>

        <tr v-for="(participant, indexParticipant) in computedLinkedParticipantsToJourney"
          :key="indexParticipant"
        >

          <td>
            <div class="participant-info">
              <img v-if="participant.hasOwnProperty('ProfilePictureURL')"
                   :src="participant.ProfilePictureURL"
                   alt="Participant Picture"
              >
              <span>{{ participant.family_name }} {{ participant.given_name }}</span>
            </div>
          </td>

          <td>

            <span class="participant-progress">{{ formattedNumber(participant.JourneyCompletionPercentage, 2) }} %</span>

            <div class="progress progress-sm">
              <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{ 'width': participant.JourneyCompletionPercentage + '%' }"
                  :aria-valuenow="participant.JourneyCompletionPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
              ></div>
            </div>

          </td>

        </tr>

      </tbody>

    </table>

  </div>

</template>

<script>

import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import $ from "jquery";
import {useRoute} from "vue-router";

export default {

  name: "JourneyParticipants.vue",

  props: {
    journeyDetails: {
      type: Object,
      required: true,
    }
  },

  setup(props) {

    const store = useStore();

    const route = useRoute();

    const hasContentLoaded = ref(false);

    const linkedParticipantsToJourney = ref([]);

    const currentSort = ref('');

    const currentSortDir = ref('desc');

    const isCreateNew = () => {
      return route.params.id === '' || route.params.id === null || route.params.id === undefined;
    };

    const getAllJourneyParticipants = async () => {

      let payloadLinkedParticipantsToJourney = {
        JourneyID: props.journeyDetails.ID,
        IsFacilitatorView: false,
      }

      const allParticipants = await store.dispatch('journey/getUsersLinkedToJourney', payloadLinkedParticipantsToJourney);

      linkedParticipantsToJourney.value = allParticipants.filter(participant => participant.JourneyRole === 'Participant');

      for (let linkedParticipant of linkedParticipantsToJourney.value) {
        if (linkedParticipant.hasOwnProperty('picture')) {
          linkedParticipant.ProfilePictureURL = await store.dispatch('file/getFile', {
            file_name: linkedParticipant.picture,
            folder_name: `profile`
          });
        }

      }

    };

    const makeRoundInteger = (number) => {
      if (number !== null  && number !== undefined && number !== '') {
        return Math.round(number);
      }
      return null;
    };

    const formattedNumber = (number, nrOfDecimals) => {

      if (number !== null && number !== undefined && number !== 0 && number !== '') {

        if (nrOfDecimals > 0) {

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: nrOfDecimals,
            useGrouping: false
          });

        } else {

          number = makeRoundInteger(number);

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            useGrouping: false
          });
        }

        number = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      }

      return number;

    };

    const sortParticipants = (ev, sortBy, direction) => {

      currentSort.value = sortBy;

      currentSortDir.value = direction;

      if (ev.target === ev.currentTarget) {

        $(ev.target).addClass('hidden');

        $(ev.target).siblings('.svg-inline--fa').removeClass('hidden');

      }

    };

    const computedLinkedParticipantsToJourney = computed(() => {

      linkedParticipantsToJourney.value = linkedParticipantsToJourney.value.sort((a, b) => {

        let modifier = 1;

        if (currentSortDir.value === 'desc') {
          modifier = -1;
        }

        if (currentSort.value !== null) {

          if (a[currentSort.value] < b[currentSort.value]) {
            return -1 * modifier;
          }

          if (a[currentSort.value] > b[currentSort.value]) {
            return 1 * modifier;
          }

        }

        return 0;

      });

      currentSort.value = null;

      currentSortDir.value = null;

      return linkedParticipantsToJourney.value;

    });

    onMounted( async () => {

      if (!isCreateNew()) {
        await getAllJourneyParticipants();
      }

      hasContentLoaded.value = true;

    });

    return {
      hasContentLoaded,
      linkedParticipantsToJourney,
      formattedNumber,
      computedLinkedParticipantsToJourney,
      sortParticipants,
      isCreateNew,
    }
  }
}

</script>

<style lang="scss" scoped>

#journey-participants {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  #participants-table {
    thead {
      tr {
        th {
          background-color: var(--primary-color) !important;

          .table-header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .hidden {
              display: none;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: #f1f1f1;

          .participant-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }
          }

          .participant-progress {
            font-size: 14px;
          }

          .progress {
            .progress-bar {
              background-color: var(--primary-color) !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #journey-participants {
    #participants-table {
      tbody {
        tr {
          td {
            .participant-info {
              img {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
}

</style>