<template>

  <div class="upload">

    <div class="upload__left">

      <div v-if="svg.htmlElement !== null && hasPreview"
           id="preview-svg"
           class="img-thumbnail rounded-circle"
           v-html="svg.htmlElement"
      >

      </div>

      <div v-if="svg.htmlElement === null && hasPreview"
           id="preview-svg"
           class="img-thumbnail rounded-circle">
      </div>

      <div>
        <!-- Heading -->
        <h4>
          Middle SVG
        </h4>

        <!-- Text -->
        <small class="text-muted">
          SVG no bigger than 9MB.
        </small>
      </div>

    </div>

    <div class="upload__right">

      <button
          class="btn btn-sm btn-primary upload__button"
          @click="$refs.file.click()"
      >
        <input
            ref="file"
            type="file"
            accept=".svg"
            @change="loadSVG($event)"
        />
        Upload
      </button>

    </div>


    <!--    <input-->
    <!--        ref="file"-->
    <!--        type="file"-->
    <!--        accept=".svg"-->
    <!--        @change="loadSVG($event)"-->
    <!--    />-->

    <!--    <div class="icon upload__image"-->
    <!--         @click="$refs.file.click()">-->
    <!--      <font-awesome-icon icon="image"/>-->
    <!--      <input-->
    <!--          ref="file"-->
    <!--          type="file"-->
    <!--          accept=".svg"-->
    <!--          @change="loadImage($event)"-->
    <!--      />-->
    <!--    </div>-->


    <!--    <div v-show="isCropperVisible" class="image-edit">-->
    <!--      <div class="image-edit__content">-->
    <!--        <div v-if="image.src" class="cropper-wrapper">-->
    <!--          <Cropper-->
    <!--              v-if="cropperType === 'Circle'"-->
    <!--              style="max-height: 300px;"-->
    <!--              ref="cropper"-->
    <!--              check-orientation-->
    <!--              :src="image.src"-->
    <!--              :stencil-component= "$options.components.CircleStencil"-->
    <!--          />-->
    <!--          <Cropper-->
    <!--              v-else-->
    <!--              ref="cropper"-->
    <!--              style="max-width: 500px; max-height: 300px;"-->
    <!--              check-orientation-->
    <!--              :src="image.src"-->
    <!--              :canvas="{-->
    <!--		      maxHeight: 256,-->
    <!--		      maxWidth: 256-->
    <!--	      }"-->
    <!--              :stencil-props="{-->
    <!--          aspectRatio: 4/3-->
    <!--        }"-->
    <!--          />-->
    <!--          <div-->
    <!--              class="btn btn-sm btn-primary reset-button"-->
    <!--              title="Reset Image"-->
    <!--              @click="reset()"-->
    <!--          >-->
    <!--            <img :src="require('@/assets/icons/reset.svg')" />-->
    <!--          </div>-->
    <!--          <div class="upload__file-type" v-if="image.type">-->
    <!--            {{ image.type }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="buttons-wrapper">-->
    <!--          <button-->
    <!--              v-if="image.src"-->
    <!--              class="btn btn-sm btn-primary"-->
    <!--              @click="$refs.file.click()"-->
    <!--          >-->
    <!--            Change-->
    <!--          </button>-->
    <!--          <button-->
    <!--              v-if="image.src"-->
    <!--              class="btn btn-sm btn-primary"-->
    <!--              @click="uploadPicture()"-->
    <!--          >-->
    <!--            Save and Close-->
    <!--          </button>-->
    <!--        </div>-->
    <!--        <div v-if="filesizeerror" class="invalid-feedback">-->
    <!--          PNG or JPG no bigger than 9MB.-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="backdrop"></div>-->
    <!--    </div>-->


  </div>

</template>

<script>
import {onMounted, onUnmounted, reactive, ref} from 'vue';
import {v4 as uuidv4} from 'uuid';
import {Cropper, CircleStencil} from 'vue-advanced-cropper';
// import Compressor from 'compressorjs';

// import 'vue-advanced-cropper/dist/style.css';
import {useStore} from "vuex";
//import 'vue-advanced-cropper/dist/theme.bubble.css'

// function dataURItoBlob(dataURI) {
//   // convert base64/URLEncoded data component to raw binary data held in a string
//   var byteString
//   if (dataURI.split(',')[0].indexOf('base64') >= 0)
//     byteString = atob(dataURI.split(',')[1])
//   else byteString = unescape(dataURI.split(',')[1])
//   // separate out the mime component
//   var mimeString = dataURI
//     .split(',')[0]
//     .split(':')[1]
//     .split(';')[0]
//   // write the bytes of the string to a typed array
//   var ia = new Uint8Array(byteString.length)
//   for (var i = 0; i < byteString.length; i++) {
//     ia[i] = byteString.charCodeAt(i)
//   }
//   return new Blob([ia], { type: mimeString })
// }
//
// function getMimeType(file, fallback = null) {
//   const byteArray = new Uint8Array(file).subarray(0, 4)
//   let header = ''
//   for (let i = 0; i < byteArray.length; i++) {
//     header += byteArray[i].toString(16)
//   }
//   console.log(header);
//
//   switch (header) {
//     case '89504e47':
//       return 'image/png'
//     case '3c737667':
//       return 'image/svg+xml'
//     // case '47494638':
//     //   return 'image/gif'
//     case 'ffd8ffe0':
//     case 'ffd8ffe1':
//     case 'ffd8ffe2':
//     case 'ffd8ffe3':
//     case 'ffd8ffe8':
//       return 'image/jpeg'
//     default:
//       return fallback
//   }
// }
//
// function formatBytes(a, b = 2) {
//   if (0 === a) return '0 Bytes'
//   const c = 0 > b ? 0 : b,
//     d = Math.floor(Math.log(a) / Math.log(1024))
//   return (
//     parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
//     ' ' +
//     ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
//   )
// }

export default {

  components: {
    Cropper,
    CircleStencil
  },

  emits: ['svgReady'],

  props: {
    folderName: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      default: null
    },
    hasPreview: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, {emit}) {

    const store = useStore();

    const svg = ref({
      file: null,
      htmlElement: props.src,
      type: null
    });

    const reset = () => {
      svg.value.file = null;
      svg.value.type = null;
    };

    const filesizeerror = ref(false);

    const loadSVG = event => {
      const {files} = event.target;

      if (files && files[0] && files[0].size <= '9500000') {

        filesizeerror.value = false;

        let file = files[0];

        const blob = URL.createObjectURL(file);

        const reader = new FileReader();
        reader.onload = e => {
          svg.value.file = new Blob([reader.result], {type: file.type})
          svg.value.htmlElement = reader.result
          svg.value.type = "image/svg+xml"
          ready();

        }
        reader.readAsText(file);

      } else {
        filesizeerror.value = true
      }
    };

    const ready = () => {
      store.dispatch('loading/setLoading', true)

      const filename = uuidv4();

      const payload = {
        name: filename,
        ext: 'svg',
        type: svg.value.type,
        file: svg.value.file,
        folder_name: props.folderName,
        htmlElement: svg.value.htmlElement,
      }

      emit('svgReady', payload);

      reset();

    }

    onMounted(() => {

    })

    onUnmounted(() => {

    })

    return {
      svg,
      filesizeerror,
      reset,
      loadSVG,
      //uploadPicture,
    }
  },

}

</script>

<style lang="scss" scoped>

.upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;

  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

  }

  &__right {

  }

  &__button {
    input {
      display: none;
    }
  }

}

</style>

<style lang="scss">

.upload {
  #preview-svg {

    svg {
      width: 100px;
      height: 100px;
    }
  }
}

</style>
