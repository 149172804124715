<template>
    <div v-if="showNotification" class="pwa-notification">
      <button class="close-btn" @click="dismissNotification">✖</button>
      <div v-if="os === 'Android'">
        <p v-if="canShowInstallButton">
          To install this app, tap the "Install App" button below or tap the menu icon (three dots) and select "Add to Home screen."
        </p>
        <p v-else>
          To install this app, tap the menu icon (three dots) and select "Add to Home screen."
        </p>
        <button v-if="canShowInstallButton" @click="installPwa">Install App</button>
      </div>
      <div v-else-if="os === 'iOS'">
        <p>To install this app on your iOS device:</p>
        <ul>
          <li>1. Tap the Share icon in Safari <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" /></li>
          <li>2. Select "Add to Home Screen" <font-awesome-icon :icon="['fas', 'square-plus']" /></li>
        </ul>
      </div>
      <div v-else>
        <p>This app can be installed as a PWA. Please check your browser's install option.</p>
      </div>
    </div>
  </template>
  
  
  
  
  <script>
  import { ref, computed, onMounted } from 'vue';
  import { useDevice } from '@/composables/useDevice';
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { faArrowUpFromBracket, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
  
  library.add(faArrowUpFromBracket, faSquarePlus);
  
  export default {
    name: 'PwaNotification',
    setup() {
      const { isMobile, os, isPwaInstalled } = useDevice();
      const deferredPrompt = ref(null);
      const canShowInstallButton = ref(false);
      const showNotification = ref(isMobile.value && !isPwaInstalled.value);
  
      const showInstallPromotion = () => {
        console.log("showInstallPromotion called");
        canShowInstallButton.value = true;
      };
  
      const dismissNotification = () => {
        showNotification.value = false;
      };
  
      onMounted(() => {
        if (os.value === 'Android') {
          window.addEventListener('beforeinstallprompt', (e) => {
            console.log("'beforeinstallprompt' event fired");
            e.preventDefault();
            deferredPrompt.value = e;
            showInstallPromotion();
          });
        }
      });
  
      const installPwa = async () => {
        if (!deferredPrompt.value) return;
  
        canShowInstallButton.value = false;
        deferredPrompt.value.prompt();
  
        const { outcome } = await deferredPrompt.value.userChoice;
        console.log(`User response to the install prompt: ${outcome}`);
        deferredPrompt.value = null;
      };
  
      return {
        os,
        showNotification,
        canShowInstallButton,
        installPwa,
        dismissNotification,
      };
    },
  };
  </script>
  
  <style scoped>
  .pwa-notification {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 90vw;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    overflow: hidden;
    box-sizing: border-box;
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically center items */
    justify-content: space-between; /* Space between close button and content */
  }
  
  .pwa-notification p,
  .pwa-notification ul {
    margin: 0;
    font-size: 16px;
    text-align: left; /* Align text to the left */
    white-space: normal; /* Allow text wrapping */
    flex: 1; /* Allow the text to take up remaining space */
  }
  
  .pwa-notification ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .pwa-notification li {
    text-align: left;
    padding: 5px 0;
  }
  
  button {
    display: block;
    margin: 15px auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Add styling for FontAwesome icons */
  .fa-arrow-up-from-bracket {
    margin-left: 5px;
  }
  
  .fa-square-plus {
    margin-left: 5px;
  }
  
  .close-btn {
    width: 6vw; /* Reduce width to ensure it's not too wide */
    max-width: 30px; /* Ensure it's not too wide on larger screens */
    min-width: 20px; /* Provide a minimum width for smaller screens */
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .close-btn:hover,
  .close-btn:focus,
  .close-btn:active {
    color: #000;
    background-color: transparent; /* Remove blue background on click */
    outline: none; /* Remove focus outline */
  }
  </style>
  