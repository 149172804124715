<template>

  <CentralLayout>

    <template v-slot:content>

      <div v-if="hasContentLoaded" id="treasure-chest">

        <div class="title" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">Your Treasure Chest</div>

        <div class="actions">


          <div class="languages-wrapper">
            <select id="cardsLanguage" name="cardsLanguage" v-model="selectedLanguage">
              <option
                  v-for="(language, indexLanguage) in memoryCardsLanguages"
                  :key="indexLanguage"
                  :value="language"
              >
                {{ language }}
              </option>
            </select>
          </div>

          <div class="icon filter">

            <div v-show="areFiltersDisplayed" class="filters_wrapper" id="filters_wrapper_id">
              <!--          type filters-->
              <div class="filter_wrapper">
                <span class="filter-title">Type</span>
                <div v-for="(filterType, indexFilterType) in filters.Types"
                     :key="indexFilterType"
                     class="filter_option">
                  <label>
                    <input type="checkbox" :value="filterType" v-model="selectedFilters.Types">
                    {{ filterType.Label }}
                  </label>
                </div>
              </div>

              <!--          source filters-->
              <div class="filter_wrapper">
                <span class="filter-title">Source</span>
                <div v-for="(filterSource, indexFilterSource) in filters.Sources"
                     :key="indexFilterSource"
                     class="filter_option">
                  <label>
                    <input type="checkbox" :value="filterSource" v-model="selectedFilters.Sources">
                    {{ filterSource.Name }}
                  </label>
                </div>
              </div>

            </div>

            <font-awesome-icon icon="filter"
                               class="icon-filter"
                               title="filter"
                               @click="changeFiltersVisibility()"
                               id="filter_icon_id"
            />
          </div>

          <div class="search-wrapper">
            <font-awesome-icon class="icon icon-search" icon="magnifying-glass" @click="toggleSearchInput()"/>
            <transition name="fade">
              <input id="search" name="search" type="text" v-model="searchFilter" v-if="isSearchVisible">
            </transition>
          </div>

        </div>

        <div v-if="displayedTreasureChestAssets.memoryCards.length > 0 || displayedTreasureChestAssets.expeditionLogs.length > 0 || displayedTreasureChestAssets.attachments.length > 0" class="treasure-chest-items">

          <div class="assets_wrapper">

            <div class="type_group_wrapper">
              <div v-for="(linkedAsset, indexLinkedAsset) in displayedTreasureChestAssets.memoryCards"
                   :key="indexLinkedAsset"
                   class='asset_wrapper'>
                <div class="memory_card" v-if="linkedAsset.Type === 'MemoryCard'">
                  <MemoryCard :card="linkedAsset"/>
                </div>
              </div>
            </div>

            <div class="type_group_wrapper">
              <div v-for="(linkedAsset, indexLinkedAsset) in displayedTreasureChestAssets.expeditionLogs"
                   :key="indexLinkedAsset"
                   class='asset_wrapper'>
                <div class="expedition_log" v-if="linkedAsset.Type === 'ExpeditionLog'">
                  <ExpeditionLogCard :item="linkedAsset"/>
                </div>
              </div>
            </div>

            <div class="type_group_wrapper">
              <div v-for="(linkedAsset, indexLinkedAsset) in displayedTreasureChestAssets.attachments"
                   :key="indexLinkedAsset"
                   class='asset_wrapper'>
                <div v-if="linkedAsset.Type === 'Attachment'"
                     class="attachment"
                     @click="downloadAsset(linkedAsset)">
                  <div class="icon attachment-icon">
                    <font-awesome-icon icon="paperclip"/>
                  </div>
                  <span>{{ linkedAsset.Label }}</span>
                </div>
              </div>
            </div>

          </div>

        </div>

        <p v-else>Welcome to the Treasure Chest!
          This is where you'll find valuable gems and gold nuggets – key learnings from your sessions. As you progress, new treasures will be added here. Stay tuned and look forward to discovering the insights that will enrich your journey!</p>

      </div>

    </template>

  </CentralLayout>


</template>

<script>

import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import CentralLayout from '@/layouts/CentralLayout';
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Cards from "@/assets/memory_cards/cardDetails";
import MemoryCard from "@/components/common/MemoryCard.vue";
import ExpeditionLogCard from "@/components/common/ExpeditionLogCard.vue";
import _ from "lodash";

export default {

  name: 'TreasureChest',

  components: {
    FontAwesomeIcon,
    CentralLayout,
    Tabs,
    Tab,
    MemoryCard,
    ExpeditionLogCard,
  },

  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isFirstPageEnter = ref(true);

    const treasureChestAssets = ref([]);

    const filters = ref({
      Types: [],
      Sources: [],
      Tags: []
    });

    const selectedFilters = ref({
      Types: [],
      Sources: [],
      Tags: []
    });

    const areFiltersDisplayed = ref(false);

    const searchFilter = ref('');

    const journeysNames = ref([]);

    const isSearchVisible = ref(false);

    // const changeLanguage = (language) => {
    //   store.dispatch('language/setLanguage', language);
    // };

    // watch(searchFilter, (newValue, oldValue) => {
    //   // fires on nested property mutations
    //   // Note: `newValue` will be equal to `oldValue` here
    //   // because they both point to the same object!
    // })
    window.addEventListener('click', function(e){
      if (areFiltersDisplayed.value === true) {
        if (!document.getElementById('filters_wrapper_id').contains(e.target) && !document.getElementById('filter_icon_id').contains(e.target) ){
          areFiltersDisplayed.value = false;
        }
      }
    });

    const toggleSearchInput = () => {
      isSearchVisible.value = !isSearchVisible.value
    }

    const changeFiltersVisibility = () => {
      areFiltersDisplayed.value = !areFiltersDisplayed.value;
    };

    const displayedTreasureChestAssets = computed(() => {

      let displayedAssets = treasureChestAssets.value.filter((treasureChestAsset) => {

        // return (
        //     (forecast.Plant_Name && forecast.Plant_Name.toString().toLowerCase().includes(mySearch))
        //     || (forecast.Group_Account && forecast.Group_Account.toString().toLowerCase().includes(mySearch))
        //     || (forecast.Customer_Sold_To_ID && forecast.Customer_Sold_To_ID.toString().toLowerCase().includes(mySearch))
        // )

        let searchMatch = true;
        let mySearch = searchFilter.value.toLowerCase();
        if (mySearch !== '') {
          if (treasureChestAsset.Type === 'Attachment') {
            searchMatch = treasureChestAsset.Label.toString().toLowerCase().includes(mySearch);
          }
          if (treasureChestAsset.Type === 'MemoryCard') {
            const frontTitle = treasureChestAsset.frontTitle[selectedLanguage.value];
            const frontBottomText = treasureChestAsset.frontBottomText[selectedLanguage.value];

            const matchTitle = frontTitle.toString().toLowerCase().includes(mySearch);
            const matchBottomText = frontBottomText.toString().toLowerCase().includes(mySearch);

            searchMatch =  matchTitle || matchBottomText;
          }
          if (treasureChestAsset.Type === 'ExpeditionLog') {
            const title = treasureChestAsset.Title['en'];

            const matchTitle = title.toString().toLowerCase().includes(mySearch);

            return matchTitle;
          }
        }

        let typeMatch = true;
        if (selectedFilters.value.Types.length !== 0) {
          let foundMatch = selectedFilters.value.Types.find((filterType) => filterType.Value === treasureChestAsset.Type);
          typeMatch = (foundMatch !== undefined)
        }

        let sourceMatch = true;
        if (selectedFilters.value.Sources.length !== 0) {
          let foundMatch = selectedFilters.value.Sources.find((filterSource) => {
            return treasureChestAsset.Sources.Journeys.includes(filterSource.ID);
          });
          sourceMatch = (foundMatch !== undefined);
        }


        return searchMatch && typeMatch && sourceMatch;

      });

      let memoryCards = displayedAssets.filter((asset) => asset.Type === 'MemoryCard');
      let expeditionLogs = displayedAssets.filter((asset) => asset.Type === 'ExpeditionLog');
      let attachments = displayedAssets.filter((asset) => asset.Type === 'Attachment');

      return {
        memoryCards: memoryCards,
        expeditionLogs: expeditionLogs,
        attachments: attachments,
      };

    });

    const allMemoryCards = ref([]);
    allMemoryCards.value = Cards;

    const ExpeditionLogs = computed(() => store.getters["tenantSpecificAssets/getterExpeditionLogs"]);

    const memoryCardsLanguages = ref(['bg', 'cz', 'de', 'en', 'fr', 'ho', 'hr', 'hu', 'it', 'mx', 'no', 'ro', 'rs', 'se', 'si', 'tr', 'us', 'pl']);
    const selectedLanguage = ref();

    watch(selectedLanguage, async (newValue, oldValue) => {

      if (!isFirstPageEnter.value) {
        await store.dispatch('userPreferences/updateLanguage', newValue);
      }

    });

    watch(() => _.cloneDeep(selectedFilters), async (newValue, oldValue) => {

          if (!isFirstPageEnter.value) {
            let payload = {
              TreasureChestFilters: newValue.value,
            }
            await store.dispatch('userPreferences/updateTreasureChestFilters', payload);
          }

        },
        {deep: true}
    );

    const getSkippedTabsTitles = (linkedAssets) => {

      let skippedTabsTitles = [];

      let oneUnitAttachment = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'Attachment');
      if (oneUnitAttachment === undefined) {
        skippedTabsTitles.push('Attachments');
      }

      let oneUnitMemoryCard = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'MemoryCard');
      if (oneUnitMemoryCard === undefined) {
        skippedTabsTitles.push('HB Memo Cards');
      }

      return skippedTabsTitles;

    };

    const downloadAsset = async (linkedAsset) => {

      let url = null;

      if (linkedAsset.hasOwnProperty('Url')) {
        url = linkedAsset.Url;
      }
      else {

        let payload = {
          file_name: linkedAsset.Name,
          file_label: linkedAsset.Label,
          folder_name: linkedAsset.FolderName
        };

        url = await store.dispatch('file/getFile', payload);
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', linkedAsset.Label);
      link.click();
    };

    const getFilters = () => {

      treasureChestAssets.value.forEach((treasureChestAsset) => {

        // get type filters
        let alreadyExists = (filters.value.Types.find((typeFilter) => typeFilter.Value === treasureChestAsset.Type)) !== undefined;

        if (!alreadyExists) {

          let typeFilterObject = {
            Value: treasureChestAsset.Type,
          }

          switch (typeFilterObject.Value) {

            case 'ExpeditionLog': {
              typeFilterObject.Label = 'Expedition Log'
              break;
            }

            case 'MemoryCard': {
              typeFilterObject.Label = 'Memo Card'
              break;
            }

            default: {
              typeFilterObject.Label = typeFilterObject.Value
              break;
            }

          }

          filters.value.Types.push(typeFilterObject);
        }

        // get sources filters
        treasureChestAsset.Sources.Journeys.forEach((journeyID) => {

          let alreadyExists = (filters.value.Sources.find((sourceFilter) => sourceFilter.ID === journeyID)) !== undefined;

          if (!alreadyExists) {

            let journeyObject = journeysNames.value.find((journey) => journey.ID === journeyID);

            if (journeyObject !== undefined) {
              filters.value.Sources.push(journeyObject);
            }
          }
        })

      });
    };

    onMounted(async () => {

      treasureChestAssets.value = await store.dispatch('treasureChest/getTreasureChestForUser');

      treasureChestAssets.value.forEach((treasureChestAsset) => {
        if (treasureChestAsset.Type === 'ExpeditionLog') {
          let expedition_log = ExpeditionLogs.value.find((expedition_log) => expedition_log.ExpeditionLogNumber === treasureChestAsset.ExpeditionLogNumber);
          Object.assign(treasureChestAsset, treasureChestAsset, expedition_log);
        }
        if (treasureChestAsset.Type === 'MemoryCard') {
          let memo_card = allMemoryCards.value.find((card) => card.batchNumber === treasureChestAsset.BatchNumber && card.memoryCardNumber === treasureChestAsset.MemoryCardNumber);
          Object.assign(treasureChestAsset, treasureChestAsset, memo_card);
        }
      });

      const sortOrder = {
        MemoryCard: 1,
        ExpeditionLog: 2,
        Attachment: 3
      };

      treasureChestAssets.value.sort((a, b) => {
        return sortOrder[a.Type] - sortOrder[b.Type];
      });

      journeysNames.value = await store.dispatch('journey/listAllJourneysNames');

      getFilters();

      selectedLanguage.value = store.state.userPreferences.userPreferences.Language;

      selectedFilters.value.Types = store.state.userPreferences.userPreferences.TreasureChestFilters.Types;
      selectedFilters.value.Sources = store.state.userPreferences.userPreferences.TreasureChestFilters.Sources;
      selectedFilters.value.Tags = store.state.userPreferences.userPreferences.TreasureChestFilters.Tags;

      hasContentLoaded.value = true;

      await store.dispatch('loading/setLoading', false);

      isFirstPageEnter.value = false;

    });

    return {
      store,
      hasContentLoaded,
      treasureChestAssets,
      changeFiltersVisibility,
      displayedTreasureChestAssets,
      searchFilter,
      filters,
      selectedFilters,
      areFiltersDisplayed,
      getSkippedTabsTitles,
      downloadAsset,
      toggleSearchInput,
      isSearchVisible,
      selectedLanguage,
      memoryCardsLanguages,
    }

  }

}

</script>

<style lang="scss" scoped>

#treasure-chest {
  padding: 20px;

  .title {
    font-size: 30px;
    text-align: left;
    margin-bottom: 10px;
  }

  .treasure-chest-items {
    display: flex;
    align-items: flex-start;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 5px;

    .search-wrapper {
      display: flex;
      align-items: center;
      height: 24px;

      .icon.icon-search {
        height: 24px;
        margin-right: 5px;
      }

      input {
        padding: 2px 25px;
        border: 1px solid gray;
        border-radius: 5px;
        width: 100%;
      }

    }

    .languages-wrapper {

      select {
        padding: 2px 10px;
        border: 1px solid gray;
        border-radius: 5px;
        text-transform: uppercase;
      }

    }

    .icon.filter {
      position: relative;
      width: fit-content;
      justify-content: flex-start;

      .icon-filter {
        font-size: 24px;
      }

      .filters_wrapper {
        position: absolute;
        top: 100%;
        left: 100%;
        z-index: 1;
        background-color: #fff;
        max-width: 300px;
        min-width: 200px;
        border-radius: 10px;
        padding: 10px 15px;
        cursor: default;
        -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);

        .filter_wrapper {
          margin: 5px 0;

          .filter-title {
            font-weight: bold;
          }

          .filter_option {
            margin: 10px 0;

            label {
              display: flex;
              align-items: flex-start;
              gap: 5px;
              line-height: 1;
              cursor: pointer;
            }
            input {
              cursor: pointer;
            }
          }

        }

      }

    }

  }

  .assets_wrapper {
    margin-top: 40px;

    .type_group_wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;
    }

    .asset_wrapper {

      .attachment {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        color: var(--bs-body-color);
        text-decoration: unset;
        background-color: #fafafa;
        width: fit-content;
        padding: 15px;
        border-radius: 10px;
        cursor: pointer;
        -webkit-box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.3);
        min-width: 130px;

        .icon.attachment {
          font-size: 30px;
        }
      }

    }

  }

  //.languages-wrapper {
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //  gap: 10px;
  //  margin-bottom: 10px;
  //
  //  select {
  //    padding: 5px 10px;
  //    border: 1px solid gray;
  //    border-radius: 5px;
  //    text-transform: uppercase;
  //  }
  //}
  //
  //.search-wrapper {
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //  gap: 10px;
  //  margin-bottom: 10px;
  //
  //  input {
  //    padding: 5px 10px;
  //    border: 1px solid gray;
  //    border-radius: 5px;
  //    width: 10%;
  //  }
  //}
  //
  //.cards-wrapper {
  //  display: flex;
  //  align-items: center;
  //  justify-content: flex-start;
  //  flex-wrap: wrap;
  //  gap: 20px;
  //  margin-top: 10px;
  //  padding: 20px 0;
  //}
}

@media only screen and (max-width: 768px) {
  #treasure-chest {
    padding: 0;

    .languages-wrapper {
      text-align: left;
    }

    //.search-wrapper {
    //  input {
    //    width: 40%;
    //  }
    //}
    //
    //.cards-wrapper {
    //  justify-content: center;
    //}
  }
}

</style>
