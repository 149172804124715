<template>
  <div v-if="image.src" class="upload__cropper-wrapper">
    <Cropper
      ref="cropper"
      check-orientation
      :src="image.src"
      :stencil-props="{
        aspectRatio: 10 / 2
      }"
    />

    <div
      class="btn btn-sm btn-primary upload__reset-button"
      title="Reset Image"
      @click="reset()"
    >
      <img :src="require('@/assets/icons/reset.svg')" />
    </div>
    <div class="upload__file-type" v-if="image.type">
      {{ image.type }}
    </div>
  </div>
  <div class="upload__buttons-wrapper">
    <button
      class="btn btn-sm btn-primary upload__button"
      @click="$refs.file.click()"
    >
      <input
        ref="file"
        type="file"
        accept="image/*"
        @change="loadImage($event)"
      />
      Select Image
    </button>
    <button
      v-if="image.src"
      class="btn btn-sm btn-primary"
      @click="uploadProfilePicture()"
    >
      Save and Close
    </button>
  </div>
  <div v-if="filesizeerror" class="invalid-feedback">
    PNG or JPG no bigger than 9MB.
  </div>
</template>

<script>
import { onUnmounted, reactive, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { Cropper } from 'vue-advanced-cropper'

import 'vue-advanced-cropper/dist/style.css'

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])
  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47':
      return 'image/png'
    // case '47494638':
    //   return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

function formatBytes(a, b = 2) {
  if (0 === a) return '0 Bytes'
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024))
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  )
}

export default {
  components: {
    Cropper
  },

  setup() {
    const image = reactive({
      src: null,
      type: null
    })
    const reset = () => {
      console.log('Image Reset')
      image.src = null
      image.type = null
    }
    //const cropper = ref(null)

    const filesizeerror = ref(false)
    const loadImage = event => {
      const { files } = event.target
      if (files && files[0] && files[0].size <= '9500000') {
        filesizeerror.value = false
        if (image.src) {
          URL.revokeObjectURL(image.src)
        }
        const blob = URL.createObjectURL(files[0])
        const reader = new FileReader()
        reader.onload = e => {
          image.src = blob
          image.type = getMimeType(e.target.result, files[0].type)
        }
        reader.readAsArrayBuffer(files[0])
      } else {
        filesizeerror.value = true
      }
    }

    onUnmounted(() => {
      //console.log('onUnmounted')
      if (image.src) {
        URL.revokeObjectURL(image.src)
      }
    })
    return {
      //cropper,

      image,
      reset,
      loadImage,

      //uploadProfilePicture,
      filesizeerror
    }
  },
  methods: {
    blobToFile(theBlob, fileName) {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },

    uploadProfilePicture() {
      this.$store.dispatch('loading/setLoading', true)
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const form = new FormData()

        let ext = null
        const filename = uuidv4()
        switch (this.image.type) {
          case 'image/png':
            ext = 'png'
            break

          case 'image/jpeg':
            ext = 'jpg'
            break

          case 'image/jpg':
            ext = 'jpg'
            break

          default:
            // Anweisungen werden ausgeführt,
            // falls keine der case-Klauseln mit expression übereinstimmt
            break
        }

        // Convert canvas image to Base64
        var img = canvas.toDataURL()
        // Convert Base64 image to binary
        var crop_img = dataURItoBlob(img)

        const logo_old = this.$store.state.tenant.tenantinfo.logoURL
        // todo has to change to logo
        // const payload = {
        //   name: filename,
        //   ext: ext,
        //   type: this.image.type,
        //   file: crop_img
        // }
        // this.$store.dispatch('upload/uploadProfilePicture', payload)

        // console.log('this.$store.state.auth.userinfo.picture')
        // console.log(picture)
        // console.log(this.$store.state.auth.userinfo.picture)
        if (logo_old) {
          const splitPicturePath = logo_old.split('/')
          const item = splitPicturePath[splitPicturePath.length - 1]
          const delete_payload = {
            type: 'profile',
            item: item
          }
          // todo has to change to logo
          //this.$store.dispatch('upload/deleteImage', delete_payload)
        } else {
          //this.$store.commit('loading/setLoading', false, { root: true })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;

  &__cropper-wrapper {
    position: relative;
  }
  &__reset-button {
    position: absolute;
    right: -20px;
    bottom: -20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 42px;
  }
  &__buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
  &__button {
    padding: 10px 20px;

    margin: 0 16px;

    input {
      display: none;
    }
  }
  &__file-type {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #0d0d0d;
    border-radius: 5px;
    padding: 0px 10px;
    padding-bottom: 2px;
    font-size: 12px;
    color: white;
  }
}
.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  opacity: 1;
  background: white !important;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
