<template>

  <div v-if="hasContentLoaded" class="asset_item_wrapper">

    <div class="asset-item"
         @click="isExpeditionLogModalOpened = true"
    >

      <div class="top-right-icons">

        <div v-if="isGemDisplayed"
             class="icon gem">
          <font-awesome-icon icon="gem"/>
        </div>

        <div v-if="isCheckmarkDisplayed"
             class="check-wrapper">

          <div v-if="isChecked"
               class="icon check"
               title="Remove"
               @click.stop="uncheckItem()"
          >
            <font-awesome-icon :icon="['fas', 'circle-check']" />
          </div>

          <div v-else
               class="icon uncheck"
               title="Add"
               @click.stop="checkItem()"
          >
            <font-awesome-icon :icon="['far', 'circle-check']" />
          </div>

        </div>

      </div>

      <div class="logo_wrapper">
        <img v-if="item.hasOwnProperty('LogoURL')" :src="item.LogoURL" alt="logo">
      </div>

      <div class="title_wrapper">
        <span>{{ item.Title[language] }}</span>
      </div>

      <div class="description_wrapper">
        <span>{{ item.Description[language] }}</span>
      </div>

    </div>

    <ExpeditionLogModal v-if="isExpeditionLogModalOpened"
                        :language="language"
                        :expeditionLogNumber="item.ExpeditionLogNumber"
                        @close="isExpeditionLogModalOpened = false"
    />

  </div>

</template>

<script>

import {useStore} from "vuex";
import {computed, onMounted, ref, watch} from "vue";
import ExpeditionLogModal from "@/components/common/ExpeditionLogModal.vue";

export default {

  name: 'ExpeditionLogCard',

  components: {
    ExpeditionLogModal
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    isCheckmarkDisplayed: {
      type: Boolean,
      default: false,
    },
    isGemDisplayed: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    assets: {
      type: Array,
      default: [],
    },
  },

  setup(props, {emit}) {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isExpeditionLogModalOpened = ref(false);

    const item = ref();

    item.value = props.item;

    props.assets.forEach(asset => {
      if (asset.Type === 'ExpeditionLog' && asset.ExpeditionLogNumber === item.value.ExpeditionLogNumber) {
        item.value.Tags = asset.Tags;
      }
    });

    const itemElement = ref(null);

    // const language = computed(() => store.getters["userPreferences/getLanguage"]);
    const language = ref('en');

    watch(() => props.card, (newValue) => {
      item.value = newValue;
    });

    // check functionality
    const checkItem = () => {

      const payload = {
        ExpeditionLogNumber: item.value.ExpeditionLogNumber,
      }

      emit('checkItem', payload);

    };

    const uncheckItem = () => {

      const payload = {
        ExpeditionLogNumber: item.value.ExpeditionLogNumber,
      }

      emit('uncheckItem', payload);

    };

    const getLogo = async () => {
      item.value.LogoURL = await store.dispatch('file/getFile', {
        file_name: item.value.Logo,
        folder_name: `expedition_logs/assets`
      }, {root: true});
    }

    // const addAssetTags = (itemNumber) => {
    //   emit('addAsset', itemNumber);
    // };
    //
    // const deleteTag = (ev, itemNumber, indexAssetTag) => {
    //   emit('deleteTag', itemNumber, indexAssetTag);
    // };

    // end of check functionality

    onMounted( async() => {
      await getLogo();
      hasContentLoaded.value = true;
    });

    return {
      hasContentLoaded,
      itemElement,
      item,
      language,
      isExpeditionLogModalOpened,
      checkItem,
      uncheckItem,
      // addAssetTags,
      // deleteTag,
    }
  }

}

</script>

<style lang="scss" scoped>

.asset_item_wrapper {

  .asset-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
    width: 255px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    .top-right-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      background-color: white;
    }

    .logo_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-shadow: 3px 3px 6px #00000029;
      background-color: #fff;
      height: 300px;
      width: 100%;

      img {
        max-width: 70%;
      }
    }

    .title_wrapper {
      font-weight: bold;
      span {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .description_wrapper {
      font-size: 14px;
      text-align: left;
    }

  }

}

</style>