<template>

  <div v-if="hasContentLoaded" id="journey-hero" v-cloak>

    <div class="hero"
         :style="{ backgroundImage: heroBackgroundImage }">

      <div class="overlay">

        <div v-if="isCurrentUserAuthorOfJourney"
             class="top-left">
<!--          path symbol-->
          <div class="icon"
          >
            <font-awesome-icon icon="route"
                               title="Go to journey"
                               @click="isEditMode ? goToJourneyAction(journeyDetails.ID, 'JourneyCreation') : goToJourneyAction(journeyDetails.ID, 'JourneyView')"
            />
          </div>

          <div v-show="!isEditMode"
               class="icon"
               @click="toggleIsEditMode()"
          >
            <font-awesome-icon icon="edit"
                               title="Edit mode"
            />
          </div>

          <div v-show="isEditMode"
               class="icon"
               @click="toggleIsEditMode()"
          >
            <font-awesome-icon :icon="['far', 'circle-play']"
                               title="View mode"
            />
          </div>
        </div>

        <div v-if="isEditMode" class="top-right">

          <!--             Dropdown-->
          <div id="journey-category" class="dropdown">
            <div
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <!--                <Icon icon="mdi-light:dots-vertical"/>-->
              <div class="icon category">
                <font-awesome-icon icon="ellipsis-vertical" title="More"/>
              </div>
            </div>
            <div class="dropdown-menu dropdown-menu-end">
              <div v-for="(journeyCategory, indexJourneyCategory) in journeyCategories"
                   :key="indexJourneyCategory"
                   :class="{ 'dropdown-item': true, 'active-item': journeyCategory.ID === journeyDetails.CategoryID }"
                   @click="changeJourneyCategory(journeyCategory.ID)"
              >
                {{ journeyCategory.Name }}
              </div>
            </div>
          </div>

          <ImageUpload class="image-upload"
                       :folderName="'journey/' + journeyDetails.ID"
                       type="icon"
                       :hasPreview="false"
                       @pictureReady="pictureReadyHandler($event)"
          />

          <div :class="{ icon: true, save: true, 'save-disabled': isSaveDisabled }"
               @click="saveClickHandler()">
            <font-awesome-icon icon="floppy-disk" title="Save Journey"/>
          </div>

        </div>

        <div class="center-content">

          <div class="entry_wrapper journey_name">
            <h1 v-if="!isEditMode">{{ journeyDetails.Name }}</h1>
            <input
                v-if="isEditMode"
                type="text"
                placeholder="Journey Name..."
                v-model="journeyDetails.Name"
                ref="journeyNameElement"
                class="editable-input"
            />
          </div>

          <div class="entry_wrapper journey_description">
            <p v-if="!isEditMode">
              {{ journeyDetails.Description }}</p>
            <input
                v-if="isEditMode"
                type="text"
                placeholder="Journey Description..."
                v-model="journeyDetails.Description"
                ref="journeyDescriptionElement"
                class="editable-input"
            />
          </div>

          <div v-if="!isJourneyReusableTemplatePage && !isCurrentUserAuthorOfJourney && !isParticipantFirstEnterJourney()"
               class="icon play"
               @click="mobile ? goToJourneyAction(journeyDetails.ID, 'MobileJourneyView') : goToJourneyAction(journeyDetails.ID, 'JourneyView')">
            <font-awesome-icon :icon="['far', 'circle-play']"/>
          </div>

          <div v-if="isJourneyReusableTemplatePage && !isCurrentUserAuthorOfJourney && !isParticipantFirstEnterJourney()"
               class="icon play"
               @click="goToJourneyAction(journeyDetails.ID, 'JourneyReusableTemplateView')">
            <font-awesome-icon :icon="['far', 'circle-play']"/>
          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import CentralLayout from '@/layouts/CentralLayout.vue';
import ImageUpload from "@/components/upload/ImageUpload.vue";
import {Icon} from "@iconify/vue";
import {computed, inject, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {

  name: 'JourneyHero',

  components: {
    FontAwesomeIcon,
    CentralLayout,
    ImageUpload,
    Icon,
  },

  emits: ['toggleIsEditMode', 'save', 'goToJourneyAction'],

  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isSaveDisabled: {
      type: Boolean,
      default: true,
    },
    mobile: {
      type: Boolean,
      default: false
    },
  },



  setup(props, {emit}) {

    const hasContentLoaded = ref(false);

    const store = useStore();

    const router = useRouter();

    const route = useRoute();

    const journeyDetails = inject('journeyDetails');

    const isCurrentUserAuthorOfJourney = inject('isCurrentUserAuthorOfJourney');

    const journeyCategories = computed(() => store.getters["journeyCategory/getJourneyCategories"]);

    const isJourneyReusableTemplatePage = ref(false);

    if(router.currentRoute._value.name === 'JourneyReusableTemplateMap') {
      isJourneyReusableTemplatePage.value = true;
    }

    const heroBackgroundImage = ref(null);

    const toggleIsEditMode = () => {
      emit('toggleIsEditMode');
    };

    const isParticipantFirstEnterJourney = () => {
      if (!isJourneyReusableTemplatePage.value) {
        return (journeyDetails.value.hasOwnProperty('Consent') && journeyDetails.value.IsCurrentUserParticipant && !journeyDetails.value.Consent);
      }
      return false;
    };

    const goToJourney = () => {
      if (props.isEditMode) {
        goToEditJourney();
      }
      else {
        goToJourneyView();
      }
    }

    const goToJourneyView = () => {

      if (!isJourneyReusableTemplatePage.value) {
        if(props.mobile)
        {
          router.push({
          name: 'MobileJourneyView',
          params: {
            id: journeyDetails.value.ID
          }
        });
        }
        else{
          router.push({
          name: 'JourneyView',
          params: {
            id: journeyDetails.value.ID
          }
        });

        }
       
      }
      else {
        router.push({
          name: 'JourneyReusableTemplateView',
          params: {
            id: journeyDetails.value.ID
          }
        });
      }

    };

    const goToEditJourney = () => {
      router.push({
        name: 'JourneyCreation',
        params: {
          id: journeyDetails.value.ID
        }
      });
    };

    const isCreateNew = () => {
      return route.params.id === '' || route.params.id === null || route.params.id === undefined;
    };

    const pictureReadyHandler = (ev) => {
      journeyDetails.value.PictureUpdated = ev;
      store.commit('loading/setLoading', false);
      store.commit('file/setUploadVisible', false);
      getHeroBackgroundImage();
    };

    const getHeroBackgroundImage = () => {
      if (journeyDetails.value.hasOwnProperty('PictureUpdated')) {
        heroBackgroundImage.value = `url( ${journeyDetails.value.PictureUpdated.url} )`;
      } else {
        heroBackgroundImage.value = `url( ${journeyDetails.value.PictureURL} )`;
      }
    };

    const changeJourneyCategory = (categoryID) => {
      journeyDetails.value.CategoryID = categoryID;
    };

    const saveClickHandler = async () => {

      emit('save');

    };

    const goToJourneyAction = (journeyID, actionName) => {
      if (props.isEditMode) {
        emit('goToJourneyAction', journeyID, actionName);
      } else {
        router.push({
          name: actionName,
          params: {
            id: journeyID
          }
        });
      }
    };

    onMounted(async () => {

      if (isCreateNew()) {
            // isEdit.value = {
            //   journeyName: true,
            //   journeyDescription: true,
            // };
      }

      getHeroBackgroundImage();

      hasContentLoaded.value = true;

    });

    return {
      hasContentLoaded,
      journeyDetails,
      journeyCategories,
      isCurrentUserAuthorOfJourney,
      heroBackgroundImage,
      isJourneyReusableTemplatePage,
      // isEdit,
      // journeyNameElement,
      // journeyDescriptionElement,
      // startEditing,
      // stopEditing,
      // updateValue,
      isCreateNew,
      pictureReadyHandler,
      saveClickHandler,
      goToJourney,
      goToJourneyView,
      goToEditJourney,
      isParticipantFirstEnterJourney,
      changeJourneyCategory,
      toggleIsEditMode,
      goToJourneyAction,
    }
  },

}

</script>

<style lang="scss" scoped>

#journey-hero {
  .hero {
    position: relative;
    min-height: 500px;
    color: white;
    text-align: center;
    background-color: #aaa;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      .center-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        max-width: 65rem;
      }

      .journey_name {
        font-size: 50px;
        font-weight: bold;

        h1 {
          font-size: 50px;
          margin-bottom: 0;
        }
      }

      .journey_description {
        font-size: 20px;

        p {
          margin-bottom: 0;
        }
      }

      .icon.play {
        font-size: 50px;
      }

      .editable-input {
        outline: none;
        cursor: text;
        width: 100%;
        color: white;
        text-align: center;
        border-radius: 10px;
        background-color: #00000075;
        border: 1px solid #fff;
      }

      input::placeholder {
        color: #fff;
        opacity: 0.8;
      }

      .top-left {
        position: absolute;
        top: 10px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        font-size: 25px;
      }

      .top-right {
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        font-size: 25px;
      }

      //.image-upload {
      //  //position: absolute;
      //  //top: 10px;
      //  //left: 20px;
      //}

      .icon.save {
        font-size: 25px;
        //position: absolute;
        //top: 10px;
        //right: 20px;

        &.save-disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      #journey-category {
        font-size: 25px;
        //position: absolute;
        //bottom: 10px;
        //left: 20px;
      }

      .dropdown {
        .dropdown-menu {
          max-height: 200px;
          max-width: 200px;
          overflow: auto;

          .dropdown-item {
            cursor: pointer;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
            -webkit-appearance: none;
          }

          .dropdown-item.active-item {
            color: #1e2125;
            background-color: #e9ecef;
          }
        }
      }

      .icon.edit {
        font-size: 25px;
        position: absolute;
        bottom: 10px;
        right: 20px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #journey-hero {
    .hero {
      .overlay {
        .center-content {
          padding: 20px;
          .journey_name {
            font-size: 40px;
          }
        }

        .icon {
          &.save, &.category, &.edit {
            font-size: 22px;
          }
        }
      }
    }
  }
}

</style>
