<template>
  <div class="table-wrapper">
    <table>
      <thead>
      <tr>
        <th>Name</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(journey, indexAllJourneys) in myJourneys" :key="indexAllJourneys">
        <td>
          <span>{{ journey.Name }}</span>
        </td>
        <td style="display: flex; justify-content: center;">
          <div class="edit-icon" @click="deleteJourney(journey.ID, journey.Name)">
            <font-awesome-icon icon="trash"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <transition name="fade">
    <div class="custom_modal" v-if="isDeleteModalDisplayed">
      <div class="custom_modal__content">
        <p>You are about to delete journey {{journeyNameToBeDeleted}}</p>
        <label>Type in the word 'DELETE' for confirmation</label>
        <input v-model="deleteConfirmationWord">
        <div class="buttons-wrapper">
          <button @click="confirmDeleteJourney()" class="btn btn-primary" style="font-weight: bold">Delete</button>
          <button @click="closeDeleteModal()" class="btn btn-primary">Cancel</button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {

  name: 'JourneyManagementMyAccount',

  setup() {
    const store = useStore();

    // const currentUserID = computed(() => store.getters["auth/getCurrentUserID"]);

    const myJourneys = computed(() => store.getters["journey/getUserAuthorJourneys"]);

    const isDeleteModalDisplayed = ref(false);

    const journeyNameToBeDeleted = ref('');

    const deleteConfirmationWord = ref('');

    const journeyIdToBeDeleted = ref('');

    const deleteJourney = (journeyID, journeyName) => {
      isDeleteModalDisplayed.value = true;
      journeyIdToBeDeleted.value = journeyID;
      journeyNameToBeDeleted.value = journeyName;
    }
    const confirmDeleteJourney = async () => {
      if(deleteConfirmationWord.value === 'DELETE'){
        await store.dispatch('journey/deleteJourney', {
          JourneyID: journeyIdToBeDeleted.value,
        });
        isDeleteModalDisplayed.value = false;
      }
    }
    const closeDeleteModal = () => {
      isDeleteModalDisplayed.value = false;
      deleteConfirmationWord.value = '';
    }

    // const filterJourneys = async () => {
      // const allJourneys = await store.dispatch('journey/list');
      // myJourneys.value = allJourneys.filter(item => item.AuthorID === currentUserID.value);
    // }

    onMounted(async() => {
      // await store.dispatch('loading/setLoading', true);
      // await filterJourneys();
      await store.dispatch('loading/setLoading', false);
    })

    return {
      myJourneys,
      isDeleteModalDisplayed,
      journeyNameToBeDeleted,
      deleteConfirmationWord,
      deleteJourney,
      confirmDeleteJourney,
      closeDeleteModal,

    }
  }

}
</script>
<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-icon:hover {
  cursor: pointer;
}
</style>
