<template>

  <CentralLayout>

    <template v-slot:content>

      <div v-if="hasContentLoaded" id="journey-map" v-cloak>

        <JourneyHero :isEditMode="isEditMode"
                     :isSaveDisabled="isSaveDisabled"
                     @toggleIsEditMode="toggleIsEditModeHandler()"
                     @save="saveClickHandler()"
                     @goToJourneyAction="goToJourneyActionHandler"
        />

        <div class="tabs-wrapper">

          <Tabs>

            <Tab title="Introduction">

              <Overview :isCurrentUserAuthorOfJourney="isCurrentUserAuthorOfJourney"
                        :isEditMode="isEditMode"
              />

            </Tab>

            <Tab title="Overview">

              <div id="modules">

                <div class="module_content-wrapper">

                  <div v-for="(module, indexModule) in journeyModules"
                       :key="indexModule"
                       class="module-content">

                    <div class="circle-wrapper">
                      <div class="circle">
<!--                        <span class="circle-element">{{ indexModule + 1 }}</span>-->
                      </div>
                    </div>

                    <ModuleDetails :module="module"/>

                  </div>

                  <div class="circle-wrapper">
                    <div class="circle">
<!--                      <span class="circle-element">End</span>-->
                    </div>
                  </div>

                </div>

              </div>

            </Tab>

            <Tab title="Participants">

              <JourneyParticipants :journeyDetails="journeyDetails"/>

            </Tab>

          </Tabs>

        </div>

        <div class="bottom_buttons-wrapper">
          <button v-if="isParticipantFirstEnterJourney()"
                  class="btn btn-primary"
                  @click="goToJourneyView()">
            <span>Start Journey</span>
          </button>
        </div>

      </div>

      <ConfirmationModal :isOpened="isUnsavedChangesModalOpened"
                         :title="titleModal"
                         :message="messageModal"
                         @confirm="confirmUnsavedChangesHandler"
                         @cancel="cancelUnsavedChangesHandler"
      />

    </template>

  </CentralLayout>

</template>

<script>

import CentralLayout from '@/layouts/CentralLayout';
import JourneyHero from "@/components/journey/Map/JourneyHero.vue";
import {computed, onMounted, provide, ref, watch} from "vue";
import {useStore} from "vuex";
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import {v4 as uuidv4, v4 as uuidV4} from "uuid";
import Tab from "@/components/common/Tab.vue";
import Tabs from "@/components/common/Tabs.vue";
import ModuleDetails from "@/components/journey/Map/ModuleDetails.vue";
import Overview from "@/components/journey/Map/Overview.vue";
import JourneyParticipants from "@/components/journey/Map/JourneyParticipants.vue";
import _ from "lodash";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";

export default {

  name: 'JourneyMap',

  components: {
    ConfirmationModal,
    Overview,
    CentralLayout,
    JourneyHero,
    Tabs,
    Tab,
    ModuleDetails,
    JourneyParticipants,
  },

  setup() {

    const hasContentLoaded = ref(false);

    const store = useStore();

    const router = useRouter();

    const route = useRoute();

    const journeyDetails = ref(null);

    const journeyModules = ref([]);

    const isCurrentUserAuthorOfJourney = ref(false);

    const activeModule = ref(0);

    const isEditMode = ref(false);

    const isSaveDisabled = ref(true);

    const firstPageEnter = ref(true);

    const isJourneyModified = ref(false);

    const isUnsavedChangesModalOpened = ref(false);

    const confirmUnsavedChanges = ref(false);

    const titleModal = ref('Warning');

    const messageModal = ref('There are unsaved changes. Are you sure you want to leave this page?');

    const actionToTriggerAfterUnsavedChanges = ref('');

    const userJourneys = computed(() => store.getters["journey/getUserJourneys"]);

    const journeyReusableTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyReusableTemplates"]);

    const journeyTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyTemplates"]);

    const currentUserRoles = computed(() => store.getters["auth/getCurrentUserRoles"]);

    const isReusableTemplatesModalDisplayed = ref(false);

    const isJourneyReusableTemplatePage = ref(false);

    const S3BucketFolderName = ref('journey');

    const isCreateNew = () => {
      return route.params.id === '' || route.params.id === null || route.params.id === undefined;
    };

    const initJourneyDetails = async () => {

      if (isCreateNew()) {
        journeyDetails.value = {
          ID: null,
          Name: '',
          Description: '',
          CategoryID: null,
          Picture: null,
          Overview: {
            Time: null,
            Elements: [],
            Version: null,
          },
          Structure: [
            {
              Type: 'Event',
              ID: uuidV4(),
              Name: 'Module 1',
              LinkedAssets: [],
              Options: {
                IsModule: true,
                IsMeeting: false,
                IsUnlock: false,
              },
              Periods: {
                From: null,
                To: null,
                Unlock: null,
              },
              Details: {
                Time: null,
                Elements: [],
                Version: null,
              }
            },
            {
              Type: 'Chapter',
              ID: uuidV4(),
              Name: 'Chapter 1',
              IsLocked: false,
              IsHidden: false,
              Units: [{
                Name: 'Unit 1',
                LinkedAssets: [],
                Blocks: [{
                  Time: null,
                  Elements: [],
                  Version: null,
                }]
              }]
            },
          ]
        }
        // isEdit.value = {
        //   journeyName: true,
        //   journeyDescription: true,
        // };
      } else {

        let journeyID = route.params.id;

        let selectedJourneyWithRef = {};

        if (!isJourneyReusableTemplatePage.value) {
          selectedJourneyWithRef = userJourneys.value.find((journey) => journey.ID === journeyID);
        }
        else {
          if (currentUserRoles.value.TenantAdmin) {
            selectedJourneyWithRef = journeyTemplates.value.find((journeyTemplate) => journeyTemplate.ID === journeyID);
          }
          if (!currentUserRoles.value.TenantAdmin && currentUserRoles.value.Facilitator) {
            selectedJourneyWithRef = journeyReusableTemplates.value.find((journeyReusableTemplate) => journeyReusableTemplate.ID === journeyID);
          }
        }

        if (selectedJourneyWithRef !== undefined) {

          await store.dispatch('userPreferences/updateLastJourneyID', {
            LastJourneyID: journeyID,
          });

          // lose reference from the journey in state
          let selectedJourney = JSON.parse(JSON.stringify(selectedJourneyWithRef));

          if (selectedJourney.hasOwnProperty('Picture')
              && selectedJourney.Picture !== null
              && selectedJourney.Picture !== undefined
              && selectedJourney.Picture !== '') {
            selectedJourney.PictureURL = await store.dispatch('file/getFile', {
              file_name: selectedJourney.Picture,
              folder_name: `${S3BucketFolderName.value}/${selectedJourney.ID}`
            })
          }

          if (selectedJourney.hasOwnProperty('Overview') && selectedJourney.Overview.hasOwnProperty('Elements')) {

            let elements = selectedJourney.Overview.Elements;

            for (const element of elements) {
              if (element.data.text && element.data.text.indexOf('href') !== -1){
                const regex = /href="(.*?)"/g;
                element.data.text = element.data.text.replace(regex, (match, p1) => {
                  return p1.indexOf(location.host) === -1
                      ? `target="_blank" ${match}`
                      : match;
                });
              }

              if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${selectedJourney.ID}/assets`
                });
              }
              if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${selectedJourney.ID}/assets`
                });
              }

            }

          }


          let activeModuleToAddChaptersIndex = null;

          selectedJourney.Structure.forEach((structureItem) => {

            if(structureItem.Type === 'Event' && structureItem.Options.IsModule) {
              let eventItem = JSON.parse(JSON.stringify(structureItem));
              eventItem.Chapters = [];
              journeyModules.value.push(eventItem);
              activeModuleToAddChaptersIndex = journeyModules.value.length - 1;
            }

            if (structureItem.Type === 'Chapter' && activeModuleToAddChaptersIndex !== null) {
              journeyModules.value[activeModuleToAddChaptersIndex].Chapters.push(structureItem);
            }

          });



          journeyDetails.value = selectedJourney;
        } else {
          await router.push({
            name: '404'
          })
        }
      }

    };

    const isParticipantFirstEnterJourney = () => {
      return (journeyDetails.value.hasOwnProperty('Consent') && journeyDetails.value.IsCurrentUserParticipant && !journeyDetails.value.Consent);
    };

    const toggleIsEditModeHandler = () => {
      isEditMode.value = !isEditMode.value;
    }

    const goToJourneyView = async() => {

      const payload = {
        JourneyID: journeyDetails.value.ID,
        JourneyParticipantLinkID: journeyDetails.value.JourneyParticipantLinkID,
      }
      await store.dispatch('journey/updateJourneyUserConsent', payload);

      await router.push({
        name: 'JourneyView',
        params: {
          id: journeyDetails.value.ID
        }
      });
    };

    const saveClickHandler = async () => {

      await store.dispatch('loading/setLoading', true);

      if (isCreateNew()) {
        journeyDetails.value = await store.dispatch('journey/create', journeyDetails.value);

        if (journeyDetails.value.hasOwnProperty('Overview') && journeyDetails.value.Overview.hasOwnProperty('Elements')) {

          let elements = journeyDetails.value.Overview.Elements;

          for (const element of elements) {
            if (element.data.text && element.data.text.indexOf('href') !== -1){
              const regex = /href="(.*?)"/g;
              element.data.text = element.data.text.replace(regex, (match, p1) => {
                return p1.indexOf(location.host) === -1
                    ? `target="_blank" ${match}`
                    : match;
              });
            }

            if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
              element.data.file.url = await store.dispatch('file/getFile', {
                file_name: element.data.file.name,
                folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
              });
            }
            if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
              element.data.file.url = await store.dispatch('file/getFile', {
                file_name: element.data.file.name,
                folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
              });
            }

          }

        }

        await router.push({
          name: 'JourneyMap',
          params: {
            id: journeyDetails.value.ID
          }
        });
      }
      else {
        await store.dispatch('journey/update', journeyDetails.value);
        if (journeyDetails.value.hasOwnProperty('Overview') && journeyDetails.value.Overview.hasOwnProperty('Elements')) {

          let elements = journeyDetails.value.Overview.Elements;

          for (const element of elements) {
            if (element.data.text && element.data.text.indexOf('href') !== -1){
              const regex = /href="(.*?)"/g;
              element.data.text = element.data.text.replace(regex, (match, p1) => {
                return p1.indexOf(location.host) === -1
                    ? `target="_blank" ${match}`
                    : match;
              });
            }

            if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
              element.data.file.url = await store.dispatch('file/getFile', {
                file_name: element.data.file.name,
                folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
              });
            }

            if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
              element.data.file.url = await store.dispatch('file/getFile', {
                file_name: element.data.file.name,
                folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
              });
            }

          }

        }
      }

      await store.dispatch('loading/setLoading', false);

      isJourneyModified.value = false;

      isSaveDisabled.value = true;

    };

    // const saveFromTemplateClickHandler = async (templateID) => {
    //
    //   await store.dispatch('loading/setLoading', true);
    //
    //   let journeyTemplate = journeyReusableTemplates.value.find((journeyReusableTemplate) => journeyReusableTemplate.ID === templateID);
    //
    //   if (journeyTemplate !== undefined) {
    //     let payload = {
    //       "Name": journeyTemplate.Name,
    //       "Description": journeyTemplate.Description,
    //       "CategoryID": journeyTemplate.CategoryID,
    //       "Picture": journeyTemplate.Picture,
    //       "Overview": journeyTemplate.Overview,
    //       "Structure": journeyTemplate.Structure,
    //       "TemplateID": journeyTemplate.ID,
    //     };
    //
    //     journeyDetails.value = await store.dispatch('journey/copyJourney', payload);
    //
    //     if (journeyDetails.value.hasOwnProperty('Overview') && journeyDetails.value.Overview.hasOwnProperty('Elements')) {
    //
    //       let elements = journeyDetails.value.Overview.Elements;
    //
    //       for (const element of elements) {
    //         if (element.data.text && element.data.text.indexOf('href') !== -1){
    //           const regex = /href="(.*?)"/g;
    //           element.data.text = element.data.text.replace(regex, (match, p1) => {
    //             return p1.indexOf(location.host) === -1
    //                 ? `target="_blank" ${match}`
    //                 : match;
    //           });
    //         }
    //
    //         if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
    //           element.data.file.url = await store.dispatch('file/getFile', {
    //             file_name: element.data.file.name,
    //             folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
    //           });
    //         }
    //
    //         if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
    //           element.data.file.url = await store.dispatch('file/getFile', {
    //             file_name: element.data.file.name,
    //             folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
    //           });
    //         }
    //
    //       }
    //
    //     }
    //
    //     isReusableTemplatesModalDisplayed.value = false;
    //
    //     await router.push({
    //       name: 'JourneyMap',
    //       params: {
    //         id: journeyDetails.value.ID
    //       }
    //     });
    //
    //     location.reload();
    //   }
    //
    //   await store.dispatch('loading/setLoading', false);
    //
    // };

    provide('journeyDetails', journeyDetails);
    provide('isReusableTemplatesModalDisplayed', isReusableTemplatesModalDisplayed);
    provide('isCurrentUserAuthorOfJourney', isCurrentUserAuthorOfJourney);

    const goToJourneyActionHandler = (journeyID, journeyAction) => {

      if (isJourneyModified.value) {
        actionToTriggerAfterUnsavedChanges.value = journeyAction;
        toggleUnsavedChangesModal();
      }
      else {
        router.push({
          name: journeyAction,
          params: {
            id: journeyID
          }
        });
      }

    };

    const toggleUnsavedChangesModal = () => {
      isUnsavedChangesModalOpened.value = !isUnsavedChangesModalOpened.value;
    };

    const confirmUnsavedChangesHandler = () => {
      confirmUnsavedChanges.value = true;
      goToJourneyAction();
      toggleUnsavedChangesModal();
    };

    const goToJourneyAction = () => {
      router.push({
        name: actionToTriggerAfterUnsavedChanges.value,
        params: {
          id: route.params.id
        }
      });
    };

    const cancelUnsavedChangesHandler = () => {
      toggleUnsavedChangesModal();
    };

    watch(() => _.cloneDeep(journeyDetails.value), (currentValue, oldValue) => {
      if (!firstPageEnter.value) {
        isSaveDisabled.value = false;
        isJourneyModified.value = true;
        }
      },
        {deep: true}
    );

    onBeforeRouteLeave((to, from, next) => {
      if (isJourneyModified.value && confirmUnsavedChanges.value) {
        confirmUnsavedChanges.value = false;
        next();
      }
      else {
        next();
      }
    });

    onMounted(async () => {

      if(router.currentRoute._value.name === 'JourneyReusableTemplateMap') {
        isJourneyReusableTemplatePage.value = true;
        S3BucketFolderName.value = 'journey_reusable_templates';
      }

      if (isJourneyReusableTemplatePage.value) {
        await store.dispatch('journeyReusableTemplates/listReusableTemplates');
        if (currentUserRoles.value.TenantAdmin) {
          await store.dispatch('journeyReusableTemplates/list');
        }
      }

      await initJourneyDetails();

      if (!isJourneyReusableTemplatePage.value) {
        isCurrentUserAuthorOfJourney.value = (journeyDetails.value.ID === null || journeyDetails.value.IsCurrentUserAuthor || journeyDetails.value.IsCurrentUserCoAuthor);
      }
      else {
        isCurrentUserAuthorOfJourney.value = false;
      }

      if (isCurrentUserAuthorOfJourney.value) {
        await store.dispatch('journeyCategory/list');
      }

      await store.dispatch('loading/setLoading', false);

      if (isCreateNew()) {
        isEditMode.value = true;
        // await store.dispatch('journeyReusableTemplates/listReusableTemplates');
      }

      firstPageEnter.value = false;

      hasContentLoaded.value = true;

      // if (isCurrentUserAuthorOfJourney.value) {
      //   initEditorJS();
      // }

    });

    return {
      hasContentLoaded,
      journeyDetails,
      isCurrentUserAuthorOfJourney,
      isEditMode,
      isSaveDisabled,
      isUnsavedChangesModalOpened,
      titleModal,
      messageModal,
      activeModule,
      journeyModules,
      S3BucketFolderName,
      isCreateNew,
      goToJourneyView,
      isParticipantFirstEnterJourney,
      toggleIsEditModeHandler,
      saveClickHandler,
      goToJourneyActionHandler,
      confirmUnsavedChangesHandler,
      cancelUnsavedChangesHandler,
    }
  },
}

</script>

<style lang="scss" scoped>

#journey-map {
  .icon.edit {
    font-size: 25px;
    align-self: flex-start;
  }

  .tabs-wrapper {
    padding: 0 20px;
  }

  #modules {
    display: flex;
    flex-direction: row;

    .module_content-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .module-content {
        display: flex;
        align-items: center;
        gap: 50px;
      }

      .circle-wrapper {
        position: relative;
        margin: 0 20px;
        height: 100%;

        .circle {
          width: 40px;
          height: 40px;
          border: 2px solid var(--primary-color);
          border-radius: 50%;
          color: white;
          background-color: var(--primary-color);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-top: 20px;

          .circle-element {
            font-size: 18px;
          }
        }

        &:not(:last-child)::after {
          content: "";
          position: absolute;
          top: calc(50% + 20px); //because of margin 20px from parent to align with content on the right side
          left: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 100%;
          background-color: var(--primary-color);
          z-index: -1;
        }
      }

      .circle-wrapper:last-child .circle::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 2px;
        height: 50%;
        background-color: black;
        z-index: -1;
      }
    }
  }

  .bottom_buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  #journey-map {
    .tabs-wrapper {
      padding: 10px;
    }

    #modules {
      .module_content-wrapper {
        .module-content {
          justify-content: center;
          gap: 15px;
        }

        .circle-wrapper {
          margin: 0;

          //.circle {
          //  width: 35px;
          //  height: 35px;
          //
          //  .circle-element {
          //    font-size: 16px;
          //  }
          //}
        }
      }
    }
  }
}

</style>

<style lang="scss">

#journey-map {
  .overview_wrapper {
      .element {
        margin: 20px 0;

        img {
          width: 75%;
        }

        ol {
          list-style: none;
          width: 600px;
          max-width: 90%;

          li {
            display: block;
            clear: both;
            counter-increment: list;
            position: relative;
            margin: 0 0 10px 0;

            &::before {
              content: counter(list);
              width: 30px;
              height: 30px;
              margin-right: 20px;
              color: #fdfdfd;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              shape-outside: ellipse();
              z-index: 1;
            }

            &::after {
              width: 15px;
              height: 15px;
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              z-index: -1;
              border-top-left-radius: 1px;
            }
          }
        }

        .checklist {
          background-color: #eee;
          width: 100px;
          height: 180px;
          border-radius: 10px;
          position: relative;
          box-shadow: 0 10px 30px rgba(65, 72, 86, 0.05);
          padding: 30px 85px;
          display: grid;
          align-items: center;
          justify-content: center;

          .checkbox-wrapper {
            display: flex;
            align-items: center;

            input {
              -webkit-appearance: none;
              -moz-appearance: none;
              position: relative;
              height: 15px;
              width: 15px;
              cursor: pointer;
              background: white;
              display: grid;
              align-items: center;
              margin: 0 20px 0 0;

              &::before,
              &::after {
                content: "";
                position: absolute;
                height: 2px;
                top: auto;
                background: green;
                border-radius: 2px;
              }

              &::before {
                width: 0;
                right: 60%;
                transform-origin: right bottom;
              }

              &::after {
                width: 0;
                left: 40%;
                transform-origin: left bottom;
              }

              &:checked {
                background-color: white;
              }

              &:checked::before {
                animation: check-01 0.4s ease forwards;
              }

              &:checked::after {
                animation: check-02 0.4s ease forwards;
              }

              &:checked + label,
              &:checked + label mark {
                color: grey;
                animation: move 0.3s ease 0.1s forwards;
              }

              &:checked + label {
                text-decoration: line-through;
              }

              @keyframes move {
                50% {
                  padding-left: 8px;
                  padding-right: 0;
                }

                100% {
                  padding-right: 4px;
                }
              }

              @keyframes slice {
                60% {
                  width: 100%;
                  left: 40px;
                }

                100% {
                  width: 100%;
                  left: -20px;
                  padding-left: 0;
                }
              }

              @keyframes check-01 {
                0% {
                  width: 4px;
                  top: auto;
                  transform: rotate(0);
                }

                50% {
                  width: 0;
                  top: auto;
                  transform: rotate(0);
                }

                51% {
                  width: 0;
                  top: 8px;
                  transform: rotate(45deg);
                }

                100% {
                  width: 5px;
                  top: 8px;
                  transform: rotate(45deg);
                }
              }

              @keyframes check-02 {
                0% {
                  width: 4px;
                  top: auto;
                  transform: rotate(0);
                }

                50% {
                  width: 0;
                  top: auto;
                  transform: rotate(0);
                }

                51% {
                  width: 0;
                  top: 8px;
                  transform: rotate(-45deg);
                }

                100% {
                  width: 10px;
                  top: 8px;
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }

        .call-out {
          border: 1px solid rgb(255, 135, 135);
          padding: 8px 16px;
          border-radius: 6px;
          box-shadow: 0 0 0 5px rgba(255, 120, 84, 0.16);
        }

        .citation {
          font-style: italic;
        }

        .details {
          font-size: 0.84em;
        }

        .quote {
          position: relative;
          width: 100%;
          margin: auto;
          padding: 1rem;
          background-color: transparent;
          background-position: top left, 0.2em 0.2em;
          background-repeat: no-repeat;
          filter: drop-shadow(0 0 15px #0005);

          &::before,
          &::after {
            position: absolute;
            z-index: -1;
            display: block;
            width: 3rem;
            height: 4rem;
            content: "";
            font-size: 90px;
            font-family: Georgia, Times, Garamond, serif;
          }

          &::before {
            top: -2.5rem;
            left: 0.5rem;
            content: open-quote;
          }

          &::after {
            bottom: -1rem;
            right: 0;
            content: close-quote;
          }

          &::before,
          &::after,
          & blockquote {
            text-shadow: -2px 2px #fff, -1.5px 1.5px #fff, -1px 1px #fff, -0.5px 0.5px #fff;
          }

          & figcaption,
          & blockquote {
            margin: 20px;
          }

          & figcaption {
            text-align: right;
          }
        }

        .delimiter {
          text-align: center;

          &:before {
            display: inline-block;
            content: "***";
            font-size: 30px;
            line-height: 65px;
            height: 30px;
            letter-spacing: 0.2em;
          }
        }
      }
    }

  .editorx_body {
    width: 100%;
    padding: 10px 30px;

    .ce-toolbar {
      .ce-toolbar__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;

        .ce-toolbar__actions {
          left: -50px;
          right: unset;

          .ce-toolbar__settings-btn {
            background-color: #6b6868c7;
            border-radius: 5px;

            svg {
              fill: #fff;
            }
          }
        }
      }
    }

    .ce-block {
      &__content {
        max-width: 100%;
      }
    }
  }
}

</style>
