<template>
  <footer class="footer mt-auto py-3 bg-light">
    <div class="container">
      <span class="text-muted"
        >Copyright © {{ year }} <span class="text-uppercase">commovis</span>® by Rupert Seewald GmbH & Co. KG</span>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
footer {
  font-size: 0.6em;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
