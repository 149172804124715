<template>
  <div v-if="hasContentLoaded" id="profile">
    <div style="min-height: 100px" class="row justify-content-between align-items-center">

      <ImageUpload class="image-upload"
                   cropperType="Circle"
                   folderName="profile"
                   :src="currentUserInfo.PictureURL"
                   type="onImage"
                   :hasPreview="true"
                   :isRoundedPreview="true"
                   title="Profile Picture"
                   description="PNG or JPG no bigger than 9MB."
                   @pictureReady="pictureReadyHandler($event)"
      />

    </div>
    <!-- / .row -->
    <form @submit.prevent="onSubmit">
      <!-- Divider -->
      <hr class="my-5" />

      <div v-if="isProfileLoading">Loading Profile ...</div>
      <div v-else class="row">

<!--        First Name-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label for="given_name" class="form-label">
              First Name
            </label>

            <!-- Input -->
            <input
                id="given_name"
                name="given_name"
                type="text"
                v-model="given_name"
                class="form-control"
                :class="[given_nameError ? 'is-invalid' : '']"
            />
            <div class="invalid-feedback">{{ given_nameError }}</div>
          </div>
        </div>
<!--        Last Name-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label for="family_name" class="form-label">
              Last Name
            </label>

            <!-- Input -->
            <input
                id="family_name"
                name="family_name"
                type="text"
                v-model="family_name"
                class="form-control"
                :class="[family_nameError ? 'is-invalid' : '']"
            />
            <div class="invalid-feedback">{{ family_nameError }}</div>
          </div>
        </div>

<!--        Email Address-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              E-Mail
            </label>

            <!-- Form text -->
            <!-- <small class="form-text text-muted">
                        This contact will be shown to others publicly, so choose
                        it carefully.
                      </small> -->

            <!-- Input -->
            <input
                type="email"
                class="form-control"
                :value="currentUserInfo.email"
                disabled
            />
          </div>
        </div>
<!--        Private Email-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Private E-Mail
            </label>
            <!-- Input -->
            <input
                id="private_email"
                name="private_email"
                type="text"
                v-model="private_email"
                :class="[private_emailError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ private_emailError }}</div>
          </div>
        </div>

<!--        Nickname-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Nickname
            </label>
            <!-- Input -->
            <input
                id="nickname"
                name="nickname"
                type="text"
                v-model="nickname"
                :class="[nicknameError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ nicknameError }}</div>
          </div>
        </div>
<!--        Gender-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Gender
            </label>
            <!-- Input -->
            <input
                id="gender"
                name="gender"
                type="text"
                v-model="gender"
                :class="[genderError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ genderError }}</div>
          </div>
        </div>

<!--        Phone Number-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              Mobile Phone
            </label>

            <!-- Input -->
            <input
                id="phone_number"
                name="phone_number"
                type="tel"
                v-model="phone_number"
                :class="[phone_numberError ? 'is-invalid' : '']"
                class="form-control mb-3"
                placeholder="+43____________"
            />
            <div class="invalid-feedback">{{ phone_numberError }}</div>
          </div>
        </div>
<!--        Address-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              Address
            </label>

            <!-- Input -->
            <input
                id="address"
                name="address"
                type="tel"
                v-model="address"
                :class="[addressError ? 'is-invalid' : '']"
                class="form-control mb-3"
            />
            <div class="invalid-feedback">{{ addressError }}</div>
          </div>
        </div>

<!--        Academic Degree-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Academic Degree
            </label>
            <!-- Input -->
            <input
                id="academic_degree"
                name="academic_degree"
                type="text"
                v-model="academic_degree"
                :class="[academic_degreeError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ academic_degreeError }}</div>
          </div>
        </div>
<!--        Job Title-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Job Title
            </label>
            <!-- Input -->
            <input
                id="job_title"
                name="job_title"
                type="text"
                v-model="job_title"
                :class="[job_titleError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ job_titleError }}</div>
          </div>
        </div>

<!--        Company-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Company
            </label>
            <!-- Input -->
            <input
                id="company"
                name="company"
                type="text"
                v-model="company"
                :class="[companyError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ companyError }}</div>
          </div>
        </div>
<!--        Position-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Position
            </label>
            <!-- Input -->
            <input
                id="position"
                name="position"
                type="text"
                v-model="position"
                :class="[positionError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ positionError }}</div>
          </div>
        </div>

<!--        Website-->
        <div class="col-12 mb-4">
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">
              Website
            </label>
            <!-- Input -->
            <input
                id="website"
                name="website"
                type="text"
                v-model="website"
                :class="[websiteError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ websiteError }}</div>
          </div>
        </div>

<!--        Bio-->
        <div class="col-12 mb-4">
          <div class="form-group">
            <label for="Bio">Bio</label>
            <textarea
                id="bio"
                name="bio"
                type="text"
                v-model="bio"
                :class="[bioError ? 'is-invalid' : '']"
                class="form-control"
                rows="3"
                placeholder="tell us something about yourself"
            ></textarea>
            <div class="invalid-feedback">{{ bioError }}</div>
          </div>
        </div>

        <!-- social -->
        <h3>Social</h3>

<!--        LinkedIn-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              LinkedIn
            </label>
            <!-- Input -->
            <input
                id="social_linkedIn"
                name="social_linkedIn"
                type="text"
                v-model="social_linkedIn"
                :class="[social_linkedInError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ social_linkedInError }}</div>
          </div>
        </div>
<!--        Instagram-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              Instagram
            </label>
            <!-- Input -->
            <input
                id="social_instagram"
                name="social_instagram"
                type="text"
                v-model="social_instagram"
                :class="[social_instagramError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ social_instagramError }}</div>
          </div>
        </div>

<!--        Facebook-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              Facebook
            </label>
            <!-- Input -->
            <input
                id="social_facebook"
                name="social_facebook"
                type="text"
                v-model="social_facebook"
                :class="[social_facebookError ? 'is-invalid' : '']"
                class="form-control"
            />
            <div class="invalid-feedback">{{ social_facebookError }}</div>
          </div>
        </div>
<!--        Other-->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <!-- Label -->
            <label class="form-label">
              Other
            </label>

            <!-- Input -->
            <input
                id="social_other"
                name="social_other"
                type="text"
                v-model="social_other"
                :class="[social_otherError ? 'is-invalid' : '']"
                class="form-control"
            />
          </div>
        </div>

        <div class="mb-5"></div>
      </div>
      <!-- / .row -->

      <!-- Button -->
      <button class="btn btn-primary">
        Save changes
      </button>

      <!-- <div class="row">
        <div class="col-12 col-md-6"> -->
      <!-- Public profile -->
      <!-- <div class="form-group"> -->
      <!-- Label -->
      <!-- <label class="mb-1">
              Public profile
            </label> -->

      <!-- Form text -->
      <!-- <small class="form-text text-muted">
              Making your profile public means that anyone on the
              {{ store.state.tenant.tenantinfo.title }} network will be able to
              find you.
            </small> -->

      <!-- <div class="row">
              <div class="col-auto">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="switchOne"
                    checked
                  />
                  <label class="form-check-label" for="switchOne"></label>
                </div>
              </div>
              <div class="col ms-n2"> -->
      <!-- Help text -->
      <!-- <small class="text-muted">
                  You're currently visible
                </small>
              </div>
            </div> -->
      <!-- / .row -->
      <!-- </div> -->
      <!-- </div>
        <div class="col-12 col-md-6"></div>
      </div> -->
      <!-- / .row -->

      <!-- Divider -->
      <hr class="mt-4 mb-5" />

      <div class="row justify-content-between">
        <div class="col-12 col-md-6">
          <!-- Heading -->
          <h4>
            Delete your account
          </h4>

          <!-- Text -->
          <p class="small text-muted mb-md-0">
            Please note, deleting your account is a permanent action and will no
            be recoverable once completed.
          </p>
        </div>
        <div class="col-auto">
          <!-- Button -->
          <button class="btn btn-danger">
            Delete
          </button>
        </div>
      </div>
      <!-- / .row -->
    </form>
  </div>
</template>

<script>
import {computed, onMounted, onUpdated, ref, watch} from 'vue'
import { useStore } from 'vuex'

import { useForm, useField } from 'vee-validate'
import {Auth} from "aws-amplify";
import ImageUpload from "@/components/upload/ImageUpload.vue";

export default {
  components: {
    ImageUpload,
  },

  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const currentUserInfo = ref({});

    currentUserInfo.value = store.state.auth.userInfo;

    store.dispatch('auth/getDeviceList')

    const isProfileLoading = computed(() => {
      return store.state.auth.isProfileLoading
    });

    const isUploadVisible = computed(() => store.getters["file/getIsUploadVisible"]);

    const pictureReadyHandler = async (ev) => {
      await store.dispatch('file/uploadFile', ev);
      await getCurrentAuthenticatedUser();
    };

    const showUpload = () => {
      //isUploadVisible.value = true
      store.commit('file/setUploadVisible', true)
    };

    const getCurrentAuthenticatedUser = async () => {

      try {

        let bearerDetails = undefined;
        let bearerDetailsBase64 = undefined;
        let commovisToken = undefined;

        // bypass needed to get updated data
        let currentAuthenticatedUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });

        const isAuthenticated = currentAuthenticatedUser !== null;

        await store.dispatch('auth/isAuthenticated', isAuthenticated);

        await store.dispatch('auth/changeAuthState', 'signedin');
        await store.dispatch('auth/setUserInfo', currentAuthenticatedUser.attributes);
        await store.dispatch('auth/getUserDetails', currentAuthenticatedUser);

        // create our own Bearer
        bearerDetails =
            store.state.tenant.tenantinfo.details.CId +
            '::' +
            store.state.tenant.tenantinfo.details.Id +
            '::' +
            store.state.tenant.tenantinfo.id

        bearerDetailsBase64 = btoa(bearerDetails);

        commovisToken =
            'Bearer ' +
            store.state.auth.user.signInUserSession.accessToken.jwtToken +
            '::::' +
            bearerDetailsBase64

        await store.dispatch('auth/getBearerDetails', commovisToken);

      }
      catch (err) {
        await store.dispatch('auth/isAuthenticated', false);
      }

    };

    //const isUploadVisible = ref(false)
    const profileSchema = {

      given_name(value) {
        const given_name = value
        if (undefined !== given_name && given_name.length >= 3) {
          return true
        }
        return 'minimum 3 characters'
      },
      family_name(value) {
        const family_name = value
        if (undefined !== family_name && family_name.length >= 3) {
          return true
        }
        return 'minimum 3 characters'
      },

      private_email(value) {
        const private_email = value
        if (
            undefined == private_email ||
            private_email.length >= 3 ||
            private_email.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      nickname(value) {
        const nickname = value
        if (
            undefined == nickname ||
            nickname.length >= 3 ||
            nickname.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      gender(value) {
        const gender = value
        if (
            undefined == gender ||
            gender.length >= 3 ||
            gender.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      phone_number(value) {
        let phone_number = ''
        if (undefined !== value && value.length > 0) {
          phone_number = value.trim()
        } else {
          phone_number = ''
        }
        var r = /^\+(?:[0-9] ?){6,14}[0-9]$/

        if (r.test(phone_number) || phone_number.length == 0) {
          return true
        } else {
          return 'please enter you mobile in an international format starting with an +'
        }
      },
      address(value) {
        const address = value
        if (
            undefined == address ||
            address.length >= 3 ||
            address.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      academic_degree(value) {
        const academic_degree = value
        if (
            undefined == academic_degree ||
            academic_degree.length >= 3 ||
            academic_degree.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      job_title(value) {
        const job_title = value
        if (
            undefined == job_title ||
            job_title.length >= 3 ||
            job_title.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      company(value) {
        const company = value
        if (
            undefined == company ||
            company.length >= 3 ||
            company.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      position(value) {
        const position = value
        if (
            undefined == position ||
            position.length >= 3 ||
            position.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      website(value) {
        const website = value
        if (
            undefined == website ||
            website.length >= 3 ||
            website.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      bio(value) {
        const bio = value
        if (
            undefined === bio ||
            (bio.length >= 3 && bio.length <= 255) ||
            bio.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      social_linkedIn(value) {
        const social_linkedIn = value

        if (
            undefined == social_linkedIn ||
            social_linkedIn.length >= 3 ||
            social_linkedIn.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      social_instagram(value) {
        const social_instagram = value
        if (
            undefined == social_instagram ||
            social_instagram.length >= 3 ||
            social_instagram.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },

      social_facebook(value) {
        const social_facebook = value
        if (
            undefined == social_facebook ||
            social_facebook.length >= 3 ||
            social_facebook.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      social_other(value) {
        const social_other = value
        if (
            undefined == social_other ||
            social_other.length >= 3 ||
            social_other.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      }
    };

    const { handleSubmit, setValues } = useForm({
      validationSchema: profileSchema,
      initialValues: {

        given_name: currentUserInfo.value.given_name,
        family_name: currentUserInfo.value.family_name,

        private_email: currentUserInfo.value.private_email,

        nickname: currentUserInfo.value.nickname,
        gender: currentUserInfo.value.gender,

        phone_number: currentUserInfo.value.phone_number,
        address: currentUserInfo.value.address,

        academic_degree: currentUserInfo.value.academic_degree,
        job_title: currentUserInfo.value.job_title,

        company: currentUserInfo.value.company,
        position: currentUserInfo.value.position,

        website: currentUserInfo.value.website,

        bio: currentUserInfo.value.bio,

        social_linkedIn: currentUserInfo.value.social_linkedIn,
        social_instagram: currentUserInfo.value.social_instagram,

        social_facebook: currentUserInfo.value.social_facebook,
        social_other: currentUserInfo.value.social_other,
      }
    })

    const { value: given_name, errorMessage: given_nameError } = useField('given_name');
    const { value: family_name, errorMessage: family_nameError } = useField('family_name');

    const { value: private_email, errorMessage: private_emailError } = useField('private_email');

    const { value: nickname, errorMessage: nicknameError } = useField('nickname');
    const { value: gender, errorMessage: genderError } = useField('gender');

    const { value: phone_number, errorMessage: phone_numberError } = useField('phone_number');
    const { value: address, errorMessage: addressError } = useField('address');

    const { value: academic_degree, errorMessage: academic_degreeError } = useField('academic_degree');
    const { value: job_title, errorMessage: job_titleError } = useField('job_title');

    const { value: company, errorMessage: companyError } = useField('company');
    const { value: position, errorMessage: positionError } = useField('position');

    const { value: website, errorMessage: websiteError } = useField('website');

    const { value: bio, errorMessage: bioError } = useField('bio');

    const { value: social_linkedIn, errorMessage: social_linkedInError } = useField('social_linkedIn');
    const { value: social_instagram, errorMessage: social_instagramError } = useField('social_instagram');

    const { value: social_facebook, errorMessage: social_facebookError } = useField('social_facebook');
    const { value: social_other, errorMessage: social_otherError } = useField('social_other');

    const onSubmit = handleSubmit( async(values) => {
      //NULL values aren't allowed
      let check_values = {}
      for (var prop in values) {
        if (values[prop] == undefined) {
          check_values[prop] = ''
        } else {
          check_values[prop] = values[prop]
        }
      }

      await store.dispatch('loading/setLoading', true);
      // dispatch with a payload
      await store.dispatch('auth/setUserDetails', check_values);

      await getCurrentAuthenticatedUser();

    });

    const isProfilePictureSet = () => {

      return ( currentUserInfo.value !== null
          && currentUserInfo.value !== undefined
          && currentUserInfo.value.hasOwnProperty('picture')
          && currentUserInfo.value.picture !== null
          && currentUserInfo.value.picture !== undefined
          && currentUserInfo.value.picture !== '' );
    }

    const getProfilePictureURL = async() => {
      await getCurrentAuthenticatedUser();
      currentUserInfo.value = store.state.auth.userInfo;
      if (isProfilePictureSet()) {
        currentUserInfo.value.PictureURL = await store.dispatch('file/getFile', {
          file_name: currentUserInfo.value.picture,
          folder_name: 'profile'
        });
      }
    };

    watch(() => store.state.auth.userInfo.picture, (newValue, oldValue) => {
      getProfilePictureURL();
    });

    onMounted(async() => {
      await getProfilePictureURL();
      hasContentLoaded.value = true;
    });

    return {
      hasContentLoaded,
      currentUserInfo,
      store,
      isProfileLoading,
      onSubmit,

      given_name,
      given_nameError,
      family_name,
      family_nameError,

      private_email,
      private_emailError,

      nickname,
      nicknameError,
      gender,
      genderError,

      phone_number,
      phone_numberError,
      address,
      addressError,

      academic_degree,
      academic_degreeError,
      job_title,
      job_titleError,

      company,
      companyError,
      position,
      positionError,

      website,
      websiteError,

      bio,
      bioError,

      social_linkedIn,
      social_linkedInError,
      social_instagram,
      social_instagramError,

      social_facebook,
      social_facebookError,
      social_other,
      social_otherError,

      // getPicture,
      isUploadVisible,
      showUpload,
      isProfilePictureSet,
      pictureReadyHandler,
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
