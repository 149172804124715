<template>

  <CentralLayout>

    <template v-slot:content>

      <div v-if="hasContentLoaded" id="journey_creation-page" class="content">

        <transition name="slide">

          <div :style="{'max-height': maxHeight + 'px', 'min-height': maxHeight + 'px' }"
               class="progress-sidebar-wrapper"
               v-if="isSidebarDisplayed"
          >
            <ProgressSidebar :isCreation="true"
                             :isSaveDisabled="isSaveDisabled"
                             :isStartBlockDrag="isStartBlockDrag"
                             :journeyModules="journeyModules"
                             @goToJourneyAction="goToJourneyActionHandler"
                             @save="saveClickHandler()"
            />
          </div>

        </transition>

        <div class="journey-editor-wrapper">

          <div class="journey-editor" :style="{'max-height': maxHeight + 'px'}">

            <div class="title-wrapper">

              <div class="menu-icon" v-if="!isSidebarDisplayed"
                   :style="{ 'color': store.state.tenant.tenantinfo.primarycolor, 'borderColor': store.state.tenant.tenantinfo.primarycolor }"
                   @click="toggleSidebar()">

                <font-awesome-icon icon="list-ul"/>

              </div>

              <div class="title" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">
                {{journeyDetails.Name }}
              </div>

            </div>

            <div v-for="(structureItem, indexStructureItem) in journeyDetails.Structure"
                 :key="indexStructureItem"
                 class="structure-item">


              <div v-if="structureItem.Type === 'Chapter' && activeStructureItem.structureItemID === structureItem.ID" class="chapter-wrapper">

<!--               edit chapter-->
                <JourneyChapter v-if="activeStructureItem.indexUnit === null"
                                :structureItemID="structureItem.ID"
                                :indexStructureItem="indexStructureItem"
                />

<!--               edit unit-->
                <div v-for="(unit, indexUnit) in structureItem.Units"
                     :key="indexUnit"
                     class="unit">

                  <div v-if="activeStructureItem.indexUnit === indexUnit"
                      class="unit-wrapper">

                    <div class="breadcrumbs">
                      <span>{{structureItem.Name }} / {{unit.Name }}</span>
                    </div>

                    <draggable v-model="unit.Blocks"
                               class="unit-blocks_wrapper"
                               item-key="ID"
                               handle=".block-handle"
                               :group="{ name: 'blocks' }"
                               :move="checkBlockMove"
                               @end="isStartBlockDrag = false"
                    >

                      <template #item="{ element: block, index: indexBlock }">

                        <div class="block-editor_wrapper">

                          <div class="actions">

                            <div class="icon bars-staggered block-handle"
                                 @mousedown="isStartBlockDrag = true"
                                 @mouseup="isStartBlockDrag = false"
                            >
                              <font-awesome-icon
                                  icon="bars-staggered"
                              />
                            </div>

                            <div v-if="hasUnitMultipleBlocks(indexStructureItem, indexUnit)"
                                 class="icon"
                                 title="Delete block"
                                 @click="deleteBlockHandler(structureItem.ID, indexUnit, indexBlock)"
                            >
                              <font-awesome-icon icon="trash"/>
                            </div>

                          </div>

                          <BlockEditor :structureItemID="structureItem.ID"
                                         :indexStructureItem="indexStructureItem"
                                         :indexUnit="indexUnit"
                                         :indexBlock="indexBlock"
                                         :reusableImages="reusableImages"
                                         :isStartBlockDrag="isStartBlockDrag"
                                         @editorDestroyComplete="editorDestroyCompleteHandler($event)"
                          />

                        </div>

                      </template>

                    </draggable>

                    <div class="icon add-block"
                         title="Add block"
                         @click="addBlock()">

                      <font-awesome-icon icon="plus"/>

                    </div>

                    <div class="separator"></div>

                    <div v-if="activeStructureItem.indexUnit === indexUnit"
                         class="icon add-asset"
                         title="Add asset"
                         @click="openEditLinkedAssetsModal()">

                      <font-awesome-icon icon="paperclip"/>

                    </div>

                    <transition name="fade">

                      <div v-show="isEditLinkedAssetsDisplayed" class="custom_modal edit-linked-assets-modal">

                        <div class="custom_modal__content">

                          <Tabs>

                            <Tab title="Expedition Logs">

                              <div class="expedition_logs_wrapper">
                                <ExpeditionLogCards v-if="isEditLinkedAssetsDisplayed"
                                                    :isDisplayAll="true"
                                                    :isCheckmarkDisplayed="true"
                                                    :checkedItems="getCheckedExpeditionLogs(unit.LinkedAssets)"
                                                    :structureItemID="structureItem.ID"
                                                    :indexStructureItem="indexStructureItem"
                                                    :indexUnit="indexUnit"
                                                    :isEditJourneyStructure="true"
                                                    :assets="unit.LinkedAssets"
                                />
                              </div>

                            </Tab>

                            <Tab title="Memory Cards">

                              <div class="memory-cards_wrapper">
                                <MemoryCards :displayAllMemoryCards="true"
                                             :isCheckmarkDisplayed="true"
                                             :checkedMemoryCards="getCheckedMemoryCards(unit.LinkedAssets)"
                                             :structureItemID="structureItem.ID"
                                             :indexStructureItem="indexStructureItem"
                                             :indexUnit="indexUnit"
                                             :isEditJourneyStructure="true"
                                             :assets="unit.LinkedAssets"
                                             @addAssetTags="addAssetTagsHandler"
                                             @deleteAssetTags="deleteTagHandler"
                                />
                              </div>

                            </Tab>

                            <Tab title="Attachments">
                              <div class="unit-items">
                                <AttachmentUpload :structureItemID="structureItem.ID"
                                                  :indexStructureItem="indexStructureItem"
                                                  :indexUnit="indexUnit"
                                                  :isEdit="true"
                                                  @addAssetTags="addAssetTagsHandler"
                                                  @deleteTag="deleteTagHandler"
                                />
                              </div>
                            </Tab>

                          </Tabs>

                        </div>

                        <div class="custom_modal add-tags-assets" v-if="isAddTagsVisible">

                          <div class="add-tags">

                            <span class="add-tags-title">Add Asset Tags</span>

                            <input
                                type="text"
                                v-model="newTagInput"
                                placeholder="Enter new tag"
                                @click.stop
                            >

                            <span>or</span>

                            <select
                                v-model="selectedTag"
                                @click.stop
                            >
                              <option value="" disabled selected>Select existing tag</option>

                              <option v-for="(tag, index) in allTags" :value="tag" @click.stop>
                                {{ tag }}
                              </option>

                            </select>

                            <button class="btn btn-primary" @click.stop="handleAddAssetTag(indexStructureItem, indexUnit)">Add</button>

                          </div>

                          <div class="backdrop" @click="toggleAddAssetTags()"></div>

                        </div>

                        <div class="backdrop" @click="closeEditLinkedAssetsModal()"></div>

                      </div>

                    </transition>

                    <div v-if="activeStructureItem.indexUnit === indexUnit && unit.LinkedAssets.length > 0" class="existing-assets">

                      <LinkedAssets :assets="unit.LinkedAssets"/>

                    </div>

                  </div>

                </div>

              </div>

              <div v-if="structureItem.Type === 'Event' && activeStructureItem.structureItemID === structureItem.ID"
                   class="journey-event-wrapper">

                <JourneyEvent
                    :structureItemID="structureItem.ID"
                    :indexStructureItem="indexStructureItem"
                    :reusableImages="reusableImages"
                />

                <div class="separator"></div>

                <div class="icon add-asset"
                     title="Add asset"
                     @click="openEditLinkedAssetsModal()">

                  <font-awesome-icon icon="paperclip"/>

                </div>

                <transition name="fade">

                  <div v-show="isEditLinkedAssetsDisplayed" class="custom_modal edit-linked-assets-modal">

                    <div class="custom_modal__content">

                      <Tabs>

                        <Tab title="Expedition Logs">

                          <div class="expedition_logs_wrapper">
                            <ExpeditionLogCards v-if="isEditLinkedAssetsDisplayed"
                                                :isDisplayAll="true"
                                                :isCheckmarkDisplayed="true"
                                                :checkedItems="getCheckedExpeditionLogs(structureItem.LinkedAssets)"
                                                :structureItemID="structureItem.ID"
                                                :indexStructureItem="indexStructureItem"
                                                :indexUnit="null"
                                                :isEditJourneyStructure="true"
                                                :assets="structureItem.LinkedAssets"
                            />
                          </div>

                        </Tab>

                        <Tab title="Memory Cards">

                          <div class="memory-cards_wrapper">
                            <MemoryCards :displayAllMemoryCards="true"
                                         :isCheckmarkDisplayed="true"
                                         :checkedMemoryCards="getCheckedMemoryCards(structureItem.LinkedAssets)"
                                         :structureItemID="structureItem.ID"
                                         :indexStructureItem="indexStructureItem"
                                         :indexUnit="null"
                                         :isEditJourneyStructure="true"
                                         :assets="structureItem.LinkedAssets"
                                         @addAssetTags="addAssetTagsHandler"
                                         @deleteAssetTags="deleteTagHandler"
                            />
                          </div>

                        </Tab>

                        <Tab title="Attachments">
                          <div class="unit-items">
                            <AttachmentUpload :structureItemID="structureItem.ID"
                                              :indexStructureItem="indexStructureItem"
                                              :indexUnit="null"
                                              :isEdit="true"
                                              @addAssetTags="addAssetTagsHandler"
                                              @deleteTag="deleteTagHandler"
                            />
                          </div>
                        </Tab>

                      </Tabs>

                    </div>

                    <div class="custom_modal add-tags-assets" v-if="isAddTagsVisible">

                      <div class="add-tags">

                        <span class="add-tags-title">Add Asset Tags</span>

                        <input
                            type="text"
                            v-model="newTagInput"
                            placeholder="Enter new tag"
                            @click.stop
                        >

                        <span>or</span>

                        <select
                            v-model="selectedTag"
                            @click.stop
                        >
                          <option value="" disabled selected>Select existing tag</option>

                          <option v-for="(tag, index) in allTags" :value="tag" @click.stop>
                            {{ tag }}
                          </option>

                        </select>

                        <button class="btn btn-primary" @click.stop="handleAddAssetTag(indexStructureItem)">Add</button>

                      </div>

                      <div class="backdrop" @click="toggleAddAssetTags()"></div>

                    </div>

                    <div class="backdrop" @click="closeEditLinkedAssetsModal()"></div>

                  </div>

                </transition>

                <div v-if="structureItem.LinkedAssets.length > 0" class="existing-assets">

                  <LinkedAssets :assets="structureItem.LinkedAssets"/>

                </div>

              </div>

              <div v-if="structureItem.Type === 'Assignment' && activeStructureItem.structureItemID === structureItem.ID"
                   class="journey-assignment-wrapper">

                <JourneyAssignment
                    :structureItemID="structureItem.ID"
                    :indexStructureItem="indexStructureItem"
                    :reusableImages="reusableImages"
                />

                <div class="separator"></div>


                <div class="icon add-asset"
                     title="Add asset"
                     @click="openEditLinkedAssetsModal()">

                  <font-awesome-icon icon="paperclip"/>

                </div>

                <transition name="fade">

                  <div v-show="isEditLinkedAssetsDisplayed" class="custom_modal edit-linked-assets-modal">

                    <div class="custom_modal__content">

                      <Tabs>

                        <Tab title="Expedition Logs">

                          <div class="expedition_logs_wrapper">
                            <ExpeditionLogCards v-if="isEditLinkedAssetsDisplayed"
                                                :isDisplayAll="true"
                                                :isCheckmarkDisplayed="true"
                                                :checkedItems="getCheckedExpeditionLogs(structureItem.LinkedAssets)"
                                                :structureItemID="structureItem.ID"
                                                :indexStructureItem="indexStructureItem"
                                                :indexUnit="null"
                                                :isEditJourneyStructure="true"
                                                :assets="structureItem.LinkedAssets"
                            />
                          </div>

                        </Tab>

                        <Tab title="Memory Cards">

                          <div class="memory-cards_wrapper">
                            <MemoryCards :displayAllMemoryCards="true"
                                         :isCheckmarkDisplayed="true"
                                         :checkedMemoryCards="getCheckedMemoryCards(structureItem.LinkedAssets)"
                                         :structureItemID="structureItem.ID"
                                         :indexStructureItem="indexStructureItem"
                                         :indexUnit="null"
                                         :isEditJourneyStructure="true"
                                         :assets="structureItem.LinkedAssets"
                                         @addAssetTags="addAssetTagsHandler"
                                         @deleteAssetTags="deleteTagHandler"
                            />
                          </div>

                        </Tab>

                        <Tab title="Attachments">
                          <div class="unit-items">
                            <AttachmentUpload :structureItemID="structureItem.ID"
                                              :indexStructureItem="indexStructureItem"
                                              :indexUnit="null"
                                              :isEdit="true"
                                              @addAssetTags="addAssetTagsHandler"
                                              @deleteTag="deleteTagHandler"
                            />
                          </div>
                        </Tab>

                      </Tabs>

                    </div>

                    <div class="custom_modal add-tags-assets" v-if="isAddTagsVisible">

                      <div class="add-tags">

                        <span class="add-tags-title">Add Asset Tags</span>

                        <input
                            type="text"
                            v-model="newTagInput"
                            placeholder="Enter new tag"
                            @click.stop
                        >

                        <span>or</span>

                        <select
                            v-model="selectedTag"
                            @click.stop
                        >
                          <option value="" disabled selected>Select existing tag</option>

                          <option v-for="(tag, index) in allTags" :value="tag" @click.stop>
                            {{ tag }}
                          </option>

                        </select>

                        <button class="btn btn-primary" @click.stop="handleAddAssetTag(indexStructureItem)">Add</button>

                      </div>

                      <div class="backdrop" @click="toggleAddAssetTags()"></div>

                    </div>

                    <div class="backdrop" @click="closeEditLinkedAssetsModal()"></div>

                  </div>

                </transition>

                <div v-if="structureItem.LinkedAssets.length > 0" class="existing-assets">

                  <LinkedAssets :assets="structureItem.LinkedAssets"/>

                </div>

              </div>

            </div>

          </div>

          <transition name="fade">

            <div class="backdrop-sidebar" v-if="isSidebarDisplayed && isMobileOrLaptopScreen" @click="toggleSidebar()"></div>

          </transition>

        </div>

      </div>

      <ConfirmationModal :isOpened="isUnsavedChangesModalOpened"
                         :title="titleModal"
                         :message="messageModal"
                         @confirm="confirmUnsavedChangesHandler"
                         @cancel="cancelUnsavedChangesHandler"
      />

    </template>

  </CentralLayout>

</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import { useStore } from 'vuex';
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import {provide} from "vue";
import _ from "lodash";
import $ from "jquery";
import draggable from "vuedraggable";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import CentralLayout from '@/layouts/CentralLayout';
import BlockEditor from "@/components/journey/Creation/BlockEditor.vue";
import ProgressSidebar from "@/components/journey/ProgressSidebar.vue";
import JourneyEvent from "@/components/journey/Creation/JourneyEvent.vue";
import JourneyChapter from "@/components/journey/Creation/JourneyChapter.vue";
import JourneyAssignment from "@/components/journey/JourneyAssignment.vue";
import MemoryCards from "@/components/common/MemoryCards.vue";
import MemoryCard from "@/components/common/MemoryCard.vue";
import Tab from "@/components/common/Tab.vue";
import Tabs from "@/components/common/Tabs.vue";
import AttachmentUpload from "@/components/journey/AttachmentUpload.vue";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import LinkedAssets from "@/components/journey/LinkedAssets.vue";
import ExpeditionLogCards from "@/components/common/ExpeditionLogCards.vue";
import ExpeditionLogCard from "@/components/common/ExpeditionLogCard.vue";
import {v4 as uuidV4} from "uuid";

export default {

  name: 'JourneyCreation',

  components: {
    LinkedAssets,
    ConfirmationModal,
    FontAwesomeIcon,
    draggable,
    ProgressSidebar,
    CentralLayout,
    BlockEditor,
    JourneyEvent,
    JourneyChapter,
    JourneyAssignment,
    MemoryCards,
    MemoryCard,
    Tabs,
    Tab,
    AttachmentUpload,
    ExpeditionLogCards,
    ExpeditionLogCard,
  },

  setup(props, {emit}) {

    const isMobileOrLaptopScreen = ref(false);

    const imagesCounter = ref(0);

    const watchCounter = ref(0);

    const store = useStore();

    const router = useRouter();

    const route = useRoute();

    const hasContentLoaded = ref(false);

    const isSaveDisabled = ref(true);

    const firstPageEnter = ref(true);

    const journeyDetails = ref({});

    const journeyModules = ref([]);

    const isJourneyModified = ref(false);

    const isUnsavedChangesModalOpened = ref(false);

    const confirmUnsavedChanges = ref(false);

    const titleModal = ref('Warning');

    const messageModal = ref('There are unsaved changes. Are you sure you want to leave this page?');

    const actionToTriggerAfterUnsavedChanges = ref('');

    const activeStructureItem = ref({
      structureItemID: null,
      indexUnit: null,
    });

    const isCurrentUserAuthorOfJourney = ref(false);

    // const isSaveClicked = ref(false);

    // const isDeleteChapterClicked = ref(false);

    // const isDeleteUnitClicked = ref(false);

    const isSidebarDisplayed = ref(true);

    const reusableImages = ref([]);

    const isEditLinkedAssetsDisplayed = ref(false);

    const isStartBlockDrag = ref(false);

    const isAddTagsVisible = ref(false);

    const newTagInput = ref('');

    const selectedTag = ref('');

    const indexAssetToAddTag = ref(null);

    const maxHeight = computed(() => store.getters["layout/getContainerMaxHeight"]);

    const userJourneys = computed(() => store.getters["journey/getUserJourneys"]);

    const allTags = computed(() => store.getters["userPreferences/getTags"]);

    // unit specific

    const isDeleteBlockClicked = ref(false);
    const deletedBlockIndex = ref(null);

    const allDisplayedBlocksIndexes = ref([]);

    const hasUnitMultipleBlocks = (indexStructureItem, indexUnit) => {
      let numberOfBlocks = journeyDetails.value.Structure[indexStructureItem].Units[indexUnit].Blocks.length;
      return numberOfBlocks > 1;
    };

    const addBlock = () => {

      let selectedStructureItemIndex = journeyDetails.value.Structure.findIndex((structureItem) => structureItem.ID === activeStructureItem.value.structureItemID);

      journeyDetails.value.Structure[selectedStructureItemIndex].Units[activeStructureItem.value.indexUnit].Blocks.push({
        Time: null,
        Elements: [],
        Version: null
      });
    };

    const deleteBlockHandler = (structureItemID, indexUnit, indexBlock) => {

      store.dispatch('loading/setLoading', true);

      isDeleteBlockClicked.value = true;
      deletedBlockIndex.value = indexBlock;

      let selectedStructureItemIndex = journeyDetails.value.Structure.findIndex((structureItem) => structureItem.ID === structureItemID);

      if (activeStructureItem.value.structureItemID === structureItemID && activeStructureItem.value.indexUnit === indexUnit) {
        allDisplayedBlocksIndexes.value = [];
        let selectedJourneyUnitBlocks = journeyDetails.value.Structure[selectedStructureItemIndex].Units[indexUnit].Blocks;
        selectedJourneyUnitBlocks.forEach((block) => {
          allDisplayedBlocksIndexes.value.push(false);
        });
        resetActiveStructureItem();
      }
    };

    const checkBlockMove = (ev) => {

      // don't allow block to be moved if is last block of unit
      let parentListRemainingNumberOfBlocks = ev.from.__draggable_component__.modelValue.length - 1;

      return parentListRemainingNumberOfBlocks > 0;
    };

    // end of unit specific

    const convertIsoStringToLocalDateTime = (dateTime) => {
      if (dateTime !== undefined && dateTime !== null && dateTime !== '') {
        const date = new Date(dateTime);
        // Get the date and time components
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Format the datetime-local string
        const datetimeLocalString = `${year}-${month}-${day}T${hours}:${minutes}`;

        return datetimeLocalString;
      }
      return null;
    };


    const checkJourneysChaptersUnlock = async() => {
      let payload = {
        JourneysIDs: [route.params.id],
      }
      await store.dispatch('journey/checkJourneysChaptersUnlock', payload);
    };

    const initJourneyDetails = async () => {

      let editJourneyID = route.params.id;
      let selectedJourneyWithRef = userJourneys.value.find((journey) => journey.ID === editJourneyID);

      if (selectedJourneyWithRef !== undefined) {

        await store.dispatch('userPreferences/updateLastJourneyID', {
          LastJourneyID: editJourneyID,
        });

        // lose reference from the journey in state
        let selectedJourney = JSON.parse(JSON.stringify(selectedJourneyWithRef));

        let activeModuleToAddChaptersIndex = null;

        for (const structureItem of selectedJourney.Structure) {

          if (structureItem.Type === 'Chapter') {

            // set modules
            if (activeModuleToAddChaptersIndex !== null) {
              journeyModules.value[activeModuleToAddChaptersIndex].Chapters.push(structureItem);
            }

            // add to every chapter the last dummy unit used to add units
            if (structureItem.hasOwnProperty('Units')) {

              let unitObject = {
                ID: uuidV4(),
                Name: '',
                LinkedAssets: [],
                Blocks: [{
                  Elements: [],
                  Time: null,
                  Version: null
                }],
                Temp: true,
              };

              structureItem.Units.push(unitObject);

            }

          }

          if (structureItem.Type === 'Event') {

            if (structureItem.Options.IsUnlock && structureItem.Periods.Unlock !== null) {

              structureItem.Periods.Unlock = convertIsoStringToLocalDateTime(structureItem.Periods.Unlock);

            }

            if (structureItem.Options.IsMeeting && structureItem.Periods.From !== null) {
              structureItem.Periods.From = convertIsoStringToLocalDateTime(structureItem.Periods.From);
            }

            if (structureItem.Options.IsMeeting && structureItem.Periods.To !== null) {
              structureItem.Periods.To = convertIsoStringToLocalDateTime(structureItem.Periods.To);
            }

            // set modules
            if(structureItem.Options.IsModule) {
              let eventItem = JSON.parse(JSON.stringify(structureItem));
              eventItem.Chapters = [];
              journeyModules.value.push(eventItem);
              activeModuleToAddChaptersIndex = journeyModules.value.length - 1;
            }

          }

        }

        journeyDetails.value = selectedJourney;

        journeyDetails.value.ExistingAssetsToDelete = [];

      } else {
        await router.push({
          name: '404'
        })
      }

    };

    const editorDestroyCompleteHandler = (path) => {

      if (isDeleteBlockClicked.value) {
        allDisplayedBlocksIndexes.value[path.indexBlock] = true;
        if (!allDisplayedBlocksIndexes.value.includes(false)) {
          journeyDetails.value.Structure[path.indexStructureItem].Units[path.indexUnit].Blocks.splice(deletedBlockIndex.value, 1);
          isDeleteBlockClicked.value = false;
          deletedBlockIndex.value = null;
          allDisplayedBlocksIndexes.value = [];

          activeStructureItem.value = {
            structureItemID: journeyDetails.value.Structure[path.indexStructureItem].ID,
            indexUnit: path.indexUnit
          }
          store.dispatch('loading/setLoading', false);
        }
      }

    };

    // const giveAssetsToParticipants = async(journeyID, unit) => {
    //   let payload = {
    //     JourneyID: journeyID,
    //     Assets: unit.LinkedAssets,
    //   };
    //   await store.dispatch('treasureChest/update', payload);
    // };

    const resetActiveStructureItem = () => {
      activeStructureItem.value = {
        structureItemID: null,
        indexUnit: null,
      };
    };

    const goToJourneyActionHandler = (journeyID, journeyAction) => { //aici avem handlerul

      if (isJourneyModified.value) {
        actionToTriggerAfterUnsavedChanges.value = journeyAction;
        toggleUnsavedChangesModal();
      }
      else {
        router.push({
          name: journeyAction,
          params: {
            id: journeyID
          }
        });
      }

    };

    const toggleUnsavedChangesModal = () => {

      isUnsavedChangesModalOpened.value = !isUnsavedChangesModalOpened.value;

    };

    const confirmUnsavedChangesHandler = () => {

      confirmUnsavedChanges.value = true;

      goToJourneyAction();

      toggleUnsavedChangesModal();

    };

    const goToJourneyAction = () => {

      router.push({
        name: actionToTriggerAfterUnsavedChanges.value,
        params: {
          id: route.params.id
        }
      });

    };

    const cancelUnsavedChangesHandler = () => {
      toggleUnsavedChangesModal();
    };

    const saveClickHandler = async () => {

      resetActiveStructureItem();

      await store.dispatch('journey/update', journeyDetails.value);

      await checkJourneysChaptersUnlock();

      await initJourneyDetails();

      isJourneyModified.value = false;

      isSaveDisabled.value = true;

      localStorage.removeItem('journeyCreationModified')

    };

    const isJourneyPictureSet = () => {
      return (journeyDetails.value.hasOwnProperty('Picture')
          && journeyDetails.value.Picture !== null
          && journeyDetails.value.Picture !== undefined
          && journeyDetails.value.Picture !== '');
    }

    const getJourneyPictureURL = async() => {
      if (isJourneyPictureSet()) {
        journeyDetails.value.PictureURL = await store.dispatch('file/getFile', {
          file_name: journeyDetails.value.Picture,
          folder_name: `journey/${journeyDetails.value.ID}`
        });
      }
    };

    const handleResize = () => {
      isMobileOrLaptopScreen.value = window.innerWidth < 768;
    };

    const toggleSidebar = () => {
      isSidebarDisplayed.value = !isSidebarDisplayed.value;
    };

    const getSkippedTabsTitles = (linkedAssets) => {

      let skippedTabsTitles = [];

      let oneUnitAttachment = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'Attachment');
      if (oneUnitAttachment === undefined) {
        skippedTabsTitles.push('Attachments');
      }

      let oneUnitMemoryCard = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'MemoryCard');
      if (oneUnitMemoryCard === undefined) {
        skippedTabsTitles.push('HB Memo Cards');
      }

      return skippedTabsTitles;

    };

    watch(() => _.cloneDeep(journeyDetails.value), (currentValue, oldValue) => {
          if (watchCounter.value > imagesCounter.value + 1) {
            if (!firstPageEnter.value) {
              isSaveDisabled.value = false;
              isJourneyModified.value = true;
            }
            localStorage.setItem('journeyCreationModified', 'yes')
          } else {
            watchCounter.value++;
          }

        },
        {deep: true}
    );

    onBeforeRouteLeave((to, from, next) => {

      if (isJourneyModified.value && confirmUnsavedChanges.value) {
        confirmUnsavedChanges.value = false;
        next();
      }
      else {
        next();
      }

    });

    // watch(isSidebarDisplayed, (newValue, oldValue) => {
    //   $(".progress-sidebar-wrapper").toggle("slide");
    // })

    provide('journeyDetails', journeyDetails);
    provide('isCurrentUserAuthorOfJourney', isCurrentUserAuthorOfJourney);
    provide('isSidebarDisplayed', isSidebarDisplayed);
    provide('activeStructureItem', activeStructureItem);

    // memory cards

    // unit assets modal

    // const memoryCardsPlusClickedHandler = () => {
    //   isEditLinkedAssetsDisplayed.value = true;
    // };

    const openEditLinkedAssetsModal = () => {
      isEditLinkedAssetsDisplayed.value = true;
    };

    const closeEditLinkedAssetsModal = () => {
      isEditLinkedAssetsDisplayed.value = false;
    };

    const toggleAddAssetTags = () => {
      isAddTagsVisible.value = !isAddTagsVisible.value;
    };

    const addAssetTagsHandler = (indexAsset) => {

      toggleAddAssetTags();
      indexAssetToAddTag.value = indexAsset;

    };

    const addTags = async () => {

      let payload = {
        NewTag: newTagInput.value,
      }
      await store.dispatch('userPreferences/updateTags', payload);
      newTagInput.value = '';

    }

    const handleAddAssetTag = (indexStructure, indexUnit) => {

      const isFromUnit = activeStructureItem.value.indexUnit !== null;
      const tagToAdd = selectedTag.value || newTagInput.value;
      const linkedAssets = isFromUnit ? journeyDetails.value.Structure[indexStructure].Units[indexUnit].LinkedAssets : journeyDetails.value.Structure[indexStructure].LinkedAssets;

      if (tagToAdd) {
        linkedAssets.forEach((linkedAsset, index) => {
          if (linkedAsset.Type === 'Attachment' && index === indexAssetToAddTag.value) {
            handleTags(linkedAsset, tagToAdd);
          }
          else if (linkedAsset.Type === 'MemoryCard') {
            const memoryCardIndex = linkedAssets.findIndex(asset => asset.MemoryCardNumber === indexAssetToAddTag.value);
            if (memoryCardIndex !== -1 && index === memoryCardIndex) {
              handleTags(linkedAsset, tagToAdd);
            }
          }
        });

      }

      addTags();
      toggleAddAssetTags();

      selectedTag.value = '';
      newTagInput.value = '';

    };

    const handleTags = (asset, tagToAdd) => {

      if (!asset.Tags) {
        asset.Tags = [];
      }
      if (!asset.Tags.includes(tagToAdd)) {
        asset.Tags.push(tagToAdd);
      }

    };

    const deleteTagHandler = (assetType, indexAsset, indexTag) => {

      const isFromUnit = activeStructureItem.value.indexUnit !== null;
      let selectedStructureItemIndex = journeyDetails.value.Structure.findIndex((structureItem) => structureItem.ID === activeStructureItem.value.structureItemID);

      if (assetType === 'Attachment') {
        if (isFromUnit) {
          journeyDetails.value.Structure[selectedStructureItemIndex].Units[activeStructureItem.value.indexUnit].LinkedAssets[indexAsset].Tags.splice(indexTag, 1);
        }
        else {
          journeyDetails.value.Structure[selectedStructureItemIndex].LinkedAssets[indexAsset].Tags.splice(indexTag, 1);
        }
      }
      else if (assetType === 'MemoryCard') {
        if (isFromUnit) {
          let selectedMemoryCardIndex = journeyDetails.value.Structure[selectedStructureItemIndex].Units[activeStructureItem.value.indexUnit].LinkedAssets.findIndex((memoryCard) => memoryCard.memoryCardNumber === indexAsset);
          journeyDetails.value.Structure[selectedStructureItemIndex].Units[activeStructureItem.value.indexUnit].LinkedAssets[selectedMemoryCardIndex].Tags.splice(indexTag, 1);
        }
        else {
          let selectedMemoryCardIndex = journeyDetails.value.Structure[selectedStructureItemIndex].LinkedAssets.findIndex((memoryCard) => memoryCard.memoryCardNumber === indexAsset);
          journeyDetails.value.Structure[selectedStructureItemIndex].LinkedAssets[selectedMemoryCardIndex].Tags.splice(indexTag, 1);
        }
      }

    };

    const getCheckedMemoryCards = (linkedAssets) => {

      return linkedAssets.filter((linkedAsset) => linkedAsset.Type === 'MemoryCard')

    };

    // end of unit assets modal

    // end of memory cards

    const getCheckedExpeditionLogs = (linkedAssets) => {

      return linkedAssets.filter((linkedAsset) => linkedAsset.Type === 'ExpeditionLog')

    };

    const isExpeditionLogChecked = (linkedAssets, ExpeditionLogNumber) => {

      let isChecked = false;
      linkedAssets.forEach((linkedAsset) => {
        if (linkedAsset.Type === 'ExpeditionLog' && linkedAsset.ExpeditionLogNumber === ExpeditionLogNumber) {
          isChecked = true;
        }
      });

      return isChecked;

    };
    // end of expedition logs

    onMounted(async () => {

      await store.dispatch('journeyCategory/list');

      await initJourneyDetails();

      let firstChapter = journeyDetails.value.Structure.find((structureItem) => structureItem.Type === 'Chapter');

      if (firstChapter !== undefined) {
        activeStructureItem.value = {
          structureItemID: firstChapter.ID,
          indexUnit: 0
        };
      }

      firstChapter.Units.forEach(unit => {
        unit.Blocks.forEach(block => {
          block.Elements.forEach( element => {
            if (element.data.text && element.data.text.indexOf('href') !== -1){
              const regex = /href="(.*?)"/g;
              element.data.text = element.data.text.replace(regex, (match, p1) => {
                return p1.indexOf(location.host) === -1
                    ? `target="_blank" ${match}`
                    : match;
              });
            }

            if (element.type === 'reusableImage' || element.type === 'image'){
              imagesCounter.value++;
            }
          })
        })
      })

      isCurrentUserAuthorOfJourney.value = (journeyDetails.value.ID === null || journeyDetails.value.IsCurrentUserAuthor || journeyDetails.value.IsCurrentUserCoAuthor);

      await getJourneyPictureURL();

      reusableImages.value = await store.dispatch('reusableAssets/listReusableJourneysImages');

      handleResize();

      firstPageEnter.value = false;

      hasContentLoaded.value = true;

      localStorage.removeItem('journeyCreationModified');

      await store.dispatch('loading/setLoading', false);
    });

    return {
      isStartBlockDrag,
      isMobileOrLaptopScreen,
      hasContentLoaded,
      isSaveDisabled,
      isCurrentUserAuthorOfJourney,
      journeyDetails,
      journeyModules,
      isUnsavedChangesModalOpened,
      titleModal,
      messageModal,
      activeStructureItem,
      store,
      isSidebarDisplayed,
      maxHeight,
      reusableImages,
      allTags,
      isAddTagsVisible,
      newTagInput,
      selectedTag,
      deleteBlockHandler,
      addBlock,
      goToJourneyActionHandler,
      confirmUnsavedChangesHandler,
      cancelUnsavedChangesHandler,
      saveClickHandler,
      editorDestroyCompleteHandler,
      handleResize,
      toggleSidebar,
      hasUnitMultipleBlocks,
      checkBlockMove,
      getSkippedTabsTitles,
      isEditLinkedAssetsDisplayed,
      openEditLinkedAssetsModal,
      closeEditLinkedAssetsModal,
      toggleAddAssetTags,
      addAssetTagsHandler,
      handleAddAssetTag,
      addTags,
      deleteTagHandler,
      getCheckedMemoryCards,
      isExpeditionLogChecked,
      getCheckedExpeditionLogs,
    }
  }

}

</script>

<style lang="scss" scoped>

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#journey_creation-page {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  .progress-sidebar-wrapper {
    width: 25%;
  }

  .journey-editor-wrapper {
    width: 75%;
    flex: 1 1 auto;

    .journey-editor {
      overflow: auto;
      padding: 15px 30px;

      .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        margin-bottom: 25px;

        .menu-icon {
          display: inline-block;
          vertical-align: top;
          width: 35px;
          height: 35px;
          text-align: center;
          line-height: 30px;
          color: #f0f4fa;
          //border-radius: 50%;
          font-size: 16px;
          //border: 2px solid;
          cursor: pointer;
          transition: .3s ease;

          &.sidebar-displayed {
            position: absolute;
            //left: 27%;
            z-index: 11;
            background-color: white;

            .close-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .title {
          text-align: center;
          font-size: 30px;
          margin: auto;
        }
      }

      .structure-item {

        .breadcrumbs {
          align-self: flex-start;
        }

        .existing-assets {
          width: 100%;

          .structure-item-attachment {
            padding: 20px 0 80px;
          }
        }

        .icon.add-asset {
          justify-content: flex-start;
          align-self: flex-start;
          font-size: 30px;
        }

        .chapter-wrapper {

          .chapter-title_wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            margin-top: 20px;

            .chapter-title {
              font-size: 26px;
            }

            .icon.eye {
              font-size: 18px;
            }

          }

          .unit {

            .unit-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 20px;

              .unit-blocks_wrapper {
                width: 100%;
                display: flex;
                align-self: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                gap: 10px;

                .block-editor_wrapper {
                  .actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 15px;
                    padding: 10px 0;

                    .icon {
                      font-size: 20px;
                    }
                  }
                }
              }

              .icon.add-block {
                align-self: flex-start;
                font-size: 30px;
              }

              .custom_modal {
                &.add-tags-assets {
                  z-index: 6;

                  .add-tags {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    height: 25%;
                    max-height: 25%;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    z-index: 9;
                    padding: 30px;
                    border: 1px solid black;
                    font-size: 18px;
                    text-align: center;
                    overflow: hidden;
                    background-color: #e8e7e7;

                    .add-tags-title {
                      font-size: 20px;
                    }

                    input, select {
                      width: 90%;
                      height: 30px;
                      border: 1px solid grey;
                      border-radius: 5px;
                    }

                    span {
                      color: grey;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }

        .separator {
          padding: 0 30px;
          margin: 10px 0;
        }

        .custom_modal {
          &.add-tags-assets {
            z-index: 6;

            .add-tags {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              height: 25%;
              max-height: 25%;
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              z-index: 9;
              padding: 30px;
              border: 1px solid black;
              font-size: 18px;
              text-align: center;
              overflow: auto;
              background-color: #e8e7e7;

              .add-tags-title {
                font-size: 20px;
              }

              input, select {
                width: 90%;
                height: 30px;
                border: 1px solid grey;
                border-radius: 5px;
              }

              span {
                color: grey;
                font-size: 16px;
              }
            }
          }
        }
      }

      & > div {
        width: 100%;
      }

    }

  }

  .edit-linked-assets-modal {
    .custom_modal__content {
      width: 95%;
      height: 95%;
      max-height: 95%;
      justify-content: flex-start;
      padding: 0;

      & > div {
        width: 100%;
      }

      .memory-cards_wrapper {
        max-height: 100%;
      }
    }
  }

  .expedition-log_wrapper {
    padding: 10px 20px;
  }

}

.backdrop-sidebar {
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 50px;
  left: 25%;
  z-index: 10;
  background-color: rgba(39, 48, 68, .5);
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  #journey_creation-page {

    .progress-sidebar-wrapper {
      position: absolute;
      left: 0;
      z-index: 5;
      width: 60%;
    }

    .journey-editor-wrapper {
      width: 40%;

      .journey-editor {
        padding: 20px;

        .title-wrapper {
          display: unset;

          .menu-icon {
            &.sidebar-displayed {
              left: 70%;
            }
          }
        }
      }
    }

    .edit-linked-assets-modal {
      .custom_modal__content {
        padding: 20px;
      }
    }
  }

  .backdrop-sidebar {
    left: 60%;
  }
}

</style>
