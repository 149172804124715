<template>

  <div v-if="hasContentLoaded" class="assets-items">

    <div v-if="isHeaderDisplayed" class="header-wrapper">

      <div class="title" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">
        <span>Expedition Logs</span>
        <div v-if="isPlusDisplayed"
             class="icon plus"
             title="Add"
             @click="plusIconClickHandler()"
        >
          <font-awesome-icon icon="plus"/>
        </div>
      </div>

      <div class="actions">

        <div class="search-wrapper">
          <font-awesome-icon class="icon icon-search" icon="magnifying-glass"/>
          <input id="search" name="search" type="text" v-model="searchFilter">
        </div>

        <div class="languages-wrapper">
          <select id="language" name="language" v-model="selectedLanguage">
            <option
                v-for="(language, indexLanguage) in allLanguages"
                :key="indexLanguage"
                :value="language"
            >
              {{ language }}
            </option>
          </select>
        </div>

      </div>

    </div>


    <div class="items-wrapper">

      <div v-for="(item, indexItem) in displayedItemsList"
           :key="indexItem"
           class="asset-item-wrapper">

<!--        <MemoryCard :card="card"-->
<!--                    :isCheckmarkDisplayed="isCheckmarkDisplayed"-->
<!--                    :isItemChecked="isItemChecked(card)"-->
<!--                    :isGemDisplayed="isItemAlreadyInJourney(card)"-->
<!--                    :isEdit="true"-->
<!--                    :assets="assets"-->
<!--                    @checkItem="checkItemHandler($event)"-->
<!--                    @uncheckItem="uncheckItemHandler($event)"-->
<!--                    @addAssetTagsItem="addAssetTagsHandler"-->
<!--                    @deleteTagItem="deleteTagHandler"-->
<!--        />-->
        <ExpeditionLogCard  :item="item"
                            :isCheckmarkDisplayed="isCheckmarkDisplayed"
                            :isChecked="isItemChecked(item)"
                            :isGemDisplayed="isItemAlreadyInJourney(item)"
                            :isEdit="true"
                            :assets="assets"
                            @checkItem="checkItemHandler($event)"
                            @uncheckItem="uncheckItemHandler($event)"
                            @addAssetTagsItem="addAssetTagsHandler"
                            @deleteTagItem="deleteTagHandler"
        />

        <!--        <div v-if="true" class="icon check">-->
        <!--          <font-awesome-icon :icon="['fas', 'circle-check']" />-->
        <!--        </div>-->

        <!--        <div v-else class="icon check">-->
        <!--          <font-awesome-icon :icon="['far', 'circle-check']" />-->
        <!--        </div>-->

      </div>

    </div>

  </div>

</template>

<script>

import {computed, inject, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import ExpeditionLogCard from "@/components/common/ExpeditionLogCard.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import _ from "lodash";

export default {

  name: 'ExpeditionLogCards',

  components: {
    ExpeditionLogCard,
    FontAwesomeIcon,
  },

  emits: ['checkItem', 'uncheckItem', 'plusClicked', 'addAssetTags', 'deleteAssetTags'],

  props: {
    isDisplayAll: {
      type: Boolean,
      default: false,
    },
    checkedItems: {
      type: Array,
      default: [],
    },
    isCheckmarkDisplayed: {
      type: Boolean,
      default: false,
    },
    displayedItems: {
      type: Array,
      default: [],
    },
    isPlusDisplayed: {
      type: Boolean,
      default: false,
    },
    isEditJourneyStructure: {
      type: Boolean,
      default: false,
    },
    isHeaderDisplayed: {
      type: Boolean,
      default: true,
    },

    structureItemID: {
      default: null
    },
    indexStructureItem: {
      default: null
    },
    indexUnit: {
      default: null
    },
    assets: {
      type: Array,
      default: [],
    },
  },

  setup(props, {emit}) {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isFirstPageEnter = ref(true);

    // const allLanguages = ref(['bg', 'cz', 'de', 'en', 'fr', 'ho', 'hr', 'hu', 'it', 'mx', 'no', 'ro', 'rs', 'se', 'si', 'tr', 'us', 'pl']);
    const allLanguages = ref(['en']);

    const selectedLanguage = ref();

    const searchFilter = ref('');

    const ExpeditionLogs = computed(() => store.getters["tenantSpecificAssets/getterExpeditionLogs"]);

    const displayedItemsList = ref([]);

    const displayedItemsListBackup = ref([]);

    // edit journey structure

    let journeyDetails = ref(null);
    const alreadySelectedItems = ref([]);

    if (props.isEditJourneyStructure) {
      journeyDetails = inject('journeyDetails');
    }

    const getAlreadySelectedItems = () => {
      journeyDetails.value.Structure.forEach((structureItem) => {

        if (structureItem.Type === 'Chapter' && structureItem.hasOwnProperty('Units') && structureItem.Units.length > 0) {

          structureItem.Units.forEach((unit) => {

            if (unit.hasOwnProperty('LinkedAssets') && unit.LinkedAssets.length > 0) {
              let items = unit.LinkedAssets.filter((linkedAsset) => linkedAsset.Type === 'ExpeditionLog');
              alreadySelectedItems.value = alreadySelectedItems.value.concat(items);
            }

          });

        }
        if ((structureItem.Type === 'Event' || structureItem.Type === 'Assignment') && structureItem.LinkedAssets.length > 0) {

          let items = structureItem.LinkedAssets.filter((linkedAsset) => linkedAsset.Type === 'ExpeditionLog');
          alreadySelectedItems.value = alreadySelectedItems.value.concat(items);

        }

      });
    };

    const checkItemHandler = (ev) => {

      const linkedAssetObject = {
        Type: 'ExpeditionLog',
        ExpeditionLogNumber: ev.ExpeditionLogNumber,
        IsTreasureChestAsset: true,
        // Tags: [],
        GiveDetails: {
          Event: 'Unlock',
          IsGiven: false,
        },
      };

      let item = ExpeditionLogs.value.find((item) => item.ExpeditionLogNumber === linkedAssetObject.ExpeditionLogNumber);

      Object.assign(linkedAssetObject, linkedAssetObject, item);

      if (props.indexUnit !== null) {
        journeyDetails.value.Structure[props.indexStructureItem].Units[props.indexUnit].LinkedAssets.push(linkedAssetObject);
      } else {
        // other than unit
        journeyDetails.value.Structure[props.indexStructureItem].LinkedAssets.push(linkedAssetObject);
      }

      alreadySelectedItems.value.push({
        Type: 'ExpeditionLog',
        ExpeditionLogNumber: ev.ExpeditionLogNumber,
      });

    };

    const uncheckItemHandler = (ev) => {

      if (props.indexUnit !== null) {
        journeyDetails.value.Structure[props.indexStructureItem].Units[props.indexUnit].LinkedAssets = journeyDetails.value.Structure[props.indexStructureItem].Units[props.indexUnit].LinkedAssets.filter((linkedAsset) => {
          if (linkedAsset.Type === 'ExpeditionLog') {
            return (linkedAsset.ExpeditionLogNumber !== ev.ExpeditionLogNumber);
          } else {
            return true;
          }
        });
      } else {
        // other than unit
        journeyDetails.value.Structure[props.indexStructureItem].LinkedAssets = journeyDetails.value.Structure[props.indexStructureItem].LinkedAssets.filter((linkedAsset) => {
          if (linkedAsset.Type === 'ExpeditionLog') {
            return (linkedAsset.ExpeditionLogNumber !== ev.ExpeditionLogNumber);
          } else {
            return true;
          }
        });
      }

      const index = alreadySelectedItems.value.findIndex((item) => (item.ExpeditionLogNumber === ev.ExpeditionLogNumber));
      if (index !== -1) {
        // If an object with the specified value is found, remove it from the array
        alreadySelectedItems.value.splice(index, 1);
      }

    };

    const addAssetTagsHandler = (itemNumber) => {
      emit('addAssetTags', itemNumber);
    };

    const deleteTagHandler = (itemNumber, indexAssetTag) => {
      emit('deleteAssetTags', 'ExpeditionLog', itemNumber, indexAssetTag);
    };

    const isItemAlreadyInJourney = (item) => {

      if (alreadySelectedItems.value) {
        return alreadySelectedItems.value.some((checkedItem) => (checkedItem.ExpeditionLogNumber === item.ExpeditionLogNumber));
      }
      return false;

    }

    // end of edit journey structure

    const searchByValue = (newSearch) => {
      let mySearch = newSearch.toLowerCase();

      displayedItemsList.value = displayedItemsListBackup.value.filter((displayedItem) => {

        const title = displayedItem.Title[selectedLanguage.value];

        const matchTitle = title.toString().toLowerCase().includes(mySearch);

        return matchTitle;

      });

    };

    const plusIconClickHandler = () => {
      emit('plusClicked');
    }

    // check functionality

    const isItemChecked = (item) => {
      return props.checkedItems.some((checkedItem) => (checkedItem.ExpeditionLogNumber === item.ExpeditionLogNumber));
    }

    const filterDisplayedItems = (items) => {

      displayedItemsList.value = [];

      items.forEach((displayedItem) => {
        if (displayedItem.Type === 'ExpeditionLog') {
          let foundItem = ExpeditionLogs.value.find((item) => item.ExpeditionLogNumber === displayedItem.ExpeditionLogNumber);
          displayedItemsList.value.push(foundItem);
        }
      });

      displayedItemsListBackup.value = JSON.parse(JSON.stringify(displayedItemsList.value));

    }

    // end of check functionality

    watch(selectedLanguage, async (newValue, oldValue) => {

      if (!isFirstPageEnter.value) {
        await store.dispatch('userPreferences/updateLanguage', newValue);
      }

    });

    watch(searchFilter, (newValue) => {

      searchByValue(newValue);

    });

    watch(() => _.cloneDeep(props.displayedItems), (currentValue, oldValue) => {

          filterDisplayedItems(currentValue);

        },
        {deep: true}
    );


    onMounted(async () => {

      // selectedLanguage.value = store.state.userPreferences.userPreferences.Language;
      selectedLanguage.value = 'en';

      if (props.isDisplayAll) {
        displayedItemsList.value = JSON.parse(JSON.stringify(ExpeditionLogs.value));
      } else {
        filterDisplayedItems(props.displayedItems);
      }

      displayedItemsListBackup.value = JSON.parse(JSON.stringify(displayedItemsList.value));

      if (props.isEditJourneyStructure) {
        getAlreadySelectedItems();
      }

      hasContentLoaded.value = true;

      await store.dispatch('loading/setLoading', false);

      isFirstPageEnter.value = false;

    });

    return {
      store,
      hasContentLoaded,
      allLanguages,
      displayedItemsList,
      displayedItemsListBackup,
      selectedLanguage,
      searchFilter,
      checkItemHandler,
      uncheckItemHandler,
      addAssetTagsHandler,
      deleteTagHandler,
      isItemChecked,
      plusIconClickHandler,
      isItemAlreadyInJourney,
    }

  }

}

</script>

<style lang="scss" scoped>

.assets-items {
  width: 100%;
  padding: 20px 50px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 30px;
      text-align: left;
      margin-bottom: 10px;

      .icon.plus {
        font-size: 24px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      .languages-wrapper {

        select {
          padding: 5px 10px;
          border: 1px solid gray;
          border-radius: 5px;
          text-transform: uppercase;
        }

      }

      .search-wrapper {
        position: relative;

        .icon.icon-search {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          opacity: 0.5;
          width: 15px;
        }

        input {
          padding: 5px 25px;
          border: 1px solid gray;
          border-radius: 5px;
          width: 100%;
        }

      }

    }

  }

  .items-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
    padding: 20px 0;

    .asset-item-wrapper {
      position: static;

      .icon.check {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .assets-items {
    padding: 0;

    .languages-wrapper {
      text-align: left;
    }

    .search-wrapper {
      input {
        width: 50%;
      }
    }

    .items-wrapper {
      justify-content: center;
    }
  }
}

</style>
