<template>
  <MobileLayout>
    <template v-slot:default>
      <div v-if="hasContentLoaded" id="treasure-chest" class="with-top-filter">
        <div class="top-filter">
          <div class="search-wrapper">
            <input id="search" name="search" type="text" v-model="searchFilter" placeholder="Search...">
          </div>
          <div :class="['icon', 'filter', { 'filter-active': areFiltersDisplayed }]">
            <div v-show="areFiltersDisplayed" class="filters_wrapper" ref="filtersWrapper">
              <!-- Filter options here -->
              <div class="filter_wrapper">
                <span class="filter-title">Type</span>
                <div v-for="(filterType, indexFilterType) in filters.Types" :key="indexFilterType"
                  class="filter_option">
                  <label>
                    <input type="checkbox" :value="filterType" v-model="selectedFilters.Types">
                    {{ filterType.Label }}
                  </label>
                </div>
              </div>
              <div class="filter_wrapper">
                <span class="filter-title">Source</span>
                <div v-for="(filterSource, indexFilterSource) in filters.Sources" :key="indexFilterSource"
                  class="filter_option">
                  <label>
                    <input type="checkbox" :value="filterSource" v-model="selectedFilters.Sources">
                    {{ filterSource.Name }}
                  </label>
                </div>
              </div>
            </div>
            <font-awesome-icon icon="filter" class="icon-filter" title="filter" @click="changeFiltersVisibility()"
              ref="filterIcon" />
          </div>
          <div class="languages-wrapper">
            <div ref="languageSelectorTrigger">
              {{ selectedLanguage.toUpperCase() }}
            </div>
          </div>
        </div>

        <div
          v-if="displayedTreasureChestAssets.memoryCards.length > 0 || displayedTreasureChestAssets.expeditionLogs.length > 0 || displayedTreasureChestAssets.attachments.length > 0">
          <Carousel :items-to-show="1" :wrap-around="true">
            <Slide v-for="(linkedAsset, indexLinkedAsset) in displayedTreasureChestAssets.memoryCards"
              :key="indexLinkedAsset">
              <div class="carousel__item">
                <div class="memory-card-wrapper">
                  <div class="memory_card" v-if="linkedAsset.Type === 'MemoryCard'">
                    <MemoryCard :card="linkedAsset" :language="selectedLanguage" />
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
        <p v-else>
          This is where you'll find valuable gems and gold nuggets – key learnings from your sessions. As you progress,
          new treasures will be added here. Stay tuned and look forward to discovering the insights that will enrich
          your
          journey!
        </p>
      </div>
    </template>
  </MobileLayout>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import MobileLayout from '@/layouts/MobileLayout.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import Cards from "@/assets/memory_cards/cardDetails";
import MemoryCard from "@/components/common/MemoryCard.vue";
import ExpeditionLogCard from "@/components/common/ExpeditionLogCard.vue";
import _ from "lodash";
import MobileSelect from 'mobile-select/dist/mobile-select.es.js';
import 'mobile-select/dist/style/mobile-select.css';

if (!('TouchEvent' in window)) {
  window.TouchEvent = function () { };
}

library.add(faSearch, faAngleDoubleUp);

export default {
  name: 'MobileTreasureChest',
  components: {
    MemoryCard,
    ExpeditionLogCard,
    MobileLayout,
    Carousel,
    Slide,
    Navigation
  },
  setup() {
    const store = useStore();
    const primary_color = ref(store.state?.tenant?.tenantinfo?.primarycolor || '#000000'); // Default to black if undefined
    const hasContentLoaded = ref(false);
    const isFirstPageEnter = ref(true);
    const treasureChestAssets = ref([]);
    const filters = ref({
      Types: [],
      Sources: [],
      Tags: []
    });
    const selectedFilters = ref({
      Types: [],
      Sources: [],
      Tags: []
    });
    const areFiltersDisplayed = ref(false);
    const filterIcon = ref(null);
    const filtersWrapper = ref(null);
    const searchFilter = ref('');
    const journeysNames = ref([]);
    const lastScrollTop = ref(0);
    const lastTouchTime = ref(0);

    const languageSelectorTrigger = ref(null);
    const languageSelector = ref(null);


    //console.log('primary_color', primary_color.value);
    const initializeLanguageSelector = () => {
      if (languageSelectorTrigger.value && !languageSelector.value) { // Add check to prevent multiple instances
        let isConfirming = false; // Add this flag

        languageSelector.value = new MobileSelect({
          trigger: languageSelectorTrigger.value,
          title: 'Select Language',
          cancelBtnText: 'Cancel',
          ensureBtnText: 'OK',
          ensureBtnColor: primary_color.value, //'#007AFF', // iOS-like blue color
          cancelBtnColor: primary_color.value, //'#007AFF', // iOS-like blue color
          titleColor: '#000000',
          titleBgColor: '#F7F7F7', // Light gray background
          textColor: '#000000',
          bgColor: '#FFFFFF',
          maskOpacity: 0.5,
          triggerDisplayValue: true,
          wheels: [{
            data: memoryCardsLanguages.value.map(lang => ({ id: lang, value: lang.toUpperCase() }))
          }],
          position: [memoryCardsLanguages.value.indexOf(selectedLanguage.value)],
          initValue: selectedLanguage.value.toUpperCase(),
          onChange: (data, indexArr, instance) => {
            if (isConfirming) {
              // console.log('onChange isConfirming', data, indexArr, instance);
              instance.hide();
              return;
            }

            //console.log('onChange', data, indexArr, instance);

            if (data && data[0]) {
              isConfirming = true; // Set the flag
              updateLanguage(data[0].id).then(() => {
                instance.hide();
                isConfirming = false; // Reset the flag
              });
            }
          },
          onShow: (instance) => {
            //console.log('Language selector opened', instance);
            tempSelectedLanguage.value = selectedLanguage.value;
          },
          onHide: (instance) => {
            //console.log('Language selector closed', instance);
            isConfirming = false; // Reset the flag when hidden
          }
        });
      }
    };

    const updateLanguage = async (newLanguage) => {
      //console.log('updateLanguage function', newLanguage);
      if (newLanguage !== selectedLanguage.value) {
        selectedLanguage.value = newLanguage;
        tempSelectedLanguage.value = newLanguage;
        await store.dispatch('userPreferences/updateLanguage', newLanguage);
        if (languageSelectorTrigger.value) {
          languageSelectorTrigger.value.textContent = newLanguage.toUpperCase();
        }
        await nextTick();
      }
    };




    const ExpeditionLogs = computed(() => store.getters["tenantSpecificAssets/getterExpeditionLogs"]);

    const changeFiltersVisibility = async () => {
      areFiltersDisplayed.value = !areFiltersDisplayed.value;
      await nextTick();
      if (areFiltersDisplayed.value) {
        window.addEventListener('click', handleClickOutside);
      } else {
        window.removeEventListener('click', handleClickOutside);
      }
    };

    const handleClickOutside = (event) => {
      if (filtersWrapper.value && filterIcon.value) {
        if (!filtersWrapper.value.contains(event.target) && !filterIcon.value.$el.contains(event.target)) {
          areFiltersDisplayed.value = false;
          window.removeEventListener('click', handleClickOutside);
        }
      }
    };

    const displayedTreasureChestAssets = computed(() => {
      let displayedAssets = treasureChestAssets.value.filter((treasureChestAsset) => {
        let searchMatch = true;
        let mySearch = searchFilter.value.toLowerCase();
        if (mySearch !== '') {
          if (treasureChestAsset.Type === 'Attachment') {
            searchMatch = treasureChestAsset.Label.toString().toLowerCase().includes(mySearch);
          }
          if (treasureChestAsset.Type === 'MemoryCard') {
            const frontTitle = treasureChestAsset.frontTitle[selectedLanguage.value];
            const frontBottomText = treasureChestAsset.frontBottomText[selectedLanguage.value];

            const matchTitle = frontTitle.toString().toLowerCase().includes(mySearch);
            const matchBottomText = frontBottomText.toString().toLowerCase().includes(mySearch);

            searchMatch = matchTitle || matchBottomText;
          }
          if (treasureChestAsset.Type === 'ExpeditionLog') {
            const title = treasureChestAsset.Title['en'];

            const matchTitle = title.toString().toLowerCase().includes(mySearch);

            return matchTitle;
          }
        }

        let typeMatch = true;
        if (selectedFilters.value.Types.length !== 0) {
          let foundMatch = selectedFilters.value.Types.find((filterType) => filterType.Value === treasureChestAsset.Type);
          typeMatch = (foundMatch !== undefined);
        }

        let sourceMatch = true;
        if (selectedFilters.value.Sources.length !== 0) {
          let foundMatch = selectedFilters.value.Sources.find((filterSource) => {
            return treasureChestAsset.Sources.Journeys.includes(filterSource.ID);
          });
          sourceMatch = (foundMatch !== undefined);
        }

        return searchMatch && typeMatch && sourceMatch;
      });

      let memoryCards = displayedAssets.filter((asset) => asset.Type === 'MemoryCard');
      let expeditionLogs = displayedAssets.filter((asset) => asset.Type === 'ExpeditionLog');
      let attachments = displayedAssets.filter((asset) => asset.Type === 'Attachment');

      return {
        memoryCards: memoryCards,
        expeditionLogs: expeditionLogs,
        attachments: attachments,
      };
    });

    const allMemoryCards = ref([]);
    allMemoryCards.value = Cards;

    const memoryCardsLanguages = ref(['bg', 'cz', 'de', 'en', 'fr', 'ho', 'hr', 'hu', 'it', 'mx', 'no', 'ro', 'rs', 'se', 'si', 'tr', 'us', 'pl']);
    //const selectedLanguage = ref('en'); // default language
    const selectedLanguage = ref(store.state.userPreferences.userPreferences.Language || 'en');
    const tempSelectedLanguage = ref(selectedLanguage.value);


    //watch(selectedLanguage, async (newValue, oldValue) => {
    //
    //  if (!isFirstPageEnter.value) {
    //    await store.dispatch('userPreferences/updateLanguage', newValue);
    //  }
    //});
    watch(selectedLanguage, async (newLanguage, oldLanguage) => {
      if (newLanguage !== oldLanguage) {
        //console.log('Language changed to:', newLanguage);
        // Force re-render of the component
        await nextTick();
      }
    });

    watch(() => _.cloneDeep(selectedFilters), async (newValue, oldValue) => {
      if (!isFirstPageEnter.value) {
        let payload = {
          TreasureChestFilters: newValue.value,
        };
        await store.dispatch('userPreferences/updateTreasureChestFilters', payload);
      }
    }, { deep: true });

    const getSkippedTabsTitles = (linkedAssets) => {
      let skippedTabsTitles = [];
      let oneUnitAttachment = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'Attachment');
      if (oneUnitAttachment === undefined) {
        skippedTabsTitles.push('Attachments');
      }

      let oneUnitMemoryCard = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'MemoryCard');
      if (oneUnitMemoryCard === undefined) {
        skippedTabsTitles.push('HB Memo Cards');
      }

      return skippedTabsTitles;
    };

    const downloadAsset = async (linkedAsset) => {
      let url = null;
      if (linkedAsset.hasOwnProperty('Url')) {
        url = linkedAsset.Url;
      } else {
        let payload = {
          file_name: linkedAsset.Name,
          file_label: linkedAsset.Label,
          folder_name: linkedAsset.FolderName
        };
        url = await store.dispatch('file/getFile', payload);
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', linkedAsset.Label);
      link.click();
    };

    watch(languageSelectorTrigger, (newValue) => {
      if (newValue && !languageSelector.value) {
        initializeLanguageSelector();
      }
    });

    const getFilters = () => {
      treasureChestAssets.value.forEach((treasureChestAsset) => {
        let alreadyExists = (filters.value.Types.find((typeFilter) => typeFilter.Value === treasureChestAsset.Type)) !== undefined;
        if (!alreadyExists) {
          let typeFilterObject = {
            Value: treasureChestAsset.Type,
          };
          switch (typeFilterObject.Value) {
            case 'ExpeditionLog': {
              typeFilterObject.Label = 'Expedition Log';
              break;
            }
            case 'MemoryCard': {
              typeFilterObject.Label = 'Memo Card';
              break;
            }
            default: {
              typeFilterObject.Label = typeFilterObject.Value;
              break;
            }
          }
          filters.value.Types.push(typeFilterObject);
        }

        treasureChestAsset.Sources.Journeys.forEach((journeyID) => {
          let alreadyExists = (filters.value.Sources.find((sourceFilter) => sourceFilter.ID === journeyID)) !== undefined;
          if (!alreadyExists) {
            let journeyObject = journeysNames.value.find((journey) => journey.ID === journeyID);
            if (journeyObject !== undefined) {
              filters.value.Sources.push(journeyObject);
            }
          }
        });
      });
    };

    onMounted(async () => {
      treasureChestAssets.value = await store.dispatch('treasureChest/getTreasureChestForUser');

      treasureChestAssets.value.forEach((treasureChestAsset) => {
        if (treasureChestAsset.Type === 'ExpeditionLog') {
          let expedition_log = ExpeditionLogs.value.value.find((expedition_log) => expedition_log.ExpeditionLogNumber === treasureChestAsset.ExpeditionLogNumber);
          Object.assign(treasureChestAsset, treasureChestAsset, expedition_log);
        }
        if (treasureChestAsset.Type === 'MemoryCard') {
          let memo_card = allMemoryCards.value.find((card) => card.batchNumber === treasureChestAsset.BatchNumber && card.memoryCardNumber === treasureChestAsset.MemoryCardNumber);
          Object.assign(treasureChestAsset, treasureChestAsset, memo_card);
        }
        selectedLanguage.value = store.state.userPreferences.userPreferences.Language;
      });


      const sortOrder = {
        MemoryCard: 1,
        ExpeditionLog: 2,
        Attachment: 3
      };

      treasureChestAssets.value.sort((a, b) => {
        return sortOrder[a.Type] - sortOrder[b.Type];
      });

      journeysNames.value = await store.dispatch('journey/listAllJourneysNames');

      getFilters();


      selectedLanguage.value = store.state.userPreferences.userPreferences.Language;

      selectedFilters.value.Types = store.state.userPreferences.userPreferences.TreasureChestFilters.Types;
      selectedFilters.value.Sources = store.state.userPreferences.userPreferences.TreasureChestFilters.Sources;
      selectedFilters.value.Tags = store.state.userPreferences.userPreferences.TreasureChestFilters.Tags;

      hasContentLoaded.value = true;

      await store.dispatch('loading/setLoading', false);

      isFirstPageEnter.value = false;

      await nextTick();
      if (languageSelectorTrigger.value) {
        initializeLanguageSelector();
      }
    });
    // Cleanup
    onUnmounted(() => {
      if (languageSelector.value) {
        languageSelector.value.destroy();
      }
    });

    return {
      store,
      hasContentLoaded,
      treasureChestAssets,
      changeFiltersVisibility,
      displayedTreasureChestAssets,
      searchFilter,
      filters,
      selectedFilters,
      areFiltersDisplayed,
      getSkippedTabsTitles,
      downloadAsset,
      selectedLanguage,
      memoryCardsLanguages,
      languageSelectorTrigger,
      selectedLanguage,
      filterIcon,
      filtersWrapper,
    };
  }
}
</script>

<style lang="scss" scoped>
#treasure-chest {
  padding: 20px;

  &.with-top-filter {
    padding-top: 60px;
  }

  .top-filter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .search-wrapper {
    flex: 1;
    margin-right: 10px;

    input {
      width: 100%;
      padding: 5px 10px;
      border: 1px solid lightgray;
      /* Lighter gray border */
      border-radius: 5px;
      transition: border-color 0.3s;

      &:focus {
        border-color: gray;
        /* Darker gray on focus */
      }
    }
  }

  .icon.filter {
    margin-right: 10px;
    opacity: 0.5;
    /* Reduced opacity for the filter icon */
    transition: opacity 0.3s;

    &.filter-active {
      opacity: 1;
      /* Full opacity when filter options are visible */
    }

    .icon-filter {
      font-size: 24px;
    }
  }

  .filters_wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;

    .filter_wrapper {
      margin-bottom: 10px;

      .filter-title {
        font-weight: bold;
      }

      .filter_option {
        margin-bottom: 5px;

        label {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
        }

        input {
          cursor: pointer;
        }
      }
    }
  }

  .languages-wrapper {
    select {
      padding: 5px 10px;
      border: 1px solid gray;
      border-radius: 5px;
      text-transform: uppercase;
    }
  }

  .carousel__item {
    width: 90vw;
    height: calc(100vh - 200px);
    margin: 0 2.5vw;
    padding: 0px;
  }

  .memory-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .memory_card {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  #treasure-chest {
    padding: 20px;

    .languages-wrapper {
      text-align: left;
    }
  }
}

.languages-wrapper {
  div {
    padding: 5px 10px;
    border: 1px solid gray;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</style>