<template>
  <div v-if="hasContentLoaded" id="users-management" class="content" v-cloak>

    <div class="icon unconfirmed-users-reset-icon"
         title="Resend Unconfirmed Users Temporary Password"
         @click="isAllTemporaryPasswordResendModalDisplayed = true">
      <font-awesome-icon icon="clock-rotate-left"/>
    </div>

    <div class="table-wrapper">

      <div class="table-header">
        <h2>Tenant Admins</h2>
        <div class="icon"
             @click="openAlignExpirationDateUsersModal(tenantAdmins, 'TenantAdmin')">
          <font-awesome-icon icon="list-ul"/>
        </div>
      </div>

      <form id="tenant_admins-form" @submit.prevent="verifyEmailsAndSaveNewUsers(newTenantAdmins, 'TenantAdmin')">

        <table id="tenant_admins-table">

          <thead>
          <tr>
            <th><h2>Given Name</h2></th>
            <th><h2>Family Name</h2></th>
            <th><h2>E-mail</h2></th>
            <th><h2>Address</h2></th>
            <th><h2>Position</h2></th>
<!--            <th><h2>Phone Number</h2></th>-->
            <th><h2>Expiration Date</h2></th>
            <th><h2>Journeys</h2></th>
            <th><h2>Role</h2></th>
            <th class="sticky-column"><h2>Actions</h2></th>
          </tr>
          </thead>

          <tbody>

          <tr v-for="(existingUser, indexExistingUser) in tenantAdmins"
              :key="indexExistingUser"
          >
            <td class="user-info">
              <div class="checkbox-wrapper">
<!--                <label class="custom-checkmark-container">-->
<!--                  <input type="checkbox"-->
<!--                         class="checkbox"-->
<!--                         @change="toggleUserSelection(existingUser)"-->
<!--                  >-->
<!--                  <span class="checkmark"></span>-->
<!--                </label>-->
                <input type="text" v-if="existingUser.hasOwnProperty('given_name')" v-model="existingUser.given_name" :disabled="!existingUser.IsEdit" required>
              </div>
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('family_name')" v-model="existingUser.family_name" :disabled="!existingUser.IsEdit" required>
            </td>
            <td>
              <input type="email" v-if="existingUser.hasOwnProperty('email')" v-model="existingUser.email" disabled>
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('address')" v-model="existingUser['address']" :disabled="!existingUser.IsEdit">
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('custom:position')" v-model="existingUser['custom:position']" :disabled="!existingUser.IsEdit">
            </td>
<!--            <td>-->
<!--              <input type="text" v-if="existingUser.hasOwnProperty('phone_number')" v-model="existingUser.phone_number" :disabled="!existingUser.IsEdit">-->
<!--            </td>-->
            <td>
              <span v-if="existingUser.hasOwnProperty('custom:expiration_date')">{{ existingUser['custom:expiration_date'] }}</span>
            </td>
            <td>
              <div class="journeys-wrapper">
                <div v-for="(journey, indexJourney) in existingUser.Journeys"
                     :key="indexJourney">
                  <router-link :to="{ name: 'JourneyView', params: { id: journey.ID } }"> {{ journey.Name }} </router-link>
                </div>
              </div>
            </td>
            <td>

              <span v-if="!existingUser.IsEdit">{{ existingUser.Group }}</span>

              <select v-else v-model="existingUser.Group" @change="updateSelectedUsersRoles(existingUser.Group)">
                <option v-for="(role, indexRole) in allRoles"
                        :key="indexRole"
                        :value="role">
                  {{ role }}
                </option>
              </select>

              <!--          <div class="roles-wrapper">-->
              <!--            <div v-for="(role, indexRole) in existingUser.Groups"-->
              <!--                 :key="indexRole">{{ role }}</div>-->
              <!--          </div>-->

              <!--              <div v-show="existingUser.IsEdit"-->
              <!--                   v-for="(role, indexRole) in allRoles"-->
              <!--                   :key="indexRole"-->
              <!--                   class="input-wrapper">-->
              <!--                <input type="checkbox">-->
              <!--                <label>{{ role }}</label>-->
              <!--              </div>-->

              <!--              <div v-show="!existingUser.IsEdit"-->
              <!--                   v-for="(group, indexGroup) in existingUser.Groups"-->
              <!--                   :key="indexGroup"-->
              <!--                   class="role"-->
              <!--              >{{ group }}</div>-->

            </td>
            <td class="sticky-column">
              <div class="actions-wrapper">
                <div class="icon delete-icon" @click="openDeleteModal(existingUser, indexExistingUser)">
                  <font-awesome-icon icon="trash"/>
                </div>
                <div class="icon edit-icon" @click="selectedUsers.length > 1 ? toggleEditForSelectedUsers() : (existingUser.IsEdit = !existingUser.IsEdit)">
                  <font-awesome-icon icon="edit"/>
                </div>
                <div v-show="existingUser.IsEdit" class="icon save-icon" @click="selectedUsers.length > 1 ? updateUser(selectedUsers) : updateUser(existingUser)">
                  <font-awesome-icon icon="floppy-disk"/>
                </div>
                <div v-if="existingUser.hasOwnProperty('email') && existingUser.hasOwnProperty('UserStatus') && existingUser.UserStatus !== 'CONFIRMED'"
                     class="icon reset-icon"
                     title="Resend Temporary Password"
                     @click="activeTemporaryPasswordResendUserSub = existingUser.sub">
                  <font-awesome-icon icon="clock-rotate-left"/>
                </div>
              </div>
            </td>
            <td class="hidden-column">
              <ConfirmationModal title="WARNING!"
                                 :message="'Are you sure you want to resend the temporary password to ' + existingUser.email + '?'"
                                 :isOpened="activeTemporaryPasswordResendUserSub === existingUser.sub"
                                 @confirm="resendIndividualUserTemporaryPassword(existingUser.email)"
                                 @cancel="activeTemporaryPasswordResendUserSub = null"
              />
            </td>
          </tr>

          <tr>
            <td colspan="10">
              <font-awesome-icon class="icon add-user" icon="plus" @click="addNewUser('TenantAdmin')"/>
            </td>
          </tr>

          <tr v-for="(newUser, indexNewUser) in newTenantAdmins"
              :key="indexNewUser"
          >

            <td>
              <input type="text" v-if="newUser.hasOwnProperty('given_name')" v-model="newUser.given_name" required>
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('family_name')" v-model="newUser.family_name" required>
            </td>
            <td>
              <input type="email" v-if="newUser.hasOwnProperty('email')" v-model="newUser.email" required>
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('address')" v-model="newUser.address">
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('custom:position')" v-model="newUser['custom:position']">
            </td>
<!--            <td>-->
<!--              <input type="text" v-if="newUser.hasOwnProperty('phone_number')" v-model="newUser.phone_number">-->
<!--            </td>-->
            <td>

            </td>
            <td>

            </td>
            <td>

              <span>{{ newUser.Group }}</span>

            </td>
            <td class="sticky-column">
              <div class="icon delete-icon" @click="removeNewUser(indexNewUser, 'TenantAdmin')">
                <font-awesome-icon icon="trash" class="red-trash"/>
              </div>
            </td>

          </tr>

          </tbody>

        </table>

        <span v-if="multipleEmailsErrorMessageTenantAdmins" class="error-message"> {{ multipleEmailsErrorMessageTenantAdmins }}</span>

        <div class="button-wrapper">
          <button type="submit" :class="{'btn': true, 'btn-primary': true, 'disabled': newTenantAdmins.length === 0}">
            Add Tenant Admins
          </button>
        </div>

      </form>

      <ImportUsers :userType="'Tenant Admins'"
                   :fromWhere="'UserManagement'"
                   :userTypeKey="'tenantAdmin'"
                   @csvImportComplete="csvImportCompleteHandler"
      />

    </div>

    <div class="table-wrapper">

      <div class="table-header">
        <h2>Facilitators</h2>
        <div class="icon"
             @click="openAlignExpirationDateUsersModal(facilitators, 'Facilitator')">
          <font-awesome-icon icon="list-ul"/>
        </div>
      </div>

      <form id="facilitators-form" @submit.prevent="verifyEmailsAndSaveNewUsers(newFacilitators, 'Facilitator')">

        <table id="facilitators-table">

          <thead>
          <tr>
            <th><h2>Given Name</h2></th>
            <th><h2>Family Name</h2></th>
            <th><h2>E-mail</h2></th>
            <th><h2>Address</h2></th>
            <th><h2>Position</h2></th>
<!--            <th><h2>Phone Number</h2></th>-->
            <th><h2>Expiration Date</h2></th>
            <th><h2>Journeys</h2></th>
            <th><h2>Role</h2></th>
            <th class="sticky-column"><h2>Actions</h2></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(existingUser, indexExistingUser) in facilitators"
              :key="indexExistingUser"
          >
            <td class="user-info">
              <div class="checkbox-wrapper">
<!--                <label class="custom-checkmark-container">-->
<!--                  <input type="checkbox"-->
<!--                         class="checkbox"-->
<!--                         @change="toggleUserSelection(existingUser)"-->
<!--                  >-->
<!--                  <span class="checkmark"></span>-->
<!--                </label>-->
                <input type="text" v-if="existingUser.hasOwnProperty('given_name')" v-model="existingUser.given_name" :disabled="!existingUser.IsEdit">
              </div>
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('family_name')" v-model="existingUser.family_name" :disabled="!existingUser.IsEdit">
            </td>
            <td>
              <input type="email" v-if="existingUser.hasOwnProperty('email')" v-model="existingUser.email" disabled>
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('address')" v-model="existingUser['address']" :disabled="!existingUser.IsEdit">
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('custom:position')" v-model="existingUser['custom:position']" :disabled="!existingUser.IsEdit">
            </td>
<!--            <td>-->
<!--              <input type="text" v-if="existingUser.hasOwnProperty('phone_number')" v-model="existingUser.phone_number" :disabled="!existingUser.IsEdit">-->
<!--            </td>-->
            <td>
              <span v-if="existingUser.hasOwnProperty('custom:expiration_date')">{{ existingUser['custom:expiration_date'] }}</span>
            </td>
            <td>
              <div class="journeys-wrapper">
                <div v-for="(journey, indexJourney) in existingUser.Journeys"
                     :key="indexJourney">
                  <router-link :to="{ name: 'JourneyView', params: { id: journey.ID } }"> {{ journey.Name }} </router-link>
                </div>
              </div>
            </td>
            <td>

              <span v-if="!existingUser.IsEdit">{{ existingUser.Group }}</span>

              <select v-else v-model="existingUser.Group" @change="updateSelectedUsersRoles(existingUser.Group)">
                <option v-for="(role, indexRole) in allRoles"
                        :key="indexRole"
                        :value="role">
                  {{ role }}
                </option>
              </select>

            </td>
            <td class="sticky-column">
              <div class="actions-wrapper">
                <div class="icon delete-icon" @click="openDeleteModal(existingUser, indexExistingUser)">
                  <font-awesome-icon icon="trash"/>
                </div>
                <div class="icon edit-icon" @click="selectedUsers.length > 1 ? toggleEditForSelectedUsers() : (existingUser.IsEdit = !existingUser.IsEdit)">
                  <font-awesome-icon icon="edit"/>
                </div>
                <div v-show="existingUser.IsEdit" class="icon save-icon" @click="selectedUsers.length > 1 ? updateUser(selectedUsers) : updateUser(existingUser)">
                  <font-awesome-icon icon="floppy-disk"/>
                </div>
                <div v-if="existingUser.hasOwnProperty('email') && existingUser.hasOwnProperty('UserStatus') && existingUser.UserStatus !== 'CONFIRMED'"
                     class="icon reset-icon"
                     title="Resend Temporary Password"
                     @click="activeTemporaryPasswordResendUserSub = existingUser.sub">
                  <font-awesome-icon icon="clock-rotate-left"/>
                </div>
              </div>
            </td>
            <td class="hidden-column">
              <ConfirmationModal title="WARNING!"
                                 :message="'Are you sure you want to resend the temporary password to ' + existingUser.email + '?'"
                                 :isOpened="activeTemporaryPasswordResendUserSub === existingUser.sub"
                                 @confirm="resendIndividualUserTemporaryPassword(existingUser.email)"
                                 @cancel="activeTemporaryPasswordResendUserSub = null"
              />
            </td>
          </tr>

          <tr>
            <td colspan="10">
              <font-awesome-icon class="icon add-user" icon="plus" @click="addNewUser('Facilitator')"/>
            </td>
          </tr>

          <tr v-for="(newUser, indexNewUser) in newFacilitators"
              :key="indexNewUser"
          >
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('given_name')" v-model="newUser.given_name" required>
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('family_name')" v-model="newUser.family_name" required>
            </td>
            <td>
              <input type="email" v-if="newUser.hasOwnProperty('email')" v-model="newUser.email" required>
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('address')" v-model="newUser.address">
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('custom:position')" v-model="newUser['custom:position']">
            </td>
<!--            <td>-->
<!--              <input type="text" v-if="newUser.hasOwnProperty('phone_number')" v-model="newUser.phone_number">-->
<!--            </td>-->
            <td>

            </td>
            <td>

            </td>
            <td>

              <span>{{ newUser.Group }}</span>

            </td>
            <td class="sticky-column">
              <div class="icon delete-icon" @click="removeNewUser(indexNewUser, 'Facilitator')">
                <font-awesome-icon icon="trash" class="red-trash"/>
              </div>
            </td>
          </tr>

          </tbody>

        </table>

        <span v-if="multipleEmailsErrorMessageFacilitators" class="error-message"> {{multipleEmailsErrorMessageFacilitators}}</span>

        <div class="button-wrapper">
          <button type="submit"
                  :class="{'btn': true, 'btn-primary': true, 'disabled': newFacilitators.length === 0}"
          >
            Add Facilitators
          </button>
        </div>

      </form>

      <ImportUsers :userType="'Facilitators'"
                   :fromWhere="'UserManagement'"
                   :userTypeKey="'facilitator'"
                   @csvImportComplete="csvImportCompleteHandler"
      />

    </div>

    <div class="table-wrapper">

      <div class="table-header">
        <h2>Participants</h2>
        <div class="icon"
             @click="openAlignExpirationDateUsersModal(participants, 'Participant')">
          <font-awesome-icon icon="list-ul"/>
        </div>
      </div>

      <form id="participants-form" @submit.prevent="verifyEmailsAndSaveNewUsers(newParticipants, 'Participant')">

        <table id="participants-table">

          <thead>
          <tr>
            <th><h2>Given Name</h2></th>
            <th><h2>Family Name</h2></th>
            <th><h2>E-mail</h2></th>
            <th><h2>Address</h2></th>
            <th><h2>Position</h2></th>
<!--            <th><h2>Phone Number</h2></th>-->
            <th><h2>Expiration Date</h2></th>
            <th><h2>Journeys</h2></th>
            <th><h2>Role</h2></th>
            <th class="sticky-column"><h2>Actions</h2></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(existingUser, indexExistingUser) in participants"
              :key="indexExistingUser"
          >
            <td class="user-info">
              <div class="checkbox-wrapper">
<!--                <label class="custom-checkmark-container">-->
<!--                  <input type="checkbox"-->
<!--                         class="checkbox"-->
<!--                         @change="toggleUserSelection(existingUser)"-->
<!--                  >-->
<!--                  <span class="checkmark"></span>-->
<!--                </label>-->
                <input type="text" v-if="existingUser.hasOwnProperty('given_name')" v-model="existingUser.given_name" :disabled="!existingUser.IsEdit">
              </div>
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('family_name')" v-model="existingUser.family_name" :disabled="!existingUser.IsEdit">
            </td>
            <td>
              <input type="email" v-if="existingUser.hasOwnProperty('email')" v-model="existingUser.email" disabled>
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('address')" v-model="existingUser['address']" :disabled="!existingUser.IsEdit">
            </td>
            <td>
              <input type="text" v-if="existingUser.hasOwnProperty('custom:position')" v-model="existingUser['custom:position']" :disabled="!existingUser.IsEdit">
            </td>
<!--            <td>-->
<!--              <input type="text" v-if="existingUser.hasOwnProperty('phone_number')" v-model="existingUser.phone_number" :disabled="!existingUser.IsEdit">-->
<!--            </td>-->
            <td>
              <span v-if="existingUser.hasOwnProperty('custom:expiration_date')">{{ existingUser['custom:expiration_date'] }}</span>
            </td>
            <td>
              <div class="journeys-wrapper">
                <div v-for="(journey, indexJourney) in existingUser.Journeys"
                     :key="indexJourney">
                  <router-link :to="{ name: 'JourneyView', params: { id: journey.ID } }"> {{ journey.Name }} </router-link>
                </div>
              </div>
            </td>
            <td>

              <span v-if="!existingUser.IsEdit">{{ existingUser.Group }}</span>

              <select v-else v-model="existingUser.Group" @change="updateSelectedUsersRoles(existingUser.Group)">
                <option v-for="(role, indexRole) in allRoles"
                        :key="indexRole"
                        :value="role">
                  {{ role }}
                </option>
              </select>

            </td>
            <td class="sticky-column">
              <div class="actions-wrapper">
                <div class="icon delete-icon" @click="openDeleteModal(existingUser, indexExistingUser)">
                  <font-awesome-icon icon="trash"/>
                </div>
                <div class="icon edit-icon" @click="selectedUsers.length > 1 ? toggleEditForSelectedUsers() : (existingUser.IsEdit = !existingUser.IsEdit)">
                  <font-awesome-icon icon="edit"/>
                </div>
                <div v-show="existingUser.IsEdit" class="icon save-icon" @click="selectedUsers.length > 1 ? updateUser(selectedUsers) : updateUser(existingUser)">
                  <font-awesome-icon icon="floppy-disk"/>
                </div>
                <div v-if="existingUser.hasOwnProperty('email') && existingUser.hasOwnProperty('UserStatus') && existingUser.UserStatus !== 'CONFIRMED'"
                     class="icon reset-icon"
                     title="Resend Temporary Password"
                     @click="activeTemporaryPasswordResendUserSub = existingUser.sub">
                  <font-awesome-icon icon="clock-rotate-left"/>
                </div>
              </div>
            </td>
            <td class="hidden-column">
              <ConfirmationModal title="WARNING!"
                                 :message="'Are you sure you want to resend the temporary password to ' + existingUser.email + '?'"
                                 :isOpened="activeTemporaryPasswordResendUserSub === existingUser.sub"
                                 @confirm="resendIndividualUserTemporaryPassword(existingUser.email)"
                                 @cancel="activeTemporaryPasswordResendUserSub = null"
              />
            </td>
          </tr>

          <tr>
            <td colspan="10">
              <font-awesome-icon class="icon add-user" icon="plus" @click="addNewUser('Participant')"/>
            </td>
          </tr>

          <tr v-for="(newUser, indexNewUser) in newParticipants"
              :key="indexNewUser"
          >
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('given_name')" v-model="newUser.given_name" required>
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('family_name')" v-model="newUser.family_name" required>
            </td>
            <td>
              <input type="email" v-if="newUser.hasOwnProperty('email')" v-model="newUser.email" required>
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('address')" v-model="newUser.address">
            </td>
            <td>
              <input type="text" v-if="newUser.hasOwnProperty('custom:position')" v-model="newUser['custom:position']">
            </td>
<!--            <td>-->
<!--              <input type="text" v-if="newUser.hasOwnProperty('phone_number')" v-model="newUser.phone_number">-->
<!--            </td>-->
            <td>

            </td>
            <td>

            </td>
            <td>

              <span>{{ newUser.Group }}</span>

            </td>
            <td class="sticky-column">
              <div class="icon delete-icon" @click="removeNewUser(indexNewUser, 'Participant')">
                <font-awesome-icon icon="trash" class="red-trash"/>
              </div>
            </td>
          </tr>

          </tbody>

        </table>

        <span v-if="multipleEmailsErrorMessageParticipants" class="error-message"> {{multipleEmailsErrorMessageParticipants}}</span>

        <div class="button-wrapper">
          <button type="submit" :class="{'btn': true, 'btn-primary': true, 'disabled': newParticipants.length === 0}">
            Add Participants
          </button>
        </div>

      </form>

      <ImportUsers :userType="'Participants'"
                   :fromWhere="'UserManagement'"
                   :userTypeKey="'participant'"
                   @csvImportComplete="csvImportCompleteHandler"
      />

    </div>

    <ConfirmationModal title="WARNING!"
                       message="Are you sure you want to delete this user?"
                       :isOpened="deleteModalPayload !== null"
                       @confirm="deleteUser()"
                       @cancel="deleteModalPayload = null"
    />

    <ConfirmationModal title="WARNING!"
                       message="Are you sure you want to resend the temporary password to all users that didn't confirm their accounts yet?"
                       :isOpened="isAllTemporaryPasswordResendModalDisplayed"
                       @confirm="resendUnconfirmedUsersTemporaryPassword()"
                       @cancel="isAllTemporaryPasswordResendModalDisplayed = false"
    />

    <transition name="fade">

      <div class="custom_modal add-new-users-modal" v-if="isAddNewUsersModalDisplayed">

        <div class="custom_modal__content">

          <h2>Are you sure you want to add these users?</h2>

          <div v-show="paymentOption !== 'FR'" class="balance-wrapper">
            <span>Your credits: {{ tenantAmount }}</span>
            <span>To pay: {{ priceToPay }}</span>
            <span>Remaining: {{ remainingAmount }}</span>
            <p v-show="remainingAmount < 0" class="error-message">You do not have enough CC.</p>
          </div>

          <div class="buttons-wrapper">
            <button class="btn btn-primary" type="button" :disabled="remainingAmount < 0" @click="saveNewUsers()">SAVE</button>
            <button class="btn btn-primary" type="button" @click="closeAddNewUsersModal()">CANCEL</button>
          </div>

        </div>

        <div class="backdrop" @click="closeAddNewUsersModal()"></div>

      </div>

    </transition>

    <transition name="fade">

      <div class="custom_modal add-new-users-modal" v-if="isAlignExpirationDateUsersModalDisplayed">

        <div class="custom_modal__content">

          <h2>Are you sure you want to align the expiration date of these users?</h2>

          <div v-show="paymentOption !== 'FR'" class="balance-wrapper">
            <span>Your credits: {{ tenantAmount }}</span>
            <span>To pay: {{ alignExpirationDatePriceToPay }}</span>
            <span>Remaining: {{ remainingAlignExpirationDateAmount }}</span>
            <p v-show="remainingAlignExpirationDateAmount < 0" class="error-message">You do not have enough CC.</p>
          </div>

          <div class="buttons-wrapper">
            <button class="btn btn-primary" type="button" :disabled="remainingAlignExpirationDateAmount < 0" @click="alignExpirationDate()">SAVE</button>
            <button class="btn btn-primary" type="button" @click="closeAlignExpirationDateUsersModal()">CANCEL</button>
          </div>

        </div>

        <div class="backdrop" @click="closeAlignExpirationDateUsersModal()"></div>

      </div>

    </transition>

  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex'
import { Icon } from '@iconify/vue';

import { useForm, useField } from 'vee-validate'

import CentralLayout from '@/layouts/CentralLayout.vue';
import MainNav from '@/components/MainNav.vue';
import LogoUpload from '@/components/upload/LogoUpload.vue';
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import ImportUsers from "@/components/users/ImportUsers.vue";
import {calculateDailyPrice, calculateDaysBetween} from "@/helpers/priceCalculations";

export default {

  name: 'UserManagement',

  components: {
    ImportUsers,
    CentralLayout,
    MainNav,
    LogoUpload,
    Icon,
    ConfirmationModal,
  },

  setup() {
    const store = useStore();

    const hasContentLoaded = ref(false);

    const existingUsers = ref([]);

    const tenantAdmins = ref([]);
    const facilitators = ref([]);
    const participants = ref([]);

    const activeEditModalIndex = ref(null);

    const allRoles = ref();

    const modalRoles = ref({
      TenantAdmin: false,
      Facilitator: false,
      Participant: false,
      Manager: false,
      Assistant: false,
    });

    const deleteModalPayload = ref(null);

    const isAllTemporaryPasswordResendModalDisplayed = ref(false);
    const activeTemporaryPasswordResendUserSub = ref(null);

    const multipleEmailsErrorMessageTenantAdmins = ref('');

    const multipleEmailsErrorMessageFacilitators = ref('');

    const multipleEmailsErrorMessageParticipants = ref('');

    const selectedUsers = ref([]);

    const tenantAmount = computed(() => store.getters["internalCurrency/getTenantAmount"]);

    const userRoleCost = computed(() => store.getters["internalCurrency/getUserRoleCost"]);

    const paymentOption = computed(() => store.getters["tenant/getPaymentOption"]);

    allRoles.value = ['TenantAdmin', 'Facilitator', 'Participant'];

    // const customModalPayload = ref(null);

    const openDeleteModal = (deletedUser, indexExistingUser) => {

      deleteModalPayload.value = {
        deletedUserSub: deletedUser.sub,
        indexExistingUser: indexExistingUser
      };
      if (deletedUser.hasOwnProperty('picture')) {
        deleteModalPayload.value.picture = deletedUser.picture;
      }
    };

    const deleteUser = async () => {

      await store.dispatch('loading/setLoading', true);

      await store.dispatch('users/deleteUser', deleteModalPayload.value.deletedUserSub);

      if (deleteModalPayload.value.hasOwnProperty('picture')) {
        const delete_picture_payload = {
          folder_name: 'profile',
          file_name: deleteModalPayload.value.picture
        }

        await store.dispatch('file/deleteFile', delete_picture_payload);

        deleteModalPayload.value = null;
      }

      existingUsers.value = await store.dispatch('users/getExistingUsers');

      separateUsersByRoles();

      await store.dispatch('loading/setLoading', false);

    };

    // const verifyEmails = (newUsers) => {
    //   let isValid = true;
    //   newUsers.forEach((user) => {
    //
    //   })
    // };

    const editUser = (indexExistingUser) => {
      existingUsers.value[indexExistingUser].IsEdit = !existingUsers.value[indexExistingUser].IsEdit;
    };

    const csvImportCompleteHandler = (importedUsers, userTypeKey) => {

      let formattedImportedUsers = [];

      importedUsers.forEach(importedUser => {
        formattedImportedUsers.push({
          given_name: importedUser.given_name,
          family_name: importedUser.family_name,
          email: importedUser.email,
          address: importedUser.country,
          'custom:position': importedUser.status,
          Note: '',
          JourneyRole: '',
          IsEdit: false,
        });
      });

      switch (userTypeKey) {
        case 'tenantAdmin':
          newTenantAdmins.value.push(...formattedImportedUsers);
          break;
        case 'facilitator':
          newFacilitators.value.push(...formattedImportedUsers);
          break;
        case 'participant':
          newParticipants.value.push(...formattedImportedUsers);
          break;
        default:
          break;
      }

    };

    const removeNewUser = async (indexNewUser, group) => {
      // await store.dispatch('loading/setLoading', true);

      switch (group) {

        case 'TenantAdmin': {
          newTenantAdmins.value.splice(indexNewUser, 1);
          break;
        }
        case 'Facilitator': {
          newFacilitators.value.splice(indexNewUser, 1);
          break;
        }
        case 'Participant': {
          newParticipants.value.splice(indexNewUser, 1);
          break;
        }
        default: {

          break;
        }

      }
      // setTimeout(function(){
      //   store.dispatch('loading/setLoading', false);
      // }, 1000);
    };

    const updateUser = async (updatedUser) => {

      await store.dispatch('loading/setLoading', true);

      await store.dispatch('users/updateUser', updatedUser);
      updatedUser.IsEdit = false;

      existingUsers.value = await store.dispatch('users/getExistingUsers');
      separateUsersByRoles();

      await store.dispatch('loading/setLoading', false);
    };

    const separateUsersByRoles = () => {
      tenantAdmins.value = existingUsers.value.filter((user) => user.Group === 'TenantAdmin');
      facilitators.value = existingUsers.value.filter((user) => user.Group === 'Facilitator');
      participants.value = existingUsers.value.filter((user) => user.Group === 'Participant');
    };

    // resend temporary password individually
    const resendIndividualUserTemporaryPassword = async (userEmail) => {
      await store.dispatch('loading/setLoading', true);
      let payload = {
        IsAdminAllUsers: false,
        UsersEmails: [userEmail]
      };
      await store.dispatch('users/resendUsersTemporaryPassword', payload);
      activeTemporaryPasswordResendUserSub.value = null;
      await store.dispatch('loading/setLoading', false);
    };

    // resend temporary password to all users that still need to change their temporary password
    const resendUnconfirmedUsersTemporaryPassword = async () => {
      await store.dispatch('loading/setLoading', true);
      let payload = {
        IsAdminAllUsers: true,
      };
      await store.dispatch('users/resendUsersTemporaryPassword', payload);
      isAllTemporaryPasswordResendModalDisplayed.value = false;
      await store.dispatch('loading/setLoading', false);
    };

    // add new users

    const newTenantAdmins = ref([]);
    const newFacilitators = ref([]);
    const newParticipants = ref([]);

    const isAddNewUsersModalDisplayed = ref(false);
    const newUsersGroupToAdd = ref('');

    const numberOfFreeTenantAdmins = ref(3);
    const priceToPay = ref(0);
    const remainingAmount = ref(0);

    const addNewUser = (group) => {
      let userObject = {
        given_name: '',
        family_name: '',
        email: '',
        address: '',
        'custom:position': '',
        // phone_number: '',
        Group: group
      }

      switch (group) {

        case 'TenantAdmin': {
          newTenantAdmins.value.push(userObject);
          break;
        }
        case 'Facilitator': {
          newFacilitators.value.push(userObject);
          break;
        }
        case 'Participant': {
          newParticipants.value.push(userObject);
          break;
        }
        default: {

          break;
        }

      }

    };

    const calculateNewUsersPriceToPay = (monthlyPrice, numberOfNewUsers) => {
      const currentDate = new Date();
      const expirationDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

      const dailyPrice = calculateDailyPrice(monthlyPrice);
      const daysBetween = calculateDaysBetween(currentDate, expirationDate);
      let totalPrice = dailyPrice * daysBetween * numberOfNewUsers;
      totalPrice = totalPrice.toFixed(2);

      return parseFloat(totalPrice);
    };

    const openAddNewUsersModal = (newUsers, group) => {

      let numberOfNewUsers = newUsers.length;

      switch (group) {

        case 'TenantAdmin': {
          // first 3 tenant admins are free
          let numberOfExistingTenantAdmins = tenantAdmins.value.length;
          if (numberOfExistingTenantAdmins < numberOfFreeTenantAdmins.value) {
            let freeTenantAdmins = numberOfFreeTenantAdmins.value - numberOfExistingTenantAdmins;
            numberOfNewUsers -= freeTenantAdmins;
          }

          priceToPay.value = calculateNewUsersPriceToPay(userRoleCost.value.TenantAdmin, numberOfNewUsers);
          break;
        }
        case 'Facilitator': {
          priceToPay.value = calculateNewUsersPriceToPay(userRoleCost.value.Facilitator, numberOfNewUsers);
          break;
        }
        case 'Participant': {
          priceToPay.value = calculateNewUsersPriceToPay(userRoleCost.value.Participant, numberOfNewUsers);
          break;
        }
        default: {

          break;
        }

      }

      let difference = tenantAmount.value - priceToPay.value;
      difference = difference.toFixed(2);
      remainingAmount.value = parseFloat(difference);
      newUsersGroupToAdd.value = group;
      isAddNewUsersModalDisplayed.value = true;
    };

    const closeAddNewUsersModal = () => {
      isAddNewUsersModalDisplayed.value = false;
      priceToPay.value = 0;
      remainingAmount.value = 0;
      newUsersGroupToAdd.value = '';
    };

    const verifyEmailsAndSaveNewUsers = (users, type) => {

      let newUsersEmails = users.map(user => user.email);
      let existingEmails = existingUsers.value.map(user => user.email);
      let newUsersDuplicateEmails = newUsersEmails.filter((email, index, self) => self.indexOf(email) !== index);
      let existingUsersDuplicateEmails = newUsersEmails.filter(email => existingEmails.includes(email));

      if (newUsersDuplicateEmails.length > 0 || existingUsersDuplicateEmails.length > 0) {
        let errorMessage = '';

        if (newUsersDuplicateEmails.length > 0) {
          errorMessage = `Duplicate emails found within the new users: "${newUsersDuplicateEmails.join(', ')}". `;
        }

        if (existingUsersDuplicateEmails.length > 0) {
          errorMessage = `Email "${existingUsersDuplicateEmails.join(', ')}" already exists.`;
        }

        switch (type) {
          case 'TenantAdmin':
            multipleEmailsErrorMessageTenantAdmins.value = errorMessage;
            break;
          case 'Facilitator':
            multipleEmailsErrorMessageFacilitators.value = errorMessage;
            break;
          case 'Participant':
            multipleEmailsErrorMessageParticipants.value = errorMessage;
            break;
          default:
            break;
        }

      }
      else {

        switch (type) {
          case 'TenantAdmin':
            multipleEmailsErrorMessageTenantAdmins.value = '';
            break;
          case 'Facilitator':
            multipleEmailsErrorMessageFacilitators.value = '';
            break;
          case 'Participant':
            multipleEmailsErrorMessageParticipants.value = '';
            break;
          default:
            break;
        }

        if (users && type) {
          openAddNewUsersModal(users, type);
        }

      }

    };

    const saveNewUsers = async () => {

      let newUsers = [];

      switch (newUsersGroupToAdd.value) {

        case 'TenantAdmin': {
          newUsers = newTenantAdmins.value;
          break;
        }
        case 'Facilitator': {
          newUsers = newFacilitators.value;
          break;
        }
        case 'Participant': {
          newUsers = newParticipants.value;
          break;
        }
        default: {

          break;
        }

      }

      await store.dispatch('loading/setLoading', true);

      let formattedNewUsers = [];

      newUsers.forEach(newUser => {
        let newUserObject = {
          given_name: newUser.given_name,
          family_name: newUser.family_name,
          email: newUser.email,
          'address': newUser.address,
          'custom:position': newUser['custom:position'],
          // phone_number: newUser.phone_number,
          Note: '',
          Group: newUser.Group,
          IsEdit: false,
        };
        formattedNewUsers.push(newUserObject);
      });

      await store.dispatch('users/createUsers', formattedNewUsers);

      existingUsers.value = await store.dispatch('users/getExistingUsers');

      separateUsersByRoles();

      switch (newUsersGroupToAdd.value) {

        case 'TenantAdmin': {
          newTenantAdmins.value = [];
          break;
        }
        case 'Facilitator': {
          newFacilitators.value = [];
          break;
        }
        case 'Participant': {
          newParticipants.value = [];
          break;
        }
        default: {

          break;
        }

      }

      await store.dispatch('internalCurrency/getAmount');

      await store.dispatch('loading/setLoading', false);

      closeAddNewUsersModal();

    };

    // end of add new users


    // align users expiration date

    const isAlignExpirationDateUsersModalDisplayed = ref(false);
    const alignExpirationDateUsersGroup = ref('');
    const alignExpirationDatePriceToPay = ref(0);
    const remainingAlignExpirationDateAmount = ref(0);
    const alignExpirationDateUsers = ref([]);

    const getLatestExpirationDate = (arr) => {
      // Filter the array to get objects with the 'custom:expiration_date' property
      const dates = arr
          .filter(obj => obj['custom:expiration_date'])
          .map(obj => new Date(obj['custom:expiration_date']));

      // If no dates found, return null or an appropriate value
      if (dates.length === 0) {
        return null;
      }

      // Find the latest date
      const latestDate = new Date(Math.max(...dates));

      return latestDate.toDateString(); // Convert back to 'Fri Jul 11 2025' format
    }

    const alignExpirationDate = async () => {

      await store.dispatch('users/alignUsersExpirationDate', {
        Users: alignExpirationDateUsers.value
      });

      await store.dispatch('internalCurrency/getAmount');

      existingUsers.value = await store.dispatch('users/getExistingUsers');

      separateUsersByRoles();

      closeAlignExpirationDateUsersModal();

    }

    const calculateAlignExpirationDatePriceToPay = (group) => {

      const latestExpirationDate = getLatestExpirationDate(alignExpirationDateUsers.value);
      let monthlyPrice = 0;

      switch (group) {

        case 'TenantAdmin': {
          monthlyPrice = userRoleCost.value.TenantAdmin;
          break;
        }
        case 'Facilitator': {
          monthlyPrice = userRoleCost.value.Facilitator;
          break;
        }
        case 'Participant': {
          monthlyPrice = userRoleCost.value.Participant;
          break;
        }
        default: {

          break;
        }

      }

      alignExpirationDateUsers.value.forEach((user) => {
        const startDate = new Date(user['custom:expiration_date']);
        const endDate = new Date(latestExpirationDate);

        const dailyPrice = calculateDailyPrice(monthlyPrice);
        const daysBetween = calculateDaysBetween(startDate, endDate);

        let totalPrice = dailyPrice * daysBetween;
        totalPrice = totalPrice.toFixed(2);
        alignExpirationDatePriceToPay.value += parseFloat(totalPrice);
      });

    };

    const openAlignExpirationDateUsersModal = (alignedUsers, group) => {

      alignedUsers = alignedUsers.map((alignedUser) => {
        return {
          sub: alignedUser.sub,
          email: alignedUser.email,
          Group: alignedUser.Group,
          'custom:expiration_date': alignedUser['custom:expiration_date']
        }
      })

      alignExpirationDateUsers.value = JSON.parse(JSON.stringify(alignedUsers));

      alignExpirationDateUsers.value = alignExpirationDateUsers.value.filter((user) => (user.hasOwnProperty('custom:expiration_date') && user['custom:expiration_date'] !== ''));

      if (paymentOption.value !== 'FR') {
        calculateAlignExpirationDatePriceToPay(group);
        let difference = tenantAmount.value - alignExpirationDatePriceToPay.value;
        difference = difference.toFixed(2);
        remainingAlignExpirationDateAmount.value = parseFloat(difference);
        alignExpirationDateUsersGroup.value = group;
      }

      isAlignExpirationDateUsersModalDisplayed.value = true;

    };

    const closeAlignExpirationDateUsersModal = () => {
      isAlignExpirationDateUsersModalDisplayed.value = false;
      alignExpirationDateUsersGroup.value = '';
      alignExpirationDatePriceToPay.value = 0;
      remainingAlignExpirationDateAmount.value = 0;
      alignExpirationDateUsers.value = [];
    };

    // end of align users expiration date

    const toggleUserSelection = (user) => {
      const index = selectedUsers.value.indexOf(user);
      if (index === -1) {
        selectedUsers.value.push(user);
      } else {
        selectedUsers.value.splice(index, 1);
      }
    };

    const toggleEditForSelectedUsers = () => {
      selectedUsers.value.forEach(user => {
        user.IsEdit = !user.IsEdit;
      });
    };

    const updateSelectedUsersRoles = (newRole) => {
      if (selectedUsers.value.length > 1) {
        selectedUsers.value.forEach(user => {
          user.Group = newRole;
        });
      }
    };


    onMounted(async() => {
      await store.dispatch('loading/setLoading', true);
      existingUsers.value = await store.dispatch('users/getExistingUsers');

      separateUsersByRoles();

      hasContentLoaded.value = true;
      await store.dispatch('loading/setLoading', false);
    })

    return {
      hasContentLoaded,
      existingUsers,

      tenantAdmins,
      facilitators,
      participants,

      newTenantAdmins,
      newFacilitators,
      newParticipants,
      multipleEmailsErrorMessageTenantAdmins,
      multipleEmailsErrorMessageFacilitators,
      multipleEmailsErrorMessageParticipants,
      selectedUsers,
      addNewUser,
      saveNewUsers,
      removeNewUser,
      editUser,
      updateUser,
      deleteModalPayload,
      activeEditModalIndex,
      allRoles,
      modalRoles,
      openDeleteModal,
      deleteUser,
      csvImportCompleteHandler,
      isAddNewUsersModalDisplayed,
      tenantAmount,
      priceToPay,
      paymentOption,
      remainingAmount,
      openAddNewUsersModal,
      closeAddNewUsersModal,
      verifyEmailsAndSaveNewUsers,

      isAllTemporaryPasswordResendModalDisplayed,
      activeTemporaryPasswordResendUserSub,
      resendIndividualUserTemporaryPassword,
      resendUnconfirmedUsersTemporaryPassword,

      isAlignExpirationDateUsersModalDisplayed,
      alignExpirationDatePriceToPay,
      remainingAlignExpirationDateAmount,
      openAlignExpirationDateUsersModal,
      closeAlignExpirationDateUsersModal,
      alignExpirationDate,
      toggleUserSelection,
      toggleEditForSelectedUsers,
      updateSelectedUsersRoles,
    }
  }

}

</script>

<style lang="scss" scoped>

#users-management {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;

  .unconfirmed-users-reset-icon {
    align-self: flex-end;
    width: fit-content;
    font-size: 28px;
  }

  div {
    width: 100%;
  }

  h2 {
    text-align: center;
  }

  .table-wrapper {
    margin-bottom: 20px;

    .table-header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .icon {
        width: fit-content;
      }
    }
    //input[type=email]:disabled {
    //  border: none;
    //  background-color: transparent;
    //}
  }

  table {
    margin: 20px auto;

    thead {
      z-index: 1;
    }

    th {
      h2 {
        font-size: 20px;
        text-align: center;
      }
    }

    td {

      &.user-info {
        display: table-cell;
      }

      //&.actions {
      //  //  .icon {
      //  //    margin-right: 10px;
      //  //    display: inline-block;
      //  //    width: fit-content;
      //  //    cursor: pointer;
      //
      //  //    &:last-of-type {
      //  //      margin-right: 0;
      //  //    }
      //  //  }
      //}

      &.hidden-column {
        width: 0;
        min-width: 0;
        padding: 0;
        border: 0;
        background-color: transparent;
      }

      .checkbox-wrapper {
        display: flex;
        margin: 0 auto;

        label, input {
          cursor: pointer;
        }

        label {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .custom-checkmark-container {
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: var(--primary-color-disabled) !important;
            border-radius: 3px;

            &:after {
              content: "";
              position: relative;
              display: none;
            }
          }

          &:hover input ~ .checkmark,
          & input:checked ~ .checkmark {
            background-color: var(--primary-color) !important;
          }

          & input:checked ~ .checkmark:after {
            display: block;
          }

          & .checkmark:after {
            left: 7px;
            top: 4px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }

    .sticky-column {
      position: sticky;
      right: -1px;
    }

    .actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      width: fit-content;

      .icon {
        justify-content: flex-start;
      }

    }

    .icon {
      font-size: 20px;
    }

    .role {
      text-align: left;
      width: 100%;
    }

    input[type='text'], input[type='email'], select {
      padding: 5px;
      border: 1px solid gray;
      border-radius: 5px;
      width: 100%;
      min-width: 150px;

      &:disabled {
        border: none;
        background-color: transparent;
        color: black;
      }
    }

  }

  .add-user {
    font-size: 24px;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: fit-content;
      margin: 10px auto 0;
    }
  }

  .custom_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;

    &__content {
      background-color: rgba(232, 231, 231);
      width: 590px;
      max-height: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      z-index: 6;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 30px;
      border: 1px solid black;
      font-size: 18px;
      text-align: center;

      h3 {
        font-weight: bold;
        text-transform: uppercase;
      }

      form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        .form-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          width: fit-content;

          .input-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            label, input {
              margin-right: 10px;
              cursor: pointer;
            }
          }

        }

        .buttons-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }

      }

    }

    .backdrop {
      background-color: rgb(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

  }

  .add-new-users-modal {
    .balance-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      width: fit-content;
    }
  }

  .error-message {
    font-style: italic;
    color: red;
    font-size: 16px;
    text-align: center;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}

</style>
