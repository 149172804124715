<template>
    <MobileLayout>
      <template v-slot:default>
  
        <div id="settings-page">
  
           
  
            <Tabs>
              <Tab title="Profile">
                <Profile/>
              </Tab>
              <Tab title="Security">
                <Security/>
              </Tab>
              
            </Tabs>
  
        </div>
  
      </template>
    </MobileLayout>
  </template>
  
  <script>
  import MobileLayout from "@/layouts/MobileLayout.vue";

  import Tab from "@/components/common/Tab.vue";
  import Tabs from "@/components/common/Tabs.vue";
  import Profile from "@/components/settings/Profile.vue";
  import Security from "@/components/settings/Security.vue";
  
  import {useStore} from "vuex";
  import {onMounted} from "vue";
  
  export default {
  
    name: 'MobileSettings',
  
    components: {
      MobileLayout,

      Tabs,
      Tab,
      Profile,
      Security,
    },
  
    setup() {
  
      const store = useStore();
  
      onMounted(() => {
        store.dispatch('loading/setLoading', false)
      });
  
      return {
  
      }
    },
  
  }
  
  </script>
  
  <style lang="scss" scoped>
  
  #settings-page {
    padding: 20px;
  padding-bottom: 80px;
  }

  
  </style>