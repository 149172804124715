<template>
  <div class="mobile-layout" :style="tenantColor">
    <slot />
    <MobileFooter/>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faHome, faUsers, faMapSigns, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { computed } from "vue";
import { useStore } from "vuex";
import MobileFooter from "@/components/mobile/FooterMobile.vue";

library.add(faHome, faUsers, faMapSigns, faBriefcase);

export default {
  name: 'MobileLayout',
  components: {
    FontAwesomeIcon,
    MobileFooter
  },
  setup() {
    const store = useStore();
    const tenantColor = computed(() => {
      return {
        '--primary-color': store.state.tenant.tenantinfo.primarycolor
      }
    });

    const isActive = (route) => {
      return route === window.location.pathname;
    };

    return {
      tenantColor,
      isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

}

.bottom-nav {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.nav-item {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
  position: relative;

  .fa-icon {
    font-size: 24px;
  }

  &.active {
    color: var(--primary-color);

    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 2px;
      background-color: var(--primary-color);
      position: absolute;
      bottom: 0; /* Adjusted to reduce the distance */
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>