<template>
  <CentralLayout>
    <template v-slot:content>

      <div v-if="hasContentLoaded" id="admin-page">

          <small class="text-capitalize">Overview</small>
          <h2>Admin</h2>

<!--        <button @click="createNewTenant()">Create New Tenant</button>-->
<!--        <button @click="populateInternalCurrencyTable()">Populate Internal Currency Table</button>-->

          <Tabs :preselectedTab="preselectedTab">

            <Tab title="Tenant Settings">
              <TenantSettings/>
            </Tab>

            <Tab title="User Management">
              <UserManagement/>
            </Tab>

            <Tab title="Journey Management">

              <div class="journey-management-wrapper">

                <Tabs>

                  <Tab title="Journeys">
                    <JourneyManagement/>
                  </Tab>

                  <Tab title="Categories">
                    <JourneyCategoryManagement/>
                  </Tab>

                  <Tab title="Templates">
                    <JourneyReusableTemplatesManagement/>
                  </Tab>

                  <Tab title="Reusable Assets">
                    <ReusableAssets/>
                  </Tab>

                </Tabs>

              </div>

            </Tab>

            <Tab title="Billing">
              <Billing/>
            </Tab>

          </Tabs>

        </div>


    </template>
  </CentralLayout>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';

import { useForm, useField } from 'vee-validate';

import CentralLayout from '@/layouts/CentralLayout';
import MainNav from '@/components/MainNav';
import LogoUpload from '@/components/upload/LogoUpload';
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import TenantSettings from "@/components/admin/TenantSettings.vue";
import UserManagement from "@/components/admin/UserManagement.vue";
import JourneyManagement from "@/components/admin/JourneyManagement.vue";
import JourneyCategoryManagement from "@/components/admin/JourneyCategoryManagement.vue";
import ReusableAssets from "@/components/admin/ReusableAssets.vue";
import JourneyReusableTemplatesManagement from "@/components/admin/JourneyReusableTemplatesManagement.vue";
import Billing from "@/components/admin/Billing.vue";
export default {

  name: 'Admin',

  components: {
    CentralLayout,
    MainNav,
    LogoUpload,
    Tab,
    Tabs,
    TenantSettings,
    UserManagement,
    JourneyManagement,
    JourneyCategoryManagement,
    ReusableAssets,
    JourneyReusableTemplatesManagement,
    Billing
  },

  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isBillingActive = computed(() => store.getters["internalCurrency/getIsReturnToBilling"]);

    const preselectedTab = ref('');

    // const createNewTenant = async () => {
    //   let payload = {
    //     "description": "Test Tenant Description",
    //     "name": "test",
    //     "primarycolor": "#000000",
    //     "secondarycolor": "#000000",
    //     "stage": "DEV",
    //     "title": "Test Tenant Title",
    //     "logo": ""
    //   };
    //
    //   await store.dispatch('tenant/createTenant', payload);
    // };

    // const populateInternalCurrencyTable = async () => {
    //   await store.dispatch('internalCurrency/populateInternalCurrencyTable');
    // };

    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);

      if (isBillingActive.value) {
        preselectedTab.value = 'Billing';
      }

      hasContentLoaded.value = true;

      await store.dispatch('internalCurrency/setIsReturnToBilling', false);

      await store.dispatch('loading/setLoading', false);
    })

    return {
      hasContentLoaded,
      preselectedTab,
      // createNewTenant,
      // populateInternalCurrencyTable,
    }
  }
}
</script>

<style lang="scss" scoped>

#admin-page {
  padding: 20px;
}

</style>
