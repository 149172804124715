<template>

  <div v-if="hasContentLoaded" class="memory-cards">

    <div v-if="isHeaderDisplayed" class="header-wrapper">

      <div class="title" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">
        <span>HB Memo Cards</span>
        <div v-if="isPlusDisplayed"
             class="icon plus"
             title="Add"
             @click="plusIconClickHandler()"
        >
          <font-awesome-icon icon="plus"/>
        </div>
      </div>

      <div class="actions">

        <div class="search-wrapper">
          <font-awesome-icon class="icon icon-search" icon="magnifying-glass"/>
          <input id="search" name="search" type="text" v-model="searchFilter">
        </div>

        <div class="languages-wrapper">
          <select id="cardsLanguage" name="cardsLanguage" v-model="selectedCardsLanguage">
            <option
                v-for="(language, indexLanguage) in memoryCardsLanguages"
                :key="indexLanguage"
                :value="language"
            >
              {{ language }}
            </option>
          </select>
        </div>

      </div>

    </div>


    <div class="cards-wrapper">

      <div v-for="(card, indexCard) in displayedMemoryCardsList"
           :key="indexCard"
           class="memory-card">

        <MemoryCard :card="card"
                    :isCheckmarkDisplayed="isCheckmarkDisplayed"
                    :isMemoryCardChecked="isMemoryCardChecked(card)"
                    :isGemDisplayed="isMemoryCardAlreadyInJourney(card)"
                    :isEdit="true"
                    :assets="assets"
                    @checkMemoryCard="checkMemoryCardHandler($event)"
                    @uncheckMemoryCard="uncheckMemoryCardHandler($event)"
                    @addAssetTagsMemoryCard="addAssetTagsHandler"
                    @deleteTagMemoryCard="deleteTagHandler"
        />

        <!--        <div v-if="true" class="icon check">-->
        <!--          <font-awesome-icon :icon="['fas', 'circle-check']" />-->
        <!--        </div>-->

        <!--        <div v-else class="icon check">-->
        <!--          <font-awesome-icon :icon="['far', 'circle-check']" />-->
        <!--        </div>-->

      </div>

    </div>

  </div>

</template>

<script>

import {inject, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import Cards from "@/assets/memory_cards/cardDetails";
import MemoryCard from "@/components/common/MemoryCard.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import _ from "lodash";

export default {

  name: 'MemoryCards',

  components: {
    FontAwesomeIcon,
    MemoryCard
  },

  emits: ['checkMemoryCard', 'uncheckMemoryCard', 'plusClicked', 'addAssetTags', 'deleteAssetTags'],

  props: {
    displayAllMemoryCards: {
      type: Boolean,
      default: false,
    },
    checkedMemoryCards: {
      type: Array,
      default: [],
    },
    isCheckmarkDisplayed: {
      type: Boolean,
      default: false,
    },
    displayedMemoryCards: {
      type: Array,
      default: [],
    },
    isPlusDisplayed: {
      type: Boolean,
      default: false,
    },
    isEditJourneyStructure: {
      type: Boolean,
      default: false,
    },
    isHeaderDisplayed: {
      type: Boolean,
      default: true,
    },

    structureItemID: {
      default: null
    },
    indexStructureItem: {
      default: null
    },
    indexUnit: {
      default: null
    },
    assets: {
      type: Array,
      default: [],
    },
  },

  setup(props, {emit}) {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isFirstPageEnter = ref(true);

    const allMemoryCards = ref([]);

    const memoryCardsLanguages = ref(['bg', 'cz', 'de', 'en', 'fr', 'ho', 'hr', 'hu', 'it', 'mx', 'no', 'ro', 'rs', 'se', 'si', 'tr', 'us', 'pl']);

    const selectedCardsLanguage = ref();

    const searchFilter = ref('');

    allMemoryCards.value = Cards;

    const displayedMemoryCardsList = ref([]);

    const displayedMemoryCardsListBackup = ref([]);

    // edit journey structure

    let journeyDetails = ref(null);
    const alreadySelectedMemoryCards = ref([]);

    if (props.isEditJourneyStructure) {
      journeyDetails = inject('journeyDetails');
    }

    const getAlreadySelectedMemoryCards = () => {
      journeyDetails.value.Structure.forEach((structureItem) => {

        if (structureItem.Type === 'Chapter' && structureItem.hasOwnProperty('Units') && structureItem.Units.length > 0) {

          structureItem.Units.forEach((unit) => {

            if (unit.hasOwnProperty('LinkedAssets') && unit.LinkedAssets.length > 0) {
              let memory_cards = unit.LinkedAssets.filter((linkedAsset) => linkedAsset.Type === 'MemoryCard');
              alreadySelectedMemoryCards.value = alreadySelectedMemoryCards.value.concat(memory_cards);
            }

          });

        }
        if ((structureItem.Type === 'Event' || structureItem.Type === 'Assignment') && structureItem.LinkedAssets.length > 0) {

          let memory_cards = structureItem.LinkedAssets.filter((linkedAsset) => linkedAsset.Type === 'MemoryCard');
          alreadySelectedMemoryCards.value = alreadySelectedMemoryCards.value.concat(memory_cards);

        }

      });

    };

    const checkMemoryCardHandler = (ev) => {

      const linkedAssetObject = {
        Type: 'MemoryCard',
        BatchNumber: ev.BatchNumber,
        MemoryCardNumber: ev.MemoryCardNumber,
        IsTreasureChestAsset: true,
        // Tags: [],
        GiveDetails: {
          Event: 'Unlock',
          IsGiven: false,
        },
      };

      let memo_card = allMemoryCards.value.find((card) => card.batchNumber === linkedAssetObject.BatchNumber && card.memoryCardNumber === linkedAssetObject.MemoryCardNumber);

      Object.assign(linkedAssetObject, linkedAssetObject, memo_card);

      if (props.indexUnit !== null) {
        journeyDetails.value.Structure[props.indexStructureItem].Units[props.indexUnit].LinkedAssets.push(linkedAssetObject);
      }
      else {
        // other than unit
        journeyDetails.value.Structure[props.indexStructureItem].LinkedAssets.push(linkedAssetObject);
      }


      alreadySelectedMemoryCards.value.push({
        Type: 'MemoryCard',
        BatchNumber: ev.BatchNumber,
        MemoryCardNumber: ev.MemoryCardNumber,
      });
    };

    const uncheckMemoryCardHandler = (ev) => {

      if (props.indexUnit !== null) {
        journeyDetails.value.Structure[props.indexStructureItem].Units[props.indexUnit].LinkedAssets = journeyDetails.value.Structure[props.indexStructureItem].Units[props.indexUnit].LinkedAssets.filter((linkedAsset) => {
          if (linkedAsset.Type === 'MemoryCard') {
            return (linkedAsset.BatchNumber !== ev.BatchNumber || linkedAsset.MemoryCardNumber !== ev.MemoryCardNumber);
          }
          else {
            return true;
          }
        });
      }
      else {
        // other than unit
        journeyDetails.value.Structure[props.indexStructureItem].LinkedAssets = journeyDetails.value.Structure[props.indexStructureItem].LinkedAssets.filter((linkedAsset) => {
          if (linkedAsset.Type === 'MemoryCard') {
            return (linkedAsset.BatchNumber !== ev.BatchNumber || linkedAsset.MemoryCardNumber !== ev.MemoryCardNumber);
          }
          else {
            return true;
          }
        });
      }

      const index = alreadySelectedMemoryCards.value.findIndex((memoryCards) => (memoryCards.BatchNumber === ev.BatchNumber && memoryCards.MemoryCardNumber === ev.MemoryCardNumber));
      if (index !== -1) {
        // If an object with the specified value is found, remove it from the array
        alreadySelectedMemoryCards.value.splice(index, 1);
      }

    };

    const addAssetTagsHandler = (memoryCardNumber) => {
      emit('addAssetTags', memoryCardNumber);
    };

    const deleteTagHandler = (memoryCardNumber, indexAssetTag) => {
      emit('deleteAssetTags', 'MemoryCard', memoryCardNumber, indexAssetTag);
    };

    const isMemoryCardAlreadyInJourney = (memoryCard) => {

      if (alreadySelectedMemoryCards.value) {
        return alreadySelectedMemoryCards.value.some((checkedMemoryCard) => (checkedMemoryCard.BatchNumber === memoryCard.batchNumber && checkedMemoryCard.MemoryCardNumber === memoryCard.memoryCardNumber));
      }
      return false;

    }

    // end of edit journey structure

    const searchByValue = (newSearch) => {
      let mySearch = newSearch.toLowerCase();

      displayedMemoryCardsList.value = displayedMemoryCardsListBackup.value.filter((memoryCard) => {

        const frontTitle = memoryCard.frontTitle[selectedCardsLanguage.value];
        const frontBottomText = memoryCard.frontBottomText[selectedCardsLanguage.value];

        const matchTitle = frontTitle.toString().toLowerCase().includes(mySearch);
        const matchBottomText = frontBottomText.toString().toLowerCase().includes(mySearch);

        return matchTitle || matchBottomText;

      });

    };

    const plusIconClickHandler = () => {
      emit('plusClicked');
    }

    // check functionality

    const isMemoryCardChecked = (memoryCard) => {
      return props.checkedMemoryCards.some((checkedMemoryCard) => (checkedMemoryCard.BatchNumber === memoryCard.batchNumber && checkedMemoryCard.MemoryCardNumber === memoryCard.memoryCardNumber));
    }

    const filterDisplayedMemoryCards = (memoryCards) => {

      displayedMemoryCardsList.value = [];

      memoryCards.forEach((displayedItem) => {
        if (displayedItem.Type === 'MemoryCard') {
          let memo_card = allMemoryCards.value.find((card) => card.batchNumber === displayedItem.BatchNumber && card.memoryCardNumber === displayedItem.MemoryCardNumber);
          displayedMemoryCardsList.value.push(memo_card);
        }
      });

      displayedMemoryCardsListBackup.value = JSON.parse(JSON.stringify(displayedMemoryCardsList.value));

    }

    // end of check functionality

    watch(selectedCardsLanguage, async (newValue, oldValue) => {

      if (!isFirstPageEnter.value) {
        await store.dispatch('userPreferences/updateLanguage', newValue);
      }

    });

    watch(searchFilter, (newValue) => {

      searchByValue(newValue);

    });

    watch(() => _.cloneDeep(props.displayedMemoryCards), (currentValue, oldValue) => {

          filterDisplayedMemoryCards(currentValue);

        },
        {deep: true}
    );


    onMounted(async () => {

      selectedCardsLanguage.value = store.state.userPreferences.userPreferences.Language;

      if (props.displayAllMemoryCards) {
        displayedMemoryCardsList.value = allMemoryCards.value;
      }
      else {

        filterDisplayedMemoryCards(props.displayedMemoryCards);

      }

      displayedMemoryCardsListBackup.value = JSON.parse(JSON.stringify(displayedMemoryCardsList.value));

      if (props.isEditJourneyStructure) {
        getAlreadySelectedMemoryCards();
      }

      hasContentLoaded.value = true;

      await store.dispatch('loading/setLoading', false);

      isFirstPageEnter.value = false;

    });

    return {
      store,
      hasContentLoaded,
      memoryCardsLanguages,
      displayedMemoryCardsList,
      displayedMemoryCardsListBackup,
      selectedCardsLanguage,
      searchFilter,
      checkMemoryCardHandler,
      uncheckMemoryCardHandler,
      addAssetTagsHandler,
      deleteTagHandler,
      isMemoryCardChecked,
      plusIconClickHandler,
      isMemoryCardAlreadyInJourney,
    }

  }

}

</script>

<style lang="scss" scoped>

.memory-cards {
  width: 100%;
  padding: 20px 50px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 30px;
      text-align: left;
      margin-bottom: 10px;

      .icon.plus {
        font-size: 24px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      .languages-wrapper {

        select {
          padding: 5px 10px;
          border: 1px solid gray;
          border-radius: 5px;
          text-transform: uppercase;
        }

      }

      .search-wrapper {
        position: relative;

        .icon.icon-search {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          opacity: 0.5;
          width: 15px;
        }

        input {
          padding: 5px 25px;
          border: 1px solid gray;
          border-radius: 5px;
          width: 100%;
        }

      }

    }

  }

  .cards-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
    padding: 20px 0;

    .memory-card {
      position: relative;

      .icon.check {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .memory-cards {
    padding: 0;

    .languages-wrapper {
      text-align: left;
    }

    .search-wrapper {
      input {
        width: 50%;
      }
    }

    .cards-wrapper {
      justify-content: center;
    }
  }
}

</style>
