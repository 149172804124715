<template>

  <div class="task-editor">

    <!-- / .content -->

    <div :class="{ 'editorx_body': true, 'disabled-item': isStartDrag }">
      <!--editorjs id-->
      <div :id="'codex-editor-assignment-' + structureItemID + '-task-' + indexTask" />
    </div>

    <!-- / .end-content -->

    <transition name="fade">

      <div v-if="isReusableImagesModalDisplayed" class="custom_modal">

        <div class="custom_modal__content">

          <h1>Reusable images</h1>

          <div class="reusable-images_wrapper">

            <img v-for="(reusableImage, indexReusableImage) in reusableImages"
                 :key="indexReusableImage"
                 class="reusable-image-handle"
                 :src="reusableImage.URL"
                 alt="reusable image"
                 @click="reusableImageClickHandler(reusableImage)"
            >

          </div>

        </div>

        <div class="backdrop" @click="closeReusableImagesModal(true)"></div>

      </div>

    </transition>

  </div>

</template>

<script>

import {inject, onMounted, onUnmounted, ref, watch} from "vue";
import EditorJS from "@editorjs/editorjs";
import TextVariantTune from "@editorjs/text-variant-tune";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import Marker from "@editorjs/marker";
import NestedList from "@editorjs/nested-list";
import Table from "@editorjs/table";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import ImageTool from "@editorjs/image";
import Checklist from "@editorjs/checklist";
import DragDrop from "editorjs-drag-drop";
import Embed from "@editorjs/embed";
import createFileObject from "@/helpers/createFileObject.js";
import {useStore} from "vuex";
import _ from "lodash";

export default {

  name: 'AssignmentTask',

  emits: ['editorDestroyComplete', 'delete'],

  props: {
    structureItemID: {
      required: true,
    },
    indexStructureItem: {
      required: true,
    },
    indexTask: {
      required: true,
    },
    // isSaveClicked: {
    //   type: Boolean,
    //   required: true,
    // },
    reusableImages: {
      type: Array,
      default: [],
    },
    isStartDrag: {
      type: Boolean,
      default: false,
    }

  },

  setup(props, {emit}) {

    class ReusableImage {

      static get toolbox() {

        return {

          title: 'Reusable image',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="20px" height="20px"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"/></svg>'
        };
      }

      constructor({data, api}) {

        if (_.isEmpty(data)) {
          this.data = data;
        }
        else {
          this.data = {
            caption: data.caption || '',
            file: {
              name: data.file.name || '',
              url: data.file.url || '',
            },
            withBorder: data.withBorder !== undefined ? data.withBorder : false,
            withBackground: data.withBackground !== undefined ? data.withBackground : false,
            stretched: data.stretched !== undefined ? data.stretched : false,
          };

          if (data.file.hasOwnProperty('source_key')) {
            this.data.file.source_key = data.file.source_key || '';
          }
          if (data.file.hasOwnProperty('isNew')) {
            this.data.file.isNew = data.file.isNew || false;
          }
        }


        this.api = api;
        this.wrapper = undefined;

        this.settings = [
          {
            name: 'withBorder',
            icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.8 10.592v2.043h2.35v2.138H15.8v2.232h-2.25v-2.232h-2.4v-2.138h2.4v-2.28h2.25v.237h1.15-1.15zM1.9 8.455v-3.42c0-1.154.985-2.09 2.2-2.09h4.2v2.137H4.15v3.373H1.9zm0 2.137h2.25v3.325H8.3v2.138H4.1c-1.215 0-2.2-.936-2.2-2.09v-3.373zm15.05-2.137H14.7V5.082h-4.15V2.945h4.2c1.215 0 2.2.936 2.2 2.09v3.42z"/></svg>`
          },
          {
            name: 'stretched',
            icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`
          },
          {
            name: 'withBackground',
            icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.043 8.265l3.183-3.183h-2.924L4.75 10.636v2.923l4.15-4.15v2.351l-2.158 2.159H8.9v2.137H4.7c-1.215 0-2.2-.936-2.2-2.09v-8.93c0-1.154.985-2.09 2.2-2.09h10.663l.033-.033.034.034c1.178.04 2.12.96 2.12 2.089v3.23H15.3V5.359l-2.906 2.906h-2.35zM7.951 5.082H4.75v3.201l3.201-3.2zm5.099 7.078v3.04h4.15v-3.04h-4.15zm-1.1-2.137h6.35c.635 0 1.15.489 1.15 1.092v5.13c0 .603-.515 1.092-1.15 1.092h-6.35c-.635 0-1.15-.489-1.15-1.092v-5.13c0-.603.515-1.092 1.15-1.092z"/></svg>`
          }
        ];
      }

      render() {

        this.wrapper = document.createElement('div');
        const button = document.createElement('button');
        const buttonDelete = document.createElement('button');
        button.setAttribute('id', 'new-reusable');
        buttonDelete.setAttribute('id', 'new-reusable-delete');
        button.style.display = 'none';
        buttonDelete.style.display = 'none';

        this.wrapper.classList.add('reusable-image');
        this.wrapper.appendChild(button);
        this.wrapper.appendChild(buttonDelete);

        // if not first click on inline tool icon, means it just needs to display what was selected
        if (!_.isEmpty(this.data)) {

          // if wasn't saved to db yet, get another signed url
          if (this.data.file.hasOwnProperty('isNew')) {
            this._createImage(this.data.file.source_key, this.data.caption);
          }
          // if saved in db, don't get another signed url, already did in initComponent
          else {
            this._createImage(null, this.data.caption);
          }
        }
        // if first click on inline tool icon, prompt the user with the reusable images modal
        else {
          skipSaveContent.value = true;
          openReusableImagesModal();
        }

        button.addEventListener('selectReusableImage', async (event) => {
          skipSaveContent.value = false;
          await this._createImage(event.detail.source_key, '');
          await saveContentEditorJS();
        });

        // if user closes reusable modal without selecting anything, delete the element to avoid errors
        buttonDelete.addEventListener('deleteElementEvent',(event) => {
          this._deleteElement(event.detail.indexElement);
        });

        return this.wrapper;
      }

      renderSettings(){
        const wrapper = document.createElement('div');

        this.settings.forEach( tune => {
          let button = document.createElement('div');

          button.classList.add(this.api.styles.settingsButton);
          button.classList.toggle(this.api.styles.settingsButtonActive, this.data[tune.name]);
          button.innerHTML = tune.icon;
          wrapper.appendChild(button);

          button.addEventListener('click', () => {
            this._toggleTune(tune.name);
            button.classList.toggle(this.api.styles.settingsButtonActive);
          });

        });

        return wrapper;
      }

      async _createImage(source_key, captionText){

        if (source_key !== null) {
          let url = await store.dispatch('file/getFile', {
            full_key: source_key
          });

          // let sourceImageName = getImageNameFromKey(source_key);
          // let imageExtension = getExtensionFromImageName(sourceImageName);

          let savedObject = {
            caption: captionText,
            file: {
              isNew: true,
              name: getImageNameFromKey(source_key),
              source_key: source_key,
              url: url,
            },
            stretched: false,
            withBackground: false,
            withBorder: false,
          }

          this.data = savedObject;
        }

        const imageElement = document.createElement('img');
        const captionElement = document.createElement('div');

        imageElement.src = this.data.file.url;
        captionElement.contentEditable = true;
        captionElement.innerHTML = this.data.caption;

        this.wrapper.innerHTML = '';
        this.wrapper.appendChild(imageElement);
        this.wrapper.appendChild(captionElement);

        this._acceptTuneView();
      };

      _toggleTune(tune) {
        this.data[tune] = !this.data[tune];
        this._acceptTuneView();
      };

      _acceptTuneView() {
        this.settings.forEach( tune => {
          this.wrapper.classList.toggle(tune.name, !!this.data[tune.name]);

          if (tune.name === 'stretched') {
            this.api.blocks.stretchBlock(this.api.blocks.getCurrentBlockIndex(), !!this.data.stretched);
          }
        });
      };

      _deleteElement(indexElement) {
        this.api.blocks.delete(indexElement);
      };

      save(blockContent) {

        const caption = blockContent.querySelector('[contenteditable]');
        this.data.caption = caption.innerHTML || '';

        return this.data;

      }

    }


    const store = useStore();

    const journeyDetails = inject('journeyDetails');

    const isCurrentUserAuthorOfJourney = inject('isCurrentUserAuthorOfJourney');

    const editorElement = ref(null);

    const prefilledEditorData = ref({});

    const skipSaveContent = ref(false);

    const hasEditorInit = ref(false);

    const initComponent = async () => {

      // reset editor element when user changes the active task
      if (editorElement.value !== null) {
        editorElement.value.destroy();
      }


      prefilledEditorData.value = {};

      if (journeyDetails.value.hasOwnProperty('Structure')
          && journeyDetails.value.Structure[props.indexStructureItem].hasOwnProperty('Tasks')) {


        let selectedTask = journeyDetails.value.Structure[props.indexStructureItem].Tasks[props.indexTask];

        prefilledEditorData.value = {
          time: selectedTask.Content.Time,
          blocks: selectedTask.Content.Elements,
          version: selectedTask.Content.Version
        }
      }

      if (prefilledEditorData.value.hasOwnProperty('blocks') && prefilledEditorData.value.blocks.length > 0) {
        for (const block of prefilledEditorData.value.blocks) {
          if (block.data.text && block.data.text.indexOf('href') !== -1){
            const regex = /href="(.*?)"/g;
            block.data.text = block.data.text.replace(regex, (match, p1) => {
              return p1.indexOf(location.host) === -1
                  ? `target="_blank" ${match}`
                  : match;
            });
          }

          if (block.type === 'image' && !block.data.file.hasOwnProperty('isNew')) {
            block.data.file.url = await store.dispatch('file/getFile', {
              file_name: block.data.file.name,
              folder_name: `journey/${journeyDetails.value.ID}/assets`
            });
          }
          if (block.type === 'reusableImage' && !block.data.file.hasOwnProperty('isNew')) {
            block.data.file.url = await store.dispatch('file/getFile', {
              file_name: block.data.file.name,
              folder_name: `journey/${journeyDetails.value.ID}/assets`
            });
          }
        }

      }


      await initEditorJS();

    };

    const initEditorJS = async() => {

      await store.dispatch('loading/setLoading', true);

      editorElement.value = new EditorJS({

        holder: `codex-editor-assignment-${props.structureItemID}-task-${props.indexTask}`,
        autofocus: false,
        logLevel: 'ERROR',
        /**
         * This Tool will be used as default
         */
        defaultBlock: 'paragraph',
        tools: {
          textVariant: TextVariantTune,
          header: {
            class: Header,
            tunes: ['anyTuneName'],
            shortcut: 'CMD+SHIFT+H'
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ['anyTuneName'],
            config: {
              placeholder: 'You can start typing to add content to your block'
            }
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: "Quote's author"
            }
          },
          delimiter: {
            class: Delimiter
          },
          marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M'
          },
          list: {
            class: NestedList,
            inlineToolbar: true
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3
            }
          },
          anyTuneName: {
            class: AlignmentTuneTool,
            config: {
              default: 'left',
              blocks: {
                header: 'left',
                list: 'left'
              }
            }
          },
          // no inlineToolbar icon for Embeds, just copy+paste YouTube link in paragraph
          embed: {
            class: Embed,
            inlineToolbar: true,
            config: {
              services: {
                youtube: true,
              }
            }
          },
          // AnyButton: {
          //   class: AnyButton,
          //   inlineToolbar: true,
          //   config: {
          //     css: {
          //       btnColor: 'black'
          //     }
          //   }
          // },
          checklist: {
            class: Checklist,
            inlineToolbar: true
          },
          image: {
            class: ImageTool,
            tunes: [],
            config: {
              uploader: {
                async uploadByFile(file) {
                  return await createFileObject(file);
                },
              },
            },
            // uploader: {
            /**
             * Upload file to the server and return an uploaded image data
             * @param {File} file - file selected from the device or pasted by drag-n-drop
             * @return {Promise.<{success, file: {url}}>}
             */
            // uploadByFile(file) {
            //   console.log('file')
            //   console.log(file)
            //   // your own uploading logic here
            //   axios.defaults.baseURL = this.$store.state.tenant.tenantinfo.baseAPI
            //   let config = {
            //     headers: {
            //       Authorization: this.$store.state.auth.bearer,
            //       'Content-Type': 'multipart/form-data'
            //     }
            //   }
            //   console.log('axios default')
            //   console.log(axios.defaults.baseURL)
            //   console.log(config)
            //   let formData = new FormData()
            //   formData.append('file', file)
            //   console.log('formdata')
            //   console.log(formData)
            //   console.log('start axios')
            //   axios
            //     .post('/upload/image/upload', formData, config)
            //     .then(function(response) {
            //       console.log(response)
            //       return {
            //         success: 1,
            //         file: {
            //           url:
            //             'https://codex.so/upload/redactor_images/o_80beea670e49f04931ce9e3b2122ac70.jpg'
            //           // any other image data you want to store, such as width, height, color, extension, etc
            //         }
            //       }
            //     })
            //     .catch(function(error) {
            //       console.log(error)
            //       return {
            //         success: 0,
            //         file: {
            //           url:
            //             'https://codex.so/upload/redactor_images/o_80beea670e49f04931ce9e3b2122ac70.jpg'
            //           // any other image data you want to store, such as width, height, color, extension, etc
            //         }
            //       }
            //     })
            // },
            /**
             * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
             * @param {string} url - pasted image URL
             * @return {Promise.<{success, file: {url}}>}
             */
            //   uploadByUrl(url) {
            //     // your ajax request for uploading
            //     // return MyAjax.upload(url).then(() => {
            //     return {
            //       success: 1,
            //       file: {
            //         url:
            //           'https://codex.so/upload/redactor_images/o_e48549d1855c7fc1807308dd14990126.jpg'
            //         // any other image data you want to store, such as width, height, color, extension, etc
            //       }
            //     }
            //     // })
            //   }
            // },
            //   endpoints: {
            //     byFile:
            //         'https://9xd35yw450.execute-api.eu-west-1.amazonaws.com/upload/image/upload'
            //     //byFile: 'https://api.dev.commovis.com/upload/image/upload' // Your backend file uploader endpoint
            //     // byUrl: 'http://localhost:8008/fetchUrl' // Your endpoint that provides uploading by Url
            //   },
            //   types: 'image/png, image/jpg',
            //   additionalRequestHeaders: {
            //     authorization: store.state.auth.bearer
            //   }
            // }
          },
          reusableImage: {
            class: ReusableImage,
            inlineToolbar: true,
            tunes: [],
          },
        },
        tunes: ['textVariant'],

        data: prefilledEditorData.value,

        onReady: () => {
          // console.log('ready')
          hasEditorInit.value = true;
          store.dispatch('loading/setLoading', false);
          new DragDrop(editorElement.value);

          //new Undo(editorElement.value)
          //const undo = new Undo(editorElement.value)
          // undo.initialize(initialData)
        },
        onChange: function() {
          if (!skipSaveContent.value) {
            saveContentEditorJS();
          }
        }
      });

    };

    const saveContentEditorJS = async() => {
      editorElement.value.save().then(async (savedData) => {

        let payload = {};
        payload.indexStructureItem = props.indexStructureItem;
        payload.indexTask = props.indexTask;
        payload.content = {
          Time: savedData.time,
          Elements: savedData.blocks,
          Version: savedData.version,
        }

        journeyDetails.value.Structure[payload.indexStructureItem].Tasks[payload.indexTask].Content = payload.content;

      });
    };

    const toggleIsHidden = () => {
      journeyDetails.value.Structure[props.indexStructureItem].IsHidden = !journeyDetails.value.Structure[props.indexStructureItem].IsHidden;
    };

    // reusable assets modal

    const isReusableImagesModalDisplayed = ref(false);

    const openReusableImagesModal = async() => {

      for (const image of props.reusableImages) {
        image.URL = await store.dispatch('file/getFile', {
          full_key: image.Key,
        });
      }
      isReusableImagesModalDisplayed.value = true;
    };

    const closeReusableImagesModal = (isDeleteBlock) => {

      // if user closes reusable modal without selecting anything, delete the element to avoid errors
      if (isDeleteBlock) {
        var buttonDelete = document.getElementById("new-reusable-delete");

        if (buttonDelete) {

          // not -1 because I need to delete the element which is not yet in my journey
          let indexElement = journeyDetails.value.Structure[props.indexStructureItem].Tasks[props.indexTask].Content.Elements.length;

          var event = new CustomEvent('deleteElementEvent', { detail: { indexElement: indexElement } });

          // Trigger a click event on the button
          buttonDelete.dispatchEvent(event);
        }
      }
      isReusableImagesModalDisplayed.value = false;

    };

    const getImageNameFromKey = (key) => {
      const parts = key.split('/');

      // Get the last part of the path, which is the filename
      const filename = parts[parts.length - 1];

      return filename;
    };

    const reusableImageClickHandler = (reusableImage) => {

      var button = document.getElementById("new-reusable");

      if (button) {

        var event = new CustomEvent('selectReusableImage', { detail: { source_key: reusableImage.Key } });

        // Trigger a click event on the button
        button.dispatchEvent(event);
      }
      closeReusableImagesModal(false);
    };

    // end of reusable assets modal


    watch(() => props.isStartDrag, (newValue, oldValue) => {
      if (!newValue) {
        initComponent()
      }
    });

    onUnmounted(async () => {
      if (hasEditorInit.value) {
        await editorElement.value.destroy();
        emit('editorDestroyComplete', {
          indexStructureItem: props.indexStructureItem,
          indexTask: props.indexTask,
        });
      }
    });

    onMounted(async() => {
      await initComponent();
    })

    return {
      journeyDetails,
      isCurrentUserAuthorOfJourney,
      toggleIsHidden,

      isReusableImagesModalDisplayed,
      openReusableImagesModal,
      closeReusableImagesModal,
      reusableImageClickHandler,
    }
  }
}

</script>

<style lang="scss" scoped>

.task-editor {
  width: 100%;

  .custom_modal {

    &__content {
      display: block;
      width: 80%;
      min-height: 80%;
      max-height: 80%;

      .reusable-images_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;

        img {
          max-width: 200px;
          cursor: pointer;
        }

      }

    }

  }

  .disabled-item {
    pointer-events: none;
    background-color: #efefef;
  }

}

</style>

<style lang="scss">

.task-editor {

  .ce-toolbar {


    .ce-toolbar__content {

      display: flex;
      align-items: center;
      justify-content: flex-start;

      .ce-toolbar__actions {
        left: -50px;
        right: unset;

        .ce-toolbar__settings-btn {
          background-color: #6b6868c7;
          border-radius: 5px;

          svg {
            fill: #fff;
          }
        }

      }

    }


  }

  .reusable-image {
    padding: 20px 0;

    input, [contenteditable] {
      width: 100%;
      padding: 10px;
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      outline: none;
      font-size: 14px;
    }

    img {
      max-width: 100%;
      margin-bottom: 15px;
    }

    &.stretched {
      img {
        width: 100%;
      }
    }

    &.withBorder {
      img {
        border: 1px solid #e8e8eb;
      }
    }

    &.withBackground {
      background: #eff2f5;
      padding: 10px;

      img {
        display: block;
        max-width: 60%;
        margin: 0 auto 15px;
      }
    }

  }

}

</style>
