<template>

  <div class="languages-wrapper">
    <button @click="changeLanguage('bg')">BG</button>
    <button @click="changeLanguage('cz')">CZ</button>
    <button @click="changeLanguage('de')">DE</button>
    <button @click="changeLanguage('en')">EN</button>
    <button @click="changeLanguage('fr')">FR</button>
    <button @click="changeLanguage('ho')">HO</button>
    <button @click="changeLanguage('hr')">HR</button>
    <button @click="changeLanguage('hu')">HU</button>
    <button @click="changeLanguage('it')">IT</button>
    <button @click="changeLanguage('mx')">MX</button>
    <button @click="changeLanguage('no')">NO</button>
    <button @click="changeLanguage('ro')">RO</button>
    <button @click="changeLanguage('rs')">RS</button>
    <button @click="changeLanguage('se')">SE</button>
    <button @click="changeLanguage('si')">SI</button>
    <button @click="changeLanguage('tr')">TR</button>
    <button @click="changeLanguage('us')">US</button>
  </div>

  <div v-if="hasContentLoaded" id="memory-cards" v-cloak>

    <div v-for="(card, indexCard) in cards"
         :key="indexCard"
         class="cards-wrapper"
    >
      <MemoryCard :card="card"
                  :giveCardButtonDisplayed="true"
      />
    </div>

  </div>

</template>

<script>

import MemoryCard from "@/components/common/MemoryCard.vue";
import {onMounted, provide, ref} from "vue";
import logo from "@/assets/logo_long.png";
import SVGUpload from "@/components/upload/SVGUpload.vue";
import {useStore} from "vuex";
import {v4 as uuidv4} from "uuid";
import ImageUpload from "@/components/upload/ImageUpload.vue";

import InlineSvg from "vue-inline-svg";
import Cards from "@/assets/memory_cards/cardDetails";

export default {

  name: 'MemoryCards',

  components: {
    InlineSvg,
    ImageUpload,
    SVGUpload,
    MemoryCard
  },

  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const usersLinkedToCurrentUser = ref([]);

    const cards = ref([]);

    cards.value = Cards;

    const changeLanguage = (language) => {
      store.dispatch('userPreferences/updateLanguage', language);
    };

    // const pictureReadyHandler = async (ev, isFront) => {
    //
    //   if (isFront) {
    //     cardDetails.value.front.topImageUpdated = ev;
    //   }
    //   else {
    //     cardDetails.value.back.topImageUpdated = ev;
    //   }
    //
    //   await store.dispatch('loading/setLoading', false);
    //
    // };

    // const svgReadyHandler = (ev, isFront) => {
    //
    //   if (isFront) {
    //     cardDetails.value.front.middleSVGUpdated = ev;
    //   }
    //   else {
    //     cardDetails.value.back.middleSVGUpdated = ev;
    //   }
    //
    //
    //   store.commit('loading/setLoading', false);
    //   store.commit('file/setUploadVisible', false);
    // };

    // const svgContent = ref(null);

    // const saveCardDetails = async () => {
    //
    //   console.log(cardDetails.value);
    //
    //   // const filename = uuidv4();
    //   //
    //   // const payload = {
    //   //   name: filename,
    //   //   ext: 'svg',
    //   //   type: tempLoadSVG.value.type,
    //   //   file: tempLoadSVG.value.src,
    //   //   folder_name: 'memory_cards',
    //   // }
    //   //
    //   // await store.dispatch('file/uploadFile', payload);
    //
    // };
    //
    // const getSvg = async () => {
    //   svgContent.value = await store.dispatch('file/getSVG', {
    //     file_name: '49dc7503-0ce1-40c1-be10-ce3bed3702f8.svg',
    //     folder_name: `memory_cards`
    //   });
    //   // console.log(signedURL);
    //   // const svgResponse = await axios.get(signedURL, { responseType: 'text' });
    //   //
    //   // svgContent.value = svgResponse.data;
    // }

    const getUsersLinkedToCurrentUser = async () => {
      usersLinkedToCurrentUser.value = await store.dispatch('users/getUsersLinkedToCurrentUser', {});
    };

    provide('usersLinkedToCurrentUser', usersLinkedToCurrentUser);

    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);
      await getUsersLinkedToCurrentUser();
      hasContentLoaded.value = true;
      await store.dispatch('loading/setLoading', false);
    });

    return {
      hasContentLoaded,
      cards,
      changeLanguage,
      usersLinkedToCurrentUser,
      // cardDetails,
      // svgContent,
      // saveCardDetails,
      // getSvg,
      // pictureReadyHandler,
      // svgReadyHandler,
    }
  }

}

</script>

<style lang="scss" scoped>

#memory-cards {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
  padding: 20px;

  .customize-wrapper {

    .front, .back {
      margin-top: 50px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 15px;

      .entry-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
      }

    }

  }

}

</style>