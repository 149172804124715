<template>

  <div :style="{'max-height': maxHeight + 'px', 'min-height': maxHeight + 'px' }" id="progress-sidebar">

    <div class="actions-wrapper">

      <div class="actions">

        <div class="icon"
             :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
             @click="goToJourneyAction(journeyDetails.ID, 'JourneyMap')"
        >
          <font-awesome-icon
              icon="map-location-dot"
              title="Go To Journey Map"
          />
        </div>

        <div class="icon"
             :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
             v-if="router.currentRoute._value.name === 'JourneyCreation'"
             @click="goToJourneyAction(journeyDetails.ID, 'JourneyView')"
        >
          <font-awesome-icon
              :icon="['far', 'circle-play']"
              title="Go To Journey View"
          />
        </div>

        <div class="icon"
             :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
             v-if="router.currentRoute._value.name === 'JourneyView' && isCurrentUserAuthorOfJourney"
             @click="goToJourneyAction(journeyDetails.ID, 'JourneyCreation')"
        >
          <font-awesome-icon
              :icon="['fas', 'edit']"
              title="Go To Journey Edit"
          />
        </div>

      </div>

<!--      <div class="menu-icon"-->
<!--          :class="{ 'sidebar-displayed': isSidebarDisplayed }"-->
<!--          :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"-->
<!--          @click="toggleSidebar()"-->
<!--      >-->
<!--        <font-awesome-icon-->
<!--            class="close-icon"-->
<!--            icon="xmark"-->
<!--            title="Close Sidebar"-->
<!--        />-->
<!--      </div>-->

      <div v-if="isCreation && isCurrentUserAuthorOfJourney"
           :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
           :class="{ icon: true, save: true, 'save-disabled': isSaveDisabled }"
           title="Save journey"
           @click="saveClickHandler()"
      >

        <font-awesome-icon icon="floppy-disk"/>

      </div>

      <div v-if="!isCreation"
           class="close-icon"
           :class="{ 'sidebar-displayed': isSidebarDisplayed }"
           :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
           @click="toggleSidebar()"
      >
        <font-awesome-icon
            icon="xmark"
            title="Close Sidebar"
        />
      </div>

    </div>

    <div v-if="isCreation" id="sidebar-content-journey-creation" class="path">

      <draggable v-model="journeyDetails.Structure"
                 class="structure-wrapper"
                 item-key="ID"
                 @start="resetActiveStructureItem()"
                 @end="dropStructureItemHandler($event)"
      >

        <template #item="{ element: structureItem, index: indexStructureItem }">

          <div class="structure-item"
               :style="{ paddingLeft: (structureItem.Type === 'Event' || structureItem.Type === 'Assignment') ? '0' : '20px'}"
          >

            <!-- Display your chapter content here -->

            <div v-if="structureItem.Type === 'Chapter'" class="chapter">

              <div class="structure-connection-branch"></div>

              <div class="structure-connection"></div>

              <div :class="{'title-wrapper': true, 'active-element': isActiveElement(structureItem.ID, null) }" @click="toggleUnits($event)">

                <div v-if="isCurrentUserAuthorOfJourney && !structureItem.IsHidden && structureItem.IsLocked"
                     class="icon lock_closed-icon"
                     :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
                >
                  <font-awesome-icon
                      icon="lock"
                      title="Locked Chapter"
                  />
                </div>

                <div v-if="isCurrentUserAuthorOfJourney && !structureItem.IsHidden && !structureItem.IsLocked"
                     class="icon lock_opened-icon"
                     :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
                >
                  <font-awesome-icon
                      icon="lock-open"
                      title="Unlocked Chapter"
                  />
                </div>

                <div v-if="isCurrentUserAuthorOfJourney && structureItem.IsHidden"
                     class="icon hidden-icon"
                     :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
                >
                  <font-awesome-icon
                      icon="eye-slash"
                  />
                </div>

                <input type="text"
                       v-model="structureItem.Name"
                       placeholder="Chapter name"
                       :readonly="isMobileOrLaptopScreen"
                       @click="goToStructureItem(structureItem.ID)"
                       class="chapter_input"
                >

                <div class="icon angle-down">
                  <font-awesome-icon
                      icon="angle-down"
                      title="See Less"
                  />
                </div>

                <div style="display: none" class="icon angle-right">
                  <font-awesome-icon
                      icon="angle-right"
                      title="See More"
                  />
                </div>

                <div v-if="isCurrentUserAuthorOfJourney && journeyHasAtLeastOneChapter()" class="icon delete-icon"
                     @click="deleteChapter(structureItem.ID, indexStructureItem)">
                  <font-awesome-icon
                      icon="trash"
                      title="Delete Chapter"
                  />
                </div>

              </div>

              <draggable v-if="structureItem.hasOwnProperty('Units')"
                         v-model="structureItem.Units"
                         class="units-wrapper"
                         :group="{ name: 'units' }"
                         item-key="indexUnit"
                         :move="checkUnitMove"
                         @start="resetActiveStructureItem()"
              >

                <template #item="{ element: unit, index: indexUnit }">

                  <div class="unit-wrapper">

                    <div class="unit-connection-branch"></div>

                    <div class="unit-connection"></div>

                    <div :class="{ unit: true, 'active-element': isActiveElement(structureItem.ID, indexUnit) }">

                      <div class="icon bars-staggered"
                           :style="{ 'color': isActiveElement(structureItem.ID, indexUnit) ? store.state.tenant.tenantinfo.primarycolor : 'grey' }"
                      >
                        <font-awesome-icon v-if="indexUnit < structureItem.Units.length - 1"
                            icon="bars-staggered"
                        />
                        <font-awesome-icon v-else
                            icon="plus"
                        />
                      </div>

                      <input type="text"
                             v-model="unit.Name"
                             placeholder="add unit"
                             :readonly="isMobileOrLaptopScreen"
                             @click="$event.target.focus()"
                             @focus="editUnit(structureItem.ID, indexUnit)"
                             @input="unitChangeHandler(indexStructureItem, indexUnit)"
                      >

                      <div v-if="isCurrentUserAuthorOfJourney && structureItem.Units.length > 2 && indexUnit < structureItem.Units.length - 1"
                           class="icon delete-icon"
                           @click="deleteUnit(structureItem.ID, indexStructureItem, indexUnit)">
                        <font-awesome-icon
                            icon="trash"
                            title="Delete Unit"
                        />
                      </div>

                    </div>

                    <draggable v-if="!isActiveElement(structureItem.ID, indexUnit) && (indexUnit < structureItem.Units.length - 1)"
                               v-model="unit.Blocks"
                               :class="{ 'unit-block_wrapper': isStartBlockDrag }"
                               item-key="ID"
                               handle=".block-handle"
                               :group="{ name: 'blocks' }"
                    >

                      <template #item="{ element: block, index: indexBlock }">
                        <div v-if="indexBlock === 0" class="block_preview">

                        </div>
                      </template>

                    </draggable>

                  </div>

                </template>

              </draggable>

            </div>

            <div v-if="structureItem.Type === 'Event'"
                 :class="{'event': true, 'active-element': isActiveElement(structureItem.ID, null) }"
                 @click="goToStructureItem(structureItem.ID)"
            >

              <div v-if="structureItem.Options.IsModule" class="module-divider">

                <div class="divider"></div>

                <div v-if="structureItem.Options.IsModule"
                     class="circle-wrapper"
                >
                  <svg viewBox="0 0 36 36"
                       class="circle"
                  >
                    <path class="circle-background"
                          :style="{ stroke: (calculateModulesPercentage.length > 0) ? '#d3d3d3' : '#000000' }"
                          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path :class="{'circle-outline': true}"
                          :style="{ strokeDasharray: (calculateModulesPercentage.length > 0) ? `${calculateModulesPercentage[journeyModules.findIndex(module => module.ID === structureItem.ID)].moduleProgressPercentage}, 100` : '0, 100', strokeWidth: (calculateModulesPercentage.length > 0) ? '2' : '0'}"
                          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <div class="circle-element">
                    <span>{{ journeyModules.findIndex(module => module.ID === structureItem.ID) + 1 }}</span>
                  </div>
                </div>

                <div class="divider"></div>

              </div>

              <div class="event-row space-between">

                <div class="title-wrapper structure-item-handle">

                  <input type="text"
                         v-model="structureItem.Name"
                         placeholder="Event name"
                         class="title-wrapper"
                         :class="{ module: structureItem.Options.IsModule }"
                         :readonly="isMobileOrLaptopScreen"
                         @click="$event.target.focus()"
                         @focus="goToStructureItem(structureItem.ID)"
                  >

                </div>

                <div v-if="indexStructureItem !== 0" class="icon delete-icon" @click="deleteEvent(structureItem.ID, indexStructureItem)">
                  <font-awesome-icon
                      icon="trash"
                      title="Delete Event"
                  />
                </div>

              </div>

              <div v-if="structureItem.Options.IsMeeting && structureItem.Periods.From !== null"
                   class="event-row meeting-content"
                   @click="goToStructureItem(structureItem.ID)"
              >
                <font-awesome-icon
                    :icon="['far', 'calendar']"
                />
                <span>{{ convertDateToDisplayFormatWithoutTime(structureItem.Periods.From) }}</span>
              </div>

              <div v-if="structureItem.Options.IsUnlock && structureItem.Periods.Unlock !== null"
                   class="event-row unlock-content"
                   @click="goToStructureItem(structureItem.ID)"
              >
                <font-awesome-icon
                    icon="lock-open"
                />
                <span>{{ convertDateToDisplayFormatWithTime(structureItem.Periods.Unlock) }}</span>
              </div>

            </div>

            <div v-if="structureItem.Type === 'Assignment'" class="assignment">

              <div :class="{'title-wrapper': true, 'active-element': isActiveElement(structureItem.ID, null) }">

                <div class="icon assignment">
                  <font-awesome-icon
                      icon="file-pen"
                  />
                </div>

                <input type="text"
                       v-model="structureItem.Name"
                       placeholder="Assignment name"
                       :readonly="isMobileOrLaptopScreen"
                       @click="goToStructureItem(structureItem.ID)"
                >

                <div v-if="isCurrentUserAuthorOfJourney" class="icon delete-icon"
                     @click="deleteChapter(structureItem.ID, indexStructureItem)">
                  <font-awesome-icon
                      icon="trash"
                      title="Delete Chapter"
                  />
                </div>

              </div>

            </div>

            <div v-if="isCurrentUserAuthorOfJourney"
                 class="add-buttons_wrapper"
                 :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }"
            >

              <font-awesome-icon
                  icon="square-plus"
                  class="icon add-button"
                  @click="toggleAddOptionsHandler('add-options-' + indexStructureItem)"
              />

              <div class="add-options" :style="{ display: 'none'}" :id="'add-options-' + indexStructureItem">

                <font-awesome-icon
                    :icon="['far', 'calendar-plus']"
                    class="icon add-event"
                    title="Add Event"
                    @click="addStructureItem(indexStructureItem, 'Event')"
                />

                <font-awesome-icon
                    icon="book"
                    class="icon add-chapter"
                    title="Add Chapter"
                    @click="addStructureItem(indexStructureItem, 'Chapter')"
                />

                <font-awesome-icon
                    icon="list-check"
                    class="icon add-assignment"
                    title="Add Assignment"
                    @click="addStructureItem(indexStructureItem, 'Assignment')"
                />

              </div>

            </div>

          </div>

        </template>

      </draggable>

    </div>

    <div v-else id="sidebar-content-journey-view" class="path">

      <div v-if="hasContentLoaded"
           v-for="(structureItem, indexStructureItem) in journeyDetails.Structure"
           :key="indexStructureItem"
           class="structure-item"
           :style="{ paddingLeft: (structureItem.Type === 'Event' || structureItem.Type === 'Assignment') ? '0' : '20px'}"
      >

        <div v-if="structureItem.Type === 'Chapter' && !structureItem.IsHidden" :class="{chapter: true, locked: structureItem.IsLocked}">

          <div class="structure-connection-branch"></div>

          <div class="structure-connection"></div>

          <div class="title-wrapper" @click="toggleUnits($event)">

            <span :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">{{ structureItem.Name }}</span>

            <div v-if="!structureItem.IsLocked" class="icon angle-down">
              <font-awesome-icon
                  icon="angle-down"
                  title="See Less"
              />
            </div>

            <div v-if="!structureItem.IsLocked" style="display: none" class="icon angle-right">
              <font-awesome-icon
                  icon="angle-right"
                  title="See More"
              />
            </div>

          </div>

          <div v-if="!structureItem.IsLocked" class="units-wrapper">

              <div v-for="(unit, indexUnit) in structureItem.Units"
                   :key="indexUnit"
                   :class="{ unit: true, 'active-element': isActiveElement(structureItem.ID, indexUnit) }"
                   @click="goToUnit(structureItem.ID, indexUnit)"
              >

                <div class="unit-connection-branch"></div>

                <div class="unit-connection"></div>

                <div class="icon bars-staggered" :style="{ 'color': isActiveElement(structureItem.ID, indexUnit) ? store.state.tenant.tenantinfo.primarycolor : 'grey' }">
                  <font-awesome-icon
                      icon="bars-staggered"
                  />
                </div>

                <span>{{ unit.Name }}</span>

                <div v-if="unit.Completed" class="icon" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">
                  <font-awesome-icon
                      icon="circle-check"
                  />
                </div>

              </div>

          </div>

        </div>

        <div v-if="structureItem.Type === 'Event'"
             :class="{'event': true, 'active-element': isActiveElement(structureItem.ID, null) }"
             @click="goToStructureItem(structureItem.ID)"
        >

          <div v-if="structureItem.Options.IsModule" class="module-divider">

            <div class="divider"></div>

            <div v-if="structureItem.Options.IsModule"
                 class="circle-wrapper"
            >
              <svg viewBox="0 0 36 36"
                   class="circle"
              >
                <path class="circle-background"
                      :style="{ stroke: (calculateModulesPercentage.length > 0) ? '#d3d3d3' : '#000000' }"
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path :class="{'circle-outline': true}"
                      :style="{ strokeDasharray: (calculateModulesPercentage.length > 0) ? `${calculateModulesPercentage[journeyModules.findIndex(module => module.ID === structureItem.ID)].moduleProgressPercentage}, 100` : '0, 100', strokeWidth: (calculateModulesPercentage.length > 0) ? '2' : '0'}"
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
              </svg>
              <div class="circle-element">
                <span v-if="journeyDetails.hasOwnProperty('ParticipantProgress') &&  Math.floor(calculateModulesPercentage[journeyModules.findIndex(module => module.ID === structureItem.ID)].moduleProgressPercentage) < 100" class="percentage">{{ Math.floor(calculateModulesPercentage[journeyModules.findIndex(module => module.ID === structureItem.ID)].moduleProgressPercentage) }}%</span>
                <span v-else-if="journeyDetails.hasOwnProperty('ParticipantProgress') &&  Math.floor(calculateModulesPercentage[journeyModules.findIndex(module => module.ID === structureItem.ID)].moduleProgressPercentage) === 100" class="percentage icon">
                  <font-awesome-icon
                      icon="check"
                  />
                </span>
                <span v-else class="module">{{ journeyModules.findIndex(module => module.ID === structureItem.ID) + 1 }}</span>
              </div>
            </div>

            <div class="divider"></div>

          </div>

          <div class="event-row space-between">

            <div class="title-wrapper">

              <span class="title-wrapper" :class="{ module: structureItem.Options.IsModule }">{{ structureItem.Name }}</span>

            </div>

          </div>

          <div v-if="structureItem.Options.IsMeeting && structureItem.Periods.From !== null"
               class="event-row meeting-content">

            <font-awesome-icon
                :icon="['far', 'calendar']"
            />

            <span>{{ convertDateToDisplayFormatWithoutTime(structureItem.Periods.From) }}</span>

          </div>

          <div v-if="structureItem.Options.IsUnlock
          && structureItem.Periods.Unlock !== null
          && (countdowns[indexStructureItem].days > 0 || countdowns[indexStructureItem].hours > 0 || countdowns[indexStructureItem].minutes > 0)"
               class="event-row unlock-content">

            <font-awesome-icon
                icon="lock-open"
            />
            <span v-if="countdowns[indexStructureItem].days > 0">{{ countdowns[indexStructureItem].days }} days </span>
            <span v-if="countdowns[indexStructureItem].days > 0 || countdowns[indexStructureItem].hours > 0">{{ countdowns[indexStructureItem].hours }} h </span>
            <span v-if="countdowns[indexStructureItem].days > 0 || countdowns[indexStructureItem].hours > 0 || countdowns[indexStructureItem].minutes > 0">{{ countdowns[indexStructureItem].minutes }} min </span>

          </div>

        </div>

        <div v-if="structureItem.Type === 'Assignment'"
             :class="{'assignment': true, 'active-element': isActiveElement(structureItem.ID, null), locked: structureItem.IsLocked }"
             @click="goToStructureItem(structureItem.ID)"
        >

          <div class="title-wrapper">

            <div class="icon assignment">
              <font-awesome-icon
                  icon="file-pen"
              />
            </div>

            <span :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">{{ structureItem.Name }}</span>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import {computed, inject, onBeforeUnmount, onMounted, ref} from "vue";
import $ from "jquery";
import {useStore} from "vuex";
import {v4 as uuidV4} from "uuid";
import draggable from "vuedraggable";
import {useRoute, useRouter} from "vue-router";

export default {

  name: 'ProgressSidebar',

  components: {
    draggable,
  },

  emits: ['save', 'goToJourneyAction'],

  props: {
    isCreation: {
      type: Boolean,
      required: true,
    },
    isSaveDisabled: {
      type: Boolean,
      default: true,
    },
    isStartBlockDrag: {
      type: Boolean,
      default: false,
    },
    journeyModules: {
      type: Array,
      required: true,
    }
  },

  setup(props, {emit}) {

    const store = useStore();

    const router = useRouter();

    const isMobileOrLaptopScreen = ref(false);

    const journeyDetails = inject('journeyDetails');

    const isSidebarDisplayed = inject('isSidebarDisplayed');

    const activeStructureItem = inject('activeStructureItem');

    const hasContentLoaded = ref(false);

    const countdowns = ref([]);

    const intervalId = ref(null) // Placeholder for interval ID

    const isJourneyReusableTemplatePage = ref(false);

    const maxHeight = computed(() => store.getters["layout/getContainerMaxHeight"]);

    const activeModule = computed(() => {

      return props.journeyModules.findIndex((module) => {
        let activeChapterIndex = module.Chapters.findIndex((chapter) => chapter.ID === activeStructureItem.value.structureItemID);
        return activeChapterIndex !== -1;
      });

    });

    const calculateModulesPercentage = computed(() => {

      const modulesPercentage = [];

      props.journeyModules.forEach(module => {

        let completedChapters = 0;

        if (journeyDetails.value.hasOwnProperty('ParticipantProgress')) {

          module.Chapters.forEach(chapter => {
            const chapterInModule = journeyDetails.value.ParticipantProgress.find(progress => progress.ChapterID === chapter.ID);
            if (chapterInModule && chapterInModule.Completed) {
              completedChapters++;
            }
          });

          const percentage = module.Chapters.length === 0 ? 100 : (completedChapters / module.Chapters.length) * 100;

          modulesPercentage.push({
            moduleID: module.ID,
            moduleProgressPercentage: percentage.toFixed(2)
          });

        }

      });

      return modulesPercentage;

    });

    if(router.currentRoute._value.name === 'JourneyReusableTemplateView') {
      isJourneyReusableTemplatePage.value = true;
    }

    const handleResize = () => {

      isMobileOrLaptopScreen.value = window.innerWidth < 768;

    };

    const convertDateToDisplayFormatWithTime = (dateTime) => {
      if (dateTime !== undefined && dateTime !== null && dateTime !== '') {
        const date = new Date(dateTime);
        // Get the date and time components
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Format the datetime-local string
        const datetimeLocalString = `${month} ${day} ${year} ${hours}:${minutes}`;

        return datetimeLocalString;
      }
      return null;
    };

    const convertDateToDisplayFormatWithoutTime = (dateTime) => {
      if (dateTime !== undefined && dateTime !== null && dateTime !== '') {
        const date = new Date(dateTime);
        // Get the date and time components
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
        const day = date.getDate().toString().padStart(2, '0');
        // const hours = date.getHours().toString().padStart(2, '0');
        // const minutes = date.getMinutes().toString().padStart(2, '0');

        // Format the datetime-local string
        const datetimeLocalString = `${month} ${day} ${year}`;

        return datetimeLocalString;
      }
      return null;
    };

    const updateCountdowns = () => {

      countdowns.value = journeyDetails.value.Structure.map((structureItem) => {

        if (structureItem.Type === 'Event' && structureItem.Options.IsUnlock && structureItem.Periods.Unlock !== null) {

          const unlockDate = new Date(structureItem.Periods.Unlock);
          const currentDate = new Date();

          // Calculate the difference in milliseconds
          const difference = unlockDate - currentDate;

          // Convert milliseconds to seconds
          const seconds = Math.floor(difference / 1000);

          // Calculate remaining days, hours, minutes, and seconds
          const days = Math.floor(seconds / 86400);
          const hours = Math.floor((seconds % 86400) / 3600);
          const minutes = Math.floor((seconds % 3600) / 60) + 1;

          // Return formatted countdown object
          return {
            days,
            hours,
            minutes,
          };
        }
        else {
          return null;
        }

      });
    };

    const toggleSidebar = () => {

      isSidebarDisplayed.value = !isSidebarDisplayed.value;

    };

    const goToJourneyAction = (journeyID, actionName) => {
      if (props.isCreation) {
        emit('goToJourneyAction', journeyID, actionName);
      } else {

        if (!isJourneyReusableTemplatePage.value) {
          router.push({
            name: actionName,
            params: {
              id: journeyID
            }
          });
        }
        else {
          router.push({
            name: 'JourneyReusableTemplateMap',
            params: {
              id: journeyID
            }
          });
        }

      }
    };

    const goToUnit = (structureItemID, indexUnit) => {

      isMobileOrLaptopScreen.value ? isSidebarDisplayed.value = false : null;

      activeStructureItem.value = {
        structureItemID: structureItemID,
        indexUnit: indexUnit,
      }

    };

    const toggleUnits = (ev) => {
      $(ev.target).siblings('.units-wrapper').slideToggle( 200, function() {
        // Animation complete.
        if ($(this).is(':visible')) {
          $(ev.target).children('.angle-down').show();
          $(ev.target).children('.angle-right').hide();
        }
        else {
          $(ev.target).children('.angle-down').hide();
          $(ev.target).children('.angle-right').show();
        }
      });
    };

    const makeRoundInteger = (number) => {
      if (number !== null  && number !== undefined && number !== '') {
        return Math.round(number);
      }
      return null;
    };

    const formattedNumber = (number, nrOfDecimals) => {

      if (number !== null && number !== undefined && number !== 0 && number !== '') {

        if (nrOfDecimals > 0) {

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: nrOfDecimals,
            useGrouping: false
          });

        }
        else {

          number = makeRoundInteger(number);

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            useGrouping: false
          });
        }

        number = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      }

      return number;

    };

    const isActiveElement = (structureItemID, indexUnit) => {

      return (activeStructureItem.value.structureItemID === structureItemID && activeStructureItem.value.indexUnit === indexUnit);

    };

    // journey creation section

    const isCurrentUserAuthorOfJourney = inject('isCurrentUserAuthorOfJourney');

    const deleteChapter = (structureItemID, indexStructureItem) => {
      journeyDetails.value.Structure.splice(indexStructureItem, 1);
      if (activeStructureItem.value.structureItemID === structureItemID) {
        resetActiveStructureItem();
      }
    };

    const lockChapter = (structureItemID) => {
      let selectedStructureItemIndex = journeyDetails.value.Structure.findIndex((structureItem) => structureItem.ID === structureItemID);
      journeyDetails.value.Structure[selectedStructureItemIndex].IsLocked = true;
    };

    const unlockChapter = (structureItemID) => {
      let selectedStructureItemIndex = journeyDetails.value.Structure.findIndex((structureItem) => structureItem.ID === structureItemID);
      journeyDetails.value.Structure[selectedStructureItemIndex].IsLocked = false;
    };

    const editUnit = (structureItemID, indexUnit) => {

      isMobileOrLaptopScreen.value ? isSidebarDisplayed.value = false : null;

      activeStructureItem.value = {
        structureItemID: structureItemID,
        indexUnit: indexUnit,
      }

    };

    const deleteUnit = (structureItemID, indexStructureItem, indexUnit) => {
      journeyDetails.value.Structure[indexStructureItem].Units.splice(indexUnit, 1);

      if (activeStructureItem.value.structureItemID === structureItemID && activeStructureItem.value.indexUnit === indexUnit) {
        resetActiveStructureItem();
      }
    };

    const setParticipantsProgress = async (chapterID = null) => {
      await store.dispatch('journey/setParticipantsProgress', {
        JourneyID: journeyDetails.value.ID,
        ChapterID: chapterID
      });
    };

    const resetActiveStructureItem = ()  => {
      activeStructureItem.value = {
        structureItemID: null,
        indexUnit: null,
      }
    };

    const deleteEvent = (structureItemID, indexStructureItem) => {
      journeyDetails.value.Structure.splice(indexStructureItem, 1);

      if (activeStructureItem.value.structureItemID === structureItemID) {
        resetActiveStructureItem();
      }
    };

    const journeyHasAtLeastOneChapter = () => {
      let numberOfChapters = journeyDetails.value.Structure.filter((structureItem) => structureItem.Type === 'Chapter').length;
      return numberOfChapters > 1;
    };

    const dropStructureItemHandler = (ev) => {

      if (journeyDetails.value.Structure.length > 0) {

        let firstStructureItem = journeyDetails.value.Structure[0];

        if (firstStructureItem.Type !== 'Event' || !firstStructureItem.Options.IsModule) {

          let newEventModuleObject = {
            Type: 'Event',
            ID: uuidV4(),
            Name: 'New Module',
            Options: {
              IsModule: true,
              IsMeeting: false,
              IsUnlock: false,
            },
            Periods: {
              From: null,
              To: null,
              Unlock: null,
            },
            Details: {
              Time: null,
              Elements: [],
              Version: null,
            }
          };
          journeyDetails.value.Structure.unshift(newEventModuleObject);
        }

      }

    };

    const isUnitEmpty = (unit) => {
      return (unit.Name === '' && unit.Blocks[0].Elements.length === 0);
    };

    const unitChangeHandler = (indexStructureItem, indexUnit) => {

      let remainingNumberOfUnits = journeyDetails.value.Structure[indexStructureItem].Units.length;

      let isLastUnit = (indexUnit === (remainingNumberOfUnits - 1));
      let isPenultimateUnit = (indexUnit === (remainingNumberOfUnits - 2));

      let unit = journeyDetails.value.Structure[indexStructureItem].Units[indexUnit];

      if (isLastUnit && !isUnitEmpty(unit)) {

        journeyDetails.value.Structure[indexStructureItem].Units.forEach((unit) => {
          if (unit.hasOwnProperty('Temp')) {
            delete unit.Temp;
          }
        });

        let unitObject = {
          ID: uuidV4(),
          Name: '',
          LinkedAssets: [],
          Blocks: [{
            Elements: [],
            Time: null,
            Version: null
          }],
          Temp: true,
        };
        journeyDetails.value.Structure[indexStructureItem].Units.push(unitObject);
      }

      if (isPenultimateUnit && isUnitEmpty(unit)) {
        journeyDetails.value.Structure[indexStructureItem].Units.pop();
        let lastIndex = journeyDetails.value.Structure[indexStructureItem].Units.length - 1;
        journeyDetails.value.Structure[indexStructureItem].Units[lastIndex].Temp = true;
      }

    };

    const checkUnitMove = (ev) => {

      // can't move a unit at the last position of any units array and can't move last unit of a units array
      let parentListNumberOfUnits = ev.from.__draggable_component__.modelValue.length - 1;
      let currentIndex = ev.draggedContext.index;
      let targetListNumberOfUnits = ev.relatedContext.list.length - 1;
      let futureIndex = ev.draggedContext.futureIndex;

      return (futureIndex < targetListNumberOfUnits) && (currentIndex !== parentListNumberOfUnits);
    };

    const addStructureItem = (indexStructureItem, type) => {
      closeAllAddOptionsPopups();
      let newStructureItemObject = {};

      switch (type) {

        case 'Chapter': {
          newStructureItemObject = {
            Type: 'Chapter',
            ID: uuidV4(),
            Name: '',
            IsLocked: false,
            IsHidden: false,
            Units: [{
              Name: '',
              LinkedAssets: [],
              Blocks: [{
                Time: null,
                Elements: [],
                Version: null,
              }]
            }]
          };
          break;
        }

        case 'Event': {
          newStructureItemObject = {
            Type: 'Event',
            ID: uuidV4(),
            Name: '',
            LinkedAssets: [],
            Options: {
              IsModule: false,
              IsMeeting: true,
              IsUnlock: false,
            },
            Periods: {
              From: null,
              To: null,
              Unlock: null,
            },
            Details: {
              Time: null,
              Elements: [],
              Version: null,
            }
          };
          break;
        }

        case 'Assignment': {
          newStructureItemObject = {
            Type: 'Assignment',
            ID: uuidV4(),
            Name: '',
            IsLocked: false,
            LinkedAssets: [],
            Tasks: [{
              ID: uuidV4(),
              IsDone: false,
              Content: {
                Time: null,
                Elements: [],
                Version: null,
              }
            }],
          };
          break;
        }

        default: {
          break;
        }

      }

      // if not last structure item, place it after current
      if (indexStructureItem < journeyDetails.value.Structure.length - 1) {
        journeyDetails.value.Structure.splice(indexStructureItem + 1, 0, newStructureItemObject);
      }
      // if last structure item, push at the end
      else {
        journeyDetails.value.Structure.push(newStructureItemObject);
      }

      activeStructureItem.value = {
        structureItemID: newStructureItemObject.ID,
        indexUnit: null,
      };

    };

    const goToStructureItem = (structureItemID) => {

      isMobileOrLaptopScreen.value ? isSidebarDisplayed.value = false : null;

      activeStructureItem.value = {
        structureItemID: structureItemID,
        indexUnit: null,
      }

    };

    const closeAllAddOptionsPopups = () => {
      const container = $(".add-options");
      container.hide();
    }
    const toggleAddOptionsHandler = (id) => {
      closeAllAddOptionsPopups();
      $(".add-buttons_wrapper").find("#" + id).slideToggle(100);
    };

    $(document).mouseup(function(e) {
      const container = $(".add-options");
      const wrapper = $(".add-buttons_wrapper");
      if (!wrapper.is(e.target) && wrapper.has(e.target).length === 0)
      {
        container.slideUp(100);
      }
    });

    const saveClickHandler = () => {
      emit('save');
    }

    // journey creation section


    onMounted( () => {

      handleResize();

      hasContentLoaded.value = true;

      if (!props.isCreation) {
        updateCountdowns();

        // Calculate the time remaining until the next minute
        const now = new Date();
        const millisecondsUntilNextMinute = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

        // Wait until the next minute starts before starting the interval
        setTimeout(() => {
          updateCountdowns(); // Update immediately
          intervalId.value = setInterval(updateCountdowns, 60000); // Update every minute
        }, millisecondsUntilNextMinute);
      }

    });

    onBeforeUnmount(() => {
      clearInterval(intervalId.value);
    });

    return {
      isMobileOrLaptopScreen,
      hasContentLoaded,
      countdowns,
      store,
      router,
      journeyDetails,
      isSidebarDisplayed,
      activeStructureItem,
      maxHeight,
      activeModule,
      calculateModulesPercentage,
      isCurrentUserAuthorOfJourney,
      toggleSidebar,
      goToJourneyAction,
      goToUnit,
      toggleUnits,
      formattedNumber,
      isActiveElement,
      resetActiveStructureItem,
      deleteChapter,
      deleteEvent,
      lockChapter,
      unlockChapter,
      editUnit,
      deleteUnit,
      setParticipantsProgress,
      journeyHasAtLeastOneChapter,
      convertDateToDisplayFormatWithTime,
      updateCountdowns,
      convertDateToDisplayFormatWithoutTime,
      dropStructureItemHandler,
      unitChangeHandler,
      checkUnitMove,
      toggleAddOptionsHandler,
      addStructureItem,
      goToStructureItem,
      saveClickHandler,
    }
  }

}

</script>

<style lang="scss" scoped>

#progress-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fafafa;
  box-shadow: -5px 5px 11px -5px rgba(0,0,0,0.2) inset;
  -webkit-box-shadow: -5px 5px 11px -5px rgba(0,0,0,0.2) inset;
  -moz-box-shadow: -5px 5px 11px -5px rgba(0,0,0,0.2) inset;
  overflow: auto;
  z-index: 10;

  .actions-wrapper {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fafafa;
    margin: 10px 10px 0 0;
    padding: 10px;

    .actions {
      display: flex;
      flex-direction: row;
      gap: 20px;
      font-size: 18px;
      background-color: #FFFFFF;
      border: 0;
      border-radius: 8px;
      box-sizing: border-box;
      font-weight: 600;
      padding: 5px 10px;
      text-align: center;
      text-decoration-thickness: auto;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      width: fit-content;
    }

    .icon {
      &.save {
        font-size: 18px;
      }
    }
  }

  #sidebar-content-journey-creation {
    &.path {
      margin: 0 10px;

      .structure-item {
        position: relative;
        padding-left: 20px;

        .locked {
          opacity: 0.5;
          pointer-events: none;

          .title-wrapper {
            cursor: not-allowed;
          }

          span {
            color: gray !important;
          }
        }

        &:first-of-type {
          padding-left: 0;
        }

        .structure-connection-branch {
          position: absolute;
          left: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background-color: var(--primary-color);
          z-index: 1;
          border-radius: 50px;
        }

        .structure-connection {
          position: absolute;
          top: 8px;
          left: 0;
          width: 15px;
          height: 10px;
          background-color: transparent;
          border: 2px none var(--primary-color);
          border-bottom-style: solid;
          border-bottom-left-radius: 10px;
        }

        .title-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          padding-bottom: 5px;
          cursor: pointer;

          .chapter-number {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #535353;
              font-size: 12px;
              font-weight: bold;
            }

            &.active-chapter {
              span {
                color: white;
              }
            }
          }

          .chapter-number, span, .angle-down, .angle-right {
            pointer-events: none;
          }

          .icon {
            &.assignment {
              color: var(--primary-color);
            }
          }

          input {
            border: none;
            background-color: transparent;
            width: 100%;


            &.chapter_input {
              font-size: large
            }
          }

          .icon {
            font-size: 14px;
          }
        }

        .event {
          display: flex;
          align-items: flex-start;
          justify-content: space-evenly;
          flex-direction: column;
          gap: 5px;
          width: 100%;
          cursor: pointer;

          .module-divider {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .divider {
              width: 40%;
              border-bottom: 2px solid var(--primary-color);
              margin: 10px 0;
            }

            .circle-wrapper {
              position: relative;
              background-color: #ffffff;
              width: 40px;
              border-radius: 50%;

              .circular-chart {
                position: relative;
                font-weight: bold;
                margin-top: -40px;
                width: 45px;
                height: 45px;
                border-radius: 50%;
              }

              .circle {
                width: 100%;
                height: 100%;

                .circle-background {
                  fill: none;
                  stroke-width: 2.5;
                }

                .circle-outline {
                  fill: white;
                  stroke-linecap: round;
                  stroke: var(--primary-color);

                  &.active-module {
                    fill: #fff2b0;
                  }
                }
              }

              .circle-element {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 12px;
                color: black;

                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }

          .event-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;

            .title-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              .title-placeholder {
                opacity: 0.5;
              }
            }

            &.space-between {
              justify-content: space-between;
            }

            &.meeting-content, &.unlock-content {
              font-size: small;
              text-transform: lowercase;
            }
          }

          .add-chapter {
            margin-left: 20px;
            padding: 5px;
          }
        }

        .chapter {
          //padding-left: 20px;

          .lock_closed-icon, .lock_opened-icon {
            cursor: default;
          }

          .units-wrapper {
            margin-left: 5px;

            .unit-wrapper {
              position: relative;
              padding-left: 20px;

              .unit-connection-branch {
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: var(--primary-color);
                z-index: 1;
                border-radius: 50px;
              }

              .unit-connection {
                position: absolute;
                top: 25%;
                left: 0;
                width: 15px;
                height: 10px;
                background-color: transparent;
                border: 2px none var(--primary-color);
                border-bottom-style: solid;
                border-bottom-left-radius: 10px;
              }

              .unit-block_wrapper {
                height: 100px;
                width: 100%;
                background-color: #efefef;
                margin: 5px 0 10px;
                overflow: hidden;

              }

            }

          }

        }

        .unit {
          display: flex;
          align-items: normal;
          justify-content: flex-start;
          gap: 5px;
          cursor: pointer;
          padding: 5px;

          .unit-checkbox {
            width: 0.5rem;
            height: 0.5rem;
            box-sizing: border-box;
            border: 1px solid rgb(83, 83, 83);
            border-radius: 50%;
          }

          input {
            border: none;
            background-color: transparent;
            width: 100%;
          }

          ::placeholder {
            opacity: 1;
            font-style: italic;
            font-size: small;
          }

          span {
            color: #0000006b;
          }

          .icon {
            font-size: 14px;
          }

          &:hover {
            background-color: #efefef;
            span, .icon.bars-staggered {
              color: black !important;
            }
          }

          &.active-element {
            span {
              font-weight: 500;
              color: black;
            }

            .unit-checkbox {

            }
          }
        }

        .active-element {
          background-color: #efefef;
        }
      }
    }
  }

  #sidebar-content-journey-view {
    &.path {
      margin: 0 10px;

      .structure-item {
        position: relative;
        padding-left: 20px;

        .locked {
          opacity: 0.5;
          pointer-events: none;

          .title-wrapper {
            cursor: not-allowed;
          }

          span {
            color: gray !important;
          }
        }

        &:first-of-type {
          padding-left: 0;
        }

        .structure-connection-branch {
          position: absolute;
          left: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background-color: var(--primary-color);
          z-index: 1;
          border-radius: 50px;
        }

        .structure-connection {
          position: absolute;
          top: 4px;
          left: 0;
          width: 15px;
          height: 10px;
          background-color: transparent;
          border: 2px none var(--primary-color);
          border-bottom-style: solid;
          border-bottom-left-radius: 10px;
        }

        .title-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          padding-bottom: 5px;
          cursor: pointer;

          .chapter-number {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #535353;
              font-size: 12px;
              font-weight: bold;
            }

            &.active-chapter {
              span {
                color: white;
              }
            }
          }

          .chapter-number, span, .angle-down, .angle-right {
            pointer-events: none;
          }

          .icon {
            &.assignment {
              color: var(--primary-color);
            }
          }

          input {
            border: none;
            background-color: transparent;
            width: 100%;


            &.chapter_input {
              font-size: large
            }
          }

          .icon {
            font-size: 14px;
          }
        }

        .event {
          display: flex;
          align-items: flex-start;
          justify-content: space-evenly;
          flex-direction: column;
          gap: 5px;
          width: 100%;
          cursor: pointer;

          .module-divider {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .divider {
              width: 40%;
              border-bottom: 2px solid var(--primary-color);
              margin: 10px 0;
            }

            .circle-wrapper {
              position: relative;
              background-color: #ffffff;
              width: 40px;
              border-radius: 50%;

              .circular-chart {
                position: relative;
                font-weight: bold;
                margin-top: -40px;
                width: 45px;
                height: 45px;
                border-radius: 50%;
              }

              .circle {
                width: 100%;
                height: 100%;

                .circle-background {
                  fill: none;
                  stroke-width: 2.5;
                }

                .circle-outline {
                  fill: white;
                  stroke-linecap: round;
                  stroke: var(--primary-color);

                  &.active-module {
                    fill: #fff2b0;
                  }
                }
              }

              .circle-element {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 10px;
                font-weight: bold;

                .percentage {
                  color: var(--primary-color);

                  &.icon {
                    font-size: 14px;
                  }
                }

                .module {
                  color: black;
                }

                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }

          .event-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;

            .title-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              .title-placeholder {
                opacity: 0.5;
              }
            }

            &.space-between {
              justify-content: space-between;
            }

            &.meeting-content, &.unlock-content {
              font-size: small;
              margin-bottom: 10px;
              text-transform: lowercase;
            }
          }

          .add-chapter {
            margin-left: 20px;
            padding: 5px;
          }
        }

        .chapter {
          //padding-left: 20px;

          .lock_closed-icon, .lock_opened-icon {
            cursor: default;
          }

          .units-wrapper {
            margin-left: 5px;

            .unit {
              position: relative;
              padding-left: 20px;

              .unit-connection-branch {
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: var(--primary-color);
                z-index: 1;
                border-radius: 50px;

                &:last-child {
                  background-color: blue;
                }
              }

              .unit-connection {
                position: absolute;
                top: 25%;
                left: 0;
                width: 15px;
                height: 10px;
                background-color: transparent;
                border: 2px none var(--primary-color);
                border-bottom-style: solid;
                border-bottom-left-radius: 10px;
              }
            }

            .unit-block_wrapper {
              height: 100px;
              width: 100%;
              background-color: #efefef;
              margin: 5px 0 10px;
              overflow: hidden;

            }

          }

        }

        .unit {
          display: flex;
          align-items: normal;
          justify-content: flex-start;
          gap: 5px;
          cursor: pointer;
          padding: 5px;

          .unit-checkbox {
            width: 0.5rem;
            height: 0.5rem;
            box-sizing: border-box;
            border: 1px solid rgb(83, 83, 83);
            border-radius: 50%;
          }

          input {
            border: none;
            background-color: transparent;
            width: 100%;
          }

          ::placeholder {
            opacity: 1;
            font-style: italic;
            font-size: small;
          }

          span {
            color: #0000006b;
          }

          .icon {
            font-size: 14px;
          }

          &:hover {
            background-color: #efefef;
            span, .icon.bars-staggered {
              color: black !important;
            }
          }

          &.active-element {
            span {
              font-weight: 500;
              color: black;
            }

            .unit-checkbox {

            }
          }
        }

        .active-element {
          background-color: #efefef;
        }
      }
    }

    .event {
      cursor: pointer;
    }
  }

  .close-icon {
    cursor: pointer;
    width: fit-content;
    font-size: 20px;
  }

  .title-wrapper.module{
    color: var(--primary-color);
    font-size: large;
    font-weight: bold;
  }

  .sidebar-top {
    padding: 10px;

    .title-wrapper {
      font-size: 20px;
      font-weight: bold;
      //text-shadow: #b7b7b7 1px 0 3px;
      text-transform: capitalize;
      margin-bottom: 30px;
    }

    .progress {
      margin: 10px 0;
    }

    .progress-value {
      text-transform: uppercase;
      font-size: 13px;
    }
  }

  .add-buttons_wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 5px 0;
    font-size: 22px;
    width: fit-content;

    .add-options {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      position: absolute;
      top: -30px;
      left: 0;
      background-color: #ffffff;
      border: 1px solid #ccc;
      padding: 5px;
      border-radius: 5px;
      animation: slideDown 0.3s ease-in-out forwards;
      z-index: 1;

      //.icon {
      //  display: inline-block;
      //  opacity: 0;
      //  animation: fadeIn 0.5s ease-in-out forwards;
      //}

    }

    .add-button {
      cursor: default;
    }

  }

  .custom-button {
    margin: 10px 0;
    background-color: #FFFFFF;
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    text-align: center;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;

    &:hover {
      background-color: rgb(249,250,251);
    }

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    &:focus-visible {
      box-shadow: none;
    }
  }

  .icon.save {

    &.save-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

}

@media only screen and (max-width: 768px) {
  #progress-sidebar {
    .close-icon {
      margin-right: 5px;
    }

    #sidebar-content-journey-creation {
      &.path {
        .structure-item {
          .structure-connection {
            top: 13px;
          }
        }
      }
    }

    #sidebar-content-journey-view {
      &.path {
        .structure-item {
          .structure-connection {
            top: 8px;
          }
        }
      }
    }

    .structure-item {
      padding: 5px;

      .event {
        .event-row {
          &.unlock-content {
            span {
              text-align: center;
            }
          }
        }
      }

      .chapter {
        .title-wrapper {
          gap: 5px;

          input {
            width: 60%;
          }
        }

        .event-wrapper {
          .top {
            input {
              max-width: 50%;
            }
          }
        }

        .units-wrapper {
          .unit {
            padding: 5px;
            gap: 5px;

            input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

</style>
