<template>

  <!--  <button @click="animateSVG()">Animate</button>-->
  <!--  <div v-if="frontSvgMaxHeight !== 0"-->
  <!--       id="front-middle-svg"-->
  <!--  >-->
  <!--    <inline-svg :src="cardDetails.front.middleSVG.htmlElement"-->
  <!--                @loaded="svgLoadedHandler($event)"-->
  <!--    />-->
  <!--  </div>-->


  <div class="card-wrapper">

    <!--    <span>Batch {{ cardDetails.batchNumber }} Card {{ cardDetails.memoryCardNumber }}</span>-->

    <div class="memory-card-wrapper" @click="toggleCard()">

      <transition name="flip">

        <div :key="cardDetails.flipped" id="memory-card" ref="cardElement"
          :class="{ joker: cardDetails.batchNumber === 5 }">

          <div v-if="cardDetails.batchNumber === 5">
            <div v-show="cardDetails.flipped" class="front">

              <div class="card_top-wrapper">

              </div>
              <div v-if="cardDetails.frontSvg !== null && frontSvgMaxHeight !== 0" id="front-middle-svg">
                <inline-svg :src="cardDetails.frontSvg" style="height: 50%" @loaded="svgLoadedHandler($event)" />
              </div>
            </div>

            <div v-show="!cardDetails.flipped" class="front">
              <div class="card_top-wrapper">

                <img class="top-image" :src="HansenBeckLogo2Lines" alt="top-image"
                  :class="{ 'joker': cardDetails.batchNumber === 5 }">

              </div>

              <div class="separator top thick" :class="{ 'joker-separator': cardDetails.batchNumber === 5 }"></div>

              <div class="top-text">{{ cardDetails.frontTitle[language] }}</div>
              <div v-if="cardDetails.subTitle[language]" class="subtitle-text">{{ cardDetails.subTitle[language] }}
              </div>

              <div v-show="cardDetails.frontBottomText[language] !== null" class="separator bottom"
                :class="{ 'joker-separator': cardDetails.batchNumber === 5 }"></div>

              <div v-show="cardDetails.frontBottomText[language] !== null" class="bottom-text">{{
                cardDetails.frontBottomText[language] }}</div>

            </div>
          </div>
          <div v-else class="front-back-wrapper">
            <div v-show="!cardDetails.flipped" class="front">

              <div class="card_top-wrapper">

                <img class="top-image" :src="HansenBeckLogo" alt="top-image"
                  :class="{ 'joker': cardDetails.batchNumber === 5 }">

              </div>

              <div class="separator top thick"></div>

              <div class="top-text">{{ cardDetails.frontTitle[language] }}</div>

              <div v-if="cardDetails.frontSvg !== null && frontSvgMaxHeight !== 0" id="front-middle-svg">
                <inline-svg :src="cardDetails.frontSvg" :style="{ 'max-height': frontSvgMaxHeight }"
                  @loaded="svgLoadedHandler($event)" />
              </div>

              <div v-if="cardDetails.frontSvg === null || frontSvgMaxHeight === 0" id="front-middle-svg">
              </div>

              <div v-show="cardDetails.frontBottomText[language] !== null" class="separator bottom"></div>

              <div v-show="cardDetails.frontBottomText[language] !== null" class="bottom-text">{{
                cardDetails.frontBottomText[language] }}</div>

            </div>

            <div v-show="cardDetails.flipped" class="back">

              <img :src="cardDetails.backImage[language]" alt="">

              <!--          <div class="card_top-wrapper">-->

              <!--&lt;!&ndash;          top image&ndash;&gt;-->
              <!--            <img v-if="cardDetails.back.topImageUpdated === null"-->
              <!--                 class="top-image"-->
              <!--                 :src="cardDetails.back.topImage"-->
              <!--                 alt="top-image"-->
              <!--            >-->

              <!--            <img v-if="cardDetails.back.topImageUpdated !== null"-->
              <!--                 class="top-image"-->
              <!--                 :src="cardDetails.back.topImageUpdated.url"-->
              <!--                 alt="top-image"-->
              <!--            >-->
              <!--&lt;!&ndash;          end of top image&ndash;&gt;-->

              <!--            <div class="icon card-back-indicator">-->
              <!--              <font-awesome-icon icon="arrow-turn-up"/>-->
              <!--            </div>-->

              <!--          </div>-->

              <!--          <div class="separator thick"></div>-->

              <!--          <div class="top-text">{{ cardDetails.back.topText }}</div>-->

              <!--&lt;!&ndash;          middle SVG&ndash;&gt;-->
              <!--          <div v-if="cardDetails.back.middleSVG.hasOwnProperty('htmlElement') && cardDetails.back.middleSVGUpdated === null"-->
              <!--               id="back-middle-svg"-->
              <!--               v-html="cardDetails.back.middleSVG.htmlElement"-->
              <!--          >-->
              <!--          </div>-->

              <!--          <div v-if="cardDetails.back.middleSVGUpdated !== null"-->
              <!--               id="back-middle-svg"-->
              <!--               v-html="cardDetails.back.middleSVGUpdated.htmlElement"-->
              <!--          >-->
              <!--          </div>-->

              <!--          <div v-if="!cardDetails.back.middleSVG.hasOwnProperty('htmlElement') && cardDetails.back.middleSVGUpdated === null"-->
              <!--               id="back-middle-svg">-->
              <!--          </div>-->
              <!--&lt;!&ndash;          end of middle SVG&ndash;&gt;-->

              <!--          <div class="separator"></div>-->

              <!--          <div class="bottom-text">{{ cardDetails.back.bottomText }}</div>-->
            </div>
          </div>

          <div class="top-right-icons">

            <div v-if="isGemDisplayed" class="icon gem">

              <font-awesome-icon icon="gem" />

            </div>

            <!--            <div class="icon tags"-->
            <!--                 v-if="isEdit && isMemoryCardChecked"-->
            <!--                 @click.stop="addAssetTags(card.memoryCardNumber)"-->
            <!--            >-->

            <!--              <font-awesome-icon icon="tags"/>-->

            <!--            </div>-->

            <div v-if="isCheckmarkDisplayed" class="check-wrapper">

              <div v-if="isMemoryCardChecked" class="icon check" title="Remove" @click.stop="uncheckMemoryCard()">
                <font-awesome-icon :icon="['fas', 'circle-check']" />
              </div>

              <div v-else class="icon uncheck" title="Add" @click.stop="checkMemoryCard()">
                <font-awesome-icon :icon="['far', 'circle-check']" />
              </div>

            </div>

          </div>

          <!--          <div v-show="!cardDetails.flipped" class="attachment-tags">-->

          <!--            <div class="tag" v-for="(assetTag, indexAssetTag) in cardDetails.Tags">-->

          <!--              <div class="icon tag">-->
          <!--                <font-awesome-icon icon="tag"/>-->
          <!--              </div>-->

          <!--              <span>{{ assetTag }}</span>-->

          <!--              <div class="icon tag" v-if="isEdit" @click.stop="deleteTag($event, cardDetails.memoryCardNumber, indexAssetTag)">-->
          <!--                <font-awesome-icon icon="xmark"/>-->
          <!--              </div>-->

          <!--            </div>-->

          <!--          </div>-->

        </div>

      </transition>

    </div>

    <!--    <button v-if="giveCardButtonDisplayed" style="margin-top: 25px" @click="openLinkedUsersModal()">Give Card</button>-->

    <!--    <transition name="fade">-->

    <!--      <div class="custom_modal" v-if="isLinkedUsersModalDisplayed">-->

    <!--        <div class="custom_modal__content">-->

    <!--          &lt;!&ndash;                    <h3>&ndash;&gt;-->
    <!--          &lt;!&ndash;                      User Management&ndash;&gt;-->
    <!--          &lt;!&ndash;                    </h3>&ndash;&gt;-->

    <!--          <div v-show="usersLinkedToCurrentUser.length > 0" class="linked-users_wrapper">-->

    <!--            <h2>My Participants</h2>-->

    <!--            <table id="linked-users">-->
    <!--              <thead>-->
    <!--              <tr>-->
    <!--                <th>Given Name</th>-->
    <!--                <th>Family Name</th>-->
    <!--                <th>Give Card</th>-->
    <!--              </tr>-->
    <!--              </thead>-->

    <!--              <tbody>-->
    <!--              <tr v-for="(linkedUser, indexUsersLinkedToCurrentUser) in usersLinkedToCurrentUser"-->
    <!--                  :key="indexUsersLinkedToCurrentUser"-->
    <!--              >-->

    <!--                <td>-->
    <!--                  <span v-if="linkedUser.hasOwnProperty('given_name')">{{ linkedUser.given_name }}</span>-->
    <!--                </td>-->
    <!--                <td>-->
    <!--                  <span v-if="linkedUser.hasOwnProperty('family_name')">{{ linkedUser.family_name }}</span>-->
    <!--                </td>-->
    <!--                <td>-->
    <!--                  <button v-if="!hasCard(linkedUser.MemoryCards, cardDetails.batchNumber, cardDetails.memoryCardNumber)"-->
    <!--                          @click="setMemoryCardToUser(linkedUser.sub)">Give</button>-->
    <!--                </td>-->

    <!--              </tr>-->
    <!--              </tbody>-->

    <!--            </table>-->

    <!--          </div>-->

    <!--        </div>-->

    <!--        <div class="backdrop" @click="closeLinkedUsersModal()"></div>-->

    <!--      </div>-->

    <!--    </transition>-->

  </div>

</template>

<script>

import { computed, nextTick, onMounted, ref, watch } from "vue";
import $ from 'jquery';
import HansenBeckLogo from '@/assets/memory_cards/HB_Logo.png';
import HansenBeckLogo2Lines from '@/assets/memory_cards/HB_logo_tagline_negative.jpg';
import InlineSvg from "vue-inline-svg";
import { useStore } from "vuex";

export default {

  name: 'MemoryCard',

  components: {
    InlineSvg
  },

  emits: ['checkMemoryCard', 'uncheckMemoryCard', 'addAssetTagsMemoryCard', 'deleteTagMemoryCard'],

  props: {
    card: {
      type: Object,
      required: true
    },
    giveCardButtonDisplayed: {
      type: Boolean,
      default: false,
    },
    isCheckmarkDisplayed: {
      type: Boolean,
      default: false,
    },
    isGemDisplayed: {
      type: Boolean,
      default: false,
    },
    isMemoryCardChecked: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    assets: {
      type: Array,
      default: [],
    },
    mobile: {
      type: Boolean,
      default: false
    },
  },

  setup(props, { emit }) {

    // const cardDetails = inject('cardDetails');

    const store = useStore();

    const hasContentLoaded = ref(false);

    const cardDetails = ref();

    cardDetails.value = props.card;

    cardDetails.value.flipped = false;

    props.assets.forEach(asset => {
      if (asset.Type === 'MemoryCard' && asset.memoryCardNumber === cardDetails.value.memoryCardNumber) {
        cardDetails.value.Tags = asset.Tags;
      }
    });

    // const usersLinkedToCurrentUser = inject('usersLinkedToCurrentUser');

    // if (props.giveCardButtonDisplayed) {
    //   usersLinkedToCurrentUser.value = inject('usersLinkedToCurrentUser');
    // }

    const cardElement = ref(null);

    // const cardDetails = ref({
    //   front: {
    //     topImage: HansenBeckLogo,
    //     topImageUpdated: null,
    //     topText: 'Positive Grundeinstellung',
    //     middleSVG: {
    //       htmlElement: PersonalResponsibility
    //     },
    //     middleSVGUpdated: null,
    //     bottomText: 'Warum freue ich mich?',
    //   },
    //   back: {
    //     topImage: HansenBeckLogo,
    //     topImageUpdated: null,
    //     topText: 'Positive Grundeinstellung',
    //     middleSVG: {
    //       htmlElement: ''
    //     },
    //     middleSVGUpdated: null,
    //     bottomText: 'Warum freue ich mich?',
    //   },
    //   flipped: false,
    // });

    const frontSvgMaxHeight = ref(0);

    const isLinkedUsersModalDisplayed = ref(false);

    const language = computed(() => store.getters["userPreferences/getLanguage"]);

    watch(language, (newValue, oldValue) => {

      if (!cardDetails.value.flipped) {

        frontSvgMaxHeight.value = 0;

        nextTick(() => {
          setFrontSvgMaxHeight();
        });
      }

    });

    watch(() => props.card, (newCard) => {

      cardDetails.value = newCard;

    });

    const toggleCard = () => {
      cardDetails.value.flipped = !cardDetails.value.flipped;
    };

    const animateSVG = () => {

      const svgElement = $(cardElement.value).find('.front').find('#front-middle-svg').find('svg');

      if (svgElement.length !== 0) {
        svgElement.drawsvg({
          duration: 200, // overall duration animation
          stagger: 100 // time between the start of each path animation
        }).drawsvg('animate');
      }

    };

    const hasCard = (array, batchNumber, memoryCardNumber) => {
      return array.find(obj => obj.BatchNumber === batchNumber && obj.MemoryCardNumber === memoryCardNumber) !== undefined;
    };

    const svgLoadedHandler = (ev) => {
      animateSVG();
    };

    const setFrontSvgMaxHeight = () => {
      const cardHeight = 510;
      const cardTopHeight = $(cardElement.value).find('.front').find('.card_top-wrapper').outerHeight(true);
      const topSeparatorHeight = $(cardElement.value).find('.front').find('.separator.top').outerHeight(true);
      const topTextHeight = $(cardElement.value).find('.front').find('.top-text').outerHeight(true);
      const bottomSeparatorHeight = $(cardElement.value).find('.front').find('.separator.bottom').outerHeight(true);
      const bottomTextHeight = $(cardElement.value).find('.front').find('.bottom-text').outerHeight(true);
      // console.log(cardTopHeight);
      // console.log(topSeparatorHeight);
      // console.log(topTextHeight);
      // console.log(bottomSeparatorHeight);
      // console.log(bottomTextHeight);

      let elementsHeight = cardTopHeight + topSeparatorHeight + topTextHeight + bottomSeparatorHeight + bottomTextHeight;

      frontSvgMaxHeight.value = cardHeight - elementsHeight;
      // return (cardHeight - elementsHeight);
    };

    const openLinkedUsersModal = async () => {
      isLinkedUsersModalDisplayed.value = true;
    };

    const closeLinkedUsersModal = () => {
      isLinkedUsersModalDisplayed.value = false;
    };

    // const setMemoryCardToUser = async (linkedUserSub) => {
    //
    //   let payload = {
    //     BatchNumber: cardDetails.value.batchNumber,
    //     MemoryCardNumber: cardDetails.value.memoryCardNumber,
    //     LinkedUserSub: linkedUserSub
    //   }
    //
    //   let updatedMemoryCard = await store.dispatch('memoryCards/assignMemoryCardToUser', payload);
    //
    //   let index = usersLinkedToCurrentUser.value.findIndex((userLinkedToCurrentUser) => {
    //     return userLinkedToCurrentUser.sub === updatedMemoryCard.UserID;
    //   });
    //   usersLinkedToCurrentUser.value[index].MemoryCards = updatedMemoryCard.MemoryCards;
    //
    // };


    // check functionality
    const checkMemoryCard = () => {

      const payload = {
        BatchNumber: cardDetails.value.batchNumber,
        MemoryCardNumber: cardDetails.value.memoryCardNumber,
      }

      emit('checkMemoryCard', payload);

    };

    const uncheckMemoryCard = () => {

      const payload = {
        BatchNumber: cardDetails.value.batchNumber,
        MemoryCardNumber: cardDetails.value.memoryCardNumber,
      }

      emit('uncheckMemoryCard', payload);

    };

    const addAssetTags = (memoryCardNumber) => {
      emit('addAssetTagsMemoryCard', memoryCardNumber);
    };

    const deleteTag = (ev, memoryCardNumber, indexAssetTag) => {
      emit('deleteTagMemoryCard', memoryCardNumber, indexAssetTag);
    };

    // end of check functionality

    onMounted(async () => {
      hasContentLoaded.value = true;
      setFrontSvgMaxHeight();
      animateSVG();
    });

    return {
      hasContentLoaded,
      HansenBeckLogo,
      HansenBeckLogo2Lines,
      cardElement,
      cardDetails,
      frontSvgMaxHeight,
      language,
      isLinkedUsersModalDisplayed,
      // usersLinkedToCurrentUser,
      hasCard,
      svgLoadedHandler,
      toggleCard,
      animateSVG,
      setFrontSvgMaxHeight,
      openLinkedUsersModal,
      closeLinkedUsersModal,
      // setMemoryCardToUser,
      checkMemoryCard,
      uncheckMemoryCard,
      // toggleIsReusableMemoryCard,
      addAssetTags,
      deleteTag,
    }
  }

}

</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'PT_Serif-Web-Bold';
  src: url('~@/assets/fonts/PT_Serif-Web-Bold.ttf') format('truetype');
}

.memory-card-wrapper {
  font-family: 'PT_Serif-Web-Bold', sans-serif;

  .joker {
    background-color: black !important;
    color: white;

    .top-right-icons {
      background-color: black !important;
    }

    .fa-circle-check,
    .fa-gem {
      color: white;
    }

  }

  #memory-card {
    position: relative;
    background-color: white;
    width: 300px;
    height: 550px;
    //border: 3px solid black;
    border-radius: 20px;
    will-change: transform;
    cursor: pointer;
    -webkit-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
    -moz-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
    box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
    transition: all 0.3s ease;
    font-weight: bold;
    overflow: hidden;

    &:hover {
      transform: scale(1.05);
    }

    .card_top-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;

      .top-image {
        height: 11px;
      }

      .joker {
        height: 80px;
        margin-bottom: 15%;
      }

      .card-back-indicator {
        font-size: 20px;
      }
    }

    .front {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      height: 550px;

      padding: 20px;

      .top-text {
        font-size: 30px;
        margin-bottom: 5px;
        line-height: 1;
        white-space: break-spaces;
        text-align: start;
      }

      .subtitle-text {
        margin-bottom: 60%;
        font-size: small;
      }

      #front-middle-svg {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &.joker {
          stroke: #fff
        }
      }

      .bottom-text {
        font-size: 12px;
        white-space: break-spaces;
        text-align: start;
      }
    }

    .front-back-wrapper {
      position: relative;


      .back {
        //display: flex;
        //align-items: flex-start;
        //justify-content: flex-start;
        //flex-direction: column;
        //height: 510px;
        height: 550px;
        width: 100%;
       // height: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
          height: 100%;
        }

        //.top-text {
        //  font-size: 32px;
        //  margin-bottom: 5px;
        //}
        //
        //#back-middle-svg {
        //  flex: 1;
        //  width: 100%;
        //  display: flex;
        //  align-items: center;
        //  justify-content: center;
        //}
        //
        //.bottom-text {
        //  font-size: 14px;
        //}
      }
    }

    .separator,
    .joker-separator {
      width: 100%;
      height: 3px;
      background-color: black;
      margin: 5px 0;

      &.thick {
        height: 4px;
      }
    }

    .joker-separator {
      background-color: white;
    }

    .icon.reusable-asset-flag {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 20px;
      opacity: 0.5;

      &.reusable {
        opacity: 1;
      }
    }

    .top-right-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      background-color: white;
    }

    .attachment-tags {
      position: absolute;
      z-index: 2000;
      top: 125px;
      width: 90%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 3px;

      .tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 3px;
        background-color: lightgrey;
        border-radius: 5px;
        font-family: var(--bs-body-font-family);
        font-size: 13px;
        font-weight: normal;
        width: fit-content;
        padding: 5px;

        .icon {

          &.tags {
            position: absolute;
            top: 7px;
            left: 35px;
            font-size: 21px;
            align-self: flex-start;
            cursor: default;
          }

          &.tag {
            font-size: 10px;
            color: black;
            padding: 0;
          }
        }
      }
    }
  }
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter-from,
.flip-leave-from {
  transform: rotateY(180deg);
  opacity: 0;
}

@media only screen and (max-width: 768px) {

  .memory-card-wrapper {
    #memory-card {
      max-width: 100vw;
      max-height: 550px;
      //
      //  .front {
      //    height: 450px;
      //  }
    }
  }
}
</style>
