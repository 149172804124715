<template>
  <slot name="header" />
  <div :style="{'max-height': maxHeight + 'px','min-height': maxHeight + 'px' }" class="container">
    <slot name="content" />
  </div>
  <Footer v-if="!mobile" />
  <MobileFooter v-if="mobile" />
</template>

<script>
import Footer from "@/components/Footer.vue";
import MobileFooter from "@/components/mobile/FooterMobile.vue";
import {computed, onMounted, ref} from "vue";
import $ from "jquery";
import {useStore} from "vuex";

export default {

  name: 'CentralLayout',

  components: {
    Footer,
    MobileFooter
  },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },

  setup () {

    const store = useStore();

    const maxHeight = computed(() => store.getters["layout/getContainerMaxHeight"]);

    onMounted(async() => {

      if(maxHeight.value === 0) {

        let navbarHeight = $('.navbar').outerHeight();
        let footerHeight = $('.footer').outerHeight();

        let containerMaxHeight = window.innerHeight - navbarHeight - footerHeight;

        await store.dispatch('layout/setContainerMaxHeight', containerMaxHeight);

      }

    });

    return {
      maxHeight
    }
  }
}

</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  padding: 0;
  //margin-bottom: 50px;
}
</style>
