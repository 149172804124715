<template>

  <div v-if="hasContentLoaded" id="tenant-settings">
    <!-- Header -->
    <div class="row justify-content-center ">
      <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
        <!-- Pretitle -->
        <!--                      <h6 class="mb-4 text-uppercase text-muted">-->
        <!--                        Tenant Settings-->
        <!--                      </h6>-->

        <!-- Title -->
        <h1 class="mb-3">
          Make it yours
        </h1>

        <!-- Subtitle -->
        <p class="mb-5 text-muted">
          Personalization is key and will help you engaging with your
          customers
        </p>
      </div>
    </div>
    <!-- / .row -->
    <label>
      Tenant URL: https://{{
        store.state.tenant.tenantinfo.tenantname
      }}.dev.commovis.com
    </label>

    <hr/>

    <!-- Project cover -->
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-12 col-md-6">
          <!-- Start date -->
          <div class="form-group">
            <!-- Label -->
            <label for="primarycolor" class="form-label">
              Primary Color (hex color)
            </label>
            <br/>
            <!-- Input -->
            <input
                id="primarycolor"
                name="primarycolor"
                v-model="primarycolor"
                type="color"
                class="form-control, icp__input"
                :class="[primarycolorError ? 'is-invalid' : 'is-valid']"
            />
            <div class="invalid-feedback">{{ primarycolorError }}</div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <!-- Start date -->
          <div class="form-group">
            <!-- Label -->
            <label for="secondarycolor" class="form-label">
              Secondary Color (hex color)
            </label>
            <br/>
            <!-- Input -->
            <input
                id="secondarycolor"
                name="secondarycolor"
                v-model="secondarycolor"
                type="color"
                class="form-control, icp__input"
                :class="[secondarycolorError ? 'is-invalid' : 'is-valid']"
                @change="secondaryColorChange()"
            />
            <div class="invalid-feedback">{{ secondarycolorError }}</div>
          </div>
        </div>
      </div>
      <!-- / .row -->

      <div class="form-group">
        <!-- Label -->
        <label for="title" class="form-label">
          Tenantname
        </label>

        <!-- Input -->
        <input
            name="title"
            id="title"
            v-model="title"
            type="text"
            class="form-control"
            :class="[titleError ? 'is-invalid' : 'is-valid']"
        />
        <div class="invalid-feedback">{{ titleError }}</div>
      </div>

      <!-- Tenant description -->
      <div class="form-group">
        <!-- Label -->
        <label for="description" class="form-label">Tenant description</label>

        <!-- Text -->
        <!-- <small class="form-text text-muted">
        This is how others will learn about the project, so make it good!
      </small> -->

        <!-- Quill -->

        <textarea
            class="form-control"
            name="description"
            id="description"
            rows="3"
            v-model="description"
            :class="[descriptionError ? 'is-invalid' : 'is-valid']"
        ></textarea>

        <div class="invalid-feedback">
          {{ descriptionError }}
        </div>
      </div>

      <!-- Divider -->
      <!-- Footer -->
      <div class="row align-items-center mt-5 mb-5">
        <div class="col text-center">
          <!-- Step -->
          <!-- <h6 class="text-uppercase text-muted mb-0">Step 3 of 3</h6> -->
        </div>
        <div class="col-auto">
          <!-- Button -->
          <button class="btn btn-lg btn-primary" type="submit" :disabled="!isSecondaryColorCorrect">
            Update
          </button>
        </div>
      </div>
    </form>
    <hr class="mt-5 mb-5"/>
    <!-- logo Upload -->
    <div style="min-height: 100px" class="row justify-content-between align-items-center">

      <ImageUpload class="image-upload"
                   folderName="tenant_assets"
                   :src="tenantLogoURL"
                   :cropperAspectRatio="null"
                   type="onImage"
                   :hasPreview="true"
                   :isRoundedPreview="false"
                   title="Tenant Logo"
                   description="PNG or JPG no bigger than 9MB."
                   @pictureReady="pictureReadyHandler($event)"
      />

    </div>
    <!-- logo upload end -->
    <hr class="mt-5 mb-5"/>

    <div class="row mb-5">
      <div class="col-12 col-md-12">
        <!-- Warning -->
        <div class="card bg-light border">
          <div class="card-body">
            <!-- Heading -->
            <h4 class="mb-2">
              <i class="fe fe-alert-triangle"></i> Warning
            </h4>

            <!-- Text -->
            <p class="small text-muted mb-0">
              If you delete your tenant, you cannot revert it.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-12 col-md-6">
        <!-- Heading -->
        <h4>
          Delete your Tenant
        </h4>

        <!-- Text -->
        <p class="small text-muted mb-md-0">
          Please note, deleting your Tenant is a permanent action and will
          no be recoverable once completed. All active subscription will
          get invalid and you can't use them anymore.
        </p>
      </div>
      <div class="col-auto">
        <!-- Button -->
        <button class="btn btn-danger">
          Delete
        </button>
      </div>
    </div>
    <!-- / .row -->

    <!-- Divider -->
    <hr class="my-5"/>
  </div>

</template>

<script>

import LogoUpload from "@/components/upload/LogoUpload.vue";
import {useField, useForm} from "vee-validate";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import ImageUpload from "@/components/upload/ImageUpload.vue";

export default {

  name: 'TenantSettings',

  components: {
    ImageUpload,
    LogoUpload
  },

  setup() {

    const store = useStore();

    const tenantLogoURL = ref(null);

    const hasContentLoaded = ref(false);

    const isSecondaryColorCorrect = ref(true);

    // Define a validation schema
    const settingSchema = {
      primarycolor(value) {
        const error = 'Please provide a HEX Color Code'
        if (value && value.trim()) {
          const r = RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$')
          if (r.test(value)) {
            return true
          } else {
            return error
          }
        }
        return error
      },

      secondarycolor(value) {
        const error = 'Please provide a HEX Color Code'
        if (value && value.trim()) {
          const r = RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$')
          if (r.test(value)) {
            return true
          } else {
            return error
          }
        }
        return error
      },
      title(value) {
        const title = value.trim()
        if (title.length >= 6) {
          return true
        }
        return 'minimum 6 characters'
      },
      description(value) {
        const description = value.trim()
        if (description.length >= 10) {
          return true
        }
        return 'Please provide a Description with minimum 10 Character'
      }
    };

    // Create a form context with the validation schema
    const { handleSubmit, setValues } = useForm({
      validationSchema: settingSchema,
      initialValues: {
        primarycolor: store.state.tenant.tenantinfo.primarycolor,
        secondarycolor: store.state.tenant.tenantinfo.secondarycolor,
        title: store.state.tenant.tenantinfo.title,
        description: store.state.tenant.tenantinfo.description
      }
    })
    // const { setValues } = useForm()

    // setValues({
    //   primarycolor: store.state.tenant.tenantinfo.primarycolor,
    //   secondarycolor: store.state.tenant.tenantinfo.secondarycolor,
    //   tenantname: store.state.tenant.tenantinfo.title,
    //   tenantdescription: store.state.tenant.tenantinfo.description
    // })

    // No need to define rules for fields
    const { value: primarycolor, errorMessage: primarycolorError } = useField(
        'primarycolor'
    )
    const {
      value: secondarycolor,
      errorMessage: secondarycolorError
    } = useField('secondarycolor')
    const { value: title, errorMessage: titleError } = useField('title')
    const { value: description, errorMessage: descriptionError } = useField(
        'description'
    )

    const onSubmit = handleSubmit(values => {
      store.dispatch('loading/setLoading', true)
      // alert(JSON.stringify(values, null, 2))
      // alert(store.state.tenantinfo.baseAPI)
      // dispatch with a payload
      store.dispatch('tenant/update', values)
      //console.log(values)
    })

    const isUploadVisible = computed(() => {
      return store.state.file.isUploadVisible
    })

    const showUpload = () => {
      store.commit('file/setUploadVisible', true)
    }

    const secondaryColorChange = async () => {
      if (secondarycolor.value === primarycolor.value) {
        isSecondaryColorCorrect.value = false;
        await store.dispatch('notifications/addNotification', {
          type: 'error',
          message: 'Secondary color cannot be same color as primary'
        });
      } else {
        isSecondaryColorCorrect.value = true;
      }
      if(secondarycolor.value === '#ffffff') {
        isSecondaryColorCorrect.value = false;
        await store.dispatch('notifications/addNotification', {
          type: 'error',
          message: 'Secondary color cannot be white'
        });
      }
    }


    // logo upload

    // const tenantLogo = computed(() => {
    //   // console.log('getPicture')
    //   // console.log(store.state.auth.userinfo.picture)
    //   return store.state.tenant.tenantinfo.logo
    // });

    const pictureReadyHandler = async (ev) => {
      let payload = {
        logo: `${ev.name}.${ev.ext}`
      };
      await store.dispatch('file/uploadFile', ev);
      await store.dispatch('tenant/setTenantLogo', payload);
    };

    const getTenantLogoURL = async() => {
      if (store.state.tenant.tenantinfo.logo !== null && store.state.tenant.tenantinfo.logo !== '' && store.state.tenant.tenantinfo.logo !== undefined) {
        tenantLogoURL.value = await store.dispatch('file/getFile', {
          file_name: store.state.tenant.tenantinfo.logo,
          folder_name: 'tenant_assets'
        });
      }
    };

    onMounted(async () => {
      await getTenantLogoURL();
      hasContentLoaded.value = true;
    });

    // end of logo upload

    return {
      store,
      hasContentLoaded,
      primarycolor,
      primarycolorError,
      secondarycolor,
      secondarycolorError,
      title,
      titleError,
      description,
      descriptionError,
      onSubmit,
      isUploadVisible,
      showUpload,

      pictureReadyHandler,
      getTenantLogoURL,
      tenantLogoURL,
      secondaryColorChange,
      isSecondaryColorCorrect

    }
  }

}

</script>

<style lang="scss" scoped>
#tenant-settings {
  .icp__input {
    height: 25px;
    border-radius: 15px;
    width: 40px;
    border-width: 0.1em;
    border-color: black;
    border-style: solid;

    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding: 0;
  }
  .icp__input::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  .icp__input::-webkit-color-swatch {
    border-width: 0.1em;
    border-color: black;
    border-style: solid;
    border-radius: 15px;
  }
  .icp__input::-moz-color-swatch {
    border-width: 0.1em;
    border-color: black;
    border-style: solid;
    border-radius: 15px;
  }
}
</style>
