<template>

  <div v-if="hasContentLoaded" id="user-journeys" v-cloak>

    <div v-show="allImagesLoaded"
         :class="{ 'journeys-wrapper': true, 'row': viewType === 'grid'}">

      <div v-if="showCreateNew && (currentUserRoles.TenantAdmin || currentUserRoles.Facilitator)"
           :class="{ 'card-wrapper': true,
                     'col-12': true, 'col-md-6': viewType === 'grid', 'col-xl-3': viewType === 'grid', 'mb-3': viewType === 'grid'}"
      >

        <div v-show="viewType === 'grid'" class="card empty-card grid" @click="isNewJourneyOptionsModalDisplayed = true">
          <!-- Image -->
          <div class="image-wrapper">

            <div class="card-image">
              <font-awesome-icon class="icon plus"
                                 icon="plus"/>
            </div>

          </div>


          <!-- Body -->
          <div class="card-body">

            <div class="row align-items-center">

              <div class="col">

                <!-- Title -->
                <h4 class="mb-2 name">
                  <span>Create New Journey</span>
                </h4>

                <!-- Subtitle -->
                <p class="card-text small text-muted"></p>

              </div>

              <div class="col-auto">

              </div>

            </div>

          </div>

          <!-- Footer -->
          <div class="card-footer card-footer-boxed">

          </div>
        </div>

        <div v-show="viewType === 'list'" class="card empty-card list mb-3" @click="isNewJourneyOptionsModalDisplayed = true">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <div class="image-wrapper">

                  <div class="card-image">

                    <font-awesome-icon class="icon plus"
                                       icon="plus"/>
                  </div>

                </div>

              </div>
              <div class="col ms-n2">

                <!-- Title -->
                <h4 class="mb-1 name">
                  <span>Create New Journey</span>
                </h4>

                <!-- Text -->
                <p class="card-text small text-muted mb-1">
                  <time datetime="2018-06-21"></time>
                </p>

              </div>

              <div class="col-auto">

              </div>
            </div>
            <!-- / .row -->
          </div>
          <!-- / .card-body -->
        </div>

        <transition name="fade">

          <div class="custom_modal new-journey-options_modal" v-if="isNewJourneyOptionsModalDisplayed">

            <div class="custom_modal__content">

              <h3>How would you like to proceed?</h3>

              <table v-if="journeyReusableTemplates.length > 0" id="journey-reusable-templates">

                <thead>
                <tr>
                  <th colspan="2">Existing Templates</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(journeyReusableTemplate, indexJourneyReusableTemplate) in journeyReusableTemplates"
                    :key="indexJourneyReusableTemplate"
                >
                  <td>
                    <router-link :to="{ name: 'JourneyReusableTemplateMap', params: { id: journeyReusableTemplate.ID } }"> {{ journeyReusableTemplate.Name }} </router-link>
                  </td>

                  <td>
                    <button class="btn btn-primary"
                            @click="createFromTemplateClickHandler(journeyReusableTemplate.ID)"
                    >
                      <span>Use</span>
                    </button>
                  </td>

                  <!--        <td>-->
                  <!--          <span>{{ getFacilitatorName(journey.AuthorID) }}</span>-->
                  <!--        </td>-->

                  <!--        <td>-->
                  <!--          <div class="edit-icon" @click="displayModal(journey.ID, journey.AuthorID)">-->
                  <!--            <font-awesome-icon-->
                  <!--                class="icon"-->
                  <!--                icon="edit"-->
                  <!--            />-->
                  <!--          </div>-->

                  <!--        </td>-->

                </tr>
                </tbody>

              </table>

              <div class="buttons-wrapper">
                <button class="btn btn-primary" type="button" @click="createNewJourney()">Create from scratch</button>
<!--                <button class="btn btn-primary" type="button" @click="isNewJourneyOptionsModalDisplayed = false">Cancel</button>-->
              </div>

            </div>

            <div class="backdrop" @click="isNewJourneyOptionsModalDisplayed = false"></div>

          </div>

        </transition>

      </div>

      <div v-for="(journey, indexJourney) in currentUserJourneys"
           :key="indexJourney"
           :class="{ 'card-wrapper': true,
                     'col-12': true, 'col-md-6': viewType === 'grid', 'col-xl-3': viewType === 'grid', 'mb-3': viewType === 'grid', 'hidden': !isJourneyCardDisplayed(journey) }"
      >

        <JourneyCard v-if="isJourneyCardDisplayed(journey)"
                     :journey="journey"
                     :currentUserRoles="currentUserRoles"
                     :viewType="viewType"
                     :showMobile="showMobile"
        />

      </div>


    </div>

  </div>

</template>

<script>

import {Icon} from "@iconify/vue";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import ImportUsers from "@/components/users/ImportUsers.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import JourneyCard from "@/components/journey/JourneyCard.vue";

export default {

  name: 'UserJourneys',

  components: {
    JourneyCard,
    FontAwesomeIcon,
    ImportUsers,
    Icon
  },

  props: {
    viewType: {
      type: String,
      default: 'grid'
    },
    showCreateNew: {
      type: Boolean,
      default: true
    },
    showMobile: {
      type: Boolean,
      default: false
    }
  },

  setup() {

    const store = useStore();

    const router = useRouter();

    const hasContentLoaded = ref(false);

    const currentUserJourneys = ref([]);

    const isNewJourneyOptionsModalDisplayed = ref(false);

    const journeyReusableTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyReusableTemplates"]);

    const currentUserRoles = computed(() => store.getters["auth/getCurrentUserRoles"]);

    // wait for images to render
    const images = ref([]);
    const loadedImagesCount = ref(0);

    const allImagesLoaded = computed(() => {
      return loadedImagesCount.value === images.value.length;
    });

    const isJourneyCardDisplayed = (journey) => {
      if (journey.IsCurrentUserAuthor) {
        return true;
      }
      else {
        if (journey.hasOwnProperty('JourneyRole') && journey.hasOwnProperty('Active') && journey.JourneyRole === 'Participant') {
          return journey.Active;
        }
        else {
          return true;
        }
      }
    };

    const imageLoaded = () => {
      loadedImagesCount.value++;
    };

    const checkAllImagesLoaded = () => {
      if (allImagesLoaded.value) {
        // All images are loaded, you can perform additional actions or display the page
      } else {
        // Continue checking until all images are loaded
        setTimeout(checkAllImagesLoaded.value, 100);
      }
    };
    // end of wait for images to render

    const getCurrentUserJourneys = async () => {
      let journeys = store.state.journey.userJourneys;

      for (const journey of journeys) {
        if (journey.hasOwnProperty('Picture')
            && journey.Picture !== null
            && journey.Picture !== undefined
            && journey.Picture !== '') {
          journey.PictureURL = await store.dispatch('file/getFile', {
            file_name: journey.Picture,
            folder_name: `journey/${journey.ID}`
          });

          // wait for images to render
          images.value.push(journey.ID);
          const img = new Image();
          img.src = journey.PictureURL;
          img.addEventListener('load', imageLoaded);
          // end of wait for images to render
        }
      }
      currentUserJourneys.value = journeys;

    };

    const createNewJourney = () => {
      router.push({
        name: 'JourneyMap'
      })
    };

    const createFromTemplateClickHandler = async (templateID) => {

      let journeyTemplate = journeyReusableTemplates.value.find((journeyReusableTemplate) => journeyReusableTemplate.ID === templateID);

      if (journeyTemplate !== undefined) {
        let payload = {
          "Name": journeyTemplate.Name,
          "Description": journeyTemplate.Description,
          "CategoryID": journeyTemplate.CategoryID,
          "Picture": journeyTemplate.Picture,
          "Overview": journeyTemplate.Overview,
          "Structure": journeyTemplate.Structure,
          "TemplateID": journeyTemplate.ID,
        };

        let newJourney = await store.dispatch('journey/copyJourney', payload);

        await router.push({
          name: 'JourneyMap',
          params: {
            id: newJourney.ID
          }
        });

      }

    };

    onMounted(async() => {

      await getCurrentUserJourneys();

      if (currentUserRoles.value.TenantAdmin || currentUserRoles.value.Facilitator) {
        await store.dispatch('journeyReusableTemplates/listReusableTemplates');
      }

      // wait for images to render
      // Start checking if all images are loaded. so the page is displayed only when images are rendered
      checkAllImagesLoaded();
      // end of wait for images to render
      hasContentLoaded.value = true;
    });

    return {
      hasContentLoaded,
      isNewJourneyOptionsModalDisplayed,
      journeyReusableTemplates,
      allImagesLoaded,
      currentUserJourneys,
      currentUserRoles,
      isJourneyCardDisplayed,
      createNewJourney,
      createFromTemplateClickHandler,
    }
  }

}

</script>

<style lang="scss">

#user-journeys {
  .journeys-wrapper {

    .hidden {
      display: none;
    }

    .card-wrapper {
      //width: 400px;

      .empty-card {
        cursor: pointer;

        .card-image {
          background-color: #aaa;

          .icon.plus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            opacity: 1;
            font-size: 100px;
          }
        }

        &.grid {
          //max-width: 500px;
          height: 100%;

          .image-wrapper {
            position: relative;

            .card-image {
              height: 300px;
              cursor: pointer;
            }

            .icon.play {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: white;
              opacity: 0.3;
              font-size: 160px;
            }
          }
        }

        &.list {
          .image-wrapper {
            position: relative;

            .card-image {
              height: 4rem;
              width: 5.33333rem;
              background-color: #aaa;
              cursor: pointer;

              .icon.plus {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                opacity: 1;
                font-size: 30px;
              }
            }
          }
        }

        .dropdown-item {
          cursor: pointer;
        }

        .card-footer {
          height: 40px;
        }
      }


      .new-journey-options_modal {
        .custom_modal__content {
          display: block;
          text-align: center;

          table {
            margin: 20px auto;

            a {
              text-decoration: none;
              color: black;
            }
          }

          .buttons-wrapper {
            //margin-top: 20px;
          }
        }
      }

    }
  }
}

@media only screen and (max-width: 768px) {
  #user-journeys {
    .journeys-wrapper {
      .card-wrapper {
        .card {
          &.grid {
            .image-wrapper {
              .card-image {
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1000px) {
  #user-journeys {
    .journeys-wrapper {
      .card-wrapper {
        width: 33.33%;
      }
    }
  }
}

</style>