<template>
<!--  <Logon v-if="!isAuthenticated" />-->
  <!--
  <div v-if="!isAuthenticated">v-if="!isAuthenticated"</div>
  <div v-if="isAuthenticated">v-if="isAuthenticated"</div> -->
  <!-- <pre>
  {{ store.state.auth }}
  </pre> -->
  <PwaNotification></PwaNotification>
  <NotificationsList></NotificationsList>
  <UserNotifications></UserNotifications>
  <TermsAndConditions :style="tenantcolor"></TermsAndConditions>
  <template v-if="isMobileRoute && isLoggedIn">
      <div class="mobile-content">
        <div class="main">
          <div v-if="hasContentLoaded" :style="tenantcolor">
            <router-view />
          </div>
        </div>
      </div>
    </template>
  <template v-else-if="isMobilePage">
    <div class="content">
      <div class="bottombar" :style="tenantcolor" v-if="isLoggedIn">
      </div>
      <div class="main">
        <div v-if="hasContentLoaded" :style="tenantcolor">
        <router-view />
      </div>
      </div>
  </div>

  </template>
 <!-- <template v-else-if="isMobile && !isAuthenticated">
    <MobileInformation />
  </template> -->
  <template v-else>
    
    <MainNav v-if="hasContentLoaded && isLoggedIn" />

    <div class="content">
      <div class="bottombar" :style="tenantcolor" v-if="isLoggedIn">
        <!-- <sidebarNavMobile v-if="hasContentLoaded && showMobileNav"/> -->
      </div>
      <div class="main">
        <div v-if="hasContentLoaded" :style="tenantcolor">
        <router-view />
      </div>
      </div>
  </div>
  </template>


<!--  <Footer />-->
  <Overlay v-if="isLoading" :mobile="isMobile" />

</template>

<script>
import {onMounted, computed, onBeforeUnmount, ref, provide} from 'vue'
import Amplify, { Auth } from 'aws-amplify'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import Footer from '@/components/Footer'
import Overlay from '@/components/Overlay'
import Logon from '@/components/auth/Logon'
import commovisconfig from '@/services/config'

import {mapGetters, useStore} from 'vuex'
import { useRouter, useRoute } from "vue-router";
import NotificationsList from "@/components/notifications/NotificationsList.vue";
import UserNotifications from "@/components/userNotifications/UserNotifications.vue";
import $ from "jquery";
import MainNav from "@/components/MainNav.vue";
import SidebarNav from "@/components/SidebarNav";
import PageNotFound from "@/views/PageNotFound";
import SidebarNavMobile from "@/components/SidebarNavMobile";
import TermsAndConditions from "@/components/common/TermsAndConditions.vue";
import MobileInformation from "@/components/mobile/MobileInformation.vue";
import PwaNotification from "@/components/mobile/PwaNotification.vue";
import { useDevice } from '@/composables/useDevice';

function increase_brightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '')

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1')
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16)

  return (
    '#' +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  )
}
function decrease_brightness(hex, percent) {
  var r = parseInt(hex.substr(1, 2), 16),
    g = parseInt(hex.substr(3, 2), 16),
    b = parseInt(hex.substr(5, 2), 16)

  return (
    '#' +
    (0 | ((1 << 8) + (r * (100 - percent)) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + (g * (100 - percent)) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + (b * (100 - percent)) / 100)).toString(16).substr(1)
  )
}

export default {

  name: 'App',

  components: {
    SidebarNavMobile,
    PageNotFound,
    SidebarNav,
    MainNav,
    NotificationsList,
    UserNotifications,
    Footer,
    Overlay,
    Logon,
    TermsAndConditions,
    MobileInformation,
    PwaNotification
  },

  setup() {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // route that should be exlusivley for mobile only and loaded with the mobile layout
    const isMobileRoute = computed(() => {
      //console.log('Current route name:', route.name); // Debugging line
      return route.name === 'MobileDashboard' || route.name === 'MobileTreasureChest'|| route.name === 'MobileJourneyMap' || route.name === 'MobileAccount'
      || route.name === 'MobileSettings' // Add other mobile route names if needed
    })

    // pages on the normal layouts but only available on mobile
    const isMobilePage = computed(() => {
      //console.log('Current route name:', route.name); // Debugging line
      return route.name === 'MobileJourneyView' || route.name === 'Logon'// Add other mobile route names if needed
    })

    const { isMobile } = useDevice();

    const isLoggedIn = computed(() => {
      return store.state.auth.loggedIn
    })

    const showMobileNav = ref(false);

    const updateShowMobileNav = () => {
      const mediaQuery = window.matchMedia('(max-width: 535px)');
      //console.log('Media query matches:', mediaQuery.matches);
      showMobileNav.value = mediaQuery.matches;
    };

    const skipDoubleSignIn = ref(false);

    const hasContentLoaded = ref(false);

    const initAmplify = tenantinfo => {
      Amplify.configure({
        Auth: {
          region: commovisconfig.region,
          userPoolId: tenantinfo.details.Id,
          userPoolWebClientId: tenantinfo.details.CId,
          mandatorySignIn: true
          //  cookieStorage: {
          //    domain: '.dev.commovis.net',
          //    path: '/',
          //    expires: 365,
          //    secure: true
          //  }
        }
      })
    }

    // tenant info is fetched on main.js
    initAmplify(store.state.tenant.tenantinfo);

    const getCurrentAuthenticatedUser = async () => {

      try {

        let bearerDetails = undefined;
        let bearerDetailsBase64 = undefined;
        let commovisToken = undefined;

        let currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
        const isAuthenticated = currentAuthenticatedUser !== null;
        // console.log('currentUserInfo', currentUserInfo);
        // console.log('currentAuthenticatedUser', currentAuthenticatedUser);

        await store.dispatch('auth/isAuthenticated', isAuthenticated);
        await store.dispatch('auth/setUserInfo', currentAuthenticatedUser.attributes);
        await store.dispatch('auth/getUserDetails', currentAuthenticatedUser);
        await store.dispatch('auth/changeAuthState', 'signedin');

        // create our own Bearer
        bearerDetails =
            store.state.tenant.tenantinfo.details.CId +
            '::' +
            store.state.tenant.tenantinfo.details.Id +
            '::' +
            store.state.tenant.tenantinfo.id

        bearerDetailsBase64 = btoa(bearerDetails);

        commovisToken =
            'Bearer ' +
            store.state.auth.user.signInUserSession.accessToken.jwtToken +
            '::::' +
            bearerDetailsBase64

        await store.dispatch('auth/getBearerDetails', commovisToken);

      }
      catch (err) {
        await store.dispatch('auth/isAuthenticated', false);
      }

    };

    const isAuthenticated = computed(() => {
      return store.state.auth.loggedIn
    });

    const isLoading = computed(() => {
      return store.state.loading.isLoading
    });

    const tenantcolor = computed(() => {
      return {
        '--primary-color': store.state.tenant.tenantinfo.primarycolor,
        '--primary-color-hover': decrease_brightness(
          store.state.tenant.tenantinfo.primarycolor,
          20
        ),
        '--primary-color-disabled': increase_brightness(
          store.state.tenant.tenantinfo.primarycolor,
          30
        ),
        '--secondary-color': store.state.tenant.tenantinfo.secondarycolor,
        '--secondary-color-hover': decrease_brightness(
          store.state.tenant.tenantinfo.secondarycolor,
          20
        ),
        '--secondary-color-disabled': increase_brightness(
          store.state.tenant.tenantinfo.secondarycolor,
          30
        )
      }
    });

    const userJourneys = computed(() => store.getters["journey/getUserJourneys"]);

    const currentUserRoles = ref();

    currentUserRoles.value = store.getters["auth/getCurrentUserRoles"];

    const unsubscribeAuth = onAuthUIStateChange(async (authState, authData) => {

      if (!skipDoubleSignIn.value) {

        let bearerDetails = undefined;
        let bearerDetailsBase64 = undefined;
        let commovisToken = undefined;

        switch (authState) {

          case AuthState.SignIn: {
            console.log('onAuthUIStateChange fired - signin');
            await store.dispatch('auth/isAuthenticated', false);
            await store.dispatch('auth/getUserDetails', null);
            await store.dispatch('auth/changeAuthState', authState);

            break;
          }

          case AuthState.SignedIn: {

            hasContentLoaded.value = false;
            skipDoubleSignIn.value = true;

            console.log('onAuthUIStateChange fired - signedin');
            await store.dispatch('auth/isAuthenticated', true);
            await store.dispatch('auth/setUserInfo', authData.attributes);
            await store.dispatch('auth/getUserDetails', authData);
            await store.dispatch('auth/changeAuthState', authState);


            // create our own Bearer
            bearerDetails =
                store.state.tenant.tenantinfo.details.CId +
                '::' +
                store.state.tenant.tenantinfo.details.Id +
                '::' +
                store.state.tenant.tenantinfo.id

            bearerDetailsBase64 = btoa(bearerDetails);

            commovisToken =
                'Bearer ' +
                store.state.auth.user.signInUserSession.accessToken.jwtToken +
                '::::' +
                bearerDetailsBase64

            await store.dispatch('auth/getBearerDetails', commovisToken);

            await loggedInUserAPICalls();

            hasContentLoaded.value = true;

            break;
          }

          case AuthState.VerifyContact: {

            hasContentLoaded.value = false;
            skipDoubleSignIn.value = true;

            console.log('onAuthUIStateChange fired - verify contact');
            await store.dispatch('auth/isAuthenticated', true);
            await store.dispatch('auth/setUserInfo', null);
            await store.dispatch('auth/getUserDetails', authData);
            await store.dispatch('auth/changeAuthState', authState);


            // create our own Bearer
            bearerDetails =
                store.state.tenant.tenantinfo.details.CId +
                '::' +
                store.state.tenant.tenantinfo.details.Id +
                '::' +
                store.state.tenant.tenantinfo.id

            bearerDetailsBase64 = btoa(bearerDetails);

            commovisToken =
                'Bearer ' +
                store.state.auth.user.signInUserSession.accessToken.jwtToken +
                '::::' +
                bearerDetailsBase64

            await store.dispatch('auth/getBearerDetails', commovisToken);

            // Attribute updated successfully
            await store.dispatch('users/verifyUserEmail');

            await getCurrentAuthenticatedUser();

            await loggedInUserAPICalls();

            hasContentLoaded.value = true;

            break;
          }

          case AuthState.SignOut: {
            console.log('onAuthUIStateChange fired - ""signout""');
            break;
          }

          case AuthState.SignedOut: {
            console.log('onAuthUIStateChange fired - "signedout"');
            //store.replaceState({});
            await store.dispatch('auth/resetState');
            await store.dispatch('users/resetState');
            //localStorage.clear();
            await router.push({name: 'Start'});
            localStorage.clear();
            //location.reload();
            break;
          }

          default: {
            await store.dispatch('auth/isAuthenticated', false);
            await store.dispatch('auth/changeAuthState', authState);
            await store.dispatch('auth/getUserDetails', null);
            break;
          }

        }

      }
      else {
        skipDoubleSignIn.value = false;
      }

    });

    const checkJourneysChaptersUnlock = async() => {

      let journeysIDs = [];
      userJourneys.value.forEach((userJourney)=> {
        journeysIDs.push(userJourney.ID);
      });

      let payload = {
        JourneysIDs: journeysIDs,
      }
      await store.dispatch('journey/checkJourneysChaptersUnlock', payload);
    };

    const loggedInUserAPICalls = async () => {
      await store.dispatch('journey/listUserJourneys');
      await checkJourneysChaptersUnlock();
      await store.dispatch('userNotifications/fetchUserNotifications');
      // await store.dispatch('userPreferences/addExistingUsersToUserPreferencesTable');
      await store.dispatch('userPreferences/fetchUserPreferences');
      await store.dispatch('internalCurrency/getAmount');
      await store.dispatch('tenantSpecificAssets/getExpeditionLogs');
    }

    onMounted(async () => {
      await getCurrentAuthenticatedUser();
      if (store.state.auth.loggedIn) {
        await loggedInUserAPICalls();
      }
      await store.dispatch('loading/setLoading', false);

      updateShowMobileNav();
      window.addEventListener('resize', updateShowMobileNav);

      hasContentLoaded.value = true;
    });

    onBeforeUnmount(() => {
      unsubscribeAuth();
      window.removeEventListener('resize', updateShowMobileNav);
    });

    return {
      hasContentLoaded,
      store,
      isAuthenticated,
      isLoading,
      tenantcolor,
      isMobileRoute,
      isMobilePage,
      isMobile,
      isLoggedIn,
      showMobileNav
    }
  },

}
</script>

<style lang="scss">
.content {
  display: inline;
  .bottombar {
    width: 100%;
    background-color: var(--primary-color) !important;
  }
  .main {
    width: 100%;
  }
  @media only screen and (min-width: 534px) {
    #mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 535px) {
    #mobile {
      display: block;
    }
  }
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: var(--primary-color-hover) !important;
  color: #fff !important;
  border-color: var(--primary-color-hover) !important;
  box-shadow: none !important;
}

.btn-primary {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.nav > a {
  float: left;
  position: relative;
  font-size: 0.8rem;
}
/* Style the links inside the navigation bar */
.nav a {
  color: gray;
}

.nav a:hover {
  border-bottom: 3px solid var(--primary-color-hover);
  color: var(--primary-color);
}

.nav a.active {
  border-bottom: 3px solid var(--primary-color);
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary-color-hover) !important;
}

.h4,
h4 {
  font-size: 0.9375rem !important;
}
.small,
small {
  font-size: 0.8125rem !important;
}
.progress-sm {
  height: 0.25rem !important;
}
.avatar-img {
  -o-object-fit: cover;
  object-fit: cover;
  margin: 2px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}
img,
svg {
  vertical-align: middle !important;
}
.btn-white {
  border-color: var(--primary-color) !important;
}
.btn-check:focus + .btn-white,
.btn-white,
.btn-white:focus,
.btn-white:hover {
  color: var(--primary-color) !important;
  background-color: #fff;
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.nav.btn-group .btn-white.active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}
.badge.bg-primary-soft {
  color: white;
  text-decoration: none;
}

.badge {
  vertical-align: bottom;
}
.bg-primary-soft {
  background-color: lightgray;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.5em !important;
  font-size: 76%;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
}

.invalid-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0;
  font-size: 0.8125rem;
  color: #e63757;
}
.input-group-merge.input-group-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group-lg.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.input-group-lg.input-group-merge.input-group-reverse > .form-control {
  padding-right: 1.25rem !important;
  border-radius: 0 0.5rem 0.5rem 0 !important;
}
.input-group-merge.input-group-reverse > .form-control {
  padding-right: 0.75rem;
  padding-left: 0;
  border-radius: 0 0.375rem 0.375rem 0;
  border-right-width: 1px;
  border-left-width: 0;
}
.input-group-lg.input-group-merge.input-group-reverse:not(.input-group-rounded)
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.input-group-lg.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: 0.5rem 0 0 0.5rem !important;
  border-right-width: 0;
}
.input-group-merge > .input-group-text {
  border-left-width: 1;
  border-right-width: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.75rem 1.25rem;
  font-size: 0.9375rem;
  border-radius: 0.5rem;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #95aac9 !important;
  text-align: center;
  white-space: nowrap;
  background-color: #fff !important;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-orange-button {
    background-color: initial;
    background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    padding: 0 20px;
    text-align: center;
    border: 0;
    transition: box-shadow .2s;
    font-size: 20px;

    &:hover {
      box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
    }

}

.table-textarea {
  font-size: 14px;
  padding: 12px 20px;
  border-radius: 4px;
  resize: none;
  &:focus{
    outline: none;
  }
}

table {
  border-collapse: collapse;
  display: block;
  overflow: auto;
  max-width: 100%;
  max-height: 50vh;
  white-space: nowrap;
  width: fit-content;
  position: relative;
  box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
}

table thead {
  position: sticky;
  top: 0;
}
table thead th{
  background-color: #f1f6fc;
}

table tr:last-child td {
  border-bottom: none;
}

table tr td {
  border-right: none;
  background-color: white;
  color: black
}

table tr th {
  //background-color: white;
  color: black
}

table tr th i {
  cursor: pointer;
}

table tr td:first-child, table tr th:first-child {
  border-left: none;
}

table, table td, table th {
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
}
table td.company {
  font-weight: 600;
}
table tr {
  border: 1px solid #ebebeb;
}

table tr td, table tr th {
  padding: 10px;
  h2 {
    font-weight: 600;
    font-size: 16px !important;
  }
}

table td.action {
  position: unset;
  display: flex;
}

table td.action img {
  width: 35px;
  cursor: pointer;
  margin: auto;
}

input, select {
  &:focus {
    outline: none;
  }

}

.disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: not-allowed;
  background-image: unset;
  background-color: #9e9191;
}

.side-by-side {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon {
  cursor: pointer;
}
div.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;

  &__content {
    background-color: rgba(232, 231, 231);
    width: 590px;
    max-height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    border: 1px solid black;
    font-size: 18px;
    text-align: center;
    overflow: auto;

    h3 {
      font-weight: bold;
      text-transform: uppercase;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

  }

  .backdrop {
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

}

.editorx_body {
  min-height: 200px;
  border: 2px solid #c8c8c8c8;
  box-sizing: border-box;

  .cdx-block {
    &.embed-tool {
      padding-right: 100px;
      cursor: text;
    }

    &.image-tool {
      .image-tool__image {
        img.image-tool__image-picture {
          width: 100%;
        }
      }
    }
  }

  .ce-block {
    img {
      width: 100%;
    }
  }
}

.ce-block--focused {
  //background: linear-gradient(
  //        90deg,
  //        rgba(2, 0, 36, 1) 0%,
  //        rgba(9, 9, 121, 0.5438550420168067) 35%,
  //        rgba(0, 212, 255, 1) 100%
  //);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: black;
}

</style>
