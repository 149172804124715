<template>

  <div id="journey-category-management">

    <div class="heading">
      <h2>Journey Categories</h2>
      <div class="icon plus-icon" @click="displayModal()">
        <font-awesome-icon icon="plus"/>
      </div>

    </div>

    <div class="categories">

      <div v-for="(category, indexCategory) in categories"
           :key="indexCategory"
           class="category">

        <span>{{ category.Name }}</span>

        <div class="actions">

          <div class="icon edit-icon" @click="displayModal(category)">
            <font-awesome-icon icon="edit"/>
          </div>

          <div class="icon delete-icon" @click="deleteCategory(category.ID)">
            <font-awesome-icon icon="trash"/>
          </div>

        </div>

      </div>

    </div>

    <transition name="fade">

      <div class="custom_modal" v-if="isModalDisplayed">

        <div class="custom_modal__content">
          <h3>
            <span v-if="isAddNew">Add</span>
            <span v-else>Edit</span>
            <span> Category</span>
          </h3>
          <form @submit.prevent="saveModalHandler">

            <div class="input-wrapper">
              <label>Name:</label>
              <input type="text"
                     v-model="modalCategory.Name"
                     required>
            </div>

            <div class="buttons-wrapper">
              <button class="btn btn-primary" type="submit">SAVE</button>
              <button class="btn btn-primary" type="button" @click="closeModal()">CANCEL</button>
            </div>

          </form>

        </div>

        <div class="backdrop" @click="closeModal()"></div>

      </div>

    </transition>

  </div>

</template>

<script>

import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {

  name: 'JourneyCategoryManagement',

  setup() {

    const store = useStore();

    const isModalDisplayed = ref(false);

    const isAddNew = ref(true);

    const modalCategory = ref({
      ID: null,
      Name: null,
    });

    const categories = computed(() => store.getters["journeyCategory/getJourneyCategories"]);

    const displayModal = (updatedCategory = null) => {

      if (updatedCategory === null) {
        isAddNew.value = true;
      }
      else {
        isAddNew.value = false;
        modalCategory.value = JSON.parse(JSON.stringify(updatedCategory));
      }

      isModalDisplayed.value = true;
    };

    const saveModalHandler = async () => {
      if (isAddNew.value) {
        await store.dispatch('journeyCategory/create', modalCategory.value);
      }
      else {
        await store.dispatch('journeyCategory/update', modalCategory.value);
      }
      closeModal();
    };

    const closeModal = () => {
      isModalDisplayed.value = false;
      modalCategory.value = {
        ID: null,
        Name: null,
      }
    };

    const deleteCategory = async (categoryID) => {
      await store.dispatch('journeyCategory/delete', categoryID);
    };

    onMounted( async() => {
      await store.dispatch('loading/setLoading', true);
      await store.dispatch('journeyCategory/list');
      await store.dispatch('loading/setLoading', false);
    });

    return {
      categories,
      isModalDisplayed,
      isAddNew,
      modalCategory,
      displayModal,
      saveModalHandler,
      closeModal,
      deleteCategory,
    }
  }

}

</script>

<style lang="scss" scoped>

#journey-category-management {
  padding: 0 25px 25px 25px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
  width: 50%;
  margin: 0 auto;

  .heading {
    text-align: center;
    position: sticky;
    top: 0;
    background-color: white;
    padding: 20px 0;

    h2 {
      margin-bottom: 0;
      color: var(--primary-color)
    }

    .plus-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 25px;
      cursor: pointer;
    }

  }

  .categories {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;

    .category {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      width: 100%;
      border-radius: 2px;
      padding: 5px;

      span {
        font-size: 20px;
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 25px;
        font-size: 18px;

        .edit-icon, .delete-icon {
          cursor: pointer;
        }
      }

    }


    //.actions {
    //  position: sticky;
    //  bottom: -1px;
    //  display: flex;
    //  align-items: center;
    //  justify-content: space-between;
    //  gap: 10px;
    //  background-color: white;
    //  padding: 20px 0;
    //
    //}
  }

  .custom_modal {


    &__content {

      form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        label {
          margin-right: 10px;
        }

      }

    }

    .backdrop {
      background-color: rgb(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

  }

}

</style>
