import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as drawSvg from 'drawsvg';
import {InlineSvgPlugin} from 'vue-inline-svg';

import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader'

import { VueCsvImportPlugin } from 'vue-csv-import';
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {faAdd, faXmarkCircle, faEdit, faTrash, faShoppingCart, faLock, faLockOpen, faLocationCrosshairs, faBars, faArrowDown, faArrowUp, faCircleCheck, faCheck, faRotateLeft, faUser, faEye, faEyeSlash, faPlus, faMinus, faAngleDown, faAngleRight, faBarsStaggered, faFloppyDisk, faCircleChevronLeft, faCircleChevronRight, faXmark, faListUl, faImage, faEllipsisVertical, faLocationDot, faMapLocationDot, faArrowTurnUp, faSquarePlus, faBookmark, faMap, faGem, faFlag, faStar, faBook, faListCheck, faPaperclip, faUnlock, faCalendar, faRoute, faMagnifyingGlass, faFilePen, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faArrowDownShortWide, faArrowUpShortWide, faTags, faTag, faFilter, faNoteSticky, faClone, faBoxArchive, faCoins, faEquals, faClockRotateLeft} from "@fortawesome/free-solid-svg-icons";

import * as far from "@fortawesome/free-regular-svg-icons";
import './registerServiceWorker'

library.add(faAdd, faXmarkCircle, faEdit, faTrash, faShoppingCart, faLock, faLockOpen, faLocationCrosshairs, faBars, faArrowDown, faArrowUp, faCircleCheck, faCheck, faRotateLeft, faUser, faEye, faEyeSlash, faPlus, faMinus, faAngleDown, faAngleRight, faBarsStaggered, faFloppyDisk, faCircleChevronLeft, faCircleChevronRight, faXmark, faListUl, faImage, faEllipsisVertical, faLocationDot, faMapLocationDot, far.faCirclePlay, far.faCircleCheck, faSquarePlus, faArrowTurnUp, faBookmark, faMap, faGem, far.faCalendar, far.faCalendarPlus, faFlag, faStar, faBook, faListCheck, faPaperclip, faUnlock, faCalendar, faRoute, faMagnifyingGlass, faFilePen, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faArrowDownShortWide, faArrowUpShortWide, faTags, faTag, faFilter, faNoteSticky, faClone, faBoxArchive, faCoins, faEquals, faClockRotateLeft);

applyPolyfills().then(() => {
  defineCustomElements(window)
})

//console.log('localstore')
//const getCache = localStorage.getItem('commovis')
const getCache = false

if (!getCache) {
  //start with tenant information
  store
    .dispatch('tenant/getTenant')
    .then(startVue)
    .catch(error => console.log(error))
} else {
  //use existing tenant information
  startVue()
}

function startVue() {
  const app = createApp(App)
  app.config.isCustomElement = tag => tag.startsWith('amplify-')
  app.use(store)
  app.use(router)
  app.use(VueCsvImportPlugin)
  app.use(drawSvg)
  app.use(InlineSvgPlugin)
  app.component("font-awesome-icon", FontAwesomeIcon)
  app.mount('#app')
}
