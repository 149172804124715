// Batch 1
import Batch1_Card1_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card1_Front.svg';
import Batch1_Card2_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card2_Front.svg';
import Batch1_Card3_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card3_Front.svg';
import Batch1_Card4_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card4_Front.svg';
import Batch1_Card5_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card5_Front.svg';
import Batch1_Card6_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card6_Front.svg';
import Batch1_Card7_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card8_Front.svg';
import Batch1_Card8_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card7_Front.svg';
import Batch1_Card9_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card9_Front.svg';
import Batch1_Card10_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card10_Front.svg';
import Batch1_Card11_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card11_Front.svg';
import Batch1_Card12_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card12_Front.svg';
import Batch1_Card13_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card13_Front.svg';
import Batch1_Card14_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card14_Front.svg';
import Batch1_Card15_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card15_Front.svg';
import Batch1_Card16_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card16_Front.svg';
import Batch1_Card17_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card17_Front.svg';
import Batch1_Card18_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card18_Front.svg';
import Batch1_Card19_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card19_Front.svg';
import Batch1_Card20_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card20_Front.svg';
import Batch1_Card21_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card21_Front.svg';
import Batch1_Card22_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card22_Front.svg';
import Batch1_Card23_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card23_Front.svg';
import Batch1_Card24_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card24_Front.svg';
import Batch1_Card25_Front from '@/assets/memory_cards/Batch_1/Front_SVG/Batch1_Card25_Front.svg';


import Batch1_Card1_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card1_Back_BG.png';
import Batch1_Card1_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card1_Back_CZ.png';
import Batch1_Card1_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card1_Back_DE.png';
import Batch1_Card1_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card1_Back_EN.png';
import Batch1_Card1_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card1_Back_FR.png';
import Batch1_Card1_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card1_Back_HO.png';
import Batch1_Card1_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card1_Back_HR.png';
import Batch1_Card1_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card1_Back_HU.png';
import Batch1_Card1_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card1_Back_IT.png';
import Batch1_Card1_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card1_Back_MX.png';
import Batch1_Card1_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card1_Back_NO.png';
import Batch1_Card1_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card1_Back_RO.png';
import Batch1_Card1_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card1_Back_RS.png';
import Batch1_Card1_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card1_Back_SE.png';
import Batch1_Card1_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card1_Back_SI.png';
import Batch1_Card1_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card1_Back_TR.png';
import Batch1_Card1_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card1_Back_US.png';

import Batch1_Card2_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card2_Back_BG.png';
import Batch1_Card2_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card2_Back_CZ.png';
import Batch1_Card2_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card2_Back_DE.png';
import Batch1_Card2_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card2_Back_EN.png';
import Batch1_Card2_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card2_Back_FR.png';
import Batch1_Card2_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card2_Back_HO.png';
import Batch1_Card2_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card2_Back_HR.png';
import Batch1_Card2_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card2_Back_HU.png';
import Batch1_Card2_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card2_Back_IT.png';
import Batch1_Card2_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card2_Back_MX.png';
import Batch1_Card2_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card2_Back_NO.png';
import Batch1_Card2_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card2_Back_RO.png';
import Batch1_Card2_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card2_Back_RS.png';
import Batch1_Card2_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card2_Back_SE.png';
import Batch1_Card2_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card2_Back_SI.png';
import Batch1_Card2_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card2_Back_TR.png';
import Batch1_Card2_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card2_Back_US.png';

import Batch1_Card3_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card3_Back_BG.png';
import Batch1_Card3_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card3_Back_CZ.png';
import Batch1_Card3_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card3_Back_DE.png';
import Batch1_Card3_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card3_Back_EN.png';
import Batch1_Card3_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card3_Back_FR.png';
import Batch1_Card3_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card3_Back_HO.png';
import Batch1_Card3_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card3_Back_HR.png';
import Batch1_Card3_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card3_Back_HU.png';
import Batch1_Card3_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card3_Back_IT.png';
import Batch1_Card3_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card3_Back_MX.png';
import Batch1_Card3_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card3_Back_NO.png';
import Batch1_Card3_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card3_Back_RO.png';
import Batch1_Card3_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card3_Back_RS.png';
import Batch1_Card3_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card3_Back_SE.png';
import Batch1_Card3_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card3_Back_SI.png';
import Batch1_Card3_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card3_Back_TR.png';
import Batch1_Card3_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card3_Back_US.png';

import Batch1_Card4_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card4_Back_BG.png';
import Batch1_Card4_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card4_Back_CZ.png';
import Batch1_Card4_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card4_Back_DE.png';
import Batch1_Card4_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card4_Back_EN.png';
import Batch1_Card4_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card4_Back_FR.png';
import Batch1_Card4_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card4_Back_HO.png';
import Batch1_Card4_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card4_Back_HR.png';
import Batch1_Card4_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card4_Back_HU.png';
import Batch1_Card4_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card4_Back_IT.png';
import Batch1_Card4_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card4_Back_MX.png';
import Batch1_Card4_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card4_Back_NO.png';
import Batch1_Card4_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card4_Back_RO.png';
import Batch1_Card4_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card4_Back_RS.png';
import Batch1_Card4_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card4_Back_SE.png';
import Batch1_Card4_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card4_Back_SI.png';
import Batch1_Card4_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card4_Back_TR.png';
import Batch1_Card4_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card4_Back_US.png';

import Batch1_Card5_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card5_Back_BG.png';
import Batch1_Card5_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card5_Back_CZ.png';
import Batch1_Card5_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card5_Back_DE.png';
import Batch1_Card5_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card5_Back_EN.png';
import Batch1_Card5_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card5_Back_FR.png';
import Batch1_Card5_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card5_Back_HO.png';
import Batch1_Card5_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card5_Back_HR.png';
import Batch1_Card5_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card5_Back_HU.png';
import Batch1_Card5_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card5_Back_IT.png';
import Batch1_Card5_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card5_Back_MX.png';
import Batch1_Card5_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card5_Back_NO.png';
import Batch1_Card5_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card5_Back_RO.png';
import Batch1_Card5_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card5_Back_RS.png';
import Batch1_Card5_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card5_Back_SE.png';
import Batch1_Card5_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card5_Back_SI.png';
import Batch1_Card5_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card5_Back_TR.png';
import Batch1_Card5_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card5_Back_US.png';

import Batch1_Card6_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card6_Back_BG.png';
import Batch1_Card6_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card6_Back_CZ.png';
import Batch1_Card6_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card6_Back_DE.png';
import Batch1_Card6_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card6_Back_EN.png';
import Batch1_Card6_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card6_Back_FR.png';
import Batch1_Card6_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card6_Back_HO.png';
import Batch1_Card6_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card6_Back_HR.png';
import Batch1_Card6_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card6_Back_HU.png';
import Batch1_Card6_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card6_Back_IT.png';
import Batch1_Card6_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card6_Back_MX.png';
import Batch1_Card6_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card6_Back_NO.png';
import Batch1_Card6_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card6_Back_RO.png';
import Batch1_Card6_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card6_Back_RS.png';
import Batch1_Card6_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card6_Back_SE.png';
import Batch1_Card6_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card6_Back_SI.png';
import Batch1_Card6_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card6_Back_TR.png';
import Batch1_Card6_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card6_Back_US.png';

import Batch1_Card7_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card7_Back_BG.png';
import Batch1_Card7_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card7_Back_CZ.png';
import Batch1_Card7_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card7_Back_DE.png';
import Batch1_Card7_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card7_Back_EN.png';
import Batch1_Card7_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card7_Back_FR.png';
import Batch1_Card7_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card7_Back_HO.png';
import Batch1_Card7_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card7_Back_HR.png';
import Batch1_Card7_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card7_Back_HU.png';
import Batch1_Card7_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card7_Back_IT.png';
import Batch1_Card7_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card7_Back_MX.png';
import Batch1_Card7_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card7_Back_NO.png';
import Batch1_Card7_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card7_Back_RO.png';
import Batch1_Card7_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card7_Back_RS.png';
import Batch1_Card7_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card7_Back_SE.png';
import Batch1_Card7_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card7_Back_SI.png';
import Batch1_Card7_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card7_Back_TR.png';
import Batch1_Card7_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card7_Back_US.png';

import Batch1_Card8_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card8_Back_BG.png';
import Batch1_Card8_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card8_Back_CZ.png';
import Batch1_Card8_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card8_Back_DE.png';
import Batch1_Card8_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card8_Back_EN.png';
import Batch1_Card8_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card8_Back_FR.png';
import Batch1_Card8_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card8_Back_HO.png';
import Batch1_Card8_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card8_Back_HR.png';
import Batch1_Card8_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card8_Back_HU.png';
import Batch1_Card8_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card8_Back_IT.png';
import Batch1_Card8_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card8_Back_MX.png';
import Batch1_Card8_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card8_Back_NO.png';
import Batch1_Card8_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card8_Back_RO.png';
import Batch1_Card8_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card8_Back_RS.png';
import Batch1_Card8_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card8_Back_SE.png';
import Batch1_Card8_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card8_Back_SI.png';
import Batch1_Card8_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card8_Back_TR.png';
import Batch1_Card8_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card8_Back_US.png';

import Batch1_Card9_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card9_Back_BG.png';
import Batch1_Card9_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card9_Back_CZ.png';
import Batch1_Card9_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card9_Back_DE.png';
import Batch1_Card9_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card9_Back_EN.png';
import Batch1_Card9_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card9_Back_FR.png';
import Batch1_Card9_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card9_Back_HO.png';
import Batch1_Card9_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card9_Back_HR.png';
import Batch1_Card9_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card9_Back_HU.png';
import Batch1_Card9_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card9_Back_IT.png';
import Batch1_Card9_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card9_Back_MX.png';
import Batch1_Card9_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card9_Back_NO.png';
import Batch1_Card9_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card9_Back_RO.png';
import Batch1_Card9_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card9_Back_RS.png';
import Batch1_Card9_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card9_Back_SE.png';
import Batch1_Card9_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card9_Back_SI.png';
import Batch1_Card9_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card9_Back_TR.png';
import Batch1_Card9_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card9_Back_US.png';

import Batch1_Card10_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card10_Back_BG.png';
import Batch1_Card10_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card10_Back_CZ.png';
import Batch1_Card10_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card10_Back_DE.png';
import Batch1_Card10_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card10_Back_EN.png';
import Batch1_Card10_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card10_Back_FR.png';
import Batch1_Card10_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card10_Back_HO.png';
import Batch1_Card10_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card10_Back_HR.png';
import Batch1_Card10_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card10_Back_HU.png';
import Batch1_Card10_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card10_Back_IT.png';
import Batch1_Card10_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card10_Back_MX.png';
import Batch1_Card10_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card10_Back_NO.png';
import Batch1_Card10_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card10_Back_RO.png';
import Batch1_Card10_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card10_Back_RS.png';
import Batch1_Card10_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card10_Back_SE.png';
import Batch1_Card10_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card10_Back_SI.png';
import Batch1_Card10_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card10_Back_TR.png';
import Batch1_Card10_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card10_Back_US.png';

import Batch1_Card11_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card11_Back_BG.png';
import Batch1_Card11_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card11_Back_CZ.png';
import Batch1_Card11_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card11_Back_DE.png';
import Batch1_Card11_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card11_Back_EN.png';
import Batch1_Card11_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card11_Back_FR.png';
import Batch1_Card11_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card11_Back_HO.png';
import Batch1_Card11_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card11_Back_HR.png';
import Batch1_Card11_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card11_Back_HU.png';
import Batch1_Card11_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card11_Back_IT.png';
import Batch1_Card11_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card11_Back_MX.png';
import Batch1_Card11_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card11_Back_NO.png';
import Batch1_Card11_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card11_Back_RO.png';
import Batch1_Card11_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card11_Back_RS.png';
import Batch1_Card11_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card11_Back_SE.png';
import Batch1_Card11_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card11_Back_SI.png';
import Batch1_Card11_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card11_Back_TR.png';
import Batch1_Card11_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card11_Back_US.png';

import Batch1_Card12_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card12_Back_BG.png';
import Batch1_Card12_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card12_Back_CZ.png';
import Batch1_Card12_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card12_Back_DE.png';
import Batch1_Card12_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card12_Back_EN.png';
import Batch1_Card12_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card12_Back_FR.png';
import Batch1_Card12_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card12_Back_HO.png';
import Batch1_Card12_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card12_Back_HR.png';
import Batch1_Card12_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card12_Back_HU.png';
import Batch1_Card12_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card12_Back_IT.png';
import Batch1_Card12_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card12_Back_MX.png';
import Batch1_Card12_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card12_Back_NO.png';
import Batch1_Card12_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card12_Back_RO.png';
import Batch1_Card12_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card12_Back_RS.png';
import Batch1_Card12_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card12_Back_SE.png';
import Batch1_Card12_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card12_Back_SI.png';
import Batch1_Card12_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card12_Back_TR.png';
import Batch1_Card12_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card12_Back_US.png';

import Batch1_Card13_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card13_Back_BG.png';
import Batch1_Card13_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card13_Back_CZ.png';
import Batch1_Card13_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card13_Back_DE.png';
import Batch1_Card13_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card13_Back_EN.png';
import Batch1_Card13_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card13_Back_FR.png';
import Batch1_Card13_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card13_Back_HO.png';
import Batch1_Card13_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card13_Back_HR.png';
import Batch1_Card13_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card13_Back_HU.png';
import Batch1_Card13_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card13_Back_IT.png';
import Batch1_Card13_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card13_Back_MX.png';
import Batch1_Card13_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card13_Back_NO.png';
import Batch1_Card13_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card13_Back_RO.png';
import Batch1_Card13_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card13_Back_RS.png';
import Batch1_Card13_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card13_Back_SE.png';
import Batch1_Card13_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card13_Back_SI.png';
import Batch1_Card13_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card13_Back_TR.png';
import Batch1_Card13_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card13_Back_US.png';

import Batch1_Card14_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card14_Back_BG.png';
import Batch1_Card14_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card14_Back_CZ.png';
import Batch1_Card14_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card14_Back_DE.png';
import Batch1_Card14_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card14_Back_EN.png';
import Batch1_Card14_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card14_Back_FR.png';
import Batch1_Card14_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card14_Back_HO.png';
import Batch1_Card14_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card14_Back_HR.png';
import Batch1_Card14_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card14_Back_HU.png';
import Batch1_Card14_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card14_Back_IT.png';
import Batch1_Card14_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card14_Back_MX.png';
import Batch1_Card14_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card14_Back_NO.png';
import Batch1_Card14_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card14_Back_RO.png';
import Batch1_Card14_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card14_Back_RS.png';
import Batch1_Card14_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card14_Back_SE.png';
import Batch1_Card14_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card14_Back_SI.png';
import Batch1_Card14_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card14_Back_TR.png';
import Batch1_Card14_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card14_Back_US.png';

import Batch1_Card15_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card15_Back_BG.png';
import Batch1_Card15_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card15_Back_CZ.png';
import Batch1_Card15_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card15_Back_DE.png';
import Batch1_Card15_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card15_Back_EN.png';
import Batch1_Card15_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card15_Back_FR.png';
import Batch1_Card15_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card15_Back_HO.png';
import Batch1_Card15_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card15_Back_HR.png';
import Batch1_Card15_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card15_Back_HU.png';
import Batch1_Card15_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card15_Back_IT.png';
import Batch1_Card15_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card15_Back_MX.png';
import Batch1_Card15_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card15_Back_NO.png';
import Batch1_Card15_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card15_Back_RO.png';
import Batch1_Card15_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card15_Back_RS.png';
import Batch1_Card15_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card15_Back_SE.png';
import Batch1_Card15_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card15_Back_SI.png';
import Batch1_Card15_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card15_Back_TR.png';
import Batch1_Card15_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card15_Back_US.png';

import Batch1_Card16_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card16_Back_BG.png';
import Batch1_Card16_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card16_Back_CZ.png';
import Batch1_Card16_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card16_Back_DE.png';
import Batch1_Card16_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card16_Back_EN.png';
import Batch1_Card16_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card16_Back_FR.png';
import Batch1_Card16_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card16_Back_HO.png';
import Batch1_Card16_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card16_Back_HR.png';
import Batch1_Card16_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card16_Back_HU.png';
import Batch1_Card16_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card16_Back_IT.png';
import Batch1_Card16_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card16_Back_MX.png';
import Batch1_Card16_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card16_Back_NO.png';
import Batch1_Card16_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card16_Back_RO.png';
import Batch1_Card16_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card16_Back_RS.png';
import Batch1_Card16_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card16_Back_SE.png';
import Batch1_Card16_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card16_Back_SI.png';
import Batch1_Card16_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card16_Back_TR.png';
import Batch1_Card16_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card16_Back_US.png';

import Batch1_Card17_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card17_Back_BG.png';
import Batch1_Card17_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card17_Back_CZ.png';
import Batch1_Card17_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card17_Back_DE.png';
import Batch1_Card17_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card17_Back_EN.png';
import Batch1_Card17_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card17_Back_FR.png';
import Batch1_Card17_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card17_Back_HO.png';
import Batch1_Card17_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card17_Back_HR.png';
import Batch1_Card17_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card17_Back_HU.png';
import Batch1_Card17_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card17_Back_IT.png';
import Batch1_Card17_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card17_Back_MX.png';
import Batch1_Card17_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card17_Back_NO.png';
import Batch1_Card17_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card17_Back_RO.png';
import Batch1_Card17_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card17_Back_RS.png';
import Batch1_Card17_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card17_Back_SE.png';
import Batch1_Card17_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card17_Back_SI.png';
import Batch1_Card17_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card17_Back_TR.png';
import Batch1_Card17_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card17_Back_US.png';

import Batch1_Card18_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card18_Back_BG.png';
import Batch1_Card18_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card18_Back_CZ.png';
import Batch1_Card18_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card18_Back_DE.png';
import Batch1_Card18_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card18_Back_EN.png';
import Batch1_Card18_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card18_Back_FR.png';
import Batch1_Card18_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card18_Back_HO.png';
import Batch1_Card18_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card18_Back_HR.png';
import Batch1_Card18_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card18_Back_HU.png';
import Batch1_Card18_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card18_Back_IT.png';
import Batch1_Card18_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card18_Back_MX.png';
import Batch1_Card18_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card18_Back_NO.png';
import Batch1_Card18_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card18_Back_RO.png';
import Batch1_Card18_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card18_Back_RS.png';
import Batch1_Card18_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card18_Back_SE.png';
import Batch1_Card18_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card18_Back_SI.png';
import Batch1_Card18_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card18_Back_TR.png';
import Batch1_Card18_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card18_Back_US.png';

import Batch1_Card19_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card19_Back_BG.png';
import Batch1_Card19_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card19_Back_CZ.png';
import Batch1_Card19_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card19_Back_DE.png';
import Batch1_Card19_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card19_Back_EN.png';
import Batch1_Card19_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card19_Back_FR.png';
import Batch1_Card19_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card19_Back_HO.png';
import Batch1_Card19_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card19_Back_HR.png';
import Batch1_Card19_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card19_Back_HU.png';
import Batch1_Card19_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card19_Back_IT.png';
import Batch1_Card19_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card19_Back_MX.png';
import Batch1_Card19_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card19_Back_NO.png';
import Batch1_Card19_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card19_Back_RO.png';
import Batch1_Card19_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card19_Back_RS.png';
import Batch1_Card19_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card19_Back_SE.png';
import Batch1_Card19_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card19_Back_SI.png';
import Batch1_Card19_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card19_Back_TR.png';
import Batch1_Card19_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card19_Back_US.png';

import Batch1_Card20_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card20_Back_BG.png';
import Batch1_Card20_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card20_Back_CZ.png';
import Batch1_Card20_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card20_Back_DE.png';
import Batch1_Card20_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card20_Back_EN.png';
import Batch1_Card20_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card20_Back_FR.png';
import Batch1_Card20_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card20_Back_HO.png';
import Batch1_Card20_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card20_Back_HR.png';
import Batch1_Card20_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card20_Back_HU.png';
import Batch1_Card20_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card20_Back_IT.png';
import Batch1_Card20_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card20_Back_MX.png';
import Batch1_Card20_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card20_Back_NO.png';
import Batch1_Card20_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card20_Back_RO.png';
import Batch1_Card20_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card20_Back_RS.png';
import Batch1_Card20_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card20_Back_SE.png';
import Batch1_Card20_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card20_Back_SI.png';
import Batch1_Card20_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card20_Back_TR.png';
import Batch1_Card20_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card20_Back_US.png';

import Batch1_Card21_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card21_Back_BG.png';
import Batch1_Card21_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card21_Back_CZ.png';
import Batch1_Card21_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card21_Back_DE.png';
import Batch1_Card21_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card21_Back_EN.png';
import Batch1_Card21_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card21_Back_FR.png';
import Batch1_Card21_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card21_Back_HO.png';
import Batch1_Card21_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card21_Back_HR.png';
import Batch1_Card21_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card21_Back_HU.png';
import Batch1_Card21_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card21_Back_IT.png';
import Batch1_Card21_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card21_Back_MX.png';
import Batch1_Card21_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card21_Back_NO.png';
import Batch1_Card21_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card21_Back_RO.png';
import Batch1_Card21_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card21_Back_RS.png';
import Batch1_Card21_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card21_Back_SE.png';
import Batch1_Card21_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card21_Back_SI.png';
import Batch1_Card21_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card21_Back_TR.png';
import Batch1_Card21_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card21_Back_US.png';

import Batch1_Card22_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card22_Back_BG.png';
import Batch1_Card22_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card22_Back_CZ.png';
import Batch1_Card22_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card22_Back_DE.png';
import Batch1_Card22_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card22_Back_EN.png';
import Batch1_Card22_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card22_Back_FR.png';
import Batch1_Card22_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card22_Back_HO.png';
import Batch1_Card22_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card22_Back_HR.png';
import Batch1_Card22_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card22_Back_HU.png';
import Batch1_Card22_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card22_Back_IT.png';
import Batch1_Card22_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card22_Back_MX.png';
import Batch1_Card22_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card22_Back_NO.png';
import Batch1_Card22_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card22_Back_RO.png';
import Batch1_Card22_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card22_Back_RS.png';
import Batch1_Card22_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card22_Back_SE.png';
import Batch1_Card22_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card22_Back_SI.png';
import Batch1_Card22_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card22_Back_TR.png';
import Batch1_Card22_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card22_Back_US.png';

import Batch1_Card23_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card23_Back_BG.png';
import Batch1_Card23_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card23_Back_CZ.png';
import Batch1_Card23_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card23_Back_DE.png';
import Batch1_Card23_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card23_Back_EN.png';
import Batch1_Card23_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card23_Back_FR.png';
import Batch1_Card23_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card23_Back_HO.png';
import Batch1_Card23_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card23_Back_HR.png';
import Batch1_Card23_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card23_Back_HU.png';
import Batch1_Card23_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card23_Back_IT.png';
import Batch1_Card23_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card23_Back_MX.png';
import Batch1_Card23_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card23_Back_NO.png';
import Batch1_Card23_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card23_Back_RO.png';
import Batch1_Card23_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card23_Back_RS.png';
import Batch1_Card23_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card23_Back_SE.png';
import Batch1_Card23_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card23_Back_SI.png';
import Batch1_Card23_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card23_Back_TR.png';
import Batch1_Card23_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card23_Back_US.png';

import Batch1_Card24_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card24_Back_BG.png';
import Batch1_Card24_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card24_Back_CZ.png';
import Batch1_Card24_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card24_Back_DE.png';
import Batch1_Card24_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card24_Back_EN.png';
import Batch1_Card24_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card24_Back_FR.png';
import Batch1_Card24_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card24_Back_HO.png';
import Batch1_Card24_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card24_Back_HR.png';
import Batch1_Card24_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card24_Back_HU.png';
import Batch1_Card24_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card24_Back_IT.png';
import Batch1_Card24_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card24_Back_MX.png';
import Batch1_Card24_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card24_Back_NO.png';
import Batch1_Card24_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card24_Back_RO.png';
import Batch1_Card24_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card24_Back_RS.png';
import Batch1_Card24_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card24_Back_SE.png';
import Batch1_Card24_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card24_Back_SI.png';
import Batch1_Card24_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card24_Back_TR.png';
import Batch1_Card24_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card24_Back_US.png';

import Batch1_Card25_Back_BG from '@/assets/memory_cards/Batch_1/Back_Images/BG/Batch1_Card25_Back_BG.png';
import Batch1_Card25_Back_CZ from '@/assets/memory_cards/Batch_1/Back_Images/CZ/Batch1_Card25_Back_CZ.png';
import Batch1_Card25_Back_DE from '@/assets/memory_cards/Batch_1/Back_Images/DE/Batch1_Card25_Back_DE.png';
import Batch1_Card25_Back_EN from '@/assets/memory_cards/Batch_1/Back_Images/EN/Batch1_Card25_Back_EN.png';
import Batch1_Card25_Back_FR from '@/assets/memory_cards/Batch_1/Back_Images/FR/Batch1_Card25_Back_FR.png';
import Batch1_Card25_Back_HO from '@/assets/memory_cards/Batch_1/Back_Images/HO/Batch1_Card25_Back_HO.png';
import Batch1_Card25_Back_HR from '@/assets/memory_cards/Batch_1/Back_Images/HR/Batch1_Card25_Back_HR.png';
import Batch1_Card25_Back_HU from '@/assets/memory_cards/Batch_1/Back_Images/HU/Batch1_Card25_Back_HU.png';
import Batch1_Card25_Back_IT from '@/assets/memory_cards/Batch_1/Back_Images/IT/Batch1_Card25_Back_IT.png';
import Batch1_Card25_Back_MX from '@/assets/memory_cards/Batch_1/Back_Images/MX/Batch1_Card25_Back_MX.png';
import Batch1_Card25_Back_NO from '@/assets/memory_cards/Batch_1/Back_Images/NO/Batch1_Card25_Back_NO.png';
import Batch1_Card25_Back_RO from '@/assets/memory_cards/Batch_1/Back_Images/RO/Batch1_Card25_Back_RO.png';
import Batch1_Card25_Back_RS from '@/assets/memory_cards/Batch_1/Back_Images/RS/Batch1_Card25_Back_RS.png';
import Batch1_Card25_Back_SE from '@/assets/memory_cards/Batch_1/Back_Images/SE/Batch1_Card25_Back_SE.png';
import Batch1_Card25_Back_SI from '@/assets/memory_cards/Batch_1/Back_Images/SI/Batch1_Card25_Back_SI.png';
import Batch1_Card25_Back_TR from '@/assets/memory_cards/Batch_1/Back_Images/TR/Batch1_Card25_Back_TR.png';
import Batch1_Card25_Back_US from '@/assets/memory_cards/Batch_1/Back_Images/US/Batch1_Card25_Back_US.png';

// Batch 2
import Batch2_Card1_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card1_Front.svg';
import Batch2_Card2_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card2_Front.svg';
import Batch2_Card3_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card3_Front.svg';
import Batch2_Card4_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card4_Front.svg';
import Batch2_Card5_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card5_Front.svg';
import Batch2_Card6_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card6_Front.svg';
import Batch2_Card7_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card8_Front.svg';
import Batch2_Card8_Front from '@/assets/memory_cards/Batch_2/Front_SVG/Batch2_Card7_Front.svg';

import Batch2_Card1_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card1_Back_BG.png';
import Batch2_Card1_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card1_Back_CZ.png';
import Batch2_Card1_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card1_Back_DE.png';
import Batch2_Card1_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card1_Back_EN.png';
import Batch2_Card1_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card1_Back_HO.png';
import Batch2_Card1_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card1_Back_HR.png';
import Batch2_Card1_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card1_Back_HU.png';
import Batch2_Card1_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card1_Back_IT.png';
import Batch2_Card1_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card1_Back_MX.png';
import Batch2_Card1_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card1_Back_NO.png';
import Batch2_Card1_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card1_Back_PL.png';
import Batch2_Card1_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card1_Back_RO.png';
import Batch2_Card1_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card1_Back_RS.png';
import Batch2_Card1_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card1_Back_SE.png';
import Batch2_Card1_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card1_Back_SI.png';
import Batch2_Card1_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card1_Back_TR.png';
import Batch2_Card1_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card1_Back_US.png';

import Batch2_Card2_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card2_Back_BG.png';
import Batch2_Card2_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card2_Back_CZ.png';
import Batch2_Card2_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card2_Back_DE.png';
import Batch2_Card2_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card2_Back_EN.png';
import Batch2_Card2_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card2_Back_HO.png';
import Batch2_Card2_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card2_Back_HR.png';
import Batch2_Card2_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card2_Back_HU.png';
import Batch2_Card2_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card2_Back_IT.png';
import Batch2_Card2_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card2_Back_MX.png';
import Batch2_Card2_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card2_Back_NO.png';
import Batch2_Card2_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card2_Back_PL.png';
import Batch2_Card2_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card2_Back_RO.png';
import Batch2_Card2_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card2_Back_RS.png';
import Batch2_Card2_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card2_Back_SE.png';
import Batch2_Card2_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card2_Back_SI.png';
import Batch2_Card2_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card2_Back_TR.png';
import Batch2_Card2_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card2_Back_US.png';

import Batch2_Card3_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card3_Back_BG.png';
import Batch2_Card3_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card3_Back_CZ.png';
import Batch2_Card3_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card3_Back_DE.png';
import Batch2_Card3_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card3_Back_EN.png';
import Batch2_Card3_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card3_Back_HO.png';
import Batch2_Card3_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card3_Back_HR.png';
import Batch2_Card3_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card3_Back_HU.png';
import Batch2_Card3_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card3_Back_IT.png';
import Batch2_Card3_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card3_Back_MX.png';
import Batch2_Card3_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card3_Back_NO.png';
import Batch2_Card3_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card3_Back_PL.png';
import Batch2_Card3_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card3_Back_RO.png';
import Batch2_Card3_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card3_Back_RS.png';
import Batch2_Card3_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card3_Back_SE.png';
import Batch2_Card3_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card3_Back_SI.png';
import Batch2_Card3_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card3_Back_TR.png';
import Batch2_Card3_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card3_Back_US.png';

import Batch2_Card4_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card4_Back_BG.png';
import Batch2_Card4_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card4_Back_CZ.png';
import Batch2_Card4_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card4_Back_DE.png';
import Batch2_Card4_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card4_Back_EN.png';
import Batch2_Card4_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card4_Back_HO.png';
import Batch2_Card4_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card4_Back_HR.png';
import Batch2_Card4_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card4_Back_HU.png';
import Batch2_Card4_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card4_Back_IT.png';
import Batch2_Card4_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card4_Back_MX.png';
import Batch2_Card4_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card4_Back_NO.png';
import Batch2_Card4_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card4_Back_PL.png';
import Batch2_Card4_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card4_Back_RO.png';
import Batch2_Card4_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card4_Back_RS.png';
import Batch2_Card4_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card4_Back_SE.png';
import Batch2_Card4_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card4_Back_SI.png';
import Batch2_Card4_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card4_Back_TR.png';
import Batch2_Card4_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card4_Back_US.png';

import Batch2_Card5_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card5_Back_BG.png';
import Batch2_Card5_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card5_Back_CZ.png';
import Batch2_Card5_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card5_Back_DE.png';
import Batch2_Card5_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card5_Back_EN.png';
import Batch2_Card5_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card5_Back_HO.png';
import Batch2_Card5_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card5_Back_HR.png';
import Batch2_Card5_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card5_Back_HU.png';
import Batch2_Card5_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card5_Back_IT.png';
import Batch2_Card5_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card5_Back_MX.png';
import Batch2_Card5_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card5_Back_NO.png';
import Batch2_Card5_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card5_Back_PL.png';
import Batch2_Card5_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card5_Back_RO.png';
import Batch2_Card5_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card5_Back_RS.png';
import Batch2_Card5_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card5_Back_SE.png';
import Batch2_Card5_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card5_Back_SI.png';
import Batch2_Card5_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card5_Back_TR.png';
import Batch2_Card5_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card5_Back_US.png';

import Batch2_Card6_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card6_Back_BG.png';
import Batch2_Card6_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card6_Back_CZ.png';
import Batch2_Card6_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card6_Back_DE.png';
import Batch2_Card6_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card6_Back_EN.png';
import Batch2_Card6_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card6_Back_HO.png';
import Batch2_Card6_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card6_Back_HR.png';
import Batch2_Card6_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card6_Back_HU.png';
import Batch2_Card6_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card6_Back_IT.png';
import Batch2_Card6_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card6_Back_MX.png';
import Batch2_Card6_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card6_Back_NO.png';
import Batch2_Card6_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card6_Back_PL.png';
import Batch2_Card6_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card6_Back_RO.png';
import Batch2_Card6_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card6_Back_RS.png';
import Batch2_Card6_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card6_Back_SE.png';
import Batch2_Card6_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card6_Back_SI.png';
import Batch2_Card6_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card6_Back_TR.png';
import Batch2_Card6_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card6_Back_US.png';

import Batch2_Card7_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card7_Back_BG.png';
import Batch2_Card7_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card7_Back_CZ.png';
import Batch2_Card7_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card7_Back_DE.png';
import Batch2_Card7_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card7_Back_EN.png';
import Batch2_Card7_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card7_Back_HO.png';
import Batch2_Card7_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card7_Back_HR.png';
import Batch2_Card7_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card7_Back_HU.png';
import Batch2_Card7_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card7_Back_IT.png';
import Batch2_Card7_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card7_Back_MX.png';
import Batch2_Card7_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card7_Back_NO.png';
import Batch2_Card7_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card7_Back_PL.png';
import Batch2_Card7_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card7_Back_RO.png';
import Batch2_Card7_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card7_Back_RS.png';
import Batch2_Card7_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card7_Back_SE.png';
import Batch2_Card7_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card7_Back_SI.png';
import Batch2_Card7_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card7_Back_TR.png';
import Batch2_Card7_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card7_Back_US.png';

import Batch2_Card8_Back_BG from '@/assets/memory_cards/Batch_2/Back_Images/BG/Batch2_Card8_Back_BG.png';
import Batch2_Card8_Back_CZ from '@/assets/memory_cards/Batch_2/Back_Images/CZ/Batch2_Card8_Back_CZ.png';
import Batch2_Card8_Back_DE from '@/assets/memory_cards/Batch_2/Back_Images/DE/Batch2_Card8_Back_DE.png';
import Batch2_Card8_Back_EN from '@/assets/memory_cards/Batch_2/Back_Images/EN/Batch2_Card8_Back_EN.png';
import Batch2_Card8_Back_HO from '@/assets/memory_cards/Batch_2/Back_Images/HO/Batch2_Card8_Back_HO.png';
import Batch2_Card8_Back_HR from '@/assets/memory_cards/Batch_2/Back_Images/HR/Batch2_Card8_Back_HR.png';
import Batch2_Card8_Back_HU from '@/assets/memory_cards/Batch_2/Back_Images/HU/Batch2_Card8_Back_HU.png';
import Batch2_Card8_Back_IT from '@/assets/memory_cards/Batch_2/Back_Images/IT/Batch2_Card8_Back_IT.png';
import Batch2_Card8_Back_MX from '@/assets/memory_cards/Batch_2/Back_Images/MX/Batch2_Card8_Back_MX.png';
import Batch2_Card8_Back_NO from '@/assets/memory_cards/Batch_2/Back_Images/NO/Batch2_Card8_Back_NO.png';
import Batch2_Card8_Back_PL from '@/assets/memory_cards/Batch_2/Back_Images/PL/Batch2_Card8_Back_PL.png';
import Batch2_Card8_Back_RO from '@/assets/memory_cards/Batch_2/Back_Images/RO/Batch2_Card8_Back_RO.png';
import Batch2_Card8_Back_RS from '@/assets/memory_cards/Batch_2/Back_Images/RS/Batch2_Card8_Back_RS.png';
import Batch2_Card8_Back_SE from '@/assets/memory_cards/Batch_2/Back_Images/SE/Batch2_Card8_Back_SE.png';
import Batch2_Card8_Back_SI from '@/assets/memory_cards/Batch_2/Back_Images/SI/Batch2_Card8_Back_SI.png';
import Batch2_Card8_Back_TR from '@/assets/memory_cards/Batch_2/Back_Images/TR/Batch2_Card8_Back_TR.png';
import Batch2_Card8_Back_US from '@/assets/memory_cards/Batch_2/Back_Images/US/Batch2_Card8_Back_US.png';

// Batch 3
import Batch3_Card1_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card1_Front.svg';
import Batch3_Card2_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card2_Front.svg';
import Batch3_Card3_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card3_Front.svg';
import Batch3_Card4_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card4_Front.svg';
import Batch3_Card5_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card5_Front.svg';
import Batch3_Card6_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card6_Front.svg';
import Batch3_Card7_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card7_Front.svg';
import Batch3_Card8_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card8_Front.svg';
import Batch3_Card9_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card9_Front.svg';
import Batch3_Card10_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card10_Front.svg';
import Batch3_Card11_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card11_Front.svg';
import Batch3_Card12_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card12_Front.svg';
import Batch3_Card13_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card13_Front.svg';
import Batch3_Card14_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card14_Front.svg';
import Batch3_Card15_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card15_Front.svg';
import Batch3_Card16_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card16_Front.svg';
import Batch3_Card17_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card17_Front.svg';
import Batch3_Card18_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card18_Front.svg';
import Batch3_Card19_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card19_Front.svg';
import Batch3_Card20_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card20_Front.svg';
import Batch3_Card21_Front from '@/assets/memory_cards/Batch_3/Front_SVG/Batch3_Card21_Front.svg';


import Batch3_Card1_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card1_Back_BG.png';
import Batch3_Card1_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card1_Back_CZ.png';
import Batch3_Card1_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card1_Back_DE.png';
import Batch3_Card1_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card1_Back_EN.png';
import Batch3_Card1_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card1_Back_HO.png';
import Batch3_Card1_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card1_Back_HR.png';
import Batch3_Card1_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card1_Back_HU.png';
import Batch3_Card1_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card1_Back_IT.png';
import Batch3_Card1_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card1_Back_MX.png';
import Batch3_Card1_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card1_Back_NO.png';
import Batch3_Card1_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card1_Back_PL.png';
import Batch3_Card1_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card1_Back_RO.png';
import Batch3_Card1_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card1_Back_RS.png';
import Batch3_Card1_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card1_Back_SE.png';
import Batch3_Card1_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card1_Back_SI.png';
import Batch3_Card1_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card1_Back_US.png';

import Batch3_Card2_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card2_Back_BG.png';
import Batch3_Card2_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card2_Back_CZ.png';
import Batch3_Card2_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card2_Back_DE.png';
import Batch3_Card2_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card2_Back_EN.png';
import Batch3_Card2_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card2_Back_HO.png';
import Batch3_Card2_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card2_Back_HR.png';
import Batch3_Card2_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card2_Back_HU.png';
import Batch3_Card2_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card2_Back_IT.png';
import Batch3_Card2_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card2_Back_MX.png';
import Batch3_Card2_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card2_Back_NO.png';
import Batch3_Card2_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card2_Back_PL.png';
import Batch3_Card2_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card2_Back_RO.png';
import Batch3_Card2_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card2_Back_RS.png';
import Batch3_Card2_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card2_Back_SE.png';
import Batch3_Card2_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card2_Back_SI.png';
import Batch3_Card2_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card2_Back_US.png';

import Batch3_Card3_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card3_Back_BG.png';
import Batch3_Card3_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card3_Back_CZ.png';
import Batch3_Card3_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card3_Back_DE.png';
import Batch3_Card3_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card3_Back_EN.png';
import Batch3_Card3_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card3_Back_HO.png';
import Batch3_Card3_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card3_Back_HR.png';
import Batch3_Card3_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card3_Back_HU.png';
import Batch3_Card3_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card3_Back_IT.png';
import Batch3_Card3_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card3_Back_MX.png';
import Batch3_Card3_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card3_Back_NO.png';
import Batch3_Card3_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card3_Back_PL.png';
import Batch3_Card3_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card3_Back_RO.png';
import Batch3_Card3_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card3_Back_RS.png';
import Batch3_Card3_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card3_Back_SE.png';
import Batch3_Card3_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card3_Back_SI.png';
import Batch3_Card3_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card3_Back_US.png';

import Batch3_Card4_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card4_Back_BG.png';
import Batch3_Card4_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card4_Back_CZ.png';
import Batch3_Card4_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card4_Back_DE.png';
import Batch3_Card4_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card4_Back_EN.png';
import Batch3_Card4_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card4_Back_HO.png';
import Batch3_Card4_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card4_Back_HR.png';
import Batch3_Card4_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card4_Back_HU.png';
import Batch3_Card4_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card4_Back_IT.png';
import Batch3_Card4_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card4_Back_MX.png';
import Batch3_Card4_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card4_Back_NO.png';
import Batch3_Card4_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card4_Back_PL.png';
import Batch3_Card4_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card4_Back_RO.png';
import Batch3_Card4_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card4_Back_RS.png';
import Batch3_Card4_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card4_Back_SE.png';
import Batch3_Card4_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card4_Back_SI.png';
import Batch3_Card4_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card4_Back_US.png';

import Batch3_Card5_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card5_Back_BG.png';
import Batch3_Card5_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card5_Back_CZ.png';
import Batch3_Card5_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card5_Back_DE.png';
import Batch3_Card5_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card5_Back_EN.png';
import Batch3_Card5_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card5_Back_HO.png';
import Batch3_Card5_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card5_Back_HR.png';
import Batch3_Card5_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card5_Back_HU.png';
import Batch3_Card5_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card5_Back_IT.png';
import Batch3_Card5_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card5_Back_MX.png';
import Batch3_Card5_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card5_Back_NO.png';
import Batch3_Card5_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card5_Back_PL.png';
import Batch3_Card5_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card5_Back_RO.png';
import Batch3_Card5_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card5_Back_RS.png';
import Batch3_Card5_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card5_Back_SE.png';
import Batch3_Card5_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card5_Back_SI.png';
import Batch3_Card5_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card5_Back_US.png';

import Batch3_Card6_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card6_Back_BG.png';
import Batch3_Card6_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card6_Back_CZ.png';
import Batch3_Card6_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card6_Back_DE.png';
import Batch3_Card6_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card6_Back_EN.png';
import Batch3_Card6_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card6_Back_HO.png';
import Batch3_Card6_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card6_Back_HR.png';
import Batch3_Card6_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card6_Back_HU.png';
import Batch3_Card6_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card6_Back_IT.png';
import Batch3_Card6_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card6_Back_MX.png';
import Batch3_Card6_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card6_Back_NO.png';
import Batch3_Card6_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card6_Back_PL.png';
import Batch3_Card6_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card6_Back_RO.png';
import Batch3_Card6_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card6_Back_RS.png';
import Batch3_Card6_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card6_Back_SE.png';
import Batch3_Card6_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card6_Back_SI.png';
import Batch3_Card6_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card6_Back_US.png';

import Batch3_Card7_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card7_Back_BG.png';
import Batch3_Card7_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card7_Back_CZ.png';
import Batch3_Card7_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card7_Back_DE.png';
import Batch3_Card7_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card7_Back_EN.png';
import Batch3_Card7_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card7_Back_HO.png';
import Batch3_Card7_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card7_Back_HR.png';
import Batch3_Card7_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card7_Back_HU.png';
import Batch3_Card7_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card7_Back_IT.png';
import Batch3_Card7_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card7_Back_MX.png';
import Batch3_Card7_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card7_Back_NO.png';
import Batch3_Card7_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card7_Back_PL.png';
import Batch3_Card7_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card7_Back_RO.png';
import Batch3_Card7_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card7_Back_RS.png';
import Batch3_Card7_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card7_Back_SE.png';
import Batch3_Card7_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card7_Back_SI.png';
import Batch3_Card7_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card7_Back_US.png';

import Batch3_Card8_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card8_Back_BG.png';
import Batch3_Card8_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card8_Back_CZ.png';
import Batch3_Card8_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card8_Back_DE.png';
import Batch3_Card8_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card8_Back_EN.png';
import Batch3_Card8_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card8_Back_HO.png';
import Batch3_Card8_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card8_Back_HR.png';
import Batch3_Card8_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card8_Back_HU.png';
import Batch3_Card8_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card8_Back_IT.png';
import Batch3_Card8_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card8_Back_MX.png';
import Batch3_Card8_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card8_Back_NO.png';
import Batch3_Card8_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card8_Back_PL.png';
import Batch3_Card8_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card8_Back_RO.png';
import Batch3_Card8_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card8_Back_RS.png';
import Batch3_Card8_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card8_Back_SE.png';
import Batch3_Card8_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card8_Back_SI.png';
import Batch3_Card8_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card8_Back_US.png';

import Batch3_Card9_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card9_Back_BG.png';
import Batch3_Card9_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card9_Back_CZ.png';
import Batch3_Card9_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card9_Back_DE.png';
import Batch3_Card9_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card9_Back_EN.png';
import Batch3_Card9_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card9_Back_HO.png';
import Batch3_Card9_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card9_Back_HR.png';
import Batch3_Card9_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card9_Back_HU.png';
import Batch3_Card9_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card9_Back_IT.png';
import Batch3_Card9_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card9_Back_MX.png';
import Batch3_Card9_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card9_Back_NO.png';
import Batch3_Card9_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card9_Back_PL.png';
import Batch3_Card9_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card9_Back_RO.png';
import Batch3_Card9_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card9_Back_RS.png';
import Batch3_Card9_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card9_Back_SE.png';
import Batch3_Card9_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card9_Back_SI.png';
import Batch3_Card9_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card9_Back_US.png';

import Batch3_Card10_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card10_Back_BG.png';
import Batch3_Card10_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card10_Back_CZ.png';
import Batch3_Card10_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card10_Back_DE.png';
import Batch3_Card10_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card10_Back_EN.png';
import Batch3_Card10_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card10_Back_HO.png';
import Batch3_Card10_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card10_Back_HR.png';
import Batch3_Card10_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card10_Back_HU.png';
import Batch3_Card10_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card10_Back_IT.png';
import Batch3_Card10_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card10_Back_MX.png';
import Batch3_Card10_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card10_Back_NO.png';
import Batch3_Card10_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card10_Back_PL.png';
import Batch3_Card10_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card10_Back_RO.png';
import Batch3_Card10_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card10_Back_RS.png';
import Batch3_Card10_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card10_Back_SE.png';
import Batch3_Card10_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card10_Back_SI.png';
import Batch3_Card10_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card10_Back_US.png';

import Batch3_Card11_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card11_Back_BG.png';
import Batch3_Card11_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card11_Back_CZ.png';
import Batch3_Card11_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card11_Back_DE.png';
import Batch3_Card11_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card11_Back_EN.png';
import Batch3_Card11_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card11_Back_HO.png';
import Batch3_Card11_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card11_Back_HR.png';
import Batch3_Card11_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card11_Back_HU.png';
import Batch3_Card11_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card11_Back_IT.png';
import Batch3_Card11_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card11_Back_MX.png';
import Batch3_Card11_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card11_Back_NO.png';
import Batch3_Card11_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card11_Back_PL.png';
import Batch3_Card11_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card11_Back_RO.png';
import Batch3_Card11_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card11_Back_RS.png';
import Batch3_Card11_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card11_Back_SE.png';
import Batch3_Card11_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card11_Back_SI.png';
import Batch3_Card11_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card11_Back_US.png';

import Batch3_Card12_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card12_Back_BG.png';
import Batch3_Card12_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card12_Back_CZ.png';
import Batch3_Card12_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card12_Back_DE.png';
import Batch3_Card12_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card12_Back_EN.png';
import Batch3_Card12_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card12_Back_HO.png';
import Batch3_Card12_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card12_Back_HR.png';
import Batch3_Card12_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card12_Back_HU.png';
import Batch3_Card12_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card12_Back_IT.png';
import Batch3_Card12_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card12_Back_MX.png';
import Batch3_Card12_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card12_Back_NO.png';
import Batch3_Card12_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card12_Back_PL.png';
import Batch3_Card12_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card12_Back_RO.png';
import Batch3_Card12_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card12_Back_RS.png';
import Batch3_Card12_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card12_Back_SE.png';
import Batch3_Card12_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card12_Back_SI.png';
import Batch3_Card12_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card12_Back_US.png';

import Batch3_Card13_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card13_Back_BG.png';
import Batch3_Card13_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card13_Back_CZ.png';
// import Batch3_Card13_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card14_Back_DE.png';
import Batch3_Card13_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card13_Back_EN.png';
import Batch3_Card13_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card13_Back_HO.png';
import Batch3_Card13_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card13_Back_HR.png';
import Batch3_Card13_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card13_Back_HU.png';
import Batch3_Card13_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card13_Back_IT.png';
import Batch3_Card13_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card13_Back_MX.png';
import Batch3_Card13_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card13_Back_NO.png';
import Batch3_Card13_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card13_Back_PL.png';
import Batch3_Card13_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card13_Back_RO.png';
import Batch3_Card13_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card13_Back_RS.png';
import Batch3_Card13_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card13_Back_SE.png';
import Batch3_Card13_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card13_Back_SI.png';
import Batch3_Card13_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card13_Back_US.png';

import Batch3_Card14_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card14_Back_BG.png';
import Batch3_Card14_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card14_Back_CZ.png';
import Batch3_Card14_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card14_Back_DE.png';
import Batch3_Card14_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card14_Back_EN.png';
import Batch3_Card14_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card14_Back_HO.png';
import Batch3_Card14_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card14_Back_HR.png';
import Batch3_Card14_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card14_Back_HU.png';
import Batch3_Card14_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card14_Back_IT.png';
import Batch3_Card14_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card14_Back_MX.png';
import Batch3_Card14_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card14_Back_NO.png';
import Batch3_Card14_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card14_Back_PL.png';
import Batch3_Card14_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card14_Back_RO.png';
import Batch3_Card14_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card14_Back_RS.png';
import Batch3_Card14_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card14_Back_SE.png';
import Batch3_Card14_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card14_Back_SI.png';
import Batch3_Card14_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card14_Back_US.png';

import Batch3_Card15_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card15_Back_BG.png';
import Batch3_Card15_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card15_Back_CZ.png';
import Batch3_Card15_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card15_Back_DE.png';
import Batch3_Card15_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card15_Back_EN.png';
import Batch3_Card15_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card15_Back_HO.png';
import Batch3_Card15_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card15_Back_HR.png';
import Batch3_Card15_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card15_Back_HU.png';
import Batch3_Card15_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card15_Back_IT.png';
import Batch3_Card15_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card15_Back_MX.png';
import Batch3_Card15_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card15_Back_NO.png';
import Batch3_Card15_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card15_Back_PL.png';
import Batch3_Card15_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card15_Back_RO.png';
import Batch3_Card15_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card15_Back_RS.png';
import Batch3_Card15_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card15_Back_SE.png';
import Batch3_Card15_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card15_Back_SI.png';
import Batch3_Card15_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card15_Back_US.png';

import Batch3_Card16_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card16_Back_BG.png';
import Batch3_Card16_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card16_Back_CZ.png';
import Batch3_Card16_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card16_Back_DE.png';
import Batch3_Card16_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card16_Back_EN.png';
import Batch3_Card16_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card16_Back_HO.png';
import Batch3_Card16_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card16_Back_HR.png';
import Batch3_Card16_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card16_Back_HU.png';
import Batch3_Card16_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card16_Back_IT.png';
import Batch3_Card16_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card16_Back_MX.png';
import Batch3_Card16_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card16_Back_NO.png';
import Batch3_Card16_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card16_Back_PL.png';
import Batch3_Card16_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card16_Back_RO.png';
import Batch3_Card16_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card16_Back_RS.png';
import Batch3_Card16_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card16_Back_SE.png';
import Batch3_Card16_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card16_Back_SI.png';
import Batch3_Card16_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card16_Back_US.png';

import Batch3_Card17_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card17_Back_BG.png';
import Batch3_Card17_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card17_Back_CZ.png';
import Batch3_Card17_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card17_Back_DE.png';
import Batch3_Card17_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card17_Back_EN.png';
import Batch3_Card17_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card17_Back_HO.png';
import Batch3_Card17_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card17_Back_HR.png';
import Batch3_Card17_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card17_Back_HU.png';
import Batch3_Card17_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card17_Back_IT.png';
import Batch3_Card17_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card17_Back_MX.png';
import Batch3_Card17_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card17_Back_NO.png';
import Batch3_Card17_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card17_Back_PL.png';
import Batch3_Card17_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card17_Back_RO.png';
import Batch3_Card17_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card17_Back_RS.png';
import Batch3_Card17_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card17_Back_SE.png';
import Batch3_Card17_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card17_Back_SI.png';
import Batch3_Card17_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card17_Back_US.png';

import Batch3_Card18_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card18_Back_BG.png';
import Batch3_Card18_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card18_Back_CZ.png';
import Batch3_Card18_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card18_Back_DE.png';
import Batch3_Card18_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card18_Back_EN.png';
import Batch3_Card18_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card18_Back_HO.png';
import Batch3_Card18_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card18_Back_HR.png';
import Batch3_Card18_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card18_Back_HU.png';
import Batch3_Card18_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card18_Back_IT.png';
import Batch3_Card18_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card18_Back_MX.png';
import Batch3_Card18_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card18_Back_NO.png';
import Batch3_Card18_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card18_Back_PL.png';
import Batch3_Card18_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card18_Back_RO.png';
import Batch3_Card18_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card18_Back_RS.png';
import Batch3_Card18_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card18_Back_SE.png';
import Batch3_Card18_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card18_Back_SI.png';
import Batch3_Card18_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card18_Back_US.png';

import Batch3_Card19_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card19_Back_BG.png';
import Batch3_Card19_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card19_Back_CZ.png';
import Batch3_Card19_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card19_Back_DE.png';
import Batch3_Card19_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card19_Back_EN.png';
import Batch3_Card19_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card19_Back_HO.png';
import Batch3_Card19_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card19_Back_HR.png';
import Batch3_Card19_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card19_Back_HU.png';
import Batch3_Card19_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card19_Back_IT.png';
import Batch3_Card19_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card19_Back_MX.png';
import Batch3_Card19_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card19_Back_NO.png';
import Batch3_Card19_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card19_Back_PL.png';
import Batch3_Card19_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card19_Back_RO.png';
import Batch3_Card19_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card19_Back_RS.png';
import Batch3_Card19_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card19_Back_SE.png';
import Batch3_Card19_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card19_Back_SI.png';
import Batch3_Card19_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card19_Back_US.png';

import Batch3_Card20_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card20_Back_BG.png';
import Batch3_Card20_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card20_Back_CZ.png';
import Batch3_Card20_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card20_Back_DE.png';
import Batch3_Card20_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card20_Back_EN.png';
import Batch3_Card20_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card20_Back_HO.png';
import Batch3_Card20_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card20_Back_HR.png';
import Batch3_Card20_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card20_Back_HU.png';
import Batch3_Card20_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card20_Back_IT.png';
import Batch3_Card20_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card20_Back_MX.png';
import Batch3_Card20_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card20_Back_NO.png';
import Batch3_Card20_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card20_Back_PL.png';
import Batch3_Card20_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card20_Back_RO.png';
import Batch3_Card20_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card20_Back_RS.png';
import Batch3_Card20_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card20_Back_SE.png';
import Batch3_Card20_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card20_Back_SI.png';
import Batch3_Card20_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card20_Back_US.png';

// import Batch3_Card21_Back_BG from '@/assets/memory_cards/Batch_3/Back_Images/BG/Batch3_Card21_Back_BG.png';
import Batch3_Card21_Back_CZ from '@/assets/memory_cards/Batch_3/Back_Images/CZ/Batch3_Card21_Back_CZ.png';
import Batch3_Card21_Back_DE from '@/assets/memory_cards/Batch_3/Back_Images/DE/Batch3_Card21_Back_DE.png';
import Batch3_Card21_Back_EN from '@/assets/memory_cards/Batch_3/Back_Images/EN/Batch3_Card21_Back_EN.png';
import Batch3_Card21_Back_HO from '@/assets/memory_cards/Batch_3/Back_Images/HO/Batch3_Card21_Back_HO.png';
import Batch3_Card21_Back_HR from '@/assets/memory_cards/Batch_3/Back_Images/HR/Batch3_Card21_Back_HR.png';
import Batch3_Card21_Back_HU from '@/assets/memory_cards/Batch_3/Back_Images/HU/Batch3_Card21_Back_HU.png';
import Batch3_Card21_Back_IT from '@/assets/memory_cards/Batch_3/Back_Images/IT/Batch3_Card21_Back_IT.png';
import Batch3_Card21_Back_MX from '@/assets/memory_cards/Batch_3/Back_Images/MX/Batch3_Card21_Back_MX.png';
import Batch3_Card21_Back_NO from '@/assets/memory_cards/Batch_3/Back_Images/NO/Batch3_Card21_Back_NO.png';
import Batch3_Card21_Back_PL from '@/assets/memory_cards/Batch_3/Back_Images/PL/Batch3_Card21_Back_PL.png';
import Batch3_Card21_Back_RO from '@/assets/memory_cards/Batch_3/Back_Images/RO/Batch3_Card21_Back_RO.png';
import Batch3_Card21_Back_RS from '@/assets/memory_cards/Batch_3/Back_Images/RS/Batch3_Card21_Back_RS.png';
import Batch3_Card21_Back_SE from '@/assets/memory_cards/Batch_3/Back_Images/SE/Batch3_Card21_Back_SE.png';
import Batch3_Card21_Back_SI from '@/assets/memory_cards/Batch_3/Back_Images/SI/Batch3_Card21_Back_SI.png';
import Batch3_Card21_Back_US from '@/assets/memory_cards/Batch_3/Back_Images/US/Batch3_Card21_Back_US.png';

// Batch 4
import Batch4_Card1_Front from '@/assets/memory_cards/Batch_4/Front_SVG/Batch4_Card1_Front.svg';
import Batch4_Card2_Front from '@/assets/memory_cards/Batch_4/Front_SVG/Batch4_Card2_Front.svg';
import Batch4_Card3_Front from '@/assets/memory_cards/Batch_4/Front_SVG/Batch4_Card3_Front.svg';
import Batch4_Card4_Front from '@/assets/memory_cards/Batch_4/Front_SVG/Batch4_Card4_Front.svg';
import Batch4_Card5_Front from '@/assets/memory_cards/Batch_4/Front_SVG/Batch4_Card5_Front.svg';


import Batch4_Card1_Back_BG from '@/assets/memory_cards/Batch_4/Back_Images/BG/Batch4_Card1_Back_BG.png';
import Batch4_Card1_Back_CZ from '@/assets/memory_cards/Batch_4/Back_Images/CZ/Batch4_Card1_Back_CZ.png';
import Batch4_Card1_Back_DE from '@/assets/memory_cards/Batch_4/Back_Images/DE/Batch4_Card1_Back_DE.png';
import Batch4_Card1_Back_EN from '@/assets/memory_cards/Batch_4/Back_Images/EN/Batch4_Card1_Back_EN.png';
import Batch4_Card1_Back_FR from '@/assets/memory_cards/Batch_4/Back_Images/FR/Batch4_Card1_Back_FR.png';
import Batch4_Card1_Back_HR from '@/assets/memory_cards/Batch_4/Back_Images/HR/Batch4_Card1_Back_HR.png';
import Batch4_Card1_Back_HU from '@/assets/memory_cards/Batch_4/Back_Images/HU/Batch4_Card1_Back_HU.png';
import Batch4_Card1_Back_IT from '@/assets/memory_cards/Batch_4/Back_Images/IT/Batch4_Card1_Back_IT.png';
import Batch4_Card1_Back_MX from '@/assets/memory_cards/Batch_4/Back_Images/MX/Batch4_Card1_Back_MX.png';
import Batch4_Card1_Back_NO from '@/assets/memory_cards/Batch_4/Back_Images/NO/Batch4_Card1_Back_NO.png';
import Batch4_Card1_Back_PL from '@/assets/memory_cards/Batch_4/Back_Images/PL/Batch4_Card1_Back_PL.png';
import Batch4_Card1_Back_RO from '@/assets/memory_cards/Batch_4/Back_Images/RO/Batch4_Card1_Back_RO.png';
import Batch4_Card1_Back_RS from '@/assets/memory_cards/Batch_4/Back_Images/RS/Batch4_Card1_Back_RS.png';
import Batch4_Card1_Back_SE from '@/assets/memory_cards/Batch_4/Back_Images/SE/Batch4_Card1_Back_SE.png';
import Batch4_Card1_Back_SI from '@/assets/memory_cards/Batch_4/Back_Images/SI/Batch4_Card1_Back_SI.png';
import Batch4_Card1_Back_US from '@/assets/memory_cards/Batch_4/Back_Images/US/Batch4_Card1_Back_US.png';

import Batch4_Card2_Back_BG from '@/assets/memory_cards/Batch_4/Back_Images/BG/Batch4_Card2_Back_BG.png';
import Batch4_Card2_Back_CZ from '@/assets/memory_cards/Batch_4/Back_Images/CZ/Batch4_Card2_Back_CZ.png';
import Batch4_Card2_Back_DE from '@/assets/memory_cards/Batch_4/Back_Images/DE/Batch4_Card2_Back_DE.png';
import Batch4_Card2_Back_EN from '@/assets/memory_cards/Batch_4/Back_Images/EN/Batch4_Card2_Back_EN.png';
import Batch4_Card2_Back_FR from '@/assets/memory_cards/Batch_4/Back_Images/FR/Batch4_Card2_Back_FR.png';
import Batch4_Card2_Back_HR from '@/assets/memory_cards/Batch_4/Back_Images/HR/Batch4_Card2_Back_HR.png';
import Batch4_Card2_Back_HU from '@/assets/memory_cards/Batch_4/Back_Images/HU/Batch4_Card2_Back_HU.png';
import Batch4_Card2_Back_IT from '@/assets/memory_cards/Batch_4/Back_Images/IT/Batch4_Card2_Back_IT.png';
import Batch4_Card2_Back_MX from '@/assets/memory_cards/Batch_4/Back_Images/MX/Batch4_Card2_Back_MX.png';
import Batch4_Card2_Back_NO from '@/assets/memory_cards/Batch_4/Back_Images/NO/Batch4_Card2_Back_NO.png';
import Batch4_Card2_Back_PL from '@/assets/memory_cards/Batch_4/Back_Images/PL/Batch4_Card2_Back_PL.png';
import Batch4_Card2_Back_RO from '@/assets/memory_cards/Batch_4/Back_Images/RO/Batch4_Card2_Back_RO.png';
import Batch4_Card2_Back_RS from '@/assets/memory_cards/Batch_4/Back_Images/RS/Batch4_Card2_Back_RS.png';
import Batch4_Card2_Back_SE from '@/assets/memory_cards/Batch_4/Back_Images/SE/Batch4_Card2_Back_SE.png';
import Batch4_Card2_Back_SI from '@/assets/memory_cards/Batch_4/Back_Images/SI/Batch4_Card2_Back_SI.png';
import Batch4_Card2_Back_US from '@/assets/memory_cards/Batch_4/Back_Images/US/Batch4_Card2_Back_US.png';

import Batch4_Card3_Back_BG from '@/assets/memory_cards/Batch_4/Back_Images/BG/Batch4_Card3_Back_BG.png';
import Batch4_Card3_Back_CZ from '@/assets/memory_cards/Batch_4/Back_Images/CZ/Batch4_Card3_Back_CZ.png';
import Batch4_Card3_Back_DE from '@/assets/memory_cards/Batch_4/Back_Images/DE/Batch4_Card3_Back_DE.png';
import Batch4_Card3_Back_EN from '@/assets/memory_cards/Batch_4/Back_Images/EN/Batch4_Card3_Back_EN.png';
import Batch4_Card3_Back_FR from '@/assets/memory_cards/Batch_4/Back_Images/FR/Batch4_Card3_Back_FR.png';
import Batch4_Card3_Back_HR from '@/assets/memory_cards/Batch_4/Back_Images/HR/Batch4_Card3_Back_HR.png';
import Batch4_Card3_Back_HU from '@/assets/memory_cards/Batch_4/Back_Images/HU/Batch4_Card3_Back_HU.png';
import Batch4_Card3_Back_IT from '@/assets/memory_cards/Batch_4/Back_Images/IT/Batch4_Card3_Back_IT.png';
import Batch4_Card3_Back_MX from '@/assets/memory_cards/Batch_4/Back_Images/MX/Batch4_Card3_Back_MX.png';
import Batch4_Card3_Back_NO from '@/assets/memory_cards/Batch_4/Back_Images/NO/Batch4_Card3_Back_NO.png';
import Batch4_Card3_Back_PL from '@/assets/memory_cards/Batch_4/Back_Images/PL/Batch4_Card3_Back_PL.png';
import Batch4_Card3_Back_RO from '@/assets/memory_cards/Batch_4/Back_Images/RO/Batch4_Card3_Back_RO.png';
import Batch4_Card3_Back_RS from '@/assets/memory_cards/Batch_4/Back_Images/RS/Batch4_Card3_Back_RS.png';
import Batch4_Card3_Back_SE from '@/assets/memory_cards/Batch_4/Back_Images/SE/Batch4_Card3_Back_SE.png';
import Batch4_Card3_Back_SI from '@/assets/memory_cards/Batch_4/Back_Images/SI/Batch4_Card3_Back_SI.png';
import Batch4_Card3_Back_US from '@/assets/memory_cards/Batch_4/Back_Images/US/Batch4_Card3_Back_US.png';

import Batch4_Card4_Back_BG from '@/assets/memory_cards/Batch_4/Back_Images/BG/Batch4_Card4_Back_BG.png';
import Batch4_Card4_Back_CZ from '@/assets/memory_cards/Batch_4/Back_Images/CZ/Batch4_Card4_Back_CZ.png';
import Batch4_Card4_Back_DE from '@/assets/memory_cards/Batch_4/Back_Images/DE/Batch4_Card4_Back_DE.png';
import Batch4_Card4_Back_EN from '@/assets/memory_cards/Batch_4/Back_Images/EN/Batch4_Card4_Back_EN.png';
import Batch4_Card4_Back_FR from '@/assets/memory_cards/Batch_4/Back_Images/FR/Batch4_Card4_Back_FR.png';
import Batch4_Card4_Back_HR from '@/assets/memory_cards/Batch_4/Back_Images/HR/Batch4_Card4_Back_HR.png';
import Batch4_Card4_Back_HU from '@/assets/memory_cards/Batch_4/Back_Images/HU/Batch4_Card4_Back_HU.png';
import Batch4_Card4_Back_IT from '@/assets/memory_cards/Batch_4/Back_Images/IT/Batch4_Card4_Back_IT.png';
import Batch4_Card4_Back_MX from '@/assets/memory_cards/Batch_4/Back_Images/MX/Batch4_Card4_Back_MX.png';
import Batch4_Card4_Back_NO from '@/assets/memory_cards/Batch_4/Back_Images/NO/Batch4_Card4_Back_NO.png';
import Batch4_Card4_Back_PL from '@/assets/memory_cards/Batch_4/Back_Images/PL/Batch4_Card4_Back_PL.png';
import Batch4_Card4_Back_RO from '@/assets/memory_cards/Batch_4/Back_Images/RO/Batch4_Card4_Back_RO.png';
import Batch4_Card4_Back_RS from '@/assets/memory_cards/Batch_4/Back_Images/RS/Batch4_Card4_Back_RS.png';
import Batch4_Card4_Back_SE from '@/assets/memory_cards/Batch_4/Back_Images/SE/Batch4_Card4_Back_SE.png';
import Batch4_Card4_Back_SI from '@/assets/memory_cards/Batch_4/Back_Images/SI/Batch4_Card4_Back_SI.png';
import Batch4_Card4_Back_US from '@/assets/memory_cards/Batch_4/Back_Images/US/Batch4_Card4_Back_US.png';

import Batch4_Card5_Back_BG from '@/assets/memory_cards/Batch_4/Back_Images/BG/Batch4_Card5_Back_BG.png';
import Batch4_Card5_Back_CZ from '@/assets/memory_cards/Batch_4/Back_Images/CZ/Batch4_Card5_Back_CZ.png';
import Batch4_Card5_Back_DE from '@/assets/memory_cards/Batch_4/Back_Images/DE/Batch4_Card5_Back_DE.png';
import Batch4_Card5_Back_EN from '@/assets/memory_cards/Batch_4/Back_Images/EN/Batch4_Card5_Back_EN.png';
import Batch4_Card5_Back_FR from '@/assets/memory_cards/Batch_4/Back_Images/FR/Batch4_Card5_Back_FR.png';
import Batch4_Card5_Back_HR from '@/assets/memory_cards/Batch_4/Back_Images/HR/Batch4_Card5_Back_HR.png';
import Batch4_Card5_Back_HU from '@/assets/memory_cards/Batch_4/Back_Images/HU/Batch4_Card5_Back_HU.png';
import Batch4_Card5_Back_IT from '@/assets/memory_cards/Batch_4/Back_Images/IT/Batch4_Card5_Back_IT.png';
import Batch4_Card5_Back_MX from '@/assets/memory_cards/Batch_4/Back_Images/MX/Batch4_Card5_Back_MX.png';
import Batch4_Card5_Back_NO from '@/assets/memory_cards/Batch_4/Back_Images/NO/Batch4_Card5_Back_NO.png';
import Batch4_Card5_Back_PL from '@/assets/memory_cards/Batch_4/Back_Images/PL/Batch4_Card5_Back_PL.png';
import Batch4_Card5_Back_RO from '@/assets/memory_cards/Batch_4/Back_Images/RO/Batch4_Card5_Back_RO.png';
import Batch4_Card5_Back_RS from '@/assets/memory_cards/Batch_4/Back_Images/RS/Batch4_Card5_Back_RS.png';
import Batch4_Card5_Back_SE from '@/assets/memory_cards/Batch_4/Back_Images/SE/Batch4_Card5_Back_SE.png';
import Batch4_Card5_Back_SI from '@/assets/memory_cards/Batch_4/Back_Images/SI/Batch4_Card5_Back_SI.png';
import Batch4_Card5_Back_US from '@/assets/memory_cards/Batch_4/Back_Images/US/Batch4_Card5_Back_US.png';

// Jokers
import Jokers_Card1_Front from '@/assets/memory_cards/Jokers/Front_SVG/Jokers_Card1_Front.svg';
import Jokers_Card2_Front from '@/assets/memory_cards/Jokers/Front_SVG/Jokers_Card2_Front.svg';
import Jokers_Card3_Front from '@/assets/memory_cards/Jokers/Front_SVG/Jokers_Card3_Front.svg';


import Jokers_Card1_Back_BG from '@/assets/memory_cards/Jokers/Back_Images/BG/Jokers_Card1_Back_BG.png';
import Jokers_Card1_Back_CZ from '@/assets/memory_cards/Jokers/Back_Images/CZ/Jokers_Card1_Back_CZ.png';
import Jokers_Card1_Back_DE from '@/assets/memory_cards/Jokers/Back_Images/DE/Jokers_Card1_Back_DE.png';
import Jokers_Card1_Back_EN from '@/assets/memory_cards/Jokers/Back_Images/EN/Jokers_Card1_Back_EN.png';
import Jokers_Card1_Back_HO from '@/assets/memory_cards/Jokers/Back_Images/HO/Jokers_Card1_Back_HO.png';
import Jokers_Card1_Back_HR from '@/assets/memory_cards/Jokers/Back_Images/HR/Jokers_Card1_Back_HR.png';
import Jokers_Card1_Back_HU from '@/assets/memory_cards/Jokers/Back_Images/HU/Jokers_Card1_Back_HU.png';
import Jokers_Card1_Back_IT from '@/assets/memory_cards/Jokers/Back_Images/IT/Jokers_Card1_Back_IT.png';
import Jokers_Card1_Back_MX from '@/assets/memory_cards/Jokers/Back_Images/MX/Jokers_Card1_Back_MX.png';
import Jokers_Card1_Back_NO from '@/assets/memory_cards/Jokers/Back_Images/NO/Jokers_Card1_Back_NO.png';
import Jokers_Card1_Back_PL from '@/assets/memory_cards/Jokers/Back_Images/PL/Jokers_Card1_Back_PL.png';
import Jokers_Card1_Back_RO from '@/assets/memory_cards/Jokers/Back_Images/RO/Jokers_Card1_Back_RO.png';
import Jokers_Card1_Back_RS from '@/assets/memory_cards/Jokers/Back_Images/RS/Jokers_Card1_Back_RS.png';
import Jokers_Card1_Back_SE from '@/assets/memory_cards/Jokers/Back_Images/SE/Jokers_Card1_Back_SE.png';
import Jokers_Card1_Back_SI from '@/assets/memory_cards/Jokers/Back_Images/SI/Jokers_Card1_Back_SI.png';
import Jokers_Card1_Back_US from '@/assets/memory_cards/Jokers/Back_Images/US/Jokers_Card1_Back_US.png';

import Jokers_Card2_Back_BG from '@/assets/memory_cards/Jokers/Back_Images/BG/Jokers_Card2_Back_BG.png';
import Jokers_Card2_Back_CZ from '@/assets/memory_cards/Jokers/Back_Images/CZ/Jokers_Card2_Back_CZ.png';
import Jokers_Card2_Back_DE from '@/assets/memory_cards/Jokers/Back_Images/DE/Jokers_Card2_Back_DE.png';
import Jokers_Card2_Back_EN from '@/assets/memory_cards/Jokers/Back_Images/EN/Jokers_Card2_Back_EN.png';
import Jokers_Card2_Back_HO from '@/assets/memory_cards/Jokers/Back_Images/HO/Jokers_Card2_Back_HO.png';
import Jokers_Card2_Back_HR from '@/assets/memory_cards/Jokers/Back_Images/HR/Jokers_Card2_Back_HR.png';
import Jokers_Card2_Back_HU from '@/assets/memory_cards/Jokers/Back_Images/HU/Jokers_Card2_Back_HU.png';
import Jokers_Card2_Back_IT from '@/assets/memory_cards/Jokers/Back_Images/IT/Jokers_Card2_Back_IT.png';
import Jokers_Card2_Back_MX from '@/assets/memory_cards/Jokers/Back_Images/MX/Jokers_Card2_Back_MX.png';
import Jokers_Card2_Back_NO from '@/assets/memory_cards/Jokers/Back_Images/NO/Jokers_Card2_Back_NO.png';
import Jokers_Card2_Back_PL from '@/assets/memory_cards/Jokers/Back_Images/PL/Jokers_Card2_Back_PL.png';
import Jokers_Card2_Back_RO from '@/assets/memory_cards/Jokers/Back_Images/RO/Jokers_Card2_Back_RO.png';
import Jokers_Card2_Back_RS from '@/assets/memory_cards/Jokers/Back_Images/RS/Jokers_Card2_Back_RS.png';
import Jokers_Card2_Back_SE from '@/assets/memory_cards/Jokers/Back_Images/SE/Jokers_Card2_Back_SE.png';
import Jokers_Card2_Back_SI from '@/assets/memory_cards/Jokers/Back_Images/SI/Jokers_Card2_Back_SI.png';
import Jokers_Card2_Back_US from '@/assets/memory_cards/Jokers/Back_Images/US/Jokers_Card2_Back_US.png';

import Jokers_Card3_Back_BG from '@/assets/memory_cards/Jokers/Back_Images/BG/Jokers_Card3_Back_BG.png';
import Jokers_Card3_Back_CZ from '@/assets/memory_cards/Jokers/Back_Images/CZ/Jokers_Card3_Back_CZ.png';
import Jokers_Card3_Back_DE from '@/assets/memory_cards/Jokers/Back_Images/DE/Jokers_Card3_Back_DE.png';
import Jokers_Card3_Back_EN from '@/assets/memory_cards/Jokers/Back_Images/EN/Jokers_Card3_Back_EN.png';
import Jokers_Card3_Back_HO from '@/assets/memory_cards/Jokers/Back_Images/HO/Jokers_Card3_Back_HO.png';
import Jokers_Card3_Back_HR from '@/assets/memory_cards/Jokers/Back_Images/HR/Jokers_Card3_Back_HR.png';
import Jokers_Card3_Back_HU from '@/assets/memory_cards/Jokers/Back_Images/HU/Jokers_Card3_Back_HU.png';
import Jokers_Card3_Back_IT from '@/assets/memory_cards/Jokers/Back_Images/IT/Jokers_Card3_Back_IT.png';
import Jokers_Card3_Back_MX from '@/assets/memory_cards/Jokers/Back_Images/MX/Jokers_Card3_Back_MX.png';
import Jokers_Card3_Back_NO from '@/assets/memory_cards/Jokers/Back_Images/NO/Jokers_Card3_Back_NO.png';
import Jokers_Card3_Back_PL from '@/assets/memory_cards/Jokers/Back_Images/PL/Jokers_Card3_Back_PL.png';
import Jokers_Card3_Back_RO from '@/assets/memory_cards/Jokers/Back_Images/RO/Jokers_Card3_Back_RO.png';
import Jokers_Card3_Back_RS from '@/assets/memory_cards/Jokers/Back_Images/RS/Jokers_Card3_Back_RS.png';
import Jokers_Card3_Back_SE from '@/assets/memory_cards/Jokers/Back_Images/SE/Jokers_Card3_Back_SE.png';
import Jokers_Card3_Back_SI from '@/assets/memory_cards/Jokers/Back_Images/SI/Jokers_Card3_Back_SI.png';
import Jokers_Card3_Back_US from '@/assets/memory_cards/Jokers/Back_Images/US/Jokers_Card3_Back_US.png';


const Cards = [
    // Batch 1
    {
        batchNumber: 1,
        memoryCardNumber: 1,
        frontSvg: Batch1_Card1_Front,
        frontTitle: {
            en: 'Personal\n' +
                'Responsibility',
            de: 'Selbst\n' +
                '-verantwortung',
            ro: 'Responsabilitate\n' +
                'Personală',
            bg: 'Лична\n' +
                'отговорност',
            hr: 'Osobna\n' +
                'Odgovornost',
            cz: 'Osobní\n' +
                'Zodpovědnost',
            ho: 'Eigen\n' +
                'Verantwoordelijkheid',
            fr: 'Responsabilité\n' +
                'Personnelle',
            hu: 'Személyes\n' +
                'Felelősség',
            it: 'Responsabilità\n' +
                'Personale',
            mx: 'Responsabilidad\n' +
                'Personal',
            no: 'Personlig\n' +
                'Ansvar',
            si: 'Osebna\n' +
                'Odgovornost',
            rs: 'Lična\n' +
                'Odgovornost',
            se: 'Personligt\n' +
                'Ansvarig',
            tr: 'Bireysel\n' +
                'Sorumluluk',
            us: 'Personal\n' +
                'Responsibility',
        },
        frontBottomText: {
            en: 'Create your own future',
            de: 'Gestalte Deine Zukunft',
            ro: 'Creează-ți viitorul',
            bg: 'Създай твоето собствено бъдеще',
            hr: 'Stvorite svoju vlastitu budućnost',
            cz: 'Vytvářej svou vlastní budoucnost',
            ho: 'Creëer je eigen toekomst',
            fr: 'Créez votre propre avenir',
            hu: 'Alakítsd a saját jövődet',
            it: 'Creare il proprio futuro',
            mx: 'Crea tu propio futuro',
            no: 'Skap din egen fremtid',
            si: 'Sam ustvarjam svojo prihodnost',
            rs: 'Kreiramo sopstvenu budućnost',
            se: 'Skapa din egen framtid',
            tr: 'Kendi geleceğini yarat',
            us: 'Create your own future',
        },
        backImage: {
            en: Batch1_Card1_Back_EN,
            de: Batch1_Card1_Back_DE,
            ro: Batch1_Card1_Back_RO,
            bg: Batch1_Card1_Back_BG,
            hr: Batch1_Card1_Back_HR,
            cz: Batch1_Card1_Back_CZ,
            ho: Batch1_Card1_Back_HO,
            fr: Batch1_Card1_Back_FR,
            hu: Batch1_Card1_Back_HU,
            it: Batch1_Card1_Back_IT,
            mx: Batch1_Card1_Back_MX,
            no: Batch1_Card1_Back_NO,
            si: Batch1_Card1_Back_SI,
            rs: Batch1_Card1_Back_RS,
            se: Batch1_Card1_Back_SE,
            tr: Batch1_Card1_Back_TR,
            us: Batch1_Card1_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 2,
        frontSvg: Batch1_Card2_Front,
        frontTitle: {
            en: 'Positive\n' +
                'Attitude',
            de: 'Positive\n' +
                'Grundeinstellung',
            ro: 'Atitudine\n' +
                'Pozitivă',
            bg: 'Позитивна\n' +
                'Нагласа',
            hr: 'Pozitivan\n' +
                'Stav',
            cz: 'Pozitivní\n' +
                'Postoj',
            ho: 'Positieve\n' +
                'Houding',
            fr: 'Attitude Positive',
            hu: 'Pozitív\n' +
                'Hozzáállás',
            it: 'Atteggiamento\n' +
                'Positivo',
            mx: 'Actitud\n' +
                'Positiva',
            no: 'Positiv\n' +
                'Holdning',
            si: 'Pozitiven\n' +
                'Odnos',
            rs: 'Pozitivan Stav',
            se: 'Positiv\n' +
                'Inställning',
            tr: 'Pozitif\n' +
                'Tavır',
            us: 'Positive\n' +
                'Attitude',
        },
        frontBottomText: {
            en: 'Why am I enjoying myself?\n' +
                'What do I like about the other person?',
            de: 'Warum freue ich mich?\n' +
                'Was gefällt mir am anderen?',
            ro: 'De ce mă bucur eu acum?\n' +
                'Ce-mi place la cealaltă persoană?',
            bg: 'Защо се забавлявам?\n' +
                'Какво харесвам в другия човек?',
            hr: 'Zašto uživam?\n' +
                'Što mi se sviđa kod druge osobe?',
            cz: 'Co si užiji?\n' +
                'Co je mi na druhém sympatické?',
            ho: 'Waarom verheug ik mij?\n' +
                'Wat bevalt mij aan de ander?',
            fr:'Pourquoi me réjouir?\n' +
                'Qu’est-ce qui me plaît chez l’autre?',
            hu: 'Miért örülök?\n' +
                'Mi tetszik a másik személyen?',
            it: 'Perché mi rallegro? \n' +
                'Che cosa mi piace dell’altro?',
            mx: '¿Por qué me alegro?\n' +
                '¿Qué me gusta de la otra persona?',
            no: 'Hvorfor gleder jeg meg?\n' +
                'Hva liker jeg ved den andre?',
            si: 'Zakaj uživam?\n' +
                'Kaj cenim pri drugi osebi?',
            rs: 'Zašto uživam?\n' +
                'Šta mi se sviđa na drugoj osobi?',
            se: 'Varför glädjer jag mig?\n' +
                'Vad gillar jag hos den andra personen',
            tr: 'Neden mutluyum?\n' +
                'Karşımdaki insanda neleri beğeniyorum?',
            us: 'Why am I enjoying myself?\n' +
                'What do I like about the other person?',
        },
        backImage: {
            en: Batch1_Card2_Back_EN,
            de: Batch1_Card2_Back_DE,
            ro: Batch1_Card2_Back_RO,
            bg: Batch1_Card2_Back_BG,
            hr: Batch1_Card2_Back_HR,
            cz: Batch1_Card2_Back_CZ,
            ho: Batch1_Card2_Back_HO,
            fr: Batch1_Card2_Back_FR,
            hu: Batch1_Card2_Back_HU,
            it: Batch1_Card2_Back_IT,
            mx: Batch1_Card2_Back_MX,
            no: Batch1_Card2_Back_NO,
            si: Batch1_Card2_Back_SI,
            rs: Batch1_Card2_Back_RS,
            se: Batch1_Card2_Back_SE,
            tr: Batch1_Card2_Back_TR,
            us: Batch1_Card2_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 3,
        frontSvg: Batch1_Card3_Front,
        frontTitle: {
            en: 'Fully Active\n' +
                'Recommendation',
            de: 'Aktive\n' +
                'Vollreferenz',
            ro: 'Recomandarea\n' +
                'Total Activă',
            bg: 'Напълно Активна\n' +
                'Препоръка',
            hr: 'Potpuno Aktivna\n' +
                'Preporuka',
            cz: 'Aktivní\n' +
                'Doporučení',
            ho: 'Actieve\n' +
                'Referentie',
            fr: 'Recommandation\n' +
                'Active Totale',
            hu: 'Teljes Aktív\n' +
                'Ajánlás',
            it: 'Referenza Attiva\n' +
                'Totale',
            mx: 'Recomendación\n' +
                'Activa Total',
            no: 'Aktive Topp\n' +
                'Referanser',
            si: 'Proaktivno\n' +
                'Osebno\n' +
                'Priporočilo',
            rs: 'Puna Aktivna\n' +
                'Preporuka',
            se: 'Aktiv Topp\n' +
                'Referens',
            tr: 'TAT - Tam Aktif\n' +
                'Tavsiye',
            us: 'Fully Active\n' +
                'Recommendation',
        },
        frontBottomText: {
            en: 'What is your ultimate objective in selling?',
            de: 'Was ist mein ultimatives Ziel?',
            ro: 'Care este obiectivul final în vânzări?',
            bg: 'Каква е твоята крайна цел?',
            hr: 'Koji je vaš krajnji cilj u prodaji?',
            cz: 'Co je tvůj konečný cíl při prodeji?',
            ho: 'Wat is uw hoogste doel in verkoop?',
            fr: 'Quel est votre objectif principal dans la vente?',
            hu: 'Mi a végső célod az értékesítésben?',
            it: 'Qual’è l’obiettivo finale nella vendita?',
            mx: '¿Cuál es el objetivo más importante al vender?',
            no: 'Hva er ditt ultimate mål?',
            si: 'Kaj je naš najvišji cilj?',
            rs: 'Koji nam je krajnji cilj u prodaji?',
            se: 'Vilket är det optimala målet att uppnå i försäljning?',
            tr: 'Satıştaki nihai hedefiniz nedir?',
            us: 'What is our ultimate objective?',
        },
        backImage: {
            en: Batch1_Card3_Back_EN,
            de: Batch1_Card3_Back_DE,
            ro: Batch1_Card3_Back_RO,
            bg: Batch1_Card3_Back_BG,
            hr: Batch1_Card3_Back_HR,
            cz: Batch1_Card3_Back_CZ,
            ho: Batch1_Card3_Back_HO,
            fr: Batch1_Card3_Back_FR,
            hu: Batch1_Card3_Back_HU,
            it: Batch1_Card3_Back_IT,
            mx: Batch1_Card3_Back_MX,
            no: Batch1_Card3_Back_NO,
            si: Batch1_Card3_Back_SI,
            rs: Batch1_Card3_Back_RS,
            se: Batch1_Card3_Back_SE,
            tr: Batch1_Card3_Back_TR,
            us: Batch1_Card3_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 4,
        frontSvg: Batch1_Card4_Front,
        frontTitle: {
            en: 'Influential\n' +
                'Presentations',
            de: 'Wirkungsvolle\n' +
                'Präsentationen',
            ro: 'Prezentări Care\n' +
                'Influențează',
            bg: 'Въздействащи\n' +
                'презентации',
            hr: 'Efektivne\n' +
                'Prezentacije',
            cz: 'Působivá\n' +
                'Prezentace',
            ho: 'Overtuigende\n' +
                'Presentaties',
            fr: 'Présentations\n' +
                'Influentes',
            hu: 'A Hatásos\n' +
                'Prezentáció',
            it: 'Presentazioni\n' +
                'Influenti',
            mx: 'Presentaciones\n' +
                'Influyentes',
            no: 'Virkningsfulle\n' +
                'Presentasjoner',
            si: 'Prepričljivo\n' +
                'Nastopanje',
            rs: 'Uticajne\n' +
                'Prezentacije',
            se: 'Inflytelserik\n' +
                'Presentation',
            tr: 'Etkili Sunum\n' +
                'Becerileri',
            us: 'Influential\n' +
                'Presentations',
        },
        frontBottomText: {
            en: 'Induce others to act',
            de: 'Andere zu Taten veranlassen',
            ro: 'Induc acțiune',
            bg: 'Подтикни другите да действат',
            hr: 'Potaknimo druge na akciju',
            cz: 'Přimět ostatní k akci',
            ho: 'Anderen tot actie aansporen',
            fr: 'Inciter autrui à l’action',
            hu: 'Ösztönözz tettre másokat',
            it: 'Indurre gli altri ad agire',
            mx: 'Induce a otros a actuar',
            no: 'Påvirke andre til handling',
            si: 'Vzpodbudimo druge k dejanjem',
            rs: 'Navesti druge na akciju',
            se: 'Få andra att agera',
            tr: 'Başkalarını harekete geçrimek',
            us: 'Induce others to act',
        },
        backImage: {
            en: Batch1_Card4_Back_EN,
            de: Batch1_Card4_Back_DE,
            ro: Batch1_Card4_Back_RO,
            bg: Batch1_Card4_Back_BG,
            hr: Batch1_Card4_Back_HR,
            cz: Batch1_Card4_Back_CZ,
            ho: Batch1_Card4_Back_HO,
            fr: Batch1_Card4_Back_FR,
            hu: Batch1_Card4_Back_HU,
            it: Batch1_Card4_Back_IT,
            mx: Batch1_Card4_Back_MX,
            no: Batch1_Card4_Back_NO,
            si: Batch1_Card4_Back_SI,
            rs: Batch1_Card4_Back_RS,
            se: Batch1_Card4_Back_SE,
            tr: Batch1_Card4_Back_TR,
            us: Batch1_Card4_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 5,
        frontSvg: Batch1_Card5_Front,
        frontTitle: {
            en: 'Appraisal',
            de: 'Fördern',
            ro: 'Aprecierea',
            bg: 'Въздействащи\n' +
                'презентации',
            hr: 'Poticanje',
            cz: 'Hodnocení',
            ho: 'Functioneren',
            fr: 'Évaluation',
            hu: 'Értékelés',
            it: 'Valutazione',
            mx: 'Estimular',
            no: 'Medarbeidersamtalen',
            si: 'Spodbujevalni\n' +
                'Razgovor',
            rs: 'Procena',
            se: 'Att Leda',
            tr: 'Değerlendirme',
            us: 'Appraisal',
        },
        frontBottomText: {
            en: 'Help them to build on their strengths',
            de: 'Stärken ausbauen',
            ro: 'Construiește folosind calitățile, punctele forte',
            bg: 'Подтикни другите да действат',
            hr: 'Gradimo na njihovim prednostima',
            cz: 'Stavěj na jejich přednostech',
            ho: 'Kwaliteiten verder ontwikkelen',
            fr: 'Aidez-les à s’appuyer sur leurs points forts',
            hu: 'Építs az erősségeire',
            it: 'Rafforzare i punti forti',
            mx: 'Construye sobre sus fortalezas',
            no: 'Bygg på deres styrker',
            si: 'Krepimo kreposti',
            rs: 'Razvijamo njihove snage',
            se: 'Att utveckla de starka sidorna',
            tr: 'Kuvvetli yönleri geliştirin',
            us: 'Build on their strengths',
        },
        backImage: {
            en: Batch1_Card5_Back_EN,
            de: Batch1_Card5_Back_DE,
            ro: Batch1_Card5_Back_RO,
            bg: Batch1_Card5_Back_BG,
            hr: Batch1_Card5_Back_HR,
            cz: Batch1_Card5_Back_CZ,
            ho: Batch1_Card5_Back_HO,
            fr: Batch1_Card5_Back_FR,
            hu: Batch1_Card5_Back_HU,
            it: Batch1_Card5_Back_IT,
            mx: Batch1_Card5_Back_MX,
            no: Batch1_Card5_Back_NO,
            si: Batch1_Card5_Back_SI,
            rs: Batch1_Card5_Back_RS,
            se: Batch1_Card5_Back_SE,
            tr: Batch1_Card5_Back_TR,
            us: Batch1_Card5_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 6,
        frontSvg: Batch1_Card6_Front,
        frontTitle: {
            en: 'Creating the\n' +
                'Written Offer',
            de: 'Angebots\n' +
                '-gestaltung',
            ro: 'Crearea Ofertei',
            bg: 'Оценка',
            hr: 'Izrada Ponude',
            cz: 'Vytvoření\n' +
                'Nabídky',
            ho: 'Het maken van\n' +
                'de Offerte',
            fr: 'Rédiger l’Offre\n' +
                'Écrite',
            hu: 'Az Ajánlat\n' +
                'létrehozása',
            it: 'Creare\n' +
                'un’ Offerta',
            mx: 'Creando la\n' +
                'Propuesta',
            no: 'Skrive Tilbud',
            si: 'Izdelava\n' +
                'Ponudbe',
            rs: 'Kreiranje Ponude',
            se: 'Skapa en Offert',
            tr: 'Teklif Oluşturma',
            us: 'Creating the\n' +
                'offer',
        },
        frontBottomText: {
            en: 'Is it the customer’s brainchild?',
            de: 'Ist es das Kind des Kunden?',
            ro: 'Este produsul minții lor?',
            bg: 'Равивай силните им страни',
            hr: 'Je li to njihova “beba”?',
            cz: 'Je to zákazníkovo dítě?',
            ho: 'Is dit het kindje van de klant?',
            fr: 'Est-ce l’idée du client?',
            hu: 'Ez az ő ötlete, a vevő gyermeke?',
            it: 'E’ la sua idea?',
            mx: 'Er det deres baby?',
            no: 'Skap din egen fremtid',
            si: 'Ali jo vidijo kot lastni izdelek?',
            rs: 'Da li je to njihova ideja?',
            se: 'Är det kundens Baby?',
            tr: 'Bu müşterinin fikir bebeği mi (kendi parlak\n' +
                'düşüncesi mi)?',
            us: 'Is it their brainchild?',
        },
        backImage: {
            en: Batch1_Card6_Back_EN,
            de: Batch1_Card6_Back_DE,
            ro: Batch1_Card6_Back_RO,
            bg: Batch1_Card6_Back_BG,
            hr: Batch1_Card6_Back_HR,
            cz: Batch1_Card6_Back_CZ,
            ho: Batch1_Card6_Back_HO,
            fr: Batch1_Card6_Back_FR,
            hu: Batch1_Card6_Back_HU,
            it: Batch1_Card6_Back_IT,
            mx: Batch1_Card6_Back_MX,
            no: Batch1_Card6_Back_NO,
            si: Batch1_Card6_Back_SI,
            rs: Batch1_Card6_Back_RS,
            se: Batch1_Card6_Back_SE,
            tr: Batch1_Card6_Back_TR,
            us: Batch1_Card6_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 7,
        frontSvg: Batch1_Card7_Front,
        frontTitle: {
            en: 'Personal\n' +
                'Effectiveness',
            de: 'Persönliche\n' +
                'Effektivität',
            ro: 'Eficiența\n' +
                'Personală',
            bg: 'Създаване на\n' +
                'офертата',
            hr: 'Osobna\n' +
                'Učinkovitost',
            cz: 'Osobní Efektivita',
            ho: 'Persoonlijke\n' +
                'Effectiviteit',
            fr: 'Efficacité\n' +
                'Personnelle',
            hu: 'A Személyes\n' +
                'Hatékonyság',
            it: 'Efficacia\n' +
                'Personale',
            mx: 'Efectividad\n' +
                'Personal',
            no: 'Din Tidsstyring',
            si: 'Osebna\n' +
                'Učinkovitost',
            rs: 'Lična Efektivnost',
            se: 'Din Tidsstyrning',
            tr: 'Kişisel Etkinlik',
            us: 'Personal\n' +
                'Effectiveness',
        },
        frontBottomText: {
            en: 'Focus on your big stones',
            de: 'Fokus auf die großen Steine',
            ro: 'Focus pe pietrele mari',
            bg: 'На техния език ли е?',
            hr: 'Fokusirajmo se na veliko kamenje',
            cz: 'Zaměř se na velké kameny',
            ho: 'Focus op de grote dingen',
            fr: 'Concentrez-vous sur vos grandes pierres',
            hu: 'Koncentrálj a nagy kövekre',
            it: 'Focus sulle grandi pietre',
            mx: 'Enfócate en tus piedras grandes',
            no: 'Fokuser på de store stenene',
            si: 'Osredotočimo se na velike kamne',
            rs: 'Fokusiraj se na svoje veliko kamenje',
            se: 'Fokusera på de stora stenarna',
            tr: 'Büyük taşlara odaklan',
            us: 'Focus on our big stones',
        },
        backImage: {
            en: Batch1_Card7_Back_EN,
            de: Batch1_Card7_Back_DE,
            ro: Batch1_Card7_Back_RO,
            bg: Batch1_Card7_Back_BG,
            hr: Batch1_Card7_Back_HR,
            cz: Batch1_Card7_Back_CZ,
            ho: Batch1_Card7_Back_HO,
            fr: Batch1_Card7_Back_FR,
            hu: Batch1_Card7_Back_HU,
            it: Batch1_Card7_Back_IT,
            mx: Batch1_Card7_Back_MX,
            no: Batch1_Card7_Back_NO,
            si: Batch1_Card7_Back_SI,
            rs: Batch1_Card7_Back_RS,
            se: Batch1_Card7_Back_SE,
            tr: Batch1_Card7_Back_TR,
            us: Batch1_Card7_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 8,
        frontSvg: Batch1_Card8_Front,
        frontTitle: {
            en: 'Communication\n' +
                '& Negotiation',
            de: 'Effiziente\n' +
                'Gesprächsführung',
            ro: 'Comunicare\n' +
                '& Negociere',
            bg: 'Лична\n' +
                'ефективност',
            hr: 'Komunikacija\n' +
                'i Pregovaranje',
            cz: 'Komunikace\n' +
                '& Vyjednávání',
            ho: 'Communicatie\n' +
                '& Onderhandelen',
            fr: 'Communication\n' +
                '& Négociation',
            hu: 'Kommunikáció\n' +
                '& Tárgyalás',
            it: 'Comunicazione\n' +
                '& Negoziazione',
            mx: 'Comunicación y\n' +
                'Negociación',
            no: 'Kommunikasjon\n' +
                '& Forhandlinger',
            si: 'Komunikacija\n' +
                'in Pogajanja',
            rs: 'Komunikacija\n' +
                'i Pregovaranje',
            se: 'Kommunikation\n' +
                '& Förhandling',
            tr: 'İletişim\n' +
                '& Müzakere',
            us: 'Communication\n' +
                '& Negotiation',
        },
        frontBottomText: {
            en: 'Consciously influence the conversation',
            de: 'Wie können wir die Konversation beeinflussen?',
            ro: 'Cum influențăm conversația?',
            bg: 'Фокусирай се върху твоите големи камъни',
            hr: 'Kako utječemo u razgovoru?',
            cz: 'Jak působíš při vedení rozhovoru?',
            ho: 'Hoe beïnvloed je het gesprek?',
            fr: 'Influencez consciemment la conversation',
            hu: 'Hogyan befolyásolod a megbeszélést?',
            it: 'Come influenzare la conversazione?',
            mx: '¿Cómo influyo en la conversación?',
            no: 'Hvordan påvirker du samtalen?',
            si: 'Kako vplivamo na pogovor?',
            rs: 'Kako utičemo na razgovor?',
            se: 'Hur påverkar du konversationen?',
            tr: 'Bir konuşmayı nasıl etkiliyorsunuz?',
            us: 'How can we influence the conversation?',
        },
        backImage: {
            en: Batch1_Card8_Back_EN,
            de: Batch1_Card8_Back_DE,
            ro: Batch1_Card8_Back_RO,
            bg: Batch1_Card8_Back_BG,
            hr: Batch1_Card8_Back_HR,
            cz: Batch1_Card8_Back_CZ,
            ho: Batch1_Card8_Back_HO,
            fr: Batch1_Card8_Back_FR,
            hu: Batch1_Card8_Back_HU,
            it: Batch1_Card8_Back_IT,
            mx: Batch1_Card8_Back_MX,
            no: Batch1_Card8_Back_NO,
            si: Batch1_Card8_Back_SI,
            rs: Batch1_Card8_Back_RS,
            se: Batch1_Card8_Back_SE,
            tr: Batch1_Card8_Back_TR,
            us: Batch1_Card8_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 9,
        frontSvg: Batch1_Card9_Front,
        frontTitle: {
            en: 'Setting\n' +
                'Objectives',
            de: 'Ziele Setzen',
            ro: 'Definește\n' +
                'Obiective',
            bg: 'Комуникация\n' +
                '& Преговори',
            hr: 'Postavimo\n' +
                'Ciljeve',
            cz: 'Stanovení Cílů',
            ho: 'Doelen stellen',
            fr: 'Définir les\n' +
                'Objectifs',
            hu: 'Célok\n' +
                'Megfogalmazása',
            it: 'Stabilire\n' +
                'Obiettivi',
            mx: 'Fijar Objetivos',
            no: 'Sette Mål',
            si: 'Postavljanje\n' +
                'Ciljev',
            rs: 'Postavljanje\n' +
                'Ciljeva',
            se: 'Sätta Mål',
            tr: 'Hedefleri\n' +
                'Belirlemek',
            us: 'Setting\n' +
                'Objectives',
        },
        frontBottomText: {
            en: 'Make them SMART!',
            de: 'Formuliere sie SMART!',
            ro: 'Obiective SMART!',
            bg: 'Как влияеш на разговора?',
            hr: 'Neka budu JASNI!',
            cz: 'Udělejme je SMART!',
            ho: 'Maak ze SMART!',
            fr: 'Rendez-les SMART!',
            hu: 'Használd a SMART-ot!',
            it: 'Renderli SMART!',
            mx: '¡Hacerlos SMART!',
            no: 'Sett SMART mål!',
            si: 'Naj bodo SMART!',
            rs: 'Napravimo ih da budu SMART!',
            se: 'SMART',
            tr: 'Hedeflerini SUSAM (SMART) yap',
            us: 'Make them SMART!',
        },
        backImage: {
            en: Batch1_Card9_Back_EN,
            de: Batch1_Card9_Back_DE,
            ro: Batch1_Card9_Back_RO,
            bg: Batch1_Card9_Back_BG,
            hr: Batch1_Card9_Back_HR,
            cz: Batch1_Card9_Back_CZ,
            ho: Batch1_Card9_Back_HO,
            fr: Batch1_Card9_Back_FR,
            hu: Batch1_Card9_Back_HU,
            it: Batch1_Card9_Back_IT,
            mx: Batch1_Card9_Back_MX,
            no: Batch1_Card9_Back_NO,
            si: Batch1_Card9_Back_SI,
            rs: Batch1_Card9_Back_RS,
            se: Batch1_Card9_Back_SE,
            tr: Batch1_Card9_Back_TR,
            us: Batch1_Card9_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 10,
        frontSvg: Batch1_Card10_Front,
        frontTitle: {
            en: 'First contact',
            de: 'Erstkontakt',
            ro: 'Primul Contact',
            bg: 'Поставяне на\n' +
                'цели',
            hr: 'Prvi Kontakt',
            cz: 'První Kontakt',
            ho: 'Eerste contact',
            fr: 'Premier Contact',
            hu: 'Kapcsolatfelvétel',
            it: 'Primo Contatto',
            mx: 'Primer Contacto',
            no: 'Første Kontakt',
            si: 'Prvi Stik',
            rs: 'Prvi Kontakt',
            se: 'Första Kontakten',
            tr: 'İlk Temas',
            us: 'First contact',
        },
        frontBottomText: {
            en: 'You only get one chance to make your first impression',
            de: 'Für den ersten Eindruck gibt es keine zweite Chance',
            ro: 'Există o singură șansă pentru a produce prima\n' +
                'impresie',
            bg: 'Направи ги SMART!',
            hr: 'Imamo samo jednu priliku za prvi dojam',
            cz: 'Máš jenom jednu šanci udělat první dojem',
            ho: 'Je hebt één kans om een goede eerste indruk te\n' +
                'maken',
            fr: 'Vous n’avez qu’une seule chance de faire votre\n' +
                'première impression',
            hu: 'Csak egy esélyed van az első benyomásra',
            it: 'C’è soltanto una opportunità per fare la prima\n' +
                'impressione',
            mx: 'Solo tienes una oportunidad para causar la primera\n' +
                'impresión',
            no: 'Du har kun en mulighet til å skape et førsteinntrykk',
            si: 'Imamo samo eno možnost za prvi vtis',
            rs: 'Imamo samo jednu priliku da ostavimo prvi utisak',
            se: 'Du får bara en chans att göra ett första intryck',
            tr: 'İlk izlenim için sadece bir şansınız var',
            us: 'We have only one chance to make our first\n' +
                'impression',
        },
        backImage: {
            en: Batch1_Card10_Back_EN,
            de: Batch1_Card10_Back_DE,
            ro: Batch1_Card10_Back_RO,
            bg: Batch1_Card10_Back_BG,
            hr: Batch1_Card10_Back_HR,
            cz: Batch1_Card10_Back_CZ,
            ho: Batch1_Card10_Back_HO,
            fr: Batch1_Card10_Back_FR,
            hu: Batch1_Card10_Back_HU,
            it: Batch1_Card10_Back_IT,
            mx: Batch1_Card10_Back_MX,
            no: Batch1_Card10_Back_NO,
            si: Batch1_Card10_Back_SI,
            rs: Batch1_Card10_Back_RS,
            se: Batch1_Card10_Back_SE,
            tr: Batch1_Card10_Back_TR,
            us: Batch1_Card10_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 11,
        frontSvg: Batch1_Card11_Front,
        frontTitle: {
            en: 'Motivation',
            de: 'Motivieren',
            ro: 'Motivarea',
            bg: 'Първи контакт',
            hr: 'Motivacija',
            cz: 'Motivace',
            ho: 'Motivatie',
            fr: 'Motivation',
            hu: 'Motiváció',
            it: 'Motivazione',
            mx: 'Motivación',
            no: 'Motivasjon',
            si: 'Motivacija',
            rs: 'Motivacija',
            se: 'Motivation',
            tr: 'Motivasyon',
            us: 'Motivation',
        },
        frontBottomText: {
            en: 'Our own idea is one hundred times better than the best idea of our manager',
            de: 'Mitarbeitern ist ihre eigene Idee hundert mal lieber als die beste Idee ihrer Chefs',
            ro: 'Membrii echipei apreciază propriile idei mult mai mult decât cea mai bună idee a managerului',
            bg: 'Имаш само един шанс да направиш първо впечатление',
            hr: 'Suradnici vole vlastite ideje sto puta više od ideja njihovih nadređenih',
            cz: 'Spolupracovníci mají svou myšlenku stokrát radši než je nejlepší myšlenka jejich šéfa',
            ho: 'Medewerkers vinden hun eigen idee 10x beter dan het (beste) idee van de leidinggevende',
            fr: 'Le collaborateur préfère son idée cent-fois plus que la meilleure idée de son chef',
            hu: 'A csoport tagjainak százszor jobban tetszik a saját ötletük, mint a főnökük legjobb ötlete',
            it: 'Ai collaboratori la propria idea piace cento volte di più della migliore idea del capo',
            mx: 'Los colaboradores prefieren cien veces más su propia idea que la mejor idea de su jefe',
            no: 'Medarbeidere liker sine egen ide hundre ganger bedre enn sjefens beste ide',
            si: 'Moja ideja mi je stokrat ljubša kot tvoja',
            rs: 'Saradnici vrednuju svoju ideju mnogo više od najbolje ideje svog menadžera',
            se: 'Medarbetaren uppfattar sin ide som hundra gånger bättre än chefens bästa',
            tr: 'Ekip üyeleri, kendi fikirlerini, yöneticilerinin en iyi fikirlerinden yüz kat daha çok severler.',
            us: 'Team members like their own idea one hundred times better than the best idea of their manager',
        },
        backImage: {
            en: Batch1_Card11_Back_EN,
            de: Batch1_Card11_Back_DE,
            ro: Batch1_Card11_Back_RO,
            bg: Batch1_Card11_Back_BG,
            hr: Batch1_Card11_Back_HR,
            cz: Batch1_Card11_Back_CZ,
            ho: Batch1_Card11_Back_HO,
            fr: Batch1_Card11_Back_FR,
            hu: Batch1_Card11_Back_HU,
            it: Batch1_Card11_Back_IT,
            mx: Batch1_Card11_Back_MX,
            no: Batch1_Card11_Back_NO,
            si: Batch1_Card11_Back_SI,
            rs: Batch1_Card11_Back_RS,
            se: Batch1_Card11_Back_SE,
            tr: Batch1_Card11_Back_TR,
            us: Batch1_Card11_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 12,
        frontSvg: Batch1_Card12_Front,
        frontTitle: {
            en: 'Leadership -\n' +
                'Four Principles',
            de: '4 Führungs- grundsätze',
            ro: 'Leadership - 4\n' +
                'Principii',
            bg: 'Мотивация',
            hr: 'Vođenje - 4\n' +
                'Principa',
            cz: 'Leadership - 4\n' +
                'Zásady Vedení',
            ho: 'De 4 Beginselen\n' +
                'van het\n' +
                'leidinggeven',
            fr: 'Leadership - 4\n' +
                'Principes',
            hu: 'Leadership - 4\n' +
                'Alapelv',
            it: 'Leadership - i 4\n' +
                'Principi',
            mx: 'Liderazgo - 4\n' +
                'Principios',
            no: 'Ledelse - 4\n' +
                'Grunnprinsipper',
            si: '4 Zakoni Vodenja',
            rs: 'Liderstvo - 4\n' +
                'Principa',
            se: 'De 4\n' +
                'Ledarprinciperna',
            tr: 'Liderlik - 4\n' +
                'Prensibi',
            us: 'Leadership - 4\n' +
                'Principles',
        },
        frontBottomText: {
            en: 'How balanced is your leadership style?',
            de: 'Achte ich auf Balance in meinem Führungsstil?',
            ro: 'Ai un stil de leadership echilibrat?',
            bg: 'Членовете на екипа харесват собствената си идея сто пъти повече от най-добрата идея на своя мениджър',
            hr: 'Je li vaš stil vođenja uravnotežen?',
            cz: 'Je tvůj styl vedení lidí vyvážený?',
            ho: 'Is jouw leiderschapsstijl in balans?',
            fr: 'Votre style de leadership est-il équilibré?',
            hu: 'A vezetői stílusod kiegyensúlyozott?',
            it: 'E’ il mio stile di leadership bilanciato?',
            mx: '¿Tu estilo de liderazgo está balanceado?',
            no: 'Er din lederstil balansert?',
            si: 'Ali je moj stil vodenja uravnotežen?',
            rs: 'Da li je tvoj stil liderstva izbalansiran?',
            se: 'Är din ledarstil i balans?',
            tr: 'Liderlik tarzınız dengeli mi?',
            us: 'Is your leadership style balanced?',
        },
        backImage: {
            en: Batch1_Card12_Back_EN,
            de: Batch1_Card12_Back_DE,
            ro: Batch1_Card12_Back_RO,
            bg: Batch1_Card12_Back_BG,
            hr: Batch1_Card12_Back_HR,
            cz: Batch1_Card12_Back_CZ,
            ho: Batch1_Card12_Back_HO,
            fr: Batch1_Card12_Back_FR,
            hu: Batch1_Card12_Back_HU,
            it: Batch1_Card12_Back_IT,
            mx: Batch1_Card12_Back_MX,
            no: Batch1_Card12_Back_NO,
            si: Batch1_Card12_Back_SI,
            rs: Batch1_Card12_Back_RS,
            se: Batch1_Card12_Back_SE,
            tr: Batch1_Card12_Back_TR,
            us: Batch1_Card12_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 13,
        frontSvg: Batch1_Card13_Front,
        frontTitle: {
            en: 'Meetings',
            de: 'Meetings',
            ro: 'Ședința',
            bg: 'Лидесрство - 4\n' +
                'принципа',
            hr: 'Sastanci',
            cz: 'Porady',
            ho: 'Vergaderen',
            fr: 'Réunions',
            hu: 'Megbeszélések',
            it: 'Riunioni',
            mx: 'Juntas',
            no: 'Møter',
            si: 'Sestanki',
            rs: 'Sastanci',
            se: 'Möten',
            tr: 'Toplantılar',
            us: 'Meetings',
        },
        frontBottomText: {
            en: 'Are you recognising the heroes?',
            de: 'Lasse ich jeden Held sein?',
            ro: 'Recunoști eroii?',
            bg: 'Лидерският ти стил балансиран ли е?',
            hr: 'Prepoznajemo li heroje?',
            cz: 'Vyzdvihujeme hrdiny na poradě?',
            ho: '(H)erken je de helden?',
            fr: 'Valorisez-vous les héros?',
            hu: 'Elismered a hősöket?',
            it: 'Sto riconoscendo gli eroi?',
            mx: '¿Estás dando reconocimiento a los héroes?',
            no: 'Gir du alle mulighetet til å være helter?',
            si: 'Ali prepoznamo junake?',
            rs: 'Da li prepoznajemo heroje?',
            se: 'Ger vi alla möjligheten till att vara en hjälte?',
            tr: 'Kahramanları (hero) tanıyor musun?',
            us: 'Are we recognizing the heros?',
        },
        backImage: {
            en: Batch1_Card13_Back_EN,
            de: Batch1_Card13_Back_DE,
            ro: Batch1_Card13_Back_RO,
            bg: Batch1_Card13_Back_BG,
            hr: Batch1_Card13_Back_HR,
            cz: Batch1_Card13_Back_CZ,
            ho: Batch1_Card13_Back_HO,
            fr: Batch1_Card13_Back_FR,
            hu: Batch1_Card13_Back_HU,
            it: Batch1_Card13_Back_IT,
            mx: Batch1_Card13_Back_MX,
            no: Batch1_Card13_Back_NO,
            si: Batch1_Card13_Back_SI,
            rs: Batch1_Card13_Back_RS,
            se: Batch1_Card13_Back_SE,
            tr: Batch1_Card13_Back_TR,
            us: Batch1_Card13_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 14,
        frontSvg: Batch1_Card14_Front,
        frontTitle: {
            en: 'Sales\n' +
                'Presentation',
            de: 'Verkaufs\n' +
                '-präsentation',
            ro: 'Prezentarea\n' +
                'Afacerii',
            bg: 'Срещи',
            hr: 'Prodajna\n' +
                'Prezentacija',
            cz: 'Obchodní\n' +
                'Prezentace',
            ho: 'Verkoop\n' +
                'Presentatie',
            fr: 'Présentation de\n' +
                'Vente',
            hu: 'Értékesítési\n' +
                'Prezentáció',
            it: 'Presentazione\n' +
                'Vendite',
            mx: 'Presentación de\n' +
                'Ventas',
            no: 'Salgspresentasjon',
            si: 'Prodajna\n' +
                'Predstavitev',
            rs: 'Prodajna\n' +
                'Prezentacija',
            se: 'Försäljnings\n' +
                'Presentation',
            tr: 'Satış Sunumu',
            us: 'Sales\n' +
                'Presentation',
        },
        frontBottomText: {
            en: 'What is your audience really interested in?',
            de: 'Wofür interessiert sich mein Teilnehmerkreis\n' +
                'wirklich?',
            ro: 'Care este interesul real al audienței?',
            bg: 'Разпознаваш ли героите?',
            hr: 'Što publiku stvarno zanima?',
            cz: 'O co se vaše publikum opravdu zajímá?',
            ho: 'In wie is men het meest geïnteresseerd?',
            fr: 'Qu’est-ce qui intéresse vraiment votre audience?',
            hu: 'Mi érdekli különösen a hallgatóságodat?',
            it: 'A chi si interessano realmente gli ascoltatori?',
            mx: '¿En qué se interesa realmente tu audiencia?',
            no: 'Hva er tilhørerne virkelig opptatt av?',
            si: 'Kaj zanima naše občinstvo?',
            rs: 'Šta našu publiku jedino interesuje?',
            se: 'Vad är din publik verkligen intresserade av?',
            tr: 'Hedef kitleniz gerçekten neyle ilgileniyor?',
            us: 'What is our audience really interested in?',
        },
        backImage: {
            en: Batch1_Card14_Back_EN,
            de: Batch1_Card14_Back_DE,
            ro: Batch1_Card14_Back_RO,
            bg: Batch1_Card14_Back_BG,
            hr: Batch1_Card14_Back_HR,
            cz: Batch1_Card14_Back_CZ,
            ho: Batch1_Card14_Back_HO,
            fr: Batch1_Card14_Back_FR,
            hu: Batch1_Card14_Back_HU,
            it: Batch1_Card14_Back_IT,
            mx: Batch1_Card14_Back_MX,
            no: Batch1_Card14_Back_NO,
            si: Batch1_Card14_Back_SI,
            rs: Batch1_Card14_Back_RS,
            se: Batch1_Card14_Back_SE,
            tr: Batch1_Card14_Back_TR,
            us: Batch1_Card14_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 15,
        frontSvg: Batch1_Card15_Front,
        frontTitle: {
            en: 'Praise',
            de: 'Loben',
            ro: 'Lauda',
            bg: 'Търговска\n' +
                'презентация',
            hr: 'Pohvala',
            cz: 'Pochvala',
            ho: 'Complimenten\n' +
                'geven',
            fr: 'Féliciter',
            hu: 'Dícsérni',
            it: 'Lodare',
            mx: 'Elogiar',
            no: 'Ros',
            si: 'Pohvala',
            rs: 'Pohvala',
            se: 'Ros',
            tr: 'Övgü',
            us: 'Praise',
        },
        frontBottomText: {
            en: 'Praise consciously every day',
            de: 'Täglich bewusst loben',
            ro: 'Laudă, în mod conștient, în fiecare zi',
            bg: 'От какво наистина се интересува твоята аудитория?',
            hr: 'Pohvalimo svjesno svakoga dana',
            cz: 'Chvalte vědomě každý den',
            ho: 'Geef bewust complimenten',
            fr: 'Félicitez chaque jour consciemment',
            hu: 'Naponta tudatosan dícsérni',
            it: 'Lodare consapevolmente ogni giorno',
            mx: 'Elogie conscientemente todos los días',
            no: 'Ros bevisst hver dag',
            si: 'Zavestno hvalimo vsak dan',
            rs: 'Pohvalimo svesno svakoga dana',
            se: 'Ge Ros medvetet dagligen',
            tr: 'Her gün bilinçli olarak öv',
            us: 'Praise consciously every day',
        },
        backImage: {
            en: Batch1_Card15_Back_EN,
            de: Batch1_Card15_Back_DE,
            ro: Batch1_Card15_Back_RO,
            bg: Batch1_Card15_Back_BG,
            hr: Batch1_Card15_Back_HR,
            cz: Batch1_Card15_Back_CZ,
            ho: Batch1_Card15_Back_HO,
            fr: Batch1_Card15_Back_FR,
            hu: Batch1_Card15_Back_HU,
            it: Batch1_Card15_Back_IT,
            mx: Batch1_Card15_Back_MX,
            no: Batch1_Card15_Back_NO,
            si: Batch1_Card15_Back_SI,
            rs: Batch1_Card15_Back_RS,
            se: Batch1_Card15_Back_SE,
            tr: Batch1_Card15_Back_TR,
            us: Batch1_Card15_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 16,
        frontSvg: Batch1_Card16_Front,
        frontTitle: {
            en: 'Selling',
            de: 'Verkaufen',
            ro: 'Vânzarea',
            bg: 'Похвала',
            hr: 'Prodaja',
            cz: 'Prodej',
            ho: 'Verkopen',
            fr: 'Vendre',
            hu: 'Értékesítés',
            it: 'Vendere',
            mx: 'Vender',
            no: 'Salg',
            si: 'Prodaja',
            rs: 'Prodavati',
            se: 'Sälja',
            tr: 'Satış',
            us: 'Selling',
        },
        frontBottomText: {
            en: 'Be proud of your ability to influence and induce others to act',
            de: 'Sei stolz auf Deine Fähigkeit, andere zu beeinflussen und zu Taten zu veranlassen',
            ro: 'Fii mândru de abilitatea de a influența și a induce acțiune',
            bg: 'Хвали съзнателно всеки ден',
            hr: 'Ponosimo se svojom sposobnošću poticanja drugih na akciju',
            cz: 'Buď hrdý na svou schopnost působit na druhé a přimět je jednat',
            ho: 'Wees trots op de persoonlijke macht om te kunnen beïnvloeden en anderen in actie te krijgen',
            fr: 'Soyez fier de votre pouvoir d’influencer et d’inciter autrui à l’action',
            hu: 'Légy büszke a befolyásoló képességedre és mások tettre ösztönzésére',
            it: 'Essere orgogliosi della propria capacità di influenzare e di indurre ad agire gli altri',
            mx: 'Siéntete orgulloso de tu habilidad para influir e inducir a otros a actuar',
            no: 'Vær stolt av din evne til å påvirke andre til å handle',
            si: 'Bodimo ponosni na svojo moč vplivanja in spodbujanja drugih k dejanjem',
            rs: 'Budite ponosni na svoju sposobnost da utičete i navedemo druge na akciju',
            se: 'Var stolt över din förmåga att påverka och få andra till att agera',
            tr: 'Başkalarını etkileme ve onları harekete geçirme yeteneğinizle gurur duyun',
            us: 'Be proud of the ability to influence and to induce others to act',
        },
        backImage: {
            en: Batch1_Card16_Back_EN,
            de: Batch1_Card16_Back_DE,
            ro: Batch1_Card16_Back_RO,
            bg: Batch1_Card16_Back_BG,
            hr: Batch1_Card16_Back_HR,
            cz: Batch1_Card16_Back_CZ,
            ho: Batch1_Card16_Back_HO,
            fr: Batch1_Card16_Back_FR,
            hu: Batch1_Card16_Back_HU,
            it: Batch1_Card16_Back_IT,
            mx: Batch1_Card16_Back_MX,
            no: Batch1_Card16_Back_NO,
            si: Batch1_Card16_Back_SI,
            rs: Batch1_Card16_Back_RS,
            se: Batch1_Card16_Back_SE,
            tr: Batch1_Card16_Back_TR,
            us: Batch1_Card16_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 17,
        frontSvg: Batch1_Card17_Front,
        frontTitle: {
            en: 'Checking Up',
            de: 'Kontrollieren',
            ro: 'Controlul',
            bg: 'Похвала',
            hr: 'Provjeravamo',
            cz: 'Kontrola',
            ho: 'Controleren',
            fr: 'Contrôler',
            hu: 'Ellenőrzés',
            it: 'Controllare',
            mx: 'Controlar',
            no: 'Kontroll',
            si: 'Kontroliranje',
            rs: 'Proveravanje',
            se: 'Kontroll',
            tr: 'Kontrol Etmek',
            us: 'Checking Up',
        },
        frontBottomText: {
            en: 'Find reasons to praise',
            de: 'Kontrollieren um zu loben',
            ro: 'Pentru a găsi motive de laudă',
            bg: 'Хвали съзнателно всеки ден',
            hr: 'da bismo našli razloge za pohvalu',
            cz: 'Hledej důvody k pochvale',
            ho: 'Om te kunnen prijzen',
            fr: 'Trouvez des raisons de féliciter',
            hu: 'Találj okot a dícsérésre',
            it: 'Per scoprire motivi per lodare',
            mx: 'Con el fin de encontrar razones para elogiar',
            no: 'For å finne en anledning til å gi ros',
            si: 'Kontroliramo, da pohvalimo',
            rs: 'Sa ciljem da pronađemo razloge za pohvalu',
            se: 'För att hitta en anledning till Beröm',
            tr: 'Övgüye nedenler bulmak için',
            us: 'In order to find things to praise',
        },
        backImage: {
            en: Batch1_Card17_Back_EN,
            de: Batch1_Card17_Back_DE,
            ro: Batch1_Card17_Back_RO,
            bg: Batch1_Card17_Back_BG,
            hr: Batch1_Card17_Back_HR,
            cz: Batch1_Card17_Back_CZ,
            ho: Batch1_Card17_Back_HO,
            fr: Batch1_Card17_Back_FR,
            hu: Batch1_Card17_Back_HU,
            it: Batch1_Card17_Back_IT,
            mx: Batch1_Card17_Back_MX,
            no: Batch1_Card17_Back_NO,
            si: Batch1_Card17_Back_SI,
            rs: Batch1_Card17_Back_RS,
            se: Batch1_Card17_Back_SE,
            tr: Batch1_Card17_Back_TR,
            us: Batch1_Card17_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 18,
        frontSvg: Batch1_Card18_Front,
        frontTitle: {
            en: 'Complaints',
            de: 'Reklamationen',
            ro: 'Reclamațiile',
            bg: 'Продаване',
            hr: 'Pritužbe',
            cz: 'Reklamace',
            ho: 'Klachten',
            fr: 'Réclamations',
            hu: 'Reklamáció',
            it: 'Reclami',
            mx: 'Reclamaciones',
            no: 'Reklamasjoner',
            si: 'Pritožbe',
            rs: 'Pritužbe',
            se: 'Reklamation',
            tr: 'Şikayetler',
            us: 'Complaints',
        },
        frontBottomText: {
            en: 'See them as personal opportunities',
            de: 'Betrachte sie als persönliche Chancen',
            ro: 'Sunt oportunități personale',
            bg: 'Гордей се със способността си да влияеш и да подтикваш другите да действат',
            hr: 'Pritužba = prilika',
            cz: 'Ber ji jako osobní příležitost',
            ho: 'Een klacht is een persoonlijke kans',
            fr: 'Considérez-les comme des opportunités personnelles',
            hu: 'Vedd észre a személyes lehetőséget',
            it: 'Vederli come opportunità personali',
            mx: 'Son oportunidades personales',
            no: 'Se på det som personlige sjanser',
            si: 'Prepoznajmo jih kot osebne priložnosti',
            rs: 'Vidimo ih kao lične mogućnosti',
            se: 'Se det som en personlig möjlighet',
            tr: 'Onları kişisel fırsatlar olarak görün',
            us: 'See them as personal opportunities',
        },
        backImage: {
            en: Batch1_Card18_Back_EN,
            de: Batch1_Card18_Back_DE,
            ro: Batch1_Card18_Back_RO,
            bg: Batch1_Card18_Back_BG,
            hr: Batch1_Card18_Back_HR,
            cz: Batch1_Card18_Back_CZ,
            ho: Batch1_Card18_Back_HO,
            fr: Batch1_Card18_Back_FR,
            hu: Batch1_Card18_Back_HU,
            it: Batch1_Card18_Back_IT,
            mx: Batch1_Card18_Back_MX,
            no: Batch1_Card18_Back_NO,
            si: Batch1_Card18_Back_SI,
            rs: Batch1_Card18_Back_RS,
            se: Batch1_Card18_Back_SE,
            tr: Batch1_Card18_Back_TR,
            us: Batch1_Card18_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 19,
        frontSvg: Batch1_Card19_Front,
        frontTitle: {
            en: 'Addressing the\n' +
                'Uncomfortable',
            de: 'Unangenehmes\n' +
                'ansprechen',
            ro: 'Adresarea\n' +
                'Inconfortabilului',
            bg: 'Проверката',
            hr: 'Kako Priopćiti\n' +
                'Neugodno',
            cz: 'Řešení\n' +
                'Nepříjemného',
            ho: 'Onaangename of\n' +
                'pijnlijke Zaken\n' +
                'bespreken',
            fr: 'Aborder des\n' +
                'Sujets Délicats',
            hu: 'A Kellemetlen\n' +
                'kezelése',
            it: 'Affrontare lo\n' +
                'Spiacevole',
            mx: 'Abordar lo\n' +
                'Incómodo',
            no: 'Den Ubehagelige\n' +
                'Samtalen',
            si: 'Nagovor\n' +
                'Neprijetnega',
            rs: 'Razgovor\n' +
                'o Neprijatnim\n' +
                'Temama',
            se: 'Obehagligt\n' +
                'Samtal',
            tr: 'Rahatsız edici bir\n' +
                'durumun Tesbiti',
            us: 'Addressing the\n' +
                'Uncomfortable',
        },
        frontBottomText: {
            en: 'Feel the fear and do it anyway\n' +
                'Believe that positive change can happen',
            de: 'Habe den Mut\n' +
                'Vertraue darauf, dass positive Veränderung möglich ist',
            ro: 'Ai curaj.\n' +
                'Crede că schimbările pozitive se pot face',
            bg: 'С цел да намерим поводи за похвала',
            hr: 'Skupimo hrabrost.\n' +
                'Vjerujmo u pozitivan ishod.',
            cz: 'Seber odvahu\n' +
                'Věř, že pozitivní změna je možná',
            ho: 'Heb de moed!\n' +
                'Vertrouw op een positieve verandering',
            fr: 'Ressentez la peur et faites-le quand même\n' +
                'Ayez confiance que des changements positifs\n' +
                'peuvent se produire',
            hu: 'Legyen bátorságod\n' +
                'Bízz a pozitív változás lehetőségében',
            it: 'Avere il coraggio\n' +
                'Credere che possa avvenire un cambiamento positivo',
            mx: 'Ten valor.\n' +
                'Confía en que el cambio positivo puede suceder.',
            no: 'Ha mot.\n' +
                'Tro på at positiv endring kan skje.',
            si: 'Zberimo pogum.\n' +
                'Verjemimo, da jim bomo pomagali.',
            rs: 'Imajmo hrabrost.\n' +
                'Verujemo da se mogu desiti pozitivne promene.',
            se: 'Ha mod\n' +
                'Tro på att det positiva kan hända',
            tr: 'Cesur ol\n' +
                'Olumlu değişimin olabileceğine güvenin',
            us: 'Have the courage.\n' +
                'Trust that positive change can happen.',
        },
        backImage: {
            en: Batch1_Card19_Back_EN,
            de: Batch1_Card19_Back_DE,
            ro: Batch1_Card19_Back_RO,
            bg: Batch1_Card19_Back_BG,
            hr: Batch1_Card19_Back_HR,
            cz: Batch1_Card19_Back_CZ,
            ho: Batch1_Card19_Back_HO,
            fr: Batch1_Card19_Back_FR,
            hu: Batch1_Card19_Back_HU,
            it: Batch1_Card19_Back_IT,
            mx: Batch1_Card19_Back_MX,
            no: Batch1_Card19_Back_NO,
            si: Batch1_Card19_Back_SI,
            rs: Batch1_Card19_Back_RS,
            se: Batch1_Card19_Back_SE,
            tr: Batch1_Card19_Back_TR,
            us: Batch1_Card19_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 20,
        frontSvg: Batch1_Card20_Front,
        frontTitle: {
            en: 'Preparation',
            de: 'Vorbereitung',
            ro: 'Pregătirea',
            bg: 'Оплаквания',
            hr: 'Priprema',
            cz: 'Příprava',
            ho: 'Voorbereiding',
            fr: 'Préparation',
            hu: 'Felkészülés',
            it: 'Preparazione',
            mx: 'Preparación',
            no: 'Forberedelser',
            si: 'Priprava',
            rs: 'Priprema',
            se: 'Förberedelse',
            tr: 'Hazırlık',
            us: 'Preparation',
        },
        frontBottomText: {
            en: 'Preparation is 90% of success',
            de: '90% des Erfolges ist Vorbereitung',
            ro: 'Pregătirea este 90% din succes',
            bg: 'Гледай на тях като на лични възможности',
            hr: 'Priprema je 90% uspjeha',
            cz: 'Příprava je 90% úspěchu',
            ho: 'Voorbereiding = 90% van het succes',
            fr: '90 % du succès est dû à la préparation',
            hu: 'Felkészülés a siker 90%-a',
            it: 'La preparazione è il 90% del successo',
            mx: 'La preparación es el 90% del éxito',
            no: 'Forberedelser er 90 % av suksessen',
            si: 'Priprava je 90 % uspeha',
            rs: 'Priprema je 90% uspeha',
            se: '90% av vår succe beror på förberedelse',
            tr: 'Başarının %90’ı hazırlıktır',
            us: 'Preparation is 90% of success',
        },
        backImage: {
            en: Batch1_Card20_Back_EN,
            de: Batch1_Card20_Back_DE,
            ro: Batch1_Card20_Back_RO,
            bg: Batch1_Card20_Back_BG,
            hr: Batch1_Card20_Back_HR,
            cz: Batch1_Card20_Back_CZ,
            ho: Batch1_Card20_Back_HO,
            fr: Batch1_Card20_Back_FR,
            hu: Batch1_Card20_Back_HU,
            it: Batch1_Card20_Back_IT,
            mx: Batch1_Card20_Back_MX,
            no: Batch1_Card20_Back_NO,
            si: Batch1_Card20_Back_SI,
            rs: Batch1_Card20_Back_RS,
            se: Batch1_Card20_Back_SE,
            tr: Batch1_Card20_Back_TR,
            us: Batch1_Card20_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 21,
        frontSvg: Batch1_Card21_Front,
        frontTitle: {
            en: 'Closing the Sale',
            de: 'Abschließen',
            ro: 'Încheierea\n' +
                'Afacerii',
            bg: 'Адресирай\n' +
                'неудобните\n' +
                'въпроси',
            hr: 'Zaključivanje\n' +
                'Prodaje',
            cz: 'Uzavírání\n' +
                'Obchodu',
            ho: 'Afsluittechniek',
            fr: 'Conclure la Vente',
            hu: 'Az Értékesítés\n' +
                'Lezárása',
            it: 'Concludere la\n' +
                'Vendita',
            mx: 'Cierre de Ventas',
            no: 'Avslutning',
            si: 'Zaključevanje\n' +
                'Prodaje',
            rs: 'Zatvaranje\n' +
                'Prodaje',
            se: 'Avslut',
            tr: 'Satışı Kapatmak',
            us: 'Closing the Sale',
        },
        frontBottomText: {
            en: 'Help them to commit to the right decision',
            de: 'Den Kunden über die Schwelle führen',
            ro: 'Ajută-ți clienții să se decidă',
            bg: 'Имай смелостта.\n' +
                'Вярвай, че може да има промяна към по-добро.',
            hr: 'Pomozimo im prevladati prepreke',
            cz: 'Veď je krok za krokem k objednávce',
            ho: 'Help hen om het laatste stapje te nemen',
            fr: 'Aidez-les à prendre la bonne décision',
            hu: 'Vezesd őket az elkötelezettség felé',
            it: 'Guidare verso il passo dell’impegno',
            mx: 'Guíalos a dar el paso sobre el compromiso.',
            no: 'Hjelp kunden over terskelen til å forplikte seg',
            si: 'Vodimo jih do odločitve za sodelovanje',
            rs: 'Vodimo ih korak po korak do obaveze',
            se: 'Hjälp kunden över tröskeln till förpliktelse',
            tr: 'Taahhüt adımına kadar onlara rehberlik edin',
            us: 'Guide them over the step to commitment',
        },
        backImage: {
            en: Batch1_Card21_Back_EN,
            de: Batch1_Card21_Back_DE,
            ro: Batch1_Card21_Back_RO,
            bg: Batch1_Card21_Back_BG,
            hr: Batch1_Card21_Back_HR,
            cz: Batch1_Card21_Back_CZ,
            ho: Batch1_Card21_Back_HO,
            fr: Batch1_Card21_Back_FR,
            hu: Batch1_Card21_Back_HU,
            it: Batch1_Card21_Back_IT,
            mx: Batch1_Card21_Back_MX,
            no: Batch1_Card21_Back_NO,
            si: Batch1_Card21_Back_SI,
            rs: Batch1_Card21_Back_RS,
            se: Batch1_Card21_Back_SE,
            tr: Batch1_Card21_Back_TR,
            us: Batch1_Card21_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 22,
        frontSvg: Batch1_Card22_Front,
        frontTitle: {
            en: 'Price\n' +
                'Negotiation',
            de: 'Preisverhandlung',
            ro: 'Negocierea\n' +
                'Prețului',
            bg: 'Подготовка',
            hr: 'Pregovori o\n' +
                'Cijeni',
            cz: 'Cenové\n' +
                'Vyjednávání',
            ho: 'Prijsonderhandeling',
            fr: 'Négociation\n' +
                'de Prix',
            hu: 'Ártárgyalás',
            it: 'Negoziazione\n' +
                'del Prezzo',
            mx: 'Negociación\n' +
                'del Precio',
            no: 'Prisforhandlinger',
            si: 'Cena',
            rs: 'Razgovor o Ceni',
            se: 'Prisförhandling',
            tr: 'Fiyat Pazarlığı',
            us: 'Price\n' +
                'Negotiation',
        },
        frontBottomText: {
            en: 'The weight of the price is a matter of perception',
            de: 'Der Preis ist eine Frage der Wahrnehmung',
            ro: 'Prețul este o problemă de percepție',
            bg: 'Подготовката е 90% от успеха',
            hr: 'Cijena je stvar percepcije',
            cz: 'Váha ceny je otázkou představy.',
            ho: 'Prijs is de voorstelling die je ervan hebt',
            fr: 'Le poids du prix est une question de perception',
            hu: 'Az ár az elképzelés függvénye',
            it: 'Il prezzo è una questione di visione',
            mx: 'El precio es un asunto de percepción',
            no: 'Pris er et spørsmål om forestilling',
            si: 'Višina cene je stvar percepcije',
            rs: 'Cena je stvar percepcije',
            se: 'Pris är en fråga om föreställning',
            tr: 'Fiyat bir algı meselesidir',
            us: 'Price is a matter of perception',
        },
        backImage: {
            en: Batch1_Card22_Back_EN,
            de: Batch1_Card22_Back_DE,
            ro: Batch1_Card22_Back_RO,
            bg: Batch1_Card22_Back_BG,
            hr: Batch1_Card22_Back_HR,
            cz: Batch1_Card22_Back_CZ,
            ho: Batch1_Card22_Back_HO,
            fr: Batch1_Card22_Back_FR,
            hu: Batch1_Card22_Back_HU,
            it: Batch1_Card22_Back_IT,
            mx: Batch1_Card22_Back_MX,
            no: Batch1_Card22_Back_NO,
            si: Batch1_Card22_Back_SI,
            rs: Batch1_Card22_Back_RS,
            se: Batch1_Card22_Back_SE,
            tr: Batch1_Card22_Back_TR,
            us: Batch1_Card22_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 23,
        frontSvg: Batch1_Card23_Front,
        frontTitle: {
            en: 'Calling',
            de: 'Telefonieren',
            ro: 'Apelul Telefonic',
            bg: 'Приключване\n' +
                'на продажбата',
            hr: 'Telefoniranje',
            cz: 'Telefonování',
            ho: 'Telefoneren',
            fr: 'Téléphoner',
            hu: 'Telefonálás',
            it: 'Telefonare',
            mx: 'Telefonear',
            no: 'Å Ringe',
            si: 'Telefoniranje',
            rs: 'Pozivi',
            se: 'Ringa',
            tr: 'Telefon Çağrısı',
            us: 'Calling',
        },
        frontBottomText: {
            en: 'Building bridges on the phone',
            de: 'Brückenschlag am Telefon',
            ro: 'A stabili punți prin telefon',
            bg: 'Преведи ги през стъпките до ангажиране',
            hr: 'Gradnja mostova putem telefona',
            cz: 'Stavění mostů po telefonu',
            ho: 'Een persoonlijke brug slaan',
            fr: 'Jeter des ponts au téléphone',
            hu: 'Hídépítés telefonon keresztül',
            it: 'Costruire ponti al telefono',
            mx: 'Construye puentes por teléfono',
            no: 'Bygge bro over telefon',
            si: 'Gradnja mostov po telefonu',
            rs: 'Gradimo mostove preko telefona',
            se: 'Bygga broar genom telefon',
            tr: 'Telefonda köprü kurmak',
            us: 'Building bridges on the phone',
        },
        backImage: {
            en: Batch1_Card23_Back_EN,
            de: Batch1_Card23_Back_DE,
            ro: Batch1_Card23_Back_RO,
            bg: Batch1_Card23_Back_BG,
            hr: Batch1_Card23_Back_HR,
            cz: Batch1_Card23_Back_CZ,
            ho: Batch1_Card23_Back_HO,
            fr: Batch1_Card23_Back_FR,
            hu: Batch1_Card23_Back_HU,
            it: Batch1_Card23_Back_IT,
            mx: Batch1_Card23_Back_MX,
            no: Batch1_Card23_Back_NO,
            si: Batch1_Card23_Back_SI,
            rs: Batch1_Card23_Back_RS,
            se: Batch1_Card23_Back_SE,
            tr: Batch1_Card23_Back_TR,
            us: Batch1_Card23_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 24,
        frontSvg: Batch1_Card24_Front,
        frontTitle: {
            en: 'Managing\n' +
                'Underperformance',
            de: 'Tadeln',
            ro: 'Gestiunea\n' +
                'Neperformanței',
            bg: 'Преговори за\n' +
                'цена',
            hr: 'Upozoravanje\n' +
                'na Greške',
            cz: 'Vytýkání',
            ho: 'Omgaan\n' +
                'met slechte\n' +
                'Resultaten',
            fr: 'Gérer la SousPerformance',
            hu: 'Az Alulteljesítés\n' +
                'kezelése',
            it: 'Biasimare',
            mx: 'Gestión del Bajo\n' +
                'Rendimiento',
            no: 'Ledelse ved\n' +
                'Avvik',
            si: 'Grajanje',
            rs: 'Upravljanje\n' +
                'Slabim\n' +
                'Performansama',
            se: 'Ledarskap vid\n' +
                'Underprestation',
            tr: 'Düşük\n' +
                'Performansı\n' +
                'Yönetme',
            us: 'Managing\n' +
                'Underperformance',
        },
        frontBottomText: {
            en: 'Admonish in order to motivate',
            de: 'Tadeln um zu motivieren',
            ro: 'Admonestarea pentru a motiva',
            bg: 'Цената е въпрос на възприятие',
            hr: 'Opominjemo da bismo motivirali',
            cz: 'Vytýkáme, abychom motivovali',
            ho: 'Corrigeren om te motiveren',
            fr: 'Réprimander pour motiver',
            hu: 'Kifogásolj, hogy motiválhass',
            it: 'Biasimare per motivare',
            mx: 'Censurar para motivar',
            no: 'Irettesettelse for å motivere',
            si: 'Grajamo, da motiviramo',
            rs: 'Upozoravamo sa ciljem da motivišemo',
            se: 'Kritik för att motivera',
            tr: 'Motive etmek için öğüt verin',
            us: 'Admonish in order to motivate',
        },
        backImage: {
            en: Batch1_Card24_Back_EN,
            de: Batch1_Card24_Back_DE,
            ro: Batch1_Card24_Back_RO,
            bg: Batch1_Card24_Back_BG,
            hr: Batch1_Card24_Back_HR,
            cz: Batch1_Card24_Back_CZ,
            ho: Batch1_Card24_Back_HO,
            fr: Batch1_Card24_Back_FR,
            hu: Batch1_Card24_Back_HU,
            it: Batch1_Card24_Back_IT,
            mx: Batch1_Card24_Back_MX,
            no: Batch1_Card24_Back_NO,
            si: Batch1_Card24_Back_SI,
            rs: Batch1_Card24_Back_RS,
            se: Batch1_Card24_Back_SE,
            tr: Batch1_Card24_Back_TR,
            us: Batch1_Card24_Back_US,
        }
    },
    {
        batchNumber: 1,
        memoryCardNumber: 25,
        frontSvg: Batch1_Card25_Front,
        frontTitle: {
            en: 'Added Value',
            de: 'Mehrwert',
            ro: 'Valoarea\n' +
                'Adăugată',
            bg: 'Обаждане',
            hr: 'Dodana\n' +
                'Vrijednost',
            cz: 'Přidaná Hodnota',
            ho: 'Toegevoegde\n' +
                'waarde',
            fr: 'Valeur Ajoutée',
            hu: 'Hozzáadott\n' +
                'Érték',
            it: 'Valore Aggiunto',
            mx: 'Valor Agregado',
            no: 'Merverdi',
            si: 'Dodana Vrednost',
            rs: 'Dodatna\n' +
                'Vrednost',
            se: 'Mervärde',
            tr: 'Katma Değer =\n' +
                'Değer Katma',
            us: 'Added Value',
        },
        frontBottomText: {
            en: 'In this world you get what you pay for',
            de: 'Qualität hat ihren Preis',
            ro: 'În această lume primim ceea ce plătim',
            bg: 'Изграждане на мостове по телефона',
            hr: 'Na ovom svijetu uvijek dobijemo ono što smo platili',
            cz: 'V tomto světě dostaneme to, za co zaplatíme',
            ho: 'In deze maatschappij krijg je waar voor je geld',
            fr: 'Dans ce monde, on obtient ce que l’on paie',
            hu: 'Ebben a világban azt kapod, amiért fizetsz',
            it: 'A questo mondo si ottiene per ciò che si paga',
            mx: 'En este mundo obtienes lo que pagas',
            no: 'I denne verden får du det du betaler for',
            si: 'Dobimo, kar plačamo',
            rs: 'U ovom svetu dobijamo ono za šta smo platili',
            se: 'I den här världen får du vad du betalar för',
            tr: 'Bu dünyada ödediğiniz paranın karşılığını\n' +
                'alıyorsunuz',
            us: 'In this world you get what you pay for',
        },
        backImage: {
            en: Batch1_Card25_Back_EN,
            de: Batch1_Card25_Back_DE,
            ro: Batch1_Card25_Back_RO,
            bg: Batch1_Card25_Back_BG,
            hr: Batch1_Card25_Back_HR,
            cz: Batch1_Card25_Back_CZ,
            ho: Batch1_Card25_Back_HO,
            fr: Batch1_Card25_Back_FR,
            hu: Batch1_Card25_Back_HU,
            it: Batch1_Card25_Back_IT,
            mx: Batch1_Card25_Back_MX,
            no: Batch1_Card25_Back_NO,
            si: Batch1_Card25_Back_SI,
            rs: Batch1_Card25_Back_RS,
            se: Batch1_Card25_Back_SE,
            tr: Batch1_Card25_Back_TR,
            us: Batch1_Card25_Back_US,
        }
    },


    // Batch 2
    {
        batchNumber: 2,
        memoryCardNumber: 1,
        frontSvg: Batch2_Card1_Front,
        frontTitle: {
            en: 'Facilitation',
            de: 'Moderation',
            ro: 'Facilitarea',
            bg: 'Фасилитиране',
            hr: 'Moderiranje',
            cz: 'Facilitace',
            ho: 'Meetings\n' +
                'Faciliteren',
            hu: 'Támogatás',
            it: 'Agevolare',
            mx: 'Facilitar',
            no: 'Fasilitering',
            pl: 'Wsparcie',
            si: 'Moderiranje',
            rs: 'Moderacija',
            se: 'Facilitering',
            tr: 'Kolaylaştırma',
            us: 'Facilitation',
        },
        frontBottomText: {
            en: 'Inspire the team to deliver the objectives',
            de: 'Inspiriere das Team, um das Ergebnis zu erzielen',
            ro: 'Inspiră echipa să livreze rezultate',
            bg: 'Вдъхнови екипа да постигне резултата',
            hr: 'Potaknimo tim za postizanje rezultata',
            cz: 'Inspiruj tým k dosažení výsledků',
            ho: 'Teams optimale resultaten laten behalen ',
            hu: 'A csapatodat inspiráld eredményekre',
            it: 'Ispirare il team a realizzare il risultato',
            mx: 'Inspira al equipo a lograr el resultado',
            no: 'Inspirere teamet til å levere resultater',
            pl: 'Zainspiruj zespół do dostarczenia rezultatu',
            si: 'Navdušite ekipo, da doseže rezultate',
            rs: 'Inspirišemo tim da ostvari rezultat',
            se: 'Inspirera teamet att leverera resultat',
            tr: 'Hedefe ulaşmak için tüm ekibe ilham verin',
            us: 'Inspire the team to deliver results',
        },
        backImage: {
            en: Batch2_Card1_Back_EN,
            de: Batch2_Card1_Back_DE,
            ro: Batch2_Card1_Back_RO,
            bg: Batch2_Card1_Back_BG,
            hr: Batch2_Card1_Back_HR,
            cz: Batch2_Card1_Back_CZ,
            ho: Batch2_Card1_Back_HO,
            hu: Batch2_Card1_Back_HU,
            it: Batch2_Card1_Back_IT,
            mx: Batch2_Card1_Back_MX,
            no: Batch2_Card1_Back_NO,
            pl: Batch2_Card1_Back_PL,
            si: Batch2_Card1_Back_SI,
            rs: Batch2_Card1_Back_RS,
            se: Batch2_Card1_Back_SE,
            tr: Batch2_Card1_Back_TR,
            us: Batch2_Card1_Back_US,
        }
    },
    {
        batchNumber: 2,
        memoryCardNumber: 2,
        frontSvg: Batch2_Card2_Front,
        frontTitle: {
            en: 'People\n' +
                'Development',
            de: 'Mitarbeiterentwicklung',
            ro: 'Dezvoltarea\n' +
                'oamenilor',
            bg: 'Развитие на\n' +
                'хора',
            hr: 'Razvoj suradnika',
            cz: 'Rozvoj lidí',
            ho: 'Persoonlijke\n' +
                'Ontwikkeling',
            hu: 'Emberek\n' +
                'fejlesztése',
            it: 'Sviluppo delle\n' +
                'Persone',
            mx: 'Desarrollar\n' +
                'Personas',
            no: 'Utvikling av\n' +
                'Personer',
            pl: 'Rozwój ludzi',
            si: 'Razvoj\n' +
                'Sodelavcev',
            rs: 'Razvoj ljudi',
            se: 'Utveckling av\n' +
                'människor',
            tr: 'İnsan gelişimi',
            us: 'People\n' +
                'Development',
        },
        frontBottomText: {
            en: 'Help them to become the best version of themselves',
            de: 'Führe sie zu der besten Version von sich selbst ',
            ro: 'Inspiră oamenii să devină cea mai bună versiune a lor',
            bg: 'Води ги да станат най-добрата версия на себе си',
            hr: 'Vodimo ih da postanu najbolja verzija sebe',
            cz: 'Veď je k tomu, aby se stali nejlepší verzí sebe sama ',
            ho: 'Laat iemand de beste versie van zichzelf worden',
            hu: 'Vezesd őket úgy, hogy magukból a legjobbat hozzák ki',
            it: 'Guidare gli altri a divenire la migliore versione di se stessi',
            mx: 'Guía a cada colaborador a convertirse en la mejor versión de sí mismos',
            no: 'Få dem til å bli den beste versjonen av seg selv',
            pl: 'Poprowadź ich, aby stali się najlepszą wersją samego siebie',
            si: 'Sodelavce vodite tako, da postanejo najboljša različica sebe',
            rs: 'Vodimo ljude tako da postanu najbolja verzija sebe',
            se: 'Få dom tll att bli den bästa versionen av sig själv',
            tr: 'Onlara kendilerinin en iyi hali olmaları için öncülük edin',
            us: 'Lead them to become the best version of themselves',
        },
        backImage: {
            en: Batch2_Card2_Back_EN,
            de: Batch2_Card2_Back_DE,
            ro: Batch2_Card2_Back_RO,
            bg: Batch2_Card2_Back_BG,
            hr: Batch2_Card2_Back_HR,
            cz: Batch2_Card2_Back_CZ,
            ho: Batch2_Card2_Back_HO,
            hu: Batch2_Card2_Back_HU,
            it: Batch2_Card2_Back_IT,
            mx: Batch2_Card2_Back_MX,
            no: Batch2_Card2_Back_NO,
            pl: Batch2_Card2_Back_PL,
            si: Batch2_Card2_Back_SI,
            rs: Batch2_Card2_Back_RS,
            se: Batch2_Card2_Back_SE,
            tr: Batch2_Card2_Back_TR,
            us: Batch2_Card2_Back_US,
        }
    },
    {
        batchNumber: 2,
        memoryCardNumber: 3,
        frontSvg: Batch2_Card3_Front,
        frontTitle: {
            en: 'Buying\n' +
                'Influencers',
            de: 'Kaufbeeinflusser',
            ro: 'Influențatorii\n' +
                'Vânzării',
            bg: 'Роли с влияние\n' +
                'за покупка',
            hr: 'Tko Utječe na\n' +
                'Odluku o Kupnji?',
            cz: 'Ovlivňovatelé\n' +
                'obchodu',
            ho: 'Koop\n' +
                'Beïnvloeders',
            hu: 'Vásárlás\n' +
                'befolyásolók',
            it: 'Acquisire\n' +
                'Influencers',
            mx: 'Los Roles que\n' +
                'influyen en la\n' +
                'compra',
            no: 'Kjøpspåvirkere',
            pl: 'Kto ma wpływ na zakup',
            si: 'Vloge pri\n' +
                'Odločanju o\n' +
                'Nakupu',
            rs: 'Ko utiče na\n' +
                'kupovinu',
            se: 'Köpbesluts\n' +
                'påverkare',
            tr: 'Satınalma\n' +
                'kararını\n' +
                'etkileyen',
            us: 'Buying\n' +
                'Influencers',
        },
        frontBottomText: {
            en: 'Identify all the people who will influence the decision-making process',
            de: 'Identifiziere jeden, der den Entscheidungsprozess beeinflussen kann',
            ro: 'Identifică pe toți cei care vor influența procesul decizional',
            bg: 'Идентифицирайте всички хора, които ще повлияят на процеса на вземане на решения',
            hr: 'Identificirajmo sve osobe koje utječu na proces donošenja odluka',
            cz: 'Identifikuj všechny lidi, kteří ovlivňují prodejní proces',
            ho: 'Identificeer iedereen die invloed heeft op het koopproces',
            hu: 'Azonosítsd mindazokat, akiknek hatásuk lesz a döntéshozó folyamatra',
            it: 'Identificare tutte le persone che influenzeranno il processo decisorio',
            mx: 'Identifica a todas las personas que influirán en el proceso de toma de decisiones',
            no: 'Identifiser alle personene som vil påvirke beslutningsprosessen',
            pl: 'Zidentyfikuj wszystkie osoby, które będą miały wpływ na proces decyzyjny',
            si: 'Določite vse ljudi, ki vplivajo na odločitev',
            rs: 'Identifikujemo sve ljude koji utiču na proces donošenja odluke ',
            se: 'Idintifiera alla som vill påverka besluts processen',
            tr: 'Karar verme sürecini etkileyecek tüm paydaşları belirleyin',
            us: 'Identify all the people who will influence the decision-making process',
        },
        backImage: {
            en: Batch2_Card3_Back_EN,
            de: Batch2_Card3_Back_DE,
            ro: Batch2_Card3_Back_RO,
            bg: Batch2_Card3_Back_BG,
            hr: Batch2_Card3_Back_HR,
            cz: Batch2_Card3_Back_CZ,
            ho: Batch2_Card3_Back_HO,
            hu: Batch2_Card3_Back_HU,
            it: Batch2_Card3_Back_IT,
            mx: Batch2_Card3_Back_MX,
            no: Batch2_Card3_Back_NO,
            pl: Batch2_Card3_Back_PL,
            si: Batch2_Card3_Back_SI,
            rs: Batch2_Card3_Back_RS,
            se: Batch2_Card3_Back_SE,
            tr: Batch2_Card3_Back_TR,
            us: Batch2_Card3_Back_US,
        }
    },
    {
        batchNumber: 2,
        memoryCardNumber: 4,
        frontSvg: Batch2_Card4_Front,
        frontTitle: {
            en: 'Leadership\n' +
                'Philosophy',
            de: 'Leadership\n' +
                'Philosophie',
            ro: 'Filozofia de\n' +
                'Leadership',
            bg: 'Лидерска\n' +
                'Философия',
            hr: 'Filozofija\n' +
                'Liderstva',
            cz: 'Filozofie\n' +
                'leadershipu',
            ho: 'Leiderschap\n' +
                'Filosophie',
            hu: 'Vezetői filozófia',
            it: 'Filosofia della\n' +
                'Leadership',
            mx: 'Filosofía de\n' +
                'Liderazgo',
            no: 'Ledelsesfilosofi',
            pl: 'Filozofia\n' +
                'przywództwa',
            si: 'Filozofija\n' +
                'Vodenja',
            rs: 'Liderska\n' +
                'filozofija',
            se: 'Ledarskaps\n' +
                'Filosof',
            tr: 'Liderlik\n' +
                'Felsefesi',
            us: 'Leadership\n' +
                'Philosophy',
        },
        frontBottomText: {
            en: 'Help your people to motivate themselves to achieve goals they can be proud of',
            de: 'Unterstütze Deine Mitarbeiter dabei, sich selbst zu motivieren und Ziele zu erreichen, auf die sie stolz sein können',
            ro: 'Ajută colegii să se automotiveze pentru a obține rezultate de care să fie mândri',
            bg: 'Помогнете на хората си сами да се мотивират за постигане на цели, с които могат да се гордеят',
            hr: 'Potaknimo suradnike da se motiviraju u ostvarenju ciljeva kojima mogu biti ponosni',
            cz: 'Pomozte svým lidem motivovat se k dosažení cílů, na které mohou být pyšní',
            ho: 'Help anderen zichzelf te motiveren om doelen te bereiken waar ze trots op zijn',
            hu: 'Segítsd a munkatársaidat, hogy motiválják magukat a célok elérésére, amelyekre büszkék lehetnek',
            it: 'Aiutare le persone ad automotivarsi per raggiungere obiettivi dei quali essere fieri',
            mx: 'Ayúdalos a motivarse para lograr las metas de las que pueden estar orgullosos',
            no: 'Hjelp folkene dine til å motivere seg selv til å oppnå mål de kan være stolte av',
            pl: 'Pomóż swoim ludziom zmotywować się do osiągnięcia celów, z których mogą być dumni',
            si: 'Pomagajte sodelavcem, da se sami motivirajo za uspehe, na katere bodo ponosni',
            rs: 'Pomažemo ljudima da motivišu sami sebe kako bi postigli ciljeve na koje su ponosni ',
            se: 'Hjälp ditt folk att motivera sig själva tll att uppnå mål som dom kan vara stolta över',
            tr: 'Çalışanlarınızın, belirledikleri hedeflerine ulaşarak gurur duymaları için motive olmalarına yardımcı olun',
            us: 'Help others to motivate themselves to achieve goals they can be proud of',
        },
        backImage: {
            en: Batch2_Card4_Back_EN,
            de: Batch2_Card4_Back_DE,
            ro: Batch2_Card4_Back_RO,
            bg: Batch2_Card4_Back_BG,
            hr: Batch2_Card4_Back_HR,
            cz: Batch2_Card4_Back_CZ,
            ho: Batch2_Card4_Back_HO,
            hu: Batch2_Card4_Back_HU,
            it: Batch2_Card4_Back_IT,
            mx: Batch2_Card4_Back_MX,
            no: Batch2_Card4_Back_NO,
            pl: Batch2_Card4_Back_PL,
            si: Batch2_Card4_Back_SI,
            rs: Batch2_Card4_Back_RS,
            se: Batch2_Card4_Back_SE,
            tr: Batch2_Card4_Back_TR,
            us: Batch2_Card4_Back_US,
        }
    },
    {
        batchNumber: 2,
        memoryCardNumber: 5,
        frontSvg: Batch2_Card5_Front,
        frontTitle: {
            en: 'Journey of\n' +
                'Change',
            de: 'Die Reise der\n' +
                'Veränderung',
            ro: 'Călătoria\n' +
                'Schimbării',
            bg: 'Пътуване към\n' +
                'промяната',
            hr: 'Put Promjena',
            cz: 'Cesta změny',
            ho: 'Reis van de\n' +
                'Verandering',
            hu: 'Változások\n' +
                'közepette',
            it: 'Percorso del\n' +
                'Cambiamento',
            mx: 'Ruta del cambio',
            no: 'Endringsreisen',
            pl: 'Podróż ze\n' +
                'zmianami',
            si: 'Spremembe',
            rs: 'Putovanje\n' +
                'promene',
            se: 'Resa till\n' +
                'Förändring',
            tr: 'Değişim\n' +
                'yolculuğu',
            us: 'Journey of\n' +
                'Change',
        },
        frontBottomText: {
            en: 'Say “Yes” to change',
            de: 'Sag “JA” zur Veränderung',
            ro: 'Spune “DA” schimbării',
            bg: 'Кажи “Да” на промяната',
            hr: 'Recimo “DA” promjenama',
            cz: 'Řekni “ANO” na změnu.',
            ho: 'Zeg “Ja” tegen verandering',
            hu: 'Mondj “Igent” a változásra',
            it: 'Dire “SI” al cambiamento',
            mx: 'Di “Sí” al cambio',
            no: 'Si “JA” til endring',
            pl: 'Powiedz “Tak”, do zmian',
            si: 'Recite “DA” spremembam',
            rs: 'Kažemo “DA” promeni',
            se: 'Säg ja till förändring',
            tr: 'Değişime “Evet” deyin',
            us: 'Say “Yes” to change',
        },
        backImage: {
            en: Batch2_Card5_Back_EN,
            de: Batch2_Card5_Back_DE,
            ro: Batch2_Card5_Back_RO,
            bg: Batch2_Card5_Back_BG,
            hr: Batch2_Card5_Back_HR,
            cz: Batch2_Card5_Back_CZ,
            ho: Batch2_Card5_Back_HO,
            hu: Batch2_Card5_Back_HU,
            it: Batch2_Card5_Back_IT,
            mx: Batch2_Card5_Back_MX,
            no: Batch2_Card5_Back_NO,
            pl: Batch2_Card5_Back_PL,
            si: Batch2_Card5_Back_SI,
            rs: Batch2_Card5_Back_RS,
            se: Batch2_Card5_Back_SE,
            tr: Batch2_Card5_Back_TR,
            us: Batch2_Card5_Back_US,
        }
    },
    {
        batchNumber: 2,
        memoryCardNumber: 6,
        frontSvg: Batch2_Card6_Front,
        frontTitle: {
            en: 'Catastrophic\n' +
                'Point',
            de: 'Katastrophenpunkt',
            ro: 'Punctul de\n' +
                'Catastrofă',
            bg: 'Кризисна\n' +
                'точка',
            hr: 'Točka Katastrofe',
            cz: 'Katastrofický bod',
            ho: 'Catastrofe Punt',
            hu: 'Katasztrófapont',
            it: 'Punto di\n' +
                'Catastrofe',
            mx: 'Punto\n' +
                'Catastrófico',
            no: 'Katastrofepunktet',
            pl: 'Punkt katastrofy',
            si: 'Točka Katastrofe',
            rs: 'Tačka katastrofe',
            se: 'Katastrof Punkt',
            tr: 'Yıkım Noktası',
            us: 'Catastrophic\n' +
                'Point',
        },
        frontBottomText: {
            en: 'Have courage to explore their personal catastrophic point',
            de: 'Habe den Mut, ihren persönlichen\n' +
                'Katastrophenpunkt zu erforschen',
            ro: 'Ai curajul să explorezi punctul lor de catastrofă',
            bg: 'Имай смелостта да изследваш тяхната лична крижисна точка',
            hr: 'Imajmo hrabrosti otkriti njihovu osobnu točku katastrofe',
            cz: 'Měj odvahu prozkoumat jejich osobní katastrofický bod.',
            ho: 'De moed hebben om het persoonlijk catastrofe punt op te zoeken',
            hu: 'Legyen bátorságod, hogy felfedezzék a személyes katasztrófapontjukat',
            it: 'Avere il coraggio di esplorare i personali punti di catastrofe',
            mx: 'Ten valor para explorar su punto catastrófico personal',
            no: 'Ha mot til å utforske deres personlige katastrofepunkt',
            pl: 'Miej odwagę zbadać ich osobisty punkt katastrofy',
            si: 'Imejte pogum, da najdete njihovo točko katastrofe',
            rs: 'Imajmo hrabrost da istražimo ličnu tačku katastrofe našeg sagovornika',
            se: 'Ha mod till att förklara deras personliga katastrof punkt',
            tr: 'Kişisel yıkım noktalarını keşfetmeye cesaretli olun',
            us: 'Have courage to explore their personal catastrophic point',
        },
        backImage: {
            en: Batch2_Card6_Back_EN,
            de: Batch2_Card6_Back_DE,
            ro: Batch2_Card6_Back_RO,
            bg: Batch2_Card6_Back_BG,
            hr: Batch2_Card6_Back_HR,
            cz: Batch2_Card6_Back_CZ,
            ho: Batch2_Card6_Back_HO,
            hu: Batch2_Card6_Back_HU,
            it: Batch2_Card6_Back_IT,
            mx: Batch2_Card6_Back_MX,
            no: Batch2_Card6_Back_NO,
            pl: Batch2_Card6_Back_PL,
            si: Batch2_Card6_Back_SI,
            rs: Batch2_Card6_Back_RS,
            se: Batch2_Card6_Back_SE,
            tr: Batch2_Card6_Back_TR,
            us: Batch2_Card6_Back_US,
        }
    },
    {
        batchNumber: 2,
        memoryCardNumber: 7,
        frontSvg: Batch2_Card7_Front,
        frontTitle: {
            en: 'Selling\n' +
                'Customer’s\n' +
                'Objectives',
            de: 'Ziele Verkaufen',
            ro: 'A Vinde\n' +
                'Obiectivele\n' +
                'Clientului',
            bg: 'Продаваме\n' +
                'целите на\n' +
                'клиента',
            hr: 'Prodajmo Ciljeve\n' +
                'Klijenta',
            cz: 'Prodej cílů\n' +
                'zákazníka',
            ho: 'Doelen Verkopen',
            hu: 'A Vevő céljainak\n' +
                'eladása',
            it: 'Vendere gli\n' +
                'Obiettivi del\n' +
                'Cliente',
            mx: 'Vender los\n' +
                'Objetivos del\n' +
                'Cliente',
            no: 'Å Selge Kundens\n' +
                'Mål',
            pl: 'Sprzedaż celów\n' +
                'klienta',
            si: 'Prodaja\n' +
                'Strankinih Ciljev',
            rs: 'Prodajemo cilj\n' +
                'našeg kupca',
            se: 'Sälja Kundens Mål',
            tr: 'Müşterinizin\n' +
                'hedeflerini satın',
            us: 'Fulfilling the\n' +
                'Customer’s\n' +
                'Objectives',
        },
        frontBottomText: {
            en: 'React to the right word\n' +
                'Listen consciously for real understanding',
            de: 'Auf’s richtige Wort richtig reagieren\n' +
                'Bewusstes Zuhören, um wirklich zu verstehen',
            ro: 'Reacționează la cuvântul potrivit\n' +
                'Ascultă în mod conștient pentru a înțelege',
            bg: 'Реагирай на точната дума\n' +
                'Слушай съзнателно за истинско разбиране',
            hr: 'Reagirajmo na pravu riječ\n' +
                'Svjesno slušajmo da bismo razumjeli',
            cz: 'Reaguj na správné slovo\n' +
                'Vědomě naslouchej pro opravdové porozumění',
            ho: 'Reageren op het juiste woord\n' +
                'Luister bewust om het echt te begrijpen',
            hu: 'A helyes szóra reagálj\n' +
                'Tudatosan figyelj, hogy jól értsd',
            it: 'Reagire alla parola giusta\n' +
                'Ascoltare consapevolmente per una reale comprensione',
            mx: 'Reacciona a la palabra correcta\n' +
                'Escucha conscientemente para una real comprensión',
            no: 'Reager på riktig ord\n' +
                'Lytt bevisst for virkelig forståelse',
            pl: 'Reaguj na właściwe słowo\n' +
                'Słuchaj świadomie, aby naprawdę zrozumieć',
            si: 'Odzovite se na pravo besedo\n' +
                'Zavestno poslušajte, da boste res razumeli',
            rs: 'Reakcija na pravu reč\n' +
                'Slušamo pažljivo za pravo razumevanje',
            se: 'Reagera på rätt ord\n' +
                'Lyssna medvetet för att verkligen försyå',
            tr: 'Doğru kelimeye tepki verin\n' +
                'Doğru anlamak için bilinçli olarak dinleyin',
            us: 'React to the right word\n' +
                'Listen consciously for real understanding',
        },
        backImage: {
            en: Batch2_Card7_Back_EN,
            de: Batch2_Card7_Back_DE,
            ro: Batch2_Card7_Back_RO,
            bg: Batch2_Card7_Back_BG,
            hr: Batch2_Card7_Back_HR,
            cz: Batch2_Card7_Back_CZ,
            ho: Batch2_Card7_Back_HO,
            hu: Batch2_Card7_Back_HU,
            it: Batch2_Card7_Back_IT,
            mx: Batch2_Card7_Back_MX,
            no: Batch2_Card7_Back_NO,
            pl: Batch2_Card7_Back_PL,
            si: Batch2_Card7_Back_SI,
            rs: Batch2_Card7_Back_RS,
            se: Batch2_Card7_Back_SE,
            tr: Batch2_Card7_Back_TR,
            us: Batch2_Card7_Back_US,
        }
    },
    {
        batchNumber: 2,
        memoryCardNumber: 8,
        frontSvg: Batch2_Card8_Front,
        frontTitle: {
            en: 'Selling\n' +
                'Strategically',
            de: 'Stategisches\n' +
                'Verkaufen',
            ro: 'A Vinde Strategic',
            bg: 'Стратегически\n' +
                'продажби',
            hr: 'Strateška\n' +
                'Prodaja',
            cz: 'Strategický\n' +
                'prodej',
            ho: 'Strategisch\n' +
                'Verkopen',
            hu: 'Stratégiai\n' +
                'értékesítés',
            it: 'Vendita\n' +
                'Strategica',
            mx: 'Ventas\n' +
                'Estratégicas',
            no: 'Strategisk Salg',
            pl: 'Sprzedaż\n' +
                'strategiczna',
            si: 'Strateška\n' +
                'Prodaja',
            rs: 'Strateška prodaja',
            se: 'Strategisk\n' +
                'Försäljning',
            tr: 'Stratejik Satış',
            us: 'Selling\n' +
                'Strategically',
        },
        frontBottomText: {
            en: 'Consciously create the Fully Active Recommendation',
            de: 'Strebe die Aktive Vollreferenz bewusst an',
            ro: 'Creează în mod conștient Recomandarea Total Activă',
            bg: 'Съзнателно създай Напълно Активна Препоръка',
            hr: 'Svjesno stvarajmo Potpuno Aktivne Preporuke',
            cz: 'Vědomě vytvářej aktivní doporučení.',
            ho: 'De Actieve Referentie als doel hebben',
            hu: 'Tudatosan alakítsd a Teljes Aktív Referenciát',
            it: 'Creare consapevolmente la Referenza Attiva Totale',
            mx: 'Crea conscientemente la Recomendación Activa Total',
            no: 'Skap Aktive Topp Referanser bevisst',
            pl: 'Świadomie stwórz Pełną Aktywną Rekomendację',
            si: 'Zavestno ustvarite Proaktivno Osebno Priporočilo',
            rs: 'Svesno kreiramo Punu Aktivnu Preporuku',
            se: 'Lyssna medvetet för att verkligen förstå',
            tr: 'Bilinçli olarak TAR (Tam Aktif Referans) yaratın',
            us: 'Consciously create the Fully Active Recommendation',
        },
        backImage: {
            en: Batch2_Card8_Back_EN,
            de: Batch2_Card8_Back_DE,
            ro: Batch2_Card8_Back_RO,
            bg: Batch2_Card8_Back_BG,
            hr: Batch2_Card8_Back_HR,
            cz: Batch2_Card8_Back_CZ,
            ho: Batch2_Card8_Back_HO,
            hu: Batch2_Card8_Back_HU,
            it: Batch2_Card8_Back_IT,
            mx: Batch2_Card8_Back_MX,
            no: Batch2_Card8_Back_NO,
            pl: Batch2_Card8_Back_PL,
            si: Batch2_Card8_Back_SI,
            rs: Batch2_Card8_Back_RS,
            se: Batch2_Card8_Back_SE,
            tr: Batch2_Card8_Back_TR,
            us: Batch2_Card8_Back_US,
        }
    },


    // Batch 3
    {
        batchNumber: 3,
        memoryCardNumber: 1,
        frontSvg: Batch3_Card1_Front,
        frontTitle: {
            en: 'Opportunity\n' +
                'Thinker',
            de: 'Chancendenker',
            ro: 'Explorator\n' +
                'Oportunități',
            bg: 'Търсач на\n' +
                'възможности',
            hr: 'Razmišljajmo o\n' +
                'Prilikama',
            cz: 'Myšlení v\n' +
                'šancích',
            ho: 'Kansen Denker',
            hu: 'A Lehetőségben\n' +
                'Gondolkodó',
            it: 'Pensatore di\n' +
                'Opportunità',
            mx: 'Pensador de\n' +
                'Oportunidades',
            no: 'Mulighetstenker',
            pl: 'Myślący o\n' +
                'Szansie',
            si: 'Razmišljajmo o\n' +
                'Priložnostih',
            rs: 'Razmišljamo o\n' +
                'mogućnostima!',
            se: 'Möjlighets\n' +
                'tänkare',
            us: 'Opportunity\n' +
                'Thinker',
        },
        frontBottomText: {
            en: 'Successfully dealing with problems starts first with our attitude rather than our expertise. Have a strong vision of success',
            de: 'Die erfolgreiche Bewältigung von Problemen beginnt zunächst mit unserer Einstellung und nicht mit unserem Fachwissen. Habe eine klare Vorstellung vom Erfolg!',
            ro: 'Abordarea cu succes a problemelor depinde mai mult de atitudine decât de expertiză. Să avem o viziune clară a succesului',
            bg: 'Успешното справяне с проблемите започва първо с нашето отношение, а не с нашия опит.\n' +
                'Имай силна представа за успех',
            hr: 'Uspješno rješavanje problema ponajprije ovisi o našem stavu a tek potom o našem znanju. Važno je imati snažnu viziju uspjeha',
            cz: 'Úspěšné řešení problémů začíná v prvé řadě našim přístupem než naší odborností. Vytvoř si silnou představu úspěchu.',
            ho: 'Succes voor het oplossen van problemen wordt niet bepaald door kennis maar door de juiste instelling.\n' +
                'Heb een sterke visie voor succes',
            hu: 'A problémák sikeres kezelése elsősorban hozzáállásunkkal kezdődik, nem pedig szakértelmünkkel. Legyen erős jövőképed a sikerről',
            it: 'Trattare con successo i problemi inizia con la nostra competenza. Avere una potente visione del successo',
            mx: 'Resolver problemas exitosamente empieza con nuestra actitud más que con nuestra experiencia.\n' +
                'Ten una fuerte visión del éxito.',
            no: 'Det å lykkes i å håndtere problemer, handler mer om holdninger enn egen ekspertise. Ha en sterk forestilling om å lykkes.',
            pl: 'Skuteczne radzenie sobie z problemami zaczyna się od naszego podejścia, a nie od naszej wiedzy. Miej silną wizję sukcesu',
            si: 'Uspešno reševanje problemov se začne z našim odnosom in šele nato z našim znanjem. Imejmo močno vizijo uspeha',
            rs: 'Uspešno rešavanje problema pre svega zavisi od našeg stava a tek onda od našeg znanja. Važno je imati snažnu viziju uspeha.',
            se: 'Det och lyckas att hantera problem, handlar mer om inställning än att vara expert. Att ha en stark föreställning om att lyckas',
            us: 'Successfully dealing with problems starts first with our attitude rather than our expertise. Have a strong vision of success',
        },
        backImage: {
            en: Batch3_Card1_Back_EN,
            de: Batch3_Card1_Back_DE,
            ro: Batch3_Card1_Back_RO,
            bg: Batch3_Card1_Back_BG,
            hr: Batch3_Card1_Back_HR,
            cz: Batch3_Card1_Back_CZ,
            ho: Batch3_Card1_Back_HO,
            hu: Batch3_Card1_Back_HU,
            it: Batch3_Card1_Back_IT,
            mx: Batch3_Card1_Back_MX,
            no: Batch3_Card1_Back_NO,
            pl: Batch3_Card1_Back_PL,
            si: Batch3_Card1_Back_SI,
            rs: Batch3_Card1_Back_RS,
            se: Batch3_Card1_Back_SE,
            us: Batch3_Card1_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 2,
        frontSvg: Batch3_Card2_Front,
        frontTitle: {
            en: 'Cause and\n' +
                'Motive Analysis',
            de: 'Ursachen - und\n' +
                'Motivanalyse',
            ro: 'Analiza Cauze și\n' +
                'Motive',
            bg: 'Анализ на\n' +
                'Причини и\n' +
                'Мотиви',
            hr: 'Analiza Uzroka\n' +
                'i Motiva',
            cz: 'Analýza příčin a\n' +
                'důvodů',
            ho: 'Oorzaak en\n' +
                'Motief Analyse',
            hu: 'Ok- és\n' +
                'Motivációelemzés',
            it: 'Analisi delle\n' +
                'Cause e dei\n' +
                'Motivi',
            mx: 'Análisis Causa -\n' +
                'Motivo',
            no: 'Årsaks og Motiv\n' +
                'Analyse',
            pl: 'Analiza Przyczyn\n' +
                'i Motywów',
            si: 'Analiza Vzrokov\n' +
                'in Motivov',
            rs: 'Analiza Uzroka i\n' +
                'Motiva',
            se: 'Orsak och Motiv\n' +
                'analys',
            us: 'Cause and\n' +
                'Motive Analysis',
        },
        frontBottomText: {
            en: 'What are causes and motives of IS ≠ SHOULD?',
            de: 'Was sind die Ursachen und Motive von IST ≠ SOLL?',
            ro: 'Care sunt cauzele și motivele când ESTE ≠ AR TREBUI?',
            bg: 'Какво представляват причините и мотивите за Е ≠ ТРЯБВА?',
            hr: 'Koji su uzroci i motivi kada JEST≠TREBA BITI',
            cz: 'Jaké jsou příčiny a důvody JE ≠ MÁ BÝT?',
            ho: 'Wat kunnen de oorzaken en motieven zijn wanneer IS ≠ MOET?',
            hu: 'Mik az okok és motivációk a TÉNY ≠ TERVEZET helyzetben',
            it: 'Quali sono le cause dell’ E’ ≠ DOVREBBE?',
            mx: '¿Cuáles son las causas y los motivos del ES ≠ DEBE SER ?',
            no: 'Hva er årsakene og motivene for at ER ≠ BØR VÆRE?',
            pl: 'Jakie są przyczyny i motywy JEST ≠ POWINNY?',
            si: 'Kaj so vzroki in motivi za OBSTOJEČE ≠ ŽELENO',
            rs: 'Koji su uzroci i motivi kada JESTE ≠ TREBA',
            se: 'Vad är orsakerna och motivna för att ÄR ≠ BÖR VARA',
            us: 'What are causes and motives of IS ≠ SHOULD?',
        },
        backImage: {
            en: Batch3_Card2_Back_EN,
            de: Batch3_Card2_Back_DE,
            ro: Batch3_Card2_Back_RO,
            bg: Batch3_Card2_Back_BG,
            hr: Batch3_Card2_Back_HR,
            cz: Batch3_Card2_Back_CZ,
            ho: Batch3_Card2_Back_HO,
            hu: Batch3_Card2_Back_HU,
            it: Batch3_Card2_Back_IT,
            mx: Batch3_Card2_Back_MX,
            no: Batch3_Card2_Back_NO,
            pl: Batch3_Card2_Back_PL,
            si: Batch3_Card2_Back_SI,
            rs: Batch3_Card2_Back_RS,
            se: Batch3_Card2_Back_SE,
            us: Batch3_Card2_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 3,
        frontSvg: Batch3_Card3_Front,
        frontTitle: {
            en: 'Decision-Making',
            de: 'Entscheidungs- findung',
            ro: 'Luarea Deciziilor',
            bg: 'Вземане на\n' +
                'решения',
            hr: 'Odlučivanje',
            cz: 'Rozhodování',
            ho: 'Besluitvorming',
            hu: 'Döntéshozatal',
            it: 'Il Processo\n' +
                'Decisionale',
            mx: 'Toma de\n' +
                'Decisiones',
            no: 'Beslutningstaking',
            pl: 'Podejmowanie\n' +
                'Decyzji',
            si: 'Odločanje',
            rs: 'Donošenje\n' +
                'odluka',
            se: 'Beslutsfattning',
            us: 'Decision-Making',
        },
        frontBottomText: {
            en: 'A well-structured overview will help us to solve the task',
            de: 'Ein gut strukturierter Überblick wird uns helfen, die Aufgabe zu lösen',
            ro: 'O imagine de ansamblu structurată ajută la găsirea soluției',
            bg: 'Добре структурираната информация ще ни помогне да решим задачата',
            hr: 'Dobro strukturirani pregled će nam pomoći da riješimo zadatak',
            cz: 'Strukturovaný přehled nám pomůže vyřešit úkol.',
            ho: 'Een gestructureerd overzicht helpt ons bij deze taak',
            hu: 'A jól-strukturált-áttekintés segít nekünk a feladat megoldásában',
            it: 'Una visione d’ insieme ben strutturata ci aiuterà a\n' +
                'trovare la soluzione al problema',
            mx: 'Una visión general bien estructurada, nos ayudará a resolver la tarea',
            no: 'Et velstrukturert overblikk vil hjelpe oss til å løse oppgaven',
            pl: 'Dobrze strukturowany przegląd pomoże nam rozwiązać zadanie',
            si: 'Pregledna struktura nam bo pomagala rešiti nalogo',
            rs: 'Dobro struktuiran pregled će nam pomoći da rešimo zadatak',
            se: 'En välstrukturerad överblick vill hjälpa oss att lösa uppgiften',
            us: 'A well-structured overview will help us to accomplish the task.',
        },
        backImage: {
            en: Batch3_Card3_Back_EN,
            de: Batch3_Card3_Back_DE,
            ro: Batch3_Card3_Back_RO,
            bg: Batch3_Card3_Back_BG,
            hr: Batch3_Card3_Back_HR,
            cz: Batch3_Card3_Back_CZ,
            ho: Batch3_Card3_Back_HO,
            hu: Batch3_Card3_Back_HU,
            it: Batch3_Card3_Back_IT,
            mx: Batch3_Card3_Back_MX,
            no: Batch3_Card3_Back_NO,
            pl: Batch3_Card3_Back_PL,
            si: Batch3_Card3_Back_SI,
            rs: Batch3_Card3_Back_RS,
            se: Batch3_Card3_Back_SE,
            us: Batch3_Card3_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 4,
        frontSvg: Batch3_Card4_Front,
        frontTitle: {
            en: 'Risk Analysis',
            de: 'Gefahrenanalyse',
            ro: 'Analiza Riscului',
            bg: 'Анализ на риска',
            hr: 'Analiza Rizika',
            cz: 'Analýza rizik',
            ho: 'Risico Analyse',
            hu: 'Kockázatelemzés',
            it: 'Analisi dei Rischi',
            mx: 'Análisis de\n' +
                'Riesgo',
            no: 'Risikoanalyse',
            pl: 'Ocena Ryzyka',
            si: 'Analiza Tveganj',
            rs: 'Analiza Rizika',
            se: 'Risk Analys',
            us: 'Risk Analysis',
        },
        frontBottomText: {
            en: 'What is to be considered? Things often turn out differently...',
            de: 'Was ist zu beachten? Meistens kommt es anders, als man denkt!',
            ro: 'Ce trebuie să considerăm? Evoluțiile pot fi adesea diferite…',
            bg: 'Какво трябва да се вземе предвид? Нещата често се оказват различни...',
            hr: 'Što treba uzeti u obzir? Stvari često ispadnu drugačije...',
            cz: 'Co je třeba zvážit? Věci se často ukážou být jinak …',
            ho: 'Waar moeten we rekening mee houden? Dat zaken vaak anders lopen dan verwacht…',
            hu: 'Alakítsd a saját jövődet',
            it: 'Che cosa va considerato? Le cose spesso vanno diversamente…..',
            mx: '¿Qué se debe considerar? Las cosas a menudo resultan diferentes ...',
            no: 'Hva må man ta høyde for? Ting viser seg ofte å være annerledes...',
            pl: 'Co należy wziąć pod uwagę? Rzeczy często wypadają inaczej...',
            si: 'Kaj je potrebno upoštevati? Stvari se pogosto obrnejo drugače…',
            rs: 'Šta treba uzeti u obzir? Stvari često ispadnu drugačije...',
            se: 'Vad måste beaktas, saker visar sig ofta bli annorlunda',
            us: 'What is to be considered? Things often turn out differently...',
        },
        backImage: {
            en: Batch3_Card4_Back_EN,
            de: Batch3_Card4_Back_DE,
            ro: Batch3_Card4_Back_RO,
            bg: Batch3_Card4_Back_BG,
            hr: Batch3_Card4_Back_HR,
            cz: Batch3_Card4_Back_CZ,
            ho: Batch3_Card4_Back_HO,
            hu: Batch3_Card4_Back_HU,
            it: Batch3_Card4_Back_IT,
            mx: Batch3_Card4_Back_MX,
            no: Batch3_Card4_Back_NO,
            pl: Batch3_Card4_Back_PL,
            si: Batch3_Card4_Back_SI,
            rs: Batch3_Card4_Back_RS,
            se: Batch3_Card4_Back_SE,
            us: Batch3_Card4_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 5,
        frontSvg: Batch3_Card5_Front,
        frontTitle: {
            en: 'Perception',
            de: 'Wahrnehmung',
            ro: 'Percepția',
            bg: 'Възприятие',
            hr: 'Percepcija',
            cz: 'Vnímání',
            ho: 'Waarneming',
            hu: 'Észlelés',
            it: 'Percezione',
            mx: 'Percepción',
            no: 'Persepsjon',
            pl: 'Postrzeganie',
            si: 'Zaznavanje',
            rs: 'Percepcija',
            se: 'Perception',
            us: 'Perception',
        },
        frontBottomText: {
            en: 'What is being perceived is more important than what is being communicated',
            de: 'Was wahrgenommen wird, ist wichtiger als das, was gesagt wird',
            ro: 'Ce se percepe este mai important decât ce se comunică',
            bg: 'Това, което се възприема, е по-важно от това, което се комуникира',
            hr: 'Ono kako je nešto shvaćeno bitnije je od onoga što je komunicirano',
            cz: 'To, co je vnímáno, je důležitější než to, co se sděluje.',
            ho: 'Wat wordt waargenomen is belangrijker dan wat wordt gecommuniceerd',
            hu: 'Amit érzékelnek, sokkal fontosabb, mint amit kommunikálnak',
            it: 'Quello che viene percepito è più importante di quello che viene comunicato',
            mx: 'Lo que se percibe es más importante que lo que se comunica',
            no: 'Det som oppfattes er viktigere en det som blir kommunisert',
            pl: 'To, co jest postrzegane, jest ważniejsze niż to, co się komunikuje',
            si: 'Kako smo razumljeni, je pomembneje od tega, kaj smo komunicirali',
            rs: 'Ono kako je nešto doživljeno je bitnije od toga šta je izgovoreno',
            se: 'Vad som uppfattas är viktigare än det som kommuniceras',
            us: 'What is being perceived is more important than what is being communicated',
        },
        backImage: {
            en: Batch3_Card5_Back_EN,
            de: Batch3_Card5_Back_DE,
            ro: Batch3_Card5_Back_RO,
            bg: Batch3_Card5_Back_BG,
            hr: Batch3_Card5_Back_HR,
            cz: Batch3_Card5_Back_CZ,
            ho: Batch3_Card5_Back_HO,
            hu: Batch3_Card5_Back_HU,
            it: Batch3_Card5_Back_IT,
            mx: Batch3_Card5_Back_MX,
            no: Batch3_Card5_Back_NO,
            pl: Batch3_Card5_Back_PL,
            si: Batch3_Card5_Back_SI,
            rs: Batch3_Card5_Back_RS,
            se: Batch3_Card5_Back_SE,
            us: Batch3_Card5_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 6,
        frontSvg: Batch3_Card6_Front,
        frontTitle: {
            en: 'Project\n' +
                'Management',
            de: 'Projekt-\n' + 'Management',
            ro: 'Management de\n' +
                'Proiect',
            bg: 'Управление на\n' +
                'проекти',
            hr: 'Upravljanje\n' +
                'Projektima',
            cz: 'Projektový\n' +
                'management',
            ho: 'Project\n' +
                'Management',
            hu: 'Projekt\n' +
                'Menedzsment',
            it: 'Project\n' +
                'Management',
            mx: 'Project\n' +
                'Management',
            no: 'Prosjektledelse',
            pl: 'Zarządzanie\n' +
                'Projektem',
            si: 'Projektno\n' +
                'Vodenje',
            rs: 'Upravljanje\n' +
                'Projektima',
            se: 'Projektledning',
            us: 'Project\n' +
                'Management',
        },
        frontBottomText: {
            en: '70% of projects fail, are late or don’t deliver the promised outcome',
            de: '70% der Projekte scheitern, sind verspätet oder liefern nicht das versprochene Ergebnis',
            ro: '70% din proiecte eșuează, întârzie sau nu livrează conform așteptărilor',
            bg: '70% от проектите се провалят, закъсняват или не дават обещания резултат',
            hr: '70% projekata propadne, kasni ili ne isporuči planirane rezultate',
            cz: '70% projektů selže, jsou zpožděné nebo nedodají slibovaný výsledek.',
            ho: '70% van de projecten mislukken, komen niet op tijd af of voldoet de uitkomst niet aan de verwachting',
            hu: 'A projektek 70% -a kudarcot vall, késik, vagy nem teljesíti az ígért eredményt',
            it: 'Il 70% dei progetti fallisce, sono tardivi o non raggiungono i risultati attesi',
            mx: 'El 70% de los proyectos fracasan, llegan tarde o no entregan el resultado prometido',
            no: '70% av prosjektene mislykkes, er forsinket eller leverer ikke det lovede resultatet',
            pl: '70% projektów kończy się niepowodzeniem, spóźnia się lub nie zapewnia obiecanego rezultatu',
            si: '70% projektov je neuspešnih, zamujajo ali ne izpolnijo pričakovanj',
            rs: '70% projekata propadne, kasni ili ne isporuči planirane rezultate',
            se: '70% av projekten misslyckas, är försenade eller levererar inte det lovade resultatet',
            us: '70% of projects fail, are late or don’t deliver the promised outcome',
        },
        backImage: {
            en: Batch3_Card6_Back_EN,
            de: Batch3_Card6_Back_DE,
            ro: Batch3_Card6_Back_RO,
            bg: Batch3_Card6_Back_BG,
            hr: Batch3_Card6_Back_HR,
            cz: Batch3_Card6_Back_CZ,
            ho: Batch3_Card6_Back_HO,
            hu: Batch3_Card6_Back_HU,
            it: Batch3_Card6_Back_IT,
            mx: Batch3_Card6_Back_MX,
            no: Batch3_Card6_Back_NO,
            pl: Batch3_Card6_Back_PL,
            si: Batch3_Card6_Back_SI,
            rs: Batch3_Card6_Back_RS,
            se: Batch3_Card6_Back_SE,
            us: Batch3_Card6_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 7,
        frontSvg: Batch3_Card7_Front,
        frontTitle: {
            en: 'Project\n' +
                'Management:\n' +
                'Working\n' +
                'Methodology',
            de: 'Projekt\n' + 'Management:\n' +
                'Arbeitsmethodik',
            ro: 'Management\n' +
                'de Proiect:\n' +
                'Metodologia de\n' +
                'Lucru',
            bg: 'Управление\n' +
                'на проекти:\n' +
                'Работна\n' +
                'Методология',
            hr: 'Upravljanje\n' +
                'Projektima:\n' +
                'Metodologija',
            cz: 'Projektový\n' +
                'management:\n' +
                'Metodika práce',
            ho: 'Project\n' +
                'Management:\n' +
                'Methodiek',
            hu: 'Projekt\n' +
                'Menedzsment:\n' +
                'Munkamódszer- tan',
            it: 'Project\n' +
                'Management:\n' +
                'Metodologia di\n' +
                'Lavoro',
            mx: 'Project\n' +
                'Management:\n' +
                'Metodología de\n' +
                'Trabajo',
            no: 'Prosjektledelse:\n' +
                'Arbeidsmetodikk',
            pl: 'Zarządzanie\n' +
                'Projektem:\n' +
                'Metodologia\n' +
                'Pracy',
            si: 'Projektno\n' +
                'Vodenje: Delovna\n' +
                'Metodologija',
            rs: 'Upravljanje\n' +
                'Projektima:\n' +
                'Metodologija',
            se: 'Projektledning:\n' +
                'Arbetsmetodik',
            us: 'Project\n' +
                'Management:\n' +
                'Working\n' +
                'Methodology',
        },
        frontBottomText: {
            en: 'From macro to micro',
            de: 'Vom Groben zum Feinen',
            ro: 'De la macro la micro',
            bg: 'От макро към микро',
            hr: 'Od makro prema mikro',
            cz: 'Od makra k mikru.',
            ho: 'Van macro naar micro',
            hu: 'A makrótól a mikróig',
            it: 'Dal macro al micro',
            mx: 'De macro a micro',
            no: 'Fra makro til mikro',
            pl: 'Od makro do mikro',
            si: 'Od makro do mikro',
            rs: 'Od makro prema mikro',
            se: 'Från macro till micro',
            us: 'From macro to micro',
        },
        backImage: {
            en: Batch3_Card7_Back_EN,
            de: Batch3_Card7_Back_DE,
            ro: Batch3_Card7_Back_RO,
            bg: Batch3_Card7_Back_BG,
            hr: Batch3_Card7_Back_HR,
            cz: Batch3_Card7_Back_CZ,
            ho: Batch3_Card7_Back_HO,
            hu: Batch3_Card7_Back_HU,
            it: Batch3_Card7_Back_IT,
            mx: Batch3_Card7_Back_MX,
            no: Batch3_Card7_Back_NO,
            pl: Batch3_Card7_Back_PL,
            si: Batch3_Card7_Back_SI,
            rs: Batch3_Card7_Back_RS,
            se: Batch3_Card7_Back_SE,
            us: Batch3_Card7_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 8,
        frontSvg: Batch3_Card8_Front,
        frontTitle: {
            en: 'Project\n' +
                'Management:\n' +
                'Definition Phase',
            de: 'Projekt-\n' + 'Management:\n' +
                'Definitionsphase',
            ro: 'Management de\n' +
                'Proiect: Faza de\n' +
                'Definire/Inițiere',
            bg: 'Управление на\n' +
                'проекти: Фаза\n' +
                'Дефиниране',
            hr: 'Upravljanje\n' +
                'projektima:\n' +
                'Faza Definiranja',
            cz: 'Projektový\n' +
                'management:\n' +
                'Definiční fáze',
            ho: 'Project\n' +
                'Management:\n' +
                'Definitie Fase',
            hu: 'Projekt\n' +
                'Menedzsment:\n' +
                'Meghatározás\n' +
                'Szakasza',
            it: 'Project\n' +
                'Management:\n' +
                'Fase della\n' +
                'Definizione',
            mx: 'Project\n' +
                'Management:\n' +
                'Fase de Definición',
            no: 'Prosjektledelse:\n' +
                'Definisjons Fasen',
            pl: 'Zarządzanie\n' +
                'Projektem:\n' +
                'Faza Definicji',
            si: 'Projektno\n' +
                'Vodenje: Faza\n' +
                'Opredelitve',
            rs: 'Upravljanje\n' +
                'projektima:\n' +
                'Faza Definicije',
            se: 'Projektledning:\n' +
                'Planläggningsfas',
            us: 'Project\n' +
                'Management:\n' +
                'Definition Phase',
        },
        frontBottomText: {
            en: 'Deliberation before starting',
            de: 'Vor dem Start Besonnenheit',
            ro: 'Deliberare înainte de a începe',
            bg: 'Обмисляне преди старта',
            hr: 'Odlučnost prije početka',
            cz: 'Rozvaha před začátkem.',
            ho: 'Afstemmen voor de start',
            hu: 'Megbeszélés a kezdés előtt',
            it: 'Deliberazione prima della partenza',
            mx: 'Deliberación antes de comenzar',
            no: 'Diskusjon før man starter',
            pl: 'Dyskusja przed rozpoczęciem',
            si: 'Razmislek pred začetkom',
            rs: 'Odlučnost pre početka',
            se: 'Diskussion före man startar',
            us: 'Deliberation before starting',
        },
        backImage: {
            en: Batch3_Card8_Back_EN,
            de: Batch3_Card8_Back_DE,
            ro: Batch3_Card8_Back_RO,
            bg: Batch3_Card8_Back_BG,
            hr: Batch3_Card8_Back_HR,
            cz: Batch3_Card8_Back_CZ,
            ho: Batch3_Card8_Back_HO,
            hu: Batch3_Card8_Back_HU,
            it: Batch3_Card8_Back_IT,
            mx: Batch3_Card8_Back_MX,
            no: Batch3_Card8_Back_NO,
            pl: Batch3_Card8_Back_PL,
            si: Batch3_Card8_Back_SI,
            rs: Batch3_Card8_Back_RS,
            se: Batch3_Card8_Back_SE,
            us: Batch3_Card8_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 9,
        frontSvg: Batch3_Card9_Front,
        frontTitle: {
            en: 'Project\n' +
                'Management:\n' +
                'Planning Phase',
            de: 'Projekt-\n' + 'Management:\n' +
                'Planungsphase',
            ro: 'Management de\n' +
                'Proiect: Faza de\n' +
                'Planificare',
            bg: 'Управление на\n' +
                'проекти: Фаза\n' +
                'Планиране',
            hr: 'Vođenje\n' +
                'projekata:\n' +
                'Faza Planiranja',
            cz: 'Projektový\n' +
                'management:\n' +
                'Plánovací fáze',
            ho: 'Project\n' +
                'Management:\n' +
                'Ontwerp Fase',
            hu: 'Projekt\n' +
                'Menedzsment:\n' +
                'Tervezés\n' +
                'Szakasza',
            it: 'Project\n' +
                'Management:\n' +
                'Fase della\n' +
                'Pianificazione',
            mx: 'Project\n' +
                'Management:\n' +
                'Fase de\n' +
                'Planeación',
            no: 'Prosjektledelse:\n' +
                'Planleggingsfase',
            pl: 'Zarządzanie\n' +
                'Projektem:\n' +
                'Faza Planowania',
            si: 'Projektno\n' +
                'Vodenje: Faza\n' +
                'Načrtovanja',
            rs: 'Upravljanje\n' +
                'projektima:\n' +
                'Faza Planiranja',
            se: 'Projektledning:\n' +
                'Planläggningsfas',
            us: 'Project\n' +
                'Management:\n' +
                'Planning Phase',
        },
        frontBottomText: {
            en: 'Overview gives security',
            de: 'Übersicht gibt Sicherheit',
            ro: 'Imaginea de ansamblu dă securitate',
            bg: 'Обзорът дава сигурност',
            hr: 'Pregled daje osjećaj sigurnosti',
            cz: 'Přehled dává bezpečí.',
            ho: 'Overzicht geeft zekerheid',
            hu: 'Az áttekintés biztonságot nyújt',
            it: 'La visione complessiva dà sicurezza',
            mx: 'La visión general da seguridad',
            no: 'Oversikt gir sikkerhet',
            pl: 'Przegląd zapewnia bezpieczeństwo',
            si: 'Pregled nam da varnost',
            rs: 'Pregled daje osećaj sigurnosti',
            se: 'Översikt ger säkerhet',
            us: 'Overview gives security',
        },
        backImage: {
            en: Batch3_Card9_Back_EN,
            de: Batch3_Card9_Back_DE,
            ro: Batch3_Card9_Back_RO,
            bg: Batch3_Card9_Back_BG,
            hr: Batch3_Card9_Back_HR,
            cz: Batch3_Card9_Back_CZ,
            ho: Batch3_Card9_Back_HO,
            hu: Batch3_Card9_Back_HU,
            it: Batch3_Card9_Back_IT,
            mx: Batch3_Card9_Back_MX,
            no: Batch3_Card9_Back_NO,
            pl: Batch3_Card9_Back_PL,
            si: Batch3_Card9_Back_SI,
            rs: Batch3_Card9_Back_RS,
            se: Batch3_Card9_Back_SE,
            us: Batch3_Card9_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 10,
        frontSvg: Batch3_Card10_Front,
        frontTitle: {
            en: 'Project\n' +
                'Management:\n' +
                'Realisation\n' +
                'Phase',
            de: 'Projekt-\n' + 'Management:\n' +
                'Realisierungs-\n' + 'phase',
            ro: 'Management de\n' +
                'Proiect: Faza de\n' +
                'Implementare',
            bg: 'Управление на\n' +
                'проекти: Фаза\n' +
                'Реализация',
            hr: 'Upravljanje\n' +
                'Projektima:\n' +
                'Faza Realizacije',
            cz: 'Projektový\n' +
                'management:\n' +
                'Realizační fáze',
            ho: 'Project\n' +
                'Management:\n' +
                'Realisatie Fase',
            hu: 'Projekt\n' +
                'Menedzsment\n' +
                'Megvalósítás\n' +
                'Szakasza',
            it: 'Project\n' +
                'Management:\n' +
                'Fase della\n' +
                'Realizzazione',
            mx: 'Proyect\n' +
                'Management:\n' +
                'Fase de Ejecución',
            no: 'Prosjektledelse:\n' +
                'Realiseringsfase',
            pl: 'Zarządzanie\n' +
                'Projektem:\n' +
                'Faza Realizacji',
            si: 'Projektno\n' +
                'Vodenje: Faza\n' +
                'Izvedbe',
            rs: 'Upravljanje\n' +
                'projektima:\n' +
                'Faza Realizacije',
            se: 'Projektledning:\n' +
                'Realiserings- fasen',
            us: 'Project\n' +
                'Management:\n' +
                'Realisation\n' +
                'Phase',
        },
        frontBottomText: {
            en: 'The quality of planning determines the quality of controlling',
            de: 'Die Qualität der Planung bestimmt die Qualität der Steuerung',
            ro: 'Calitatea planificării determină calitatea controlului',
            bg: 'Качеството на планирането определя качеството на контролинга',
            hr: 'Kvaliteta kontrole ovisi o kvaliteti planiranja',
            cz: 'Kvalita plánování určuje kvalitu kontroly.',
            ho: 'De kwaliteit van de planning bepaalt de mate van controle',
            hu: 'A tervezés minősége meghatározza az ellenőrzés minőségét',
            it: 'La qualità della pianificazione determina la qualità del controllo',
            mx: 'La calidad de la planeación determina la calidad del control',
            no: 'Kvaliteten på planleggingen bestemmer kontrollenes kvalitet',
            pl: 'Jakość planowania decyduje o jakości kontrolingu',
            si: 'Kakovost načrtovanja določa kakovost nadzora',
            rs: 'Kvalitet kontrole zavisi od kvaliteta planiranja',
            se: 'Kvaliteten på planläggningen bestämmer kontrollens kvalitet',
            us: 'The quality of planning determines the quality of controlling',
        },
        backImage: {
            en: Batch3_Card10_Back_EN,
            de: Batch3_Card10_Back_DE,
            ro: Batch3_Card10_Back_RO,
            bg: Batch3_Card10_Back_BG,
            hr: Batch3_Card10_Back_HR,
            cz: Batch3_Card10_Back_CZ,
            ho: Batch3_Card10_Back_HO,
            hu: Batch3_Card10_Back_HU,
            it: Batch3_Card10_Back_IT,
            mx: Batch3_Card10_Back_MX,
            no: Batch3_Card10_Back_NO,
            pl: Batch3_Card10_Back_PL,
            si: Batch3_Card10_Back_SI,
            rs: Batch3_Card10_Back_RS,
            se: Batch3_Card10_Back_SE,
            us: Batch3_Card10_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 11,
        frontSvg: Batch3_Card11_Front,
        frontTitle: {
            en: 'Project\n' +
                'Management:\n' +
                'Closing Phase',
            de: 'Projekt-\n' + 'Management:\n' +
                'Abschlussphase',
            ro: 'Management de\n' +
                'Proiect: Faza de\n' +
                'Încheiere',
            bg: 'Управление\n' +
                'на проекта:\n' +
                'Заключителна\n' +
                'фаза',
            hr: 'Upravljanje\n' +
                'Projektima:\n' +
                'Faza Zatvaranja',
            cz: 'Projektový\n' +
                'management:\n' +
                'Uzavírací fáze',
            ho: 'Project\n' +
                'Management:\n' +
                'Nazorg Fase',
            hu: 'Projekt\n' +
                'Menedzsment\n' +
                'Lezárás Szakasza',
            it: 'Project\n' +
                'Management:\n' +
                'Fase della\n' +
                'Conclusione',
            mx: 'Proyect\n' +
                'Management:\n' +
                'Fase de Cierre',
            no: 'Prosjektledelse:\n' +
                'avslutningsfase',
            pl: 'Zarządzanie\n' +
                'Projektem:\n' +
                'Faza Zamknięcia',
            si: 'Projektno\n' +
                'Vodenje: Faza\n' +
                'Zaključka',
            rs: 'Upravljanje\n' +
                'projektima:\n' +
                'Faza Zatvaranja',
            se: 'Projektledning:\n' +
                'Avslutnings\n' +
                'fasen',
            us: 'Project\n' +
                'Management:\n' +
                'Closing Phase',
        },
        frontBottomText: {
            en: 'Let your team members be heroes',
            de: 'Lasse Deine Teammitglieder Helden sein',
            ro: 'Colegii din echipă sunt eroi',
            bg: 'Нека членовете на вашия екип бъдат герои',
            hr: 'Neka članovi tima budu heroji',
            cz: 'Umožni každému členu týmu stát se hrdinou.',
            ho: 'Let de teamleden held zijn',
            hu: 'Váljanak a csapatod tagjai hősökké',
            it: 'Consentire che i membri del team siano eroi',
            mx: 'Deja que los miembros del equipo sean los héroes',
            no: 'La teammedlemmene dine være helter',
            pl: 'Niech współpracownicy twojej drużyny będą bohaterami',
            si: 'Člani projektne skupine naj bodo junaki',
            rs: 'Neka članovi tima budu herojit',
            se: 'Låt dina team medlemmar vara hjältar',
            us: 'Let your team members be heroes',
        },
        backImage: {
            en: Batch3_Card11_Back_EN,
            de: Batch3_Card11_Back_DE,
            ro: Batch3_Card11_Back_RO,
            bg: Batch3_Card11_Back_BG,
            hr: Batch3_Card11_Back_HR,
            cz: Batch3_Card11_Back_CZ,
            ho: Batch3_Card11_Back_HO,
            hu: Batch3_Card11_Back_HU,
            it: Batch3_Card11_Back_IT,
            mx: Batch3_Card11_Back_MX,
            no: Batch3_Card11_Back_NO,
            pl: Batch3_Card11_Back_PL,
            si: Batch3_Card11_Back_SI,
            rs: Batch3_Card11_Back_RS,
            se: Batch3_Card11_Back_SE,
            us: Batch3_Card11_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 12,
        frontSvg: Batch3_Card12_Front,
        frontTitle: {
            en: 'Delegating',
            de: 'Delegieren',
            ro: 'Delegarea',
            bg: 'Делегиране',
            hr: 'Delegiranje',
            cz: 'Delegování',
            ho: 'Delegeren',
            hu: 'Delegálás',
            it: 'Delegare',
            mx: 'Delegar',
            no: 'Delegere',
            pl: 'Delegowanie',
            si: 'Delegiranje',
            rs: 'Delegiranje',
            se: 'Delegera',
            us: 'Delegating',
        },
        frontBottomText: {
            en: 'Grow yourself, your team members and your company by creating heroes',
            de: 'Entwickele Deine Mitarbeiter, Dein Unternehmen und somit Dich selbst weiter, indem Du andere zu Helden machst',
            ro: 'Dezvoltă-te pe tine, colegii și organizația, creează eroi',
            bg: 'Развий себе си, членовете на екипа и своята компания, като създаваш герои',
            hr: 'Izgradimo sebe, članove našeg tima i našu kompaniju kroz stvaranje heroja',
            cz: 'Rozvíjej sebe, členovi svého týmu a svoji společnost vytvářením hrdinů.',
            ho: 'Groei (jij!), je teamleden en je organisatie door helden te creëeren',
            hu: 'Fejleszd magad, a csapatod tagjait és a cégedet hősök létrehozásával',
            it: 'Far crescere se stessi, il proprio team e la propria azienda creando degli eroi',
            mx: 'Crezca usted mismo, los miembros de su equipo y su empresa creando héroes',
            no: 'Utvikl deg selv, teammedlemmene dine og selskapet ditt, ved å skape helter',
            pl: 'Rozwijaj siebie, współpracowników zespołu i swoją firmę, tworząc bohaterów',
            si: 'Razvijajmo sebe, člane svoje ekipe in svoje podjetje z ustvarjanjem junakov',
            rs: 'Izgradimo sebe, članove našeg tima i našu kompaniju kroz stvaranje heroja',
            se: 'Utvekla dig själv, teammedlemmarna dina och sällskapet ditt genom att skapa hjältar',
            us: 'Grow yourself, your team members and your company by creating heroes',
        },
        backImage: {
            en: Batch3_Card12_Back_EN,
            de: Batch3_Card12_Back_DE,
            ro: Batch3_Card12_Back_RO,
            bg: Batch3_Card12_Back_BG,
            hr: Batch3_Card12_Back_HR,
            cz: Batch3_Card12_Back_CZ,
            ho: Batch3_Card12_Back_HO,
            hu: Batch3_Card12_Back_HU,
            it: Batch3_Card12_Back_IT,
            mx: Batch3_Card12_Back_MX,
            no: Batch3_Card12_Back_NO,
            pl: Batch3_Card12_Back_PL,
            si: Batch3_Card12_Back_SI,
            rs: Batch3_Card12_Back_RS,
            se: Batch3_Card12_Back_SE,
            us: Batch3_Card12_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 13,
        frontSvg: Batch3_Card13_Front,
        frontTitle: {
            en: 'Feedback',
            de: '',
            ro: 'Feedback',
            bg: 'Обратна връзка',
            hr: 'Davanje povratne\n' +
                'informacije',
            cz: 'Zpětná vazba',
            ho: 'Feedback',
            hu: 'Feedback',
            it: 'Feedback',
            mx: 'Retroalimentar',
            no: 'Tilbakmelding',
            pl: 'Feedback',
            si: 'Povratna\n' +
                'Informacija',
            rs: 'Povratna\n' +
                'informacija',
            se: 'Återkoppling',
            us: 'Feedback',
        },
        frontBottomText: {
            en: 'Feedback to move forward',
            de: 'Bewusst nach außen zu wirken, erhöht unseren Einfluss',
            ro: 'Feedback pentru a progresa',
            bg: 'Обратна връзка, за да продължи напред',
            hr: 'Povratna informacija omogućava nam da idemo naprijed',
            cz: 'Zpětná vazba pro posun vpřed.',
            ho: 'Feedback om verder te kunnen komen',
            hu: 'Visszajelzés a továbblépéshez',
            it: 'Dare feedback per progredire',
            mx: 'Retroalimentar para avanzar',
            no: 'Tilbakmelding',
            pl: 'Informacje zwrotne, aby przejść do przodu',
            si: 'Povratne informacije za napredek in razvoj',
            rs: 'Povratna informacija nam omogućava da idemo napred',
            se: 'Återkoppla för att komma vidare',
            us: 'Feedback to move forward',
        },
        backImage: {
            en: Batch3_Card13_Back_EN,
            de: null,
            ro: Batch3_Card13_Back_RO,
            bg: Batch3_Card13_Back_BG,
            hr: Batch3_Card13_Back_HR,
            cz: Batch3_Card13_Back_CZ,
            ho: Batch3_Card13_Back_HO,
            hu: Batch3_Card13_Back_HU,
            it: Batch3_Card13_Back_IT,
            mx: Batch3_Card13_Back_MX,
            no: Batch3_Card13_Back_NO,
            pl: Batch3_Card13_Back_PL,
            si: Batch3_Card13_Back_SI,
            rs: Batch3_Card13_Back_RS,
            se: Batch3_Card13_Back_SE,
            us: Batch3_Card13_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 14,
        frontSvg: Batch3_Card14_Front,
        frontTitle: {
            en: 'Influencing\n' +
                'Outwards',
            de: 'Wirken nach\n' +
                'außen',
            ro: 'Influența spre\n' +
                'Exterior',
            bg: 'Влияние навън',
            hr: 'Utjecaj prema\n' +
                'van',
            cz: 'Působení\n' +
                'navenek',
            ho: 'Invloed naar\n' +
                'Boven',
            hu: 'Kifelé Hatni',
            it: 'Influenzare\n' +
                'all’Esterno',
            mx: 'Influir Hacia\n' +
                'Arriba',
            no: 'Påvirke utover',
            pl: 'Oddziaływanie\n' +
                'na Zewnątrz',
            si: 'Vplivanje\n' +
                'Navzven',
            rs: 'Uticaj ka spolja',
            se: 'Påverka utåt',
            us: 'Influencing\n' +
                'Outwards',
        },
        frontBottomText: {
            en: 'Influencing outwards multiplies our impact',
            de: 'Den Mehrwert für den Kunden zu finden ist eine Frage der Einstellung, Methodik und Kreativität',
            ro: 'Influența spre exterior ne amplifică impactul',
            bg: 'Влиянието навън умножава нашето въздействие',
            hr: 'Utjecaj prema van povećava naš učinak',
            cz: 'Působení nahoru a navenek znásobuje náš vliv.',
            ho: 'Invloed naar boven verdubbelt onze impact',
            hu: 'A kifelé történő befolyásolás megsokszorozza hatásunkat',
            it: 'Influenzare all’esterno moltiplica il nostro impatto',
            mx: 'Influir hacia arriba multiplica nuestro impacto',
            no: 'Å påvirke utover multipliserer effekten vår',
            pl: 'Wpływanie na zewnątrz zwielokrotnia nasz wpływ',
            si: 'Vplivanje navzven razširi naš vpliv',
            rs: 'Uticaj ka spolja povećava naš učinak',
            se: 'Att påverka utåt multipliserar effekten vår',
            us: 'Influencing outwards multiplies our impact',
        },
        backImage: {
            en: Batch3_Card14_Back_EN,
            de: Batch3_Card14_Back_DE,
            ro: Batch3_Card14_Back_RO,
            bg: Batch3_Card14_Back_BG,
            hr: Batch3_Card14_Back_HR,
            cz: Batch3_Card14_Back_CZ,
            ho: Batch3_Card14_Back_HO,
            hu: Batch3_Card14_Back_HU,
            it: Batch3_Card14_Back_IT,
            mx: Batch3_Card14_Back_MX,
            no: Batch3_Card14_Back_NO,
            pl: Batch3_Card14_Back_PL,
            si: Batch3_Card14_Back_SI,
            rs: Batch3_Card14_Back_RS,
            se: Batch3_Card14_Back_SE,
            us: Batch3_Card14_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 15,
        frontSvg: Batch3_Card15_Front,
        frontTitle: {
            en: 'Discover\n' +
                'Customer Needs',
            de: 'Kunden-\n' + 'bedürfnisse\n' + 'entdecken',
            ro: 'Descoperă\n' +
                'Nevoile\n' +
                'Clientului',
            bg: 'Открий\n' +
                'нуждите на\n' +
                'клиентите',
            hr: 'Otkrijmo Potrebe\n' +
                'Klijenta',
            cz: 'Objev\n' +
                'zákazníkovy\n' +
                'potřeby',
            ho: 'Ontdek de\n' +
                'Behoeften van de\n' +
                'Klant',
            hu: 'Felfedezni a Vevő\n' +
                'Igényeit',
            it: 'Scoprire le\n' +
                'Necessità del\n' +
                'Cliente',
            mx: 'Descubrir las\n' +
                'Necesidades del\n' +
                'Cliente',
            no: 'Avdekk kundens\n' +
                'behov',
            pl: 'Odkryj Potrzeby\n' +
                'Klientów',
            si: 'Odkrivanje\n' +
                'Potreb Stranke',
            rs: 'Otkrijmo potrebe\n' +
                'klijenta',
            se: 'Upptäck kundens\n' +
                'behov',
            us: 'Discover\n' +
                'Customer Needs',
        },
        frontBottomText: {
            en: 'Finding extra value for the customer is a matter of attitude, methodology and creativity',
            de: 'Nutze die richtigen Technologien, um Nähe zu schaffen',
            ro: 'A identifica valoare adăugată pentru client este o chestiune de atitudine, metodologie și creativitate',
            bg: 'Намирането на допълнителна стойност за клиента е въпрос на отношение, методология и креативност',
            hr: 'Nalaženje dodatne vrijednosti za klijenta pitanje je stava, metodologije i kreativnosti',
            cz: 'Najít přidanou hodnotu pro zákazníka je věcí přístupu, metodiky a kreativity',
            ho: 'Extra waarde bepalen is een kwestie van instelling, methodiek en creativiteit',
            hu: 'A vevő számára megtalálni a hozzáadott értéket, hozzáállás, módszertan és kreativitás kérdése',
            it: 'Individuare un valore aggiunto per il cliente è questione di attitudine, metodologia e creatività',
            mx: 'Encontrar valor adicional para el cliente es una cuestión de actitud, metodología y creatividad',
            no: 'Å finne nytteverdi for kunden er et spørsmål om holdning, metodikk og kreativitet',
            pl: 'Znalezienie dodatkowej wartości dla klienta jest kwestią podejścia, metodologii i kreatywności',
            si: 'Najti dodano vrednost za stranko je rezultat naše naravnanosti, metodologije in kreativnosti',
            rs: 'Nalaženje dodatne vrednosti za klijenta pitanje je stava, metodologije i kreativnosti',
            se: 'Att finna nyttovärde för kunden är en fråga om hållning,metodik och kreativitet',
            us: 'Finding extra value for the customer is a matter of attitude, methodology and creativity',
        },
        backImage: {
            en: Batch3_Card15_Back_EN,
            de: Batch3_Card15_Back_DE,
            ro: Batch3_Card15_Back_RO,
            bg: Batch3_Card15_Back_BG,
            hr: Batch3_Card15_Back_HR,
            cz: Batch3_Card15_Back_CZ,
            ho: Batch3_Card15_Back_HO,
            hu: Batch3_Card15_Back_HU,
            it: Batch3_Card15_Back_IT,
            mx: Batch3_Card15_Back_MX,
            no: Batch3_Card15_Back_NO,
            pl: Batch3_Card15_Back_PL,
            si: Batch3_Card15_Back_SI,
            rs: Batch3_Card15_Back_RS,
            se: Batch3_Card15_Back_SE,
            us: Batch3_Card15_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 16,
        frontSvg: Batch3_Card16_Front,
        frontTitle: {
            en: 'Remote\n' +
                'Communication',
            de: 'Kommunikation\n' +
                'auf Distanz',
            ro: 'Comunicarea la\n' +
                'Distanță',
            bg: 'Дистанционна\n' +
                'комуникация',
            hr: 'Komunikacija na\n' +
                'daljinu',
            cz: 'Vzdálená\n' +
                'komunikace',
            ho: 'Online\n' +
                'Communication',
            hu: 'Távoli\n' +
                'Kommunikáció',
            it: 'Comunicazione a\n' +
                'Distanza',
            mx: 'Comunicación\n' +
                'Remota',
            no: 'Fjernkommunikasjon',
            pl: 'Zdalna\n' +
                'Komunikacja',
            si: 'Komuniciranje\n' +
                'na Daljavo',
            rs: 'Komunikacija na\n' +
                'daljinu',
            se: 'Fjärrkommu- nikation',
            us: 'Remote\n' +
                'Communication',
        },
        frontBottomText: {
            en: 'Utilise technology to foster relationships',
            de: 'Die Akezptanz des anderen ist der beste Treibstoff für gute Kommunikation',
            ro: 'Folosește tehnologia pentru dezvoltarea relațiilor',
            bg: 'Използвай технологията за насърчаване на взаимоотношения',
            hr: 'Koristimo tehnologiju kako bismo unaprijedili odnose',
            cz: 'Využij technologií k posílení vztahů.',
            ho: 'Gebruik technologie om relaties te verbeteren',
            hu: 'Használd a technológiát a kapcsolatok elősegítésére',
            it: 'Utilizzare la tecnologia per favorire le relazioni',
            mx: 'Utilizar la tecnología para fomentar las relaciones',
            no: 'Bruk teknologi for å fremme relasjoner',
            pl: 'Wykorzystaj technologię do budowania relacji',
            si: 'Izkoristimo tehnologijo za razvoj odnosov',
            rs: 'Koristimo tehnologiju kako bismo unapredili odnose',
            se: 'Använd teknik för att skapa relationer',
            us: 'Utilise technology to foster relationships',
        },
        backImage: {
            en: Batch3_Card16_Back_EN,
            de: Batch3_Card16_Back_DE,
            ro: Batch3_Card16_Back_RO,
            bg: Batch3_Card16_Back_BG,
            hr: Batch3_Card16_Back_HR,
            cz: Batch3_Card16_Back_CZ,
            ho: Batch3_Card16_Back_HO,
            hu: Batch3_Card16_Back_HU,
            it: Batch3_Card16_Back_IT,
            mx: Batch3_Card16_Back_MX,
            no: Batch3_Card16_Back_NO,
            pl: Batch3_Card16_Back_PL,
            si: Batch3_Card16_Back_SI,
            rs: Batch3_Card16_Back_RS,
            se: Batch3_Card16_Back_SE,
            us: Batch3_Card16_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 17,
        frontSvg: Batch3_Card17_Front,
        frontTitle: {
            en: 'Confrontation',
            de: 'Konfrontation',
            ro: 'Confruntarea',
            bg: 'Конфронтация',
            hr: 'Sukob',
            cz: 'Konfrontace',
            ho: 'Confrontatie',
            hu: 'Konfrontáció',
            it: 'Confronto',
            mx: 'Confrontación',
            no: 'Konfrontasjon',
            pl: 'Konfrontacja',
            si: 'Konfrontacija',
            rs: 'Sukob',
            se: 'Konfrontation',
            us: 'Confrontation',
        },
        frontBottomText: {
            en: 'Accepting the other person is the best fuel for good communication',
            de: 'In jeder Begegnung haben wir die Wahl, entweder unseren Superhelden oder unseren Höhlenmenschen zu zeigen',
            ro: 'Acceptarea celeilalte persoane este cel mai bun combustibil pentru o bună comunicare',
            bg: 'Приемането на другия човек е най-доброто гориво за добра комуникация',
            hr: 'Prihvaćanje druge osobe je najbolje gorivo za dobru komunikaciju',
            cz: 'Přijetí druhého člověka je nejlepší palivo pro dobrou komunikaci.',
            ho: 'Accepteer dat de ander jouw energie bron is voor goede communicatie',
            hu: 'A másik ember elfogadása a legjobb eszköz a jó kommunikációhoz',
            it: 'Accettare il fatto che l’altra persona sia il migliore carburante per una buona comunicazione',
            mx: 'Aceptar a la otra persona es el mejor combustible para una buena comunicación',
            no: 'Å akseptere den andre personen, er den beste næring for god kommunikasjon',
            pl: 'Zaakceptowanie drugiej osoby jest najlepszym poparciem dla dobrej komunikacji',
            si: 'Sprejetje druge osebe je najboljša osnova za dobro komunikacijo',
            rs: 'Prihvatanje druge osobe je najbolje gorivo za dobru komunikaciju',
            se: 'Att acceptera den andra personen att han är bästa näringen för god kommunikation',
            us: 'Accepting the other person is the best fuel for good communication',
        },
        backImage: {
            en: Batch3_Card17_Back_EN,
            de: Batch3_Card17_Back_DE,
            ro: Batch3_Card17_Back_RO,
            bg: Batch3_Card17_Back_BG,
            hr: Batch3_Card17_Back_HR,
            cz: Batch3_Card17_Back_CZ,
            ho: Batch3_Card17_Back_HO,
            hu: Batch3_Card17_Back_HU,
            it: Batch3_Card17_Back_IT,
            mx: Batch3_Card17_Back_MX,
            no: Batch3_Card17_Back_NO,
            pl: Batch3_Card17_Back_PL,
            si: Batch3_Card17_Back_SI,
            rs: Batch3_Card17_Back_RS,
            se: Batch3_Card17_Back_SE,
            us: Batch3_Card17_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 18,
        frontSvg: Batch3_Card18_Front,
        frontTitle: {
            en: 'Superhero and\n' +
                'Caveman',
            de: 'Superheld und\n' +
                'Höhlenmensch',
            ro: 'Supererou și\n' +
                'Neanderthal',
            bg: 'Супергерой и\n' +
                'пещерен човек',
            hr: 'Superheroj i\n' +
                'Spiljski čovjek',
            cz: 'Superhrdina a\n' +
                'jeskynní muž',
            ho: 'Superheld en\n' +
                'Holbewoner',
            hu: 'A Szuperhős és\n' +
                'az Ősember',
            it: 'Supereroe e\n' +
                'Uomo delle\n' +
                'Caverne',
            mx: 'Superhéroe y\n' +
                'Cavernícola',
            no: 'Superhelt og\n' +
                'huleboer',
            pl: 'Superbohater i\n' +
                'Jaskiniowiec',
            si: 'Superjunak ali\n' +
                'Jamski Človek',
            rs: 'Superheroj i\n' +
                'Pećinski čovek',
            se: 'Superhjälten och\n' +
                'grottmänniskan',
            us: 'Superhero and\n' +
                'Caveman',
        },
        frontBottomText: {
            en: 'In each encounter, we have the choice to exhibit either our Superhero or our Caveman',
            de: 'In der ebenbürtigen Kommunikation sagen uns die Menschen, was wir wissen müssen, und nicht, was wir hören wollen',
            ro: 'Cu orice prilej, noi alegem dacă ne activăm Supereroul sau Neanderthal-ul',
            bg: 'Във всяко взаимодействие имаме избор да използваме или нашия супергерой, или нашия пещерен човек',
            hr: 'U svakom susretu biramo da li ćemo pokazati svojeg unutarnjeg Superheroja ili Spiljskog čovjeka',
            cz: 'Při každém setkání je na nás, zda ukážeme našeho superhrdinu nebo našeho jeskynního muže.',
            ho: 'Bij elke ontmoeting hebben we de keuze om onze superheld of onze holbewoner te laten zien',
            hu: 'Minden egyes találkozáskor választhatunk, hogy a Szuperhősünket vagy az Ősemberünket mutatjuk be',
            it: 'In ogni incontro, abbiamo la scelta di esibire il nostro Supereroe oppure il nostro Uomo delle Caverne',
            mx: 'En cada encuentro, tenemos la opción de exhibir nuestro Superhéroe o a nuestro Cavernícola',
            no: 'I hvert møte med andre har vi valget mellom å enten vise vår superhelt eller vår huleboer',
            pl: 'W każdym spotkaniu mamy do wyboru przedstawienie Superbohatera lub Jaskiniowca',
            si: 'Vedno lahko izberemo ali želimo biti superjunak ali jamski človek',
            rs: 'U svakom susretu biramo da li ćemo pokazati svoj unutrašnjeg Superheroja ili Pećinskog čoveka',
            se: 'Vid varje möte med andra har vi valet att antingen visa vår superhjälte eller grottmänniskan',
            us: 'In each encounter, we have the choice to exhibit either our Superhero or our Caveman',
        },
        backImage: {
            en: Batch3_Card18_Back_EN,
            de: Batch3_Card18_Back_DE,
            ro: Batch3_Card18_Back_RO,
            bg: Batch3_Card18_Back_BG,
            hr: Batch3_Card18_Back_HR,
            cz: Batch3_Card18_Back_CZ,
            ho: Batch3_Card18_Back_HO,
            hu: Batch3_Card18_Back_HU,
            it: Batch3_Card18_Back_IT,
            mx: Batch3_Card18_Back_MX,
            no: Batch3_Card18_Back_NO,
            pl: Batch3_Card18_Back_PL,
            si: Batch3_Card18_Back_SI,
            rs: Batch3_Card18_Back_RS,
            se: Batch3_Card18_Back_SE,
            us: Batch3_Card18_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 19,
        frontSvg: Batch3_Card19_Front,
        frontTitle: {
            en: 'Equal\n' +
                'Communication',
            de: 'Kommunikation\n' +
                'auf Augenhöhe',
            ro: 'Comunicarea\n' +
                'Egală',
            bg: 'Равностойна\n' +
                'комуникация',
            hr: 'Ravnopravna\n' +
                'komunikacija',
            cz: 'Komunikace na\n' +
                'stejné úrovni',
            ho: 'Gelijkwaardige\n' +
                'Communicatie',
            hu: 'Egyenrangú\n' +
                'Kommunikáció',
            it: 'Comunicazione\n' +
                'allo Stesso\n' +
                'Livello',
            mx: 'Comunicación\n' +
                'Equivalente',
            no: 'Likeverdig\n' +
                'kommunikasjon',
            pl: 'Komunikacja\n' +
                'na Równym\n' +
                'SZczeblu',
            si: 'Enakovredna\n' +
                'Komunikacija',
            rs: 'Ravnopravna\n' +
                'komunikacija',
            se: 'Jämställd\n' +
                'Kommunikation',
            us: 'Equal\n' +
                'Communication',
        },
        frontBottomText: {
            en: 'In equal communication, people tell us what we need to know, rather than what we want to hear',
            de: 'Tadeln um zu motivieren',
            ro: 'În comunicarea egală, oamenii ne spun ce trebuie să știm, mai degrabă decât ce vrem să auzim',
            bg: 'При равностойното общуване хората ни казват това, което трябва да знаем, а не това, което искаме да чуем',
            hr: 'U ravnopravnoj nam komunikaciji ljudi kažu ono što moramo znati, ne samo ono što želimo čuti',
            cz: 'Při komunikaci na stejné úrovni nám lidé spíše říkají, co potřebujeme vědět, než-li to co chceme slyšet.',
            ho: 'Bij gelijkwaardige communicatie vertellen mensen ons wat we moeten weten, in plaats van wat we willen horen',
            hu: 'Az egyenlő kommunikációban az emberek arról beszélnek, amit tudnunk kell, nem pedig arról, amit hallani akarunk',
            it: 'Nella comunicazione allo stesso livello, le persone ci dicono quello che abbiamo bisogno di conoscere, piuttosto che quello che vogliamo sentirci dire.',
            mx: 'En una comunicación equivalente, las personas nos dicen lo que necesitamos saber, en lugar de lo que queremos escuchar',
            no: 'I likverdig kommunikasjon forteller folk oss hva vi trenger å vite, i stedet for hva vi vil høre',
            pl: 'W komunikacji na równym szczeblu ludzie mówią nam, co powinniśmy wiedzieć, a nie to, co chcemy usłyszeć',
            si: 'V enakovrednem odnosu nam ljudje povedo, kar moramo vedeti, in ne tistega, kar želimo slišati',
            rs: 'U ravnopravnoj komunikaciji ljudi nam kažu ono što moramo znati, ne samo ono što želimo čuti',
            se: 'I jämställd kommunikation berättar folk för oss vad vi behöver att veta, istället för vad vi vill höra',
            us: 'In equal communication, people tell us what we need to know, rather than what we want to hear',
        },
        backImage: {
            en: Batch3_Card19_Back_EN,
            de: Batch3_Card19_Back_DE,
            ro: Batch3_Card19_Back_RO,
            bg: Batch3_Card19_Back_BG,
            hr: Batch3_Card19_Back_HR,
            cz: Batch3_Card19_Back_CZ,
            ho: Batch3_Card19_Back_HO,
            hu: Batch3_Card19_Back_HU,
            it: Batch3_Card19_Back_IT,
            mx: Batch3_Card19_Back_MX,
            no: Batch3_Card19_Back_NO,
            pl: Batch3_Card19_Back_PL,
            si: Batch3_Card19_Back_SI,
            rs: Batch3_Card19_Back_RS,
            se: Batch3_Card19_Back_SE,
            us: Batch3_Card19_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 20,
        frontSvg: Batch3_Card20_Front,
        frontTitle: {
            en: 'Managing\n' +
                'Underperformance',
            de: 'Tadeln',
            ro: 'Gestiunea\n' +
                'Neperformanței',
            bg: 'Управление на\n' +
                'незадоволителното представяне',
            hr: 'Upozoravanje na\n' +
                'greške',
            cz: 'Vedení rozhovoru\n' +
                'ke zlepšení',
            ho: 'Managen van\n' +
                'Ondermaatse\n' +
                'Prestaties',
            hu: 'Az Alulteljesítés\n' +
                'Menedzselése',
            it: 'Biasimo',
            mx: 'Censurar',
            no: 'Ledelse ved avvik',
            pl: 'Zarządzanie\n' +
                'Gorszymi\n' +
                'Wynikami',
            si: 'Grajanje',
            rs: 'Upravljanje slabim\n' +
                'performansama',
            se: 'Underpresterat\n' +
                'Ledarskap',
            us: 'Managing\n' +
                'Underperformance',
        },
        frontBottomText: {
            en: 'Admonish in order to motivate',
            de: 'Feedback zur Weiterentwicklung',
            ro: 'Admonestarea pentru a motiva',
            bg: 'Изисквай, за да мотивираш',
            hr: 'Upozoravamo na greške kako bismo motivirali',
            cz: 'Vytýkáme, abychom motivovali.',
            ho: 'Corrigeren om te motiveren',
            hu: 'Kifogásolj, hogy motiválhass',
            it: 'Biasimare al fine di motivare',
            mx: 'Censurar para motivar',
            no: 'Irettesettelse for å motivere',
            pl: 'Przestrzegaj, aby motywować',
            si: 'Grajamo, da motiviramo',
            rs: 'Upravljamo slabim performansama sa ciljem motivisanja',
            se: 'Kritik för att motivera',
            us: 'Admonish in order to motivate',
        },
        backImage: {
            en: Batch3_Card20_Back_EN,
            de: Batch3_Card20_Back_DE,
            ro: Batch3_Card20_Back_RO,
            bg: Batch3_Card20_Back_BG,
            hr: Batch3_Card20_Back_HR,
            cz: Batch3_Card20_Back_CZ,
            ho: Batch3_Card20_Back_HO,
            hu: Batch3_Card20_Back_HU,
            it: Batch3_Card20_Back_IT,
            mx: Batch3_Card20_Back_MX,
            no: Batch3_Card20_Back_NO,
            pl: Batch3_Card20_Back_PL,
            si: Batch3_Card20_Back_SI,
            rs: Batch3_Card20_Back_RS,
            se: Batch3_Card20_Back_SE,
            us: Batch3_Card20_Back_US,
        }
    },
    {
        batchNumber: 3,
        memoryCardNumber: 21,
        frontSvg: Batch3_Card21_Front,
        frontTitle: {
            en: 'Feedback',
            ro: 'Feedback',
            de: 'Feedback',
            hr: 'Davanje povratne\n' +
                'informacije',
            cz: 'Zpětná vazba',
            ho: 'Feedback',
            hu: 'Feedback',
            it: 'Feedback',
            mx: 'Retroalimentar',
            no: 'Tilbakmelding',
            pl: 'Feedback',
            si: 'Povratna\n' +
                'Informacija',
            rs: 'Povratna\n' +
                'informacija',
            se: 'Återkoppling',
            us: 'Feedback',
        },
        frontBottomText: {
            en: 'Feedback to move forward',
            ro: 'Feedback pentru a progresa',
            hr: 'Povratna informacija omogućava nam da idemo naprijed',
            cz: 'Zpětná vazba pro posun vpřed.',
            ho: 'Feedback om verder te kunnen komen',
            hu: 'Visszajelzés a továbblépéshez',
            it: 'Dare feedback per progredire',
            mx: 'Retroalimentar para avanzar',
            no: 'Tilbakemelding for å komme videre',
            pl: 'Informacje zwrotne, aby przejść do przodu',
            si: 'Povratne informacije za napredek in razvoj',
            rs: 'Povratna informacija nam omogućava da idemo napred',
            se: 'Återkoppla för att komma vidare',
            us: 'Give feedback to move forward',
        },
        backImage: {
            en: Batch3_Card21_Back_EN,
            ro: Batch3_Card21_Back_RO,
            hr: Batch3_Card21_Back_HR,
            cz: Batch3_Card21_Back_CZ,
            ho: Batch3_Card21_Back_HO,
            hu: Batch3_Card21_Back_HU,
            it: Batch3_Card21_Back_IT,
            mx: Batch3_Card21_Back_MX,
            no: Batch3_Card21_Back_NO,
            pl: Batch3_Card21_Back_PL,
            si: Batch3_Card21_Back_SI,
            rs: Batch3_Card21_Back_RS,
            se: Batch3_Card21_Back_SE,
            us: Batch3_Card21_Back_US,
        }
    },


    // Batch 4
    {
        batchNumber: 4,
        memoryCardNumber: 1,
        frontSvg: Batch4_Card1_Front,
        frontTitle: {
            en: 'Continuous\n' +
                'Learning',
            de: 'Kontinuierliches\n' +
                'Lernen',
            ro: 'Învățare\n' +
                'Continuă',
            bg: 'Непрекъснато\n' +
                'учене',
            hr: 'Kontinuirano\n' +
                'Učenje',
            cz: 'Neustálé učení',
            fr: 'Développement\n' +
                'continue',
            hu: 'Folyamatos\n' +
                'Tanulás',
            it: 'Apprendimento\n' +
                'Continuo',
            mx: 'Aprendizaje\n' +
                'Continuo',
            no: 'Kontinuerlig\n' +
                'Utvikling',
            pl: 'Kontynuacja\n' +
                'Uczenie się',
            si: 'Nenehno\n' +
                'Učenje',
            rs: 'Kontinuirano\n' +
                'učenje',
            se: 'Medvetet\n' +
                'lärande',
            us: 'Continuous\n' +
                'Learning',
        },
        frontBottomText: {
            en: 'Raise the bar; learning is not a one-time activity',
            de: 'Legen Sie die Messlatte höher; Lernen ist keine einmalige Aktivität',
            ro: 'Ridică ștacheta; învățarea nu este o activitate ocazională',
            bg: 'Вдигнете летвата; ученето не е еднократна дейност',
            hr: 'Podignimo ljestvicu; učenje nije jednokratna aktivnost',
            cz: 'Zvedni si laťku; učení není jednorázová aktivita',
            fr: 'Relever la barre ; le développement n’est pas une activité ponctuelle',
            hu: 'Emeld a mércét; a tanulás nem egyszeri tevékenység',
            it: 'Alzare l’asticella; l’apprendimento non è un’attività una tantum',
            mx: 'Aumenta la exigencia; aprender no es una actividad de una sola vez',
            no: 'Hev standaren; utvikling er ikke en engangsaktivitet.',
            pl: 'Podnieść poprzeczkę; nauka nie jest czynnością jednorazową',
            si: 'Od sebe zahtevajmo več; učenje se nikoli ne konča.',
            rs: 'Podignimo očekivanja; učenje nije jednokratna aktivnos',
            se: 'Höj ribban; lärandet är inte en engångsföreteelse',
            us: 'Raise the bar; learning is not a one-time activity',
        },
        backImage: {
            en: Batch4_Card1_Back_EN,
            de: Batch4_Card1_Back_DE,
            ro: Batch4_Card1_Back_RO,
            bg: Batch4_Card1_Back_BG,
            hr: Batch4_Card1_Back_HR,
            cz: Batch4_Card1_Back_CZ,
            fr: Batch4_Card1_Back_FR,
            hu: Batch4_Card1_Back_HU,
            it: Batch4_Card1_Back_IT,
            mx: Batch4_Card1_Back_MX,
            no: Batch4_Card1_Back_NO,
            pl: Batch4_Card1_Back_PL,
            si: Batch4_Card1_Back_SI,
            rs: Batch4_Card1_Back_RS,
            se: Batch4_Card1_Back_SE,
            us: Batch4_Card1_Back_US,
        }
    },
    {
        batchNumber: 4,
        memoryCardNumber: 2,
        frontSvg: Batch4_Card2_Front,
        frontTitle: {
            en: 'Personal\n' +
                'Preferences',
            de: 'Persönliche\n' +
                'Präferenzen',
            ro: 'Preferințe\n' +
                'Personale',
            bg: 'Лични\n' +
                'предпочитания',
            hr: 'Osobne\n' +
                'Preferencije',
            cz: 'Osobní\n' +
                'preference',
            fr: 'Préférences\n' +
                'personnelles',
            hu: 'Személyes\n' +
                'Preferenciák',
            it: 'Preferenze\n' +
                'Personali',
            mx: 'Preferencias\n' +
                'Personales',
            no: 'Personlige\n' +
                'Preferanser',
            pl: 'Preferencje\n' +
                'Osobiste',
            si: 'Osebne\n' +
                'Preference',
            rs: 'Lične\n' +
                'preference',
            se: 'Personliga\n' +
                'preferenser',
            us: 'Personal\n' +
                'Preferences',
        },
        frontBottomText: {
            en: '“Every advance, every conceptual achievement of mankind has been connected with an advance in self-awareness.”\n' +
                'CARL GUSTAV JUNG',
            de: '“Jeder Fortschritt, jede konzeptionelle Errungenschaft der Menschheit war mit einem Fortschritt in der Selbsterkenntnis verbunden.”\n' +
                'CARL GUSTAV JUNG',
            ro: '“Orice progres, orice realizare conceptuală a omenirii, a fost legată de un progres în cunoașterea de sine.”\n' +
                'CARL GUSTAV JUNG',
            bg: '„Всеки напредък, всяко концептуално постижение на човечеството е свързано с напредък в самопознаването.“\n' +
                'CARL GUSTAV JUNG',
            hr: '“Svaki napredak, svako konceptualno postignuće čovječanstva bilo je povezano s napretkom u samospoznaji.”\n' +
                'CARL GUSTAV JUNG',
            cz: 'Každý pokrok, každý koncepční úspěch lidstva byl spojen s pokrokem v sebeuvědomění.”\n' +
                'CARL GUSTAV JUNG',
            fr: '“Chaque avancée, chaque réalisation conceptuelle de l’humanité a été liée à une avancée de la conscience de soi.”\n' +
                'CARL GUSTAV JUNG',
            hu: '“Az emberiség minden előrelépése, minden elméleti teljesítménye összekapcsolódott az öntudat fejlődésével.”\n' +
                'CARL GUSTAV JUNG',
            it: '“Ciascun progresso, ciascuna conquista concettuale dell’umanità è stata correlata con un progresso nella autoconsapevolezza.”\n' +
                'CARL GUSTAV JUNG',
            mx: '“Cada avance, cada logro conceptual de la humanidad se ha relacionado con un avance en la autoconciencia.”\n' +
                'CARL GUSTAV JUNG',
            no: 'Hvert fremskritt, enhver konseptuell prestasjon av menneskeheten, har vært forbundet med et fremskritt i selvbevisstheten.”\n' +
                'CARL GUSTAV JUNG',
            pl: '„Każdy postęp, każde koncepcyjne osiągnięcie ludzkości było związane z postępem w samoświadomości.”\n' +
                'CARL GUSTAV JUNG',
            si: '“Vsak napredek, vsak konceptualni dosežek človeštva je povezan z napredkom v samozavedanju.”\n' +
                'CARL GUSTAV JUNG',
            rs: '“Svaki napredak, svako konceptualno dostignuće čovečanstva povezano je sa napretkom u samosvesti.”\n' +
                'CARL GUSTAV JUNG',
            se: 'Every advance, every conceptual achievement of mankind has been connected with an advance in self-awareness.”\n' +
                'CARL GUSTAV JUNG',
            us: 'Every advance, every conceptual achievement of mankind has been connected with an advance in self-awareness.”\n' +
                'CARL GUSTAV JUNG',
        },
        backImage: {
            en: Batch4_Card2_Back_EN,
            de: Batch4_Card2_Back_DE,
            ro: Batch4_Card2_Back_RO,
            bg: Batch4_Card2_Back_BG,
            hr: Batch4_Card2_Back_HR,
            cz: Batch4_Card2_Back_CZ,
            fr: Batch4_Card2_Back_FR,
            hu: Batch4_Card2_Back_HU,
            it: Batch4_Card2_Back_IT,
            mx: Batch4_Card2_Back_MX,
            no: Batch4_Card2_Back_NO,
            pl: Batch4_Card2_Back_PL,
            si: Batch4_Card2_Back_SI,
            rs: Batch4_Card2_Back_RS,
            se: Batch4_Card2_Back_SE,
            us: Batch4_Card2_Back_US,
        }
    },
    {
        batchNumber: 4,
        memoryCardNumber: 3,
        frontSvg: Batch4_Card3_Front,
        frontTitle: {
            en: 'Recognising\n' +
                'Preferences',
            de: 'Erkennen von\n' +
                'Präferenzen',
            ro: 'Recunoașterea\n' +
                'Preferințelor',
            bg: 'Разпознаване на\n' +
                'предпочитанията',
            hr: 'Prepoznavanje\n' +
                'Preferencija',
            cz: 'Rozpoznávání\n' +
                'preferencí',
            fr: 'Reconnaître les\n' +
                'préférences',
            hu: 'Preferenciák\n' +
                'Felismerése',
            it: 'Riconoscere le\n' +
                'Preferenze',
            mx: 'Reconociendo\n' +
                'Preferencias',
            no: 'Gjenkjenne\n' +
                'Preferanser',
            pl: 'Rozpoznawanie\n' +
                'Preferencji',
            si: 'Prepoznavanje\n' +
                'Preferenc',
            rs: 'Prepoznavanje\n' +
                'Preferenci',
            se: 'Igenkännande\n' +
                'preferenser',
            us: 'Recognising\n' +
                'Preferences',
        },
        frontBottomText: {
            en: 'Are we smart enough to recognise and adapt?',
            de: 'Sind wir klug genug, das zu erkennen und uns anzupassen?',
            ro: 'Avem abilitatea să recunoaștem și să ne adaptăm?',
            bg: 'Достатъчно умни ли сме да разпознаем и да се адаптираме?',
            hr: 'Jesmo li dovoljno mudri da prepoznamo i prilagodimo se?',
            cz: 'Jsme dostatečně moudří, abychom rozpoznali a adaptovali se?',
            fr: 'Sommes-nous assez intelligents pour reconnaître et s’adapter ?',
            hu: 'Elég okosak vagyunk ahhoz, hogy felismerjük és alkalmazkodjunk?',
            it: 'Siamo abbastanza intelligenti da riconoscere e adattarci?',
            mx: '¿Somos lo suficientemente hábiles para reconocer y adaptarnos?',
            no: 'Er vi flinke nok til å gjennkjenne og tilpasse oss?',
            pl: 'Czy jesteśmy wystarczająco inteligentni, aby rozpoznać i dostosować się?',
            si: 'Smo dovolj modri, da prepoznamo komunikacijski stil sogovornika in se prilagodimo?',
            rs: 'Da li smo dovoljno pametni da prepoznamo i prilagodimo se?',
            se: 'Är vi smarta nog att känna igen och anpassa oss?',
            us: 'Are we smart enough to recognise and adapt?',
        },
        backImage: {
            en: Batch4_Card3_Back_EN,
            de: Batch4_Card3_Back_DE,
            ro: Batch4_Card3_Back_RO,
            bg: Batch4_Card3_Back_BG,
            hr: Batch4_Card3_Back_HR,
            cz: Batch4_Card3_Back_CZ,
            fr: Batch4_Card3_Back_FR,
            hu: Batch4_Card3_Back_HU,
            it: Batch4_Card3_Back_IT,
            mx: Batch4_Card3_Back_MX,
            no: Batch4_Card3_Back_NO,
            pl: Batch4_Card3_Back_PL,
            si: Batch4_Card3_Back_SI,
            rs: Batch4_Card3_Back_RS,
            se: Batch4_Card3_Back_SE,
            us: Batch4_Card3_Back_US,
        }
    },
    {
        batchNumber: 4,
        memoryCardNumber: 4,
        frontSvg: Batch4_Card4_Front,
        frontTitle: {
            en: 'Team\n' +
                'Effectiveness',
            de: 'TeamEffektivität',
            ro: 'Eficiența\n' +
                'Echipei',
            bg: 'Екипна\n' +
                'ефективност',
            hr: 'Timska\n' +
                'Učinkovitost',
            cz: 'Efektivita týmu',
            fr: 'Efficacité de\n' +
                'l’équipe',
            hu: 'Csapat\n' +
                'Hatékonyság',
            it: 'L’ Efficacia del\n' +
                'Team',
            mx: 'Eficacia del\n' +
                'Equipo',
            no: 'Teamets\n' +
                'Suksessfaktorer',
            pl: 'Efektywność\n' +
                'Zespołu',
            si: 'Uspešni Timi',
            rs: 'Timska\n' +
                'efektivnost',
            se: 'Teamets\n' +
                'effektivitet',
            us: 'Are we smart enough to recognise and adapt?',
        },
        frontBottomText: {
            en: 'Take an active role for team success',
            de: 'Nehmen Sie eine aktive Rolle für den Teamerfolg ein',
            ro: 'Asumă-ți un rol activ pentru succesul echipei',
            bg: 'Поемете активна роля за успеха на екипа',
            hr: 'Preuzmimo aktivnu ulogu u uspjehu tima',
            cz: 'Přijmi aktivní roli pro úspěch týmu',
            fr: 'Jouez un rôle actif dans la réussite de l’équipe',
            hu: 'Vállalj aktív szerepet a csapat sikerében',
            it: 'Contribuire attivamente al successo del team',
            mx: 'Juegue un papel activo para alcanzar el éxito del equipo',
            no: 'Ta en aktiv rolle for teamets suksess',
            pl: 'Odegraj aktywną rolę na rzecz sukcesu zespołu',
            si: 'Prevzemite aktivno vlogo za uspeh ekipe',
            rs: 'Uzimamo aktivnu ulogu za uspeh tima',
            se: 'Ta en aktiv roll för teamets framgång',
            us: 'Take an active role for team success',
        },
        backImage: {
            en: Batch4_Card4_Back_EN,
            de: Batch4_Card4_Back_DE,
            ro: Batch4_Card4_Back_RO,
            bg: Batch4_Card4_Back_BG,
            hr: Batch4_Card4_Back_HR,
            cz: Batch4_Card4_Back_CZ,
            fr: Batch4_Card4_Back_FR,
            hu: Batch4_Card4_Back_HU,
            it: Batch4_Card4_Back_IT,
            mx: Batch4_Card4_Back_MX,
            no: Batch4_Card4_Back_NO,
            pl: Batch4_Card4_Back_PL,
            si: Batch4_Card4_Back_SI,
            rs: Batch4_Card4_Back_RS,
            se: Batch4_Card4_Back_SE,
            us: Batch4_Card4_Back_US,
        }
    },
    {
        batchNumber: 4,
        memoryCardNumber: 5,
        frontSvg: Batch4_Card5_Front,
        frontTitle: {
            en: 'We Are All\n' +
                'Different',
            de: 'Wir sind alle\n' +
                'verschieden',
            ro: 'Suntem cu\n' +
                'Toții Diferiți',
            bg: 'Всички сме\n' +
                'различни',
            hr: 'Svi Smo\n' +
                'Različiti',
            cz: 'Každý jsme\n' +
                'jiný',
            fr: 'Nous\n' +
                'sommes tous\n' +
                'différents',
            hu: 'Mindannyian\n' +
                'Mások\n' +
                'Vagyunk',
            it: 'Siamo Tutti\n' +
                'Differenti',
            mx: 'Todos Somos\n' +
                'Diferentes',
            no: 'Vi er alle\n' +
                'forskjellige',
            pl: 'Wszyscy\n' +
                'Jesteśmy\n' +
                'Różni',
            si: 'Vsi Smo\n' +
                'Drugačni',
            rs: 'Svi smo\n' +
                'različiti',
            se: 'Vi är alla\n' +
                'olika',
            us: 'We Are All\n' +
                'Different',
        },
        frontBottomText: {
            en: '“Only through our connectedness to others can we really know and enhance the self. And only through our working on the self can we begin to enhance our connectedness to others.”\n' +
                'HARRIET GOLDHOR LERNER',
            de: 'Nur durch unsere Verbundenheit mit anderen können wir unser Selbst wirklich kennen und verbessern. Und nur wenn wir an unserem Selbst arbeiten, können wir beginnen, unsere Verbundenheit mit anderen zu verbessern.\n' +
                'HARRIET GOLDHOR LERNER',
            ro: '“Numai prin conectarea la ceilalți putem să ne cunoaștem și să ne dezvoltăm sinele. Și numai prin lucrul cu sinele putem să ne dezvoltăm capacitatea de conectare cu ceilalți.”\n' +
                'HARRIET GOLDHOR LERNER',
            bg: '„Само чрез нашата свързаност с другите можем наистина да опознаем и подобрим себе си. И само чрез нашата работа върху себе си можем да започнем да подобряваме връзката си с другите.”\n' +
                'HARRIET GOLDHOR LERNER',
            hr: 'Samo našom povezanošću s drugima možemo doista spoznati i unaprijediti sebe. I samo radom na sebi možemo početi povećavati svoju povezanost s drugima.”\n' +
                'HARRIET GOLDHOR LERNER',
            cz: '“Pouze prostřednictvím našeho spojení s ostatními můžeme skutečně poznat a zlepšit své já. A pouze díky práci na sobě můžeme začít posilovat naše spojení s ostatními.”\n' +
                'HARRIET GOLDHOR LERNER',
            fr: '“Ce n’est qu’à travers notre connexion aux autres que nous pouvons vraiment connaître et améliorer le soi. Et ce n’est qu’en travaillant sur le soi que nous pouvons commencer à améliorer notre connexion aux autres.”\n' +
                'HARRIET GOLDHOR LERNER',
            hu: '„Csak a másokhoz fűződő kapcsolatunkon keresztül tudjuk igazán megismerni és fejleszteni önmagunkat. És csak az önmagunkon végzett munkánkkal kezdhetjük meg erősíteni kapcsolatunkat másokkal.”\n' +
                'HARRIET GOLDHOR LERNER',
            it: '“Soltanto attraverso la nostra connessione con gli altri, possiamo davvero conoscere e migliorare noi stessi. E soltanto attraverso il lavoro con noi stessi, possiamo cominciare a migliorare la nostra connessione con gli altri.”\n' +
                'HARRIET GOLDHOR LERNER',
            mx: '“Solo a través de nuestra conexión con los demás podemos realmente conocer y mejorar el yo. Y solo a través de trabajar en uno mismo podemos comenzar a mejorar nuestra conexión con los demás”.\n' +
                'HARRIET GOLDHOR LERNER',
            no: 'Bare gjennom vår tilknytning til andre, kan vi virkelig lære å kjenne og forbedre oss selv. Og bare gjennom vårt arbeid med oss selv, kan vi begynne å forbedre vår tilknytning til andre.”\n' +
                'HARRIET GOLDHOR LERNER',
            pl: '„Tylko dzięki naszej łączności z innymi możemy naprawdę poznać i udoskonalić siebie. I tylko poprzez naszą pracę nad sobą możemy zacząć wzmacniać naszą więź z innymi.”\n' +
                'HARRIET GOLDHOR LERNER',
            si: 'Samo s svojo povezanostjo z drugimi lahko resnično spoznamo in izboljšamo sebe. In le z delom na sebi krepimo našo povezanost z drugimi.”\n' +
                'HARRIET GOLDHOR LERNER',
            rs: '“Samo kroz našu povezanost sa drugima možemo zaista spoznati i poboljšati sebe. I samo kroz naš rad na sebi možemo početi da poboljšavamo našu povezanost sa drugima.”\n' +
                'HARRIET GOLDHOR LERNER',
            se: '“Only through our connectedness to others can we really know and enhance the self. And only through our working on the self can we begin to enhance our connectedness to others.”\n' +
                'HARRIET GOLDHOR LERNER',
            us: '“Only through our connectedness to others can we really know and enhance the self. And only through our working on the self can we begin to enhance our connectedness to others.”\n' +
                'HARRIET GOLDHOR LERNER',
        },
        backImage: {
            en: Batch4_Card5_Back_EN,
            de: Batch4_Card5_Back_DE,
            ro: Batch4_Card5_Back_RO,
            bg: Batch4_Card5_Back_BG,
            hr: Batch4_Card5_Back_HR,
            cz: Batch4_Card5_Back_CZ,
            fr: Batch4_Card5_Back_FR,
            hu: Batch4_Card5_Back_HU,
            it: Batch4_Card5_Back_IT,
            mx: Batch4_Card5_Back_MX,
            no: Batch4_Card5_Back_NO,
            pl: Batch4_Card5_Back_PL,
            si: Batch4_Card5_Back_SI,
            rs: Batch4_Card5_Back_RS,
            se: Batch4_Card5_Back_SE,
            us: Batch4_Card5_Back_US,
        }
    },


    // Jokers
    {
        batchNumber: 5,
        memoryCardNumber: 1,
        frontSvg: Jokers_Card1_Front,
        frontTitle: {
            en: 'Visions\n' +
                'Determine Our\n' +
                'Behaviour',
            de: 'Vorstellungen\n' +
                'bestimmen\n' +
                'unser\n' +
                'Verhalten',
            ro: 'Viziunile Ne\n' +
                'Determină\n' +
                'Comporta-\n' + 'mentul',
            bg: 'Представите\n' +
                'определят\n' +
                'нашето\n' +
                'поведение',
            hr: 'Vizije\n' +
                'Određuju Naše\n' +
                'Ponašanje',
            cz: 'Představy\n' +
                'určují naše\n' +
                'chování',
            ho: 'Voorstellingen\n' +
                'Bepalen Ons\n' +
                'Gedrag',
            hu: 'Magatartá-\n' +
                'sunkat\n' +
                'Elképzelések\n' +
                'Határozzák\n' +
                'Meg',
            it: 'Le Visioni\n' +
                'Determinano\n' +
                'i Nostri\n' +
                'Comporta\n' +
                '-menti',
            mx: 'Las Visiones\n' +
                'Determinan\n' +
                'Nuestra\n' +
                'Conducta',
            no: 'Forestillinger\n' +
                'Bestemmer\n' +
                'Våre\n' +
                'Handlinger',
            pl: 'Wizje\n' +
                'Określają\n' +
                'Nasze\n' +
                'Zachowanie',
            si: 'Vizije Določajo\n' +
                'Naše Vedenje',
            rs: 'Vizije\n' +
                'Određuju Naše\n' +
                'Ponašanje',
            se: 'Föreställningar\n' +
                'styr vårt\n' +
                'handlande',
            us: 'Visions\n' +
                'Determine\n' +
                'Our Behavior',
        },
        subTitle: {
            en: 'The choice is ours',
            de: 'Wir haben die Wahl',
            ro: 'Noi alegem',
            bg: 'Изборът е наш',
            hr: 'Izbor je na nama',
            cz: 'Volba je na nás',
            ho: 'De keuze is aan ons',
            hu: 'Miénk a választás',
            it: 'La scelta è nostra',
            mx: 'La elección es nuestra',
            no: 'Valget er vårt',
            pl: 'Wybór należy do nas',
            si: 'Izbira je naša',
            rs: 'Izbor je na nama',
            se: 'Valet är ditt',
            us: 'Inlärningen stoppar aldrig',
        },
        frontBottomText: {
            en: '“You become what you think about all day long.”\n' +
                'RALPH WALDO EMERSON',
            de: '“Man wird zu dem, woran man den ganzen Tag lang denkt.”\n' +
                'RALPH WALDO EMERSON',
            ro: '“Vei deveni ceea ce obișnuești să gândești.”\n' +
                'RALPH WALDO EMERSON',
            bg: '„Превръщаш се в това, за което мислиш по цял ден.“\n' +
                'RALPH WALDO EMERSON',
            hr: '“Postajemo ono o čemu najviše razmišljamo.”\n' +
                'RALPH WALDO EMERSON',
            cz: '“Stáváš se tím, o čem přemýšlíš celý den.”\n' +
                'RALPH WALDO EMERSON',
            ho: '“Je wordt waar je de hele dag aan denkt”\n' +
                'RALPH WALDO EMERSON',
            hu: '“Azzá válsz, amire egész nap gondolsz.”\n' +
                'RALPH WALDO EMERSON',
            it: '“Diveniamo ciò che pensiamo durante tutto il giorno.”\n' +
                'RALPH WALDO EMERSON',
            mx: '“Te conviertes en aquello en lo que piensas durante todo el día.”\n' +
                'RALPH WALDO EMERSON',
            no: '“Du blir det du tenker på hele dagen.”\n' +
                'RALPH WALDO EMERSON',
            pl: '“Stajesz się tym, o czym myślisz przez cały dzień.”\n' +
                'RALPH WALDO EMERSON',
            si: '“Postaneš tisto, o čemer razmišljaš ves dan.”\n' +
                'RALPH WALDO EMERSON',
            rs: '“Postajemo ono o čemu najviše razmišljamo.”\n' +
                'RALPH WALDO EMERSON',
            se: '“You become what you think about all day long.”\n' +
                'RALPH WALDO EMERSON',
            us: '“You become what you think about all day long.”\n' +
                'RALPH WALDO EMERSON',
        },
        backImage: {
            en: Jokers_Card1_Back_EN,
            de: Jokers_Card1_Back_DE,
            ro: Jokers_Card1_Back_RO,
            bg: Jokers_Card1_Back_BG,
            hr: Jokers_Card1_Back_HR,
            cz: Jokers_Card1_Back_CZ,
            ho: Jokers_Card1_Back_HO,
            hu: Jokers_Card1_Back_HU,
            it: Jokers_Card1_Back_IT,
            mx: Jokers_Card1_Back_MX,
            no: Jokers_Card1_Back_NO,
            pl: Jokers_Card1_Back_PL,
            si: Jokers_Card1_Back_SI,
            rs: Jokers_Card1_Back_RS,
            se: Jokers_Card1_Back_SE,
            us: Jokers_Card1_Back_US,
        }
    },
    {
        batchNumber: 5,
        memoryCardNumber: 2,
        frontSvg: Jokers_Card2_Front,
        frontTitle: {
            en: 'Silence Gives\n' +
                'Immense\n' +
                'Power',
            de: 'Die Macht des\n' +
                'Schweigens',
            ro: 'Tăcerea\n' +
                'Dă Putere\n' +
                'Imensă',
            bg: 'Мълчанието\n' +
                'дава\n' +
                'огромна\n' +
                'сила',
            hr: 'Šutnja je Moć',
            cz: 'Mlčení má\n' +
                'neuvěřitelnou\n' +
                'moc',
            ho: 'Stilte Geeft\n' +
                'Enorme\n' +
                'Kracht',
            hu: 'A Csend\n' +
                'Hatalmas\n' +
                'Erőt Ad',
            it: 'Il Silenzio\n' +
                'Dona un\n' +
                'Immenso\n' +
                'Potere',
            mx: 'El Silencio da\n' +
                'un Inmenso\n' +
                'Poder',
            no: 'Taushet Gir\n' +
                'Enorm Kraft',
            pl: 'Cisza Daje\n' +
                'Ogromną\n' +
                'Moc',
            si: 'Tišina Ima\n' +
                'Neverjetno\n' +
                'Moč',
            rs: 'Ćutanje Daje\n' +
                'Izuzetnu Moć',
            se: 'Tystnaden\n' +
                'är en enorm\n' +
                'kraft',
            us: 'Silence Gives\n' +
                'Immense\n' +
                'Power',
        },subTitle: {
            en: 'Use it wisely',
            de: 'Setze sie weise ein',
            ro: 'Folosește-o cu înțelepciune',
            bg: 'Иползвай я мъдро',
            hr: 'Koristimo ju mudro',
            cz: 'Používej ho moudře',
            ho: 'Gebruik het verstandig',
            hu: 'Használd bölcsen',
            it: 'Usiamolo saggiamente',
            mx: 'Usalo sabiamente',
            no: 'Mądrze korzystaj z niej',
            pl: 'Mądrze korzystaj z niej',
            si: 'Uporabi jo modro',
            rs: 'Koristimo ga mudro',
            se: 'Använd den förnuftigt',
            us: 'Use it wisely',
        },
        frontBottomText: {
            en: '“Nothing strengthens authority so much as silence.”\n' +
                'LEONARDO DA VINCI',
            de: '“Nichts stärkt die Autorität so sehr wie das Schweigen.”\n' +
                'LEONARDO DA VINCI',
            ro: '“Nimic nu întărește autoritatea mai mult ca tăcerea.”\n' +
                'LEONARDO DA VINCI',
            bg: '„Нищо не засилва авторитета така, както мълчанието.“\n' +
                'LEONARDO DA VINCI',
            hr: '“Ništa ne ojačava autoritet kao šutnja.”\n' +
                'LEONARDO DA VINCI',
            cz: 'Nic neposílí autoritu tak jako ticho.”\n' +
                'LEONARDO DA VINCI',
            ho: '“Niets versterkt autoriteit zozeer als stilte.”\n' +
                'LEONARDO DA VINCI',
            hu: '“Semmi sem erősíti a tekintélyt úgy, mint a csend.”\n' +
                'LEONARDO DA VINCI',
            it: '“Niente rafforza l’ autorevolezza come il silenzio.”\n' +
                'LEONARDO DA VINCI',
            mx: '“Nada fortalece más la autoridad que el silencio.”\n' +
                'LEONARDO DA VINCI',
            no: '“Ingenting styrker autoriteten så mye som stillhet.”\n' +
                'LEONARDO DA VINCI',
            pl: '“Nic nie wzmacnia autorytet tak bardzo jak milczenie.”\n' +
                'LEONARDO DA VINCI',
            si: '“Nič ne krepi avtoritete tako kot tišina.”\n' +
                'LEONARDO DA VINCI',
            rs: '“Ništa ne ojačava autoritet kao ćutanje.”\n' +
                'LEONARDO DA VINČI',
            se: '“Nothing strengthens authority so much as silence.”\n' +
                'LEONARDO DA VINCI',
            us: '“Nothing strengthens authority so much as silence.”\n' +
                'LEONARDO DA VINCI',
        },
        backImage: {
            en: Jokers_Card2_Back_EN,
            de: Jokers_Card2_Back_DE,
            ro: Jokers_Card2_Back_RO,
            bg: Jokers_Card2_Back_BG,
            hr: Jokers_Card2_Back_HR,
            cz: Jokers_Card2_Back_CZ,
            ho: Jokers_Card2_Back_HO,
            hu: Jokers_Card2_Back_HU,
            it: Jokers_Card2_Back_IT,
            mx: Jokers_Card2_Back_MX,
            no: Jokers_Card2_Back_NO,
            pl: Jokers_Card2_Back_PL,
            si: Jokers_Card2_Back_SI,
            rs: Jokers_Card2_Back_RS,
            se: Jokers_Card2_Back_SE,
            us: Jokers_Card2_Back_US,
        }
    },
    {
        batchNumber: 5,
        memoryCardNumber: 3,
        frontSvg: Jokers_Card3_Front,
        frontTitle: {
            en: 'Our Growing\n' +
                'Mind',
            de: 'Persönliche\n' +
                'Weiter-\n' + 'entwicklung',
            ro: 'Mintea\n' +
                'Noastră Se\n' +
                'Dezvoltă',
            bg: 'Нашият\n' +
                'развиващ\n' +
                'се ум',
            hr: 'Naša Rastuća\n' +
                'Svijest',
            cz: 'Naše\n' +
                'rostoucí\n' +
                'mysl',
            ho: 'Onze\n' +
                'Groeiende\n' +
                'Geest',
            hu: 'Növekvő\n' +
                'Elménk',
            it: 'La Nostra\n' +
                'Crescita\n' +
                'Mentale',
            mx: 'Nuestra\n' +
                'Mente en\n' +
                'Crecimiento',
            no: 'Vårt\n' +
                'Voksende\n' +
                'Sinn',
            pl: 'Nasz\n' +
                'Rozwijający\n' +
                'się Umysł',
            si: 'Neskončni\n' +
                'Um',
            rs: 'Naš\n' +
                'Rastući Um',
            se: 'Vårt växande\n' +
                'sinne',
            us: 'Our Growing\n' +
                'Mind',
        },
        subTitle: {
            en: 'Learning never ends',
            de: 'Lebenslanges Lernen',
            ro: 'Învățarea nu are sfârșit',
            bg: 'Ученето никога не свършва',
            hr: 'Učenje nikada ne prestaje',
            cz: 'Učení nikdy nekončí',
            ho: 'Leren stopt nooit',
            hu: 'A tanulás soha nem ér véget',
            it: 'Apprendere non finisce mai',
            mx: 'El aprendizaje nunca termina',
            no: 'Læring slutter aldri',
            pl: 'Uczenie się nigdy się nie kończy',
            si: 'Učenje se nikoli ne konča',
            rs: 'Učenje nikada ne prestaje',
            se: 'Inlärningen stoppar aldrig',
            us: 'Learning never ends',
        },
        frontBottomText: {
            en: 'The day you think you know everything is the day you have the most yet to learn.”\n' +
                'A.J. DARKHOLME, RISE OF THE MORNINGSTAR',
            de: '“Der Tag, an dem Du glaubst, Du wüsstest alles, ist der Tag, an dem Du am meisten zu lernen hast.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            ro: '“Ziua în care crezi că știi totul este ziua în care ai încă cele mai multe de învățat.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            bg: '„Денят, в който помислиш, че знаеш всичко, е денят, в който ти остава да учиш най-много.“\n' +
                'A.J. DARKHOLME, RISE OF THE MORNINGSTAR',
            hr: '“Dan kada mislimo da sve znamo je dan kada moramo najviše učiti.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            cz: '“Den, kdy si myslíte, že víte všechno, je dnem, kdy se toho musíte ještě hodně naučit.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            ho: '“De dag dat je denkt dat je alles weet, is de dag waarop je het meeste nog moet leren.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            hu: 'Azon a napon, amikor úgy gondolod, hogy mindent tudsz, kell a legtöbbet még megtanulnod.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            it: '“Il giorno in cui pensi di conoscere tutto, è il giorno in cui hai ancora molto da imparare.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            mx: '“El día que crees que lo sabes todo, es el día que aún tienes más por aprender.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            no: '“Den dagen du tror du vet alt, er den dagen du har mest å lære”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            pl: '“Dzień, w którym myślisz, że wiesz wszystko, jest dniem, w którym masz najwięcej do nauczenia się.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            si: '“Tisti dan, ko misliš, da vse veš, je dan, ko se moraš naučiti še največ.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            rs: '“Dan kada mislimo da sve znamo je dan kada moramo najviše učiti.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            se: '“The day you think you know everything is the day you have the most yet to learn.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
            us: '“The day you think you know everything is the day you have the most yet to learn.”\n' +
                'A.J. DA R K H O L M E, RISE OF THE MORNINGSTAR',
        },
        backImage: {
            en: Jokers_Card3_Back_EN,
            de: Jokers_Card3_Back_DE,
            ro: Jokers_Card3_Back_RO,
            bg: Jokers_Card3_Back_BG,
            hr: Jokers_Card3_Back_HR,
            cz: Jokers_Card3_Back_CZ,
            ho: Jokers_Card3_Back_HO,
            hu: Jokers_Card3_Back_HU,
            it: Jokers_Card3_Back_IT,
            mx: Jokers_Card3_Back_MX,
            no: Jokers_Card3_Back_NO,
            pl: Jokers_Card3_Back_PL,
            si: Jokers_Card3_Back_SI,
            rs: Jokers_Card3_Back_RS,
            se: Jokers_Card3_Back_SE,
            us: Jokers_Card3_Back_US,
        }
    },

];

export default Cards;
