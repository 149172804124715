<template>
    <MobileLayout>
      <template v-slot:default>
        <div v-if="hasContentLoaded && journeyDetails" id="mobile-journey-map" v-cloak>
          <JourneyHero :isEditMode="isEditMode"
                       :isSaveDisabled="isSaveDisabled"
                       :mobile="true"
                       @toggleIsEditMode="toggleIsEditModeHandler()"
                       @save="saveClickHandler()"
                       @saveFromTemplate="saveFromTemplateClickHandler($event)"
                       @goToJourneyAction="goToJourneyActionHandler"
          />
          <div class="tabs-wrapper">
            <Tabs>
              <Tab title="Introduction">
                <Overview :isCurrentUserAuthorOfJourney="isCurrentUserAuthorOfJourney"
                          :isEditMode="isEditMode"
                />
              </Tab>
              <Tab title="Overview">
                <div id="modules">
                  <div class="module_content-wrapper">
                    <div v-for="(module, indexModule) in journeyModules"
                         :key="indexModule"
                         class="module-content">
                      <div class="circle-wrapper">
                        <div class="circle">
                          <!-- <span class="circle-element">{{ indexModule + 1 }}</span> -->
                        </div>
                      </div>
                      <ModuleDetails :module="module"/>
                    </div>
                    <div class="circle-wrapper">
                      <div class="circle">
                        <!-- <span class="circle-element">End</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab title="Participants">
                <JourneyParticipants :journeyDetails="journeyDetails"/>
              </Tab>
            </Tabs>
          </div>
          <div class="bottom_buttons-wrapper">
            <button v-if="isParticipantFirstEnterJourney()"
                    class="btn btn-primary"
                    @click="goToJourneyView()">
              <span>Start Journey</span>
            </button>
          </div>
        </div>
        <ConfirmationModal :isOpened="isUnsavedChangesModalOpened"
                           :title="titleModal"
                           :message="messageModal"
                           @confirm="confirmUnsavedChangesHandler"
                           @cancel="cancelUnsavedChangesHandler"
        />
      </template>
    </MobileLayout>
  </template>
  
  <script>
  import { ref, onMounted, computed, provide, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
  import { v4 as uuidV4 } from 'uuid';
  import MobileLayout from '@/layouts/MobileLayout.vue';
  import JourneyHero from "@/components/journey/Map/JourneyHero.vue";
  import Overview from "@/components/journey/Map/Overview.vue";
  import Tabs from "@/components/common/Tabs.vue";
  import Tab from "@/components/common/Tab.vue";
  import ModuleDetails from "@/components/journey/Map/ModuleDetails.vue";
  import JourneyParticipants from "@/components/journey/Map/JourneyParticipants.vue";
  import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
  import _ from 'lodash';
  
  export default {
    name: 'MobileJourneyMap',
    components: {
      MobileLayout,
      JourneyHero,
      Overview,
      Tabs,
      Tab,
      ModuleDetails,
      JourneyParticipants,
      ConfirmationModal
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
  
      const hasContentLoaded = ref(false);
      const journeyDetails = ref(null);
      const journeyModules = ref([]);
      const isCurrentUserAuthorOfJourney = ref(false);
      const isEditMode = ref(false);
      const isSaveDisabled = ref(true);
      const firstPageEnter = ref(true);
      const isJourneyModified = ref(false);
      const isUnsavedChangesModalOpened = ref(false);
      const confirmUnsavedChanges = ref(false);
      const titleModal = ref('Warning');
      const messageModal = ref('There are unsaved changes. Are you sure you want to leave this page?');
      const actionToTriggerAfterUnsavedChanges = ref('');
      const S3BucketFolderName = ref('journey');
      const isReusableTemplatesModalDisplayed = ref(false);
      const isJourneyReusableTemplatePage = ref(false);
  
      const userJourneys = computed(() => store.getters["journey/getUserJourneys"]);
      const journeyReusableTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyReusableTemplates"]);
      const journeyTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyTemplates"]);
  
      const isCreateNew = () => {
        return route.params.id === '' || route.params.id === null || route.params.id === undefined;
      };
  
      const initJourneyDetails = async () => {
        if (isCreateNew()) {
          journeyDetails.value = {
            ID: null,
            Name: '',
            Description: '',
            CategoryID: null,
            Picture: null,
            Overview: {
              Time: null,
              Elements: [],
              Version: null,
            },
            Structure: [
              {
                Type: 'Event',
                ID: uuidV4(),
                Name: 'Module 1',
                LinkedAssets: [],
                Options: {
                  IsModule: true,
                  IsMeeting: false,
                  IsUnlock: false,
                },
                Periods: {
                  From: null,
                  To: null,
                  Unlock: null,
                },
                Details: {
                  Time: null,
                  Elements: [],
                  Version: null,
                }
              },
              {
                Type: 'Chapter',
                ID: uuidV4(),
                Name: 'Chapter 1',
                IsLocked: false,
                IsHidden: false,
                Units: [{
                  Name: 'Unit 1',
                  LinkedAssets: [],
                  Blocks: [{
                    Time: null,
                    Elements: [],
                    Version: null,
                  }]
                }]
              },
            ]
          }
        } else {
          let journeyID = route.params.id;
          let selectedJourneyWithRef = {};
  
          if (!isJourneyReusableTemplatePage.value) {
            selectedJourneyWithRef = userJourneys.value.find((journey) => journey.ID === journeyID);
          } else {
            selectedJourneyWithRef = journeyTemplates.value.find((journeyTemplate) => journeyTemplate.ID === journeyID);
          }
  
          if (selectedJourneyWithRef !== undefined) {
            await store.dispatch('userPreferences/updateLastJourneyID', {
              LastJourneyID: journeyID,
            });
  
            let selectedJourney = JSON.parse(JSON.stringify(selectedJourneyWithRef));
  
            if (selectedJourney.hasOwnProperty('Picture')
                && selectedJourney.Picture !== null
                && selectedJourney.Picture !== undefined
                && selectedJourney.Picture !== '') {
              selectedJourney.PictureURL = await store.dispatch('file/getFile', {
                file_name: selectedJourney.Picture,
                folder_name: `${S3BucketFolderName.value}/${selectedJourney.ID}`
              })
            }
  
            if (selectedJourney.hasOwnProperty('Overview') && selectedJourney.Overview.hasOwnProperty('Elements')) {
              let elements = selectedJourney.Overview.Elements;
              for (const element of elements) {
                if (element.data.text && element.data.text.indexOf('href') !== -1){
                  const regex = /href="(.*?)"/g;
                  element.data.text = element.data.text.replace(regex, (match, p1) => {
                    return p1.indexOf(location.host) === -1
                        ? `target="_blank" ${match}`
                        : match;
                  });
                }
  
                if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
                  element.data.file.url = await store.dispatch('file/getFile', {
                    file_name: element.data.file.name,
                    folder_name: `${S3BucketFolderName.value}/${selectedJourney.ID}/assets`
                  });
                }
                if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
                  element.data.file.url = await store.dispatch('file/getFile', {
                    file_name: element.data.file.name,
                    folder_name: `${S3BucketFolderName.value}/${selectedJourney.ID}/assets`
                  });
                }
              }
            }
  
            let activeModuleToAddChaptersIndex = null;
            selectedJourney.Structure.forEach((structureItem) => {
              if(structureItem.Type === 'Event' && structureItem.Options.IsModule) {
                let eventItem = JSON.parse(JSON.stringify(structureItem));
                eventItem.Chapters = [];
                journeyModules.value.push(eventItem);
                activeModuleToAddChaptersIndex = journeyModules.value.length - 1;
              }
  
              if (structureItem.Type === 'Chapter' && activeModuleToAddChaptersIndex !== null) {
                journeyModules.value[activeModuleToAddChaptersIndex].Chapters.push(structureItem);
              }
            });
  
            journeyDetails.value = selectedJourney;
          } else {
            await router.push({
              name: '404'
            })
          }
        }
      };
  
      const isParticipantFirstEnterJourney = () => {
        return (journeyDetails.value?.hasOwnProperty('Consent') && journeyDetails.value.IsCurrentUserParticipant && !journeyDetails.value.Consent);
      };
  
      const toggleIsEditModeHandler = () => {
        isEditMode.value = !isEditMode.value;
      }
  
      const goToJourneyView = async() => {
        const payload = {
          JourneyID: journeyDetails.value.ID,
          JourneyParticipantLinkID: journeyDetails.value.JourneyParticipantLinkID,
        }
        await store.dispatch('journey/updateJourneyUserConsent', payload);
  
        await router.push({
          name: 'JourneyView',
          params: {
            id: journeyDetails.value.ID
          }
        });
      };
  
      const saveClickHandler = async () => {
        await store.dispatch('loading/setLoading', true);
  
        if (isCreateNew()) {
          journeyDetails.value = await store.dispatch('journey/create', journeyDetails.value);
  
          if (journeyDetails.value.hasOwnProperty('Overview') && journeyDetails.value.Overview.hasOwnProperty('Elements')) {
            let elements = journeyDetails.value.Overview.Elements;
            for (const element of elements) {
              if (element.data.text && element.data.text.indexOf('href') !== -1){
                const regex = /href="(.*?)"/g;
                element.data.text = element.data.text.replace(regex, (match, p1) => {
                  return p1.indexOf(location.host) === -1
                      ? `target="_blank" ${match}`
                      : match;
                });
              }
  
              if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
                });
              }
              if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
                });
              }
            }
          }
  
          await router.push({
            name: 'JourneyMap',
            params: {
              id: journeyDetails.value.ID
            }
          });
        }
        else {
          await store.dispatch('journey/update', journeyDetails.value);
          if (journeyDetails.value.hasOwnProperty('Overview') && journeyDetails.value.Overview.hasOwnProperty('Elements')) {
            let elements = journeyDetails.value.Overview.Elements;
            for (const element of elements) {
              if (element.data.text && element.data.text.indexOf('href') !== -1){
                const regex = /href="(.*?)"/g;
                element.data.text = element.data.text.replace(regex, (match, p1) => {
                  return p1.indexOf(location.host) === -1
                      ? `target="_blank" ${match}`
                      : match;
                });
              }
  
              if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
                });
              }
              if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
                });
              }
            }
          }
        }
  
        await store.dispatch('loading/setLoading', false);
        isJourneyModified.value = false;
        isSaveDisabled.value = true;
      };
  
      const saveFromTemplateClickHandler = async (templateID) => {
        await store.dispatch('loading/setLoading', true);
  
        let journeyTemplate = journeyReusableTemplates.value.find((journeyReusableTemplate) => journeyReusableTemplate.ID === templateID);
  
        if (journeyTemplate !== undefined) {
          let payload = {
            "Name": journeyTemplate.Name,
            "Description": journeyTemplate.Description,
            "CategoryID": journeyTemplate.CategoryID,
            "Picture": journeyTemplate.Picture,
            "Overview": journeyTemplate.Overview,
            "Structure": journeyTemplate.Structure,
            "TemplateID": journeyTemplate.ID,
          };
  
          journeyDetails.value = await store.dispatch('journey/copyJourney', payload);
  
          if (journeyDetails.value.hasOwnProperty('Overview') && journeyDetails.value.Overview.hasOwnProperty('Elements')) {
            let elements = journeyDetails.value.Overview.Elements;
            for (const element of elements) {
              if (element.data.text && element.data.text.indexOf('href') !== -1){
                const regex = /href="(.*?)"/g;
                element.data.text = element.data.text.replace(regex, (match, p1) => {
                  return p1.indexOf(location.host) === -1
                      ? `target="_blank" ${match}`
                      : match;
                });
              }
  
              if (element.type === 'image' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
                });
              }
              if (element.type === 'reusableImage' && !element.data.file.hasOwnProperty('url')) {
                element.data.file.url = await store.dispatch('file/getFile', {
                  file_name: element.data.file.name,
                  folder_name: `${S3BucketFolderName.value}/${journeyDetails.value.ID}/assets`
                });
              }
            }
          }
  
          isReusableTemplatesModalDisplayed.value = false;
  
          await router.push({
            name: 'JourneyMap',
            params: {
              id: journeyDetails.value.ID
            }
          });
  
          location.reload();
        }
  
        await store.dispatch('loading/setLoading', false);
      };
  
      provide('journeyDetails', journeyDetails);
      provide('isReusableTemplatesModalDisplayed', isReusableTemplatesModalDisplayed);
  
      const goToJourneyActionHandler = (journeyID, journeyAction) => {
        alert('journeyID', journeyID);
        console.log('journeyID', journeyID);
        console.log('journeyAction', journeyAction);
        if (isJourneyModified.value) {
          actionToTriggerAfterUnsavedChanges.value = journeyAction;
          toggleUnsavedChangesModal();
        }
        else {
          router.push({
            name: journeyAction,
            params: {
              id: journeyID
            }
          });
        }
      };


  
      const toggleUnsavedChangesModal = () => {
        isUnsavedChangesModalOpened.value = !isUnsavedChangesModalOpened.value;
      };
  
      const confirmUnsavedChangesHandler = () => {
        confirmUnsavedChanges.value = true;
        goToJourneyAction();
        toggleUnsavedChangesModal();
      };
  
      const goToJourneyAction = () => {
        router.push({
          name: actionToTriggerAfterUnsavedChanges.value,
          params: {
            id: route.params.id
          }
        });
      };
  
      const cancelUnsavedChangesHandler = () => {
        toggleUnsavedChangesModal();
      };
  
      watch(() => _.cloneDeep(journeyDetails.value), (currentValue, oldValue) => {
        if (!firstPageEnter.value) {
        isSaveDisabled.value = false;
        isJourneyModified.value = true;
      }
    }, { deep: true });

    onBeforeRouteLeave((to, from, next) => {
      if (isJourneyModified.value && confirmUnsavedChanges.value) {
        confirmUnsavedChanges.value = false;
        next();
      } else {
        next();
      }
    });

    onMounted(async () => {
 
      if (router.currentRoute._value.name === 'JourneyReusableTemplateMap') {
        isJourneyReusableTemplatePage.value = true;
        S3BucketFolderName.value = 'journey_reusable_templates';
      }

      await store.dispatch('journeyReusableTemplates/list');
      await initJourneyDetails();

      if (!isJourneyReusableTemplatePage.value) {
        isCurrentUserAuthorOfJourney.value = (journeyDetails.value?.ID === null || journeyDetails.value?.IsCurrentUserAuthor || journeyDetails.value?.IsCurrentUserCoAuthor);
      } else {
        isCurrentUserAuthorOfJourney.value = false;
        await store.dispatch('journeyReusableTemplates/list');
      }

      if (isCurrentUserAuthorOfJourney.value) {
        await store.dispatch('journeyCategory/list');
      }

      await store.dispatch('loading/setLoading', false);

      if (isCreateNew()) {
        isEditMode.value = true;
        await store.dispatch('journeyReusableTemplates/listReusableTemplates');
      }

      firstPageEnter.value = false;
      hasContentLoaded.value = true;
    });

    return {
      hasContentLoaded,
      journeyDetails,
      isCurrentUserAuthorOfJourney,
      isEditMode,
      isSaveDisabled,
      isUnsavedChangesModalOpened,
      titleModal,
      messageModal,
      journeyModules,
      S3BucketFolderName,
      isCreateNew,
      goToJourneyView,
      isParticipantFirstEnterJourney,
      toggleIsEditModeHandler,
      saveClickHandler,
      saveFromTemplateClickHandler,
      goToJourneyActionHandler,
      confirmUnsavedChangesHandler,
      cancelUnsavedChangesHandler,
    };
  }
};
</script>

<style lang="scss" scoped>
#mobile-journey-map {
  padding: 20px;
  padding-bottom: 60px;
}
.title {
  font-size: 30px;
  text-align: left;
  margin-bottom: 10px;
}
.tabs-wrapper {
  padding: 10px;
}
#modules {
  .module_content-wrapper {
    .module-content {
      justify-content: center;
      gap: 15px;
    }
    .circle-wrapper {
      margin: 0;
    }
  }
}
</style>
        