<template>

  <div class="upload">

    <div v-if="type === 'onImage'" class="preview-wrapper">

      <div class="upload__wrapper">

        <div v-if="hasPreview"
             class="image__wrapper"
             @click="$refs.file.click()"
        >

          <img v-if="image.src !== null"
               :src="image.src"
               alt="preview image"
               :class="{ 'img-thumbnail': true, 'rounded-circle': isRoundedPreview, 'square': !isRoundedPreview }"
          >

          <div v-if="image.src === null"
               :class="{ 'img-thumbnail': true, 'rounded-circle': isRoundedPreview, 'square': !isRoundedPreview }"
          ></div>

          <div class="icon add">
            <font-awesome-icon icon="plus"/>
          </div>

        </div>

        <input
            v-show="false"
            ref="file"
            type="file"
            accept="image/*"
            @change="loadImage($event)"
        />

        <div>
          <!-- Heading -->
          <h4>
            {{ title }}
          </h4>

          <!-- Text -->
          <small class="text-muted">
            {{ description }}
          </small>
        </div>

      </div>

    </div>

    <div v-if="type === 'icon'" class="icon upload__image"
         @click="$refs.file.click()">
      <font-awesome-icon icon="image" title="Upload Image"/>
      <input
          ref="file"
          type="file"
          accept="image/*"
          @change="loadImage($event)"
      />
    </div>

    <div v-show="isCropperVisible" class="image-edit">
      <div class="image-edit__content">
        <div v-if="image.blob" class="cropper-wrapper">
          <Cropper
              v-if="cropperType === 'Circle'"
              style="max-height: 300px;"
              ref="cropper"
              check-orientation
              :src="image.blob"
              :stencil-component= "$options.components.CircleStencil"
          />
          <Cropper
              v-else
              ref="cropper"
              style="max-width: 500px; max-height: 300px;"
              check-orientation
              :src="image.blob"
              :canvas="{
		      maxHeight: 256,
		      maxWidth: 256
	      }"
              :stencil-props="{
          aspectRatio: cropperAspectRatio
        }"
          />
          <div
              class="btn btn-sm btn-primary reset-button"
              title="Reset Image"
              @click="reset()"
          >
            <img :src="require('@/assets/icons/reset.svg')" />
          </div>
          <div class="upload__file-type" v-if="image.type">
            {{ image.type }}
          </div>
        </div>
        <div class="buttons-wrapper">
          <button
              v-if="image.blob"
              class="btn btn-sm btn-primary"
              @click="$refs.file.click()"
          >
            Change
          </button>
          <button
              v-if="image.blob"
              class="btn btn-sm btn-primary"
              @click="uploadPicture()"
          >
            Save and Close
          </button>
        </div>
        <div v-if="filesizeerror" class="invalid-feedback">
          PNG or JPG no bigger than 9MB.
        </div>
      </div>
      <div class="backdrop"></div>
    </div>

  </div>

</template>

<script>
import { onUnmounted, reactive, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
// import Compressor from 'compressorjs';

import 'vue-advanced-cropper/dist/style.css'
import {useStore} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
//import 'vue-advanced-cropper/dist/theme.bubble.css'

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])
  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47':
      return 'image/png'
    // case '47494638':
    //   return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

function formatBytes(a, b = 2) {
  if (0 === a) return '0 Bytes'
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024))
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  )
}

export default {

  components: {
    FontAwesomeIcon,
    Cropper,
    CircleStencil
  },

  emits: ['pictureReady'],

  props: {
    folderName: {
      type: String,
      required: true,
    },
    cropperType: {
      type: String,
      default: 'Rectangle'
    },
    src: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['icon', 'onImage'].includes(value),
    },
    hasPreview: {
      type: Boolean,
      required: true,
    },
    isRoundedPreview: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    cropperAspectRatio: {
      default: 4/3,
    }
  },

  setup(props, {emit}) {

    const store = useStore();

    const isCropperVisible = ref(false);

    const image = ref({
      blob: null,
      type: null,
      src: props.src,
    });

    const reset = () => {
      image.value.blob = null;
      image.value.type = null;
      isCropperVisible.value = false;
    }
    const cropper = ref(null);

    const filesizeerror = ref(false);

    const loadImage = event => {
      const { files } = event.target;

      if (files && files[0] && files[0].size <= '9500000') {

        filesizeerror.value = false;

        let file = files[0];

        if (image.value.blob) {
          URL.revokeObjectURL(image.value.blob)
        }

        // new Compressor(file, {
        //   quality: 0.2,
        //   success(result) {
        //     const reader = new FileReader();
        //     // const myBlob = result;
        //     const blob = URL.createObjectURL(result)
        //     console.log('result: ', blob);
        //     reader.onload = e => {
        //       image.value.src = blob
        //       image.value.type = getMimeType(e.target.result, file.type)
        //     }
        //     reader.readAsArrayBuffer(file)
        //   }
        // })

        const blob = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.onload = e => {
          image.value.blob = blob
          image.value.type = getMimeType(e.target.result, file.type)
        }
        reader.readAsArrayBuffer(file);
        isCropperVisible.value = true;
      } else {
        filesizeerror.value = true
      }
    };

    const uploadPicture = () => {
      store.dispatch('loading/setLoading', true)
      const { canvas } = cropper.value.getResult();
      if (canvas) {

        let ext = null
        const filename = uuidv4()
        switch (image.value.type) {
          case 'image/png':
            ext = 'png'
            break

          case 'image/jpeg':
            ext = 'jpg'
            break

          case 'image/jpg':
            ext = 'jpg'
            break

          default:
            // Anweisungen werden ausgeführt,
            // falls keine der case-Klauseln mit expression übereinstimmt
            break
        }

        // Convert canvas image to Base64
        var img = canvas.toDataURL();
        // Convert Base64 image to binary
        var crop_img = dataURItoBlob(img);

        image.value.src = img;

        const payload = {
          name: filename,
          ext: ext,
          type: image.value.type,
          file: crop_img,
          folder_name: props.folderName,
          url: img
        }
        // this.$store.dispatch('file/uploadFile', payload);
        //here need to emit an event to parent that image was selected, with payload as param and then the parent will decide when to store it to database
        emit('pictureReady', payload);

        reset();
        isCropperVisible.value = false;

      }
    }

    onUnmounted(() => {
      if (image.value.blob) {
        URL.revokeObjectURL(image.value.blob)
      }
    })

    return {
      cropper,
      isCropperVisible,
      image,
      reset,
      loadImage,
      uploadPicture,

      //uploadPicture,
      filesizeerror
    }
  },

}

</script>

<style lang="scss" scoped>
.upload {

  .preview-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;

    .img-thumbnail.rounded-circle {
      width: 100px;
      height: 100px;
    }

    div.img-thumbnail.square {
      width: 100px;
      min-height: 100px;
    }

  }

  .upload__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .image__wrapper {
      position: relative;
      border-radius: 50%;
      cursor: pointer;

      div.img-thumbnail {
        background-color: #c1c1c1;
      }

      .icon.add {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20px;
      }

      &:hover {
        opacity: 0.5;

        .icon.add {
          display: block;
        }
      }

    }
  }

  &__button {
    input {
      display: none;
    }
  }

  &__reset-button {
    position: absolute;
    right: -20px;
    bottom: -20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 42px;
  }

  &__image {
    font-size: 25px;

    input {
      display: none;
    }
  }
  &__file-type {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #0d0d0d;
    border-radius: 5px;
    padding: 0px 10px;
    padding-bottom: 2px;
    font-size: 12px;
    color: white;
  }

  .cropper-wrapper {
    position: relative;
    //border: 1px solid black;
    //padding: 5px;
  }

  .reset-button {
    position: absolute;
    right: -20px;
    bottom: -20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 42px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 17px;
  }

  .image-edit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      z-index: 6;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .backdrop {
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .upload {
    .icon {
      &.upload__image {
        font-size: 22px;
      }
    }

    .image-edit {
      .image-edit__content {
        .cropper-wrapper {
          max-width: 90%;
        }
      }
    }
  }
}

</style>

<style lang="scss">
.upload {
  .vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
    background: transparent;
  }
}
</style>
