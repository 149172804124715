<template>
  <Tabs>
    <Tab title="HB Memo Cards">
      <memory-cards :displayAllMemoryCards="true" :isCheckmarkDisplayed="false"></memory-cards>
    </Tab>
    <Tab title="Reusable Images">
      <div v-if="hasContentLoaded" class="reusable-assets_tab">

        <div class="title">
          <h1>Reusable images</h1>
        </div>

        <div class="reusable-images_wrapper">

          <div class="add-image_wrapper"
               title="Add image"
               @click="openModal()"
          >

            <div class="icon plus">
              <font-awesome-icon icon="plus"/>
            </div>

          </div>

          <div v-for="(reusableImage, indexReusableImage) in reusableImages"
               :key="indexReusableImage"
               class="image_wrapper"
               v-if="isDataReady">

            <div class="overlay" @click="removeImageFromReusable(reusableImage)">
              <div class="icon trash" title="Remove">
                <font-awesome-icon icon="trash"/>
              </div>
            </div>

            <img :src="reusableImage.url"
                 alt="reusable image">

          </div>

        </div>

        <transition name="fade">

          <div class="custom_modal journey-images_modal" v-show="isModalDisplayed">

            <div class="custom_modal__content">

              <div class="journeys-images_wrapper">

                <div class="add-image_wrapper"
                     title="Upload image"
                     @click="$refs.uploadImage.click()"
                >

                  <div class="icon plus">
                    <font-awesome-icon icon="image"/>
                  </div>

                  <input
                      ref="uploadImage"
                      type="file"
                      accept="image/*"
                      multiple
                      @change="loadImages($event)"
                  />

                </div>

                <div v-for="(journeyImage, indexJourneyImage) in journeysImages"
                     :key="indexJourneyImage"
                     class="image_wrapper"
                     @click="addExistingImageToReusableJourneysImages(journeyImage)"
                     v-if="isDataReady">

                  <div class="overlay-modal">
                    <div class="icon plus" title="Plus">
                      <font-awesome-icon icon="plus"/>
                    </div>
                  </div>
                  <img :src="journeyImage.url"
                       alt="journey image">

                </div>

              </div>

            </div>

            <div class="backdrop" @click="closeModal()"></div>

          </div>

        </transition>

      </div>
    </Tab>
  </Tabs>
</template>

<script>

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import draggable from "vuedraggable";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import createFileObject from "@/helpers/createFileObject.js";
import MemoryCards from "@/components/common/MemoryCards";
import Tabs from "@/components/common/Tabs";
import Tab from "@/components/common/Tab";

export default {

  name: 'ReusableAssets',

  components: {
    FontAwesomeIcon,
    draggable,
    MemoryCards,
    Tabs,
    Tab
  },

  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isModalDisplayed = ref(false);

    const journeysImages = ref([]);

    const reusableImages = ref([]);

    const uploadedImages = ref(null);

    const isDataReady = ref(false);

    const getJourneysImages = async() => {
      await store.dispatch('loading/setLoading', true);
      isDataReady.value = false;
      const uniqueKeys = new Set();
      const uniqueObjects = [];

      journeysImages.value = await store.dispatch('journey/getJourneysImages');

      for (const image of reusableImages.value) {

        let reusableImageName = getImageNameFromKey(image.Key);

        journeysImages.value = journeysImages.value.filter((image) => !image.Key.includes(reusableImageName));

      }

      for ( const img of journeysImages.value){
        let imgName = getImageNameFromKey(img.Key);
        if (!uniqueKeys.has(imgName)) {
          uniqueKeys.add(imgName);
          uniqueObjects.push(img);
        }
      }
      journeysImages.value = uniqueObjects
      await getJourneysImagesURLs();
      isDataReady.value = true;
      await store.dispatch('loading/setLoading', false);
    };

    const getReusableImagesURLs = async() => {

      for (const image of reusableImages.value) {
        image.url = await store.dispatch('file/getFile', {
          full_key: image.Key,
        });
      }

    };

    const getJourneysImagesURLs = async() => {

      for (const image of journeysImages.value) {
        image.url = await store.dispatch('file/getFile', {
          full_key: image.Key,
        });
      }
    };

    const getImageNameFromKey = (key) => {
      const parts = key.split('/');

      // Get the last part of the path, which is the filename
      const filename = parts[parts.length - 1];

      return filename;
    };

    const removeImageFromReusable = async (removedImage) => {

      await store.dispatch('loading/setLoading', true);
      isDataReady.value = false;

      // remove reusable image that was fetched on page load
      if (removedImage.hasOwnProperty('Key')) {
        const delete_payload = {
          folder_name: 'reusable_assets/journeys_images',
          file_name: getImageNameFromKey(removedImage.Key)
        }

        await store.dispatch('file/deleteFile', delete_payload);

        reusableImages.value = reusableImages.value.filter((image) => image.Key !== removedImage.Key);

        await store.dispatch('notifications/addNotification', {
          type: 'success',
          message: 'Image is no longer reusable'
        });

      }

      // remove image that was added to reusable after page load
      if (removedImage.hasOwnProperty('name')) {
        const delete_payload = {
          folder_name: 'reusable_assets/journeys_images',
          file_name: removedImage.name
        }

        await store.dispatch('file/deleteFile', delete_payload);

        reusableImages.value = reusableImages.value.filter((image) => image.name !== removedImage.name);

        await store.dispatch('notifications/addNotification', {
          type: 'success',
          message: 'Image is no longer reusable'
        });

      }

      journeysImages.value = [];
      await getJourneysImages();
      isDataReady.value = true;
      await store.dispatch('loading/setLoading', false);

    };

    // modal
    const openModal = () => {
      isModalDisplayed.value = true;
    };

    const closeModal = () => {
      isModalDisplayed.value = false;
    }

    const loadImages = async (ev) => {

      await store.dispatch('loading/setLoading', true);

      let files = ev.target.files;

      for (let i = 0; i < files.length; i++) {

        const file = files[i];

        let file_object = await createFileObject(file);

        let newImage = {
          name: file_object.file.name,
          ext: file_object.file.ext,
          type: file_object.file.type,
          file: file_object.file.file,
          folder_name: 'reusable_assets/journeys_images',
        }

        await store.dispatch('file/uploadFile', newImage, {root: true});

        let uploadedImage = {
          name: newImage.name + '.' + newImage.ext
        };

        uploadedImage.url = await store.dispatch('file/getFile', {
          file_name: uploadedImage.name,
          folder_name: newImage.folder_name
        }, {root: true});

        reusableImages.value.push(uploadedImage);

      }

      closeModal();

      await store.dispatch('loading/setLoading', false);

    };

    const addExistingImageToReusableJourneysImages = async (existingImage) => {

      await store.dispatch('loading/setLoading', true);
      isDataReady.value = false;


      const payload = {
        source_key: existingImage.Key,
        file_name: getImageNameFromKey(existingImage.Key)
      };

      await store.dispatch('reusableAssets/addExistingImageToReusableJourneysImages', payload);

      reusableImages.value.push(existingImage);

      journeysImages.value = journeysImages.value.filter((image) => image.Key !== existingImage.Key);
      isDataReady.value = true;
      await store.dispatch('loading/setLoading', false);

    };

    // end modal

    onMounted(async() => {

      await store.dispatch('loading/setLoading', true);

      reusableImages.value = await store.dispatch('reusableAssets/listReusableJourneysImages');

      await getJourneysImages();

      await getReusableImagesURLs();

      hasContentLoaded.value = true;

      await store.dispatch('loading/setLoading', false);

    });

    return {
      hasContentLoaded,
      isModalDisplayed,
      reusableImages,
      journeysImages,
      uploadedImages,
      isDataReady,
      openModal,
      closeModal,
      loadImages,
      removeImageFromReusable,
      addExistingImageToReusableJourneysImages,
    }
  },

}

</script>

<style lang="scss" scoped>

.reusable-assets_tab {
  padding: 20px;

  h1 {
    font-size: 30px;
    text-align: left;
    margin-bottom: 10px;
    color: var(--primary-color);
    font-weight: normal;
  }

  .add-image_wrapper {
    width: 200px;
    align-self: stretch;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .icon:hover {
    color: var(--primary-color);
  }
  .image_wrapper {
    position: relative;
    max-width: 200px;

    img {
      max-width: 200px;
    }

    .overlay, .overlay-modal {
      height: 100%;
      width: 100%;
      background-color: transparentize(white, 0.2);
      position: absolute;
      opacity: 0;
      -webkit-transition: opacity 0.5s ease-in-out;
      -moz-transition: opacity 0.5s ease-in-out;
      -ms-transition: opacity 0.5s ease-in-out;
      -o-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out;

      .icon {
        position: absolute;
        top: 45%;
        left: 45%;
        &:hover {
          color: black
        }
      }
    }

    .overlay-modal {
      background-color: transparentize(#e8e7e7, 0.2);
      .icon {
        font-size: 25px;
      }
    }
    &:hover {
      .overlay, .overlay-modal{
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .reusable-images_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .journeys-images_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .journey-images_modal {

    .custom_modal__content {
      display: block;
      width: 90%;
      height: 80%;
      max-height: 80%;
      border: none;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

      .add-image_wrapper {
        font-size: 30px;
      }

      .image_wrapper {
        cursor: pointer;
      }

      input[type=file] {
        display: none;
      }

    }

  }

}

</style>
