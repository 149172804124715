import { createRouter, createWebHistory } from 'vue-router'

import { Auth } from 'aws-amplify'
//import { configAuth } from '@/services/auth'
import { useDevice } from '@/composables/useDevice';

import Start from '@/views/Start';
import Dashboard from '@/views/Dashboard';
import MobileDashboard from '@/views/MobileDashboard';
import MobileTreasureChest from '@/views/MobileTreasureChest';
import TreasureChest from "@/views/TreasureChest.vue";
import Admin from '@/views/Admin.vue';
import PaymentSuccess from '@/views/PaymentSuccess.vue';
import PaymentCanceled from '@/views/PaymentCanceled.vue';
import Account from '@/views/Account';
import Settings from "@/views/Settings.vue";
import JourneyCreation from "@/views/JourneyCreation.vue";
import JourneyMap from "@/views/JourneyMap.vue";
import JourneyView from "@/views/JourneyView.vue";
import Logon from "@/components/auth/Logon.vue";
import MobileJourneyMap from "@/views/MobileJourneyMap.vue";
import MobileJourneyView from "@/views/MobileJourneyView.vue";
import MobileAccount from "@/views/MobileAccount.vue";
import MobileSettings from "@/views/MobileSettings.vue";
import PageNotFound from '@/views/PageNotFound';

import store from '@/store';

const roleNames = {
  TenantAdmin: 'TenantAdmin',
  Facilitator: 'Facilitator',
  Participant: 'Participant',
}

// load the auth services and write to store
//configAuth()
const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start,
    meta: { title: 'Start', requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { title: 'Dashboard', requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const { isMobile } = useDevice();
      if (isMobile.value) {
        next({ name: 'MobileDashboard' });
      } else {
        next();
      }
    }
  },
  {
    path: '/m-dashboard',
    name: 'MobileDashboard',
    component: MobileDashboard,
    meta: { title: 'Mobile Dashboard', requiresAuth: true }
  },
  {
    path: '/treasure-chest',
    name: 'TreasureChest',
    component: TreasureChest,
    meta: { title: 'TreasureChest', requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const { isMobile } = useDevice();
      if (isMobile.value) {
        next({ name: 'MobileDashboard' });
      } else {
        next();
      }
    }
  },
  {
    path: '/m-treasure-chest',
    name: 'MobileTreasureChest',
    component: MobileTreasureChest,
    meta: { title: 'Mobile TreasureChest', requiresAuth: true }
  },

  {
    path: '/journey-creation/:id?',
    name: 'JourneyCreation',
    component: JourneyCreation,
    meta: { title: 'JourneyCreation', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] }
  },
  {
    path: '/journey/map/:id?',
    name: 'JourneyMap',
    component: JourneyMap,
    meta: { title: 'JourneyMap', requiresAuth: true }
    
  },
  {
    path: '/journey/view/:id',
    name: 'JourneyView',
    component: JourneyView,
    meta: { title: 'JourneyView', requiresAuth: true }
  },
 
  {
    path: '/m-journey/view/:id?',
    name: 'MobileJourneyView',
    component: MobileJourneyView,
    meta: { title: 'Mobile Journey View', requiresAuth: true }
  },
  {
    path: '/m-journey/map/:id?',
    name: 'MobileJourneyMap',
    component: MobileJourneyMap,
    meta: { title: 'Mobile Journey Map', requiresAuth: true }
  },
  {
    path: '/journey-template/map/:id',
    name: 'JourneyReusableTemplateMap',
    component: JourneyMap,
    meta: { title: 'JourneyReusableTemplateMap', requiresAuth: true,  allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] }
  },
  {
    path: '/journey-template/view/:id',
    name: 'JourneyReusableTemplateView',
    component: JourneyView,
    meta: { title: 'JourneyReusableTemplateView', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { title: 'Admin', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin] }
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
    meta: { title: 'PaymentSuccess', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] }
  },
  {
    path: '/payment-canceled',
    name: 'PaymentCanceled',
    component: PaymentCanceled,
    meta: { title: 'PaymentCanceled', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { title: 'Account', requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const { isMobile } = useDevice();
      if (isMobile.value) {
        next({ name: 'MobileAccount' });
      } else {
        next();
      }
    }
  },
  {
    path: '/m-account',
    name: 'MobileAccount',
    component: MobileAccount,
    meta: { title: 'Mobile Account', requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { title: 'Settings', requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const { isMobile } = useDevice();
      if (isMobile.value) {
        next({ name: 'MobileSettings' });
      } else {
        next();
      }
    }
  },
  {
    path: '/m-settings',
    name: 'MobileSettings',
    component: MobileSettings,
    meta: { title: 'Settings', requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
    meta: { title: 'PageNotFound', requiresAuth: false }
  },
  {
    path: '/logon',
    name: 'Logon',
    component: Logon,
    meta: { title: 'Logon', requiresAuth: false }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const isUserAllowedBasedOnRole = (allowedRoles) => {

  for (const [key, value] of Object.entries(roleNames)) {
    if (allowedRoles.includes(value)) {
      if (store.state.auth.roles[key]) {
        return store.state.auth.roles[key];
      }
    }
  }

  return false;

};

router.beforeResolve((to, from, next) => {
  //check based on logged in
  if (to.meta.requiresAuth) {
   // console.log('This route is protected');
    Auth.currentAuthenticatedUser().then(() => {
      //check based on current user role
      if (to.meta.hasOwnProperty('allowedRoles')) {
        if (isUserAllowedBasedOnRole(to.meta.allowedRoles)) {
          next();
        }
        else {
          next({
            path: '/'
          })
        }
      }
      else {
        next();
      }
    }).catch(() => {
      console.log('User not authenticated');
      next({
        path: '/logon'
      })
      // next();//use the above to secure routes
    })
  }
  else {
    next();
  }
});

router.beforeEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  store.dispatch('loading/setLoading', true)
})

/*
router.beforeResolve((to, from, next) => {
  //console.log('Router')
  //console.log(Auth)
  //console.log(Auth.currentAuthenticatedUser)
  //console.log(store.state.auth)
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
    //console.log('Title')
    //console.log(to.meta.title)
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        //console.log('isch AUTH')
        store.commit('auth/loggedIn', true)
        next()
        return
      })
      .catch(() => {
        //console.log('isch not')
        store.commit('auth/loggedIn', false)
        router.push({ name: 'Start', path: '/' })
        return
      })
  } else {
    //console.log('Ohne auth next')
    next()
  }
})

*/

export default router;
