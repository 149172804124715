function calculateDailyPrice(monthlyPrice) {
    const daysInYear = 365;
    const totalAnnualPrice = monthlyPrice * 12;
    const dailyPrice = totalAnnualPrice / daysInYear;
    return dailyPrice;
}

function calculateDaysBetween(date1, date2) {
    // Normalize the dates to ignore the time part
    const start = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const end = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    // Calculate the difference in time
    const differenceInTime = end - start; // difference in milliseconds

    // Convert the difference from milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    return Math.round(differenceInDays);
}

export {calculateDailyPrice, calculateDaysBetween};