<template>

  <div id="journey-management">

    <table v-if="allJourneys.length > 0" id="existing-journeys">
      <thead>
        <tr>
          <th>Name</th>
          <th>Author</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(journey, indexAllJourneys) in allJourneys"
            :key="indexAllJourneys"
        >
          <td>
            <span>{{ journey.Name }}</span>
          </td>

          <td>
            <span>{{ getFacilitatorName(journey.AuthorID) }}</span>
          </td>

          <td style="display: flex; justify-content: center;">
            <div class="edit-icon" @click="displayModal(journey.ID, journey.AuthorID)">
              <font-awesome-icon
                  class="icon"
                  icon="edit"
              />
            </div>
            <div class="edit-icon" @click="deleteJourney(journey.ID, journey.Name)">
              <font-awesome-icon icon="trash"/>
            </div>

          </td>

        </tr>
      </tbody>

    </table>
    <transition name="fade">
      <div class="custom_modal" v-if="isDeleteModalDisplayed">
        <div class="custom_modal__content">
          <p>You are about to delete journey {{journeyNameToBeDeleted}}</p>
          <label>Type in the word 'DELETE' for confirmation</label>
          <input v-model="deleteConfirmationWord">
          <div class="buttons-wrapper">
            <button @click="confirmDeleteJourney()" class="btn btn-primary" style="font-weight: bold">Delete</button>
            <button @click="closeDeleteModal()" class="btn btn-primary">Cancel</button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">

      <div class="custom_modal" v-if="isModalDisplayed">

        <div class="custom_modal__content">
          <h3>
            <span>Edit Author</span>
          </h3>
          <form @submit.prevent="saveModalHandler">

            <div class="input-wrapper">
              <label for="updatedAuthor">Author:</label>
              <select name="updatedAuthor" id="updatedAuthor" v-model="modalData.AuthorID">
                <option v-for="(facilitator, indexFacilitator) in facilitators"
                        :key="indexFacilitator"
                        :value="facilitator.sub">
                  {{ facilitator.given_name }} {{ facilitator.family_name }}
                </option>
              </select>
            </div>

            <div class="buttons-wrapper">
              <button class="btn btn-primary" type="submit">SAVE</button>
              <button class="btn btn-primary" type="button" @click="closeModal()">CANCEL</button>
            </div>

          </form>

        </div>

        <div class="backdrop" @click="closeModal()"></div>

      </div>

    </transition>

  </div>

</template>

<script>

import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {

  name: 'JourneyManagement',

  setup() {

    const store = useStore();


    const isModalDisplayed = ref(false);

    const isDeleteModalDisplayed = ref(false);

    const journeyNameToBeDeleted = ref('');

    const journeyIdToBeDeleted = ref('');

    const deleteConfirmationWord = ref('');

    const modalData = ref({
      JourneyID: null,
      AuthorID: null,
    });

    const existingUsers = ref([]);

    const facilitators = ref([]);

    const allJourneys = ref([]);

    const getFacilitatorName = (ID) => {
      let facilitator = facilitators.value.filter((facilitator) => facilitator.sub === ID);
      if (facilitator.length > 0) {
        facilitator = facilitator[0];
        return facilitator.given_name + ' ' + facilitator.family_name;
      }
      return null;
    };

    const filterFacilitators = () => {
      facilitators.value = existingUsers.value.filter((user) => (user.Group === 'TenantAdmin') || (user.Group === 'Facilitator'));
    };

    const displayModal = (journeyID, authorID) => {
      modalData.value = {
        JourneyID: journeyID,
        AuthorID: authorID
      };
      isModalDisplayed.value = true;
    };

    const deleteJourney = (journeyID, journeyName) => {
      isDeleteModalDisplayed.value = true;
      journeyIdToBeDeleted.value = journeyID;
      journeyNameToBeDeleted.value = journeyName;
    }
    const confirmDeleteJourney = async () => {
      if(deleteConfirmationWord.value === 'DELETE'){
        await store.dispatch('journey/deleteJourney', {
          JourneyID: journeyIdToBeDeleted.value,
        });
        allJourneys.value = allJourneys.value.filter((journey) => journey.ID !== journeyIdToBeDeleted.value);
        isDeleteModalDisplayed.value = false;
      }
    }
    const closeDeleteModal = () => {
      isDeleteModalDisplayed.value = false;
      deleteConfirmationWord.value = '';
    }

    const saveModalHandler = async () => {
      let updatedJourney = await store.dispatch('journey/updateAuthor', modalData.value);
      const indexUserJourneys = allJourneys.value.findIndex(journey => journey.ID === updatedJourney.ID);

      if (indexUserJourneys !== -1) {
        allJourneys.value[indexUserJourneys].AuthorID = updatedJourney.AuthorID;
      }

      await store.dispatch('journey/listUserJourneys');

      closeModal();

    };

    const closeModal = () => {
      modalData.value = {
        JourneyID: null,
        AuthorID: null,
      };
      isModalDisplayed.value = false;
    };

    onMounted(async() => {
      await store.dispatch('loading/setLoading', true);
      allJourneys.value = await store.dispatch('journey/list');
      existingUsers.value = await store.dispatch('users/getExistingUsers');
      filterFacilitators();
      await store.dispatch('loading/setLoading', false);
    })

    return {
      allJourneys,
      facilitators,
      isModalDisplayed,
      isDeleteModalDisplayed,
      modalData,
      journeyNameToBeDeleted,
      deleteConfirmationWord,
      journeyIdToBeDeleted,
      getFacilitatorName,
      displayModal,
      saveModalHandler,
      closeModal,
      deleteJourney,
      confirmDeleteJourney,
      closeDeleteModal,
    }
  }

}

</script>

<style lang="scss" scoped>

#journey-management {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;

  .custom_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;

    &__content {
      background-color: rgba(232, 231, 231);
      width: 590px;
      max-height: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      z-index: 6;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 30px;
      border: 1px solid black;
      font-size: 18px;
      text-align: center;

      h3 {
        font-weight: bold;
        text-transform: uppercase;
      }

      form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        label {
          margin-right: 10px;
        }

        .buttons-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }

      }

    }

    .backdrop {
      background-color: rgb(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

  }

  .edit-icon {
    cursor: pointer;
    margin: 0 2px
  }

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
