<template>

  <CentralLayout>

    <template v-slot:content>

      <div v-if="hasContentLoaded" id="journey-view">

        <transition name="slide">

          <div :style="{'max-height': maxHeight + 'px', 'min-height': maxHeight + 'px' }"
               class="progress-sidebar-wrapper"
               v-if="isSidebarDisplayed"
          >
            <ProgressSidebar :isCreation="false"
                             :journeyModules="journeyModules"
            />
          </div>

        </transition>

        <div class="journey-content-wrapper">

          <JourneyContent :isSidebarDisplayed="isSidebarDisplayed"
                          :journeyModules="journeyModules"/>

        </div>

      </div>

    </template>

  </CentralLayout>

</template>

<script>

import JourneyContent from "@/components/journey/ParticipantView/JourneyContent.vue";
import ProgressSidebar from "@/components/journey/ProgressSidebar.vue";
import {useStore} from "vuex";
import {computed, inject, onMounted, provide, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import Notification from "@/components/settings/Notification.vue";
import MainNav from "@/components/MainNav.vue";
import CentralLayout from "@/layouts/CentralLayout.vue";
import $ from 'jquery';

export default {

  name: 'JourneyView',

  components: {
    CentralLayout,
    MainNav,
    JourneyContent,
    ProgressSidebar,
    Notification,
  },

  setup() {

    const hasContentLoaded = ref(false);

    const store = useStore();

    const router = useRouter();

    const route = useRoute();

    const journeyID = ref();

    const journey = ref(null);

    const journeyModules = ref([]);

    const isSidebarDisplayed = ref(true);

    const activeStructureItem = ref({
      structureItemID: null,
      indexUnit: null
    });

    const isCurrentUserAuthorOfJourney = ref(false);

    const userJourneys = computed(() => store.getters["journey/getUserJourneys"]);

    const maxHeight = computed(() => store.getters["layout/getContainerMaxHeight"]);

    const journeyReusableTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyReusableTemplates"]);

    const journeyTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyTemplates"]);

    const currentUserRoles = computed(() => store.getters["auth/getCurrentUserRoles"]);

    const isJourneyReusableTemplatePage = ref(false);

    const S3BucketFolderName = ref('journey');

    const convertIsoStringToLocalDateTime = (dateTime) => {
      if (dateTime !== undefined && dateTime !== null && dateTime !== '') {
        const date = new Date(dateTime);
        // Get the date and time components
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Format the datetime-local string
        const datetimeLocalString = `${year}-${month}-${day}T${hours}:${minutes}`;

        return datetimeLocalString;
      }
      return null;
    };

    const isChapterCompleted = (chapterID) => {
      if (journey.value.hasOwnProperty('ParticipantProgress')) {
        let chapterProgress = journey.value.ParticipantProgress.find((chapter) => chapter.ChapterID === chapterID);
        return chapterProgress !== undefined ? chapterProgress.Completed : false;
      }
      else {
        return false;
      }

    };

    const isChapterLocked = (chapterID) => {
      let selectedChapter = journey.value.Structure.find((structureItem) => structureItem.ID === chapterID);

      return selectedChapter !== undefined ? selectedChapter.IsLocked : true;
    };

    const getJourney = async() => {

      journeyID.value = route.params.id;

      let selectedJourneyWithRef = {};

      if (!isJourneyReusableTemplatePage.value) {
        selectedJourneyWithRef = userJourneys.value.find((myJourney) => myJourney.ID === journeyID.value);
      }
      else {
        if (currentUserRoles.value.TenantAdmin) {
          selectedJourneyWithRef = journeyTemplates.value.find((journeyTemplate) => journeyTemplate.ID === journeyID.value);
        }
        if (!currentUserRoles.value.TenantAdmin && currentUserRoles.value.Facilitator) {
          selectedJourneyWithRef = journeyReusableTemplates.value.find((journeyReusableTemplate) => journeyReusableTemplate.ID === journeyID.value);
        }
      }

      if (selectedJourneyWithRef !== undefined) {
        journey.value = JSON.parse(JSON.stringify(selectedJourneyWithRef));

        if (journey.value.hasOwnProperty('Picture')
            && journey.value.Picture !== null
            && journey.value.Picture !== undefined
            && journey.value.Picture !== '') {
          journey.value.PictureURL = await store.dispatch('file/getFile', {
            file_name: journey.value.Picture,
            folder_name: `${S3BucketFolderName.value}/${journey.value.ID}`
          })
        }

        let activeModuleToAddChaptersIndex = null;

        for (const structureItem of journey.value.Structure) {

          if (structureItem.Type === 'Chapter') {

            // set modules
            if (activeModuleToAddChaptersIndex !== null) {
              journeyModules.value[activeModuleToAddChaptersIndex].Chapters.push(structureItem);
            }

            // get images signed url
            if (structureItem.hasOwnProperty('Units')){
              for (const unit of structureItem.Units) {
                unit.Completed = isChapterCompleted(structureItem.ID);

                if (unit.hasOwnProperty('Blocks')) {
                  for (const block of unit.Blocks) {

                    if (block.hasOwnProperty('Elements')) {
                      for (const element of block.Elements) {
                        if (element.data.text && element.data.text.indexOf('href') !== -1){
                          const regex = /href="(.*?)"/g;
                          element.data.text = element.data.text.replace(regex, (match, p1) => {
                            return p1.indexOf(location.host) === -1
                                ? `target="_blank" ${match}`
                                : match;
                          });
                        }
                        if (element.type === 'image') {
                          element.data.file.url = await store.dispatch('file/getFile', {
                            file_name: element.data.file.name,
                            folder_name: `${S3BucketFolderName.value}/${journey.value.ID}/assets`
                          });
                        }
                        if (element.type === 'reusableImage') {
                          element.data.file.url = await store.dispatch('file/getFile', {
                            file_name: element.data.file.name,
                            folder_name: `${S3BucketFolderName.value}/${journey.value.ID}/assets`
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          if (structureItem.Type === 'Assignment') {

            // get images signed url
            if (structureItem.hasOwnProperty('Tasks') && structureItem.Tasks.length > 0){
              for (const task of structureItem.Tasks) {

                if (task.hasOwnProperty('Content') && task.Content.hasOwnProperty('Elements')) {
                  for (const element of task.Content.Elements) {
                    if (element.data.text && element.data.text.indexOf('href') !== -1){
                      const regex = /href="(.*?)"/g;
                      element.data.text = element.data.text.replace(regex, (match, p1) => {
                        return p1.indexOf(location.host) === -1
                            ? `target="_blank" ${match}`
                            : match;
                      });
                    }
                    if (element.type === 'image') {
                      element.data.file.url = await store.dispatch('file/getFile', {
                        file_name: element.data.file.name,
                        folder_name: `${S3BucketFolderName.value}/${journey.value.ID}/assets`
                      });
                    }
                    if (element.type === 'reusableImage') {
                      element.data.file.url = await store.dispatch('file/getFile', {
                        file_name: element.data.file.name,
                        folder_name: `${S3BucketFolderName.value}/${journey.value.ID}/assets`
                      });
                    }
                  }
                }
              }
            }

          }

          if (structureItem.Type === 'Event') {

            // unlock chapters if time passed the event unlock time
            if (structureItem.Options.IsUnlock && structureItem.Periods.Unlock !== null) {

              structureItem.Periods.Unlock = convertIsoStringToLocalDateTime(structureItem.Periods.Unlock);

            }

            if (structureItem.Options.IsMeeting && structureItem.Periods.From !== null) {
              structureItem.Periods.From = convertIsoStringToLocalDateTime(structureItem.Periods.From);
            }

            if (structureItem.Options.IsMeeting && structureItem.Periods.To !== null) {
              structureItem.Periods.To = convertIsoStringToLocalDateTime(structureItem.Periods.To);
            }

            // set modules
            if(structureItem.Options.IsModule) {
              let eventItem = JSON.parse(JSON.stringify(structureItem));
              eventItem.Chapters = [];
              journeyModules.value.push(eventItem);
              activeModuleToAddChaptersIndex = journeyModules.value.length - 1;
            }

            // get images signed url
            if (structureItem.hasOwnProperty('Details') && structureItem.Details.hasOwnProperty('Elements') && structureItem.Details.Elements.length > 0) {
              for (const element of structureItem.Details.Elements) {

                if (element.data.text && element.data.text.indexOf('href') !== -1){
                  const regex = /href="(.*?)"/g;
                  element.data.text = element.data.text.replace(regex, (match, p1) => {
                    return p1.indexOf(location.host) === -1
                        ? `target="_blank" ${match}`
                        : match;
                  });
                }

                if (element.type === 'image') {
                  element.data.file.url = await store.dispatch('file/getFile', {
                    file_name: element.data.file.name,
                    folder_name: `${S3BucketFolderName.value}/${journey.value.ID}/assets`
                  });
                }
                if (element.type === 'reusableImage') {
                  element.data.file.url = await store.dispatch('file/getFile', {
                    file_name: element.data.file.name,
                    folder_name: `${S3BucketFolderName.value}/${journey.value.ID}/assets`
                  });
                }
              }
            }

          }

        }

      }
    };

    const setActiveChapterUnit = () => {

      // if user has progress, try putting him on next available chapter
      if (journey.value.hasOwnProperty('ParticipantProgress')) {

        let completedChapter = journey.value.ParticipantProgress.find((chapter) => chapter.Completed === true);

        // user did not manage to get any progress
        if (completedChapter === undefined) {

          let firstStructureItem = journey.value.Structure[0];

          if (firstStructureItem.Type === 'Chapter') {
            activeStructureItem.value = {
              structureItemID: firstStructureItem.ID,
              indexUnit: 0
            };
          }

          else {
            activeStructureItem.value = {
              structureItemID: firstStructureItem.ID,
              indexUnit: null
            };
          }
        }
        else {
          let nextChapterInProgress = journey.value.ParticipantProgress.find((chapter) => chapter.Completed === false);

          // if next chapter exists
          if (nextChapterInProgress !== undefined) {

            // if next chapter is not locked, make it active
            if (!isChapterLocked(nextChapterInProgress.ChapterID)) {

              let chapters = [];

              chapters = journey.value.Structure.filter((structureItem) => structureItem.Type === 'Chapter');

              const nextActiveChapter = chapters.find((chapter) => chapter.ID === nextChapterInProgress.ChapterID);

              activeStructureItem.value = {
                structureItemID: nextActiveChapter.ID,
                indexUnit: 0
              };

            }
            else {

              let lastCompletedChapter = null;

              let chapters = [];

              chapters = journey.value.Structure.filter((structureItem) => structureItem.Type === 'Chapter');

              for (let i = chapters.length - 1; i >= 0; i--) {
                if (!chapters[i].IsLocked) {
                  lastCompletedChapter = chapters[i];
                  activeStructureItem.value = {
                    structureItemID: lastCompletedChapter.ID,
                    indexUnit: 0
                  };
                }
              }
            }

          }
          else {

            let chapter = journey.value.Structure.find((structureItem) => structureItem.Type === 'Chapter'
                && !structureItem.IsLocked
                && structureItem.hasOwnProperty('Units')
                && structureItem.Units.length > 0);

            if (chapter !== undefined) {
              activeStructureItem.value = {
                structureItemID: chapter.ID,
                indexUnit: 0
              };
            }

          }
        }

      }

      // if not, put him on first structure item
      else {

        let firstStructureItem = journey.value.Structure[0];

        if (firstStructureItem.Type === 'Chapter') {
          activeStructureItem.value = {
            structureItemID: firstStructureItem.ID,
            indexUnit: 0
          };
        }

        else {
          activeStructureItem.value = {
            structureItemID: firstStructureItem.ID,
            indexUnit: null
          };
        }


      }
    };

    // watch(isSidebarDisplayed, (newValue, oldValue) => {
    //   $(".progress-sidebar-wrapper").toggle("slide");
    // })

    provide('journeyDetails', journey);
    provide('isSidebarDisplayed', isSidebarDisplayed);
    provide('activeStructureItem', activeStructureItem);
    provide('isCurrentUserAuthorOfJourney', isCurrentUserAuthorOfJourney);


    onMounted(async () => {

      if(router.currentRoute._value.name === 'JourneyReusableTemplateView') {
        isJourneyReusableTemplatePage.value = true;
        S3BucketFolderName.value = 'journey_reusable_templates';
      }

      if (isJourneyReusableTemplatePage.value) {
        await store.dispatch('journeyReusableTemplates/listReusableTemplates');
        if (currentUserRoles.value.TenantAdmin) {
          await store.dispatch('journeyReusableTemplates/list');
        }
      }

      await getJourney();

      if (!isJourneyReusableTemplatePage.value) {
        isCurrentUserAuthorOfJourney.value = (journey.value.ID === null || journey.value.IsCurrentUserAuthor || journey.value.IsCurrentUserCoAuthor);
      }
      else {
        isCurrentUserAuthorOfJourney.value = false;
      }

      setActiveChapterUnit();

      if (!isJourneyReusableTemplatePage.value) {
        await store.dispatch('userPreferences/updateLastJourneyID', {
          LastJourneyID: journey.value.ID,
        });
      }

      await store.dispatch('loading/setLoading', false);

      hasContentLoaded.value = true;

    });

    return {
      hasContentLoaded,
      isSidebarDisplayed,
      journeyModules,
      maxHeight,
    }
  }

}

</script>

<style lang="scss" scoped>

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#journey-view {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  .progress-sidebar-wrapper {
    width: 25%;
    z-index: 1;
  }

  .journey-content-wrapper {
    width: 75%;
    flex: 1 1 auto;
  }
}

@media only screen and (max-width: 768px) {
  #journey-view {

    .progress-sidebar-wrapper {
      width: 60%;
    }

    .journey-content-wrapper {
      width: 40%;
    }
  }
}

</style>

<style lang="scss">
#app .navbar {
  margin-bottom: 0 !important;
}
#app .container {
  margin: 0;
  width: 100%;
  max-width: unset;
}
</style>
