<template>
      <nav class="bottom-nav">
        <router-link to="/m-dashboard" class="nav-item" :class="{ active: isActive('/m-dashboard') }" exact>
          <font-awesome-icon icon="home" />
        </router-link>

        <router-link to="/m-treasure-chest" class="nav-item" :class="{ active: isActive('/m-treasure-chest') }" exact>
          <!-- <font-awesome-icon icon="briefcase" /> --> 
          <div class="png-container">
         <img src="../../assets/icons/chest.png" :class="['icon-image', { active: isActive('/m-treasure-chest') }]">
                </div>
        </router-link>
        <div class="nav-item" :class="[
          { 'disabled-button': (lastJourneyID === '' || lastJourneyID === null || lastJourneyID === undefined) },
          { active: isActive('/m-journey') }
        ]" @click="goToLastJourney" >
            <font-awesome-icon icon="route" />
        </div>

  
     
        <router-link to="/m-settings" class="nav-item" :class="{ active: isActive('/m-settings') }" exact>
          <font-awesome-icon icon="user" />
        </router-link>
      </nav>

  </template>
  
  <script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  //import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { faHome, faUsers, faRoute } from '@fortawesome/free-solid-svg-icons';
  import { computed } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from 'vue-router';
  
  library.add(faHome, faUsers, faRoute);
  
  export default {
    name: 'FooterMobile',
    components: {
    //  FontAwesomeIcon
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const tenantColor = computed(() => {
        return {
          '--primary-color': store.state.tenant.tenantinfo.primarycolor
        }
      });
  
      const isActive = (route) => {
        return window.location.pathname.startsWith(route);
      };
      const lastJourneyID = computed(() => store.getters["userPreferences/getLastJourneyID"]);
      const goToLastJourney = () => {
      router.push('/m-journey/view/' + lastJourneyID.value).then(() => {
       // checkActiveRoute(window.location.href);
      });
    };
  
      return {
        tenantColor,
        isActive,
        lastJourneyID,
        goToLastJourney,
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .bottom-nav {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 2vh 0; /* Use vh units to adjust height */
    
    /* Add padding to account for the iOS safe area */
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
  }
  
  .nav-item {
    flex: 1;
    text-align: center;
    padding: 2vh 0; /* Adjust vertical padding to move icons higher */
    color: #000;
    text-decoration: none;
    position: relative;
  
    .fa-icon,
    .icon-image {
      font-size: 24px;
      margin-bottom: 2vh; /* Add margin to push the icon higher */
    }
  
    .icon-image {
      width: 18px;
      height: 18px;
    }
  
    .png-container {
      padding-top: 5px;
      overflow: hidden;
    }
  
    .png-container img.active {  
      filter: drop-shadow(0px 1000px 0 var(--primary-color));
      transform: translateY(-1000px);
    }
  
    &.active {
      color: var(--primary-color);
  
      &::after {
        content: '';
        display: block;
        width: 50%;
        height: 2px;
        background-color: var(--primary-color);
        position: absolute;
        bottom: 2vh; /* Adjust bottom position using vh */
        left: 50%;
        transform: translateX(-50%);
      }
    }
  
    &.disabled-button {
      color: gray;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  </style>
  