<template>
  <div id="import-users">

    <h2>Upload {{ userType }}</h2>

    <vue-csv-import v-if="fromWhere === 'JourneyCard'"
                    v-model="importedUsersCsv[userTypeKey]"
                    :fields="{
                      'given_name': { required: true, label: 'given_name' },
                      'family_name': { required: true, label: 'family_name' },
                      'email': { required: true, label: 'email' },
                      'country': { required: true, label: 'country' },
                      'status': { required: true, label: 'status' },
                    }"
    >
<!--       {{ file }}-->
      <vue-csv-errors></vue-csv-errors>
      <vue-csv-toggle-headers></vue-csv-toggle-headers>
      <vue-csv-input @change="uploadAndRemoveUsers" :id="`vue-import-csv-button-${userTypeKey}`" class="import-csv-button"></vue-csv-input>
      <vue-csv-table-map :auto-match="true"></vue-csv-table-map>
      <!-- :table-attributes="{ id: 'csv-table' }" -->
    </vue-csv-import>

    <vue-csv-import v-else
                    v-model="importedUsersCsv[userTypeKey]"
                    :fields="{
                      'given_name': { required: true, label: 'given_name' },
                      'family_name': { required: true, label: 'family_name' },
                      'email': { required: true, label: 'email' },
                      'country': { required: true, label: 'country' },
                      'status': { required: true, label: 'status' },
                    }"
    >
      <!--       {{ file }}-->
      <vue-csv-errors></vue-csv-errors>
      <vue-csv-toggle-headers></vue-csv-toggle-headers>
      <vue-csv-input @change="uploadAndRemoveUsers" :id="`vue-import-csv-button-${userTypeKey}`" class="import-csv-button"></vue-csv-input>
      <vue-csv-table-map :auto-match="true"></vue-csv-table-map>
      <!-- :table-attributes="{ id: 'csv-table' }" -->
    </vue-csv-import>

    <div class="import-buttons">

<!--      <a :href="fileUrl" download="import_users_template.csv">-->
      <button class="btn btn-primary"
              @click="downloadUsersTemplate()"
      >Download CSV Template</button>
<!--      </a>-->

      <button class="btn btn-primary" @click="redirectClickToVueCsvImport(userTypeKey)">Upload</button>

    </div>


<!--    <table v-show="importedUsersCsv.length > 0" id="imported-users-table">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th>First Name</th>-->
<!--        <th>Surname</th>-->
<!--        <th>Email</th>-->
<!--        <th>Company</th>-->
<!--        <th>Job Title</th>-->
<!--        <th>Phone</th>-->
<!--        <th>Reports To</th>-->
<!--        <th>Role</th>-->
<!--        <th>Actions</th>-->
<!--      </tr>-->
<!--      </thead>-->

<!--      <tbody>-->
<!--&lt;!&ndash;      <tr v-for="(row, index) in importedUsersCsv"&ndash;&gt;-->
<!--&lt;!&ndash;          :key="index"&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.firstname }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.surname }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.email }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.company }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.jobtitle }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.phone }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.reportsto }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ row.role }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="delete-icon" @click="removeImportedUser(index)">&ndash;&gt;-->
<!--&lt;!&ndash;            <font-awesome-icon&ndash;&gt;-->
<!--&lt;!&ndash;                class="icon"&ndash;&gt;-->
<!--&lt;!&ndash;                icon="trash"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </td>&ndash;&gt;-->
<!--&lt;!&ndash;      </tr>&ndash;&gt;-->
<!--      </tbody>-->

<!--    </table>-->

<!--    <button v-if="importedUsersCsv.length > 0"-->
<!--            class="btn btn-primary"-->
<!--            @click="registerParticipantsHandler()"-->
<!--    >-->
<!--      Register Users-->
<!--    </button>-->

  </div>
</template>

<script>

import {useStore} from "vuex";
import {onMounted, ref, watch} from "vue";
import $ from "jquery";

export default {

  name: 'ImportUsers',

  emits: ['csvImportComplete'],

  props: {
    userType: {
      type: String,
    },
    fromWhere: {
      type: String,
    },
    userTypeKey: {
      type: String,
      required: true
    },
  },

  setup(props, {emit}) {

    const store = useStore();

    const importedUsersCsv = ref({});
    const importedFileName = ref([]);

    // const fileUrl = ref('/assets/import_users_template.csv');

    function redirectClickToVueCsvImport(userTypeKey) {
      const file = document.querySelector(`#vue-import-csv-button-${userTypeKey}`);
      file.value = '';
      document.getElementById(`vue-import-csv-button-${userTypeKey}`).click();
    }

    function uploadAndRemoveUsers() {
      emit('csvImportComplete', importedUsersCsv.value[props.userTypeKey] || [], props.userTypeKey);

      store.dispatch('notifications/addNotification', {
        type: 'success',
        message: 'File successfully uploaded'
      }, {root: true});
    }

    // const downloadCSV = function (data) {
    //
    //   const blob = new Blob([data], { type: 'text/csv' });
    //
    //   const link = document.createElement('a');
    //
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = 'import_template.csv';
    //   link.click();
    //
    // }

    // const downloadTemplate = async function () {
    //
    //   const headers = props.fromWhere === 'JourneyCard' ? ['firstname', 'lastname', 'email'] : ['given_name', 'family_name', 'email', 'company', 'position', 'phone_number'];
    //
    //   const templateHeaders = [headers].map(row => row.join(',')).join('\n');
    //
    //   downloadCSV(templateHeaders);
    //
    // }

    const downloadUsersTemplate = async () => {

      const fileName = 'import_users_template.csv';

      // Create an anchor element
      const a = document.createElement('a');

      // Set the href attribute to the file URL
      a.href = await store.dispatch('file/getFile', {
        file_name: fileName,
        folder_name: 'tenant_assets'
      });

      // Set the download attribute to specify the file name
      a.download = fileName;

      // Append the anchor to the body (not necessary to actually show it)
      document.body.appendChild(a);

      // Trigger a click on the anchor element
      a.click();

      // Remove the anchor element from the document
      document.body.removeChild(a);

    };

    watch(() => importedUsersCsv.value[props.userTypeKey], (newValue, oldValue) => {
      emit('csvImportComplete', newValue || [], props.userTypeKey);
    });

    onMounted(() => {
      $('#import-users label input').click();
      $('#import-users label').hide();
    });

    return {
      importedUsersCsv,
      importedFileName,
      // fileUrl,
      uploadAndRemoveUsers,
      redirectClickToVueCsvImport,
      downloadUsersTemplate,
    }
  }

}

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
#import-users {
  margin: 30px auto 10px auto;
  text-align: center;

  h2 {
    color: var(--primary-color)
  }

  .import-csv-button {
    display: none;
  }

  input[type="file"] {
    background-color: pink;
  }
  table:not(#imported-users-table) {
    display: none;
  }

  #imported-users-table {
    max-width: 90%;
    margin: 0 auto;
  }

  .import-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

}
</style>
