<template>

  <div class="assignment">


    <div class="assignment_name">

      <h1 v-if="!isEdit.assignmentName && (journeyDetails.Structure[indexStructureItem].Name !== null && journeyDetails.Structure[indexStructureItem].Name !== '')"
          @click="startEditing('AssignmentName')">{{ journeyDetails.Structure[indexStructureItem].Name }}</h1>
      <input
          v-if="isEdit.assignmentName || (journeyDetails.Structure[indexStructureItem].Name === null || journeyDetails.Structure[indexStructureItem].Name === '')"
          type="text"
          placeholder="Assignment name"
          :value="journeyDetails.Structure[indexStructureItem].Name"
          @input="updateValue($event, 'AssignmentName')"
          @blur="stopEditing('AssignmentName')"
          ref="assignmentNameElement"
          class="editable-input"
      />

    </div>


    <div class="tasks_wrapper">

      <draggable v-model="journeyDetails.Structure[indexStructureItem].Tasks"
                 class="tasks"
                 item-key="ID"
                 handle=".task-handle"
                 :group="{ name: 'tasks' }"
                 :move="checkMove"
                 @end="isStartDrag = false"
      >

        <template #item="{ element: task, index: indexTask }">

          <div class="task">

            <div class="task_header">

              <div class="left">

                <div class="icon bars-staggered task-handle"
                     @mousedown="isStartDrag = true"
                     @mouseup="isStartDrag = false"
                >
                  <font-awesome-icon
                      icon="bars-staggered"
                  />
                </div>

                <span class="task-title">Task {{ indexTask + 1 }}</span>
              </div>

              <div class="right">

                <div v-if="journeyDetails.Structure[indexStructureItem].Tasks.length > 1"
                     class="icon delete"
                     title="Delete task"
                     @click="deleteTask(indexStructureItem, indexTask)"
                >
                  <font-awesome-icon icon="trash"/>
                </div>

              </div>

            </div>

            <AssignmentTask :structureItemID="journeyDetails.Structure[indexStructureItem].ID"
                            :indexStructureItem="indexStructureItem"
                            :indexTask="indexTask"
                            :reusableImages="reusableImages"
                            :isStartDrag="isStartDrag"
                            @editorChange="$emit('editorChange')"
            />

          </div>

        </template>
      </draggable>

      <div class="icon add"
           title="Add task"
           @click="addTask(indexStructureItem)"
      >
        <font-awesome-icon icon="plus"/>
      </div>

    </div>

  </div>

</template>

<script>

import {useStore} from "vuex";
import {inject, nextTick, onMounted, ref} from "vue";
import draggable from "vuedraggable";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AssignmentTask from "@/components/journey/Creation/AssignmentTask.vue";
import {v4 as uuidV4} from "uuid";
import BlockEditor from "@/components/journey/Creation/BlockEditor.vue";

export default {

  name: 'JourneyAssignment',

  components: {
    BlockEditor,
    draggable,
    FontAwesomeIcon,
    AssignmentTask,
  },

  emits: ['editorChange'],

  props: {
    structureItemID: {
      required: true,
    },
    indexStructureItem: {
      required: true,
    },
    reusableImages: {
      type: Array,
      default: [],
    },
  },

  setup(props) {

    const store = useStore();

    const journeyDetails = inject('journeyDetails');

    // chapter name edit

    const isEdit = ref({
      assignmentName: false,
    });

    const assignmentNameElement = ref(null);

    const startEditing = (element) => {

      if (element === 'AssignmentName') {
        isEdit.value.assignmentName = true;
        nextTick(() => {
          assignmentNameElement.value.focus();
        });
      }

    };

    const stopEditing = (element) => {

      if (element === 'AssignmentName' && journeyDetails.value.Structure[props.indexStructureItem].Name !== null && journeyDetails.value.Structure[props.indexStructureItem].Name !== '') {
        isEdit.value.assignmentName = false;
      }

    };

    const updateValue = (event, element) => {

      if (element === 'AssignmentName') {
        journeyDetails.value.Structure[props.indexStructureItem].Name = event.target.value;
      }

    };

    //end of chapter name edit

    const isStartDrag = ref(false);

    const checkMove = (ev) => {

      // don't allow task to be moved if is last task of assignment
      let parentListRemainingNumberOfTasks = ev.from.__draggable_component__.modelValue.length - 1;

      return parentListRemainingNumberOfTasks > 0;
    };

    const addTask = (indexStructureItem) => {

      let taskObject = {
        ID: uuidV4(),
        IsDone: false,
        Content: {
          Time: null,
          Elements: [],
          Version: null,
        }
      };

      journeyDetails.value.Structure[indexStructureItem].Tasks.push(taskObject);

    };

    const deleteTask = (indexStructureItem, indexTask) => {

      journeyDetails.value.Structure[indexStructureItem].Tasks.splice(indexTask, 1);

    };

    onMounted(async() => {

      if (journeyDetails.value.Structure[props.indexStructureItem].Name === null || journeyDetails.value.Structure[props.indexStructureItem].Name === '') {
        isEdit.value.assignmentName = true;
      }

    });

    return {
      journeyDetails,
      addTask,
      deleteTask,
      checkMove,

      isEdit,
      assignmentNameElement,
      isStartDrag,
      startEditing,
      stopEditing,
      updateValue,
    }
  }

}

</script>

<style lang="scss" scoped>

.assignment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  .assignment_name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: bold;

    h1 {
      font-size: 40px;
      margin-bottom: 0;
    }

    input {
      font-size: 40px;
    }

    .editable-input {
      outline: none;
      cursor: text;
      width: 100%;
      color: white;
      text-align: center;
      border-radius: 10px;
      background-color: #00000075;
      border: 1px solid #fff;
    }

  }

  .tasks_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .tasks {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .task {
        width: 100%;

        .task_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0;
          font-size: 20px;

          .left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            .task-title {
              font-size: 20px;
            }
          }
        }

      }

    }

    .icon.add {
      align-self: flex-start;
      font-size: 28px;
    }

  }
}

</style>