<template>
  <div v-if="hasContentLoaded" class="journey-reusable-templates-management">

    <table v-if="journeyTemplates.length > 0" id="journey-reusable-templates">
      <thead>
      <tr>
        <th>Name</th>
        <th>Reusable</th>
<!--        <th>Author</th>-->
<!--        <th>Actions</th>-->
      </tr>
      </thead>

      <tbody>
      <tr v-for="(journeyTemplate, indexJourneyTemplate) in journeyTemplates"
          :key="indexJourneyTemplate"
      >
        <td>
          <router-link :to="{ name: 'JourneyReusableTemplateMap', params: { id: journeyTemplate.ID } }"> {{ journeyTemplate.Name }} </router-link>
        </td>

        <td>
          <button class="btn btn-primary" @click="updateJourneyTemplateStatus(journeyTemplate.ID, journeyTemplate.Reusable)">
            <span v-if="!journeyTemplate.Reusable">Accept</span>
            <span v-else>Decline</span>
          </button>
        </td>

<!--        <td>-->
<!--          <span>{{ getFacilitatorName(journey.AuthorID) }}</span>-->
<!--        </td>-->

<!--        <td>-->
<!--          <div class="edit-icon" @click="displayModal(journey.ID, journey.AuthorID)">-->
<!--            <font-awesome-icon-->
<!--                class="icon"-->
<!--                icon="edit"-->
<!--            />-->
<!--          </div>-->

<!--        </td>-->

      </tr>
      </tbody>

    </table>

  </div>
</template>

<script>

import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {

  name: 'JourneyReusableTemplatesManagement',

  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const journeyTemplates = computed(() => store.getters["journeyReusableTemplates/getJourneyTemplates"]);

    const updateJourneyTemplateStatus = async (journeyTemplateID, isReusable)=> {
      let payload = {
        ID: journeyTemplateID,
        Reusable: !isReusable
      }
      await store.dispatch('journeyReusableTemplates/updateJourneyTemplateStatus', payload);
    };

    onMounted(async () => {
      await store.dispatch('journeyReusableTemplates/list');
      hasContentLoaded.value = true;
    });

    return {
      hasContentLoaded,
      journeyTemplates,
      updateJourneyTemplateStatus,
    }
  }

}

</script>

<style lang="scss" scoped>

.journey-reusable-templates-management {
  #journey-reusable-templates {
    margin: 0 auto;
  }
}

</style>