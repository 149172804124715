<template>
  <MobileLayout>
    <template v-slot:default>
      <div v-if="hasContentLoaded" id="account-page">
        <small class="text-capitalize">Overview</small>
        <h2>My Account</h2>
        <Tabs :preselectedTab="preselectedTab">
          <Tab title="User Management">
            <UserManagement/>
          </Tab>
          
        </Tabs>
      </div>
    </template>
  </MobileLayout>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import MobileLayout from '@/layouts/MobileLayout';
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import UserManagement from "@/components/account/UserManagement.vue";

export default {
  name: 'MobileAccount',
  components: {
    MobileLayout,
    Tabs,
    Tab,
    UserManagement,
  },
  setup() {
    const store = useStore();
    const hasContentLoaded = ref(false);
    const isBillingActive = computed(() => store.getters["internalCurrency/getIsReturnToBilling"]);
    const preselectedTab = ref('');

    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);
      if (isBillingActive.value) {
        preselectedTab.value = 'Billing';
      }
      hasContentLoaded.value = true;
      await store.dispatch('internalCurrency/setIsReturnToBilling', false);
      await store.dispatch('loading/setLoading', false);
    });

    return {
      hasContentLoaded,
      preselectedTab,
    }
  }
}
</script>

<style lang="scss" scoped>
#account-page {
  padding: 20px;
  padding-bottom: 60px;
}

  
</style>