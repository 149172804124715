<template>

  <div v-if="hasContentLoaded" id="module-details">

    <span class="module-name">{{ module.Name }}</span>

    <div class="module-details-content">

    <div class="module-view">
      <div v-for="(element, indexElement) in module.Details.Elements"
           :key="indexElement"
           class="element"
           v-html="convertToHTML(element)"
      >
      </div>

      </div>

      <!--    <div v-if="isCurrentUserAuthorOfJourney" v-show="isEdit" class="editorx_body">-->

      <!--      <div :id="'codex-editor-module-' + indexModule" />-->

      <!--    </div>-->

      <div class="chapters-section">

        <!--      <div v-if="isCurrentUserAuthorOfJourney" class="chapters-all">-->

        <!--        <div class="title">Remaining Chapters</div>-->

        <!--        <draggable :list="remainingChapters" tag="ul" group="chapters" itemKey="Name" :sort="false" class="chapters-wrapper">-->
        <!--          <template #item="{ element }">-->
        <!--            <li class="chapter">{{ element.Name }}</li>-->
        <!--          </template>-->
        <!--        </draggable>-->

        <!--      </div>-->

        <!--      <div class="icon move">-->
        <!--        <font-awesome-icon :icon="['fas', 'greater-than']" />-->
        <!--      </div>-->

<!--        <div class="title">Included Chapters</div>-->

        <ul class="chapters-wrapper">
          <li v-for="(chapter, indexChapter) in module.Chapters"
              :key="indexChapter"
              class="chapter">{{ chapter.Name }}</li>
        </ul>

        <!--        <draggable :list="includedChapters" tag="ul" group="chapters" itemKey="Name" class="chapters-wrapper" :sort="false" @change="draggableChangeEventHandler">-->
        <!--          <template #item="{ element }">-->
        <!--            <li class="chapter">{{ element.Name }}</li>-->
        <!--          </template>-->
        <!--        </draggable>-->

      </div>

    </div>

  </div>

</template>

<script>

import {inject, onMounted, ref} from "vue";
import draggable from "vuedraggable";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {

  name: 'ModuleDetails',

  components: {
    draggable,
  },

  props: {

    module: {
      type: Object,
      required: true,
    },

  },

  setup(props, {emit}) {

    const store = useStore();

    const router = useRouter();

    const hasContentLoaded = ref(false);

    const journeyDetails = inject('journeyDetails');

    const isJourneyReusableTemplatePage = ref(false);

    const assetsSourceFolder = ref('journey');

    if(router.currentRoute._value.name === 'JourneyReusableTemplateMap') {
      isJourneyReusableTemplatePage.value = true;
      assetsSourceFolder.value = 'journey_reusable_templates';
    }

    const initModuleAssets = async () => {
      for(let Element of props.module.Details.Elements) {

        // aici
        if (Element.data.text && Element.data.text.indexOf('href') !== -1){
          const regex = /href="(.*?)"/g;
          Element.data.text = Element.data.text.replace(regex, (match, p1) => {
            return p1.indexOf(location.host) === -1
                ? `target="_blank" ${match}`
                : match;
          });
        }

        if (Element.type === 'image' || Element.type === 'reusableImage') {
          Element.data.file.url = await store.dispatch('file/getFile', {
            file_name: Element.data.file.name,
            folder_name: `${assetsSourceFolder.value}/${journeyDetails.value.ID}/assets`
          });
        }
      }
    }

    const processListItems = (items, listType) => {

      let convertedElement = '';

      if (listType === 'ordered') {
        convertedElement += '<ol class="${element.tunes.textVariant}">'
      }
      if (listType === 'unordered') {
        convertedElement += '<ul class="${element.tunes.textVariant}">'
      }


      items.forEach((item) => {
        convertedElement += `<li><span>${item.content}<span>`;
        if (item.items.length > 0) {
          convertedElement += processListItems(item.items, listType);
        }
        convertedElement += `</li>`
      })


      if (listType === 'ordered') {
        convertedElement += '</ol>'
      }
      if (listType === 'unordered') {
        convertedElement += '</ul>'
      }

      return convertedElement;
    };

    const convertToHTML = (element) => {

      let convertedElement = '';

      switch (element.type) {

        case 'paragraph': {

          convertedElement =
              `<p style="text-align: ${element.tunes.anyTuneName.alignment}">
                ${element.data.text}
              </p>`

          break;
        }

        case 'header': {
          convertedElement =
              `<h${element.data.level} style="text-align: ${element.tunes.anyTuneName.alignment}">
                ${element.data.text}
              </h${element.data.level}>`
          break;
        }

        case 'quote': {
          convertedElement =
              `<figure class="quote ${element.tunes.textVariant}">
                   <blockquote>
                     ${element.data.text}
                   </blockquote>
                 <figcaption>
                   &mdash; <span>${element.data.caption}</span>
                 </figcaption>
               </figure>`
          break;
        }

        case 'delimiter': {
          convertedElement =
              `<div class="delimiter ${element.tunes.textVariant}">
            </div>`
          break;
        }

        case 'list': {
          convertedElement += processListItems(element.data.items, element.data.style);
          break;
        }

        case 'table': {
          convertedElement += `<table style="margin: 0 auto" class="${element.tunes.textVariant}">`;
          if (element.data.withHeadings) {
            let rowHeader = element.data.content[0];
            convertedElement += `<thead>`;
            convertedElement += '<tr>';
            rowHeader.forEach((data) => {
              convertedElement += `<th>${data}</th>`;
            });

            convertedElement += `</tr>`;
            convertedElement += `</thead>`;

            convertedElement += `<tbody>`;
            for (let i = 1; i < element.data.content.length; i++) {
              let rowBody =  element.data.content[i];
              convertedElement += '<tr>';
              rowBody.forEach((data) => {
                convertedElement += `<td>${data}</td>`;
              });
              convertedElement += '</tr>';
            }
            convertedElement += `</tbody>`;


          }
          else {
            convertedElement += `<tbody>`;
            element.data.content.forEach((row) => {
              convertedElement += '<tr>';
              row.forEach((data) => {
                convertedElement += `<td>${data}</td>`;
              });
              convertedElement += '</tr>';
            });
            convertedElement += `</tbody>`;
          }
          break;
        }

        case 'checklist': {
          convertedElement += `<div class="checklist" class="${element.tunes.textVariant}">`;

          element.data.items.forEach((item) => {
            convertedElement += `<div class="checkbox-wrapper">`;

            convertedElement += `<input type="checkbox"`;
            if (item.checked) {
              convertedElement += `checked>`;
            }
            else {
              convertedElement += `>`;
            }
            convertedElement += `<label>${item.text}</label>`;

            convertedElement += `</div>`;

          });



          convertedElement += `</div>`;
          break;
        }

        case 'embed': {
          convertedElement =
              `<div class="iframe-wrapper">` +
              `<iframe src=${element.data.embed} height=${element.data.height} width=${element.data.width} title=${element.data.caption}></iframe>` +
              `</div>`
          break;
        }

        case 'image': {

          convertedElement =
              `<img src=${element.data.file.url}>`

          break;
        }

        case 'reusableImage': {

          convertedElement =
              `<img src=${element.data.file.url}>`

          break;
        }

        default : {
          convertedElement = '';
          break;
        }

      }

      return convertedElement;

    };

    onMounted(async () => {
      await initModuleAssets();
      hasContentLoaded.value = true;
    });

    return {
      hasContentLoaded,
      journeyDetails,
      convertToHTML,
    }
  }

}

</script>

<style lang="scss" scoped>

#module-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;
  margin: 20px 0;

  .module-name {
    color: var(--primary-color);
    font-size: 25px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .module-details-content {
    padding: 3%;
    border: 2px solid var(--primary-color);
    border-radius: 6px;

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 10px;

      .edit-module, .delete-module {
        font-size: 25px;
      }

    }

    .chapters-section {

      .title {
        font-size: 25px;
        font-weight: bold;
      }

      .chapters-wrapper {
        gap: 10px;
        width: 100%;
        min-height: 100px;

        .chapter {
          font-size: 20px;
        }

        li::marker {
          color: var(--primary-color);
        }
      }
    }
  }
}

</style>

<style lang="scss">

.ce-toolbar {
  .ce-toolbar__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ce-toolbar__actions {
      left: -50px;
      right: unset;
      .ce-toolbar__settings-btn {
        background-color: #6b6868c7;
        border-radius: 5px;
        svg {
          fill: #fff;
        }
      }
    }
  }
}

</style>
