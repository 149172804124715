<template>
  <div class="tabs">

    <nav class="nav nav-justified main-navigation mb-4">
      <a v-for="(title, index) in tabTitles"
         :key="index"
         v-show="isTabDisplayed(title)"
         :class="{ 'nav-item': true, 'nav-link': true, active: title === selectedTitle }"
         @click="selectedTitle = title"
      >
        {{ title }}
      </a>
    </nav>

    <slot/>
  </div>
</template>

<script>
import {ref, provide, onMounted} from 'vue';

export default {

  props: {
    tabsToSkip: {
      type: Array,
      default: []
    },
    preselectedTab: {
      type: String,
      default: ''
    }
  },

  setup(props, {slots}) {

    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const selectedTitle = ref(null);

    const isTabDisplayed = (title) => {
      return !props.tabsToSkip.includes(title)
    }

    provide('selectedTitle', selectedTitle);

    onMounted(() => {
      selectedTitle.value = tabTitles.value.find((tabTitle) => isTabDisplayed(tabTitle));
      if (props.preselectedTab !== '') {
        selectedTitle.value = props.preselectedTab;
      }
    });

    return {
      selectedTitle,
      tabTitles,
      isTabDisplayed,
    };
  },
};
</script>

<style scoped>
.main-navigation {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px 0 0;
  z-index: 3;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}
.nav-link {
  cursor: pointer;
}
</style>
