import axios from 'axios'

const getDefaultState = () => {
  return {
    loggedIn: false,
    authState: undefined,
    user: undefined,
    authData: undefined,
    bearer: undefined,
    isProfileLoading: true,
    isProfileLoadingdone: false,
    groups: '',
    // Facilitator: false,
    // isAdmin: false,
    roles: {
      TenantAdmin: false,
      Facilitator: false,
      Participant: false,
      Manager: false,
      Assistant: false,
    },
    userInfo: {
      given_name: null,
      family_name: null,
      phone_number: null,
      position: null,
      bio: null,
      social_linkedIn: null,
      social_xing: null,
      social_facebook: null,
      social_twitter: null,
      social_instagram: null,
      social_other: null,
      picture: null
    },
    devicelist: false,
  }
}

// initial state
const state = getDefaultState()

// const state = {
//   loggedIn: false,
//   authState: undefined,
//   user: undefined,
//   authData: undefined
// }
const getters = {
  //StateTenant: state => state.tenantinfo
  bearerGetter: state => state.bearer,
  getAuthState (state) {
    return state;
  },
  getCurrentUserRoles: state => state.roles,
  getCurrentUserID: state => {
    if (state.user !== undefined && state.user !== null) {
      return state.user.attributes.sub;
    }
    return null;
  },
}

const actions = {

  resetState({ commit }) {
    commit('resetState')
  },

  isAuthenticated({ commit }, status) {
    commit('setLoggedIn', status)
  },

  changeAuthState({ commit }, authState) {
    commit('setAuthState', authState)
  },

  getUserDetails({ commit }, user) {
    commit('setUser', user);
    if (user !== null) {
      let groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      if (typeof groups !== 'undefined') {
        commit('setGroupsAndRoles', groups);
      }
    }
  },

  async getDeviceList({ commit, state, rootState }) {
    // get cognito user-device list
    axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI
    let config = {
      headers: {
        Authorization: rootState.auth.bearer
      }
    }
    axios
      .get('v2/user/devicelist', config)
      .then(function(response) {
        let obj = {}

        let entries = Object.entries(response.data)

        for (const devicedetails of entries) {
          const idx = devicedetails[0]
          const md = new Date(devicedetails[1].devicelastmodifieddate)
          const ad = new Date(devicedetails[1].devicelastauthenticateddate)
          const cd = new Date(devicedetails[1].devicecreatedatee)
          obj[idx] = devicedetails[1]
          obj[idx].devicelastmodifieddate = md.toString()
          obj[idx].devicelastauthenticateddate = ad.toString()
          obj[idx].devicecreatedatee = cd.toString()
        }

        commit('setDeviceList', obj)
        commit('loading/setLoading', false, {
          root: true
        })
      })
      .catch(function(error) {
        // handle error
        commit('loading/setLoading', false, { root: true })
      })

    // try {
    //   let response = await axios.get('user/devicelist', config)
    //   //console.log(response.data)
    //   let obj = {}
    //   let count = 0
    //   for (const listdevice of response.data.Devices) {
    //     //console.log(listdevice.DeviceAttributes)
    //     obj[count] = listdevice.DeviceAttributes
    //     count++
    //   }
    //   //console.log(obj)
    //   commit('setDeviceList', obj)
    //   commit('loading/setLoading', false, { root: true })
    // } catch (err) {
    //   alert(err)
    //   console.log(err)
    //   commit('loading/setLoading', false, { root: true })
    // }
  },

  setUserInfo({ commit }, userInfo) {
    let auth_userInfo = {}
    for (var prop in userInfo) {
      if (prop.startsWith('custom:')) {
        let tmp = prop.substring(7)
        auth_userInfo[tmp] = userInfo[prop]
      } else {
        auth_userInfo[prop] = userInfo[prop]
      }
    }
    commit('setUserInfo', auth_userInfo)
  },

  async setUserDetails({ commit, dispatch, state, rootState }, payload) {
    // update the profile on cognito
    axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

    let config = {
      headers: {
        Authorization: rootState.auth.bearer
      }
    }
    try {
      await axios.put('v2/user/' + state.user.username, payload, config);

      dispatch('notifications/addNotification', {
        type: 'success',
        message: 'User updated successfully'
      }, {root: true});

      commit('loading/setLoading', false, { root: true });

    } catch (err) {

      dispatch('notifications/addNotification', {
        type: 'error',
        message: 'Failed to update the user'
      }, {root: true});

      commit('loading/setLoading', false, { root: true });
    }
  },

  async setUserPicture({ commit, dispatch, state, rootState }, payload) {
    // update the profile on cognito

    //commit('loading/setLoading', true, { root: true })
    axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI
    let config = {
      headers: {
        Authorization: rootState.auth.bearer
      }
    };

    let picture_old = null;

    if (rootState.auth.userInfo.hasOwnProperty('picture')) {
      picture_old = rootState.auth.userInfo.picture;
    }

    try {
      let response = await axios.put(
        'v2/user/picture/' + state.user.username,
        payload,
        config
      );

      const profilePicture = response.data.profilePicture;

      commit('setPicture', profilePicture);

      if (picture_old !== null && picture_old !== undefined ) {
        const delete_payload = {
          folder_name: 'profile',
          file_name: picture_old
        }
        await dispatch('file/deleteFile', delete_payload, {root: true});
      }

      commit('loading/setLoading', false, {
        root: true
      });

      commit('file/setUploadVisible', false, {
        root: true
      });


    } catch (err) {
      alert(err)
    }
  },

  getBearerDetails({ commit }, bearer) {
    commit('setBearer', bearer)
  }
}

const mutations = {

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setLoggedIn(state, loggedInStatud) {
    state.loggedIn = loggedInStatud
  },

  setPicture(state, picture) {
    state.userInfo.picture = picture;
    state.user.attributes.picture = picture;
  },

  setAuthState(state, authState) {
    state.authState = authState
  },

  setUser(state, user) {
    state.user = user
  },

  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
    state.isProfileLoading = false
  },

  setDeviceList(state, devicelist) {
    state.devicelist = devicelist
  },

  setisProfileLoadingdone(state) {
    state.isProfileLoadingdone = true
  },

  setBearer(state, bearer) {
    state.bearer = bearer
  },

  setGroupsAndRoles(state, groups) {
    state.groups = groups;
    state.roles.TenantAdmin = groups.includes('TenantAdmin');
    state.roles.Facilitator = groups.includes('Facilitator');
    state.roles.Participant = groups.includes('Participant');
    state.roles.Manager = groups.includes('Manager');
    state.roles.Assistant = groups.includes('Assistant');
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
