<template>
  <div id="my-users-management" class="content">

    <table v-show="usersLinkedToCurrentUser.length > 0" id="linked-users">
            <thead>
            <tr>
              <th>Given Name</th>
              <th>Family Name</th>
              <th>E-mail</th>
              <th>Company</th>
              <th>Position</th>
              <th>Phone Number</th>
              <th>Journeys</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(linkedUser, indexUsersLinkedToCurrentUser) in usersLinkedToCurrentUser"
                :key="indexUsersLinkedToCurrentUser"
            >
              <td>
                <span v-if="linkedUser.hasOwnProperty('given_name')">{{ linkedUser.given_name }}</span>
              </td>
              <td>
                <span v-if="linkedUser.hasOwnProperty('family_name')">{{ linkedUser.family_name }}</span>
              </td>
              <td>
                <span v-if="linkedUser.hasOwnProperty('email')">{{ linkedUser.email }}</span>
              </td>
              <td class="company">
                <span v-if="linkedUser.hasOwnProperty('custom:company')">{{ linkedUser['custom:company'] }}</span>
              </td>
              <td>
                <span v-if="linkedUser.hasOwnProperty('custom:position')">{{ linkedUser['custom:position'] }}</span>
              </td>
              <td>
                <span v-if="linkedUser.hasOwnProperty('phone_number')">{{ linkedUser.phone_number }}</span>
              </td>
              <td>
                <div class="journeys-wrapper">
                  <div v-for="(journey, indexJourney) in linkedUser.Journeys"
                       :key="indexJourney">
                    <router-link :to="{ name: 'JourneyView', params: { id: journey.ID } }"> {{ journey.Name }} </router-link>
                  </div>
                </div>
              </td>

            </tr>
            </tbody>

          </table>

  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex'
import { Icon } from '@iconify/vue';

import { useForm, useField } from 'vee-validate'

import CentralLayout from '@/layouts/CentralLayout.vue';
import MainNav from '@/components/MainNav.vue';
import LogoUpload from '@/components/upload/LogoUpload.vue';

export default {

  name: 'UserManagement',

  components: {
    CentralLayout,
    MainNav,
    LogoUpload,
    Icon,
  },

  setup() {
    const store = useStore();

    const usersLinkedToCurrentUser = ref([]);

    const getUsersLinkedToCurrentUser = async () => {

      usersLinkedToCurrentUser.value = await store.dispatch('users/getUsersLinkedToCurrentUser', {})
    };

    onMounted(async() => {
      await getUsersLinkedToCurrentUser();
      await store.dispatch('loading/setLoading', false);
    })

    return {
      usersLinkedToCurrentUser,
    }
  }
}
</script>

<style lang="scss" scoped>

#my-users-management {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;

  div {
    width: 100%;
  }

  h2 {
    text-align: left;
  }

}

</style>
