<template>
  <!-- Card -->
  <div class="card mb-5">
    <div class="card-header ">
      <!-- Title -->
      <h4 class="card-header-title">
        Notifications
      </h4>

      <!-- Button -->
      <button class="btn btn-sm btn-primary">
        Disable all
      </button>
    </div>
    <div class="table-responsive p-2">
      <table class="table table-sm table-nowrap card-table ">
        <thead>
          <tr>
            <th>Type</th>
            <th class="text-center">E-mail</th>
          </tr>
        </thead>
        <tbody class="font-size-base">
          <tr>
            <td>
              Direct messages
            </td>

            <td class="text-center">
              <!-- Checkbox -->
              <div class="form-check d-inline-block me-n3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="emailCheckboxOne"
                  checked
                />
                <label class="form-check-label" for="emailCheckboxOne"></label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              Reminders
            </td>

            <td class="text-center">
              <!-- Checkbox -->
              <div class="form-check d-inline-block me-n3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="emailCheckboxTwo"
                  checked
                />
                <label class="form-check-label" for="emailCheckboxTwo"></label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              Assingments
            </td>

            <td class="text-center">
              <!-- Checkbox -->
              <div class="form-check d-inline-block me-n3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="emailCheckboxThree"
                  checked
                />
                <label
                  class="form-check-label"
                  for="emailCheckboxThree"
                ></label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              Announcements
            </td>
            <td class="text-center">
              <!-- Checkbox -->
              <div class="form-check d-inline-block me-n3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="callCheckboxFour"
                />
                <label class="form-check-label" for="callCheckboxFour"></label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Card -->
  <div class="card">
    <div class="card-header">
      <!-- Title -->
      <h4 class="card-header-title">
        Subscriptions
      </h4>

      <!-- Button -->
      <button class="btn btn-sm btn-primary">
        Unsubscribe all
      </button>
    </div>
    <div class="card-body">
      <!-- List group -->
      <div class="list-group list-group-flush my-n3">
        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <!-- Heading -->
              <h4 class="font-weight-base mb-1">
                Product updates
              </h4>

              <!-- Small -->
              <small class="text-muted">
                Major changes in our product offering
              </small>
            </div>
            <div class="col-auto">
              <!-- Switch -->
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  id="subscriptionsSwitchTwo"
                  type="checkbox"
                  checked
                />
                <label
                  class="form-check-label"
                  for="subscriptionsSwitchTwo"
                ></label>
              </div>
            </div>
          </div>
          <!-- / .row -->
        </div>
        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <!-- Heading -->
              <h4 class="font-weight-base mb-1">
                Newsletter
              </h4>

              <!-- Small -->
              <small class="text-muted">
                Updates on what’s going on here at
                {{ store.state.tenant.tenantinfo.title }}.
              </small>
            </div>
            <div class="col-auto">
              <!-- Switch -->
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  id="subscriptionsSwitchThree"
                  type="checkbox"
                  checked
                />
                <label
                  class="form-check-label"
                  for="subscriptionsSwitchThree"
                ></label>
              </div>
            </div>
          </div>
          <!-- / .row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()

    return {
      store
    }
  }
}
</script>

<style></style>
