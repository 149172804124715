<template>
  <nav class="navbar navbar-expand navbar-dark bg-ligth main-navigation mb-5"
       :class="{'navbar-hidden': !isLoggedIn()}"
       :style="tenantColor"
  >
    <div class="container">
      <router-link :to="{ name: 'Dashboard' }" class="navbar-brand">
        <img v-if="hasContentLoaded && tenantLogoURL !== null" class="bi me-4" :src="tenantLogoURL" alt="tenant logo"/>
        <img v-if="hasContentLoaded && tenantLogoURL === null" class="bi me-4 default-logo" :src="commovisLogo" alt="tenant logo"/>
      </router-link>

      <div class="full_width">
        <sidebarNavMobile
            v-if="hasContentLoaded"
            :isTopMenu="true"
            :isMyAccountMenu="isMyAccountMenu"
            @routeChange="routeChangeHandler($event)"
        />
      </div>

      <div v-if="hasContentLoaded" class="navbar-nav mr-auto">
        <!-- <a class="nav-item  nav-link">Home </a> -->
        <div class="navbar_links">

        </div>
        <div v-if="isLoggedIn()" class="dropdown dropdown-menu-right" id="user-menu">
          <a
              href="#"
              class="d-block link-dark text-decoration-none dropdown-toggle"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="width: 100%"
          >
            <div v-if="isProfilePictureSet()" class="img-user">
              <div class="img-load"></div>
              <img
                  v-show="allImagesLoaded"
                  :src="currentUserInfo.PictureURL"
                  alt="hairer"
                  width="32"
                  height="32"
                  class="rounded-circle"
              />
            </div>

            <div v-else class="empty-profile-picture"></div>

            <span class="username" style="text-align: left">
              <span class="user-name">{{ getUserName }}</span>

              <img src="../assets/icons/drop-down-arrow.png" class="dropdown-arrow">
          </span>
          </a>

          <div
              class="dropdown-menu dropdown-menu-right text-small"
              aria-labelledby="dropdownUser"
              style="right: 0; left: unset"
          >
            <div v-if="currentUserRoles.TenantAdmin" @click="changeRouteToAccount()">
              <router-link :to="{ name: 'Admin' }" class="dropdown-item">Admin</router-link>
            </div>
            <div v-if="currentUserRoles.TenantAdmin || currentUserRoles.Facilitator" @click="changeRouteToAccount()">
              <router-link :to="{ name: 'Account' }" class="dropdown-item">My Account</router-link>
            </div>
            <div @click="changeRouteToAccount()">
              <router-link :to="{ name: 'Settings' }" class="dropdown-item">Settings</router-link>
            </div>

            <hr class="dropdown-divider"/>
            <amplify-sign-out button-text="Sign out"></amplify-sign-out>
          </div>

        </div>
      </div>
    </div>
  </nav>
  <!--
  <header
    class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom"
  >
    <router-link
      to="/dashboard"
      class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
    >
      <img class="bi me-4" height="20" src="@/assets/logo_long.png" />
    </router-link>

    <ul class="nav col-12 col-md-auto mb-2 justify-content-right mb-md-0">
      <li>
        <router-link to="/editor" class="nav-link px-2 link-dark">
          editor
        </router-link>
      </li>
    </ul>
-->
  <!--
            <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
              <input
                type="search"
                class="form-control"
                placeholder="Search..."
              />
            </form>
-->
  <!--
    <div class="dropdown text-end">
      <a
        href="#"
        class="d-block link-dark text-decoration-none dropdown-toggle"
        id="dropdownUser1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          src="https://github.com/skullsick.png"
          alt="hairer"
          width="32"
          height="32"
          class="rounded-circle"
        />
      </a>
      <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser">
        <li>
          <router-link to="/profile" class="dropdown-item">Profile</router-link>
        </li>
        <li>
          <router-link to="/settings" class="dropdown-item"
            >Settings</router-link
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <amplify-sign-out button-text="Sign out"></amplify-sign-out>
        </li>
      </ul>
    </div>
  </header>-->
</template>

<script>
//import { auth_logout } from '@/services/auth'
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import { useStore } from 'vuex'
import SidebarNavMobile from "./SidebarNavMobile";
import commovisLogo from "@/assets/logo_long.png";
import $ from "jquery";

export default {

  components: {
    SidebarNavMobile,
  },

  setup() {
    const isMyAccountMenu = ref(false);

    const store = useStore();

    const hasContentLoaded = ref(false);

    const currentUserInfo = ref({});

    const tenantLogoURL = ref(null);

    currentUserInfo.value = store.state.auth.userInfo;

    const tenantColor = computed(() => {
      return {
        '--primary-color': store.state.tenant.tenantinfo.primarycolor
      }
    });

    const getUserName = computed(() => {
      return store.state.auth.userInfo.given_name
    });

    const currentUserRoles = ref(store.state.auth.roles);

    // wait for images to render
    const images = ref([]);
    const loadedImagesCount = ref(0);

    const allImagesLoaded = computed(() => {
      return loadedImagesCount.value === images.value.length;
    });

    const routeChangeHandler = (ev) => {
      isMyAccountMenu.value = false;
      document.getElementById('user-menu').style.borderBottom = 'none'
    }
    const changeRouteToAccount = () => {
      isMyAccountMenu.value = true;
      document.getElementById('user-menu').style.borderBottom = '2px solid ' + store.state.tenant.tenantinfo.primarycolor
    }

    const imageLoaded = () => {
      loadedImagesCount.value++;
    };

    const checkAllImagesLoaded = () => {
      if (allImagesLoaded.value) {
        // All images are loaded, you can perform additional actions or display the page
      } else {
        // Continue checking until all images are loaded
        setTimeout(checkAllImagesLoaded.value, 100);
      }
    };
    // end of wait for images to render

    const isLoggedIn = () => {
      return store.state.auth.loggedIn;
    };

    const isProfilePictureSet = () => {

      return ( currentUserInfo.value !== null
          && currentUserInfo.value !== undefined
          && currentUserInfo.value.hasOwnProperty('picture')
          && currentUserInfo.value.picture !== null
          && currentUserInfo.value.picture !== undefined
          && currentUserInfo.value.picture !== '' );
    }

    const getProfilePictureURL = async() => {
      currentUserInfo.value = store.state.auth.userInfo;
      if (isProfilePictureSet()) {
        currentUserInfo.value.PictureURL = await store.dispatch('file/getFile', {
          file_name: currentUserInfo.value.picture,
          folder_name: 'profile'
        });
      }
      // wait for images to render
      images.value.push(currentUserInfo.value.picture);
      const img = new Image();
      img.src = currentUserInfo.value.PictureURL;
      img.addEventListener('load', imageLoaded);
      // end of wait for images to render
    };

    const getTenantLogoURL = async() => {
      if (store.state.tenant.tenantinfo.logo !== null && store.state.tenant.tenantinfo.logo !== '' && store.state.tenant.tenantinfo.logo !== undefined) {
        tenantLogoURL.value = await store.dispatch('file/getTenantLogoFile');
      }
      else {
        tenantLogoURL.value = null;
      }
    };

    watch(() => store.state.auth.userInfo.picture, (newValue, oldValue) => {
      currentUserInfo.value = store.state.auth.userInfo;
      getProfilePictureURL();
    });

    watch(() => store.state.tenant.tenantinfo.logo, (newValue, oldValue) => {
      nextTick(() => {
        getTenantLogoURL();
      });
    });


    const changeHeight = () => {
      let navbarHeight = $('.navbar').outerHeight() || 0;
      let footerHeight = $('.footer').outerHeight() || 0;

      const mediaQuery = window.matchMedia('(max-width: 535px)');
      let containerMaxHeight = 0;
      if (mediaQuery.matches) {
        const mobileElement = document.getElementById("mobile");
        const sidebar_height = mobileElement ? mobileElement.getBoundingClientRect().height : 0;
        containerMaxHeight = window.innerHeight - navbarHeight - footerHeight - sidebar_height;
      } else {
        containerMaxHeight = window.innerHeight - navbarHeight - footerHeight;
      }
      store.dispatch('layout/setContainerMaxHeight', containerMaxHeight);
    };
    window.addEventListener('resize', changeHeight);

    onMounted(async () => {
      await getTenantLogoURL();
      await getProfilePictureURL();
      await checkAllImagesLoaded();
      hasContentLoaded.value = true;
      setTimeout(() => {
        try {
          changeHeight();
        } catch (error) {
          console.error("Error in changeHeight:", error);
        }
        const url = window.location.href;
        if(url.includes('admin') || url.includes('account') || url.includes('settings')){
          changeRouteToAccount()
        }
      }, 1000)
    });

    return {
      isMyAccountMenu,
      store,
      hasContentLoaded,
      allImagesLoaded,
      currentUserInfo,
      tenantColor,
      getUserName,
      currentUserRoles,
      tenantLogoURL,
      commovisLogo,
      routeChangeHandler,
      changeRouteToAccount,
      isLoggedIn,
      getProfilePictureURL,
      isProfilePictureSet,
      getTenantLogoURL
    }
  }

}

</script>

<style lang="scss" scoped>
.dropdown-toggle:after {
  display: none;
}
.dropdown-toggle {
  justify-content: center;
  display: flex !important;
}
amplify-sign-out {
  --container-height: 400px;
  --amplify-primary-color: var(--primary-color);
  --amplify-secondary-color: black;
  --amplify-primary-tint: black;
  --amplify-primary-shade: var(--primary-color);
  --amplify-secondary-contrast: white;
}

.main-navigation {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.navbar {
  opacity: 1;
  border-bottom: 1px solid #eee;

  .me-4 {
    max-width: 80%;
  }
  .default-logo {
    max-width: 30%;
    @media screen and (max-width: 769px) {
      max-width: 50%;
    }
  }
  .navbar-nav {
    max-width: 10%;
    @media screen and (max-width: 535px) {
      max-width: 20%;
      margin-right: 2%;
    }
  }
  &_links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;

    a {
      text-decoration: none;
      color: black;
    }

    .navbar-link {
      cursor: pointer;

      .icon {
        height: 25px;
      }
    }
  }
  .full_width {
    width: 100%;
    display: block;
    @media screen and (max-width: 535px) {
      display: none;
    }
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .username{
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-top: 32px;
      a {
        margin-left: 5px;
      }
      .user-name {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .dropdown-arrow {
        height: 10px;
        margin-left: 3px;
      }
    }
    &.dropdown-menu-right {
      width: 100%;
      position: relative;
      a {
        .img-user {
          display: contents;
          .img-load {
            position: absolute;
            width: 32px;
            height: 32px;
            background-color: #c1c1c1;
            border-radius: 50%;
          }
        }
        img.rounded-circle {
          position: absolute;
          display: flex;
          margin: 0 auto;
        }
        .empty-profile-picture {
          position: absolute;
          width: 32px;
          height: 32px;
          background-color: #c1c1c1;
          border-radius: 50%;
        }
      }
    }
  }
}

.navbar.navbar-hidden {
  opacity: 0;
}

</style>