<template>
  <MainLayout>
    <template v-slot:sidebar> </template>
    <template v-slot:content>
      <router-view />
    </template>
  </MainLayout>
</template>

<script>
import { onMounted } from 'vue'
import router from '@/router/'
import MainLayout from '@/layouts/MainLayout'
import { useDevice } from '@/composables/useDevice'

export default {
  components: {
    MainLayout
  },
  setup() {
    const { isMobile } = useDevice()

    onMounted(() => {
    console.log('onMounted in Start.vue');
    if (isMobile.value && router.currentRoute.value.path === '/') {
      console.log('Redirecting to MobileDashboard');
      router.push({ name: 'MobileDashboard' });
    } else {
      console.log('isMobile', isMobile.value);
      router.push({ name: 'Dashboard' });
    }
  });

    return {}
  }
}
</script>