<template>
  <div></div>
</template>
<script>

import {computed, inject, onMounted, onUnmounted, ref} from "vue";
import * as stripe from "stripe";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {

  name: 'PaymentSuccess',

  setup() {
    const userExtraAttributes = computed(() => store.getters["userPreferences/getUserExtraAttributes"]);

    const store = useStore();

    const router = useRouter();

    // const amountAdded = ref(0);

    const customerId = ref('');

    const redirectToPage = async (fromPage) => {
      switch (fromPage) {
        case 'Admin': {
          await router.push({
            name: 'Admin'
          });
          break;
        }
        case 'Account': {
          await router.push({
            name: 'Account'
          });
          break;
        }
        default: {

        }
      }
    }
    onUnmounted(() => {
      localStorage.removeItem('invoiceItems_amount')
      localStorage.removeItem('invoiceItems_country')
    })

    onMounted(async () => {

      await store.dispatch('loading/setLoading', true);

      const fromPage = localStorage.getItem('fromPage');

      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');

      if (sessionId && fromPage !== null) {
        let response = await store.dispatch('internalCurrency/onPaymentSuccess', {
          SessionID: sessionId,
          FromPage: fromPage,
          InvoiceItemsAmount: localStorage.getItem('invoiceItems_amount'),
          InvoiceItemsCountry: localStorage.getItem('invoiceItems_country'),
          TenantLink: window.location.origin
        });

        if (response !== null) {

          if (response.hasOwnProperty('amount_to_add')) {
            // amountAdded.value = response.amount_to_add;
            await store.dispatch('internalCurrency/getAmount');
            await store.dispatch('internalCurrency/setIsReturnToBilling', true);
            await redirectToPage(fromPage);
          }

          if (response.hasOwnProperty('redirect')) {
            await store.dispatch('internalCurrency/setIsReturnToBilling', true);
            await redirectToPage(fromPage);
          }

        }

      }
      else {
        await router.push({
          name: '404'
        });
      }



    })
    return {

    }
  }
}
</script>
<style lang="scss" scoped>

</style>
