import {v4 as uuidv4} from "uuid";

const getFileBase64Url = async (file) => {
    try {

        // Read the file data as a data URL
        const reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve, reject) => {
            reader.onload = () => {

                // Resolve with the data URL
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                // Reject if there is an error
                reject(error);
            };
        });
    } catch (error) {
        throw new Error('Image upload failed');
    }
};

const getMimeType = (file, fallback = null) => {
    const byteArray = new Uint8Array(file).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }

    switch (header) {
        case '89504e47':
            return 'image/png';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
            return 'image/jpeg';
        case 'ffd8ffe000104a464946':
            return 'image/jpeg';
        case '504b0304':
            return 'application/zip'; // ZIP
        case '504b34':
            return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; // PPTX
        case '4d534346':
            return 'application/vnd.ms-powerpoint'; // PPT
        case '25504446':
            return 'application/pdf'; // PDF
        case 'd0cf11e0':
            return 'application/vnd.ms-excel'; // XLS
        case '504b0506':
        case '504b0708':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // XLSX
        case '2c2d':
            return 'text/csv'; // CSV
        default:
            return fallback;
    }
};

const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0)

        byteString = atob(dataURI.split(',')[1])

    else byteString = unescape(dataURI.split(',')[1])

    // separate out the mime component
    var mimeString = dataURI
        .split(',')[0]

        .split(':')[1]

        .split(';')[0]

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {

        ia[i] = byteString.charCodeAt(i)

    }

    return new Blob([ia], {type: mimeString})

};

const createFileObject = async (file) => {
    try {

        // Simulate an asynchronous upload process
        const fileUrl = await getFileBase64Url(file);
        const fileType = getMimeType(file, file.type);
        let fileExt = null;
        // Convert Base64 image to binary
        const fileBlob = dataURItoBlob(fileUrl);

        switch (fileType) {
            case 'image/png':
                fileExt = 'png'
                break;

            case 'image/jpeg':
                fileExt = 'jpeg'
                break;

            case 'image/jpg':
                fileExt = 'jpg'
                break;

            case 'application/zip':
                fileExt = 'zip'
                break;

            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                fileExt = 'pptx'
                break;

            case 'application/vnd.ms-powerpoint':
                fileExt = 'ppt'
                break;

            case 'application/pdf':
                fileExt = 'pdf'
                break;

            case 'application/vnd.ms-excel':
                fileExt = 'xls'
                break;

            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                fileExt = 'xlsx'
                break;

            case 'text/csv':
                fileExt = 'csv'
                break;

            default:
                // Anweisungen werden ausgeführt,
                // falls keine der case-Klauseln mit expression übereinstimmt
                break;
        }

        // Return the expected response format
        return {
            success: 1,
            file: {
                name: uuidv4(),
                label: file.name,
                ext: fileExt,
                type: fileType,
                file: fileBlob,
                url: fileUrl,
                isNew: true
            }
        };
    } catch (error) {
        throw new Error('Image upload failed');
    }
};

export default createFileObject;