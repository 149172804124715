<template>

<!--  <transition name="fade">-->

    <div class="expedition_log_modal" v-if="hasContentLoaded">

      <div class="icon close"
           @click="$emit('close')">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </div>

      <div class="expedition_log_modal__content">

        <div class="expedition_log" v-html="htmlContent"></div>

      </div>

      <div class="backdrop" @click="$emit('close')"></div>

    </div>

<!--  </transition>-->

</template>

<script>

import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {

  name: 'ExpeditionLogModal',

  components: {
    FontAwesomeIcon
  },

  emits: ['close'],

  props: {

    expeditionLogNumber: {
      type: Number,
      required: true,
    },

    language: {
      type: String,
      required: true,
    },

  },

  setup(props, {emit}) {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const htmlContent = ref('');

    const expeditionLog = ref({});

    const language = ref('en');
    const ExpeditionLogNumber = ref(0);

    const ExpeditionLogs = computed(() => store.getters["tenantSpecificAssets/getterExpeditionLogs"]);

    const prepareFetchData = () => {

      language.value = props.language;
      ExpeditionLogNumber.value = props.expeditionLogNumber;

      expeditionLog.value = ExpeditionLogs.value.find((expeditionLog) => expeditionLog.ExpeditionLogNumber == ExpeditionLogNumber.value);

    };

    // const fetchContent = async () => {
    //   try {
    //     const response = await store.dispatch('file/getFile', {
    //       file_name: 'index_en.html',
    //       folder_name: 'expedition_logs'
    //     });
    //     sourcePath.value = response;
    //   } catch (error) {
    //     console.error('Error fetching HTML content:', error);
    //   }
    // };

    const fetchSignedUrl = async () => {

      if (expeditionLog.value !== undefined) {
        try {
          const response = await store.dispatch('file/getFile', {
            file_name: expeditionLog.value.HtmlFileName,
            folder_name: `expedition_logs/${language.value}`
          });

          return response;

        } catch (error) {
          await store.dispatch('notifications/addNotification', {
            type: 'error',
            message: 'Expedition Log failed to be fetched!'
          });
          return null;
        }
      }
      else {
        throw new Error('No expedition log found');
      }
    };

    const fetchHtmlContent = async() => {

      try {

        prepareFetchData();

        const signedUrl = await fetchSignedUrl();

        if (signedUrl) {

          const response = await fetch(signedUrl);
          if (response.ok) {
            htmlContent.value = await response.text();
          } else {
            await store.dispatch('notifications/addNotification', {
              type: 'error',
              message: 'Expedition Log failed to be fetched!'
            });
          }
        }
      } catch (error) {
        await store.dispatch('notifications/addNotification', {
          type: 'error',
          message: 'Expedition Log failed to be fetched!'
        });
      }
    };

    const fetchNewImageSrc = async (oldSrc) => {

      let URL = await store.dispatch('file/getFile', {
        file_name: oldSrc,
        folder_name: 'expedition_logs/assets'
      });

      return URL; // Assume the API returns an object with newSrc
    };

    const processSrcset = async (srcset) => {
      const sources = srcset.split(',').map(source => source.trim());

      const newSources = await Promise.all(
          sources.map(async (source) => {
            const [url, descriptor] = source.split(/\s+/); // Split by space to get the URL and the descriptor
            const newUrl = await fetchNewImageSrc(url);
            return `${newUrl} ${descriptor || ''}`.trim();
          })
      );

      return newSources.join(', ');
    };

    const updateImageSrcs = async () => {
      const imgTagRegex = /<img[^>]+>/g;
      const srcRegex = /src="([^">]+)"/;
      const srcsetRegex = /srcset="([^">]+)"/;
      let match;
      let newHtmlContent = htmlContent.value;

      // Extract all <img> tags one by one
      while ((match = imgTagRegex.exec(htmlContent.value)) !== null) {
        const imgTag = match[0];

        let updatedImgTag = imgTag;

        // Process the src attribute
        const srcMatch = imgTag.match(srcRegex);
        if (srcMatch) {
          const oldSrc = srcMatch[1];
          const newSrc = await fetchNewImageSrc(oldSrc);
          updatedImgTag = updatedImgTag.replace(srcRegex, `src="${newSrc}"`);
        }

        // Process the srcset attribute
        const srcsetMatch = imgTag.match(srcsetRegex);
        if (srcsetMatch) {
          const oldSrcset = srcsetMatch[1];
          const newSrcset = await processSrcset(oldSrcset);
          updatedImgTag = updatedImgTag.replace(srcsetRegex, `srcset="${newSrcset}"`);
        }

        // Replace the original img tag with the updated one in the entire HTML content
        newHtmlContent = newHtmlContent.replace(imgTag, updatedImgTag);
      }

      // Update the HTML content with all img tags processed
      htmlContent.value = newHtmlContent;

    }

    onMounted(async () => {

      await store.dispatch('loading/setLoading', true);

      await fetchHtmlContent();

      await updateImageSrcs();

      hasContentLoaded.value = true;

      await store.dispatch('loading/setLoading', false);

    });

    return {
      hasContentLoaded,
      htmlContent,
    }
  }

}

</script>

<style lang="scss" scoped>

.expedition_log_modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  .icon.close {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 10;
    font-size: 22px;
    display: unset;
    width: fit-content;
  }

  &__content {
    background-color: rgba(232, 231, 231);
    width: 100%;
    height: 100%;
    max-height: 100%;
    gap: 10px;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    font-size: 18px;
    text-align: center;
    overflow: auto;

    h3 {
      font-weight: bold;
      text-transform: uppercase;
      cursor: default;
    }

    p {
      cursor: default;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

  }

  .backdrop {
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>

<style lang="scss">

.expedition_log_modal {

  .expedition_log {
      /** Import Bootstrap functions */

      /** Bootstrap navbar fix (https://git.io/fADqW) */

      /* Fonts */

      /* Colors */

      /* Borders */

      /* Vertical align center */

      /* Vertical & horizontal align center */

      /** Import everything from autoload */

      /*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

      /* line 1, node_modules/bootstrap/scss/_root.scss */

      :root {
        --blue: #007bff;
        --indigo: #6610f2;
        --purple: #6f42c1;
        --pink: #e83e8c;
        --red: #dc3545;
        --orange: #fd7e14;
        --yellow: #ffc107;
        --green: #28a745;
        --teal: #20c997;
        --cyan: #17a2b8;
        --white: #fff;
        --gray: #6c757d;
        --gray-dark: #343a40;
        --primary: #525ddc;
        --secondary: #6c757d;
        --success: #28a745;
        --info: #17a2b8;
        --warning: #ffc107;
        --danger: #dc3545;
        --light: #f8f9fa;
        --dark: #343a40;
        --breakpoint-xs: 0;
        --breakpoint-sm: 576px;
        --breakpoint-md: 768px;
        --breakpoint-lg: 992px;
        --breakpoint-xl: 1200px;
        --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
      }

      /* line 19, node_modules/bootstrap/scss/_reboot.scss */

      *,
      *::before,
      *::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      /* line 25, node_modules/bootstrap/scss/_reboot.scss */

      html {
        font-family: sans-serif;
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }

      /* line 35, node_modules/bootstrap/scss/_reboot.scss */

      article,
      aside,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      main,
      nav,
      section {
        display: block;
      }

      /* line 46, node_modules/bootstrap/scss/_reboot.scss */

      body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
        background-color: #fff;
      }

      .main-content {
        margin: 0 auto !important;
      }

      /* line 62, node_modules/bootstrap/scss/_reboot.scss */

      [tabindex="-1"]:focus {
        outline: 0 !important;
      }

      /* line 72, node_modules/bootstrap/scss/_reboot.scss */

      hr {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
      }

      /* line 88, node_modules/bootstrap/scss/_reboot.scss */

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      /* line 97, node_modules/bootstrap/scss/_reboot.scss */

      p {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      /* line 110, node_modules/bootstrap/scss/_reboot.scss */

      abbr[title],
      abbr[data-original-title] {
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
        cursor: help;
        border-bottom: 0;
        text-decoration-skip-ink: none;
      }

      /* line 119, node_modules/bootstrap/scss/_reboot.scss */

      address {
        margin-bottom: 1rem;
        font-style: normal;
        line-height: inherit;
      }

      /* line 125, node_modules/bootstrap/scss/_reboot.scss */

      ol,
      ul,
      dl {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      /* line 132, node_modules/bootstrap/scss/_reboot.scss */

      ol ol,
      ul ul,
      ol ul,
      ul ol {
        margin-bottom: 0;
      }

      /* line 139, node_modules/bootstrap/scss/_reboot.scss */

      dt {
        font-weight: 700;
      }

      /* line 143, node_modules/bootstrap/scss/_reboot.scss */

      dd {
        margin-bottom: .5rem;
        margin-left: 0;
      }

      /* line 148, node_modules/bootstrap/scss/_reboot.scss */

      blockquote {
        margin: 0 0 1rem;
      }

      /* line 152, node_modules/bootstrap/scss/_reboot.scss */

      b,
      strong {
        font-weight: bolder;
      }

      /* line 157, node_modules/bootstrap/scss/_reboot.scss */

      small {
        font-size: 80%;
      }

      /* line 166, node_modules/bootstrap/scss/_reboot.scss */

      sub,
      sup {
        position: relative;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
      }

      /* line 174, node_modules/bootstrap/scss/_reboot.scss */

      sub {
        bottom: -.25em;
      }

      /* line 175, node_modules/bootstrap/scss/_reboot.scss */

      sup {
        top: -.5em;
      }

      /* line 182, node_modules/bootstrap/scss/_reboot.scss */

      a {
        color: #525ddc;
        text-decoration: none;
        background-color: transparent;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      a:hover {
        color: #2632bc;
        text-decoration: underline;
      }

      /* line 199, node_modules/bootstrap/scss/_reboot.scss */

      a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a:not([href]):not([tabindex]):hover,
      a:not([href]):not([tabindex]):focus {
        color: inherit;
        text-decoration: none;
      }

      /* line 208, node_modules/bootstrap/scss/_reboot.scss */

      a:not([href]):not([tabindex]):focus {
        outline: 0;
      }

      /* line 218, node_modules/bootstrap/scss/_reboot.scss */

      pre,
      code,
      kbd,
      samp {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        font-size: 1em;
      }

      /* line 226, node_modules/bootstrap/scss/_reboot.scss */

      pre {
        margin-top: 0;
        margin-bottom: 1rem;
        overflow: auto;
      }

      /* line 240, node_modules/bootstrap/scss/_reboot.scss */

      figure {
        margin: 0 0 1rem;
      }

      /* line 250, node_modules/bootstrap/scss/_reboot.scss */

      img {
        vertical-align: middle;
        border-style: none;
      }

      /* line 255, node_modules/bootstrap/scss/_reboot.scss */

      svg {
        overflow: hidden;
        vertical-align: middle;
      }

      /* line 267, node_modules/bootstrap/scss/_reboot.scss */

      table {
        border-collapse: collapse;
      }

      /* line 271, node_modules/bootstrap/scss/_reboot.scss */

      caption {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        color: #6c757d;
        text-align: left;
        caption-side: bottom;
      }

      /* line 279, node_modules/bootstrap/scss/_reboot.scss */

      th {
        text-align: inherit;
      }

      /* line 290, node_modules/bootstrap/scss/_reboot.scss */

      label {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      /* line 299, node_modules/bootstrap/scss/_reboot.scss */

      button {
        border-radius: 0;
      }

      /* line 308, node_modules/bootstrap/scss/_reboot.scss */

      button:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
      }

      /* line 313, node_modules/bootstrap/scss/_reboot.scss */

      input,
      button,
      select,
      optgroup,
      textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }

      /* line 324, node_modules/bootstrap/scss/_reboot.scss */

      button,
      input {
        overflow: visible;
      }

      /* line 329, node_modules/bootstrap/scss/_reboot.scss */

      button,
      select {
        text-transform: none;
      }

      /* line 337, node_modules/bootstrap/scss/_reboot.scss */

      select {
        word-wrap: normal;
      }

      /* line 345, node_modules/bootstrap/scss/_reboot.scss */

      button,
      [type="button"],
      [type="reset"],
      [type="submit"] {
        -webkit-appearance: button;
      }

      /* line 358, node_modules/bootstrap/scss/_reboot.scss */

      button:not(:disabled),
      [type="button"]:not(:disabled),
      [type="reset"]:not(:disabled),
      [type="submit"]:not(:disabled) {
        cursor: pointer;
      }

      /* line 365, node_modules/bootstrap/scss/_reboot.scss */

      button::-moz-focus-inner,
      [type="button"]::-moz-focus-inner,
      [type="reset"]::-moz-focus-inner,
      [type="submit"]::-moz-focus-inner {
        padding: 0;
        border-style: none;
      }

      /* line 373, node_modules/bootstrap/scss/_reboot.scss */

      input[type="radio"],
      input[type="checkbox"] {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
      }

      /* line 380, node_modules/bootstrap/scss/_reboot.scss */

      input[type="date"],
      input[type="time"],
      input[type="datetime-local"],
      input[type="month"] {
        -webkit-appearance: listbox;
      }

      /* line 392, node_modules/bootstrap/scss/_reboot.scss */

      textarea {
        overflow: auto;
        resize: vertical;
      }

      /* line 398, node_modules/bootstrap/scss/_reboot.scss */

      fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0;
      }

      /* line 413, node_modules/bootstrap/scss/_reboot.scss */

      legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: .5rem;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal;
      }

      /* line 425, node_modules/bootstrap/scss/_reboot.scss */

      progress {
        vertical-align: baseline;
      }

      /* line 430, node_modules/bootstrap/scss/_reboot.scss */

      [type="number"]::-webkit-inner-spin-button,
      [type="number"]::-webkit-outer-spin-button {
        height: auto;
      }

      /* line 435, node_modules/bootstrap/scss/_reboot.scss */

      [type="search"] {
        outline-offset: -2px;
        -webkit-appearance: none;
      }

      /* line 448, node_modules/bootstrap/scss/_reboot.scss */

      [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
      }

      /* line 457, node_modules/bootstrap/scss/_reboot.scss */

      ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
      }

      /* line 466, node_modules/bootstrap/scss/_reboot.scss */

      output {
        display: inline-block;
      }

      /* line 470, node_modules/bootstrap/scss/_reboot.scss */

      summary {
        display: list-item;
        cursor: pointer;
      }

      /* line 475, node_modules/bootstrap/scss/_reboot.scss */

      template {
        display: none;
      }

      /* line 481, node_modules/bootstrap/scss/_reboot.scss */

      [hidden] {
        display: none !important;
      }

      /* line 7, node_modules/bootstrap/scss/_type.scss */

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6 {
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
      }

      /* line 16, node_modules/bootstrap/scss/_type.scss */

      h1,
      .h1 {
        font-size: 2.5rem;
      }

      /* line 17, node_modules/bootstrap/scss/_type.scss */

      h2,
      .h2 {
        font-size: 2rem;
      }

      /* line 18, node_modules/bootstrap/scss/_type.scss */

      h3,
      .h3 {
        font-size: 1.75rem;
      }

      /* line 19, node_modules/bootstrap/scss/_type.scss */

      h4,
      .h4 {
        font-size: 1.5rem;
      }

      /* line 20, node_modules/bootstrap/scss/_type.scss */

      h5,
      .h5 {
        font-size: 1.25rem;
      }

      /* line 21, node_modules/bootstrap/scss/_type.scss */

      h6,
      .h6 {
        font-size: 1rem;
      }

      /* line 23, node_modules/bootstrap/scss/_type.scss */

      .lead {
        font-size: 1.25rem;
        font-weight: 300;
      }

      /* line 29, node_modules/bootstrap/scss/_type.scss */

      .display-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      /* line 34, node_modules/bootstrap/scss/_type.scss */

      .display-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      /* line 39, node_modules/bootstrap/scss/_type.scss */

      .display-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      /* line 44, node_modules/bootstrap/scss/_type.scss */

      .display-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      /* line 55, node_modules/bootstrap/scss/_type.scss */

      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      /* line 67, node_modules/bootstrap/scss/_type.scss */

      small,
      .small {
        font-size: 80%;
        font-weight: 400;
      }

      /* line 73, node_modules/bootstrap/scss/_type.scss */

      mark,
      .mark {
        padding: 0.2em;
        background-color: #fcf8e3;
      }

      /* line 84, node_modules/bootstrap/scss/_type.scss */

      .list-unstyled,
      .comment-list {
        padding-left: 0;
        list-style: none;
      }

      /* line 89, node_modules/bootstrap/scss/_type.scss */

      .list-inline {
        padding-left: 0;
        list-style: none;
      }

      /* line 92, node_modules/bootstrap/scss/_type.scss */

      .list-inline-item {
        display: inline-block;
      }

      /* line 95, node_modules/bootstrap/scss/_type.scss */

      .list-inline-item:not(:last-child) {
        margin-right: 0.5rem;
      }

      /* line 106, node_modules/bootstrap/scss/_type.scss */

      .initialism {
        font-size: 90%;
        text-transform: uppercase;
      }

      /* line 112, node_modules/bootstrap/scss/_type.scss */

      .blockquote {
        margin-bottom: 1rem;
        font-size: 1.25rem;
      }

      /* line 117, node_modules/bootstrap/scss/_type.scss */

      .blockquote-footer {
        display: block;
        font-size: 80%;
        color: #6c757d;
      }

      /* line 122, node_modules/bootstrap/scss/_type.scss */

      .blockquote-footer::before {
        content: "\2014\A0";
      }

      /* line 8, node_modules/bootstrap/scss/_images.scss */

      .img-fluid,
      .wp-caption img {
        max-width: 100%;
        height: auto;
      }

      /* line 14, node_modules/bootstrap/scss/_images.scss */

      .img-thumbnail {
        padding: 0.25rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        max-width: 100%;
        height: auto;
      }

      /* line 29, node_modules/bootstrap/scss/_images.scss */

      .figure,
      .wp-caption {
        display: inline-block;
      }

      /* line 34, node_modules/bootstrap/scss/_images.scss */

      .figure-img,
      .wp-caption img {
        margin-bottom: 0.5rem;
        line-height: 1;
      }

      /* line 39, node_modules/bootstrap/scss/_images.scss */

      .figure-caption,
      .wp-caption-text {
        font-size: 90%;
        color: #6c757d;
      }

      /* line 2, node_modules/bootstrap/scss/_code.scss */

      code {
        font-size: 87.5%;
        color: #e83e8c;
        word-break: break-word;
      }

      /* line 8, node_modules/bootstrap/scss/_code.scss */

      a > code {
        color: inherit;
      }

      /* line 14, node_modules/bootstrap/scss/_code.scss */

      kbd {
        padding: 0.2rem 0.4rem;
        font-size: 87.5%;
        color: #fff;
        background-color: #212529;
        border-radius: 0.2rem;
      }

      /* line 22, node_modules/bootstrap/scss/_code.scss */

      kbd kbd {
        padding: 0;
        font-size: 100%;
        font-weight: 700;
      }

      /* line 31, node_modules/bootstrap/scss/_code.scss */

      pre {
        display: block;
        font-size: 87.5%;
        color: #212529;
      }

      /* line 37, node_modules/bootstrap/scss/_code.scss */

      pre code {
        font-size: inherit;
        color: inherit;
        word-break: normal;
      }

      /* line 45, node_modules/bootstrap/scss/_code.scss */

      .pre-scrollable {
        max-height: 340px;
        overflow-y: scroll;
      }

      /* line 6, node_modules/bootstrap/scss/_grid.scss */

      .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }

      @media (min-width: 576px) {
        /* line 6, node_modules/bootstrap/scss/_grid.scss */

        .container {
          max-width: 540px;
        }
      }

      @media (min-width: 768px) {
        /* line 6, node_modules/bootstrap/scss/_grid.scss */

        .container {
          max-width: 720px;
        }
      }

      @media (min-width: 992px) {
        /* line 6, node_modules/bootstrap/scss/_grid.scss */

        .container {
          max-width: 960px;
        }
      }

      @media (min-width: 1200px) {
        /* line 6, node_modules/bootstrap/scss/_grid.scss */

        .container {
          max-width: 1140px;
        }
      }

      /* line 18, node_modules/bootstrap/scss/_grid.scss */

      .container-fluid {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }

      /* line 28, node_modules/bootstrap/scss/_grid.scss */

      .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
      }

      /* line 34, node_modules/bootstrap/scss/_grid.scss */

      .no-gutters {
        margin-right: 0;
        margin-left: 0;
      }

      /* line 38, node_modules/bootstrap/scss/_grid.scss */

      .no-gutters > .col,
      .no-gutters > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }

      /* line 8, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12,
      .col,
      .col-auto,
      .col-sm-1,
      .col-sm-2,
      .col-sm-3,
      .col-sm-4,
      .col-sm-5,
      .col-sm-6,
      .col-sm-7,
      .col-sm-8,
      .col-sm-9,
      .col-sm-10,
      .col-sm-11,
      .col-sm-12,
      .col-sm,
      .col-sm-auto,
      .col-md-1,
      .col-md-2,
      .col-md-3,
      .col-md-4,
      .col-md-5,
      .col-md-6,
      .col-md-7,
      .col-md-8,
      .col-md-9,
      .col-md-10,
      .col-md-11,
      .col-md-12,
      .col-md,
      .col-md-auto,
      .col-lg-1,
      .col-lg-2,
      .col-lg-3,
      .col-lg-4,
      .col-lg-5,
      .col-lg-6,
      .col-lg-7,
      .col-lg-8,
      .col-lg-9,
      .col-lg-10,
      .col-lg-11,
      .col-lg-12,
      .col-lg,
      .col-lg-auto,
      .col-xl-1,
      .col-xl-2,
      .col-xl-3,
      .col-xl-4,
      .col-xl-5,
      .col-xl-6,
      .col-xl-7,
      .col-xl-8,
      .col-xl-9,
      .col-xl-10,
      .col-xl-11,
      .col-xl-12,
      .col-xl,
      .col-xl-auto {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }

      /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }

      /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
      }

      /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .col-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
      }

      /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .order-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-1 {
        margin-left: 8.33333%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-2 {
        margin-left: 16.66667%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-3 {
        margin-left: 25%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-4 {
        margin-left: 33.33333%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-5 {
        margin-left: 41.66667%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-6 {
        margin-left: 50%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-7 {
        margin-left: 58.33333%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-8 {
        margin-left: 66.66667%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-9 {
        margin-left: 75%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-10 {
        margin-left: 83.33333%;
      }

      /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

      .offset-11 {
        margin-left: 91.66667%;
      }

      @media (min-width: 576px) {
        /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
        }

        /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-auto {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-1 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
          max-width: 8.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-2 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-3 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-4 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-5 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-6 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-7 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
          max-width: 58.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-8 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
          max-width: 66.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-9 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 75%;
          flex: 0 0 75%;
          max-width: 75%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-10 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
          max-width: 83.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-11 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
          max-width: 91.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-sm-12 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }

        /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-first {
          -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
          order: -1;
        }

        /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-last {
          -webkit-box-ordinal-group: 14;
          -ms-flex-order: 13;
          order: 13;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-0 {
          -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
          order: 0;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-1 {
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-2 {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-3 {
          -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
          order: 3;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-4 {
          -webkit-box-ordinal-group: 5;
          -ms-flex-order: 4;
          order: 4;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-5 {
          -webkit-box-ordinal-group: 6;
          -ms-flex-order: 5;
          order: 5;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-6 {
          -webkit-box-ordinal-group: 7;
          -ms-flex-order: 6;
          order: 6;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-7 {
          -webkit-box-ordinal-group: 8;
          -ms-flex-order: 7;
          order: 7;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-8 {
          -webkit-box-ordinal-group: 9;
          -ms-flex-order: 8;
          order: 8;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-9 {
          -webkit-box-ordinal-group: 10;
          -ms-flex-order: 9;
          order: 9;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-10 {
          -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
          order: 10;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-11 {
          -webkit-box-ordinal-group: 12;
          -ms-flex-order: 11;
          order: 11;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-sm-12 {
          -webkit-box-ordinal-group: 13;
          -ms-flex-order: 12;
          order: 12;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-0 {
          margin-left: 0;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-1 {
          margin-left: 8.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-2 {
          margin-left: 16.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-3 {
          margin-left: 25%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-4 {
          margin-left: 33.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-5 {
          margin-left: 41.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-6 {
          margin-left: 50%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-7 {
          margin-left: 58.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-8 {
          margin-left: 66.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-9 {
          margin-left: 75%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-10 {
          margin-left: 83.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-sm-11 {
          margin-left: 91.66667%;
        }
      }

      @media (min-width: 768px) {
        /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
        }

        /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-auto {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-1 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
          max-width: 8.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-2 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-3 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-4 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-5 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-6 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-7 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
          max-width: 58.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-8 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
          max-width: 66.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-9 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 75%;
          flex: 0 0 75%;
          max-width: 75%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-10 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
          max-width: 83.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-11 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
          max-width: 91.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-md-12 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }

        /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-first {
          -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
          order: -1;
        }

        /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-last {
          -webkit-box-ordinal-group: 14;
          -ms-flex-order: 13;
          order: 13;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-0 {
          -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
          order: 0;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-1 {
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-2 {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-3 {
          -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
          order: 3;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-4 {
          -webkit-box-ordinal-group: 5;
          -ms-flex-order: 4;
          order: 4;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-5 {
          -webkit-box-ordinal-group: 6;
          -ms-flex-order: 5;
          order: 5;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-6 {
          -webkit-box-ordinal-group: 7;
          -ms-flex-order: 6;
          order: 6;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-7 {
          -webkit-box-ordinal-group: 8;
          -ms-flex-order: 7;
          order: 7;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-8 {
          -webkit-box-ordinal-group: 9;
          -ms-flex-order: 8;
          order: 8;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-9 {
          -webkit-box-ordinal-group: 10;
          -ms-flex-order: 9;
          order: 9;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-10 {
          -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
          order: 10;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-11 {
          -webkit-box-ordinal-group: 12;
          -ms-flex-order: 11;
          order: 11;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-md-12 {
          -webkit-box-ordinal-group: 13;
          -ms-flex-order: 12;
          order: 12;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-0 {
          margin-left: 0;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-1 {
          margin-left: 8.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-2 {
          margin-left: 16.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-3 {
          margin-left: 25%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-4 {
          margin-left: 33.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-5 {
          margin-left: 41.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-6 {
          margin-left: 50%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-7 {
          margin-left: 58.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-8 {
          margin-left: 66.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-9 {
          margin-left: 75%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-10 {
          margin-left: 83.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-md-11 {
          margin-left: 91.66667%;
        }
      }

      @media (min-width: 992px) {
        /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
        }

        /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-auto {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-1 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
          max-width: 8.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-2 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-3 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-4 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-5 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-6 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-7 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
          max-width: 58.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-8 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
          max-width: 66.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-9 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 75%;
          flex: 0 0 75%;
          max-width: 75%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-10 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
          max-width: 83.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-11 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
          max-width: 91.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-lg-12 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }

        /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-first {
          -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
          order: -1;
        }

        /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-last {
          -webkit-box-ordinal-group: 14;
          -ms-flex-order: 13;
          order: 13;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-0 {
          -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
          order: 0;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-1 {
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-2 {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-3 {
          -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
          order: 3;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-4 {
          -webkit-box-ordinal-group: 5;
          -ms-flex-order: 4;
          order: 4;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-5 {
          -webkit-box-ordinal-group: 6;
          -ms-flex-order: 5;
          order: 5;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-6 {
          -webkit-box-ordinal-group: 7;
          -ms-flex-order: 6;
          order: 6;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-7 {
          -webkit-box-ordinal-group: 8;
          -ms-flex-order: 7;
          order: 7;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-8 {
          -webkit-box-ordinal-group: 9;
          -ms-flex-order: 8;
          order: 8;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-9 {
          -webkit-box-ordinal-group: 10;
          -ms-flex-order: 9;
          order: 9;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-10 {
          -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
          order: 10;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-11 {
          -webkit-box-ordinal-group: 12;
          -ms-flex-order: 11;
          order: 11;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-lg-12 {
          -webkit-box-ordinal-group: 13;
          -ms-flex-order: 12;
          order: 12;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-0 {
          margin-left: 0;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-1 {
          margin-left: 8.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-2 {
          margin-left: 16.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-3 {
          margin-left: 25%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-4 {
          margin-left: 33.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-5 {
          margin-left: 41.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-6 {
          margin-left: 50%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-7 {
          margin-left: 58.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-8 {
          margin-left: 66.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-9 {
          margin-left: 75%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-10 {
          margin-left: 83.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-lg-11 {
          margin-left: 91.66667%;
        }
      }

      @media (min-width: 1200px) {
        /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
        }

        /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-auto {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-1 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
          max-width: 8.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-2 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-3 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-4 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-5 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-6 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-7 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
          max-width: 58.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-8 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
          max-width: 66.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-9 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 75%;
          flex: 0 0 75%;
          max-width: 75%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-10 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
          max-width: 83.33333%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-11 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
          max-width: 91.66667%;
        }

        /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .col-xl-12 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }

        /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-first {
          -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
          order: -1;
        }

        /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-last {
          -webkit-box-ordinal-group: 14;
          -ms-flex-order: 13;
          order: 13;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-0 {
          -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
          order: 0;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-1 {
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-2 {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-3 {
          -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
          order: 3;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-4 {
          -webkit-box-ordinal-group: 5;
          -ms-flex-order: 4;
          order: 4;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-5 {
          -webkit-box-ordinal-group: 6;
          -ms-flex-order: 5;
          order: 5;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-6 {
          -webkit-box-ordinal-group: 7;
          -ms-flex-order: 6;
          order: 6;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-7 {
          -webkit-box-ordinal-group: 8;
          -ms-flex-order: 7;
          order: 7;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-8 {
          -webkit-box-ordinal-group: 9;
          -ms-flex-order: 8;
          order: 8;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-9 {
          -webkit-box-ordinal-group: 10;
          -ms-flex-order: 9;
          order: 9;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-10 {
          -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
          order: 10;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-11 {
          -webkit-box-ordinal-group: 12;
          -ms-flex-order: 11;
          order: 11;
        }

        /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .order-xl-12 {
          -webkit-box-ordinal-group: 13;
          -ms-flex-order: 12;
          order: 12;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-0 {
          margin-left: 0;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-1 {
          margin-left: 8.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-2 {
          margin-left: 16.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-3 {
          margin-left: 25%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-4 {
          margin-left: 33.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-5 {
          margin-left: 41.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-6 {
          margin-left: 50%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-7 {
          margin-left: 58.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-8 {
          margin-left: 66.66667%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-9 {
          margin-left: 75%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-10 {
          margin-left: 83.33333%;
        }

        /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

        .offset-xl-11 {
          margin-left: 91.66667%;
        }
      }

      /* line 5, node_modules/bootstrap/scss/_tables.scss */

      .table {
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
      }

      /* line 11, node_modules/bootstrap/scss/_tables.scss */

      .table th,
      .table td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
      }

      /* line 18, node_modules/bootstrap/scss/_tables.scss */

      .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
      }

      /* line 23, node_modules/bootstrap/scss/_tables.scss */

      .table tbody + tbody {
        border-top: 2px solid #dee2e6;
      }

      /* line 34, node_modules/bootstrap/scss/_tables.scss */

      .table-sm th,
      .table-sm td {
        padding: 0.3rem;
      }

      /* line 45, node_modules/bootstrap/scss/_tables.scss */

      .table-bordered {
        border: 1px solid #dee2e6;
      }

      /* line 48, node_modules/bootstrap/scss/_tables.scss */

      .table-bordered th,
      .table-bordered td {
        border: 1px solid #dee2e6;
      }

      /* line 54, node_modules/bootstrap/scss/_tables.scss */

      .table-bordered thead th,
      .table-bordered thead td {
        border-bottom-width: 2px;
      }

      /* line 62, node_modules/bootstrap/scss/_tables.scss */

      .table-borderless th,
      .table-borderless td,
      .table-borderless thead th,
      .table-borderless tbody + tbody {
        border: 0;
      }

      /* line 75, node_modules/bootstrap/scss/_tables.scss */

      .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover tbody tr:hover {
        color: #212529;
        background-color: rgba(0, 0, 0, 0.075);
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-primary,
      .table-primary > th,
      .table-primary > td {
        background-color: #cfd2f5;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-primary th,
      .table-primary td,
      .table-primary thead th,
      .table-primary tbody + tbody {
        border-color: #a5abed;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-primary:hover {
        background-color: #babef1;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-primary:hover > td,
      .table-hover .table-primary:hover > th {
        background-color: #babef1;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-secondary,
      .table-secondary > th,
      .table-secondary > td {
        background-color: #d6d8db;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-secondary th,
      .table-secondary td,
      .table-secondary thead th,
      .table-secondary tbody + tbody {
        border-color: #b3b7bb;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-secondary:hover {
        background-color: #c8cbcf;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-secondary:hover > td,
      .table-hover .table-secondary:hover > th {
        background-color: #c8cbcf;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-success,
      .table-success > th,
      .table-success > td {
        background-color: #c3e6cb;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-success th,
      .table-success td,
      .table-success thead th,
      .table-success tbody + tbody {
        border-color: #8fd19e;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-success:hover {
        background-color: #b1dfbb;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-success:hover > td,
      .table-hover .table-success:hover > th {
        background-color: #b1dfbb;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-info,
      .table-info > th,
      .table-info > td {
        background-color: #bee5eb;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-info th,
      .table-info td,
      .table-info thead th,
      .table-info tbody + tbody {
        border-color: #86cfda;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-info:hover {
        background-color: #abdde5;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-info:hover > td,
      .table-hover .table-info:hover > th {
        background-color: #abdde5;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-warning,
      .table-warning > th,
      .table-warning > td {
        background-color: #ffeeba;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-warning th,
      .table-warning td,
      .table-warning thead th,
      .table-warning tbody + tbody {
        border-color: #ffdf7e;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-warning:hover {
        background-color: #ffe8a1;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-warning:hover > td,
      .table-hover .table-warning:hover > th {
        background-color: #ffe8a1;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-danger,
      .table-danger > th,
      .table-danger > td {
        background-color: #f5c6cb;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-danger th,
      .table-danger td,
      .table-danger thead th,
      .table-danger tbody + tbody {
        border-color: #ed969e;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-danger:hover {
        background-color: #f1b0b7;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-danger:hover > td,
      .table-hover .table-danger:hover > th {
        background-color: #f1b0b7;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-light,
      .table-light > th,
      .table-light > td {
        background-color: #fdfdfe;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-light th,
      .table-light td,
      .table-light thead th,
      .table-light tbody + tbody {
        border-color: #fbfcfc;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-light:hover {
        background-color: #ececf6;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-light:hover > td,
      .table-hover .table-light:hover > th {
        background-color: #ececf6;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-dark,
      .table-dark > th,
      .table-dark > td {
        background-color: #c6c8ca;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-dark th,
      .table-dark td,
      .table-dark thead th,
      .table-dark tbody + tbody {
        border-color: #95999c;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-dark:hover {
        background-color: #b9bbbe;
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-dark:hover > td,
      .table-hover .table-dark:hover > th {
        background-color: #b9bbbe;
      }

      /* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-active,
      .table-active > th,
      .table-active > td {
        background-color: rgba(0, 0, 0, 0.075);
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-hover .table-active:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

      .table-hover .table-active:hover > td,
      .table-hover .table-active:hover > th {
        background-color: rgba(0, 0, 0, 0.075);
      }

      /* line 114, node_modules/bootstrap/scss/_tables.scss */

      .table .thead-dark th {
        color: #fff;
        background-color: #343a40;
        border-color: #454d55;
      }

      /* line 122, node_modules/bootstrap/scss/_tables.scss */

      .table .thead-light th {
        color: #495057;
        background-color: #e9ecef;
        border-color: #dee2e6;
      }

      /* line 130, node_modules/bootstrap/scss/_tables.scss */

      .table-dark {
        color: #fff;
        background-color: #343a40;
      }

      /* line 134, node_modules/bootstrap/scss/_tables.scss */

      .table-dark th,
      .table-dark td,
      .table-dark thead th {
        border-color: #454d55;
      }

      /* line 140, node_modules/bootstrap/scss/_tables.scss */

      .table-dark.table-bordered {
        border: 0;
      }

      /* line 145, node_modules/bootstrap/scss/_tables.scss */

      .table-dark.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.05);
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .table-dark.table-hover tbody tr:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.075);
      }

      @media (max-width: 575.98px) {
        /* line 171, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-sm {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }

        /* line 179, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-sm > .table-bordered {
          border: 0;
        }
      }

      @media (max-width: 767.98px) {
        /* line 171, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-md {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }

        /* line 179, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-md > .table-bordered {
          border: 0;
        }
      }

      @media (max-width: 991.98px) {
        /* line 171, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-lg {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }

        /* line 179, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-lg > .table-bordered {
          border: 0;
        }
      }

      @media (max-width: 1199.98px) {
        /* line 171, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-xl {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }

        /* line 179, node_modules/bootstrap/scss/_tables.scss */

        .table-responsive-xl > .table-bordered {
          border: 0;
        }
      }

      /* line 171, node_modules/bootstrap/scss/_tables.scss */

      .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }

      /* line 179, node_modules/bootstrap/scss/_tables.scss */

      .table-responsive > .table-bordered {
        border: 0;
      }

      /* line 7, node_modules/bootstrap/scss/_forms.scss */

      .form-control,
      .comment-form input[type="text"],
      .comment-form input[type="email"],
      .comment-form input[type="url"],
      .comment-form textarea,
      .search-form .search-field {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 7, node_modules/bootstrap/scss/_forms.scss */

        .form-control,
        .comment-form input[type="text"],
        .comment-form input[type="email"],
        .comment-form input[type="url"],
        .comment-form textarea,
        .search-form .search-field {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 28, node_modules/bootstrap/scss/_forms.scss */

      .form-control::-ms-expand,
      .comment-form input[type="text"]::-ms-expand,
      .comment-form input[type="email"]::-ms-expand,
      .comment-form input[type="url"]::-ms-expand,
      .comment-form textarea::-ms-expand,
      .search-form .search-field::-ms-expand {
        background-color: transparent;
        border: 0;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_forms.scss */

      .form-control:focus,
      .comment-form input:focus[type="text"],
      .comment-form input:focus[type="email"],
      .comment-form input:focus[type="url"],
      .comment-form textarea:focus,
      .search-form .search-field:focus {
        color: #495057;
        background-color: #fff;
        border-color: #bcc0f1;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 37, node_modules/bootstrap/scss/_forms.scss */

      .form-control::-webkit-input-placeholder,
      .comment-form input[type="text"]::-webkit-input-placeholder,
      .comment-form input[type="email"]::-webkit-input-placeholder,
      .comment-form input[type="url"]::-webkit-input-placeholder,
      .comment-form textarea::-webkit-input-placeholder,
      .search-form .search-field::-webkit-input-placeholder {
        color: #6c757d;
        opacity: 1;
      }

      .form-control::-ms-input-placeholder,
      .comment-form input[type="text"]::-ms-input-placeholder,
      .comment-form input[type="email"]::-ms-input-placeholder,
      .comment-form input[type="url"]::-ms-input-placeholder,
      .comment-form textarea::-ms-input-placeholder,
      .search-form .search-field::-ms-input-placeholder {
        color: #6c757d;
        opacity: 1;
      }

      .form-control::placeholder,
      .comment-form input[type="text"]::placeholder,
      .comment-form input[type="email"]::placeholder,
      .comment-form input[type="url"]::placeholder,
      .comment-form textarea::placeholder,
      .search-form .search-field::placeholder {
        color: #6c757d;
        opacity: 1;
      }

      /* line 48, node_modules/bootstrap/scss/_forms.scss */

      .form-control:disabled,
      .comment-form input:disabled[type="text"],
      .comment-form input:disabled[type="email"],
      .comment-form input:disabled[type="url"],
      .comment-form textarea:disabled,
      .search-form .search-field:disabled,
      .form-control[readonly],
      .comment-form input[readonly][type="text"],
      .comment-form input[readonly][type="email"],
      .comment-form input[readonly][type="url"],
      .comment-form textarea[readonly],
      .search-form .search-field[readonly] {
        background-color: #e9ecef;
        opacity: 1;
      }

      /* line 57, node_modules/bootstrap/scss/_forms.scss */

      select.form-control:focus::-ms-value,
      .search-form select.search-field:focus::-ms-value {
        color: #495057;
        background-color: #fff;
      }

      /* line 69, node_modules/bootstrap/scss/_forms.scss */

      .form-control-file,
      .form-control-range {
        display: block;
        width: 100%;
      }

      /* line 82, node_modules/bootstrap/scss/_forms.scss */

      .col-form-label {
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
      }

      /* line 90, node_modules/bootstrap/scss/_forms.scss */

      .col-form-label-lg {
        padding-top: calc(0.5rem + 1px);
        padding-bottom: calc(0.5rem + 1px);
        font-size: 1.25rem;
        line-height: 1.5;
      }

      /* line 97, node_modules/bootstrap/scss/_forms.scss */

      .col-form-label-sm {
        padding-top: calc(0.25rem + 1px);
        padding-bottom: calc(0.25rem + 1px);
        font-size: 0.875rem;
        line-height: 1.5;
      }

      /* line 110, node_modules/bootstrap/scss/_forms.scss */

      .form-control-plaintext {
        display: block;
        width: 100%;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        margin-bottom: 0;
        line-height: 1.5;
        color: #212529;
        background-color: transparent;
        border: solid transparent;
        border-width: 1px 0;
      }

      /* line 122, node_modules/bootstrap/scss/_forms.scss */

      .form-control-plaintext.form-control-sm,
      .form-control-plaintext.form-control-lg {
        padding-right: 0;
        padding-left: 0;
      }

      /* line 137, node_modules/bootstrap/scss/_forms.scss */

      .form-control-sm {
        height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
      }

      /* line 145, node_modules/bootstrap/scss/_forms.scss */

      .form-control-lg {
        height: calc(1.5em + 1rem + 2px);
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
      }

      /* line 155, node_modules/bootstrap/scss/_forms.scss */

      select.form-control[size],
      .search-form select.search-field[size],
      select.form-control[multiple],
      .search-form select.search-field[multiple] {
        height: auto;
      }

      /* line 161, node_modules/bootstrap/scss/_forms.scss */

      textarea.form-control,
      .comment-form textarea,
      .search-form textarea.search-field {
        height: auto;
      }

      /* line 170, node_modules/bootstrap/scss/_forms.scss */

      .form-group,
      .comment-form p,
      .search-form label {
        margin-bottom: 1rem;
      }

      /* line 174, node_modules/bootstrap/scss/_forms.scss */

      .form-text {
        display: block;
        margin-top: 0.25rem;
      }

      /* line 184, node_modules/bootstrap/scss/_forms.scss */

      .form-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;
      }

      /* line 190, node_modules/bootstrap/scss/_forms.scss */

      .form-row > .col,
      .form-row > [class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
      }

      /* line 202, node_modules/bootstrap/scss/_forms.scss */

      .form-check {
        position: relative;
        display: block;
        padding-left: 1.25rem;
      }

      /* line 208, node_modules/bootstrap/scss/_forms.scss */

      .form-check-input {
        position: absolute;
        margin-top: 0.3rem;
        margin-left: -1.25rem;
      }

      /* line 213, node_modules/bootstrap/scss/_forms.scss */

      .form-check-input:disabled ~ .form-check-label {
        color: #6c757d;
      }

      /* line 218, node_modules/bootstrap/scss/_forms.scss */

      .form-check-label {
        margin-bottom: 0;
      }

      /* line 222, node_modules/bootstrap/scss/_forms.scss */

      .form-check-inline {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 0;
        margin-right: 0.75rem;
      }

      /* line 229, node_modules/bootstrap/scss/_forms.scss */

      .form-check-inline .form-check-input {
        position: static;
        margin-top: 0;
        margin-right: 0.3125rem;
        margin-left: 0;
      }

      /* line 30, node_modules/bootstrap/scss/mixins/_forms.scss */

      .valid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #28a745;
      }

      /* line 38, node_modules/bootstrap/scss/mixins/_forms.scss */

      .valid-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: .1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #fff;
        background-color: rgba(40, 167, 69, 0.9);
        border-radius: 0.25rem;
      }

      /* line 54, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control:valid,
      .was-validated .comment-form input:valid[type="text"],
      .comment-form .was-validated input:valid[type="text"],
      .was-validated .comment-form input:valid[type="email"],
      .comment-form .was-validated input:valid[type="email"],
      .was-validated .comment-form input:valid[type="url"],
      .comment-form .was-validated input:valid[type="url"],
      .was-validated .comment-form textarea:valid,
      .comment-form .was-validated textarea:valid,
      .was-validated .search-form .search-field:valid,
      .search-form .was-validated .search-field:valid,
      .form-control.is-valid,
      .comment-form input.is-valid[type="text"],
      .comment-form input.is-valid[type="email"],
      .comment-form input.is-valid[type="url"],
      .comment-form textarea.is-valid,
      .search-form .is-valid.search-field {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right calc(0.375em + 0.1875rem);
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

      /* line 66, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control:valid:focus,
      .was-validated .comment-form input:valid:focus[type="text"],
      .comment-form .was-validated input:valid:focus[type="text"],
      .was-validated .comment-form input:valid:focus[type="email"],
      .comment-form .was-validated input:valid:focus[type="email"],
      .was-validated .comment-form input:valid:focus[type="url"],
      .comment-form .was-validated input:valid:focus[type="url"],
      .was-validated .comment-form textarea:valid:focus,
      .comment-form .was-validated textarea:valid:focus,
      .was-validated .search-form .search-field:valid:focus,
      .search-form .was-validated .search-field:valid:focus,
      .form-control.is-valid:focus,
      .comment-form input.is-valid:focus[type="text"],
      .comment-form input.is-valid:focus[type="email"],
      .comment-form input.is-valid:focus[type="url"],
      .comment-form textarea.is-valid:focus,
      .search-form .is-valid.search-field:focus {
        border-color: #28a745;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control:valid ~ .valid-feedback,
      .was-validated .comment-form input:valid[type="text"] ~ .valid-feedback,
      .comment-form .was-validated input:valid[type="text"] ~ .valid-feedback,
      .was-validated .comment-form input:valid[type="email"] ~ .valid-feedback,
      .comment-form .was-validated input:valid[type="email"] ~ .valid-feedback,
      .was-validated .comment-form input:valid[type="url"] ~ .valid-feedback,
      .comment-form .was-validated input:valid[type="url"] ~ .valid-feedback,
      .was-validated .comment-form textarea:valid ~ .valid-feedback,
      .comment-form .was-validated textarea:valid ~ .valid-feedback,
      .was-validated .search-form .search-field:valid ~ .valid-feedback,
      .search-form .was-validated .search-field:valid ~ .valid-feedback,
      .was-validated .form-control:valid ~ .valid-tooltip,
      .was-validated .comment-form input:valid[type="text"] ~ .valid-tooltip,
      .comment-form .was-validated input:valid[type="text"] ~ .valid-tooltip,
      .was-validated .comment-form input:valid[type="email"] ~ .valid-tooltip,
      .comment-form .was-validated input:valid[type="email"] ~ .valid-tooltip,
      .was-validated .comment-form input:valid[type="url"] ~ .valid-tooltip,
      .comment-form .was-validated input:valid[type="url"] ~ .valid-tooltip,
      .was-validated .comment-form textarea:valid ~ .valid-tooltip,
      .comment-form .was-validated textarea:valid ~ .valid-tooltip,
      .was-validated .search-form .search-field:valid ~ .valid-tooltip,
      .search-form .was-validated .search-field:valid ~ .valid-tooltip,
      .form-control.is-valid ~ .valid-feedback,
      .comment-form input.is-valid[type="text"] ~ .valid-feedback,
      .comment-form input.is-valid[type="email"] ~ .valid-feedback,
      .comment-form input.is-valid[type="url"] ~ .valid-feedback,
      .comment-form textarea.is-valid ~ .valid-feedback,
      .search-form .is-valid.search-field ~ .valid-feedback,
      .form-control.is-valid ~ .valid-tooltip,
      .comment-form input.is-valid[type="text"] ~ .valid-tooltip,
      .comment-form input.is-valid[type="email"] ~ .valid-tooltip,
      .comment-form input.is-valid[type="url"] ~ .valid-tooltip,
      .comment-form textarea.is-valid ~ .valid-tooltip,
      .search-form .is-valid.search-field ~ .valid-tooltip {
        display: block;
      }

      /* line 80, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated textarea.form-control:valid,
      .was-validated .comment-form textarea:valid,
      .comment-form .was-validated textarea:valid,
      .was-validated .search-form textarea.search-field:valid,
      .search-form .was-validated textarea.search-field:valid,
      textarea.form-control.is-valid,
      .comment-form textarea.is-valid,
      .search-form textarea.is-valid.search-field {
        padding-right: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
      }

      /* line 90, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-select:valid,
      .custom-select.is-valid {
        border-color: #28a745;
        padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

      /* line 99, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-select:valid:focus,
      .custom-select.is-valid:focus {
        border-color: #28a745;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
      }

      /* line 104, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-select:valid ~ .valid-feedback,
      .was-validated .custom-select:valid ~ .valid-tooltip,
      .custom-select.is-valid ~ .valid-feedback,
      .custom-select.is-valid ~ .valid-tooltip {
        display: block;
      }

      /* line 115, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control-file:valid ~ .valid-feedback,
      .was-validated .form-control-file:valid ~ .valid-tooltip,
      .form-control-file.is-valid ~ .valid-feedback,
      .form-control-file.is-valid ~ .valid-tooltip {
        display: block;
      }

      /* line 125, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-check-input:valid ~ .form-check-label,
      .form-check-input.is-valid ~ .form-check-label {
        color: #28a745;
      }

      /* line 129, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-check-input:valid ~ .valid-feedback,
      .was-validated .form-check-input:valid ~ .valid-tooltip,
      .form-check-input.is-valid ~ .valid-feedback,
      .form-check-input.is-valid ~ .valid-tooltip {
        display: block;
      }

      /* line 139, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:valid ~ .custom-control-label,
      .custom-control-input.is-valid ~ .custom-control-label {
        color: #28a745;
      }

      /* line 142, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:valid ~ .custom-control-label::before,
      .custom-control-input.is-valid ~ .custom-control-label::before {
        border-color: #28a745;
      }

      /* line 147, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:valid ~ .valid-feedback,
      .was-validated .custom-control-input:valid ~ .valid-tooltip,
      .custom-control-input.is-valid ~ .valid-feedback,
      .custom-control-input.is-valid ~ .valid-tooltip {
        display: block;
      }

      /* line 153, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
      .custom-control-input.is-valid:checked ~ .custom-control-label::before {
        border-color: #34ce57;
        background-color: #34ce57;
      }

      /* line 160, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
      .custom-control-input.is-valid:focus ~ .custom-control-label::before {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
      }

      /* line 164, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
      .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #28a745;
      }

      /* line 175, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-file-input:valid ~ .custom-file-label,
      .custom-file-input.is-valid ~ .custom-file-label {
        border-color: #28a745;
      }

      /* line 179, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-file-input:valid ~ .valid-feedback,
      .was-validated .custom-file-input:valid ~ .valid-tooltip,
      .custom-file-input.is-valid ~ .valid-feedback,
      .custom-file-input.is-valid ~ .valid-tooltip {
        display: block;
      }

      /* line 185, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
      .custom-file-input.is-valid:focus ~ .custom-file-label {
        border-color: #28a745;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
      }

      /* line 30, node_modules/bootstrap/scss/mixins/_forms.scss */

      .invalid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }

      /* line 38, node_modules/bootstrap/scss/mixins/_forms.scss */

      .invalid-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: .1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #fff;
        background-color: rgba(220, 53, 69, 0.9);
        border-radius: 0.25rem;
      }

      /* line 54, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control:invalid,
      .was-validated .comment-form input:invalid[type="text"],
      .comment-form .was-validated input:invalid[type="text"],
      .was-validated .comment-form input:invalid[type="email"],
      .comment-form .was-validated input:invalid[type="email"],
      .was-validated .comment-form input:invalid[type="url"],
      .comment-form .was-validated input:invalid[type="url"],
      .was-validated .comment-form textarea:invalid,
      .comment-form .was-validated textarea:invalid,
      .was-validated .search-form .search-field:invalid,
      .search-form .was-validated .search-field:invalid,
      .form-control.is-invalid,
      .comment-form input.is-invalid[type="text"],
      .comment-form input.is-invalid[type="email"],
      .comment-form input.is-invalid[type="url"],
      .comment-form textarea.is-invalid,
      .search-form .is-invalid.search-field {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
        background-repeat: no-repeat;
        background-position: center right calc(0.375em + 0.1875rem);
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

      /* line 66, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control:invalid:focus,
      .was-validated .comment-form input:invalid:focus[type="text"],
      .comment-form .was-validated input:invalid:focus[type="text"],
      .was-validated .comment-form input:invalid:focus[type="email"],
      .comment-form .was-validated input:invalid:focus[type="email"],
      .was-validated .comment-form input:invalid:focus[type="url"],
      .comment-form .was-validated input:invalid:focus[type="url"],
      .was-validated .comment-form textarea:invalid:focus,
      .comment-form .was-validated textarea:invalid:focus,
      .was-validated .search-form .search-field:invalid:focus,
      .search-form .was-validated .search-field:invalid:focus,
      .form-control.is-invalid:focus,
      .comment-form input.is-invalid:focus[type="text"],
      .comment-form input.is-invalid:focus[type="email"],
      .comment-form input.is-invalid:focus[type="url"],
      .comment-form textarea.is-invalid:focus,
      .search-form .is-invalid.search-field:focus {
        border-color: #dc3545;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control:invalid ~ .invalid-feedback,
      .was-validated .comment-form input:invalid[type="text"] ~ .invalid-feedback,
      .comment-form .was-validated input:invalid[type="text"] ~ .invalid-feedback,
      .was-validated .comment-form input:invalid[type="email"] ~ .invalid-feedback,
      .comment-form .was-validated input:invalid[type="email"] ~ .invalid-feedback,
      .was-validated .comment-form input:invalid[type="url"] ~ .invalid-feedback,
      .comment-form .was-validated input:invalid[type="url"] ~ .invalid-feedback,
      .was-validated .comment-form textarea:invalid ~ .invalid-feedback,
      .comment-form .was-validated textarea:invalid ~ .invalid-feedback,
      .was-validated .search-form .search-field:invalid ~ .invalid-feedback,
      .search-form .was-validated .search-field:invalid ~ .invalid-feedback,
      .was-validated .form-control:invalid ~ .invalid-tooltip,
      .was-validated .comment-form input:invalid[type="text"] ~ .invalid-tooltip,
      .comment-form .was-validated input:invalid[type="text"] ~ .invalid-tooltip,
      .was-validated .comment-form input:invalid[type="email"] ~ .invalid-tooltip,
      .comment-form .was-validated input:invalid[type="email"] ~ .invalid-tooltip,
      .was-validated .comment-form input:invalid[type="url"] ~ .invalid-tooltip,
      .comment-form .was-validated input:invalid[type="url"] ~ .invalid-tooltip,
      .was-validated .comment-form textarea:invalid ~ .invalid-tooltip,
      .comment-form .was-validated textarea:invalid ~ .invalid-tooltip,
      .was-validated .search-form .search-field:invalid ~ .invalid-tooltip,
      .search-form .was-validated .search-field:invalid ~ .invalid-tooltip,
      .form-control.is-invalid ~ .invalid-feedback,
      .comment-form input.is-invalid[type="text"] ~ .invalid-feedback,
      .comment-form input.is-invalid[type="email"] ~ .invalid-feedback,
      .comment-form input.is-invalid[type="url"] ~ .invalid-feedback,
      .comment-form textarea.is-invalid ~ .invalid-feedback,
      .search-form .is-invalid.search-field ~ .invalid-feedback,
      .form-control.is-invalid ~ .invalid-tooltip,
      .comment-form input.is-invalid[type="text"] ~ .invalid-tooltip,
      .comment-form input.is-invalid[type="email"] ~ .invalid-tooltip,
      .comment-form input.is-invalid[type="url"] ~ .invalid-tooltip,
      .comment-form textarea.is-invalid ~ .invalid-tooltip,
      .search-form .is-invalid.search-field ~ .invalid-tooltip {
        display: block;
      }

      /* line 80, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated textarea.form-control:invalid,
      .was-validated .comment-form textarea:invalid,
      .comment-form .was-validated textarea:invalid,
      .was-validated .search-form textarea.search-field:invalid,
      .search-form .was-validated textarea.search-field:invalid,
      textarea.form-control.is-invalid,
      .comment-form textarea.is-invalid,
      .search-form textarea.is-invalid.search-field {
        padding-right: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
      }

      /* line 90, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-select:invalid,
      .custom-select.is-invalid {
        border-color: #dc3545;
        padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

      /* line 99, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-select:invalid:focus,
      .custom-select.is-invalid:focus {
        border-color: #dc3545;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }

      /* line 104, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-select:invalid ~ .invalid-feedback,
      .was-validated .custom-select:invalid ~ .invalid-tooltip,
      .custom-select.is-invalid ~ .invalid-feedback,
      .custom-select.is-invalid ~ .invalid-tooltip {
        display: block;
      }

      /* line 115, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-control-file:invalid ~ .invalid-feedback,
      .was-validated .form-control-file:invalid ~ .invalid-tooltip,
      .form-control-file.is-invalid ~ .invalid-feedback,
      .form-control-file.is-invalid ~ .invalid-tooltip {
        display: block;
      }

      /* line 125, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-check-input:invalid ~ .form-check-label,
      .form-check-input.is-invalid ~ .form-check-label {
        color: #dc3545;
      }

      /* line 129, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .form-check-input:invalid ~ .invalid-feedback,
      .was-validated .form-check-input:invalid ~ .invalid-tooltip,
      .form-check-input.is-invalid ~ .invalid-feedback,
      .form-check-input.is-invalid ~ .invalid-tooltip {
        display: block;
      }

      /* line 139, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:invalid ~ .custom-control-label,
      .custom-control-input.is-invalid ~ .custom-control-label {
        color: #dc3545;
      }

      /* line 142, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
      .custom-control-input.is-invalid ~ .custom-control-label::before {
        border-color: #dc3545;
      }

      /* line 147, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:invalid ~ .invalid-feedback,
      .was-validated .custom-control-input:invalid ~ .invalid-tooltip,
      .custom-control-input.is-invalid ~ .invalid-feedback,
      .custom-control-input.is-invalid ~ .invalid-tooltip {
        display: block;
      }

      /* line 153, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
      .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
        border-color: #e4606d;
        background-color: #e4606d;
      }

      /* line 160, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
      .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }

      /* line 164, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
      .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #dc3545;
      }

      /* line 175, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-file-input:invalid ~ .custom-file-label,
      .custom-file-input.is-invalid ~ .custom-file-label {
        border-color: #dc3545;
      }

      /* line 179, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-file-input:invalid ~ .invalid-feedback,
      .was-validated .custom-file-input:invalid ~ .invalid-tooltip,
      .custom-file-input.is-invalid ~ .invalid-feedback,
      .custom-file-input.is-invalid ~ .invalid-tooltip {
        display: block;
      }

      /* line 185, node_modules/bootstrap/scss/mixins/_forms.scss */

      .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
      .custom-file-input.is-invalid:focus ~ .custom-file-label {
        border-color: #dc3545;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }

      /* line 258, node_modules/bootstrap/scss/_forms.scss */

      .form-inline,
      .search-form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      /* line 266, node_modules/bootstrap/scss/_forms.scss */

      .form-inline .form-check,
      .search-form .form-check {
        width: 100%;
      }

      @media (min-width: 576px) {
        /* line 272, node_modules/bootstrap/scss/_forms.scss */

        .form-inline label,
        .search-form label {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-bottom: 0;
        }

        /* line 280, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .form-group,
        .search-form .form-group,
        .form-inline .comment-form p,
        .comment-form .form-inline p,
        .search-form .comment-form p,
        .comment-form .search-form p,
        .form-inline .search-form label,
        .search-form .form-inline label,
        .search-form label {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 0;
        }

        /* line 289, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .form-control,
        .search-form .form-control,
        .form-inline .comment-form input[type="text"],
        .comment-form .form-inline input[type="text"],
        .search-form .comment-form input[type="text"],
        .comment-form .search-form input[type="text"],
        .form-inline .comment-form input[type="email"],
        .comment-form .form-inline input[type="email"],
        .search-form .comment-form input[type="email"],
        .comment-form .search-form input[type="email"],
        .form-inline .comment-form input[type="url"],
        .comment-form .form-inline input[type="url"],
        .search-form .comment-form input[type="url"],
        .comment-form .search-form input[type="url"],
        .form-inline .comment-form textarea,
        .comment-form .form-inline textarea,
        .search-form .comment-form textarea,
        .comment-form .search-form textarea,
        .search-form .search-field {
          display: inline-block;
          width: auto;
          vertical-align: middle;
        }

        /* line 296, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .form-control-plaintext,
        .search-form .form-control-plaintext {
          display: inline-block;
        }

        /* line 300, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .input-group,
        .search-form .input-group,
        .form-inline .custom-select,
        .search-form .custom-select {
          width: auto;
        }

        /* line 307, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .form-check,
        .search-form .form-check {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: auto;
          padding-left: 0;
        }

        /* line 314, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .form-check-input,
        .search-form .form-check-input {
          position: relative;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          margin-top: 0;
          margin-right: 0.25rem;
          margin-left: 0;
        }

        /* line 322, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .custom-control,
        .search-form .custom-control {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }

        /* line 326, node_modules/bootstrap/scss/_forms.scss */

        .form-inline .custom-control-label,
        .search-form .custom-control-label {
          margin-bottom: 0;
        }
      }

      /* line 7, node_modules/bootstrap/scss/_buttons.scss */

      .btn,
      .comment-form input[type="submit"],
      .search-form .search-submit {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 7, node_modules/bootstrap/scss/_buttons.scss */

        .btn,
        .comment-form input[type="submit"],
        .search-form .search-submit {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn:hover,
      .comment-form input:hover[type="submit"],
      .search-form .search-submit:hover {
        color: #212529;
        text-decoration: none;
      }

      /* line 25, node_modules/bootstrap/scss/_buttons.scss */

      .btn:focus,
      .comment-form input:focus[type="submit"],
      .search-form .search-submit:focus,
      .btn.focus,
      .comment-form input.focus[type="submit"],
      .search-form .focus.search-submit {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 32, node_modules/bootstrap/scss/_buttons.scss */

      .btn.disabled,
      .comment-form input.disabled[type="submit"],
      .search-form .disabled.search-submit,
      .btn:disabled,
      .comment-form input:disabled[type="submit"],
      .search-form .search-submit:disabled {
        opacity: 0.65;
      }

      /* line 49, node_modules/bootstrap/scss/_buttons.scss */

      a.btn.disabled,
      .search-form a.disabled.search-submit,
      fieldset:disabled a.btn,
      fieldset:disabled .search-form a.search-submit,
      .search-form fieldset:disabled a.search-submit {
        pointer-events: none;
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-primary {
        color: #fff;
        background-color: #525ddc;
        border-color: #525ddc;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-primary:hover {
        color: #fff;
        background-color: #323fd6;
        border-color: #2a38d1;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-primary:focus,
      .btn-primary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 225, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 225, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-primary.disabled,
      .btn-primary:disabled {
        color: #fff;
        background-color: #525ddc;
        border-color: #525ddc;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-primary:not(:disabled):not(.disabled):active,
      .btn-primary:not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #2a38d1;
        border-color: #2835c6;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-primary:not(:disabled):not(.disabled):active:focus,
      .btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-primary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 225, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 225, 0.5);
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-secondary,
      .comment-form input[type="submit"],
      .search-form .search-submit {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-secondary:hover,
      .comment-form input:hover[type="submit"],
      .search-form .search-submit:hover {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-secondary:focus,
      .comment-form input:focus[type="submit"],
      .search-form .search-submit:focus,
      .btn-secondary.focus,
      .comment-form input.focus[type="submit"],
      .search-form .focus.search-submit {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-secondary.disabled,
      .comment-form input.disabled[type="submit"],
      .search-form .disabled.search-submit,
      .btn-secondary:disabled,
      .comment-form input:disabled[type="submit"],
      .search-form .search-submit:disabled {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-secondary:not(:disabled):not(.disabled):active,
      .comment-form input:not(:disabled):not(.disabled):active[type="submit"],
      .search-form .search-submit:not(:disabled):not(.disabled):active,
      .btn-secondary:not(:disabled):not(.disabled).active,
      .comment-form input:not(:disabled):not(.disabled).active[type="submit"],
      .search-form .search-submit:not(:disabled):not(.disabled).active,
      .show > .btn-secondary.dropdown-toggle,
      .comment-form .show > input.dropdown-toggle[type="submit"],
      .search-form .show > .dropdown-toggle.search-submit {
        color: #fff;
        background-color: #545b62;
        border-color: #4e555b;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-secondary:not(:disabled):not(.disabled):active:focus,
      .comment-form input:not(:disabled):not(.disabled):active:focus[type="submit"],
      .search-form .search-submit:not(:disabled):not(.disabled):active:focus,
      .btn-secondary:not(:disabled):not(.disabled).active:focus,
      .comment-form input:not(:disabled):not(.disabled).active:focus[type="submit"],
      .search-form .search-submit:not(:disabled):not(.disabled).active:focus,
      .show > .btn-secondary.dropdown-toggle:focus,
      .comment-form .show > input.dropdown-toggle:focus[type="submit"],
      .search-form .show > .dropdown-toggle.search-submit:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-success {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-success:hover {
        color: #fff;
        background-color: #218838;
        border-color: #1e7e34;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-success:focus,
      .btn-success.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-success.disabled,
      .btn-success:disabled {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-success:not(:disabled):not(.disabled):active,
      .btn-success:not(:disabled):not(.disabled).active,
      .show > .btn-success.dropdown-toggle {
        color: #fff;
        background-color: #1e7e34;
        border-color: #1c7430;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-success:not(:disabled):not(.disabled):active:focus,
      .btn-success:not(:disabled):not(.disabled).active:focus,
      .show > .btn-success.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-info {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-info:hover {
        color: #fff;
        background-color: #138496;
        border-color: #117a8b;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-info:focus,
      .btn-info.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-info.disabled,
      .btn-info:disabled {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-info:not(:disabled):not(.disabled):active,
      .btn-info:not(:disabled):not(.disabled).active,
      .show > .btn-info.dropdown-toggle {
        color: #fff;
        background-color: #117a8b;
        border-color: #10707f;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-info:not(:disabled):not(.disabled):active:focus,
      .btn-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn-info.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-warning {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-warning:hover {
        color: #212529;
        background-color: #e0a800;
        border-color: #d39e00;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-warning:focus,
      .btn-warning.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-warning.disabled,
      .btn-warning:disabled {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-warning:not(:disabled):not(.disabled):active,
      .btn-warning:not(:disabled):not(.disabled).active,
      .show > .btn-warning.dropdown-toggle {
        color: #212529;
        background-color: #d39e00;
        border-color: #c69500;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-warning:not(:disabled):not(.disabled):active:focus,
      .btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > .btn-warning.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-danger {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-danger:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-danger:focus,
      .btn-danger.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-danger.disabled,
      .btn-danger:disabled {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-danger:not(:disabled):not(.disabled):active,
      .btn-danger:not(:disabled):not(.disabled).active,
      .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-danger:not(:disabled):not(.disabled):active:focus,
      .btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > .btn-danger.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-light {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-light:hover {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-light:focus,
      .btn-light.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-light.disabled,
      .btn-light:disabled {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-light:not(:disabled):not(.disabled):active,
      .btn-light:not(:disabled):not(.disabled).active,
      .show > .btn-light.dropdown-toggle {
        color: #212529;
        background-color: #dae0e5;
        border-color: #d3d9df;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-light:not(:disabled):not(.disabled):active:focus,
      .btn-light:not(:disabled):not(.disabled).active:focus,
      .show > .btn-light.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
      }

      /* line 60, node_modules/bootstrap/scss/_buttons.scss */

      .btn-dark {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-dark:hover {
        color: #fff;
        background-color: #23272b;
        border-color: #1d2124;
      }

      /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-dark:focus,
      .btn-dark.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
      }

      /* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-dark.disabled,
      .btn-dark:disabled {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
      }

      /* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-dark:not(:disabled):not(.disabled):active,
      .btn-dark:not(:disabled):not(.disabled).active,
      .show > .btn-dark.dropdown-toggle {
        color: #fff;
        background-color: #1d2124;
        border-color: #171a1d;
      }

      /* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-dark:not(:disabled):not(.disabled):active:focus,
      .btn-dark:not(:disabled):not(.disabled).active:focus,
      .show > .btn-dark.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-primary {
        color: #525ddc;
        border-color: #525ddc;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-primary:hover {
        color: #fff;
        background-color: #525ddc;
        border-color: #525ddc;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-primary:focus,
      .btn-outline-primary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-primary.disabled,
      .btn-outline-primary:disabled {
        color: #525ddc;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-primary:not(:disabled):not(.disabled):active,
      .btn-outline-primary:not(:disabled):not(.disabled).active,
      .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #525ddc;
        border-color: #525ddc;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
      .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-primary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-secondary {
        color: #6c757d;
        border-color: #6c757d;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-secondary:hover {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-secondary:focus,
      .btn-outline-secondary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-secondary.disabled,
      .btn-outline-secondary:disabled {
        color: #6c757d;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-secondary:not(:disabled):not(.disabled):active,
      .btn-outline-secondary:not(:disabled):not(.disabled).active,
      .show > .btn-outline-secondary.dropdown-toggle {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
      .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-secondary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-success {
        color: #28a745;
        border-color: #28a745;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-success:hover {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-success:focus,
      .btn-outline-success.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-success.disabled,
      .btn-outline-success:disabled {
        color: #28a745;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-success:not(:disabled):not(.disabled):active,
      .btn-outline-success:not(:disabled):not(.disabled).active,
      .show > .btn-outline-success.dropdown-toggle {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-success:not(:disabled):not(.disabled):active:focus,
      .btn-outline-success:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-success.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-info {
        color: #17a2b8;
        border-color: #17a2b8;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-info:hover {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-info:focus,
      .btn-outline-info.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-info.disabled,
      .btn-outline-info:disabled {
        color: #17a2b8;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-info:not(:disabled):not(.disabled):active,
      .btn-outline-info:not(:disabled):not(.disabled).active,
      .show > .btn-outline-info.dropdown-toggle {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-info:not(:disabled):not(.disabled):active:focus,
      .btn-outline-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-info.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-warning {
        color: #ffc107;
        border-color: #ffc107;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-warning:hover {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-warning:focus,
      .btn-outline-warning.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-warning.disabled,
      .btn-outline-warning:disabled {
        color: #ffc107;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-warning:not(:disabled):not(.disabled):active,
      .btn-outline-warning:not(:disabled):not(.disabled).active,
      .show > .btn-outline-warning.dropdown-toggle {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
      .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-warning.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-danger {
        color: #dc3545;
        border-color: #dc3545;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-danger:hover {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-danger:focus,
      .btn-outline-danger.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-danger.disabled,
      .btn-outline-danger:disabled {
        color: #dc3545;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-danger:not(:disabled):not(.disabled):active,
      .btn-outline-danger:not(:disabled):not(.disabled).active,
      .show > .btn-outline-danger.dropdown-toggle {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
      .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-danger.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-light {
        color: #f8f9fa;
        border-color: #f8f9fa;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-light:hover {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-light:focus,
      .btn-outline-light.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-light.disabled,
      .btn-outline-light:disabled {
        color: #f8f9fa;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-light:not(:disabled):not(.disabled):active,
      .btn-outline-light:not(:disabled):not(.disabled).active,
      .show > .btn-outline-light.dropdown-toggle {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-light:not(:disabled):not(.disabled):active:focus,
      .btn-outline-light:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-light.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      }

      /* line 66, node_modules/bootstrap/scss/_buttons.scss */

      .btn-outline-dark {
        color: #343a40;
        border-color: #343a40;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-outline-dark:hover {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
      }

      /* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-dark:focus,
      .btn-outline-dark.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      }

      /* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-dark.disabled,
      .btn-outline-dark:disabled {
        color: #343a40;
        background-color: transparent;
      }

      /* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-dark:not(:disabled):not(.disabled):active,
      .btn-outline-dark:not(:disabled):not(.disabled).active,
      .show > .btn-outline-dark.dropdown-toggle {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;
      }

      /* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

      .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
      .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-dark.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      }

      /* line 77, node_modules/bootstrap/scss/_buttons.scss */

      .btn-link {
        font-weight: 400;
        color: #525ddc;
        text-decoration: none;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-link:hover {
        color: #2632bc;
        text-decoration: underline;
      }

      /* line 87, node_modules/bootstrap/scss/_buttons.scss */

      .btn-link:focus,
      .btn-link.focus {
        text-decoration: underline;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      /* line 93, node_modules/bootstrap/scss/_buttons.scss */

      .btn-link:disabled,
      .btn-link.disabled {
        color: #6c757d;
        pointer-events: none;
      }

      /* line 107, node_modules/bootstrap/scss/_buttons.scss */

      .btn-lg,
      .btn-group-lg > .btn,
      .comment-form .btn-group-lg > input[type="submit"],
      .search-form .btn-group-lg > .search-submit {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
      }

      /* line 111, node_modules/bootstrap/scss/_buttons.scss */

      .btn-sm,
      .btn-group-sm > .btn,
      .comment-form .btn-group-sm > input[type="submit"],
      .search-form .btn-group-sm > .search-submit {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
      }

      /* line 120, node_modules/bootstrap/scss/_buttons.scss */

      .btn-block {
        display: block;
        width: 100%;
      }

      /* line 125, node_modules/bootstrap/scss/_buttons.scss */

      .btn-block + .btn-block {
        margin-top: 0.5rem;
      }

      /* line 134, node_modules/bootstrap/scss/_buttons.scss */

      input[type="submit"].btn-block,
      input[type="reset"].btn-block,
      input[type="button"].btn-block {
        width: 100%;
      }

      /* line 1, node_modules/bootstrap/scss/_transitions.scss */

      .fade {
        -webkit-transition: opacity 0.15s linear;
        -o-transition: opacity 0.15s linear;
        transition: opacity 0.15s linear;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 1, node_modules/bootstrap/scss/_transitions.scss */

        .fade {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 4, node_modules/bootstrap/scss/_transitions.scss */

      .fade:not(.show) {
        opacity: 0;
      }

      /* line 10, node_modules/bootstrap/scss/_transitions.scss */

      .collapse:not(.show) {
        display: none;
      }

      /* line 15, node_modules/bootstrap/scss/_transitions.scss */

      .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        -webkit-transition: height 0.35s ease;
        -o-transition: height 0.35s ease;
        transition: height 0.35s ease;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 15, node_modules/bootstrap/scss/_transitions.scss */

        .collapsing {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 2, node_modules/bootstrap/scss/_dropdown.scss */

      .dropup,
      .dropright,
      .dropdown,
      .dropleft {
        position: relative;
      }

      /* line 9, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-toggle {
        white-space: nowrap;
      }

      /* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }

      /* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropdown-toggle:empty::after {
        margin-left: 0;
      }

      /* line 17, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
      }

      /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-menu-left {
        right: auto;
        left: 0;
      }

      /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-menu-right {
        right: 0;
        left: auto;
      }

      @media (min-width: 576px) {
        /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-sm-left {
          right: auto;
          left: 0;
        }

        /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-sm-right {
          right: 0;
          left: auto;
        }
      }

      @media (min-width: 768px) {
        /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-md-left {
          right: auto;
          left: 0;
        }

        /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-md-right {
          right: 0;
          left: auto;
        }
      }

      @media (min-width: 992px) {
        /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-lg-left {
          right: auto;
          left: 0;
        }

        /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-lg-right {
          right: 0;
          left: auto;
        }
      }

      @media (min-width: 1200px) {
        /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-xl-left {
          right: auto;
          left: 0;
        }

        /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

        .dropdown-menu-xl-right {
          right: 0;
          left: auto;
        }
      }

      /* line 57, node_modules/bootstrap/scss/_dropdown.scss */

      .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: 0.125rem;
      }

      /* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropup .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
      }

      /* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropup .dropdown-toggle:empty::after {
        margin-left: 0;
      }

      /* line 70, node_modules/bootstrap/scss/_dropdown.scss */

      .dropright .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: 0.125rem;
      }

      /* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropright .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid transparent;
        border-right: 0;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
      }

      /* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropright .dropdown-toggle:empty::after {
        margin-left: 0;
      }

      /* line 80, node_modules/bootstrap/scss/_dropdown.scss */

      .dropright .dropdown-toggle::after {
        vertical-align: 0;
      }

      /* line 87, node_modules/bootstrap/scss/_dropdown.scss */

      .dropleft .dropdown-menu {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: 0.125rem;
      }

      /* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropleft .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
      }

      /* line 45, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropleft .dropdown-toggle::after {
        display: none;
      }

      /* line 49, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropleft .dropdown-toggle::before {
        display: inline-block;
        margin-right: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid transparent;
        border-right: 0.3em solid;
        border-bottom: 0.3em solid transparent;
      }

      /* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

      .dropleft .dropdown-toggle:empty::after {
        margin-left: 0;
      }

      /* line 97, node_modules/bootstrap/scss/_dropdown.scss */

      .dropleft .dropdown-toggle::before {
        vertical-align: 0;
      }

      /* line 106, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-menu[x-placement^="top"],
      .dropdown-menu[x-placement^="right"],
      .dropdown-menu[x-placement^="bottom"],
      .dropdown-menu[x-placement^="left"] {
        right: auto;
        bottom: auto;
      }

      /* line 116, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid #e9ecef;
      }

      /* line 123, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .dropdown-item:hover,
      .dropdown-item:focus {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
      }

      /* line 153, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-item.active,
      .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: #525ddc;
      }

      /* line 160, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-item.disabled,
      .dropdown-item:disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: transparent;
      }

      /* line 172, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-menu.show {
        display: block;
      }

      /* line 177, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-header {
        display: block;
        padding: 0.5rem 1.5rem;
        margin-bottom: 0;
        font-size: 0.875rem;
        color: #6c757d;
        white-space: nowrap;
      }

      /* line 187, node_modules/bootstrap/scss/_dropdown.scss */

      .dropdown-item-text {
        display: block;
        padding: 0.25rem 1.5rem;
        color: #212529;
      }

      /* line 4, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group,
      .btn-group-vertical {
        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        vertical-align: middle;
      }

      /* line 10, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group > .btn,
      .comment-form .btn-group > input[type="submit"],
      .search-form .btn-group > .search-submit,
      .btn-group-vertical > .btn,
      .comment-form .btn-group-vertical > input[type="submit"],
      .search-form .btn-group-vertical > .search-submit {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .btn-group > .btn:hover,
      .comment-form .btn-group > input:hover[type="submit"],
      .search-form .btn-group > .search-submit:hover,
      .btn-group-vertical > .btn:hover,
      .comment-form .btn-group-vertical > input:hover[type="submit"],
      .search-form .btn-group-vertical > .search-submit:hover {
        z-index: 1;
      }

      /* line 19, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group > .btn:focus,
      .comment-form .btn-group > input:focus[type="submit"],
      .search-form .btn-group > .search-submit:focus,
      .btn-group > .btn:active,
      .comment-form .btn-group > input:active[type="submit"],
      .search-form .btn-group > .search-submit:active,
      .btn-group > .btn.active,
      .comment-form .btn-group > input.active[type="submit"],
      .search-form .btn-group > .active.search-submit,
      .btn-group-vertical > .btn:focus,
      .comment-form .btn-group-vertical > input:focus[type="submit"],
      .search-form .btn-group-vertical > .search-submit:focus,
      .btn-group-vertical > .btn:active,
      .comment-form .btn-group-vertical > input:active[type="submit"],
      .search-form .btn-group-vertical > .search-submit:active,
      .btn-group-vertical > .btn.active,
      .comment-form .btn-group-vertical > input.active[type="submit"],
      .search-form .btn-group-vertical > .active.search-submit {
        z-index: 1;
      }

      /* line 28, node_modules/bootstrap/scss/_button-group.scss */

      .btn-toolbar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
      }

      /* line 33, node_modules/bootstrap/scss/_button-group.scss */

      .btn-toolbar .input-group {
        width: auto;
      }

      /* line 40, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group > .btn:not(:first-child),
      .comment-form .btn-group > input:not(:first-child)[type="submit"],
      .search-form .btn-group > .search-submit:not(:first-child),
      .btn-group > .btn-group:not(:first-child) {
        margin-left: -1px;
      }

      /* line 46, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
      .comment-form .btn-group > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
      .search-form .btn-group > .search-submit:not(:last-child):not(.dropdown-toggle),
      .btn-group > .btn-group:not(:last-child) > .btn,
      .comment-form .btn-group > .btn-group:not(:last-child) > input[type="submit"],
      .search-form .btn-group > .btn-group:not(:last-child) > .search-submit {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      /* line 51, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group > .btn:not(:first-child),
      .comment-form .btn-group > input:not(:first-child)[type="submit"],
      .search-form .btn-group > .search-submit:not(:first-child),
      .btn-group > .btn-group:not(:first-child) > .btn,
      .comment-form .btn-group > .btn-group:not(:first-child) > input[type="submit"],
      .search-form .btn-group > .btn-group:not(:first-child) > .search-submit {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      /* line 69, node_modules/bootstrap/scss/_button-group.scss */

      .dropdown-toggle-split {
        padding-right: 0.5625rem;
        padding-left: 0.5625rem;
      }

      /* line 73, node_modules/bootstrap/scss/_button-group.scss */

      .dropdown-toggle-split::after,
      .dropup .dropdown-toggle-split::after,
      .dropright .dropdown-toggle-split::after {
        margin-left: 0;
      }

      /* line 79, node_modules/bootstrap/scss/_button-group.scss */

      .dropleft .dropdown-toggle-split::before {
        margin-right: 0;
      }

      /* line 84, node_modules/bootstrap/scss/_button-group.scss */

      .btn-sm + .dropdown-toggle-split,
      .btn-group-sm > .btn + .dropdown-toggle-split,
      .comment-form .btn-group-sm > input[type="submit"] + .dropdown-toggle-split,
      .search-form .btn-group-sm > .search-submit + .dropdown-toggle-split {
        padding-right: 0.375rem;
        padding-left: 0.375rem;
      }

      /* line 89, node_modules/bootstrap/scss/_button-group.scss */

      .btn-lg + .dropdown-toggle-split,
      .btn-group-lg > .btn + .dropdown-toggle-split,
      .comment-form .btn-group-lg > input[type="submit"] + .dropdown-toggle-split,
      .search-form .btn-group-lg > .search-submit + .dropdown-toggle-split {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }

      /* line 111, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group-vertical {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }

      /* line 116, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group-vertical > .btn,
      .comment-form .btn-group-vertical > input[type="submit"],
      .search-form .btn-group-vertical > .search-submit,
      .btn-group-vertical > .btn-group {
        width: 100%;
      }

      /* line 121, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group-vertical > .btn:not(:first-child),
      .comment-form .btn-group-vertical > input:not(:first-child)[type="submit"],
      .search-form .btn-group-vertical > .search-submit:not(:first-child),
      .btn-group-vertical > .btn-group:not(:first-child) {
        margin-top: -1px;
      }

      /* line 127, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
      .comment-form .btn-group-vertical > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
      .search-form .btn-group-vertical > .search-submit:not(:last-child):not(.dropdown-toggle),
      .btn-group-vertical > .btn-group:not(:last-child) > .btn,
      .comment-form .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"],
      .search-form .btn-group-vertical > .btn-group:not(:last-child) > .search-submit {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      /* line 132, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group-vertical > .btn:not(:first-child),
      .comment-form .btn-group-vertical > input:not(:first-child)[type="submit"],
      .search-form .btn-group-vertical > .search-submit:not(:first-child),
      .btn-group-vertical > .btn-group:not(:first-child) > .btn,
      .comment-form .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"],
      .search-form .btn-group-vertical > .btn-group:not(:first-child) > .search-submit {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      /* line 152, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group-toggle > .btn,
      .comment-form .btn-group-toggle > input[type="submit"],
      .search-form .btn-group-toggle > .search-submit,
      .btn-group-toggle > .btn-group > .btn,
      .comment-form .btn-group-toggle > .btn-group > input[type="submit"],
      .search-form .btn-group-toggle > .btn-group > .search-submit {
        margin-bottom: 0;
      }

      /* line 156, node_modules/bootstrap/scss/_button-group.scss */

      .btn-group-toggle > .btn input[type="radio"],
      .comment-form .btn-group-toggle > input[type="submit"] input[type="radio"],
      .search-form .btn-group-toggle > .search-submit input[type="radio"],
      .btn-group-toggle > .btn input[type="checkbox"],
      .comment-form .btn-group-toggle > input[type="submit"] input[type="checkbox"],
      .search-form .btn-group-toggle > .search-submit input[type="checkbox"],
      .btn-group-toggle > .btn-group > .btn input[type="radio"],
      .comment-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"],
      .search-form .btn-group-toggle > .btn-group > .search-submit input[type="radio"],
      .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
      .comment-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"],
      .search-form .btn-group-toggle > .btn-group > .search-submit input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }

      /* line 7, node_modules/bootstrap/scss/_input-group.scss */

      .input-group {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
      }

      /* line 14, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .form-control,
      .comment-form .input-group > input[type="text"],
      .comment-form .input-group > input[type="email"],
      .comment-form .input-group > input[type="url"],
      .comment-form .input-group > textarea,
      .search-form .input-group > .search-field,
      .input-group > .form-control-plaintext,
      .input-group > .custom-select,
      .input-group > .custom-file {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
      }

      /* line 25, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .form-control + .form-control,
      .comment-form .input-group > input[type="text"] + .form-control,
      .comment-form .input-group > input[type="email"] + .form-control,
      .comment-form .input-group > input[type="url"] + .form-control,
      .comment-form .input-group > textarea + .form-control,
      .search-form .input-group > .search-field + .form-control,
      .comment-form .input-group > .form-control + input[type="text"],
      .comment-form .input-group > input[type="text"] + input[type="text"],
      .comment-form .input-group > input[type="email"] + input[type="text"],
      .comment-form .input-group > input[type="url"] + input[type="text"],
      .comment-form .input-group > textarea + input[type="text"],
      .search-form .comment-form .input-group > .search-field + input[type="text"],
      .comment-form .search-form .input-group > .search-field + input[type="text"],
      .comment-form .input-group > .form-control + input[type="email"],
      .comment-form .input-group > input[type="text"] + input[type="email"],
      .comment-form .input-group > input[type="email"] + input[type="email"],
      .comment-form .input-group > input[type="url"] + input[type="email"],
      .comment-form .input-group > textarea + input[type="email"],
      .search-form .comment-form .input-group > .search-field + input[type="email"],
      .comment-form .search-form .input-group > .search-field + input[type="email"],
      .comment-form .input-group > .form-control + input[type="url"],
      .comment-form .input-group > input[type="text"] + input[type="url"],
      .comment-form .input-group > input[type="email"] + input[type="url"],
      .comment-form .input-group > input[type="url"] + input[type="url"],
      .comment-form .input-group > textarea + input[type="url"],
      .search-form .comment-form .input-group > .search-field + input[type="url"],
      .comment-form .search-form .input-group > .search-field + input[type="url"],
      .comment-form .input-group > .form-control + textarea,
      .comment-form .input-group > input[type="text"] + textarea,
      .comment-form .input-group > input[type="email"] + textarea,
      .comment-form .input-group > input[type="url"] + textarea,
      .comment-form .input-group > textarea + textarea,
      .search-form .comment-form .input-group > .search-field + textarea,
      .comment-form .search-form .input-group > .search-field + textarea,
      .search-form .input-group > .form-control + .search-field,
      .comment-form .search-form .input-group > input[type="text"] + .search-field,
      .search-form .comment-form .input-group > input[type="text"] + .search-field,
      .comment-form .search-form .input-group > input[type="email"] + .search-field,
      .search-form .comment-form .input-group > input[type="email"] + .search-field,
      .comment-form .search-form .input-group > input[type="url"] + .search-field,
      .search-form .comment-form .input-group > input[type="url"] + .search-field,
      .comment-form .search-form .input-group > textarea + .search-field,
      .search-form .comment-form .input-group > textarea + .search-field,
      .search-form .input-group > .search-field + .search-field,
      .input-group > .form-control + .custom-select,
      .comment-form .input-group > input[type="text"] + .custom-select,
      .comment-form .input-group > input[type="email"] + .custom-select,
      .comment-form .input-group > input[type="url"] + .custom-select,
      .comment-form .input-group > textarea + .custom-select,
      .search-form .input-group > .search-field + .custom-select,
      .input-group > .form-control + .custom-file,
      .comment-form .input-group > input[type="text"] + .custom-file,
      .comment-form .input-group > input[type="email"] + .custom-file,
      .comment-form .input-group > input[type="url"] + .custom-file,
      .comment-form .input-group > textarea + .custom-file,
      .search-form .input-group > .search-field + .custom-file,
      .input-group > .form-control-plaintext + .form-control,
      .comment-form .input-group > .form-control-plaintext + input[type="text"],
      .comment-form .input-group > .form-control-plaintext + input[type="email"],
      .comment-form .input-group > .form-control-plaintext + input[type="url"],
      .comment-form .input-group > .form-control-plaintext + textarea,
      .search-form .input-group > .form-control-plaintext + .search-field,
      .input-group > .form-control-plaintext + .custom-select,
      .input-group > .form-control-plaintext + .custom-file,
      .input-group > .custom-select + .form-control,
      .comment-form .input-group > .custom-select + input[type="text"],
      .comment-form .input-group > .custom-select + input[type="email"],
      .comment-form .input-group > .custom-select + input[type="url"],
      .comment-form .input-group > .custom-select + textarea,
      .search-form .input-group > .custom-select + .search-field,
      .input-group > .custom-select + .custom-select,
      .input-group > .custom-select + .custom-file,
      .input-group > .custom-file + .form-control,
      .comment-form .input-group > .custom-file + input[type="text"],
      .comment-form .input-group > .custom-file + input[type="email"],
      .comment-form .input-group > .custom-file + input[type="url"],
      .comment-form .input-group > .custom-file + textarea,
      .search-form .input-group > .custom-file + .search-field,
      .input-group > .custom-file + .custom-select,
      .input-group > .custom-file + .custom-file {
        margin-left: -1px;
      }

      /* line 33, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .form-control:focus,
      .comment-form .input-group > input:focus[type="text"],
      .comment-form .input-group > input:focus[type="email"],
      .comment-form .input-group > input:focus[type="url"],
      .comment-form .input-group > textarea:focus,
      .search-form .input-group > .search-field:focus,
      .input-group > .custom-select:focus,
      .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
        z-index: 3;
      }

      /* line 40, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .custom-file .custom-file-input:focus {
        z-index: 4;
      }

      /* line 46, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .form-control:not(:last-child),
      .comment-form .input-group > input:not(:last-child)[type="text"],
      .comment-form .input-group > input:not(:last-child)[type="email"],
      .comment-form .input-group > input:not(:last-child)[type="url"],
      .comment-form .input-group > textarea:not(:last-child),
      .search-form .input-group > .search-field:not(:last-child),
      .input-group > .custom-select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      /* line 47, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .form-control:not(:first-child),
      .comment-form .input-group > input:not(:first-child)[type="text"],
      .comment-form .input-group > input:not(:first-child)[type="email"],
      .comment-form .input-group > input:not(:first-child)[type="url"],
      .comment-form .input-group > textarea:not(:first-child),
      .search-form .input-group > .search-field:not(:first-child),
      .input-group > .custom-select:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      /* line 52, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .custom-file {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      /* line 56, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .custom-file:not(:last-child) .custom-file-label,
      .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      /* line 58, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      /* line 69, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-prepend,
      .input-group-append {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }

      /* line 76, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-prepend .btn,
      .input-group-prepend .comment-form input[type="submit"],
      .comment-form .input-group-prepend input[type="submit"],
      .input-group-prepend .search-form .search-submit,
      .search-form .input-group-prepend .search-submit,
      .input-group-append .btn,
      .input-group-append .comment-form input[type="submit"],
      .comment-form .input-group-append input[type="submit"],
      .input-group-append .search-form .search-submit,
      .search-form .input-group-append .search-submit {
        position: relative;
        z-index: 2;
      }

      /* line 80, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-prepend .btn:focus,
      .input-group-prepend .comment-form input:focus[type="submit"],
      .comment-form .input-group-prepend input:focus[type="submit"],
      .input-group-prepend .search-form .search-submit:focus,
      .search-form .input-group-prepend .search-submit:focus,
      .input-group-append .btn:focus,
      .input-group-append .comment-form input:focus[type="submit"],
      .comment-form .input-group-append input:focus[type="submit"],
      .input-group-append .search-form .search-submit:focus,
      .search-form .input-group-append .search-submit:focus {
        z-index: 3;
      }

      /* line 85, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-prepend .btn + .btn,
      .input-group-prepend .comment-form input[type="submit"] + .btn,
      .comment-form .input-group-prepend input[type="submit"] + .btn,
      .input-group-prepend .search-form .search-submit + .btn,
      .search-form .input-group-prepend .search-submit + .btn,
      .input-group-prepend .comment-form .btn + input[type="submit"],
      .comment-form .input-group-prepend .btn + input[type="submit"],
      .input-group-prepend .comment-form input[type="submit"] + input[type="submit"],
      .comment-form .input-group-prepend input[type="submit"] + input[type="submit"],
      .input-group-prepend .search-form .comment-form .search-submit + input[type="submit"],
      .comment-form .input-group-prepend .search-form .search-submit + input[type="submit"],
      .search-form .input-group-prepend .comment-form .search-submit + input[type="submit"],
      .comment-form .search-form .input-group-prepend .search-submit + input[type="submit"],
      .input-group-prepend .search-form .btn + .search-submit,
      .search-form .input-group-prepend .btn + .search-submit,
      .input-group-prepend .comment-form .search-form input[type="submit"] + .search-submit,
      .search-form .input-group-prepend .comment-form input[type="submit"] + .search-submit,
      .comment-form .input-group-prepend .search-form input[type="submit"] + .search-submit,
      .search-form .comment-form .input-group-prepend input[type="submit"] + .search-submit,
      .input-group-prepend .search-form .search-submit + .search-submit,
      .search-form .input-group-prepend .search-submit + .search-submit,
      .input-group-prepend .btn + .input-group-text,
      .input-group-prepend .comment-form input[type="submit"] + .input-group-text,
      .comment-form .input-group-prepend input[type="submit"] + .input-group-text,
      .input-group-prepend .search-form .search-submit + .input-group-text,
      .search-form .input-group-prepend .search-submit + .input-group-text,
      .input-group-prepend .input-group-text + .input-group-text,
      .input-group-prepend .input-group-text + .btn,
      .input-group-prepend .comment-form .input-group-text + input[type="submit"],
      .comment-form .input-group-prepend .input-group-text + input[type="submit"],
      .input-group-prepend .search-form .input-group-text + .search-submit,
      .search-form .input-group-prepend .input-group-text + .search-submit,
      .input-group-append .btn + .btn,
      .input-group-append .comment-form input[type="submit"] + .btn,
      .comment-form .input-group-append input[type="submit"] + .btn,
      .input-group-append .search-form .search-submit + .btn,
      .search-form .input-group-append .search-submit + .btn,
      .input-group-append .comment-form .btn + input[type="submit"],
      .comment-form .input-group-append .btn + input[type="submit"],
      .input-group-append .comment-form input[type="submit"] + input[type="submit"],
      .comment-form .input-group-append input[type="submit"] + input[type="submit"],
      .input-group-append .search-form .comment-form .search-submit + input[type="submit"],
      .comment-form .input-group-append .search-form .search-submit + input[type="submit"],
      .search-form .input-group-append .comment-form .search-submit + input[type="submit"],
      .comment-form .search-form .input-group-append .search-submit + input[type="submit"],
      .input-group-append .search-form .btn + .search-submit,
      .search-form .input-group-append .btn + .search-submit,
      .input-group-append .comment-form .search-form input[type="submit"] + .search-submit,
      .search-form .input-group-append .comment-form input[type="submit"] + .search-submit,
      .comment-form .input-group-append .search-form input[type="submit"] + .search-submit,
      .search-form .comment-form .input-group-append input[type="submit"] + .search-submit,
      .input-group-append .search-form .search-submit + .search-submit,
      .search-form .input-group-append .search-submit + .search-submit,
      .input-group-append .btn + .input-group-text,
      .input-group-append .comment-form input[type="submit"] + .input-group-text,
      .comment-form .input-group-append input[type="submit"] + .input-group-text,
      .input-group-append .search-form .search-submit + .input-group-text,
      .search-form .input-group-append .search-submit + .input-group-text,
      .input-group-append .input-group-text + .input-group-text,
      .input-group-append .input-group-text + .btn,
      .input-group-append .comment-form .input-group-text + input[type="submit"],
      .comment-form .input-group-append .input-group-text + input[type="submit"],
      .input-group-append .search-form .input-group-text + .search-submit,
      .search-form .input-group-append .input-group-text + .search-submit {
        margin-left: -1px;
      }

      /* line 93, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-prepend {
        margin-right: -1px;
      }

      /* line 94, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-append {
        margin-left: -1px;
      }

      /* line 102, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
      }

      /* line 118, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-text input[type="radio"],
      .input-group-text input[type="checkbox"] {
        margin-top: 0;
      }

      /* line 130, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-lg > .form-control:not(textarea),
      .comment-form .input-group-lg > input:not(textarea)[type="text"],
      .comment-form .input-group-lg > input:not(textarea)[type="email"],
      .comment-form .input-group-lg > input:not(textarea)[type="url"],
      .comment-form .input-group-lg > textarea:not(textarea),
      .search-form .input-group-lg > .search-field:not(textarea),
      .input-group-lg > .custom-select {
        height: calc(1.5em + 1rem + 2px);
      }

      /* line 135, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-lg > .form-control,
      .comment-form .input-group-lg > input[type="text"],
      .comment-form .input-group-lg > input[type="email"],
      .comment-form .input-group-lg > input[type="url"],
      .comment-form .input-group-lg > textarea,
      .search-form .input-group-lg > .search-field,
      .input-group-lg > .custom-select,
      .input-group-lg > .input-group-prepend > .input-group-text,
      .input-group-lg > .input-group-append > .input-group-text,
      .input-group-lg > .input-group-prepend > .btn,
      .comment-form .input-group-lg > .input-group-prepend > input[type="submit"],
      .search-form .input-group-lg > .input-group-prepend > .search-submit,
      .input-group-lg > .input-group-append > .btn,
      .comment-form .input-group-lg > .input-group-append > input[type="submit"],
      .search-form .input-group-lg > .input-group-append > .search-submit {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
      }

      /* line 147, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-sm > .form-control:not(textarea),
      .comment-form .input-group-sm > input:not(textarea)[type="text"],
      .comment-form .input-group-sm > input:not(textarea)[type="email"],
      .comment-form .input-group-sm > input:not(textarea)[type="url"],
      .comment-form .input-group-sm > textarea:not(textarea),
      .search-form .input-group-sm > .search-field:not(textarea),
      .input-group-sm > .custom-select {
        height: calc(1.5em + 0.5rem + 2px);
      }

      /* line 152, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-sm > .form-control,
      .comment-form .input-group-sm > input[type="text"],
      .comment-form .input-group-sm > input[type="email"],
      .comment-form .input-group-sm > input[type="url"],
      .comment-form .input-group-sm > textarea,
      .search-form .input-group-sm > .search-field,
      .input-group-sm > .custom-select,
      .input-group-sm > .input-group-prepend > .input-group-text,
      .input-group-sm > .input-group-append > .input-group-text,
      .input-group-sm > .input-group-prepend > .btn,
      .comment-form .input-group-sm > .input-group-prepend > input[type="submit"],
      .search-form .input-group-sm > .input-group-prepend > .search-submit,
      .input-group-sm > .input-group-append > .btn,
      .comment-form .input-group-sm > .input-group-append > input[type="submit"],
      .search-form .input-group-sm > .input-group-append > .search-submit {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
      }

      /* line 164, node_modules/bootstrap/scss/_input-group.scss */

      .input-group-lg > .custom-select,
      .input-group-sm > .custom-select {
        padding-right: 1.75rem;
      }

      /* line 177, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .input-group-prepend > .btn,
      .comment-form .input-group > .input-group-prepend > input[type="submit"],
      .search-form .input-group > .input-group-prepend > .search-submit,
      .input-group > .input-group-prepend > .input-group-text,
      .input-group > .input-group-append:not(:last-child) > .btn,
      .comment-form .input-group > .input-group-append:not(:last-child) > input[type="submit"],
      .search-form .input-group > .input-group-append:not(:last-child) > .search-submit,
      .input-group > .input-group-append:not(:last-child) > .input-group-text,
      .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
      .comment-form .input-group > .input-group-append:last-child > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
      .search-form .input-group > .input-group-append:last-child > .search-submit:not(:last-child):not(.dropdown-toggle),
      .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      /* line 186, node_modules/bootstrap/scss/_input-group.scss */

      .input-group > .input-group-append > .btn,
      .comment-form .input-group > .input-group-append > input[type="submit"],
      .search-form .input-group > .input-group-append > .search-submit,
      .input-group > .input-group-append > .input-group-text,
      .input-group > .input-group-prepend:not(:first-child) > .btn,
      .comment-form .input-group > .input-group-prepend:not(:first-child) > input[type="submit"],
      .search-form .input-group > .input-group-prepend:not(:first-child) > .search-submit,
      .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
      .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
      .comment-form .input-group > .input-group-prepend:first-child > input:not(:first-child)[type="submit"],
      .search-form .input-group > .input-group-prepend:first-child > .search-submit:not(:first-child),
      .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      /* line 10, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control {
        position: relative;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
      }

      /* line 17, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-inline {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1rem;
      }

      /* line 22, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }

      /* line 27, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #525ddc;
        background-color: #525ddc;
      }

      /* line 34, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-input:focus ~ .custom-control-label::before {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 43, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #bcc0f1;
      }

      /* line 47, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        color: #fff;
        background-color: #e6e8fa;
        border-color: #e6e8fa;
      }

      /* line 55, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-input:disabled ~ .custom-control-label {
        color: #6c757d;
      }

      /* line 58, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #e9ecef;
      }

      /* line 69, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
      }

      /* line 75, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-label::before {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: #adb5bd solid 1px;
      }

      /* line 90, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-label::after {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background: no-repeat 50% / 50% 50%;
      }

      /* line 108, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-checkbox .custom-control-label::before {
        border-radius: 0.25rem;
      }

      /* line 113, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      }

      /* line 119, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
        border-color: #525ddc;
        background-color: #525ddc;
      }

      /* line 124, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
      }

      /* line 130, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: rgba(82, 93, 220, 0.5);
      }

      /* line 133, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
        background-color: rgba(82, 93, 220, 0.5);
      }

      /* line 144, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-radio .custom-control-label::before {
        border-radius: 50%;
      }

      /* line 150, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      }

      /* line 156, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: rgba(82, 93, 220, 0.5);
      }

      /* line 167, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-switch {
        padding-left: 2.25rem;
      }

      /* line 171, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-switch .custom-control-label::before {
        left: -2.25rem;
        width: 1.75rem;
        pointer-events: all;
        border-radius: 0.5rem;
      }

      /* line 179, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 2px);
        left: calc(-2.25rem + 2px);
        width: calc(1rem - 4px);
        height: calc(1rem - 4px);
        background-color: #adb5bd;
        border-radius: 0.5rem;
        -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -o-transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -o-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 179, node_modules/bootstrap/scss/_custom-forms.scss */

        .custom-switch .custom-control-label::after {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 192, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #fff;
        -webkit-transform: translateX(0.75rem);
        -o-transform: translateX(0.75rem);
        transform: translateX(0.75rem);
      }

      /* line 199, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: rgba(82, 93, 220, 0.5);
      }

      /* line 212, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select {
        display: inline-block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      /* line 230, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select:focus {
        border-color: #bcc0f1;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 239, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select:focus::-ms-value {
        color: #495057;
        background-color: #fff;
      }

      /* line 250, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select[multiple],
      .custom-select[size]:not([size="1"]) {
        height: auto;
        padding-right: 0.75rem;
        background-image: none;
      }

      /* line 257, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select:disabled {
        color: #6c757d;
        background-color: #e9ecef;
      }

      /* line 263, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select::-ms-expand {
        display: none;
      }

      /* line 268, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select-sm {
        height: calc(1.5em + 0.5rem + 2px);
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.5rem;
        font-size: 0.875rem;
      }

      /* line 276, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-select-lg {
        height: calc(1.5em + 1rem + 2px);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        font-size: 1.25rem;
      }

      /* line 289, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file {
        position: relative;
        display: inline-block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin-bottom: 0;
      }

      /* line 297, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file-input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        opacity: 0;
      }

      /* line 305, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file-input:focus ~ .custom-file-label {
        border-color: #bcc0f1;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 310, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file-input:disabled ~ .custom-file-label {
        background-color: #e9ecef;
      }

      /* line 315, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: "Browse";
      }

      /* line 320, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: attr(data-browse);
      }

      /* line 325, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file-label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
      }

      /* line 342, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-file-label::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: calc(1.5em + 0.75rem);
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #495057;
        content: "Browse";
        background-color: #e9ecef;
        border-left: inherit;
        border-radius: 0 0.25rem 0.25rem 0;
      }

      /* line 366, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range {
        width: 100%;
        height: calc(1rem + 0.4rem);
        padding: 0;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      /* line 373, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:focus {
        outline: none;
      }

      /* line 378, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:focus::-webkit-slider-thumb {
        -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 379, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 380, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:focus::-ms-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 383, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-moz-focus-outer {
        border: 0;
      }

      /* line 387, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-webkit-slider-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: -0.25rem;
        background-color: #525ddc;
        border: 0;
        border-radius: 1rem;
        -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        appearance: none;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 387, node_modules/bootstrap/scss/_custom-forms.scss */

        .custom-range::-webkit-slider-thumb {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 398, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-webkit-slider-thumb:active {
        background-color: #e6e8fa;
      }

      /* line 403, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dee2e6;
        border-color: transparent;
        border-radius: 1rem;
      }

      /* line 414, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
        background-color: #525ddc;
        border: 0;
        border-radius: 1rem;
        -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -moz-appearance: none;
        appearance: none;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 414, node_modules/bootstrap/scss/_custom-forms.scss */

        .custom-range::-moz-range-thumb {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 424, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-moz-range-thumb:active {
        background-color: #e6e8fa;
      }

      /* line 429, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-moz-range-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dee2e6;
        border-color: transparent;
        border-radius: 1rem;
      }

      /* line 440, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-ms-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: 0;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        background-color: #525ddc;
        border: 0;
        border-radius: 1rem;
        -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        appearance: none;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 440, node_modules/bootstrap/scss/_custom-forms.scss */

        .custom-range::-ms-thumb {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 453, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-ms-thumb:active {
        background-color: #e6e8fa;
      }

      /* line 458, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-ms-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: transparent;
        border-color: transparent;
        border-width: 0.5rem;
      }

      /* line 469, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-ms-fill-lower {
        background-color: #dee2e6;
        border-radius: 1rem;
      }

      /* line 474, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range::-ms-fill-upper {
        margin-right: 15px;
        background-color: #dee2e6;
        border-radius: 1rem;
      }

      /* line 481, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:disabled::-webkit-slider-thumb {
        background-color: #adb5bd;
      }

      /* line 485, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:disabled::-webkit-slider-runnable-track {
        cursor: default;
      }

      /* line 489, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:disabled::-moz-range-thumb {
        background-color: #adb5bd;
      }

      /* line 493, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:disabled::-moz-range-track {
        cursor: default;
      }

      /* line 497, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-range:disabled::-ms-thumb {
        background-color: #adb5bd;
      }

      /* line 503, node_modules/bootstrap/scss/_custom-forms.scss */

      .custom-control-label::before,
      .custom-file-label,
      .custom-select {
        -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 503, node_modules/bootstrap/scss/_custom-forms.scss */

        .custom-control-label::before,
        .custom-file-label,
        .custom-select {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 6, node_modules/bootstrap/scss/_nav.scss */

      .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
      }

      /* line 14, node_modules/bootstrap/scss/_nav.scss */

      .nav-link,
      .banner .nav a {
        display: block;
        padding: 0.5rem 1rem;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .nav-link:hover,
      .banner .nav a:hover,
      .nav-link:focus,
      .banner .nav a:focus {
        text-decoration: none;
      }

      /* line 23, node_modules/bootstrap/scss/_nav.scss */

      .nav-link.disabled,
      .banner .nav a.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default;
      }

      /* line 34, node_modules/bootstrap/scss/_nav.scss */

      .nav-tabs {
        border-bottom: 1px solid #dee2e6;
      }

      /* line 37, node_modules/bootstrap/scss/_nav.scss */

      .nav-tabs .nav-item,
      .nav-tabs .banner .nav li,
      .banner .nav .nav-tabs li {
        margin-bottom: -1px;
      }

      /* line 41, node_modules/bootstrap/scss/_nav.scss */

      .nav-tabs .nav-link,
      .nav-tabs .banner .nav a,
      .banner .nav .nav-tabs a {
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .nav-tabs .nav-link:hover,
      .nav-tabs .banner .nav a:hover,
      .banner .nav .nav-tabs a:hover,
      .nav-tabs .nav-link:focus,
      .nav-tabs .banner .nav a:focus,
      .banner .nav .nav-tabs a:focus {
        border-color: #e9ecef #e9ecef #dee2e6;
      }

      /* line 49, node_modules/bootstrap/scss/_nav.scss */

      .nav-tabs .nav-link.disabled,
      .nav-tabs .banner .nav a.disabled,
      .banner .nav .nav-tabs a.disabled {
        color: #6c757d;
        background-color: transparent;
        border-color: transparent;
      }

      /* line 56, node_modules/bootstrap/scss/_nav.scss */

      .nav-tabs .nav-link.active,
      .nav-tabs .banner .nav a.active,
      .banner .nav .nav-tabs a.active,
      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .banner .nav li.show .nav-link,
      .banner .nav .nav-tabs li.show .nav-link,
      .nav-tabs .nav-item.show .banner .nav a,
      .banner .nav .nav-tabs .nav-item.show a,
      .nav-tabs .banner .nav li.show a,
      .banner .nav .nav-tabs li.show a {
        color: #495057;
        background-color: #fff;
        border-color: #dee2e6 #dee2e6 #fff;
      }

      /* line 63, node_modules/bootstrap/scss/_nav.scss */

      .nav-tabs .dropdown-menu {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      /* line 77, node_modules/bootstrap/scss/_nav.scss */

      .nav-pills .nav-link,
      .nav-pills .banner .nav a,
      .banner .nav .nav-pills a {
        border-radius: 0.25rem;
      }

      /* line 81, node_modules/bootstrap/scss/_nav.scss */

      .nav-pills .nav-link.active,
      .nav-pills .banner .nav a.active,
      .banner .nav .nav-pills a.active,
      .nav-pills .show > .nav-link,
      .nav-pills .banner .nav .show > a,
      .banner .nav .nav-pills .show > a {
        color: #fff;
        background-color: #525ddc;
      }

      /* line 94, node_modules/bootstrap/scss/_nav.scss */

      .nav-fill .nav-item,
      .nav-fill .banner .nav li,
      .banner .nav .nav-fill li {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: center;
      }

      /* line 101, node_modules/bootstrap/scss/_nav.scss */

      .nav-justified .nav-item,
      .nav-justified .banner .nav li,
      .banner .nav .nav-justified li {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        text-align: center;
      }

      /* line 114, node_modules/bootstrap/scss/_nav.scss */

      .tab-content > .tab-pane {
        display: none;
      }

      /* line 117, node_modules/bootstrap/scss/_nav.scss */

      .tab-content > .active {
        display: block;
      }

      /* line 18, node_modules/bootstrap/scss/_navbar.scss */

      .navbar {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0.5rem 1rem;
      }

      /* line 28, node_modules/bootstrap/scss/_navbar.scss */

      .navbar > .container,
      .navbar > .container-fluid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }

      /* line 42, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-brand {
        display: inline-block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-brand:hover,
      .navbar-brand:focus {
        text-decoration: none;
      }

      /* line 61, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
      }

      /* line 68, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-nav .nav-link,
      .navbar-nav .banner .nav a,
      .banner .nav .navbar-nav a {
        padding-right: 0;
        padding-left: 0;
      }

      /* line 73, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-nav .dropdown-menu {
        position: static;
        float: none;
      }

      /* line 84, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-text {
        display: inline-block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      /* line 99, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-collapse {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      /* line 108, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0.25rem;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-toggler:hover,
      .navbar-toggler:focus {
        text-decoration: none;
      }

      /* line 123, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%;
      }

      @media (max-width: 575.98px) {
        /* line 142, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
          padding-right: 0;
          padding-left: 0;
        }
      }

      @media (min-width: 576px) {
        /* line 140, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }

        /* line 153, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 156, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm .navbar-nav .dropdown-menu {
          position: absolute;
        }

        /* line 160, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm .navbar-nav .nav-link,
        .navbar-expand-sm .navbar-nav .banner .nav a,
        .banner .nav .navbar-expand-sm .navbar-nav a {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }

        /* line 167, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
        }

        /* line 172, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto;
        }

        /* line 179, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-sm .navbar-toggler {
          display: none;
        }
      }

      @media (max-width: 767.98px) {
        /* line 142, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
          padding-right: 0;
          padding-left: 0;
        }
      }

      @media (min-width: 768px) {
        /* line 140, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }

        /* line 153, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 156, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md .navbar-nav .dropdown-menu {
          position: absolute;
        }

        /* line 160, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md .navbar-nav .nav-link,
        .navbar-expand-md .navbar-nav .banner .nav a,
        .banner .nav .navbar-expand-md .navbar-nav a {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }

        /* line 167, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
        }

        /* line 172, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto;
        }

        /* line 179, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-md .navbar-toggler {
          display: none;
        }
      }

      @media (max-width: 991.98px) {
        /* line 142, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
          padding-right: 0;
          padding-left: 0;
        }
      }

      @media (min-width: 992px) {
        /* line 140, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }

        /* line 153, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 156, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute;
        }

        /* line 160, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg .navbar-nav .nav-link,
        .navbar-expand-lg .navbar-nav .banner .nav a,
        .banner .nav .navbar-expand-lg .navbar-nav a {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }

        /* line 167, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
        }

        /* line 172, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto;
        }

        /* line 179, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-lg .navbar-toggler {
          display: none;
        }
      }

      @media (max-width: 1199.98px) {
        /* line 142, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
          padding-right: 0;
          padding-left: 0;
        }
      }

      @media (min-width: 1200px) {
        /* line 140, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }

        /* line 153, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 156, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl .navbar-nav .dropdown-menu {
          position: absolute;
        }

        /* line 160, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl .navbar-nav .nav-link,
        .navbar-expand-xl .navbar-nav .banner .nav a,
        .banner .nav .navbar-expand-xl .navbar-nav a {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }

        /* line 167, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
        }

        /* line 172, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl .navbar-collapse {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          -ms-flex-preferred-size: auto;
          flex-basis: auto;
        }

        /* line 179, node_modules/bootstrap/scss/_navbar.scss */

        .navbar-expand-xl .navbar-toggler {
          display: none;
        }
      }

      /* line 140, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
      }

      /* line 142, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand > .container,
      .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0;
      }

      /* line 153, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
      }

      /* line 156, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute;
      }

      /* line 160, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand .navbar-nav .nav-link,
      .navbar-expand .navbar-nav .banner .nav a,
      .banner .nav .navbar-expand .navbar-nav a {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }

      /* line 167, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand > .container,
      .navbar-expand > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }

      /* line 172, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
      }

      /* line 179, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-expand .navbar-toggler {
        display: none;
      }

      /* line 194, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, 0.9);
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-light .navbar-brand:hover,
      .navbar-light .navbar-brand:focus {
        color: rgba(0, 0, 0, 0.9);
      }

      /* line 203, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-nav .nav-link,
      .navbar-light .navbar-nav .banner .nav a,
      .banner .nav .navbar-light .navbar-nav a {
        color: rgba(0, 0, 0, 0.5);
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-light .navbar-nav .nav-link:hover,
      .navbar-light .navbar-nav .banner .nav a:hover,
      .banner .nav .navbar-light .navbar-nav a:hover,
      .navbar-light .navbar-nav .nav-link:focus,
      .navbar-light .navbar-nav .banner .nav a:focus,
      .banner .nav .navbar-light .navbar-nav a:focus {
        color: rgba(0, 0, 0, 0.7);
      }

      /* line 210, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-nav .nav-link.disabled,
      .navbar-light .navbar-nav .banner .nav a.disabled,
      .banner .nav .navbar-light .navbar-nav a.disabled {
        color: rgba(0, 0, 0, 0.3);
      }

      /* line 215, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-nav .show > .nav-link,
      .navbar-light .navbar-nav .banner .nav .show > a,
      .banner .nav .navbar-light .navbar-nav .show > a,
      .navbar-light .navbar-nav .active > .nav-link,
      .navbar-light .navbar-nav .banner .nav .active > a,
      .banner .nav .navbar-light .navbar-nav .active > a,
      .navbar-light .navbar-nav .nav-link.show,
      .navbar-light .navbar-nav .banner .nav a.show,
      .banner .nav .navbar-light .navbar-nav a.show,
      .navbar-light .navbar-nav .nav-link.active,
      .navbar-light .navbar-nav .banner .nav a.active,
      .banner .nav .navbar-light .navbar-nav a.active {
        color: rgba(0, 0, 0, 0.9);
      }

      /* line 223, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.1);
      }

      /* line 228, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-toggler-icon {
        background-image: none;
      }

      /* line 232, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-text {
        color: rgba(0, 0, 0, 0.5);
      }

      /* line 234, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, 0.9);
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-light .navbar-text a:hover,
      .navbar-light .navbar-text a:focus {
        color: rgba(0, 0, 0, 0.9);
      }

      /* line 246, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-brand {
        color: #fff;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-dark .navbar-brand:hover,
      .navbar-dark .navbar-brand:focus {
        color: #fff;
      }

      /* line 255, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-nav .nav-link,
      .navbar-dark .navbar-nav .banner .nav a,
      .banner .nav .navbar-dark .navbar-nav a {
        color: rgba(255, 255, 255, 0.5);
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-dark .navbar-nav .nav-link:hover,
      .navbar-dark .navbar-nav .banner .nav a:hover,
      .banner .nav .navbar-dark .navbar-nav a:hover,
      .navbar-dark .navbar-nav .nav-link:focus,
      .navbar-dark .navbar-nav .banner .nav a:focus,
      .banner .nav .navbar-dark .navbar-nav a:focus {
        color: rgba(255, 255, 255, 0.75);
      }

      /* line 262, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-nav .nav-link.disabled,
      .navbar-dark .navbar-nav .banner .nav a.disabled,
      .banner .nav .navbar-dark .navbar-nav a.disabled {
        color: rgba(255, 255, 255, 0.25);
      }

      /* line 267, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-nav .show > .nav-link,
      .navbar-dark .navbar-nav .banner .nav .show > a,
      .banner .nav .navbar-dark .navbar-nav .show > a,
      .navbar-dark .navbar-nav .active > .nav-link,
      .navbar-dark .navbar-nav .banner .nav .active > a,
      .banner .nav .navbar-dark .navbar-nav .active > a,
      .navbar-dark .navbar-nav .nav-link.show,
      .navbar-dark .navbar-nav .banner .nav a.show,
      .banner .nav .navbar-dark .navbar-nav a.show,
      .navbar-dark .navbar-nav .nav-link.active,
      .navbar-dark .navbar-nav .banner .nav a.active,
      .banner .nav .navbar-dark .navbar-nav a.active {
        color: #fff;
      }

      /* line 275, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0.1);
      }

      /* line 280, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-toggler-icon {
        background-image: none;
      }

      /* line 284, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, 0.5);
      }

      /* line 286, node_modules/bootstrap/scss/_navbar.scss */

      .navbar-dark .navbar-text a {
        color: #fff;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .navbar-dark .navbar-text a:hover,
      .navbar-dark .navbar-text a:focus {
        color: #fff;
      }

      /* line 5, node_modules/bootstrap/scss/_card.scss */

      .card {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
      }

      /* line 16, node_modules/bootstrap/scss/_card.scss */

      .card > hr {
        margin-right: 0;
        margin-left: 0;
      }

      /* line 22, node_modules/bootstrap/scss/_card.scss */

      .card > .list-group:first-child .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      /* line 28, node_modules/bootstrap/scss/_card.scss */

      .card > .list-group:last-child .list-group-item:last-child {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      /* line 34, node_modules/bootstrap/scss/_card.scss */

      .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1.25rem;
      }

      /* line 42, node_modules/bootstrap/scss/_card.scss */

      .card-title {
        margin-bottom: 0.75rem;
      }

      /* line 46, node_modules/bootstrap/scss/_card.scss */

      .card-subtitle {
        margin-top: -0.375rem;
        margin-bottom: 0;
      }

      /* line 51, node_modules/bootstrap/scss/_card.scss */

      .card-text:last-child {
        margin-bottom: 0;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .card-link:hover {
        text-decoration: none;
      }

      /* line 60, node_modules/bootstrap/scss/_card.scss */

      .card-link + .card-link {
        margin-left: 1.25rem;
      }

      /* line 69, node_modules/bootstrap/scss/_card.scss */

      .card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }

      /* line 76, node_modules/bootstrap/scss/_card.scss */

      .card-header:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
      }

      /* line 81, node_modules/bootstrap/scss/_card.scss */

      .card-header + .list-group .list-group-item:first-child {
        border-top: 0;
      }

      /* line 87, node_modules/bootstrap/scss/_card.scss */

      .card-footer {
        padding: 0.75rem 1.25rem;
        background-color: rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.125);
      }

      /* line 92, node_modules/bootstrap/scss/_card.scss */

      .card-footer:last-child {
        border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
      }

      /* line 102, node_modules/bootstrap/scss/_card.scss */

      .card-header-tabs {
        margin-right: -0.625rem;
        margin-bottom: -0.75rem;
        margin-left: -0.625rem;
        border-bottom: 0;
      }

      /* line 109, node_modules/bootstrap/scss/_card.scss */

      .card-header-pills {
        margin-right: -0.625rem;
        margin-left: -0.625rem;
      }

      /* line 115, node_modules/bootstrap/scss/_card.scss */

      .card-img-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1.25rem;
      }

      /* line 124, node_modules/bootstrap/scss/_card.scss */

      .card-img {
        width: 100%;
        border-radius: calc(0.25rem - 1px);
      }

      /* line 130, node_modules/bootstrap/scss/_card.scss */

      .card-img-top {
        width: 100%;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
      }

      /* line 135, node_modules/bootstrap/scss/_card.scss */

      .card-img-bottom {
        width: 100%;
        border-bottom-right-radius: calc(0.25rem - 1px);
        border-bottom-left-radius: calc(0.25rem - 1px);
      }

      /* line 143, node_modules/bootstrap/scss/_card.scss */

      .card-deck {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      /* line 147, node_modules/bootstrap/scss/_card.scss */

      .card-deck .card {
        margin-bottom: 15px;
      }

      @media (min-width: 576px) {
        /* line 143, node_modules/bootstrap/scss/_card.scss */

        .card-deck {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          margin-right: -15px;
          margin-left: -15px;
        }

        /* line 156, node_modules/bootstrap/scss/_card.scss */

        .card-deck .card {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
          flex: 1 0 0%;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          margin-right: 15px;
          margin-bottom: 0;
          margin-left: 15px;
        }
      }

      /* line 173, node_modules/bootstrap/scss/_card.scss */

      .card-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      /* line 179, node_modules/bootstrap/scss/_card.scss */

      .card-group > .card {
        margin-bottom: 15px;
      }

      @media (min-width: 576px) {
        /* line 173, node_modules/bootstrap/scss/_card.scss */

        .card-group {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
        }

        /* line 187, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card {
          -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
          flex: 1 0 0%;
          margin-bottom: 0;
        }

        /* line 192, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0;
        }

        /* line 199, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        /* line 202, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0;
        }

        /* line 207, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0;
        }

        /* line 214, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        /* line 217, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0;
        }

        /* line 222, node_modules/bootstrap/scss/_card.scss */

        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0;
        }
      }

      /* line 239, node_modules/bootstrap/scss/_card.scss */

      .card-columns .card {
        margin-bottom: 0.75rem;
      }

      @media (min-width: 576px) {
        /* line 238, node_modules/bootstrap/scss/_card.scss */

        .card-columns {
          -webkit-column-count: 3;
          column-count: 3;
          -webkit-column-gap: 1.25rem;
          column-gap: 1.25rem;
          orphans: 1;
          widows: 1;
        }

        /* line 249, node_modules/bootstrap/scss/_card.scss */

        .card-columns .card {
          display: inline-block;
          width: 100%;
        }
      }

      /* line 262, node_modules/bootstrap/scss/_card.scss */

      .accordion > .card {
        overflow: hidden;
      }

      /* line 266, node_modules/bootstrap/scss/_card.scss */

      .accordion > .card:not(:first-of-type) .card-header:first-child {
        border-radius: 0;
      }

      /* line 270, node_modules/bootstrap/scss/_card.scss */

      .accordion > .card:not(:first-of-type):not(:last-of-type) {
        border-bottom: 0;
        border-radius: 0;
      }

      /* line 276, node_modules/bootstrap/scss/_card.scss */

      .accordion > .card:first-of-type {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      /* line 281, node_modules/bootstrap/scss/_card.scss */

      .accordion > .card:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      /* line 285, node_modules/bootstrap/scss/_card.scss */

      .accordion > .card .card-header {
        margin-bottom: -1px;
      }

      /* line 1, node_modules/bootstrap/scss/_breadcrumb.scss */

      .breadcrumb {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
        list-style: none;
        background-color: #e9ecef;
        border-radius: 0.25rem;
      }

      /* line 13, node_modules/bootstrap/scss/_breadcrumb.scss */

      .breadcrumb-item + .breadcrumb-item {
        padding-left: 0.5rem;
      }

      /* line 16, node_modules/bootstrap/scss/_breadcrumb.scss */

      .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "/";
      }

      /* line 30, node_modules/bootstrap/scss/_breadcrumb.scss */

      .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: underline;
      }

      /* line 34, node_modules/bootstrap/scss/_breadcrumb.scss */

      .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: none;
      }

      /* line 38, node_modules/bootstrap/scss/_breadcrumb.scss */

      .breadcrumb-item.active {
        color: #6c757d;
      }

      /* line 1, node_modules/bootstrap/scss/_pagination.scss */

      .pagination {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 0.25rem;
      }

      /* line 7, node_modules/bootstrap/scss/_pagination.scss */

      .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #525ddc;
        background-color: #fff;
        border: 1px solid #dee2e6;
      }

      /* line 17, node_modules/bootstrap/scss/_pagination.scss */

      .page-link:hover {
        z-index: 2;
        color: #2632bc;
        text-decoration: none;
        background-color: #e9ecef;
        border-color: #dee2e6;
      }

      /* line 25, node_modules/bootstrap/scss/_pagination.scss */

      .page-link:focus {
        z-index: 2;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.25);
      }

      /* line 34, node_modules/bootstrap/scss/_pagination.scss */

      .page-item:first-child .page-link {
        margin-left: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      /* line 40, node_modules/bootstrap/scss/_pagination.scss */

      .page-item:last-child .page-link {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      /* line 45, node_modules/bootstrap/scss/_pagination.scss */

      .page-item.active .page-link {
        z-index: 1;
        color: #fff;
        background-color: #525ddc;
        border-color: #525ddc;
      }

      /* line 52, node_modules/bootstrap/scss/_pagination.scss */

      .page-item.disabled .page-link {
        color: #6c757d;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */

      .pagination-lg .page-link {
        padding: 0.75rem 1.5rem;
        font-size: 1.25rem;
        line-height: 1.5;
      }

      /* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */

      .pagination-lg .page-item:first-child .page-link {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */

      .pagination-lg .page-item:last-child .page-link {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */

      .pagination-sm .page-link {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
      }

      /* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */

      .pagination-sm .page-item:first-child .page-link {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */

      .pagination-sm .page-item:last-child .page-link {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }

      /* line 6, node_modules/bootstrap/scss/_badge.scss */

      .badge {
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 6, node_modules/bootstrap/scss/_badge.scss */

        .badge {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge:hover,
      a.badge:focus {
        text-decoration: none;
      }

      /* line 25, node_modules/bootstrap/scss/_badge.scss */

      .badge:empty {
        display: none;
      }

      /* line 31, node_modules/bootstrap/scss/_badge.scss */

      .btn .badge,
      .comment-form input[type="submit"] .badge,
      .search-form .search-submit .badge {
        position: relative;
        top: -1px;
      }

      /* line 40, node_modules/bootstrap/scss/_badge.scss */

      .badge-pill {
        padding-right: 0.6em;
        padding-left: 0.6em;
        border-radius: 10rem;
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-primary {
        color: #fff;
        background-color: #525ddc;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-primary:hover,
      a.badge-primary:focus {
        color: #fff;
        background-color: #2a38d1;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-primary:focus,
      a.badge-primary.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(82, 93, 220, 0.5);
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-secondary {
        color: #fff;
        background-color: #6c757d;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-secondary:hover,
      a.badge-secondary:focus {
        color: #fff;
        background-color: #545b62;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-secondary:focus,
      a.badge-secondary.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-success {
        color: #fff;
        background-color: #28a745;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-success:hover,
      a.badge-success:focus {
        color: #fff;
        background-color: #1e7e34;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-success:focus,
      a.badge-success.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-info {
        color: #fff;
        background-color: #17a2b8;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-info:hover,
      a.badge-info:focus {
        color: #fff;
        background-color: #117a8b;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-info:focus,
      a.badge-info.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-warning {
        color: #212529;
        background-color: #ffc107;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-warning:hover,
      a.badge-warning:focus {
        color: #212529;
        background-color: #d39e00;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-warning:focus,
      a.badge-warning.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-danger {
        color: #fff;
        background-color: #dc3545;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-danger:hover,
      a.badge-danger:focus {
        color: #fff;
        background-color: #bd2130;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-danger:focus,
      a.badge-danger.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-light {
        color: #212529;
        background-color: #f8f9fa;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-light:hover,
      a.badge-light:focus {
        color: #212529;
        background-color: #dae0e5;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-light:focus,
      a.badge-light.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      }

      /* line 51, node_modules/bootstrap/scss/_badge.scss */

      .badge-dark {
        color: #fff;
        background-color: #343a40;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.badge-dark:hover,
      a.badge-dark:focus {
        color: #fff;
        background-color: #1d2124;
      }

      /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

      a.badge-dark:focus,
      a.badge-dark.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      }

      /* line 1, node_modules/bootstrap/scss/_jumbotron.scss */

      .jumbotron {
        padding: 2rem 1rem;
        margin-bottom: 2rem;
        background-color: #e9ecef;
        border-radius: 0.3rem;
      }

      @media (min-width: 576px) {
        /* line 1, node_modules/bootstrap/scss/_jumbotron.scss */

        .jumbotron {
          padding: 4rem 2rem;
        }
      }

      /* line 13, node_modules/bootstrap/scss/_jumbotron.scss */

      .jumbotron-fluid {
        padding-right: 0;
        padding-left: 0;
        border-radius: 0;
      }

      /* line 5, node_modules/bootstrap/scss/_alert.scss */

      .alert {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
      }

      /* line 14, node_modules/bootstrap/scss/_alert.scss */

      .alert-heading {
        color: inherit;
      }

      /* line 20, node_modules/bootstrap/scss/_alert.scss */

      .alert-link {
        font-weight: 700;
      }

      /* line 29, node_modules/bootstrap/scss/_alert.scss */

      .alert-dismissible {
        padding-right: 4rem;
      }

      /* line 33, node_modules/bootstrap/scss/_alert.scss */

      .alert-dismissible .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.75rem 1.25rem;
        color: inherit;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-primary {
        color: #2b3072;
        background-color: #dcdff8;
        border-color: #cfd2f5;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-primary hr {
        border-top-color: #babef1;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-primary .alert-link {
        color: #1d204d;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-secondary {
        color: #383d41;
        background-color: #e2e3e5;
        border-color: #d6d8db;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-secondary hr {
        border-top-color: #c8cbcf;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-secondary .alert-link {
        color: #202326;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-success hr {
        border-top-color: #b1dfbb;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-success .alert-link {
        color: #0b2e13;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-info {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-info hr {
        border-top-color: #abdde5;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-info .alert-link {
        color: #062c33;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-warning hr {
        border-top-color: #ffe8a1;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-warning .alert-link {
        color: #533f03;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-danger hr {
        border-top-color: #f1b0b7;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-danger .alert-link {
        color: #491217;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-light {
        color: #818182;
        background-color: #fefefe;
        border-color: #fdfdfe;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-light hr {
        border-top-color: #ececf6;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-light .alert-link {
        color: #686868;
      }

      /* line 48, node_modules/bootstrap/scss/_alert.scss */

      .alert-dark {
        color: #1b1e21;
        background-color: #d6d8d9;
        border-color: #c6c8ca;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-dark hr {
        border-top-color: #b9bbbe;
      }

      /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

      .alert-dark .alert-link {
        color: #040505;
      }

      @-webkit-keyframes progress-bar-stripes {
        from {
          background-position: 1rem 0;
        }

        to {
          background-position: 0 0;
        }
      }

      @-o-keyframes progress-bar-stripes {
        from {
          background-position: 1rem 0;
        }

        to {
          background-position: 0 0;
        }
      }

      @keyframes progress-bar-stripes {
        from {
          background-position: 1rem 0;
        }

        to {
          background-position: 0 0;
        }
      }

      /* line 9, node_modules/bootstrap/scss/_progress.scss */

      .progress {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 1rem;
        overflow: hidden;
        font-size: 0.75rem;
        background-color: #e9ecef;
        border-radius: 0.25rem;
      }

      /* line 19, node_modules/bootstrap/scss/_progress.scss */

      .progress-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #525ddc;
        -webkit-transition: width 0.6s ease;
        -o-transition: width 0.6s ease;
        transition: width 0.6s ease;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 19, node_modules/bootstrap/scss/_progress.scss */

        .progress-bar {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 30, node_modules/bootstrap/scss/_progress.scss */

      .progress-bar-striped {
        background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 1rem 1rem;
      }

      /* line 36, node_modules/bootstrap/scss/_progress.scss */

      .progress-bar-animated {
        -webkit-animation: progress-bar-stripes 1s linear infinite;
        -o-animation: progress-bar-stripes 1s linear infinite;
        animation: progress-bar-stripes 1s linear infinite;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 36, node_modules/bootstrap/scss/_progress.scss */

        .progress-bar-animated {
          -webkit-animation: none;
          -o-animation: none;
          animation: none;
        }
      }

      /* line 1, node_modules/bootstrap/scss/_media.scss */

      .media {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
      }

      /* line 6, node_modules/bootstrap/scss/_media.scss */

      .media-body {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }

      /* line 5, node_modules/bootstrap/scss/_list-group.scss */

      .list-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
      }

      /* line 20, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-item-action {
        width: 100%;
        color: #495057;
        text-align: inherit;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-action:hover,
      .list-group-item-action:focus {
        z-index: 1;
        color: #495057;
        text-decoration: none;
        background-color: #f8f9fa;
      }

      /* line 33, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-item-action:active {
        color: #212529;
        background-color: #e9ecef;
      }

      /* line 44, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-item {
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
      }

      /* line 54, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      /* line 58, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      /* line 63, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-item.disabled,
      .list-group-item:disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: #fff;
      }

      /* line 71, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-item.active {
        z-index: 2;
        color: #fff;
        background-color: #525ddc;
        border-color: #525ddc;
      }

      /* line 88, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-horizontal {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
      }

      /* line 91, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-horizontal .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
      }

      /* line 95, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-horizontal .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }

      /* line 100, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-horizontal .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
      }

      @media (min-width: 576px) {
        /* line 88, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-sm {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 91, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-sm .list-group-item {
          margin-right: -1px;
          margin-bottom: 0;
        }

        /* line 95, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-sm .list-group-item:first-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0;
        }

        /* line 100, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-sm .list-group-item:last-child {
          margin-right: 0;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0;
        }
      }

      @media (min-width: 768px) {
        /* line 88, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-md {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 91, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-md .list-group-item {
          margin-right: -1px;
          margin-bottom: 0;
        }

        /* line 95, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-md .list-group-item:first-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0;
        }

        /* line 100, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-md .list-group-item:last-child {
          margin-right: 0;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0;
        }
      }

      @media (min-width: 992px) {
        /* line 88, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-lg {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 91, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-lg .list-group-item {
          margin-right: -1px;
          margin-bottom: 0;
        }

        /* line 95, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-lg .list-group-item:first-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0;
        }

        /* line 100, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-lg .list-group-item:last-child {
          margin-right: 0;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0;
        }
      }

      @media (min-width: 1200px) {
        /* line 88, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-xl {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }

        /* line 91, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-xl .list-group-item {
          margin-right: -1px;
          margin-bottom: 0;
        }

        /* line 95, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-xl .list-group-item:first-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          border-top-right-radius: 0;
        }

        /* line 100, node_modules/bootstrap/scss/_list-group.scss */

        .list-group-horizontal-xl .list-group-item:last-child {
          margin-right: 0;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0;
        }
      }

      /* line 117, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-flush .list-group-item {
        border-right: 0;
        border-left: 0;
        border-radius: 0;
      }

      /* line 122, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-flush .list-group-item:last-child {
        margin-bottom: -1px;
      }

      /* line 128, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-flush:first-child .list-group-item:first-child {
        border-top: 0;
      }

      /* line 134, node_modules/bootstrap/scss/_list-group.scss */

      .list-group-flush:last-child .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-primary {
        color: #2b3072;
        background-color: #cfd2f5;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-primary.list-group-item-action:hover,
      .list-group-item-primary.list-group-item-action:focus {
        color: #2b3072;
        background-color: #babef1;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-primary.list-group-item-action.active {
        color: #fff;
        background-color: #2b3072;
        border-color: #2b3072;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-secondary.list-group-item-action:hover,
      .list-group-item-secondary.list-group-item-action:focus {
        color: #383d41;
        background-color: #c8cbcf;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-secondary.list-group-item-action.active {
        color: #fff;
        background-color: #383d41;
        border-color: #383d41;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-success.list-group-item-action:hover,
      .list-group-item-success.list-group-item-action:focus {
        color: #155724;
        background-color: #b1dfbb;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-success.list-group-item-action.active {
        color: #fff;
        background-color: #155724;
        border-color: #155724;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-info.list-group-item-action:hover,
      .list-group-item-info.list-group-item-action:focus {
        color: #0c5460;
        background-color: #abdde5;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-info.list-group-item-action.active {
        color: #fff;
        background-color: #0c5460;
        border-color: #0c5460;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-warning.list-group-item-action:hover,
      .list-group-item-warning.list-group-item-action:focus {
        color: #856404;
        background-color: #ffe8a1;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-warning.list-group-item-action.active {
        color: #fff;
        background-color: #856404;
        border-color: #856404;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-danger.list-group-item-action:hover,
      .list-group-item-danger.list-group-item-action:focus {
        color: #721c24;
        background-color: #f1b0b7;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-danger.list-group-item-action.active {
        color: #fff;
        background-color: #721c24;
        border-color: #721c24;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-light.list-group-item-action:hover,
      .list-group-item-light.list-group-item-action:focus {
        color: #818182;
        background-color: #ececf6;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-light.list-group-item-action.active {
        color: #fff;
        background-color: #818182;
        border-color: #818182;
      }

      /* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .list-group-item-dark.list-group-item-action:hover,
      .list-group-item-dark.list-group-item-action:focus {
        color: #1b1e21;
        background-color: #b9bbbe;
      }

      /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

      .list-group-item-dark.list-group-item-action.active {
        color: #fff;
        background-color: #1b1e21;
        border-color: #1b1e21;
      }

      /* line 1, node_modules/bootstrap/scss/_close.scss */

      .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .5;
      }

      /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

      .close:hover {
        color: #000;
        text-decoration: none;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .close:not(:disabled):not(.disabled):hover,
      .close:not(:disabled):not(.disabled):focus {
        opacity: .75;
      }

      /* line 29, node_modules/bootstrap/scss/_close.scss */

      button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      /* line 39, node_modules/bootstrap/scss/_close.scss */

      a.close.disabled {
        pointer-events: none;
      }

      /* line 1, node_modules/bootstrap/scss/_toasts.scss */

      .toast {
        max-width: 350px;
        overflow: hidden;
        font-size: 0.875rem;
        background-color: rgba(255, 255, 255, 0.85);
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        opacity: 0;
        border-radius: 0.25rem;
      }

      /* line 14, node_modules/bootstrap/scss/_toasts.scss */

      .toast:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      /* line 18, node_modules/bootstrap/scss/_toasts.scss */

      .toast.showing {
        opacity: 1;
      }

      /* line 22, node_modules/bootstrap/scss/_toasts.scss */

      .toast.show {
        display: block;
        opacity: 1;
      }

      /* line 27, node_modules/bootstrap/scss/_toasts.scss */

      .toast.hide {
        display: none;
      }

      /* line 32, node_modules/bootstrap/scss/_toasts.scss */

      .toast-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.25rem 0.75rem;
        color: #6c757d;
        background-color: rgba(255, 255, 255, 0.85);
        background-clip: padding-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }

      /* line 42, node_modules/bootstrap/scss/_toasts.scss */

      .toast-body {
        padding: 0.75rem;
      }

      /* line 7, node_modules/bootstrap/scss/_modal.scss */

      .modal-open {
        overflow: hidden;
      }

      /* line 11, node_modules/bootstrap/scss/_modal.scss */

      .modal-open .modal {
        overflow-x: hidden;
        overflow-y: auto;
      }

      /* line 18, node_modules/bootstrap/scss/_modal.scss */

      .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
      }

      /* line 36, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog {
        position: relative;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;
      }

      /* line 44, node_modules/bootstrap/scss/_modal.scss */

      .modal.fade .modal-dialog {
        -webkit-transition: -webkit-transform 0.3s ease-out;
        transition: -webkit-transform 0.3s ease-out;
        -o-transition: -o-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -o-transform 0.3s ease-out;
        -webkit-transform: translate(0, -50px);
        -o-transform: translate(0, -50px);
        transform: translate(0, -50px);
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 44, node_modules/bootstrap/scss/_modal.scss */

        .modal.fade .modal-dialog {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 48, node_modules/bootstrap/scss/_modal.scss */

      .modal.show .modal-dialog {
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
      }

      /* line 53, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-scrollable {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-height: calc(100% - 1rem);
      }

      /* line 57, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 1rem);
        overflow: hidden;
      }

      /* line 62, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-scrollable .modal-header,
      .modal-dialog-scrollable .modal-footer {
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }

      /* line 67, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-scrollable .modal-body {
        overflow-y: auto;
      }

      /* line 72, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-centered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100% - 1rem);
      }

      /* line 78, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-centered::before {
        display: block;
        height: calc(100vh - 1rem);
        content: "";
      }

      /* line 85, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-centered.modal-dialog-scrollable {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
      }

      /* line 90, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-centered.modal-dialog-scrollable .modal-content {
        max-height: none;
      }

      /* line 94, node_modules/bootstrap/scss/_modal.scss */

      .modal-dialog-centered.modal-dialog-scrollable::before {
        content: none;
      }

      /* line 101, node_modules/bootstrap/scss/_modal.scss */

      .modal-content {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
      }

      /* line 119, node_modules/bootstrap/scss/_modal.scss */

      .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: #000;
      }

      /* line 129, node_modules/bootstrap/scss/_modal.scss */

      .modal-backdrop.fade {
        opacity: 0;
      }

      /* line 130, node_modules/bootstrap/scss/_modal.scss */

      .modal-backdrop.show {
        opacity: 0.5;
      }

      /* line 135, node_modules/bootstrap/scss/_modal.scss */

      .modal-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
      }

      /* line 143, node_modules/bootstrap/scss/_modal.scss */

      .modal-header .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
      }

      /* line 151, node_modules/bootstrap/scss/_modal.scss */

      .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
      }

      /* line 158, node_modules/bootstrap/scss/_modal.scss */

      .modal-body {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
      }

      /* line 167, node_modules/bootstrap/scss/_modal.scss */

      .modal-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }

      /* line 176, node_modules/bootstrap/scss/_modal.scss */

      .modal-footer > :not(:first-child) {
        margin-left: .25rem;
      }

      /* line 177, node_modules/bootstrap/scss/_modal.scss */

      .modal-footer > :not(:last-child) {
        margin-right: .25rem;
      }

      /* line 181, node_modules/bootstrap/scss/_modal.scss */

      .modal-scrollbar-measure {
        position: absolute;
        top: -9999px;
        width: 50px;
        height: 50px;
        overflow: scroll;
      }

      @media (min-width: 576px) {
        /* line 192, node_modules/bootstrap/scss/_modal.scss */

        .modal-dialog {
          max-width: 500px;
          margin: 1.75rem auto;
        }

        /* line 197, node_modules/bootstrap/scss/_modal.scss */

        .modal-dialog-scrollable {
          max-height: calc(100% - 3.5rem);
        }

        /* line 200, node_modules/bootstrap/scss/_modal.scss */

        .modal-dialog-scrollable .modal-content {
          max-height: calc(100vh - 3.5rem);
        }

        /* line 205, node_modules/bootstrap/scss/_modal.scss */

        .modal-dialog-centered {
          min-height: calc(100% - 3.5rem);
        }

        /* line 208, node_modules/bootstrap/scss/_modal.scss */

        .modal-dialog-centered::before {
          height: calc(100vh - 3.5rem);
        }

        /* line 217, node_modules/bootstrap/scss/_modal.scss */

        .modal-sm {
          max-width: 300px;
        }
      }

      @media (min-width: 992px) {
        /* line 221, node_modules/bootstrap/scss/_modal.scss */

        .modal-lg,
        .modal-xl {
          max-width: 800px;
        }
      }

      @media (min-width: 1200px) {
        /* line 228, node_modules/bootstrap/scss/_modal.scss */

        .modal-xl {
          max-width: 1140px;
        }
      }

      /* line 2, node_modules/bootstrap/scss/_tooltip.scss */

      .tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.875rem;
        word-wrap: break-word;
        opacity: 0;
      }

      /* line 15, node_modules/bootstrap/scss/_tooltip.scss */

      .tooltip.show {
        opacity: 0.9;
      }

      /* line 17, node_modules/bootstrap/scss/_tooltip.scss */

      .tooltip .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem;
      }

      /* line 23, node_modules/bootstrap/scss/_tooltip.scss */

      .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
      }

      /* line 32, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-top,
      .bs-tooltip-auto[x-placement^="top"] {
        padding: 0.4rem 0;
      }

      /* line 35, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-top .arrow,
      .bs-tooltip-auto[x-placement^="top"] .arrow {
        bottom: 0;
      }

      /* line 38, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-top .arrow::before,
      .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000;
      }

      /* line 46, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-right,
      .bs-tooltip-auto[x-placement^="right"] {
        padding: 0 0.4rem;
      }

      /* line 49, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-right .arrow,
      .bs-tooltip-auto[x-placement^="right"] .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem;
      }

      /* line 54, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-right .arrow::before,
      .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000;
      }

      /* line 62, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-bottom,
      .bs-tooltip-auto[x-placement^="bottom"] {
        padding: 0.4rem 0;
      }

      /* line 65, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-bottom .arrow,
      .bs-tooltip-auto[x-placement^="bottom"] .arrow {
        top: 0;
      }

      /* line 68, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-bottom .arrow::before,
      .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000;
      }

      /* line 76, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-left,
      .bs-tooltip-auto[x-placement^="left"] {
        padding: 0 0.4rem;
      }

      /* line 79, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-left .arrow,
      .bs-tooltip-auto[x-placement^="left"] .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem;
      }

      /* line 84, node_modules/bootstrap/scss/_tooltip.scss */

      .bs-tooltip-left .arrow::before,
      .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: #000;
      }

      /* line 108, node_modules/bootstrap/scss/_tooltip.scss */

      .tooltip-inner {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-align: center;
        background-color: #000;
        border-radius: 0.25rem;
      }

      /* line 1, node_modules/bootstrap/scss/_popover.scss */

      .popover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1060;
        display: block;
        max-width: 276px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.875rem;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
      }

      /* line 20, node_modules/bootstrap/scss/_popover.scss */

      .popover .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
      }

      /* line 27, node_modules/bootstrap/scss/_popover.scss */

      .popover .arrow::before,
      .popover .arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
      }

      /* line 38, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-top,
      .bs-popover-auto[x-placement^="top"] {
        margin-bottom: 0.5rem;
      }

      /* line 41, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-top > .arrow,
      .bs-popover-auto[x-placement^="top"] > .arrow {
        bottom: calc((0.5rem + 1px) * -1);
      }

      /* line 44, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-top > .arrow::before,
      .bs-popover-auto[x-placement^="top"] > .arrow::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25);
      }

      /* line 50, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-top > .arrow::after,
      .bs-popover-auto[x-placement^="top"] > .arrow::after {
        bottom: 1px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #fff;
      }

      /* line 58, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-right,
      .bs-popover-auto[x-placement^="right"] {
        margin-left: 0.5rem;
      }

      /* line 61, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-right > .arrow,
      .bs-popover-auto[x-placement^="right"] > .arrow {
        left: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0;
      }

      /* line 67, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-right > .arrow::before,
      .bs-popover-auto[x-placement^="right"] > .arrow::before {
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, 0.25);
      }

      /* line 73, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-right > .arrow::after,
      .bs-popover-auto[x-placement^="right"] > .arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff;
      }

      /* line 81, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-bottom,
      .bs-popover-auto[x-placement^="bottom"] {
        margin-top: 0.5rem;
      }

      /* line 84, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-bottom > .arrow,
      .bs-popover-auto[x-placement^="bottom"] > .arrow {
        top: calc((0.5rem + 1px) * -1);
      }

      /* line 87, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-bottom > .arrow::before,
      .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25);
      }

      /* line 93, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-bottom > .arrow::after,
      .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff;
      }

      /* line 101, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-bottom .popover-header::before,
      .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1rem;
        margin-left: -0.5rem;
        content: "";
        border-bottom: 1px solid #f7f7f7;
      }

      /* line 113, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-left,
      .bs-popover-auto[x-placement^="left"] {
        margin-right: 0.5rem;
      }

      /* line 116, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-left > .arrow,
      .bs-popover-auto[x-placement^="left"] > .arrow {
        right: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0;
      }

      /* line 122, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-left > .arrow::before,
      .bs-popover-auto[x-placement^="left"] > .arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25);
      }

      /* line 128, node_modules/bootstrap/scss/_popover.scss */

      .bs-popover-left > .arrow::after,
      .bs-popover-auto[x-placement^="left"] > .arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff;
      }

      /* line 153, node_modules/bootstrap/scss/_popover.scss */

      .popover-header {
        padding: 0.5rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        background-color: #f7f7f7;
        border-bottom: 1px solid #ebebeb;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
      }

      /* line 163, node_modules/bootstrap/scss/_popover.scss */

      .popover-header:empty {
        display: none;
      }

      /* line 168, node_modules/bootstrap/scss/_popover.scss */

      .popover-body {
        padding: 0.5rem 0.75rem;
        color: #212529;
      }

      /* line 14, node_modules/bootstrap/scss/_carousel.scss */

      .carousel {
        position: relative;
      }

      /* line 18, node_modules/bootstrap/scss/_carousel.scss */

      .carousel.pointer-event {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
      }

      /* line 22, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
      }

      /* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */

      .carousel-inner::after {
        display: block;
        clear: both;
        content: "";
      }

      /* line 29, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-item {
        position: relative;
        display: none;
        float: left;
        width: 100%;
        margin-right: -100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        -o-transition: -o-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out, -o-transform 0.6s ease-in-out;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 29, node_modules/bootstrap/scss/_carousel.scss */

        .carousel-item {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 39, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-item.active,
      .carousel-item-next,
      .carousel-item-prev {
        display: block;
      }

      /* line 45, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-item-next:not(.carousel-item-left),
      .active.carousel-item-right {
        -webkit-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
      }

      /* line 50, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-item-prev:not(.carousel-item-right),
      .active.carousel-item-left {
        -webkit-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
      }

      /* line 61, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-fade .carousel-item {
        opacity: 0;
        -webkit-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
      }

      /* line 67, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-fade .carousel-item.active,
      .carousel-fade .carousel-item-next.carousel-item-left,
      .carousel-fade .carousel-item-prev.carousel-item-right {
        z-index: 1;
        opacity: 1;
      }

      /* line 74, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-fade .active.carousel-item-left,
      .carousel-fade .active.carousel-item-right {
        z-index: 0;
        opacity: 0;
        -webkit-transition: 0s 0.6s opacity;
        -o-transition: 0s 0.6s opacity;
        transition: 0s 0.6s opacity;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 74, node_modules/bootstrap/scss/_carousel.scss */

        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-right {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 87, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-control-prev,
      .carousel-control-next {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 15%;
        color: #fff;
        text-align: center;
        opacity: 0.5;
        -webkit-transition: opacity 0.15s ease;
        -o-transition: opacity 0.15s ease;
        transition: opacity 0.15s ease;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 87, node_modules/bootstrap/scss/_carousel.scss */

        .carousel-control-prev,
        .carousel-control-next {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      .carousel-control-prev:hover,
      .carousel-control-prev:focus,
      .carousel-control-next:hover,
      .carousel-control-next:focus {
        color: #fff;
        text-decoration: none;
        outline: 0;
        opacity: 0.9;
      }

      /* line 111, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-control-prev {
        left: 0;
      }

      /* line 117, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-control-next {
        right: 0;
      }

      /* line 125, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: no-repeat 50% / 100% 100%;
      }

      /* line 132, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
      }

      /* line 135, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
      }

      /* line 145, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 15;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
      }

      /* line 159, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-indicators li {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: .5;
        -webkit-transition: opacity 0.6s ease;
        -o-transition: opacity 0.6s ease;
        transition: opacity 0.6s ease;
      }

      @media (prefers-reduced-motion: reduce) {
        /* line 159, node_modules/bootstrap/scss/_carousel.scss */

        .carousel-indicators li {
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
        }
      }

      /* line 177, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-indicators .active {
        opacity: 1;
      }

      /* line 187, node_modules/bootstrap/scss/_carousel.scss */

      .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 20px;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
      }

      @-webkit-keyframes spinner-border {
        to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @-o-keyframes spinner-border {
        to {
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes spinner-border {
        to {
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      /* line 9, node_modules/bootstrap/scss/_spinners.scss */

      .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border .75s linear infinite;
        -o-animation: spinner-border .75s linear infinite;
        animation: spinner-border .75s linear infinite;
      }

      /* line 21, node_modules/bootstrap/scss/_spinners.scss */

      .spinner-border-sm {
        width: 1rem;
        height: 1rem;
        border-width: 0.2em;
      }

      @-webkit-keyframes spinner-grow {
        0% {
          -webkit-transform: scale(0);
          transform: scale(0);
        }

        50% {
          opacity: 1;
        }
      }

      @-o-keyframes spinner-grow {
        0% {
          -o-transform: scale(0);
          transform: scale(0);
        }

        50% {
          opacity: 1;
        }
      }

      @keyframes spinner-grow {
        0% {
          -webkit-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);
        }

        50% {
          opacity: 1;
        }
      }

      /* line 40, node_modules/bootstrap/scss/_spinners.scss */

      .spinner-grow {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        background-color: currentColor;
        border-radius: 50%;
        opacity: 0;
        -webkit-animation: spinner-grow .75s linear infinite;
        -o-animation: spinner-grow .75s linear infinite;
        animation: spinner-grow .75s linear infinite;
      }

      /* line 52, node_modules/bootstrap/scss/_spinners.scss */

      .spinner-grow-sm {
        width: 1rem;
        height: 1rem;
      }

      /* line 3, node_modules/bootstrap/scss/utilities/_align.scss */

      .align-baseline {
        vertical-align: baseline !important;
      }

      /* line 4, node_modules/bootstrap/scss/utilities/_align.scss */

      .align-top {
        vertical-align: top !important;
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_align.scss */

      .align-middle {
        vertical-align: middle !important;
      }

      /* line 6, node_modules/bootstrap/scss/utilities/_align.scss */

      .align-bottom {
        vertical-align: bottom !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_align.scss */

      .align-text-bottom {
        vertical-align: text-bottom !important;
      }

      /* line 8, node_modules/bootstrap/scss/utilities/_align.scss */

      .align-text-top {
        vertical-align: text-top !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-primary {
        background-color: #525ddc !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-primary:hover,
      a.bg-primary:focus,
      button.bg-primary:hover,
      button.bg-primary:focus {
        background-color: #2a38d1 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-secondary {
        background-color: #6c757d !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-secondary:hover,
      a.bg-secondary:focus,
      button.bg-secondary:hover,
      button.bg-secondary:focus {
        background-color: #545b62 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-success {
        background-color: #28a745 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-success:hover,
      a.bg-success:focus,
      button.bg-success:hover,
      button.bg-success:focus {
        background-color: #1e7e34 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-info {
        background-color: #17a2b8 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-info:hover,
      a.bg-info:focus,
      button.bg-info:hover,
      button.bg-info:focus {
        background-color: #117a8b !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-warning {
        background-color: #ffc107 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-warning:hover,
      a.bg-warning:focus,
      button.bg-warning:hover,
      button.bg-warning:focus {
        background-color: #d39e00 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-danger {
        background-color: #dc3545 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-danger:hover,
      a.bg-danger:focus,
      button.bg-danger:hover,
      button.bg-danger:focus {
        background-color: #bd2130 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-light {
        background-color: #f8f9fa !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-light:hover,
      a.bg-light:focus,
      button.bg-light:hover,
      button.bg-light:focus {
        background-color: #dae0e5 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

      .bg-dark {
        background-color: #343a40 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.bg-dark:hover,
      a.bg-dark:focus,
      button.bg-dark:hover,
      button.bg-dark:focus {
        background-color: #1d2124 !important;
      }

      /* line 13, node_modules/bootstrap/scss/utilities/_background.scss */

      .bg-white {
        background-color: #fff !important;
      }

      /* line 17, node_modules/bootstrap/scss/utilities/_background.scss */

      .bg-transparent {
        background-color: transparent !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border {
        border: 1px solid #dee2e6 !important;
      }

      /* line 8, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-top {
        border-top: 1px solid #dee2e6 !important;
      }

      /* line 9, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-right {
        border-right: 1px solid #dee2e6 !important;
      }

      /* line 10, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-bottom {
        border-bottom: 1px solid #dee2e6 !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-left {
        border-left: 1px solid #dee2e6 !important;
      }

      /* line 13, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-0 {
        border: 0 !important;
      }

      /* line 14, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-top-0 {
        border-top: 0 !important;
      }

      /* line 15, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-right-0 {
        border-right: 0 !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-bottom-0 {
        border-bottom: 0 !important;
      }

      /* line 17, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-left-0 {
        border-left: 0 !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-primary {
        border-color: #525ddc !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-secondary {
        border-color: #6c757d !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-success {
        border-color: #28a745 !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-info {
        border-color: #17a2b8 !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-warning {
        border-color: #ffc107 !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-danger {
        border-color: #dc3545 !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-light {
        border-color: #f8f9fa !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-dark {
        border-color: #343a40 !important;
      }

      /* line 25, node_modules/bootstrap/scss/utilities/_borders.scss */

      .border-white {
        border-color: #fff !important;
      }

      /* line 33, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-sm {
        border-radius: 0.2rem !important;
      }

      /* line 37, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded {
        border-radius: 0.25rem !important;
      }

      /* line 41, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-top {
        border-top-left-radius: 0.25rem !important;
        border-top-right-radius: 0.25rem !important;
      }

      /* line 46, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-right {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
      }

      /* line 51, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-bottom {
        border-bottom-right-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
      }

      /* line 56, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-left {
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
      }

      /* line 61, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-lg {
        border-radius: 0.3rem !important;
      }

      /* line 65, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-circle {
        border-radius: 50% !important;
      }

      /* line 69, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-pill {
        border-radius: 50rem !important;
      }

      /* line 73, node_modules/bootstrap/scss/utilities/_borders.scss */

      .rounded-0 {
        border-radius: 0 !important;
      }

      /* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */

      .clearfix::after {
        display: block;
        clear: both;
        content: "";
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-none {
        display: none !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-inline {
        display: inline !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-inline-block {
        display: inline-block !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-block {
        display: block !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-table {
        display: table !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-table-row {
        display: table-row !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-table-cell {
        display: table-cell !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

      .d-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
      }

      @media (min-width: 576px) {
        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-none {
          display: none !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-inline {
          display: inline !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-inline-block {
          display: inline-block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-block {
          display: block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-table {
          display: table !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-table-row {
          display: table-row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-table-cell {
          display: table-cell !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-flex {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-sm-inline-flex {
          display: -webkit-inline-box !important;
          display: -ms-inline-flexbox !important;
          display: inline-flex !important;
        }
      }

      @media (min-width: 768px) {
        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-none {
          display: none !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-inline {
          display: inline !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-inline-block {
          display: inline-block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-block {
          display: block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-table {
          display: table !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-table-row {
          display: table-row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-table-cell {
          display: table-cell !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-flex {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-md-inline-flex {
          display: -webkit-inline-box !important;
          display: -ms-inline-flexbox !important;
          display: inline-flex !important;
        }
      }

      @media (min-width: 992px) {
        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-none {
          display: none !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-inline {
          display: inline !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-inline-block {
          display: inline-block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-block {
          display: block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-table {
          display: table !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-table-row {
          display: table-row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-table-cell {
          display: table-cell !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-flex {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-lg-inline-flex {
          display: -webkit-inline-box !important;
          display: -ms-inline-flexbox !important;
          display: inline-flex !important;
        }
      }

      @media (min-width: 1200px) {
        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-none {
          display: none !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-inline {
          display: inline !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-inline-block {
          display: inline-block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-block {
          display: block !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-table {
          display: table !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-table-row {
          display: table-row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-table-cell {
          display: table-cell !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-flex {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-xl-inline-flex {
          display: -webkit-inline-box !important;
          display: -ms-inline-flexbox !important;
          display: inline-flex !important;
        }
      }

      @media print {
        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-none {
          display: none !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-inline {
          display: inline !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-inline-block {
          display: inline-block !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-block {
          display: block !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-table {
          display: table !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-table-row {
          display: table-row !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-table-cell {
          display: table-cell !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-flex {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

        .d-print-inline-flex {
          display: -webkit-inline-box !important;
          display: -ms-inline-flexbox !important;
          display: inline-flex !important;
        }
      }

      /* line 3, node_modules/bootstrap/scss/utilities/_embed.scss */

      .embed-responsive {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
      }

      /* line 10, node_modules/bootstrap/scss/utilities/_embed.scss */

      .embed-responsive::before {
        display: block;
        content: "";
      }

      /* line 15, node_modules/bootstrap/scss/utilities/_embed.scss */

      .embed-responsive .embed-responsive-item,
      .embed-responsive iframe,
      .embed-responsive embed,
      .embed-responsive object,
      .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

      .embed-responsive-21by9::before {
        padding-top: 42.85714%;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

      .embed-responsive-16by9::before {
        padding-top: 56.25%;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

      .embed-responsive-4by3::before {
        padding-top: 75%;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

      .embed-responsive-1by1::before {
        padding-top: 100%;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
      }

      /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
      }

      /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
      }

      /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
      }

      /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
      }

      /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
      }

      /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
      }

      /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
      }

      /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

      .flex-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
      }

      /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

      .justify-content-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
      }

      /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

      .justify-content-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
      }

      /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

      .justify-content-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
      }

      /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

      .justify-content-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
      }

      /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

      .justify-content-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
      }

      /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-items-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
      }

      /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-items-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
      }

      /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-items-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
      }

      /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-items-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-items-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
      }

      /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-content-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
      }

      /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-content-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
      }

      /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-content-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
      }

      /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-content-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
      }

      /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-content-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
      }

      /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-content-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
      }

      /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-self-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
      }

      /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-self-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
      }

      /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-self-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
      }

      /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-self-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
      }

      /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-self-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
      }

      /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

      .align-self-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
      }

      @media (min-width: 576px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-row {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: row !important;
          flex-direction: row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-column {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
          flex-direction: column !important;
        }

        /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-row-reverse {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
        }

        /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-column-reverse {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-wrap {
          -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
        }

        /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-nowrap {
          -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
        }

        /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-wrap-reverse {
          -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
        }

        /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-fill {
          -webkit-box-flex: 1 !important;
          -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-grow-0 {
          -webkit-box-flex: 0 !important;
          -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
        }

        /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-grow-1 {
          -webkit-box-flex: 1 !important;
          -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
        }

        /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-shrink-0 {
          -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-sm-shrink-1 {
          -ms-flex-negative: 1 !important;
          flex-shrink: 1 !important;
        }

        /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-sm-start {
          -webkit-box-pack: start !important;
          -ms-flex-pack: start !important;
          justify-content: flex-start !important;
        }

        /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-sm-end {
          -webkit-box-pack: end !important;
          -ms-flex-pack: end !important;
          justify-content: flex-end !important;
        }

        /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-sm-center {
          -webkit-box-pack: center !important;
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }

        /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-sm-between {
          -webkit-box-pack: justify !important;
          -ms-flex-pack: justify !important;
          justify-content: space-between !important;
        }

        /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-sm-around {
          -ms-flex-pack: distribute !important;
          justify-content: space-around !important;
        }

        /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-sm-start {
          -webkit-box-align: start !important;
          -ms-flex-align: start !important;
          align-items: flex-start !important;
        }

        /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-sm-end {
          -webkit-box-align: end !important;
          -ms-flex-align: end !important;
          align-items: flex-end !important;
        }

        /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-sm-center {
          -webkit-box-align: center !important;
          -ms-flex-align: center !important;
          align-items: center !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-sm-baseline {
          -webkit-box-align: baseline !important;
          -ms-flex-align: baseline !important;
          align-items: baseline !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-sm-stretch {
          -webkit-box-align: stretch !important;
          -ms-flex-align: stretch !important;
          align-items: stretch !important;
        }

        /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-sm-start {
          -ms-flex-line-pack: start !important;
          align-content: flex-start !important;
        }

        /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-sm-end {
          -ms-flex-line-pack: end !important;
          align-content: flex-end !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-sm-center {
          -ms-flex-line-pack: center !important;
          align-content: center !important;
        }

        /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-sm-between {
          -ms-flex-line-pack: justify !important;
          align-content: space-between !important;
        }

        /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-sm-around {
          -ms-flex-line-pack: distribute !important;
          align-content: space-around !important;
        }

        /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-sm-stretch {
          -ms-flex-line-pack: stretch !important;
          align-content: stretch !important;
        }

        /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-sm-auto {
          -ms-flex-item-align: auto !important;
          align-self: auto !important;
        }

        /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-sm-start {
          -ms-flex-item-align: start !important;
          align-self: flex-start !important;
        }

        /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-sm-end {
          -ms-flex-item-align: end !important;
          align-self: flex-end !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-sm-center {
          -ms-flex-item-align: center !important;
          align-self: center !important;
        }

        /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-sm-baseline {
          -ms-flex-item-align: baseline !important;
          align-self: baseline !important;
        }

        /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-sm-stretch {
          -ms-flex-item-align: stretch !important;
          align-self: stretch !important;
        }
      }

      @media (min-width: 768px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-row {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: row !important;
          flex-direction: row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-column {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
          flex-direction: column !important;
        }

        /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-row-reverse {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
        }

        /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-column-reverse {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-wrap {
          -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
        }

        /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-nowrap {
          -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
        }

        /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-wrap-reverse {
          -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
        }

        /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-fill {
          -webkit-box-flex: 1 !important;
          -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-grow-0 {
          -webkit-box-flex: 0 !important;
          -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
        }

        /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-grow-1 {
          -webkit-box-flex: 1 !important;
          -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
        }

        /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-shrink-0 {
          -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-md-shrink-1 {
          -ms-flex-negative: 1 !important;
          flex-shrink: 1 !important;
        }

        /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-md-start {
          -webkit-box-pack: start !important;
          -ms-flex-pack: start !important;
          justify-content: flex-start !important;
        }

        /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-md-end {
          -webkit-box-pack: end !important;
          -ms-flex-pack: end !important;
          justify-content: flex-end !important;
        }

        /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-md-center {
          -webkit-box-pack: center !important;
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }

        /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-md-between {
          -webkit-box-pack: justify !important;
          -ms-flex-pack: justify !important;
          justify-content: space-between !important;
        }

        /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-md-around {
          -ms-flex-pack: distribute !important;
          justify-content: space-around !important;
        }

        /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-md-start {
          -webkit-box-align: start !important;
          -ms-flex-align: start !important;
          align-items: flex-start !important;
        }

        /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-md-end {
          -webkit-box-align: end !important;
          -ms-flex-align: end !important;
          align-items: flex-end !important;
        }

        /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-md-center {
          -webkit-box-align: center !important;
          -ms-flex-align: center !important;
          align-items: center !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-md-baseline {
          -webkit-box-align: baseline !important;
          -ms-flex-align: baseline !important;
          align-items: baseline !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-md-stretch {
          -webkit-box-align: stretch !important;
          -ms-flex-align: stretch !important;
          align-items: stretch !important;
        }

        /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-md-start {
          -ms-flex-line-pack: start !important;
          align-content: flex-start !important;
        }

        /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-md-end {
          -ms-flex-line-pack: end !important;
          align-content: flex-end !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-md-center {
          -ms-flex-line-pack: center !important;
          align-content: center !important;
        }

        /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-md-between {
          -ms-flex-line-pack: justify !important;
          align-content: space-between !important;
        }

        /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-md-around {
          -ms-flex-line-pack: distribute !important;
          align-content: space-around !important;
        }

        /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-md-stretch {
          -ms-flex-line-pack: stretch !important;
          align-content: stretch !important;
        }

        /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-md-auto {
          -ms-flex-item-align: auto !important;
          align-self: auto !important;
        }

        /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-md-start {
          -ms-flex-item-align: start !important;
          align-self: flex-start !important;
        }

        /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-md-end {
          -ms-flex-item-align: end !important;
          align-self: flex-end !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-md-center {
          -ms-flex-item-align: center !important;
          align-self: center !important;
        }

        /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-md-baseline {
          -ms-flex-item-align: baseline !important;
          align-self: baseline !important;
        }

        /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-md-stretch {
          -ms-flex-item-align: stretch !important;
          align-self: stretch !important;
        }
      }

      @media (min-width: 992px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-row {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: row !important;
          flex-direction: row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-column {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
          flex-direction: column !important;
        }

        /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-row-reverse {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
        }

        /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-column-reverse {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-wrap {
          -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
        }

        /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-nowrap {
          -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
        }

        /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-wrap-reverse {
          -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
        }

        /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-fill {
          -webkit-box-flex: 1 !important;
          -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-grow-0 {
          -webkit-box-flex: 0 !important;
          -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
        }

        /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-grow-1 {
          -webkit-box-flex: 1 !important;
          -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
        }

        /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-shrink-0 {
          -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-lg-shrink-1 {
          -ms-flex-negative: 1 !important;
          flex-shrink: 1 !important;
        }

        /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-lg-start {
          -webkit-box-pack: start !important;
          -ms-flex-pack: start !important;
          justify-content: flex-start !important;
        }

        /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-lg-end {
          -webkit-box-pack: end !important;
          -ms-flex-pack: end !important;
          justify-content: flex-end !important;
        }

        /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-lg-center {
          -webkit-box-pack: center !important;
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }

        /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-lg-between {
          -webkit-box-pack: justify !important;
          -ms-flex-pack: justify !important;
          justify-content: space-between !important;
        }

        /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-lg-around {
          -ms-flex-pack: distribute !important;
          justify-content: space-around !important;
        }

        /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-lg-start {
          -webkit-box-align: start !important;
          -ms-flex-align: start !important;
          align-items: flex-start !important;
        }

        /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-lg-end {
          -webkit-box-align: end !important;
          -ms-flex-align: end !important;
          align-items: flex-end !important;
        }

        /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-lg-center {
          -webkit-box-align: center !important;
          -ms-flex-align: center !important;
          align-items: center !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-lg-baseline {
          -webkit-box-align: baseline !important;
          -ms-flex-align: baseline !important;
          align-items: baseline !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-lg-stretch {
          -webkit-box-align: stretch !important;
          -ms-flex-align: stretch !important;
          align-items: stretch !important;
        }

        /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-lg-start {
          -ms-flex-line-pack: start !important;
          align-content: flex-start !important;
        }

        /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-lg-end {
          -ms-flex-line-pack: end !important;
          align-content: flex-end !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-lg-center {
          -ms-flex-line-pack: center !important;
          align-content: center !important;
        }

        /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-lg-between {
          -ms-flex-line-pack: justify !important;
          align-content: space-between !important;
        }

        /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-lg-around {
          -ms-flex-line-pack: distribute !important;
          align-content: space-around !important;
        }

        /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-lg-stretch {
          -ms-flex-line-pack: stretch !important;
          align-content: stretch !important;
        }

        /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-lg-auto {
          -ms-flex-item-align: auto !important;
          align-self: auto !important;
        }

        /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-lg-start {
          -ms-flex-item-align: start !important;
          align-self: flex-start !important;
        }

        /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-lg-end {
          -ms-flex-item-align: end !important;
          align-self: flex-end !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-lg-center {
          -ms-flex-item-align: center !important;
          align-self: center !important;
        }

        /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-lg-baseline {
          -ms-flex-item-align: baseline !important;
          align-self: baseline !important;
        }

        /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-lg-stretch {
          -ms-flex-item-align: stretch !important;
          align-self: stretch !important;
        }
      }

      @media (min-width: 1200px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-row {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: row !important;
          flex-direction: row !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-column {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
          flex-direction: column !important;
        }

        /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-row-reverse {
          -webkit-box-orient: horizontal !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
        }

        /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-column-reverse {
          -webkit-box-orient: vertical !important;
          -webkit-box-direction: reverse !important;
          -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-wrap {
          -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
        }

        /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-nowrap {
          -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
        }

        /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-wrap-reverse {
          -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
        }

        /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-fill {
          -webkit-box-flex: 1 !important;
          -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-grow-0 {
          -webkit-box-flex: 0 !important;
          -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
        }

        /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-grow-1 {
          -webkit-box-flex: 1 !important;
          -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
        }

        /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-shrink-0 {
          -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

        .flex-xl-shrink-1 {
          -ms-flex-negative: 1 !important;
          flex-shrink: 1 !important;
        }

        /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-xl-start {
          -webkit-box-pack: start !important;
          -ms-flex-pack: start !important;
          justify-content: flex-start !important;
        }

        /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-xl-end {
          -webkit-box-pack: end !important;
          -ms-flex-pack: end !important;
          justify-content: flex-end !important;
        }

        /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-xl-center {
          -webkit-box-pack: center !important;
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }

        /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-xl-between {
          -webkit-box-pack: justify !important;
          -ms-flex-pack: justify !important;
          justify-content: space-between !important;
        }

        /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

        .justify-content-xl-around {
          -ms-flex-pack: distribute !important;
          justify-content: space-around !important;
        }

        /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-xl-start {
          -webkit-box-align: start !important;
          -ms-flex-align: start !important;
          align-items: flex-start !important;
        }

        /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-xl-end {
          -webkit-box-align: end !important;
          -ms-flex-align: end !important;
          align-items: flex-end !important;
        }

        /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-xl-center {
          -webkit-box-align: center !important;
          -ms-flex-align: center !important;
          align-items: center !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-xl-baseline {
          -webkit-box-align: baseline !important;
          -ms-flex-align: baseline !important;
          align-items: baseline !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-items-xl-stretch {
          -webkit-box-align: stretch !important;
          -ms-flex-align: stretch !important;
          align-items: stretch !important;
        }

        /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-xl-start {
          -ms-flex-line-pack: start !important;
          align-content: flex-start !important;
        }

        /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-xl-end {
          -ms-flex-line-pack: end !important;
          align-content: flex-end !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-xl-center {
          -ms-flex-line-pack: center !important;
          align-content: center !important;
        }

        /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-xl-between {
          -ms-flex-line-pack: justify !important;
          align-content: space-between !important;
        }

        /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-xl-around {
          -ms-flex-line-pack: distribute !important;
          align-content: space-around !important;
        }

        /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-content-xl-stretch {
          -ms-flex-line-pack: stretch !important;
          align-content: stretch !important;
        }

        /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-xl-auto {
          -ms-flex-item-align: auto !important;
          align-self: auto !important;
        }

        /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-xl-start {
          -ms-flex-item-align: start !important;
          align-self: flex-start !important;
        }

        /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-xl-end {
          -ms-flex-item-align: end !important;
          align-self: flex-end !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-xl-center {
          -ms-flex-item-align: center !important;
          align-self: center !important;
        }

        /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-xl-baseline {
          -ms-flex-item-align: baseline !important;
          align-self: baseline !important;
        }

        /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

        .align-self-xl-stretch {
          -ms-flex-item-align: stretch !important;
          align-self: stretch !important;
        }
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

      .float-left {
        float: left !important;
      }

      /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

      .float-right {
        float: right !important;
      }

      /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

      .float-none {
        float: none !important;
      }

      @media (min-width: 576px) {
        /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-sm-left {
          float: left !important;
        }

        /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-sm-right {
          float: right !important;
        }

        /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-sm-none {
          float: none !important;
        }
      }

      @media (min-width: 768px) {
        /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-md-left {
          float: left !important;
        }

        /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-md-right {
          float: right !important;
        }

        /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-md-none {
          float: none !important;
        }
      }

      @media (min-width: 992px) {
        /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-lg-left {
          float: left !important;
        }

        /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-lg-right {
          float: right !important;
        }

        /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-lg-none {
          float: none !important;
        }
      }

      @media (min-width: 1200px) {
        /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-xl-left {
          float: left !important;
        }

        /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-xl-right {
          float: right !important;
        }

        /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

        .float-xl-none {
          float: none !important;
        }
      }

      /* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */

      .overflow-auto {
        overflow: auto !important;
      }

      /* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */

      .overflow-hidden {
        overflow: hidden !important;
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

      .position-static {
        position: static !important;
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

      .position-relative {
        position: relative !important;
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

      .position-absolute {
        position: absolute !important;
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

      .position-fixed {
        position: fixed !important;
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

      .position-sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
      }

      /* line 10, node_modules/bootstrap/scss/utilities/_position.scss */

      .fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
      }

      /* line 18, node_modules/bootstrap/scss/utilities/_position.scss */

      .fixed-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
      }

      @supports ((position: -webkit-sticky) or (position: sticky)) {
        /* line 26, node_modules/bootstrap/scss/utilities/_position.scss */

        .sticky-top {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 1020;
        }
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_screenreaders.scss */

      .sr-only,
      .screen-reader-text {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }

      /* line 24, node_modules/bootstrap/scss/mixins/_screen-reader.scss */

      .sr-only-focusable:active,
      .screen-reader-text:active,
      .sr-only-focusable:focus,
      .screen-reader-text:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
      }

      /* line 3, node_modules/bootstrap/scss/utilities/_shadows.scss */

      .shadow-sm {
        -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      }

      /* line 4, node_modules/bootstrap/scss/utilities/_shadows.scss */

      .shadow {
        -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      }

      /* line 5, node_modules/bootstrap/scss/utilities/_shadows.scss */

      .shadow-lg {
        -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
      }

      /* line 6, node_modules/bootstrap/scss/utilities/_shadows.scss */

      .shadow-none {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .w-25 {
        width: 25% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .w-50 {
        width: 50% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .w-75 {
        width: 75% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .w-100 {
        width: 100% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .w-auto {
        width: auto !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .h-25 {
        height: 25% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .h-50 {
        height: 50% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .h-75 {
        height: 75% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .h-100 {
        height: 100% !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .h-auto {
        height: auto !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .mw-100 {
        max-width: 100% !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .mh-100 {
        max-height: 100% !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .min-vw-100 {
        min-width: 100vw !important;
      }

      /* line 17, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .min-vh-100 {
        min-height: 100vh !important;
      }

      /* line 19, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .vw-100 {
        width: 100vw !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_sizing.scss */

      .vh-100 {
        height: 100vh !important;
      }

      /* line 6, node_modules/bootstrap/scss/utilities/_stretched-link.scss */

      .stretched-link::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
        background-color: rgba(0, 0, 0, 0);
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-0 {
        margin: 0 !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-0,
      .my-0 {
        margin-top: 0 !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-0,
      .mx-0 {
        margin-right: 0 !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-0,
      .my-0 {
        margin-bottom: 0 !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-0,
      .mx-0 {
        margin-left: 0 !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-1 {
        margin: 0.25rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-1,
      .my-1 {
        margin-top: 0.25rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-1,
      .mx-1 {
        margin-right: 0.25rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-1,
      .my-1 {
        margin-bottom: 0.25rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-1,
      .mx-1 {
        margin-left: 0.25rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-2 {
        margin: 0.5rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-2,
      .my-2 {
        margin-top: 0.5rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-2,
      .mx-2 {
        margin-right: 0.5rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-2,
      .my-2 {
        margin-bottom: 0.5rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-2,
      .mx-2 {
        margin-left: 0.5rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-3 {
        margin: 1rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-3,
      .my-3 {
        margin-top: 1rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-3,
      .mx-3 {
        margin-right: 1rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-3,
      .my-3 {
        margin-bottom: 1rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-3,
      .mx-3 {
        margin-left: 1rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-4 {
        margin: 1.5rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-4,
      .my-4 {
        margin-top: 1.5rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-4,
      .mx-4 {
        margin-right: 1.5rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-4,
      .my-4 {
        margin-bottom: 1.5rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-4,
      .mx-4 {
        margin-left: 1.5rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-5 {
        margin: 3rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-5,
      .my-5 {
        margin-top: 3rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-5,
      .mx-5 {
        margin-right: 3rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-5,
      .my-5 {
        margin-bottom: 3rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-5,
      .mx-5 {
        margin-left: 3rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .p-0 {
        padding: 0 !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pt-0,
      .py-0 {
        padding-top: 0 !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pr-0,
      .px-0 {
        padding-right: 0 !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pb-0,
      .py-0 {
        padding-bottom: 0 !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pl-0,
      .px-0 {
        padding-left: 0 !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .p-1 {
        padding: 0.25rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pt-1,
      .py-1 {
        padding-top: 0.25rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pr-1,
      .px-1 {
        padding-right: 0.25rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pb-1,
      .py-1 {
        padding-bottom: 0.25rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pl-1,
      .px-1 {
        padding-left: 0.25rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .p-2 {
        padding: 0.5rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pt-2,
      .py-2 {
        padding-top: 0.5rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pr-2,
      .px-2 {
        padding-right: 0.5rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pb-2,
      .py-2 {
        padding-bottom: 0.5rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pl-2,
      .px-2 {
        padding-left: 0.5rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .p-3 {
        padding: 1rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pt-3,
      .py-3 {
        padding-top: 1rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pr-3,
      .px-3 {
        padding-right: 1rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pb-3,
      .py-3 {
        padding-bottom: 1rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pl-3,
      .px-3 {
        padding-left: 1rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .p-4 {
        padding: 1.5rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pt-4,
      .py-4 {
        padding-top: 1.5rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pr-4,
      .px-4 {
        padding-right: 1.5rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pb-4,
      .py-4 {
        padding-bottom: 1.5rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pl-4,
      .px-4 {
        padding-left: 1.5rem !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .p-5 {
        padding: 3rem !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pt-5,
      .py-5 {
        padding-top: 3rem !important;
      }

      /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pr-5,
      .px-5 {
        padding-right: 3rem !important;
      }

      /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pb-5,
      .py-5 {
        padding-bottom: 3rem !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .pl-5,
      .px-5 {
        padding-left: 3rem !important;
      }

      /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-n1 {
        margin: -0.25rem !important;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-n1,
      .my-n1 {
        margin-top: -0.25rem !important;
      }

      /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-n1,
      .mx-n1 {
        margin-right: -0.25rem !important;
      }

      /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-n1,
      .my-n1 {
        margin-bottom: -0.25rem !important;
      }

      /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-n1,
      .mx-n1 {
        margin-left: -0.25rem !important;
      }

      /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-n2 {
        margin: -0.5rem !important;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-n2,
      .my-n2 {
        margin-top: -0.5rem !important;
      }

      /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-n2,
      .mx-n2 {
        margin-right: -0.5rem !important;
      }

      /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-n2,
      .my-n2 {
        margin-bottom: -0.5rem !important;
      }

      /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-n2,
      .mx-n2 {
        margin-left: -0.5rem !important;
      }

      /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-n3 {
        margin: -1rem !important;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-n3,
      .my-n3 {
        margin-top: -1rem !important;
      }

      /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-n3,
      .mx-n3 {
        margin-right: -1rem !important;
      }

      /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-n3,
      .my-n3 {
        margin-bottom: -1rem !important;
      }

      /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-n3,
      .mx-n3 {
        margin-left: -1rem !important;
      }

      /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-n4 {
        margin: -1.5rem !important;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-n4,
      .my-n4 {
        margin-top: -1.5rem !important;
      }

      /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-n4,
      .mx-n4 {
        margin-right: -1.5rem !important;
      }

      /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-n4,
      .my-n4 {
        margin-bottom: -1.5rem !important;
      }

      /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-n4,
      .mx-n4 {
        margin-left: -1.5rem !important;
      }

      /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-n5 {
        margin: -3rem !important;
      }

      /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-n5,
      .my-n5 {
        margin-top: -3rem !important;
      }

      /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-n5,
      .mx-n5 {
        margin-right: -3rem !important;
      }

      /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-n5,
      .my-n5 {
        margin-bottom: -3rem !important;
      }

      /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-n5,
      .mx-n5 {
        margin-left: -3rem !important;
      }

      /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .m-auto {
        margin: auto !important;
      }

      /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mt-auto,
      .my-auto {
        margin-top: auto !important;
      }

      /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mr-auto,
      .mx-auto {
        margin-right: auto !important;
      }

      /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .mb-auto,
      .my-auto {
        margin-bottom: auto !important;
      }

      /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

      .ml-auto,
      .mx-auto {
        margin-left: auto !important;
      }

      @media (min-width: 576px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-0 {
          margin: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-0,
        .my-sm-0 {
          margin-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-0,
        .mx-sm-0 {
          margin-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-0,
        .my-sm-0 {
          margin-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-0,
        .mx-sm-0 {
          margin-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-1 {
          margin: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-1,
        .my-sm-1 {
          margin-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-1,
        .mx-sm-1 {
          margin-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-1,
        .my-sm-1 {
          margin-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-1,
        .mx-sm-1 {
          margin-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-2 {
          margin: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-2,
        .my-sm-2 {
          margin-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-2,
        .mx-sm-2 {
          margin-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-2,
        .my-sm-2 {
          margin-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-2,
        .mx-sm-2 {
          margin-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-3 {
          margin: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-3,
        .my-sm-3 {
          margin-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-3,
        .mx-sm-3 {
          margin-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-3,
        .my-sm-3 {
          margin-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-3,
        .mx-sm-3 {
          margin-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-4 {
          margin: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-4,
        .my-sm-4 {
          margin-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-4,
        .mx-sm-4 {
          margin-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-4,
        .my-sm-4 {
          margin-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-4,
        .mx-sm-4 {
          margin-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-5 {
          margin: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-5,
        .my-sm-5 {
          margin-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-5,
        .mx-sm-5 {
          margin-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-5,
        .my-sm-5 {
          margin-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-5,
        .mx-sm-5 {
          margin-left: 3rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-sm-0 {
          padding: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-sm-0,
        .py-sm-0 {
          padding-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-sm-0,
        .px-sm-0 {
          padding-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-sm-0,
        .py-sm-0 {
          padding-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-sm-0,
        .px-sm-0 {
          padding-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-sm-1 {
          padding: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-sm-1,
        .py-sm-1 {
          padding-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-sm-1,
        .px-sm-1 {
          padding-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-sm-1,
        .py-sm-1 {
          padding-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-sm-1,
        .px-sm-1 {
          padding-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-sm-2 {
          padding: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-sm-2,
        .py-sm-2 {
          padding-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-sm-2,
        .px-sm-2 {
          padding-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-sm-2,
        .py-sm-2 {
          padding-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-sm-2,
        .px-sm-2 {
          padding-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-sm-3 {
          padding: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-sm-3,
        .py-sm-3 {
          padding-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-sm-3,
        .px-sm-3 {
          padding-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-sm-3,
        .py-sm-3 {
          padding-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-sm-3,
        .px-sm-3 {
          padding-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-sm-4 {
          padding: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-sm-4,
        .py-sm-4 {
          padding-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-sm-4,
        .px-sm-4 {
          padding-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-sm-4,
        .py-sm-4 {
          padding-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-sm-4,
        .px-sm-4 {
          padding-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-sm-5 {
          padding: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-sm-5,
        .py-sm-5 {
          padding-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-sm-5,
        .px-sm-5 {
          padding-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-sm-5,
        .py-sm-5 {
          padding-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-sm-5,
        .px-sm-5 {
          padding-left: 3rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-n1 {
          margin: -0.25rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-n1,
        .my-sm-n1 {
          margin-top: -0.25rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-n1,
        .mx-sm-n1 {
          margin-right: -0.25rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-n1,
        .my-sm-n1 {
          margin-bottom: -0.25rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-n1,
        .mx-sm-n1 {
          margin-left: -0.25rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-n2 {
          margin: -0.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-n2,
        .my-sm-n2 {
          margin-top: -0.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-n2,
        .mx-sm-n2 {
          margin-right: -0.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-n2,
        .my-sm-n2 {
          margin-bottom: -0.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-n2,
        .mx-sm-n2 {
          margin-left: -0.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-n3 {
          margin: -1rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-n3,
        .my-sm-n3 {
          margin-top: -1rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-n3,
        .mx-sm-n3 {
          margin-right: -1rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-n3,
        .my-sm-n3 {
          margin-bottom: -1rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-n3,
        .mx-sm-n3 {
          margin-left: -1rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-n4 {
          margin: -1.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-n4,
        .my-sm-n4 {
          margin-top: -1.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-n4,
        .mx-sm-n4 {
          margin-right: -1.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-n4,
        .my-sm-n4 {
          margin-bottom: -1.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-n4,
        .mx-sm-n4 {
          margin-left: -1.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-n5 {
          margin: -3rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-n5,
        .my-sm-n5 {
          margin-top: -3rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-n5,
        .mx-sm-n5 {
          margin-right: -3rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-n5,
        .my-sm-n5 {
          margin-bottom: -3rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-n5,
        .mx-sm-n5 {
          margin-left: -3rem !important;
        }

        /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-sm-auto {
          margin: auto !important;
        }

        /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-sm-auto,
        .my-sm-auto {
          margin-top: auto !important;
        }

        /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-sm-auto,
        .mx-sm-auto {
          margin-right: auto !important;
        }

        /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-sm-auto,
        .my-sm-auto {
          margin-bottom: auto !important;
        }

        /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-sm-auto,
        .mx-sm-auto {
          margin-left: auto !important;
        }
      }

      @media (min-width: 768px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-0 {
          margin: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-0,
        .my-md-0 {
          margin-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-0,
        .mx-md-0 {
          margin-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-0,
        .my-md-0 {
          margin-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-0,
        .mx-md-0 {
          margin-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-1 {
          margin: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-1,
        .my-md-1 {
          margin-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-1,
        .mx-md-1 {
          margin-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-1,
        .my-md-1 {
          margin-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-1,
        .mx-md-1 {
          margin-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-2 {
          margin: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-2,
        .my-md-2 {
          margin-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-2,
        .mx-md-2 {
          margin-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-2,
        .my-md-2 {
          margin-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-2,
        .mx-md-2 {
          margin-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-3 {
          margin: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-3,
        .my-md-3 {
          margin-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-3,
        .mx-md-3 {
          margin-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-3,
        .my-md-3 {
          margin-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-3,
        .mx-md-3 {
          margin-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-4 {
          margin: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-4,
        .my-md-4 {
          margin-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-4,
        .mx-md-4 {
          margin-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-4,
        .my-md-4 {
          margin-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-4,
        .mx-md-4 {
          margin-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-5 {
          margin: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-5,
        .my-md-5 {
          margin-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-5,
        .mx-md-5 {
          margin-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-5,
        .my-md-5 {
          margin-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-5,
        .mx-md-5 {
          margin-left: 3rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-md-0 {
          padding: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-md-0,
        .py-md-0 {
          padding-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-md-0,
        .px-md-0 {
          padding-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-md-0,
        .py-md-0 {
          padding-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-md-0,
        .px-md-0 {
          padding-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-md-1 {
          padding: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-md-1,
        .py-md-1 {
          padding-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-md-1,
        .px-md-1 {
          padding-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-md-1,
        .py-md-1 {
          padding-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-md-1,
        .px-md-1 {
          padding-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-md-2 {
          padding: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-md-2,
        .py-md-2 {
          padding-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-md-2,
        .px-md-2 {
          padding-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-md-2,
        .py-md-2 {
          padding-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-md-2,
        .px-md-2 {
          padding-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-md-3 {
          padding: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-md-3,
        .py-md-3 {
          padding-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-md-3,
        .px-md-3 {
          padding-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-md-3,
        .py-md-3 {
          padding-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-md-3,
        .px-md-3 {
          padding-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-md-4 {
          padding: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-md-4,
        .py-md-4 {
          padding-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-md-4,
        .px-md-4 {
          padding-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-md-4,
        .py-md-4 {
          padding-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-md-4,
        .px-md-4 {
          padding-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-md-5 {
          padding: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-md-5,
        .py-md-5 {
          padding-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-md-5,
        .px-md-5 {
          padding-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-md-5,
        .py-md-5 {
          padding-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-md-5,
        .px-md-5 {
          padding-left: 3rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-n1 {
          margin: -0.25rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-n1,
        .my-md-n1 {
          margin-top: -0.25rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-n1,
        .mx-md-n1 {
          margin-right: -0.25rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-n1,
        .my-md-n1 {
          margin-bottom: -0.25rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-n1,
        .mx-md-n1 {
          margin-left: -0.25rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-n2 {
          margin: -0.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-n2,
        .my-md-n2 {
          margin-top: -0.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-n2,
        .mx-md-n2 {
          margin-right: -0.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-n2,
        .my-md-n2 {
          margin-bottom: -0.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-n2,
        .mx-md-n2 {
          margin-left: -0.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-n3 {
          margin: -1rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-n3,
        .my-md-n3 {
          margin-top: -1rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-n3,
        .mx-md-n3 {
          margin-right: -1rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-n3,
        .my-md-n3 {
          margin-bottom: -1rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-n3,
        .mx-md-n3 {
          margin-left: -1rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-n4 {
          margin: -1.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-n4,
        .my-md-n4 {
          margin-top: -1.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-n4,
        .mx-md-n4 {
          margin-right: -1.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-n4,
        .my-md-n4 {
          margin-bottom: -1.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-n4,
        .mx-md-n4 {
          margin-left: -1.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-n5 {
          margin: -3rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-n5,
        .my-md-n5 {
          margin-top: -3rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-n5,
        .mx-md-n5 {
          margin-right: -3rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-n5,
        .my-md-n5 {
          margin-bottom: -3rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-n5,
        .mx-md-n5 {
          margin-left: -3rem !important;
        }

        /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-md-auto {
          margin: auto !important;
        }

        /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-md-auto,
        .my-md-auto {
          margin-top: auto !important;
        }

        /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-md-auto,
        .mx-md-auto {
          margin-right: auto !important;
        }

        /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-md-auto,
        .my-md-auto {
          margin-bottom: auto !important;
        }

        /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-md-auto,
        .mx-md-auto {
          margin-left: auto !important;
        }
      }

      @media (min-width: 992px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-0 {
          margin: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-0,
        .my-lg-0 {
          margin-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-0,
        .mx-lg-0 {
          margin-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-0,
        .my-lg-0 {
          margin-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-0,
        .mx-lg-0 {
          margin-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-1 {
          margin: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-1,
        .my-lg-1 {
          margin-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-1,
        .mx-lg-1 {
          margin-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-1,
        .my-lg-1 {
          margin-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-1,
        .mx-lg-1 {
          margin-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-2 {
          margin: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-2,
        .my-lg-2 {
          margin-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-2,
        .mx-lg-2 {
          margin-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-2,
        .my-lg-2 {
          margin-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-2,
        .mx-lg-2 {
          margin-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-3 {
          margin: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-3,
        .my-lg-3 {
          margin-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-3,
        .mx-lg-3 {
          margin-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-3,
        .my-lg-3 {
          margin-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-3,
        .mx-lg-3 {
          margin-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-4 {
          margin: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-4,
        .my-lg-4 {
          margin-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-4,
        .mx-lg-4 {
          margin-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-4,
        .my-lg-4 {
          margin-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-4,
        .mx-lg-4 {
          margin-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-5 {
          margin: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-5,
        .my-lg-5 {
          margin-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-5,
        .mx-lg-5 {
          margin-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-5,
        .my-lg-5 {
          margin-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-5,
        .mx-lg-5 {
          margin-left: 3rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-lg-0 {
          padding: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-lg-0,
        .py-lg-0 {
          padding-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-lg-0,
        .px-lg-0 {
          padding-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-lg-0,
        .py-lg-0 {
          padding-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-lg-0,
        .px-lg-0 {
          padding-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-lg-1 {
          padding: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-lg-1,
        .py-lg-1 {
          padding-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-lg-1,
        .px-lg-1 {
          padding-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-lg-1,
        .py-lg-1 {
          padding-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-lg-1,
        .px-lg-1 {
          padding-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-lg-2 {
          padding: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-lg-2,
        .py-lg-2 {
          padding-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-lg-2,
        .px-lg-2 {
          padding-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-lg-2,
        .py-lg-2 {
          padding-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-lg-2,
        .px-lg-2 {
          padding-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-lg-3 {
          padding: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-lg-3,
        .py-lg-3 {
          padding-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-lg-3,
        .px-lg-3 {
          padding-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-lg-3,
        .py-lg-3 {
          padding-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-lg-3,
        .px-lg-3 {
          padding-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-lg-4 {
          padding: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-lg-4,
        .py-lg-4 {
          padding-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-lg-4,
        .px-lg-4 {
          padding-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-lg-4,
        .py-lg-4 {
          padding-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-lg-4,
        .px-lg-4 {
          padding-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-lg-5 {
          padding: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-lg-5,
        .py-lg-5 {
          padding-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-lg-5,
        .px-lg-5 {
          padding-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-lg-5,
        .py-lg-5 {
          padding-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-lg-5,
        .px-lg-5 {
          padding-left: 3rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-n1 {
          margin: -0.25rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-n1,
        .my-lg-n1 {
          margin-top: -0.25rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-n1,
        .mx-lg-n1 {
          margin-right: -0.25rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-n1,
        .my-lg-n1 {
          margin-bottom: -0.25rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-n1,
        .mx-lg-n1 {
          margin-left: -0.25rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-n2 {
          margin: -0.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-n2,
        .my-lg-n2 {
          margin-top: -0.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-n2,
        .mx-lg-n2 {
          margin-right: -0.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-n2,
        .my-lg-n2 {
          margin-bottom: -0.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-n2,
        .mx-lg-n2 {
          margin-left: -0.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-n3 {
          margin: -1rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-n3,
        .my-lg-n3 {
          margin-top: -1rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-n3,
        .mx-lg-n3 {
          margin-right: -1rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-n3,
        .my-lg-n3 {
          margin-bottom: -1rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-n3,
        .mx-lg-n3 {
          margin-left: -1rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-n4 {
          margin: -1.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-n4,
        .my-lg-n4 {
          margin-top: -1.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-n4,
        .mx-lg-n4 {
          margin-right: -1.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-n4,
        .my-lg-n4 {
          margin-bottom: -1.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-n4,
        .mx-lg-n4 {
          margin-left: -1.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-n5 {
          margin: -3rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-n5,
        .my-lg-n5 {
          margin-top: -3rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-n5,
        .mx-lg-n5 {
          margin-right: -3rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-n5,
        .my-lg-n5 {
          margin-bottom: -3rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-n5,
        .mx-lg-n5 {
          margin-left: -3rem !important;
        }

        /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-lg-auto {
          margin: auto !important;
        }

        /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-lg-auto,
        .my-lg-auto {
          margin-top: auto !important;
        }

        /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-lg-auto,
        .mx-lg-auto {
          margin-right: auto !important;
        }

        /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-lg-auto,
        .my-lg-auto {
          margin-bottom: auto !important;
        }

        /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-lg-auto,
        .mx-lg-auto {
          margin-left: auto !important;
        }
      }

      @media (min-width: 1200px) {
        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-0 {
          margin: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-0,
        .my-xl-0 {
          margin-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-0,
        .mx-xl-0 {
          margin-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-0,
        .my-xl-0 {
          margin-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-0,
        .mx-xl-0 {
          margin-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-1 {
          margin: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-1,
        .my-xl-1 {
          margin-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-1,
        .mx-xl-1 {
          margin-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-1,
        .my-xl-1 {
          margin-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-1,
        .mx-xl-1 {
          margin-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-2 {
          margin: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-2,
        .my-xl-2 {
          margin-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-2,
        .mx-xl-2 {
          margin-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-2,
        .my-xl-2 {
          margin-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-2,
        .mx-xl-2 {
          margin-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-3 {
          margin: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-3,
        .my-xl-3 {
          margin-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-3,
        .mx-xl-3 {
          margin-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-3,
        .my-xl-3 {
          margin-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-3,
        .mx-xl-3 {
          margin-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-4 {
          margin: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-4,
        .my-xl-4 {
          margin-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-4,
        .mx-xl-4 {
          margin-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-4,
        .my-xl-4 {
          margin-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-4,
        .mx-xl-4 {
          margin-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-5 {
          margin: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-5,
        .my-xl-5 {
          margin-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-5,
        .mx-xl-5 {
          margin-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-5,
        .my-xl-5 {
          margin-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-5,
        .mx-xl-5 {
          margin-left: 3rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-xl-0 {
          padding: 0 !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-xl-0,
        .py-xl-0 {
          padding-top: 0 !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-xl-0,
        .px-xl-0 {
          padding-right: 0 !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-xl-0,
        .py-xl-0 {
          padding-bottom: 0 !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-xl-0,
        .px-xl-0 {
          padding-left: 0 !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-xl-1 {
          padding: 0.25rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-xl-1,
        .py-xl-1 {
          padding-top: 0.25rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-xl-1,
        .px-xl-1 {
          padding-right: 0.25rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-xl-1,
        .py-xl-1 {
          padding-bottom: 0.25rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-xl-1,
        .px-xl-1 {
          padding-left: 0.25rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-xl-2 {
          padding: 0.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-xl-2,
        .py-xl-2 {
          padding-top: 0.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-xl-2,
        .px-xl-2 {
          padding-right: 0.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-xl-2,
        .py-xl-2 {
          padding-bottom: 0.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-xl-2,
        .px-xl-2 {
          padding-left: 0.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-xl-3 {
          padding: 1rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-xl-3,
        .py-xl-3 {
          padding-top: 1rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-xl-3,
        .px-xl-3 {
          padding-right: 1rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-xl-3,
        .py-xl-3 {
          padding-bottom: 1rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-xl-3,
        .px-xl-3 {
          padding-left: 1rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-xl-4 {
          padding: 1.5rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-xl-4,
        .py-xl-4 {
          padding-top: 1.5rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-xl-4,
        .px-xl-4 {
          padding-right: 1.5rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-xl-4,
        .py-xl-4 {
          padding-bottom: 1.5rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-xl-4,
        .px-xl-4 {
          padding-left: 1.5rem !important;
        }

        /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .p-xl-5 {
          padding: 3rem !important;
        }

        /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pt-xl-5,
        .py-xl-5 {
          padding-top: 3rem !important;
        }

        /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pr-xl-5,
        .px-xl-5 {
          padding-right: 3rem !important;
        }

        /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pb-xl-5,
        .py-xl-5 {
          padding-bottom: 3rem !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .pl-xl-5,
        .px-xl-5 {
          padding-left: 3rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-n1 {
          margin: -0.25rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-n1,
        .my-xl-n1 {
          margin-top: -0.25rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-n1,
        .mx-xl-n1 {
          margin-right: -0.25rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-n1,
        .my-xl-n1 {
          margin-bottom: -0.25rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-n1,
        .mx-xl-n1 {
          margin-left: -0.25rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-n2 {
          margin: -0.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-n2,
        .my-xl-n2 {
          margin-top: -0.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-n2,
        .mx-xl-n2 {
          margin-right: -0.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-n2,
        .my-xl-n2 {
          margin-bottom: -0.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-n2,
        .mx-xl-n2 {
          margin-left: -0.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-n3 {
          margin: -1rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-n3,
        .my-xl-n3 {
          margin-top: -1rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-n3,
        .mx-xl-n3 {
          margin-right: -1rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-n3,
        .my-xl-n3 {
          margin-bottom: -1rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-n3,
        .mx-xl-n3 {
          margin-left: -1rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-n4 {
          margin: -1.5rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-n4,
        .my-xl-n4 {
          margin-top: -1.5rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-n4,
        .mx-xl-n4 {
          margin-right: -1.5rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-n4,
        .my-xl-n4 {
          margin-bottom: -1.5rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-n4,
        .mx-xl-n4 {
          margin-left: -1.5rem !important;
        }

        /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-n5 {
          margin: -3rem !important;
        }

        /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-n5,
        .my-xl-n5 {
          margin-top: -3rem !important;
        }

        /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-n5,
        .mx-xl-n5 {
          margin-right: -3rem !important;
        }

        /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-n5,
        .my-xl-n5 {
          margin-bottom: -3rem !important;
        }

        /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-n5,
        .mx-xl-n5 {
          margin-left: -3rem !important;
        }

        /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .m-xl-auto {
          margin: auto !important;
        }

        /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mt-xl-auto,
        .my-xl-auto {
          margin-top: auto !important;
        }

        /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mr-xl-auto,
        .mx-xl-auto {
          margin-right: auto !important;
        }

        /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .mb-xl-auto,
        .my-xl-auto {
          margin-bottom: auto !important;
        }

        /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

        .ml-xl-auto,
        .mx-xl-auto {
          margin-left: auto !important;
        }
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-monospace {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-justify {
        text-align: justify !important;
      }

      /* line 12, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-wrap {
        white-space: normal !important;
      }

      /* line 13, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-nowrap {
        white-space: nowrap !important;
      }

      /* line 14, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-left {
        text-align: left !important;
      }

      /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-right {
        text-align: right !important;
      }

      /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-center {
        text-align: center !important;
      }

      @media (min-width: 576px) {
        /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-sm-left {
          text-align: left !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-sm-right {
          text-align: right !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-sm-center {
          text-align: center !important;
        }
      }

      @media (min-width: 768px) {
        /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-md-left {
          text-align: left !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-md-right {
          text-align: right !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-md-center {
          text-align: center !important;
        }
      }

      @media (min-width: 992px) {
        /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-lg-left {
          text-align: left !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-lg-right {
          text-align: right !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-lg-center {
          text-align: center !important;
        }
      }

      @media (min-width: 1200px) {
        /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-xl-left {
          text-align: left !important;
        }

        /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-xl-right {
          text-align: right !important;
        }

        /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

        .text-xl-center {
          text-align: center !important;
        }
      }

      /* line 30, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-lowercase {
        text-transform: lowercase !important;
      }

      /* line 31, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-uppercase {
        text-transform: uppercase !important;
      }

      /* line 32, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-capitalize {
        text-transform: capitalize !important;
      }

      /* line 36, node_modules/bootstrap/scss/utilities/_text.scss */

      .font-weight-light {
        font-weight: 300 !important;
      }

      /* line 37, node_modules/bootstrap/scss/utilities/_text.scss */

      .font-weight-lighter {
        font-weight: lighter !important;
      }

      /* line 38, node_modules/bootstrap/scss/utilities/_text.scss */

      .font-weight-normal {
        font-weight: 400 !important;
      }

      /* line 39, node_modules/bootstrap/scss/utilities/_text.scss */

      .font-weight-bold {
        font-weight: 700 !important;
      }

      /* line 40, node_modules/bootstrap/scss/utilities/_text.scss */

      .font-weight-bolder {
        font-weight: bolder !important;
      }

      /* line 41, node_modules/bootstrap/scss/utilities/_text.scss */

      .font-italic {
        font-style: italic !important;
      }

      /* line 45, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-white {
        color: #fff !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-primary {
        color: #525ddc !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-primary:hover,
      a.text-primary:focus {
        color: #2632bc !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-secondary {
        color: #6c757d !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-secondary:hover,
      a.text-secondary:focus {
        color: #494f54 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-success {
        color: #28a745 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-success:hover,
      a.text-success:focus {
        color: #19692c !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-info {
        color: #17a2b8 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-info:hover,
      a.text-info:focus {
        color: #0f6674 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-warning {
        color: #ffc107 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-warning:hover,
      a.text-warning:focus {
        color: #ba8b00 !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-danger {
        color: #dc3545 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-danger:hover,
      a.text-danger:focus {
        color: #a71d2a !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-light {
        color: #f8f9fa !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-light:hover,
      a.text-light:focus {
        color: #cbd3da !important;
      }

      /* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

      .text-dark {
        color: #343a40 !important;
      }

      /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

      a.text-dark:hover,
      a.text-dark:focus {
        color: #121416 !important;
      }

      /* line 51, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-body {
        color: #212529 !important;
      }

      /* line 52, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-muted {
        color: #6c757d !important;
      }

      /* line 54, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-black-50 {
        color: rgba(0, 0, 0, 0.5) !important;
      }

      /* line 55, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-white-50 {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      /* line 59, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-hide {
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
      }

      /* line 63, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-decoration-none {
        text-decoration: none !important;
      }

      /* line 65, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-break {
        word-break: break-word !important;
        overflow-wrap: break-word !important;
      }

      /* line 72, node_modules/bootstrap/scss/utilities/_text.scss */

      .text-reset {
        color: inherit !important;
      }

      /* line 7, node_modules/bootstrap/scss/utilities/_visibility.scss */

      .visible {
        visibility: visible !important;
      }

      /* line 11, node_modules/bootstrap/scss/utilities/_visibility.scss */

      .invisible {
        visibility: hidden !important;
      }

      @media print {
        /* line 13, node_modules/bootstrap/scss/_print.scss */

        *,
        *::before,
        *::after {
          text-shadow: none !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
        }

        /* line 24, node_modules/bootstrap/scss/_print.scss */

        a:not(.btn) {
          text-decoration: underline;
        }

        /* line 34, node_modules/bootstrap/scss/_print.scss */

        abbr[title]::after {
          content: " (" attr(title) ")";
        }

        /* line 49, node_modules/bootstrap/scss/_print.scss */

        pre {
          white-space: pre-wrap !important;
        }

        /* line 52, node_modules/bootstrap/scss/_print.scss */

        pre,
        blockquote {
          border: 1px solid #adb5bd;
          page-break-inside: avoid;
        }

        /* line 63, node_modules/bootstrap/scss/_print.scss */

        thead {
          display: table-header-group;
        }

        /* line 67, node_modules/bootstrap/scss/_print.scss */

        tr,
        img {
          page-break-inside: avoid;
        }

        /* line 72, node_modules/bootstrap/scss/_print.scss */

        p,
        h2,
        h3 {
          orphans: 3;
          widows: 3;
        }

        /* line 79, node_modules/bootstrap/scss/_print.scss */

        h2,
        h3 {
          page-break-after: avoid;
        }

        @page {
          size: a3;
        }

        /* line 92, node_modules/bootstrap/scss/_print.scss */

        body {
          min-width: 992px !important;
        }

        /* line 95, node_modules/bootstrap/scss/_print.scss */

        .container {
          min-width: 992px !important;
        }

        /* line 100, node_modules/bootstrap/scss/_print.scss */

        .navbar {
          display: none;
        }

        /* line 103, node_modules/bootstrap/scss/_print.scss */

        .badge {
          border: 1px solid #000;
        }

        /* line 107, node_modules/bootstrap/scss/_print.scss */

        .table {
          border-collapse: collapse !important;
        }

        /* line 110, node_modules/bootstrap/scss/_print.scss */

        .table td,
        .table th {
          background-color: #fff !important;
        }

        /* line 117, node_modules/bootstrap/scss/_print.scss */

        .table-bordered th,
        .table-bordered td {
          border: 1px solid #dee2e6 !important;
        }

        /* line 123, node_modules/bootstrap/scss/_print.scss */

        .table-dark {
          color: inherit;
        }

        /* line 126, node_modules/bootstrap/scss/_print.scss */

        .table-dark th,
        .table-dark td,
        .table-dark thead th,
        .table-dark tbody + tbody {
          border-color: #dee2e6;
        }

        /* line 134, node_modules/bootstrap/scss/_print.scss */

        .table .thead-dark th {
          color: inherit;
          border-color: #dee2e6;
        }
      }

      /**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */

      /** Import theme styles */

      /* line 1, resources/assets/styles/common/_global.scss */

      html {
        scroll-behavior: smooth;
        overflow-x: hidden;
      }

      /* line 6, resources/assets/styles/common/_global.scss */

      body {
        font-family: "PT Sans", sans-serif;
        background-color: #f5f5f5;
        color: #000;
        overflow-x: hidden;
      }

      /* line 13, resources/assets/styles/common/_global.scss */

      h1,
      h2,
      h4 {
        font-family: "PT Serif", serif;
        font-weight: 700;
      }

      /* line 20, resources/assets/styles/common/_global.scss */

      h1 {
        font-size: 39px;
        border-top: 4px solid #000;
        display: inline-block;
        padding-top: 5px;
        margin-bottom: 12px;
      }

      /* line 28, resources/assets/styles/common/_global.scss */

      h2 {
        font-size: 25px;
      }

      /* line 32, resources/assets/styles/common/_global.scss */

      h3 {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      /* line 40, resources/assets/styles/common/_global.scss */

      h4 {
        font-size: 18px;
      }

      /* line 44, resources/assets/styles/common/_global.scss */

      p {
        line-height: 1.4;
        font-size: 18px;
      }

      /* line 49, resources/assets/styles/common/_global.scss */

      ol,
      ul {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 35px;
      }

      /* line 56, resources/assets/styles/common/_global.scss */

      ul {
        padding-left: 17px;
      }

      /* line 60, resources/assets/styles/common/_global.scss */

      ol {
        padding-left: 25px;
      }

      /* line 64, resources/assets/styles/common/_global.scss */

      li {
        margin-bottom: 15px;
      }

      /* line 68, resources/assets/styles/common/_global.scss */

      figcaption.wp-caption-text {
        font-size: 12px;
        color: #000;
        font-weight: 700;
        line-height: 1.75;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      /* line 77, resources/assets/styles/common/_global.scss */

      .pt-sans {
        font-family: "PT Sans", sans-serif;
      }

      /* line 81, resources/assets/styles/common/_global.scss */

      .pt-serif {
        font-family: "PT Serif", serif;
      }

      /* line 85, resources/assets/styles/common/_global.scss */

      .section {
        padding-top: 50px;
      }

      /* line 88, resources/assets/styles/common/_global.scss */

      .section--page-title {
        padding-top: 150px;
      }

      /* line 92, resources/assets/styles/common/_global.scss */

      .section--language {
        padding-bottom: 25px;
      }

      /* line 96, resources/assets/styles/common/_global.scss */

      .section--button {
        padding-top: 15px;
      }

      /* line 100, resources/assets/styles/common/_global.scss */

      .section:last-child {
        padding-bottom: 100px;
      }

      /* line 104, resources/assets/styles/common/_global.scss */

      .section p.section__subtitle {
        margin-bottom: 0;
      }

      /* line 109, resources/assets/styles/common/_global.scss */

      .container,
      .container-fluid {
        z-index: 1;
      }

      /* line 114, resources/assets/styles/common/_global.scss */

      .container {
        position: relative;
        max-width: 1290px;
        padding-left: 40px;
        padding-right: 40px;
      }

      /* line 121, resources/assets/styles/common/_global.scss */

      .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }

      /* line 125, resources/assets/styles/common/_global.scss */

      .container-fluid--log {
        background-color: #fff;
      }

      /* line 130, resources/assets/styles/common/_global.scss */

      .archive .wrap.container {
        min-height: calc(100vh - 140px);
      }

      /* line 134, resources/assets/styles/common/_global.scss */

      .page-header {
        position: relative;
      }

      /* line 1, resources/assets/styles/components/_buttons.scss */

      .hb-button {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        background-color: #000;
        letter-spacing: 3px;
        display: block;
        width: 240px;
        padding: 11px 0;
        text-align: center;
        border: 2px solid #000;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      /* line 15, resources/assets/styles/components/_buttons.scss */

      .hb-button:hover,
      .hb-button:active,
      .hb-button:focus {
        color: #000;
        background-color: #fff;
        text-decoration: none;
      }

      /* line 24, resources/assets/styles/components/_buttons.scss */

      .section--button .hb-button {
        margin: 0 auto;
      }

      /* line 5, resources/assets/styles/components/_comments.scss */

      .comment-list ol {
        list-style: none;
      }

      /** Search form */

      /* line 6, resources/assets/styles/components/_forms.scss */

      .search-form label {
        font-weight: normal;
      }

      /**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

      /** Media alignment */

      /* line 7, resources/assets/styles/components/_wp-classes.scss */

      .alignnone {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        height: auto;
      }

      /* line 14, resources/assets/styles/components/_wp-classes.scss */

      .aligncenter {
        display: block;
        margin: 0.5rem auto;
        height: auto;
      }

      /* line 20, resources/assets/styles/components/_wp-classes.scss */

      .alignleft,
      .alignright {
        margin-bottom: 0.5rem;
        height: auto;
      }

      @media (min-width: 576px) {
        /* line 27, resources/assets/styles/components/_wp-classes.scss */

        .alignleft {
          float: left;
          margin-right: 0.5rem;
        }

        /* line 32, resources/assets/styles/components/_wp-classes.scss */

        .alignright {
          float: right;
          margin-left: 0.5rem;
        }
      }

      /** Captions */

      /** Text meant only for screen readers */

      /* line 1, resources/assets/styles/components/_kv.scss */

      .kv {
        width: 100%;
        height: 100vh;
      }

      /* line 5, resources/assets/styles/components/_kv.scss */

      .kv .container {
        height: 100%;
        position: relative;
      }

      /* line 11, resources/assets/styles/components/_kv.scss */

      .kv__intro {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 2;
      }

      /* line 17, resources/assets/styles/components/_kv.scss */

      .intro__title {
        font-size: 50px;
        letter-spacing: -0.5px;
        line-height: 1;
        font-weight: 700;
        border-top: 4px solid #000;
        display: inline-block;
        padding-top: 6px;
        margin-bottom: 18px;
      }

      /* line 28, resources/assets/styles/components/_kv.scss */

      .intro__text {
        margin-bottom: 42px;
      }

      /* line 32, resources/assets/styles/components/_kv.scss */

      .kv__animation {
        width: 83%;
        position: absolute;
        right: -16%;
        height: 100vh;
        top: 0;
      }

      /* line 39, resources/assets/styles/components/_kv.scss */

      .kv__animation img,
      .kv__animation #kv__animation__path {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        width: 100%;
      }

      /* line 46, resources/assets/styles/components/_kv.scss */

      .kv__animation img svg,
      .kv__animation #kv__animation__path svg {
        height: auto;
      }

      /* line 1, resources/assets/styles/components/_log-list.scss */

      .log-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
        margin-top: 50px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }

      /* line 7, resources/assets/styles/components/_log-list.scss */

      .log-list--all {
        margin-top: 30px;
        width: calc(100% + 24px);
        margin-left: -12px;
      }

      /* line 13, resources/assets/styles/components/_log-list.scss */

      .log-list--home {
        margin-top: 50px;
        width: calc(100% + 24px);
        margin-left: -12px;
      }

      /* line 20, resources/assets/styles/components/_log-list.scss */

      .log-list__item {
        margin: 0 1% 35px;
        width: 14.65%;
      }

      /* line 27, resources/assets/styles/components/_log-list.scss */

      .item__card {
        width: 100%;
        padding-top: 127%;
        background-color: #fff;
        -webkit-box-shadow: 3px 3px 6px #00000029;
        box-shadow: 3px 3px 6px #00000029;
        position: relative;
        margin-bottom: 18px;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      /* line 37, resources/assets/styles/components/_log-list.scss */

      .item__card:hover {
        -webkit-transform: scale(1.05);
        -o-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-box-shadow: 3px 3px 18px #00000029;
        box-shadow: 3px 3px 18px #00000029;
      }

      /* line 42, resources/assets/styles/components/_log-list.scss */

      .item__card a {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      /* line 51, resources/assets/styles/components/_log-list.scss */

      .item__card span {
        font-size: 11px;
        text-transform: uppercase;
        color: #000;
        position: absolute;
        left: 11%;
        top: 6.5%;
      }

      /* line 60, resources/assets/styles/components/_log-list.scss */

      .item__card svg {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 55%;
        max-height: 65%;
      }

      /* line 69, resources/assets/styles/components/_log-list.scss */

      .item__content h4 {
        margin-bottom: 12px;
        color: #000;
      }

      /* line 74, resources/assets/styles/components/_log-list.scss */

      .item__content p {
        font-size: 14px;
        margin-bottom: 20px;
        color: #000;
      }

      /* line 80, resources/assets/styles/components/_log-list.scss */

      .item__content a + p {
        margin-bottom: 0;
      }

      /* line 84, resources/assets/styles/components/_log-list.scss */

      .item__content .tag {
        display: inline-block;
        font-size: 11px;
        line-height: 30px;
        padding: 0 8px;
        color: #fff;
        background-color: #000;
        text-transform: uppercase;
        letter-spacing: 1.65px;
        cursor: default;
      }

      /* line 96, resources/assets/styles/components/_log-list.scss */

      .item__content a:hover {
        color: #000;
      }

      /* line 1, resources/assets/styles/components/_quotes.scss */

      .section--quotes {
        padding: 150px 0 100px;
      }

      /* line 5, resources/assets/styles/components/_quotes.scss */

      .quote-item__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
      }

      /* line 10, resources/assets/styles/components/_quotes.scss */

      .quote-item__wrap:nth-child(even) {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }

      /* line 14, resources/assets/styles/components/_quotes.scss */

      .quote-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 50%;
      }

      /* line 20, resources/assets/styles/components/_quotes.scss */

      .quote-item__img {
        width: 90px;
        height: 90px;
        margin: 20px 0 0 25px;
      }

      /* line 25, resources/assets/styles/components/_quotes.scss */

      .quote-item__img img {
        width: 100%;
        border-radius: 50%;
      }

      /* line 31, resources/assets/styles/components/_quotes.scss */

      .quote-item__content {
        width: calc(100% - 175px);
        border-top: 4px solid #000;
      }

      /* line 35, resources/assets/styles/components/_quotes.scss */

      .quote-item__content p {
        font-size: 25px;
        line-height: 1.3;
        font-family: "PT Serif", serif;
        font-style: italic;
        font-weight: 700;
        padding-top: 5px;
      }

      /* line 44, resources/assets/styles/components/_quotes.scss */

      .quote-item__content span {
        font-size: 18px;
      }

      /* line 1, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu {
        position: fixed;
        top: 0;
        left: -260px;
        width: 260px;
        height: 100vh;
        background-color: #000;
        z-index: 4;
        overflow: hidden;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-box-shadow: 3px 3px 16px transparent;
        box-shadow: 3px 3px 16px transparent;
      }

      /* line 13, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu.active {
        left: 0;
        -webkit-box-shadow: 3px 3px 16px #00000089;
        box-shadow: 3px 3px 16px #00000089;
      }

      /* line 19, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu-blur,
      .print-download-blur {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        -webkit-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
        z-index: -1;
      }

      /* line 31, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu-blur.active,
      .print-download-blur.active {
        opacity: 1;
      }

      /* line 36, resources/assets/styles/components/_sidemenu.scss */

      .print-download-blur {
        background-color: rgba(255, 255, 255, 0.95);
      }

      /* line 40, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__header {
        height: 70px;
        position: relative;
      }

      /* line 44, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__header .sidemenu__close {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 21px;
        stroke: #fff;
        cursor: pointer;
      }

      /* line 54, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body ul {
        display: block;
        padding-left: 0;
      }

      /* line 59, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li {
        height: 90px;
        line-height: 90px;
        font-size: 16px;
        letter-spacing: 3px;
        font-weight: 700;
        display: block;
        text-transform: uppercase;
        border-top: 1px solid #333;
        margin-bottom: 0;
      }

      /* line 70, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li a {
        width: 100%;
        height: 100%;
        color: #fff;
        display: block;
        padding-left: 35px;
        position: relative;
        left: 0;
        -webkit-transition: left 0.2s ease-in-out;
        -o-transition: left 0.2s ease-in-out;
        transition: left 0.2s ease-in-out;
      }

      /* line 80, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li a::before {
        content: "";
        display: block;
        position: absolute;
        height: 90px;
        width: 15px;
        left: -15px;
        top: 0;
        background-color: #fff;
      }

      /* line 91, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li a:hover,
      .sidemenu__body li a:active,
      .sidemenu__body li a:focus {
        left: 10px;
        text-decoration: none;
      }

      /* line 100, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li.active a {
        left: 10px;
      }

      /* line 105, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li:last-child {
        border-bottom: 1px solid #333;
      }

      /* line 109, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li.menu-dashboard {
        border-top: none;
      }

      /* line 112, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li.menu-dashboard a {
        padding-left: 94px;
      }

      /* line 115, resources/assets/styles/components/_sidemenu.scss */

      .sidemenu__body li.menu-dashboard a::after {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        content: "";
        display: block;
        left: 35px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #fff;
      }

      /* line 1, resources/assets/styles/components/_audio-player.scss */

      .single-post {
        text-align: left;
      }

      /* line 3, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-container {
        margin: 50px 0 7px;
      }

      /* line 7, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls {
        padding: 0;
      }

      /* line 11, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-container,
      .single-post .mejs-container .mejs-controls,
      .single-post .mejs-embed,
      .single-post .mejs-embed body {
        background-color: #fff;
      }

      /* line 18, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-button > button {
        -webkit-filter: invert(1);
        filter: invert(1);
      }

      /* line 22, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-playpause-button button {
        margin-left: 0;
      }

      /* line 26, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-time {
        color: #000;
        font-size: 16px;
        font-family: "PT Sans", sans-serif;
        position: relative;
        overflow: visible;
        padding-top: 15px;
      }

      /* line 35, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-currenttime-container {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        padding-right: 7px;
      }

      /* line 39, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-currenttime-container::after {
        content: "/";
        display: block;
        position: absolute;
        right: -3px;
        top: 14px;
      }

      /* line 48, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-duration-container {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        padding-left: 7px;
        padding-right: 20px;
      }

      /* line 54, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-volume-button {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
      }

      /* line 58, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-horizontal-volume-slider {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
      }

      /* line 61, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
        left: auto;
        right: 0;
        top: 19px;
        height: 1px;
        background: #000;
      }

      /* line 69, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
        height: 5px;
        top: -2px;
        background: #000;
      }

      /* line 76, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail {
        padding-top: 8px;
        margin-left: 0;
      }

      /* line 80, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-total {
        height: 15px;
        background: transparent;
        margin-top: 4px;
        outline: none;
      }

      /* line 86, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-total::before {
        position: absolute;
        top: 7px;
        left: 0;
        height: 1px;
        background-color: #000;
        width: 100%;
        display: block;
        content: "";
      }

      /* line 98, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-buffering {
        height: 0;
      }

      /* line 102, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-hovered,
      .single-post .mejs-controls .mejs-time-rail .mejs-time-loaded,
      .single-post .mejs-controls .mejs-time-rail .mejs-time-marker {
        height: 1px;
      }

      /* line 108, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-hovered,
      .single-post .mejs-controls .mejs-time-rail .mejs-time-loaded {
        background: transparent;
      }

      /* line 113, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-current {
        top: 5px;
        height: 5px;
        background: #000;
      }

      /* line 119, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-handle {
        border-radius: 50%;
        border: 7px solid #000;
        top: 1px;
        left: -2px;
      }

      /* line 126, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-handle-content {
        background: transparent;
        border: none;
      }

      /* line 131, resources/assets/styles/components/_audio-player.scss */

      .single-post .mejs-controls .mejs-time-rail .mejs-time-float-corner {
        display: none;
      }

      /* line 1, resources/assets/styles/components/_filter.scss */

      .section--logs {
        position: relative;
      }

      /* line 5, resources/assets/styles/components/_filter.scss */

      .log-filter {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        position: absolute;
        top: 50px;
        right: 0;
        border: 1px solid #fff;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        z-index: 9;
      }

      /* line 16, resources/assets/styles/components/_filter.scss */

      .log-filter:hover,
      .log-filter.active {
        border: 1px solid #000;
      }

      /* line 22, resources/assets/styles/components/_filter.scss */

      .filter__active {
        width: 160px;
        height: 38px;
        background-color: #fff;
        padding: 10px 10px 10px 11px;
        cursor: pointer;
        position: relative;
      }

      /* line 30, resources/assets/styles/components/_filter.scss */

      .filter__active span {
        vertical-align: middle;
      }

      /* line 34, resources/assets/styles/components/_filter.scss */

      .filter__active .arrow {
        width: 15px;
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(0deg);
        -o-transform: translateY(-50%) rotate(0deg);
        transform: translateY(-50%) rotate(0deg);
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      /* line 44, resources/assets/styles/components/_filter.scss */

      .flag-img {
        width: 21px;
        margin-right: 15px;
        -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.35);
      }

      /* line 50, resources/assets/styles/components/_filter.scss */

      .filter__list {
        background-color: #fff;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      /* line 57, resources/assets/styles/components/_filter.scss */

      .log-filter.active {
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        /* width */
        /* Track */
        /* Handle */
        /* Handle on hover */
      }

      /* line 60, resources/assets/styles/components/_filter.scss */

      .log-filter.active .filter__active {
        background-color: #f5f5f5;
      }

      /* line 64, resources/assets/styles/components/_filter.scss */

      .log-filter.active .arrow {
        -webkit-transform: translateY(-50%) rotate(180deg);
        -o-transform: translateY(-50%) rotate(180deg);
        transform: translateY(-50%) rotate(180deg);
      }

      /* line 68, resources/assets/styles/components/_filter.scss */

      .log-filter.active .filter__list {
        max-height: 190px;
        overflow-y: scroll;
      }

      /* line 74, resources/assets/styles/components/_filter.scss */

      .log-filter.active .filter__list::-webkit-scrollbar {
        width: 5px;
        margin-right: 7px;
        height: 100px;
      }

      /* line 81, resources/assets/styles/components/_filter.scss */

      .log-filter.active .filter__list::-webkit-scrollbar-track {
        background: #fff;
      }

      /* line 87, resources/assets/styles/components/_filter.scss */

      .log-filter.active .filter__list::-webkit-scrollbar-thumb {
        background: #808080;
      }

      /* line 92, resources/assets/styles/components/_filter.scss */

      .log-filter.active .filter__list::-webkit-scrollbar-thumb:hover {
        background: #f5f5f5;
      }

      /* line 97, resources/assets/styles/components/_filter.scss */

      .single-lang {
        width: 100%;
        height: 38px;
      }

      /* line 101, resources/assets/styles/components/_filter.scss */

      .single-lang a {
        padding: 10px;
        width: 100%;
        height: 100%;
        display: block;
        color: #000;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      /* line 109, resources/assets/styles/components/_filter.scss */

      .single-lang a:hover {
        background-color: #f5f5f5;
        text-decoration: none;
      }

      /* line 116, resources/assets/styles/components/_filter.scss */

      .log-filter--cat {
        top: 0;
      }

      /* line 120, resources/assets/styles/components/_filter.scss */

      .log-filter--home {
        top: 50px;
      }

      /* line 2, resources/assets/styles/components/_faq.scss */

      .template-faq .wrap.container {
        max-width: 680px;
      }

      /* line 7, resources/assets/styles/components/_faq.scss */

      .section--faq {
        position: relative;
      }

      /* line 11, resources/assets/styles/components/_faq.scss */

      .faq-item {
        padding: 20px 50px 20px 0;
        background-color: transparent;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-box-shadow: 3px 3px 16px transparent;
        box-shadow: 3px 3px 16px transparent;
      }

      /* line 17, resources/assets/styles/components/_faq.scss */

      .faq-item:hover {
        padding: 20px 25px;
      }

      /* line 21, resources/assets/styles/components/_faq.scss */

      .faq-item.active {
        padding: 20px 25px;
        background-color: #fff;
        -webkit-box-shadow: 3px 3px 6px #00000029;
        box-shadow: 3px 3px 6px #00000029;
      }

      /* line 29, resources/assets/styles/components/_faq.scss */

      .faq-item__question h2 {
        margin-bottom: 0;
      }

      /* line 33, resources/assets/styles/components/_faq.scss */

      .faq-item__question a {
        width: 100%;
        display: block;
        cursor: pointer;
      }

      /* line 40, resources/assets/styles/components/_faq.scss */

      .faq-item__answer {
        padding: 25px 0 0;
      }

      /* line 9, resources/assets/styles/layouts/_header.scss */

      header.banner {
        height: 70px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      /* line 18, resources/assets/styles/layouts/_header.scss */

      .banner__left {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 21px;
      }

      /* line 24, resources/assets/styles/layouts/_header.scss */

      .banner__right {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 21px;
      }

      /* line 30, resources/assets/styles/layouts/_header.scss */

      .banner__hamburger {
        margin-right: 18px;
        cursor: pointer;
      }

      /* line 36, resources/assets/styles/layouts/_header.scss */

      .banner__logotype span {
        color: #808080;
        font-size: 16px;
        line-height: 16px;
        vertical-align: middle;
        padding-left: 15px;
        margin-left: 15px;
        position: relative;
        display: inline-block;
      }

      /* line 46, resources/assets/styles/layouts/_header.scss */

      .banner__logotype span::before {
        content: "";
        width: 1px;
        height: 17px;
        background-color: #808080;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }

      /* line 58, resources/assets/styles/layouts/_header.scss */

      .banner__logotype:hover {
        text-decoration: none;
      }

      /* line 63, resources/assets/styles/layouts/_header.scss */

      .banner__user {
        text-transform: uppercase;
        letter-spacing: 2.25px;
        font-size: 12px;
        margin-right: 15px;
      }

      /* line 1, resources/assets/styles/layouts/_footer.scss */

      footer {
        background-color: #000;
        color: #fff;
        height: 140px;
      }

      /* line 6, resources/assets/styles/layouts/_footer.scss */

      footer .container {
        height: 100%;
        position: relative;
      }

      /* line 11, resources/assets/styles/layouts/_footer.scss */

      footer .widget_nav_menu {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      /* line 14, resources/assets/styles/layouts/_footer.scss */

      footer .widget_nav_menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      /* line 20, resources/assets/styles/layouts/_footer.scss */

      footer .widget_nav_menu li {
        display: inline-block;
        margin-right: 55px;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 700;
      }

      /* line 28, resources/assets/styles/layouts/_footer.scss */

      footer .widget_nav_menu li:last-child {
        margin-right: 0;
      }

      /* line 33, resources/assets/styles/layouts/_footer.scss */

      footer .widget_nav_menu a {
        color: #fff;
        vertical-align: middle;
      }

      /* line 39, resources/assets/styles/layouts/_footer.scss */

      footer .widget_media_image,
      footer .widget_text {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      /* line 44, resources/assets/styles/layouts/_footer.scss */

      footer .widget_media_image {
        left: 40px;
      }

      /* line 47, resources/assets/styles/layouts/_footer.scss */

      footer .widget_media_image svg {
        width: 180px;
      }

      /* line 52, resources/assets/styles/layouts/_footer.scss */

      footer .widget_text {
        right: 40px;
        font-size: 12px;
        margin-bottom: 0;
        opacity: 0.7;
      }

      /* line 2, resources/assets/styles/layouts/_posts.scss */

      .single-post .print-first-page {
        display: none;
      }

      /* line 6, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content {
        max-width: 680px !important;
        padding: 130px 40px 100px;
      }

      /* line 10, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content img,
      .single-post .container.main-content svg {
        width: calc(50% - 30px);
      }

      /* line 16, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content pre {
        margin: 40px 0;
      }

      /* line 20, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content pre > img,
      .single-post .container.main-content pre > svg,
      .single-post .container.main-content a > img,
      .single-post .container.main-content a > svg {
        margin: 0;
      }

      /* line 28, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--extra .container.main-content {
        padding-bottom: 100px;
      }

      /* line 32, resources/assets/styles/layouts/_posts.scss */

      .single-post .download-button {
        position: absolute;
        left: calc(100% + 30px);
        top: 130px;
      }

      /* line 38, resources/assets/styles/layouts/_posts.scss */

      .single-post .download-button--mobile {
        display: none;
      }

      /* line 41, resources/assets/styles/layouts/_posts.scss */

      .single-post .download-button--mobile .hb-button {
        width: 60px;
        height: 50px;
        padding: 0;
        line-height: 48px;
        letter-spacing: 2px;
        font-size: 15px;
      }

      /* line 51, resources/assets/styles/layouts/_posts.scss */

      .single-post article {
        clear: both;
      }

      /* line 55, resources/assets/styles/layouts/_posts.scss */

      .single-post h1 {
        display: block;
        margin-bottom: 35px;
      }

      /* line 60, resources/assets/styles/layouts/_posts.scss */

      .single-post h2 {
        margin: 100px 0 35px;
        border-top: 4px solid #000;
        padding-top: 5px;
      }

      /* line 66, resources/assets/styles/layouts/_posts.scss */

      .single-post h3 {
        margin: 50px 0 22px;
      }

      /* line 70, resources/assets/styles/layouts/_posts.scss */

      .single-post p {
        margin-bottom: 35px;
      }

      /* line 74, resources/assets/styles/layouts/_posts.scss */

      .single-post blockquote {
        background-color: #f5f5f5;
        padding: 30px 95px 30px;
        margin: 60px 0;
        font-size: 18px;
        font-family: "PT Serif", serif;
        line-height: 1.5;
        position: relative;
      }

      /* line 83, resources/assets/styles/layouts/_posts.scss */

      .single-post blockquote::before,
      .single-post blockquote::after {
        content: "";
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi4zOTgiIGhlaWdodD0iMjEuMzYzIiB2aWV3Qm94PSIwIDAgMjIuMzk4IDIxLjM2MyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6bm9uZTtzdHJva2U6IzFhMTgxODtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzc1LjE4NSAtODQ2Ljc2MSkiPjxwYXRoIGNsYXNzPSJhIiBkPSJNNzc3LjcsODUzLjM4MmEzLjM2MiwzLjM2MiwwLDAsMCw0Ljg0NS4zMDZjMi40MjktMi4xNC4wNzQtNS44OC0zLjA0NC00LjkzNS01LjA2NSwxLjcxNC0zLjMyNSw3LjkwOS0zLjE1Myw4LjkyNi45LDUuNTUyLDYuMiw4Ljc0OSw2LjIsOC43NDkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTAuMikiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTEuNzc3LDQuNzc5YTMuMzYyLDMuMzYyLDAsMCwwLDQuODQ1LjMwNkM5LjA1MiwyLjk0NSw2LjctLjc5NSwzLjU3OS4xNS0xLjQ4NiwxLjg2NS4yNTQsOC4wNTkuNDI2LDkuMDc2Yy45LDUuNTUyLDYuMiw4Ljc0OSw2LjIsOC43NDkiIHRyYW5zZm9ybT0ibWF0cml4KDAuOTY2LCAwLjI1OSwgLTAuMjU5LCAwLjk2NiwgNzg5LjE5NCwgODQ3LjcyKSIvPjwvZz48L3N2Zz4=);
        display: block;
        position: absolute;
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
        top: 30px;
      }

      /* line 95, resources/assets/styles/layouts/_posts.scss */

      .single-post blockquote::before {
        left: 35px;
      }

      /* line 99, resources/assets/styles/layouts/_posts.scss */

      .single-post blockquote::after {
        right: 30px;
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      /* line 104, resources/assets/styles/layouts/_posts.scss */

      .single-post blockquote p {
        margin-bottom: 0;
      }

      /* line 109, resources/assets/styles/layouts/_posts.scss */

      .single-post .emphasis-block {
        background-color: #f5f5f5;
        padding: 30px 35px 15px;
        margin: 60px 0;
        font-size: 18px;
        font-family: "PT Serif", serif;
      }

      /* line 116, resources/assets/styles/layouts/_posts.scss */

      .single-post .emphasis-block p {
        margin-bottom: 15px;
      }

      /* line 120, resources/assets/styles/layouts/_posts.scss */

      .single-post .emphasis-block ol {
        padding-left: 25px;
      }

      /* line 124, resources/assets/styles/layouts/_posts.scss */

      .single-post .emphasis-block figcaption {
        font-family: "PT Sans", sans-serif;
      }

      /* line 129, resources/assets/styles/layouts/_posts.scss */

      .single-post .entry-content > p:last-child {
        margin-bottom: 0;
      }

      /* line 133, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content figure {
        margin: 60px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        width: 100% !important;
      }

      /* line 140, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content figure a,
      .single-post .container.main-content figure img,
      .single-post .container.main-content figure svg {
        width: calc(50% - 30px);
        margin: 0;
      }

      /* line 147, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content figure a img,
      .single-post .container.main-content figure a svg {
        width: 100%;
      }

      /* line 152, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content figure figcaption {
        position: relative;
        margin-top: 25px;
        width: 50%;
        padding: 10px 15px 0 0;
      }

      /* line 158, resources/assets/styles/layouts/_posts.scss */

      .single-post .container.main-content figure figcaption::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000;
      }

      /* line 170, resources/assets/styles/layouts/_posts.scss */

      .single-post #single-post__svg {
        position: absolute;
        top: 130px;
        width: 100%;
        right: calc(100% + 30px);
      }

      /* line 176, resources/assets/styles/layouts/_posts.scss */

      .single-post #single-post__svg svg {
        max-width: 195px;
        max-height: 195px;
        float: right;
        margin: 0;
      }

      /* line 184, resources/assets/styles/layouts/_posts.scss */

      .single-post table {
        margin: 35px 0;
        border: 1px solid #000;
        font-size: 18px;
      }

      /* line 189, resources/assets/styles/layouts/_posts.scss */

      .single-post table tr {
        color: #000;
        background-color: #f5f5f5;
      }

      /* line 193, resources/assets/styles/layouts/_posts.scss */

      .single-post table tr td {
        border: 1px solid #000;
        padding: 6px 10px;
        position: relative;
      }

      /* line 199, resources/assets/styles/layouts/_posts.scss */

      .single-post table tr:first-child {
        color: #fff;
        background-color: #000;
      }

      /* line 203, resources/assets/styles/layouts/_posts.scss */

      .single-post table tr:first-child td {
        padding: 10px;
        position: relative;
      }

      /* line 207, resources/assets/styles/layouts/_posts.scss */

      .single-post table tr:first-child td::before {
        content: "";
        width: calc(100% + 2px);
        height: 2px;
        position: absolute;
        bottom: 0;
        left: -1px;
        background-color: #fff;
      }

      /* line 217, resources/assets/styles/layouts/_posts.scss */

      .single-post table tr:first-child td:first-child {
        border-right: 2px solid #fff;
      }

      /* line 225, resources/assets/styles/layouts/_posts.scss */

      .single-post table tr:nth-child(2) td:first-child::before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        top: -1px;
        left: 2px;
        background-color: #000;
      }

      /* line 239, resources/assets/styles/layouts/_posts.scss */

      .single-post .media-caption {
        font-size: 18px;
        font-weight: 700;
        font-family: "PT Serif", serif;
        padding: 0 32px;
      }

      /* line 245, resources/assets/styles/layouts/_posts.scss */

      .single-post .media-caption span {
        font-size: 14px;
        font-weight: 400;
        font-family: "PT Sans", sans-serif;
        display: inline-block;
        padding-top: 5px;
      }

      /* line 254, resources/assets/styles/layouts/_posts.scss */

      .single-post .yt-video {
        position: relative;
        margin: 50px 0;
      }

      /* line 258, resources/assets/styles/layouts/_posts.scss */

      .single-post .yt-video iframe {
        width: 100%;
      }

      /* line 262, resources/assets/styles/layouts/_posts.scss */

      .single-post .yt-video .media-caption {
        padding: 0;
      }

      /* line 268, resources/assets/styles/layouts/_posts.scss */

      .single-post .next-prev__wrap .next-prev {
        margin-top: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }

      /* line 274, resources/assets/styles/layouts/_posts.scss */

      .single-post .next-prev__wrap .log-list__item {
        width: 180px;
      }

      /* line 277, resources/assets/styles/layouts/_posts.scss */

      .single-post .next-prev__wrap .log-list__item a:hover {
        text-decoration: none;
      }

      /* line 281, resources/assets/styles/layouts/_posts.scss */

      .single-post .next-prev__wrap .log-list__item h4:hover {
        text-decoration: underline;
      }

      /* line 287, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--call-to-action {
        background-color: #000;
        color: #fff;
        padding: 80px 0;
      }

      /* line 292, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--call-to-action blockquote {
        color: #000;
        background-color: #fff;
      }

      /* line 297, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--call-to-action .container.main-content {
        padding-bottom: 0;
      }

      /* line 300, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--call-to-action .container.main-content h2 {
        margin-top: 20px;
        border-color: #fff;
      }

      /* Additional resources */

      /* line 310, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--extra .container {
        padding: 0 40px;
      }

      /* line 314, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--extra ul,
      .single-post .container-fluid--extra ol {
        margin: 0;
      }

      /* line 319, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--extra h3 + .yt-video {
        margin-top: 40px;
      }

      /* line 323, resources/assets/styles/layouts/_posts.scss */

      .single-post .container-fluid--extra .hb-button {
        margin-top: 80px;
      }

      /* line 328, resources/assets/styles/layouts/_posts.scss */

      .video-embed__link {
        display: none;
        font-size: 22px;
        margin-left: 17px;
        position: relative;
      }

      /* line 334, resources/assets/styles/layouts/_posts.scss */

      .video-embed__link::before {
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #000;
        position: absolute;
        left: -19px;
        top: 12px;
      }

      /* line 1, resources/assets/styles/layouts/_tinymce.scss */

      body#tinymce {
        margin: 12px !important;
      }

      @media only screen and (max-width: 1279px) {
        /* line 3, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button {
          left: calc(100% + 10px);
        }

        /* line 6, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button .hb-button {
          width: 200px;
        }

        /* line 12, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button--mobile .hb-button {
          width: 60px;
        }

        /* line 17, resources/assets/styles/common/_responsive.scss */

        .single-post #single-post__svg {
          right: calc(100% + 20px);
        }

        /* line 20, resources/assets/styles/common/_responsive.scss */

        .single-post #single-post__svg svg {
          max-width: 160px;
          max-height: 160px;
        }
      }

      @media only screen and (max-width: 1199px) {
        /* line 31, resources/assets/styles/common/_responsive.scss */

        .single-post .container.main-content {
          padding-top: 115px;
        }

        /* line 35, resources/assets/styles/common/_responsive.scss */

        .single-post .container-fluid--extra .container.main-content {
          padding-top: 0;
        }

        /* line 39, resources/assets/styles/common/_responsive.scss */

        .single-post #single-post__svg {
          position: relative;
          right: auto;
          top: 5px;
          margin-bottom: 30px;
        }

        /* line 44, resources/assets/styles/common/_responsive.scss */

        .single-post #single-post__svg svg {
          float: none;
        }

        /* line 49, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button {
          left: auto;
          top: auto;
          bottom: 55px;
          position: relative;
        }

        /* line 55, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button .hb-button {
          width: 240px;
          float: right;
        }

        /* line 62, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button--mobile .hb-button {
          width: 60px;
        }
      }

      @media only screen and (max-width: 991px) {
        /* line 71, resources/assets/styles/common/_responsive.scss */

        .quote-item__wrap {
          margin-bottom: 100px;
        }

        /* line 74, resources/assets/styles/common/_responsive.scss */

        .quote-item__wrap:last-child {
          margin-bottom: 0;
        }

        /* line 79, resources/assets/styles/common/_responsive.scss */

        .quote-item {
          width: 100%;
        }

        /* line 84, resources/assets/styles/common/_responsive.scss */

        .log-list__item {
          width: 30%;
          margin: 0 1.66% 35px;
        }

        /* line 90, resources/assets/styles/common/_responsive.scss */

        footer {
          height: 150px;
        }

        /* line 93, resources/assets/styles/common/_responsive.scss */

        footer .widget_media_image,
        footer .widget_nav_menu {
          top: 35%;
        }

        /* line 98, resources/assets/styles/common/_responsive.scss */

        footer .widget_nav_menu {
          left: auto;
          right: 40px;
          -webkit-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        /* line 103, resources/assets/styles/common/_responsive.scss */

        footer .widget_nav_menu li {
          margin-right: 45px;
        }

        /* line 108, resources/assets/styles/common/_responsive.scss */

        footer .widget_text {
          top: auto;
          right: auto;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
          bottom: 35px;
        }
      }

      @media only screen and (max-width: 768px) {
        /* line 120, resources/assets/styles/common/_responsive.scss */

        .kv__intro {
          top: 170px;
          -webkit-transform: none;
          -o-transform: none;
          transform: none;
        }

        /* line 125, resources/assets/styles/common/_responsive.scss */

        .kv__animation {
          top: 17%;
          right: -23%;
          width: 125%;
        }

        /* line 132, resources/assets/styles/common/_responsive.scss */

        .section--quotes {
          padding: 100px 0;
        }

        /* line 138, resources/assets/styles/common/_responsive.scss */

        footer .widget_media_image svg {
          width: 148px;
        }

        /* line 144, resources/assets/styles/common/_responsive.scss */

        .log-filter {
          right: auto;
          top: 125px;
        }

        /* line 149, resources/assets/styles/common/_responsive.scss */

        .section__subtitle {
          margin-top: 65px;
        }

        /* line 153, resources/assets/styles/common/_responsive.scss */

        .home .section__subtitle {
          margin-top: 0;
        }

        /* line 157, resources/assets/styles/common/_responsive.scss */

        .log-filter--cat {
          top: 80px;
        }

        /* line 163, resources/assets/styles/common/_responsive.scss */

        .category .page-header,
        .template-all-logs .page-header {
          margin-bottom: 45px;
        }

        /* line 168, resources/assets/styles/common/_responsive.scss */

        .log-filter--home {
          top: 170px;
        }

        /* line 172, resources/assets/styles/common/_responsive.scss */

        .log-list--home {
          margin-top: 100px;
        }
      }

      @media only screen and (max-width: 658px) {
        /* line 179, resources/assets/styles/common/_responsive.scss */

        .log-list__item {
          width: 29%;
          margin: 0 2.1% 35px;
        }

        /* line 185, resources/assets/styles/common/_responsive.scss */

        footer {
          height: auto;
          padding: 30px 0 20px;
        }

        /* line 189, resources/assets/styles/common/_responsive.scss */

        footer .widget_media_image,
        footer .widget_nav_menu,
        footer .widget_text {
          position: relative;
          top: auto;
          right: auto;
          bottom: auto;
          left: auto;
          -webkit-transform: none;
          -o-transform: none;
          transform: none;
        }

        /* line 200, resources/assets/styles/common/_responsive.scss */

        footer .widget_nav_menu {
          padding: 50px 0 40px;
        }

        /* line 203, resources/assets/styles/common/_responsive.scss */

        footer .widget_nav_menu li {
          display: block;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      @media only screen and (max-width: 576px) {
        /* line 214, resources/assets/styles/common/_responsive.scss */

        h1 {
          font-size: 28px;
          padding-top: 3px;
        }

        /* line 219, resources/assets/styles/common/_responsive.scss */

        h3 {
          font-size: 12px;
        }

        /* line 223, resources/assets/styles/common/_responsive.scss */

        h2 {
          font-size: 20px;
        }

        /* line 227, resources/assets/styles/common/_responsive.scss */

        h4 {
          font-size: 14px;
        }

        /* line 231, resources/assets/styles/common/_responsive.scss */

        p,
        ul,
        ol {
          font-size: 16px;
        }

        /* line 237, resources/assets/styles/common/_responsive.scss */

        ul {
          padding-left: 14px;
        }

        /* line 241, resources/assets/styles/common/_responsive.scss */

        figcaption.wp-caption-text {
          font-size: 10px;
          line-height: 1.6;
        }

        /* line 246, resources/assets/styles/common/_responsive.scss */

        .container,
        .single-post .container.main-content {
          padding-left: 24px;
          padding-right: 24px;
        }

        /* line 253, resources/assets/styles/common/_responsive.scss */

        .banner__left {
          left: 10px;
        }

        /* line 257, resources/assets/styles/common/_responsive.scss */

        .banner__right {
          right: 10px;
        }

        /* line 261, resources/assets/styles/common/_responsive.scss */

        .banner__user {
          display: none;
        }

        /* line 265, resources/assets/styles/common/_responsive.scss */

        .banner__hamburger {
          margin-right: 5px;
        }

        /* line 269, resources/assets/styles/common/_responsive.scss */

        .banner__logotype span {
          font-size: 14px;
        }

        /* line 274, resources/assets/styles/common/_responsive.scss */

        .kv__intro {
          top: 110px;
        }

        /* line 278, resources/assets/styles/common/_responsive.scss */

        .intro__title {
          font-size: 34px;
        }

        /* line 282, resources/assets/styles/common/_responsive.scss */

        .kv__animation {
          top: 17%;
          right: -23%;
          width: 125%;
        }

        /* line 289, resources/assets/styles/common/_responsive.scss */

        .item__content h4 {
          margin-bottom: 8px;
        }

        /* line 293, resources/assets/styles/common/_responsive.scss */

        .item__content p {
          font-size: 12px;
        }

        /* line 298, resources/assets/styles/common/_responsive.scss */

        .quote-item__content {
          width: calc(100% - 150px);
        }

        /* line 302, resources/assets/styles/common/_responsive.scss */

        .quote-item__content p {
          font-size: 20px;
        }

        /* line 306, resources/assets/styles/common/_responsive.scss */

        .quote-item__content span {
          font-size: 16px;
        }

        /* line 312, resources/assets/styles/common/_responsive.scss */

        .single-post .container.main-content,
        .single-post .container-fluid--extra .container.main-content {
          padding-bottom: 65px;
        }

        /* line 317, resources/assets/styles/common/_responsive.scss */

        .single-post h1 {
          margin-bottom: 20px;
        }

        /* line 321, resources/assets/styles/common/_responsive.scss */

        .single-post h2 {
          margin: 70px 0 20px;
        }

        /* line 325, resources/assets/styles/common/_responsive.scss */

        .single-post p {
          margin-bottom: 28px;
        }

        /* line 329, resources/assets/styles/common/_responsive.scss */

        .single-post table {
          font-size: 16px;
        }

        /* line 333, resources/assets/styles/common/_responsive.scss */

        .single-post blockquote,
        .single-post .emphasis-block {
          margin: 0 0 28px;
          font-size: 16px;
          width: calc(100% + 48px);
          position: relative;
          left: -24px;
          padding: 15px 24px;
        }

        /* line 343, resources/assets/styles/common/_responsive.scss */

        .single-post blockquote {
          padding-top: 52px;
        }

        /* line 346, resources/assets/styles/common/_responsive.scss */

        .single-post blockquote::after,
        .single-post blockquote::before {
          top: 20px;
        }

        /* line 352, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button {
          display: none;
        }

        /* line 356, resources/assets/styles/common/_responsive.scss */

        .single-post .download-button--mobile {
          display: block;
          position: absolute;
          top: 90px;
          right: 24px;
          left: auto;
          bottom: auto;
        }

        /* line 365, resources/assets/styles/common/_responsive.scss */

        .single-post #single-post__svg {
          padding: 10px 0;
          top: -35px;
        }

        /* line 372, resources/assets/styles/common/_responsive.scss */

        .log-filter {
          top: 105px;
        }

        /* line 376, resources/assets/styles/common/_responsive.scss */

        .log-filter--cat {
          top: 65px;
        }

        /* line 380, resources/assets/styles/common/_responsive.scss */

        .section__subtitle {
          margin-top: 55px;
        }

        /* line 385, resources/assets/styles/common/_responsive.scss */

        .home .log-list {
          margin-top: 30px;
        }

        /* line 389, resources/assets/styles/common/_responsive.scss */

        .home .log-list--home {
          margin-top: 100px;
        }

        /* line 393, resources/assets/styles/common/_responsive.scss */

        .home .section__subtitle {
          margin-top: 0;
        }

        /* line 398, resources/assets/styles/common/_responsive.scss */

        .log-filter--home {
          top: 150px;
        }
      }

      @media only screen and (max-width: 470px) {
        /* line 405, resources/assets/styles/common/_responsive.scss */

        .quote-item__wrap {
          margin-bottom: 0;
        }

        /* line 408, resources/assets/styles/common/_responsive.scss */

        .quote-item__wrap:last-child {
          display: none;
        }

        /* line 413, resources/assets/styles/common/_responsive.scss */

        .quote-item {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }

        /* line 417, resources/assets/styles/common/_responsive.scss */

        .quote-item__img {
          margin: 0 0 30px;
        }

        /* line 421, resources/assets/styles/common/_responsive.scss */

        .quote-item__content {
          width: 100%;
        }
      }

      @media only screen and (max-width: 420px) {
        /* line 428, resources/assets/styles/common/_responsive.scss */

        .log-list__item {
          width: 45%;
          margin: 0 2.5% 35px;
        }

        /* line 436, resources/assets/styles/common/_responsive.scss */

        .single-post .container.main-content img,
        .single-post .container.main-content svg {
          width: 100%;
        }

        /* line 441, resources/assets/styles/common/_responsive.scss */

        .single-post .container.main-content figure {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }

        /* line 444, resources/assets/styles/common/_responsive.scss */

        .single-post .container.main-content figure a,
        .single-post .container.main-content figure img {
          width: 100%;
        }

        /* line 449, resources/assets/styles/common/_responsive.scss */

        .single-post .container.main-content figure figcaption {
          width: 50%;
        }

        /* line 452, resources/assets/styles/common/_responsive.scss */

        .single-post .container.main-content figure figcaption::before {
          height: 1px;
        }
      }

      @media only screen and (max-width: 340px) {
        /* line 463, resources/assets/styles/common/_responsive.scss */

        .log-list__item {
          width: 44%;
          margin: 0 3% 35px;
        }

        /* line 469, resources/assets/styles/common/_responsive.scss */

        .container,
        .single-post .container.main-content {
          padding-left: 18px;
          padding-right: 18px;
        }
      }

      /* line 1, resources/assets/styles/layouts/_print.scss */

      #print-footer {
        display: none;
      }

      /* line 5, resources/assets/styles/layouts/_print.scss */

      .print-download-blur__content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 320px;
        -webkit-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        font-family: "PT Serif", serif;
        font-weight: 700;
        font-size: 24px;
      }

      /* line 17, resources/assets/styles/layouts/_print.scss */

      #loading__svg {
        width: 150px;
        margin: 0 auto;
      }

      /* line 21, resources/assets/styles/layouts/_print.scss */

      #loading__svg svg:nth-child(n + 2) {
        display: none;
      }

      @media print {
        /* line 27, resources/assets/styles/layouts/_print.scss */

        body {
          background-color: #fff;
        }

        /* line 35, resources/assets/styles/layouts/_print.scss */

        header.banner,
        footer,
        .next-prev__wrap,
        .download-button {
          display: none;
        }

        /* line 42, resources/assets/styles/layouts/_print.scss */

        .single-post .container.main-content {
          padding: 0 4cm 3cm 6cm;
        }

        /* line 46, resources/assets/styles/layouts/_print.scss */

        h1 {
          font-size: 44px;
        }

        /* line 50, resources/assets/styles/layouts/_print.scss */

        h2 {
          font-size: 39px;
        }

        /* line 54, resources/assets/styles/layouts/_print.scss */

        p,
        ol,
        ul {
          font-size: 22px;
        }

        /* line 60, resources/assets/styles/layouts/_print.scss */

        pre,
        blockquote {
          border: none;
        }

        /* line 65, resources/assets/styles/layouts/_print.scss */

        table,
        figure {
          page-break-inside: avoid;
        }

        /* line 70, resources/assets/styles/layouts/_print.scss */

        .single-post .container-fluid--call-to-action {
          page-break-inside: avoid;
          background-color: #f5f5f5;
          color: #000;
        }

        /* line 75, resources/assets/styles/layouts/_print.scss */

        .single-post .container-fluid--call-to-action .container.main-content h1 {
          margin-top: 0;
        }

        /* line 79, resources/assets/styles/layouts/_print.scss */

        .single-post .container-fluid--call-to-action .container.main-content h2 {
          page-break-after: avoid;
          margin-top: 20px;
          border-color: #000;
        }

        /* line 86, resources/assets/styles/layouts/_print.scss */

        .container-fluid--log,
        .page-break {
          -webkit-column-break-before: page;
          break-before: page;
        }

        /* line 91, resources/assets/styles/layouts/_print.scss */

        .single-post .print-first-page {
          display: block;
          padding: 0 4cm 0 6cm;
          width: 992px;
          margin: 0 auto;
        }

        /* line 97, resources/assets/styles/layouts/_print.scss */

        .single-post .print-first-page .wp-post-image {
          display: block;
          width: auto !important;
          height: 11cm !important;
          margin: 7cm 0 8cm;
        }

        /* line 105, resources/assets/styles/layouts/_print.scss */

        .print-first-page__logo {
          width: 9cm;
          height: auto;
        }

        /* line 110, resources/assets/styles/layouts/_print.scss */

        .print-first-page__title {
          margin: 0;
          font-size: 44px;
        }

        /* line 115, resources/assets/styles/layouts/_print.scss */

        .single-post .new-page {
          -webkit-column-break-before: page;
          break-before: page;
          margin-top: 0;
        }

        /* line 120, resources/assets/styles/layouts/_print.scss */

        #print-footer {
          display: none;
          position: fixed;
          bottom: -3.5cm;
          right: 2cm;
          left: 2cm;
          text-align: left;
          background-color: transparent;
          border-top: 1px solid #000;
          color: #000;
          padding-top: 10px;
          font-size: 12px;
        }

        /* line 134, resources/assets/styles/layouts/_print.scss */

        .video-embed__link {
          display: block;
        }

        /* line 138, resources/assets/styles/layouts/_print.scss */

        .yt-video {
          display: none;
        }
      }


      /*# sourceMappingURL=main.css.map*/


  }

}

</style>
