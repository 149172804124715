<template>

  <div class="asset-upload">

    <div v-if="isEdit"
         class="icon upload"
         title="Add attachment"
         @click="$refs.uploadAsset.click()">

      <font-awesome-icon icon="plus"/>

      <input
          v-show="false"
          ref="uploadAsset"
          type="file"
          accept=".png,.jpeg,.jpg,.zip,.pptx,.ppt,.pdf,.xls,.xlsx,.csv"
          multiple
          @change="loadAssets($event)"
      />

    </div>

    <div v-if="indexUnit !== null"
         v-for="(linkedAsset, indexLinkedAsset) in journeyDetails.Structure[indexStructureItem].Units[indexUnit].LinkedAssets"
         :key="indexLinkedAsset"
         class="existing-assets">

      <div v-if="linkedAsset.Type === 'Attachment'"
           class="existing-asset"
           @click="downloadAsset(linkedAsset)"
      >

        <div v-if="isEdit"
             :class="{ 'icon': true, 'treasure-chest-asset-flag': true, 'treasure-chest': linkedAsset.IsTreasureChestAsset }"
             @click.stop="toggleTreasureChestFlag(linkedAsset)"
        >

<!--          <div v-if="linkedAsset.IsTreasureChestAsset" class="treasure-chest-options">-->

<!--            <div class="options-wrapper">-->

<!--              <div :class="{ 'option-wrapper': true, 'active-option': linkedAsset.GiveDetails.Event === 'Unlock' }"-->
<!--                   @click.stop="treasureChestOptionClickHandler(linkedAsset, 'Unlock')"-->
<!--              >-->
<!--                <div class="icon">-->
<!--                  <font-awesome-icon icon="lock-open"/>-->
<!--                </div>-->
<!--                <span>Chapter unlock</span>-->
<!--              </div>-->

<!--              <div :class="{ 'option-wrapper': true, 'active-option': linkedAsset.GiveDetails.Event === 'CompleteAssignment' }"-->
<!--                   @click.stop="treasureChestOptionClickHandler(linkedAsset, 'CompleteAssignment')"-->
<!--              >-->
<!--                <div class="icon">-->
<!--                  <font-awesome-icon icon="list-check"/>-->
<!--                </div>-->
<!--                <span>Assignment</span>-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->

          <font-awesome-icon class="icon-gem" icon="gem"/>

        </div>

<!--        <div class="icon tags"-->
<!--             v-if="isEdit"-->
<!--             @click.stop="addAssetTags(indexStructureItem, indexUnit, indexLinkedAsset)"-->
<!--        >-->

<!--          <font-awesome-icon icon="tags"/>-->

<!--        </div>-->

        <div v-if="isEdit"
             class="icon delete"
             title="Remove"
             @click.stop="deleteAsset('Unit', indexStructureItem, linkedAsset, indexLinkedAsset, indexUnit)"
        >

          <font-awesome-icon class="icon-delete" icon="trash"/>

        </div>

        <div class="icon attachment">
          <font-awesome-icon icon="paperclip"/>
        </div>

        <span>{{ linkedAsset.Label }}</span>

<!--        <div class="attachment-tags">-->

<!--          <div class="tag" v-for="(assetTag, indexAssetTag) in linkedAsset.Tags">-->

<!--            <div class="icon tag">-->
<!--              <font-awesome-icon icon="tag"/>-->
<!--            </div>-->

<!--            <span>{{ assetTag }}</span>-->

<!--            <div class="icon tag" v-if="isEdit" @click.stop="deleteTag('Attachment', indexLinkedAsset, indexAssetTag)">-->
<!--              <font-awesome-icon icon="xmark"/>-->
<!--            </div>-->

<!--          </div>-->

<!--        </div>-->

      </div>

    </div>

    <div v-else
         v-for="(linkedAsset, indexLinkedAsset2) in journeyDetails.Structure[indexStructureItem].LinkedAssets"
         :key="indexLinkedAsset2"
         class="existing-assets">

      <div v-if="linkedAsset.Type === 'Attachment'"
           class="existing-asset"
           @click="downloadAsset(linkedAsset)"
      >

        <div v-if="isEdit"
             :class="{ 'icon': true, 'treasure-chest-asset-flag': true, 'treasure-chest': linkedAsset.IsTreasureChestAsset }"
             @click.stop="toggleTreasureChestFlag(linkedAsset)"
        >

<!--          <div v-if="linkedAsset.IsTreasureChestAsset" class="treasure-chest-options">-->

<!--            <div class="options-wrapper">-->

<!--              <div :class="{ 'option-wrapper': true, 'active-option': linkedAsset.GiveDetails.Event === 'Unlock' }"-->
<!--                   @click.stop="treasureChestOptionClickHandler(linkedAsset, 'Unlock')"-->
<!--              >-->
<!--                <div class="icon">-->
<!--                  <font-awesome-icon icon="lock-open"/>-->
<!--                </div>-->
<!--                <span>Chapter unlock</span>-->
<!--              </div>-->

<!--              <div :class="{ 'option-wrapper': true, 'active-option': linkedAsset.GiveDetails.Event === 'CompleteAssignment' }"-->
<!--                   @click.stop="treasureChestOptionClickHandler(linkedAsset, 'CompleteAssignment')"-->
<!--              >-->
<!--                <div class="icon">-->
<!--                  <font-awesome-icon icon="list-check"/>-->
<!--                </div>-->
<!--                <span>Assignment</span>-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->

          <font-awesome-icon class="icon-gem" icon="gem"/>

        </div>

<!--        <div class="icon tags"-->
<!--             v-if="isEdit"-->
<!--             @click.stop="addAssetTags(indexStructureItem, indexUnit, indexLinkedAsset2)"-->
<!--        >-->

<!--          <font-awesome-icon icon="tags"/>-->

<!--        </div>-->

        <div v-if="isEdit"
             class="icon delete"
             title="Remove"
             @click.stop="deleteAsset('StructureItem', indexStructureItem, linkedAsset, indexLinkedAsset2, null)"
        >

          <font-awesome-icon class="icon-delete" icon="trash"/>

        </div>

        <div class="icon attachment">
          <font-awesome-icon icon="paperclip"/>
        </div>

        <span>{{ linkedAsset.Label }}</span>

<!--        <div class="attachment-tags">-->

<!--          <div class="tag" v-for="(assetTag2, indexAssetTag2) in linkedAsset.Tags">-->

<!--            <div class="icon tag">-->
<!--              <font-awesome-icon icon="tag"/>-->
<!--            </div>-->

<!--            <span>{{ assetTag2 }}</span>-->

<!--            <div class="icon tag" v-if="isEdit" @click.stop="deleteTag('Attachment', indexLinkedAsset2, indexAssetTag2)">-->
<!--              <font-awesome-icon icon="xmark"/>-->
<!--            </div>-->

<!--          </div>-->

<!--        </div>-->


<!--        <div class="attachment-tags">-->

<!--          <div class="tag" v-for="(assetTag2, indexAssetTag2) in linkedAsset.Tags">-->

<!--            <div class="icon tag">-->
<!--              <font-awesome-icon icon="tag"/>-->
<!--            </div>-->

<!--            <span>{{ assetTag2 }}</span>-->

<!--            <div class="icon tag" v-if="isEdit" @click.stop="deleteTag('Attachment', indexLinkedAsset2, indexAssetTag2)">-->
<!--              <font-awesome-icon icon="xmark"/>-->
<!--            </div>-->

<!--          </div>-->

<!--        </div>-->

      </div>

    </div>

  </div>

</template>

<script>

import createFileObject from "@/helpers/createFileObject";
import {useStore} from "vuex";
import {inject, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {

  name: 'AttachmentUpload',

  components: {
    FontAwesomeIcon
  },

  emits: ['addAssetTags', 'deleteTag'],

  props: {
    structureItemID: {
      default: null
    },
    indexStructureItem: {
      default: null
    },
    indexUnit: {
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, {emit}) {

    const store = useStore();

    const journeyDetails = inject('journeyDetails');

    const selectedStructureIndex = ref(null);

    const selectedUnitIndex = ref(null);

    const selectedTag = ref(['']);

    const newTagInput = ref([]);

    const loadAssets = async (ev) => {

      await store.dispatch('loading/setLoading', true);

      let files = ev.target.files;

      for (let i = 0; i < files.length; i++) {

        const file = files[i];

        let file_object = await createFileObject(file);

        let linkedAssetObject = {
          Type: 'Attachment',
          Name: file_object.file.name,
          Label: file_object.file.label,
          Tags: [],
          Url: file_object.file.url,
          Ext: file_object.file.ext,
          File_type: file_object.file.type,
          File: file_object.file.file,
          IsNew: file_object.file.isNew,
          IsTreasureChestAsset: false,
        };

        if (props.indexUnit !== null) {
          journeyDetails.value.Structure[props.indexStructureItem].Units[props.indexUnit].LinkedAssets.push(linkedAssetObject);
        }
        else {
          journeyDetails.value.Structure[props.indexStructureItem].LinkedAssets.push(linkedAssetObject);
        }

      }

      await store.dispatch('loading/setLoading', false);

    };

    const toggleTreasureChestFlag = (linkedAsset) => {

      linkedAsset.IsTreasureChestAsset = !linkedAsset.IsTreasureChestAsset;

      if (linkedAsset.IsTreasureChestAsset) {
        linkedAsset.GiveDetails = {
          Event: 'Unlock',
          IsGiven: false,
        }
      }
      else {
        delete linkedAsset.GiveDetails;
      }

    };

    const treasureChestOptionClickHandler = (linkedAsset, eventName) => {

      switch (eventName) {

        case 'Unlock': {
          linkedAsset.GiveDetails = {
            Event: eventName,
            IsGiven: false
          };
          break;
        }

        case 'CompleteAssignment': {
          linkedAsset.GiveDetails = {
            Event: eventName,
          };
          break;
        }

        default: {
          break;
        }
      }
    };

    const downloadAsset = async (linkedAsset) => {

      let url = null;

      if (linkedAsset.hasOwnProperty('Url')) {
        url = linkedAsset.Url;
      }
      else {

        let payload = {
          file_name: linkedAsset.Name,
          file_label: linkedAsset.Label,
          folder_name: `journey/${journeyDetails.value.ID}/assets`
        };

        url = await store.dispatch('file/getFile', payload);
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', linkedAsset.Label);
      link.click();
    };

    const deleteAsset = async (fromWhere, indexStructureItem, linkedAsset, indexLinkedAsset, indexUnit = null) => {

      if (!linkedAsset.hasOwnProperty('IsNew')) {
        journeyDetails.value.ExistingAssetsToDelete.push(linkedAsset.Name);
      }

      if (fromWhere === 'Unit') {
        journeyDetails.value.Structure[indexStructureItem].Units[indexUnit].LinkedAssets.splice(indexLinkedAsset, 1);
      }
      if (fromWhere === 'StructureItem') {
        journeyDetails.value.Structure[indexStructureItem].LinkedAssets.splice(indexLinkedAsset, 1);
      }

    };

    const getAllTags = (fromWhere) => {
      const allTags = [];
      if (fromWhere === 'Unit') {
        const currentStructure = journeyDetails.value.Structure[selectedStructureIndex.value];
        const currentUnit = currentStructure.Units[selectedUnitIndex.value];
        currentUnit.LinkedAssets.forEach((asset) => {
          if (asset.Tags) {
            asset.Tags.forEach((tag) => {
              if (!allTags.includes(tag)) {
                allTags.push(tag);
              }
            });
          }
        });
      }
      else if (fromWhere === 'StructureItem') {
        const currentStructure = journeyDetails.value.Structure[selectedStructureIndex.value];
        currentStructure.LinkedAssets.forEach((asset) => {
          if (asset.Tags) {
            asset.Tags.forEach((tag) => {
              if (!allTags.includes(tag)) {
                allTags.push(tag);
              }
            });
          }
        });
      }

      return allTags;
    };

    const addAssetTags = (indexStructureItem, indexUnit, indexAsset) => {
      emit('addAssetTags', indexAsset);
    };

    const deleteTag = (assetType, indexAsset, indexTag) => {
      emit('deleteTag', assetType, indexAsset, indexTag);
    };

    return {
      journeyDetails,
      selectedTag,
      newTagInput,
      loadAssets,
      toggleTreasureChestFlag,
      treasureChestOptionClickHandler,
      downloadAsset,
      deleteAsset,
      getAllTags,
      addAssetTags,
      // handleAddAssetTag,
      deleteTag
    }
  }
}

</script>

<style lang="scss" scoped>

.asset-upload {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  .existing-assets {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    .existing-asset {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      color: var(--bs-body-color);
      text-decoration: unset;
      background-color: #fafafa;
      width: 100%;
      padding: 15px;
      border-radius: 10px;
      cursor: pointer;
      -webkit-box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.3);
      min-width: 130px;

      .icon.treasure-chest-asset-flag {
        position: absolute;
        top: 7px;
        left: 7px;
        font-size: 20px;
        align-self: flex-start;
        cursor: default;

        .icon-gem {
          opacity: 0.5;
          cursor: pointer;
        }

        .treasure-chest-options {
          display: none;
          position: absolute;
          bottom: 20px;
          left: 0;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          padding: 10px;
          background: white;
          border-radius: 10px;
          z-index: 1;

          .options-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 5px;

            .option-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 5px;
              font-size: 18px;
              cursor: pointer;
              opacity: 0.5;
              width: 100%;

              &.active-option {
                opacity: 1;
                font-weight: bold;
              }

              span {
                white-space: nowrap;
              }

            }

          }

        }

        &.treasure-chest {

          //&:hover {
          //  .treasure-chest-options {
          //    display: flex;
          //  }
          //}

          .icon-gem {
            opacity: 1;
          }
        }

      }

      .icon.delete {
        position: absolute;
        top: 7px;
        right: 7px;
        font-size: 16px;
        align-self: flex-start;
        cursor: pointer;
      }

      .icon.attachment {
        font-size: 30px;
      }

      .attachment-tags {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3px;

        .tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 3px;
          background-color: lightgrey;
          border-radius: 5px;
          font-size: 13px;
          width: fit-content;
          padding: 5px;
        }
      }

      .icon {

        &.tags {
          position: absolute;
          top: 7px;
          left: 35px;
          font-size: 21px;
          align-self: flex-start;
          cursor: default;
        }

        &.tag {
          font-size: 10px;
          color: black;
          padding: 0;
        }
      }
    }
  }

  .icon.upload {
    font-size: 30px;
  }

}

</style>
