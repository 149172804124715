<template>

  <Tabs :preselectedTab="preselectedTab">

    <Tab title="Billing Address">

      <form @submit.prevent="onSubmit" class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="form-label">
              Country*
            </label>
            <select name="countries" id="countries" v-model="country" :class="[countryError ? 'is-invalid' : '']">
              <option v-for="(countryName, indexCountry) in countryArray" :value="countryName.name.common">{{ countryName.name.common }}</option>
            </select>
          </div>
        </div>
        <!--          <div class="col-12 col-md-6">-->
        <!--            <div class="form-group">-->
        <!--              <label class="form-label">-->
        <!--                Country-->
        <!--              </label>-->
        <!--              <input-->
        <!--                  id="country"-->
        <!--                  name="country"-->
        <!--                  type="text"-->
        <!--                  v-model="country"-->
        <!--                  :class="[countryError ? 'is-invalid' : '']"-->
        <!--                  class="form-control mb-3"-->
        <!--              />-->
        <!--              <div class="invalid-feedback">{{ countryError }}</div>-->
        <!--            </div>-->
        <!--          </div>-->

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="form-label">
              City*
            </label>

            <input
                id="city"
                name="city"
                type="tel"
                v-model="city"
                :class="[cityError ? 'is-invalid' : '']"
                class="form-control mb-3"
            />
            <div class="invalid-feedback">{{ cityError }}</div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="form-label">
              Address Line 1*
            </label>

            <input
                id="addressLine1"
                name="addressLine1"
                type="tel"
                v-model="addressLine1"
                :class="[addressLine1Error ? 'is-invalid' : '']"
                class="form-control mb-3"
            />
            <div class="invalid-feedback">{{ addressLine1Error }}</div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="form-label">
              Address Line 2
            </label>

            <input
                id="addressLine2"
                name="addressLine2"
                type="tel"
                v-model="addressLine2"
                :class="[addressLine2Error ? 'is-invalid' : '']"
                class="form-control mb-3"
            />
            <div class="invalid-feedback">{{ addressLine2Error }}</div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="form-label">
              Postal Code*
            </label>

            <input
                id="postalCode"
                name="postalCode"
                type="tel"
                v-model="postalCode"
                :class="[postalCodeError ? 'is-invalid' : '']"
                class="form-control mb-3"
            />
            <div class="invalid-feedback">{{ postalCodeError }}</div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="form-label">
              Province*
            </label>

            <input
                id="province"
                name="province"
                type="tel"
                v-model="province"
                :class="[provinceError ? 'is-invalid' : '']"
                class="form-control mb-3"
            />
            <div class="invalid-feedback">{{ provinceError }}</div>
          </div>
        </div>
      </form>

      <div class="invalid-feedback" v-if="errorMessage">{{ errorMessage }}</div>

      <button class="btn btn-primary" @click="saveBillingAddress()">Save</button>

    </Tab>
    <Tab title="Commovis Credits">
      <div class="billing-wrapper" v-if="hasContentLoaded">

        <!--    <div v-if="isAddCreditsDisplayed" class="current-amount">-->

        <!--      <div class="icon">-->
        <!--        <font-awesome-icon icon="coins"/>-->
        <!--      </div>-->

        <!--      <div class="bold">{{ userAmount }} CC</div>-->

        <!--    </div>-->
        <div>
          <div v-if="isAddCreditsDisplayed" class="current-amount">
            <div v-if="billingAddressOrNameError" class="invalid-feedback" style="text-align: center;">
              {{ billingAddressOrNameError }}
            </div>

            <h2 class="title">COMMOVIS Credits (CC)</h2>

            <p class="description">
              "You currently have <span class="bold">{{ userAmount }}</span> COMMOVIS Credits (CC), covering <span class="bold">{{ calculateNumberOfNewUsersWithCurrentAmount() }}</span> new users for 1 year. Use this handy form to calculate how many credits you'll need for the number of users you'd like to enroll."
            </p>

          </div>

          <div v-if="isAddCreditsDisplayed" class="calculation">

            <div class="calculation__row">

              <div class="calculation__element">
                <input type="number"
                       v-model.number="equivalentNewUsers"
                       @keydown="checkKey($event)"
                       @input="calculateNeededCommovisCredits()"
                >
                <div class="calculation__element-text">equivalent of new users for 1 year</div>
              </div>

              <div class="calculation__sign equivalent">
                <span>≙</span>
              </div>

              <div class="calculation__element">
                <input type="number"
                       v-model.number="numberOfCommovisCredits"
                       @keydown="checkKey($event)"
                       @input="calculateEquivalentNewUsers()"
                >
                <div class="calculation__element-text">CC</div>
              </div>

              <div class="calculation__sign multiplication">
                <span>X</span>
              </div>

              <div class="calculation__element">
                <div class="calculation__disabled-element">{{ euroExchangeRate }} €</div>
                <div class="calculation__element-text">cost for 1 CC</div>
              </div>

              <div class="calculation__sign equal">
                <span>=</span>
              </div>

              <div class="calculation__element">
                <div class="calculation__disabled-element">{{ totalAmountInEuro }} €</div>
                <div class="calculation__element-text">total</div>
              </div>

            </div>

            <div class="calculation__separator">

            </div>

            <div class="calculation__total">
              <span>Total: </span>
              <span>{{ totalAmountInEuro }} €</span>
            </div>

            <div class="calculation__buy-action">
              <button @click="buyCC()" class="btn btn-primary buy-button">
                <font-awesome-icon icon="cart-shopping"/>
                <span>Buy</span>
              </button>

              <!--                <button class="btn btn-primary" @click="buyCC()"> click</button>-->
            </div>

            <!--      <div class="calculation__row">-->

            <!--        <div class="calculation__element first">-->
            <!--          <input type="number"-->
            <!--                 v-model.number="equivalentNewUsers"-->
            <!--                 @keydown="checkKey($event)"-->
            <!--                 @input="calculateNeededCommovisCredits()"-->
            <!--          >-->
            <!--          <div class="calculation__element-text">equivalent of new users for 1 year</div>-->
            <!--        </div>-->

            <!--        <div class="calculation__sign">-->
            <!--          <font-awesome-icon class="icon equals" icon="equals"/>-->
            <!--        </div>-->

            <!--        <div class="calculation__element">-->
            <!--          <input type="number"-->
            <!--                 v-model.number="numberOfCommovisCredits"-->
            <!--                 @keydown="checkKey($event)"-->
            <!--                 @input="calculateEquivalentNewUsers()"-->
            <!--          >-->
            <!--          <div class="calculation__element-text">CC</div>-->
            <!--        </div>-->

            <!--      </div>-->

            <!--      <div class="calculation__row right-content">-->

            <!--        <div class="calculation__element">-->
            <!--          <div class="calculation__disabled-element">{{ numberOfCommovisCredits }}</div>-->
            <!--          <div class="calculation__element-text"></div>-->
            <!--        </div>-->

            <!--        <div class="calculation__sign">-->
            <!--          <font-awesome-icon class="icon equals" icon="equals"/>-->
            <!--        </div>-->

            <!--        <div class="calculation__element">-->
            <!--          <div class="calculation__disabled-element">{{ euroExchangeRate }} €</div>-->
            <!--          <div class="calculation__element-text">cost for 1 CC</div>-->
            <!--        </div>-->

            <!--      </div>-->

            <!--      <div class="calculation__additional-info right-content">-->

            <!--      </div>-->

            <!--      <div class="calculation__separator right-content">-->

            <!--      </div>-->

            <!--      <div class="calculation__total right-content">-->
            <!--        <span>Total: </span>-->
            <!--        <span>{{ totalAmountInEuro }} €</span>-->
            <!--      </div>-->

            <!--      <div class="calculation__buy-action right-content">-->
            <!--        <button @click="buyClickHandler()" class="btn btn-primary buy-button">-->
            <!--          <font-awesome-icon icon="cart-shopping"/>-->
            <!--          <span>Buy</span>-->
            <!--        </button>-->
            <!--      </div>-->

          </div>

          <div v-if="!isAddCreditsDisplayed" class="no-add-credits">
            <p>Currently you have a payment plan that doesn't require to add credits from this level.</p>
          </div>
        </div>

      </div>
    </Tab>


  </Tabs>

</template>

<script>

import {computed, nextTick, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {calculateDailyPrice, calculateDaysBetween} from "@/helpers/priceCalculations";
import {useField, useForm} from "vee-validate";
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import axios from "axios";

export default {

  name: 'Billing',

  components: {
    FontAwesomeIcon,
    Tab,
    Tabs,
  },

  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const paymentOptionFullName = ref('');

    const isAddCreditsDisplayed = ref(false);

    const numberOfCommovisCredits = ref(12);

    const equivalentNewUsers = ref(1);

    const totalAmountInEuro = ref(0);

    const currentPage = ref('Account');

    const billingAddressOrNameError = ref('');

    const preselectedTab = ref('');

    const userAmount = computed(() => store.getters["internalCurrency/getUserAmount"]);

    const paymentOption = computed(() => store.getters["tenant/getPaymentOption"]);

    const euroExchangeRate = computed(() => store.getters["tenant/getEuroExchangeRate"]);

    const userRoleCost = computed(() => store.getters["internalCurrency/getUserRoleCost"]);

    const userExtraAttributes = computed(() => store.getters["userPreferences/getUserExtraAttributes"]);

    const profileSchema = {
      country(value) {
        const country = value
        if (
            undefined == country ||
            country.length == 0
        ) {
          return true
        }
        return 'choose country'
      },
      city(value) {
        const city = value
        if (
            undefined == city ||
            city.length >= 3 ||
            city.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      addressLine1(value) {
        const addressLine1 = value
        if ( addressLine1 && addressLine1.length >= 3 ) {
          return true
        }
        return 'minimum 3 characters'
      },
      addressLine2(value) {
        const addressLine2 = value
        if (
            undefined == addressLine2 ||
            addressLine2.length >= 3 ||
            addressLine2.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      postalCode(value) {
        const postalCode = value
        if (
            undefined == postalCode ||
            postalCode.length >= 3 ||
            postalCode.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
      province(value) {
        const province = value
        if (
            undefined == province ||
            province.length >= 3 ||
            province.length == 0
        ) {
          return true
        }
        return 'minimum 3 characters'
      },
    };

    const currentBillingInfo = ref({});


    const { handleSubmit, setValues } = useForm({
      validationSchema: profileSchema,
      initialValues: {
        country: currentBillingInfo.value.country,
        city: currentBillingInfo.value.city,
        addressLine1: currentBillingInfo.value.addressLine1,
        addressLine2: currentBillingInfo.value.addressLine2,
        postalCode: currentBillingInfo.value.postalCode,
        province: currentBillingInfo.value.province,
      }
    })

    const countryArray = ref([])

    const { value: country, errorMessage: countryError } = useField('country');

    const { value: city, errorMessage: cityError } = useField('city');

    const { value: addressLine1, errorMessage: addressLine1Error } = useField('addressLine1');

    const { value: addressLine2, errorMessage: addressLine2Error } = useField('addressLine2');

    const { value: postalCode, errorMessage: postalCodeError } = useField('postalCode');

    const { value: province, errorMessage: provinceError } = useField('province');

    const errorMessage = ref('');

    const onSubmit = handleSubmit( async(values) => {
      //NULL values aren't allowed
      let check_values = {}
      for (var prop in values) {
        if (values[prop] == undefined) {
          check_values[prop] = ''
        } else {
          check_values[prop] = values[prop]
        }
      }

      await store.dispatch('loading/setLoading', true);

    });

    const checkRequiredFields = () => {
      return (city.value && city.value.length > 3) && (country.value && country.value.length > 3) && (addressLine1.value && addressLine1.value.length > 3) && (postalCode.value && postalCode.value.length > 3) && (province.value && province.value.length > 3)
    }

    const saveBillingAddress = async () => {

      if (checkRequiredFields()) {
        errorMessage.value = '';
        billingAddressOrNameError.value = '';
        await store.dispatch('loading/setLoading', true);

        const payload = {
          Billing_City: city.value !== undefined ? city.value : '',
          Billing_Country: country.value !== undefined ? country.value : '',
          Billing_AddressLine1: addressLine1.value !== undefined ? addressLine1.value : '',
          Billing_AddressLine2: addressLine2.value !== undefined ? addressLine2.value : '',
          Billing_PostalCode: postalCode.value !== undefined ? postalCode.value : '',
          Billing_Province: province.value !== undefined ? province.value : '',
        }

        await store.dispatch('userPreferences/updateUserExtraAttributes', payload);
        await store.dispatch('loading/setLoading', false);
      } else {
        errorMessage.value = 'Make sure you completed all required fields';
      }
    }

    const getBillingAddressData = () => {
      if (userExtraAttributes.value){
        if (userExtraAttributes.value.Billing_Country) {
          country.value = userExtraAttributes.value.Billing_Country
        }
        if (userExtraAttributes.value.Billing_City) {
          city.value = userExtraAttributes.value.Billing_City
        }
        if (userExtraAttributes.value.Billing_AddressLine1) {
          addressLine1.value = userExtraAttributes.value.Billing_AddressLine1
        }
        if (userExtraAttributes.value.Billing_AddressLine2) {
          addressLine2.value = userExtraAttributes.value.Billing_AddressLine2
        }
        if (userExtraAttributes.value.Billing_PostalCode) {
          postalCode.value = userExtraAttributes.value.Billing_PostalCode
        }
        if (userExtraAttributes.value.Billing_Province) {
          province.value = userExtraAttributes.value.Billing_Province
        }
      }
    }

    const checkBillingAddress = () => {
      if ( userExtraAttributes.value && userExtraAttributes.value.hasOwnProperty('Billing_Country') && userExtraAttributes.value.Billing_Country &&  userExtraAttributes.value.Billing_Country.length > 0 &&
          userExtraAttributes.value && userExtraAttributes.value.hasOwnProperty('Billing_City') && userExtraAttributes.value.Billing_City &&  userExtraAttributes.value.Billing_City.length > 0 &&
          userExtraAttributes.value && userExtraAttributes.value.hasOwnProperty('Billing_AddressLine1') && userExtraAttributes.value.Billing_AddressLine1 &&  userExtraAttributes.value.Billing_AddressLine1.length > 0 &&
          userExtraAttributes.value && userExtraAttributes.value.hasOwnProperty('Billing_PostalCode') && userExtraAttributes.value.Billing_PostalCode &&  userExtraAttributes.value.Billing_PostalCode.length > 0 &&
          userExtraAttributes.value && userExtraAttributes.value.hasOwnProperty('Billing_Province') && userExtraAttributes.value.Billing_Province &&  userExtraAttributes.value.Billing_Province.length > 0
      ) {
        return true
      } else {
        return false
      }
    }

    const buyCC = async () => {
      if (checkFields()) {
        const name = store.state.auth.userInfo.given_name;

        if (checkBillingAddress()) {
          if (name) {
            billingAddressOrNameError.value = '';
            await getPaymentLink();
          } else {
            billingAddressOrNameError.value = 'You need to complete your name in \'Settings\' first';
          }

        } else {
          // complete billing address
          billingAddressOrNameError.value = 'You need to complete billing address first';
        }
      }
    }

    const paymentOptionSettings = () => {


      switch (paymentOption.value) {
        case 'FR': {
          paymentOptionFullName.value = 'Flat Rate';
          isAddCreditsDisplayed.value = false;
          break;
        }
        case 'T': {
          paymentOptionFullName.value = 'Tenant Only';
          isAddCreditsDisplayed.value = false;
          break;
        }
        case 'TF': {
          paymentOptionFullName.value = 'Tenant-Facilitator';
          isAddCreditsDisplayed.value = true;
          break;
        }
        case 'F': {
          paymentOptionFullName.value = 'Facilitator Only';
          isAddCreditsDisplayed.value = false;
          break;
        }
        default: {
          paymentOptionFullName.value = '';
          break;
        }
      }

    };

    const calculateEquivalentNewUsers = () => {

      nextTick(() => {

        if (typeof numberOfCommovisCredits.value === 'number') {

          const currentDate = new Date();
          const expirationDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

          // TODO: should it be adapted to role or always Participant?
          let monthlyPrice = userRoleCost.value.Participant;

          const dailyPrice = calculateDailyPrice(monthlyPrice);
          const daysBetween = calculateDaysBetween(currentDate, expirationDate);
          let totalNewUsers = divide(numberOfCommovisCredits.value, (dailyPrice * daysBetween));

          if (totalNewUsers !== null) {
            let totalNewUsersFixed = totalNewUsers.toFixed(2);
            equivalentNewUsers.value = parseFloat(totalNewUsersFixed);
          } else {
            equivalentNewUsers.value = 0;
          }

          equivalentNewUsers.value = Math.floor(equivalentNewUsers.value);

          calculateTotalInEuro();

        } else {
          equivalentNewUsers.value = 0;
          calculateTotalInEuro();
        }

      });

    };

    const calculateNeededCommovisCredits = () => {

      nextTick(() => {

        if (typeof equivalentNewUsers.value === 'number') {

          const currentDate = new Date();
          const expirationDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

          // TODO: should it be adapted to role or always Participant?
          let monthlyPrice = userRoleCost.value.Participant;

          const dailyPrice = calculateDailyPrice(monthlyPrice);
          const daysBetween = calculateDaysBetween(currentDate, expirationDate);
          let totalPrice = dailyPrice * daysBetween * equivalentNewUsers.value;
          let totalPriceFixed = totalPrice.toFixed(2);

          numberOfCommovisCredits.value = parseFloat(totalPriceFixed);

          calculateTotalInEuro();

        } else {
          numberOfCommovisCredits.value = 0;
          calculateTotalInEuro();
        }

      });

    };

    const calculateTotalInEuro = () => {
      if (typeof numberOfCommovisCredits.value !== 'number' || typeof euroExchangeRate.value !== 'number') {
        totalAmountInEuro.value = 0;
      } else {
        totalAmountInEuro.value = numberOfCommovisCredits.value * euroExchangeRate.value;
      }
    };

    const checkFields = () => {
      if (numberOfCommovisCredits.value < 1) {
        store.dispatch('notifications/addNotification', {
          type: 'error',
          message: 'Added CCs must be greater than 0'
        })
        return false;
      }
      return true;
    };

    const buyClickHandler = async () => {
      if (checkFields()) {
        await getPaymentLink();
      }
    };

    const getPaymentLink = async () => {
      const originLink = window.location.origin;
      const URL = await store.dispatch('internalCurrency/getPaymentLink', {
        NumberOfCredits: numberOfCommovisCredits.value,
        RedirectLinkSuccess: `${originLink}/payment-success?session_id={CHECKOUT_SESSION_ID}`,
        RedirectLinkCancel: `${originLink}/payment-canceled`,
        TenantLink: `${originLink}`,
      });
      const invoiceItemsAmount = totalAmountInEuro.value * 100;
      localStorage.setItem('invoiceItems_amount', invoiceItemsAmount.toString())
      localStorage.setItem('invoiceItems_country', country.value.toString())
      localStorage.setItem('fromPage', currentPage.value);
      location.href = URL;
    };

    const divide = (divident, divisor) => {

      if (divident !== null && divisor !== null) {

        if (divisor !== 0) {
          let result = parseFloat(divident) / parseFloat(divisor);

          return result;
        } else {
          return 0;
        }

      } else {
        return null;
      }

    };

    //don't allow '.', ',', '-' and 'e'
    const checkKey = (ev) => {
      if (ev.key === '.' || ev.key === ',' || ev.key === '-' || ev.key === 'e') {
        ev.preventDefault();
      } else {
        return true;
      }
    };

    const calculateNumberOfNewUsersWithCurrentAmount = () => {

      let equivalentNewUsersOfAvailableAmount = 0;

      if (typeof userAmount.value === 'number') {

        const currentDate = new Date();
        const expirationDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

        // TODO: should it be adapted to role or always Participant?
        let monthlyPrice = userRoleCost.value.Participant;

        const dailyPrice = calculateDailyPrice(monthlyPrice);
        const daysBetween = calculateDaysBetween(currentDate, expirationDate);
        let totalNewUsers = divide(userAmount.value, (dailyPrice * daysBetween));

        if (totalNewUsers !== null) {
          let totalNewUsersFixed = totalNewUsers.toFixed(2);
          equivalentNewUsersOfAvailableAmount = parseFloat(totalNewUsersFixed);
        }
        else {
          equivalentNewUsersOfAvailableAmount = 0;
        }

      }
      else {
        equivalentNewUsersOfAvailableAmount = 0;
      }

      return Math.floor(equivalentNewUsersOfAvailableAmount);

    };

    const sortCountries = () => {
      // Return the countries array sorted by common name
      return countryArray.value.sort((a, b) => {
        if (a.name.common < b.name.common) return -1;
        if (a.name.common > b.name.common) return 1;
        return 0;
      });
    }

    const getAllContries = async () => {
      // Fetch data from external API
      const response = await axios.get('https://restcountries.com/v3.1/all');
      countryArray.value = response.data;
      const sortedCountries = sortCountries(countryArray.value);
      countryArray.value = sortedCountries;
    }
    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);
      await getAllContries();
      getBillingAddressData();
      localStorage.removeItem('fromPage');
      paymentOptionSettings();
      calculateTotalInEuro();
      await store.dispatch('loading/setLoading', false);
      hasContentLoaded.value = true;
    });

    return {
      hasContentLoaded,
      userAmount,
      paymentOption,
      paymentOptionFullName,
      isAddCreditsDisplayed,
      equivalentNewUsers,
      numberOfCommovisCredits,
      euroExchangeRate,
      totalAmountInEuro,
      paymentOptionSettings,
      buyClickHandler,
      calculateNeededCommovisCredits,
      calculateEquivalentNewUsers,
      calculateTotalInEuro,
      checkKey,
      calculateNumberOfNewUsersWithCurrentAmount,
      buyCC,
      billingAddressOrNameError,
      country,
      countryError,
      preselectedTab,
      addressLine1,
      addressLine1Error,
      addressLine2,
      addressLine2Error,
      postalCode,
      postalCodeError,
      province,
      provinceError,
      city,
      cityError,
      onSubmit,
      saveBillingAddress,
      countryArray,
      errorMessage
    }
  }

}

</script>

<style lang="scss" scoped>

$calculation_element_width: 150px;
$calculation_sign_width: 20px;
$calculation_row_gap: 15px;
select {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.billing-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;

  .bold {
    font-weight: bold;
  }

  .current-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    //width: fit-content;
    //padding: 15px;
    //border: 3px solid black;
    //border-radius: 50%;
    //align-self: flex-end;
    //margin-right: 50px;

    .description {
      width: 90%;
      text-align: center;
    }

    .icon {
      font-size: 30px;
      cursor: default;
    }
  }

  .calculation {
    //width: calc($calculation_element_width + $calculation_sign_width + $calculation_element_width + $calculation_sign_width + $calculation_element_width + ($calculation_row_gap * 4));
    //max-width: calc($calculation_element_width + $calculation_sign_width + $calculation_element_width + $calculation_sign_width + $calculation_element_width + ($calculation_row_gap * 4));
    max-width: 90%;
    margin: 0 auto;

    .right-content {
      margin-left: calc($calculation_element_width + $calculation_sign_width + ($calculation_row_gap * 2));
    }

    & > div {
      margin-bottom: 5px;
      justify-content: center;
    }

    &__row {
      display: flex;
      align-self: center;
      justify-content: flex-start;
      gap: $calculation_row_gap;

    }

    &__element {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      width: $calculation_element_width;
      padding-top: 5px;
    }

    &__element-text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex: 1 1 auto;
      text-align: center;
      width: 100%;
      padding: 5px;
    }

    input {
      border: 1px solid rgb(118, 118, 118);
    }

    input, &__disabled-element {
      width: 100%;
      height: 30px;
      text-align: center;
    }

    &__disabled-element {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 16px;
      border: 1px solid #d6d6d6;
      background-color: #e9e3e3;
    }

    &__sign {

      &.equivalent {
        font-size: 30px;
      }

      &.multiplication {
        font-size: 20px;
        padding-top: 5px;
      }

      &.equal {
        font-size: 20px;
        padding-top: 5px;
      }

      //width: $calculation_sign_width;
      //.icon {
      //  width: 100%;
      //}
    }

    &__additional-info {
      min-height: 25px;
    }

    &__separator {
      height: 3px;
      background-color: black;
      border-radius: 15px;
      margin-top: 5px;
    }

    &__total {
      text-align: right;
      font-weight: bold;
      font-size: 20px;
    }

    &__buy-action {
      text-align: right;

      .buy-button {

        span {
          margin-left: 5px;
        }

      }

    }

  }

  .no-add-credits {
    align-self: flex-start;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

}

</style>
