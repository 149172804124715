<template>

  <div class="chapter-content">

    <div class="chapter_name">
      <h1 v-if="!isEdit.chapterName && (journeyDetails.Structure[indexStructureItem].Name !== null && journeyDetails.Structure[indexStructureItem].Name !== '')"
          @click="startEditing('ChapterName')">{{ journeyDetails.Structure[indexStructureItem].Name }}</h1>
      <input
          v-if="isEdit.chapterName || (journeyDetails.Structure[indexStructureItem].Name === null || journeyDetails.Structure[indexStructureItem].Name === '')"
          type="text"
          placeholder="Chapter name"
          :value="journeyDetails.Structure[indexStructureItem].Name"
          @input="updateValue($event, 'ChapterName')"
          @blur="stopEditing('ChapterName')"
          ref="chapterNameElement"
          class="editable-input"
      />
      <div v-if="journeyDetails.Structure[indexStructureItem].IsHidden"
           class="icon eye"
           title="Show chapter"
           @click="toggleIsHidden(indexStructureItem)"
      >
        <font-awesome-icon icon="eye-slash"/>
      </div>

      <div v-else
           class="icon eye"
           title="Hide chapter"
           @click="toggleIsHidden(indexStructureItem)"
      >
        <font-awesome-icon icon="eye"/>
      </div>
    </div>

    <div class="units-wrapper">

      <div v-for="(unit, indexUnit) in journeyDetails.Structure[indexStructureItem].Units"
           :key="indexUnit"
           class="unit">

        <input type="text"
               placeholder="Unit name"
               v-model="unit.Name"
               @input="unitChangeHandler(indexStructureItem, indexUnit)"
        >

        <div v-if="isCurrentUserAuthorOfJourney
        && journeyDetails.Structure[indexStructureItem].Units.length > 2
        && indexUnit !== journeyDetails.Structure[indexStructureItem].Units.length - 1"
             class="icon delete"
             title="Delete unit"
             @click="deleteUnit(structureItemID, indexStructureItem, indexUnit)"
        >
          <font-awesome-icon icon="trash"/>
        </div>

      </div>

    </div>

  </div>


</template>

<script>

import {inject, nextTick, onMounted, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {v4 as uuidV4} from "uuid";

export default {

  name: 'JourneyChapter',

  components: {
    FontAwesomeIcon
  },

  props: {
    structureItemID: {
      required: true,
    },
    indexStructureItem: {
      required: true,
    },
  },

  setup(props) {

    const journeyDetails = inject('journeyDetails');

    const isCurrentUserAuthorOfJourney = inject('isCurrentUserAuthorOfJourney');

    // chapter name edit

    const isEdit = ref({
      chapterName: false,
    });

    const chapterNameElement = ref(null);

    const startEditing = (element) => {

      if (element === 'ChapterName') {
        isEdit.value.chapterName = true;
        nextTick(() => {
          chapterNameElement.value.focus();
        });
      }

    };

    const stopEditing = (element) => {

      if (element === 'ChapterName' && journeyDetails.value.Structure[props.indexStructureItem].Name !== null && journeyDetails.value.Structure[props.indexStructureItem].Name !== '') {
        isEdit.value.chapterName = false;
      }

    };

    const updateValue = (event, element) => {

      if (element === 'ChapterName') {
        journeyDetails.value.Structure[props.indexStructureItem].Name = event.target.value;
      }

    };

    //end of chapter name edit


    const toggleIsHidden = (indexStructureItem) => {
      journeyDetails.value.Structure[indexStructureItem].IsHidden = !journeyDetails.value.Structure[indexStructureItem].IsHidden;
    };

    const isUnitEmpty = (unit) => {
      return (unit.Name === '' && unit.Blocks[0].Elements.length === 0);
    };

    const unitChangeHandler = (indexStructureItem, indexUnit) => {

      let remainingNumberOfUnits = journeyDetails.value.Structure[indexStructureItem].Units.length;

      let isLastUnit = (indexUnit === (remainingNumberOfUnits - 1));
      let isPenultimateUnit = (indexUnit === (remainingNumberOfUnits - 2));

      let unit = journeyDetails.value.Structure[indexStructureItem].Units[indexUnit];

      if (isLastUnit && !isUnitEmpty(unit)) {

        journeyDetails.value.Structure[indexStructureItem].Units.forEach((unit) => {
          if (unit.hasOwnProperty('Temp')) {
            delete unit.Temp;
          }
        });

        let unitObject = {
          ID: uuidV4(),
          Name: '',
          LinkedAssets: [],
          Blocks: [{
            Elements: [],
            Time: null,
            Version: null
          }],
          Temp: true,
        };
        journeyDetails.value.Structure[indexStructureItem].Units.push(unitObject);
      }

      if (isPenultimateUnit && isUnitEmpty(unit)) {
        journeyDetails.value.Structure[indexStructureItem].Units.pop();
        let lastIndex = journeyDetails.value.Structure[indexStructureItem].Units.length - 1;
        journeyDetails.value.Structure[indexStructureItem].Units[lastIndex].Temp = true;
      }

    };

    const deleteUnit = (structureItemID, indexStructureItem, indexUnit) => {
      journeyDetails.value.Structure[indexStructureItem].Units.splice(indexUnit, 1);
    };

    onMounted(async() => {

      if (journeyDetails.value.Structure[props.indexStructureItem].Name === null || journeyDetails.value.Structure[props.indexStructureItem].Name === '') {
        isEdit.value.chapterName = true;
      }

    });

    return {
      journeyDetails,
      isCurrentUserAuthorOfJourney,
      isEdit,
      chapterNameElement,
      startEditing,
      stopEditing,
      updateValue,
      toggleIsHidden,
      unitChangeHandler,
      deleteUnit,
    }
  }

}

</script>

<style lang="scss" scoped>

.chapter-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;

  .chapter_name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: bold;

    h1 {
      font-size: 40px;
      margin-bottom: 0;
    }

    input {
      font-size: 40px;
    }

    .icon {
      font-size: 24px;
    }

  }

  .editable-input {
    outline: none;
    cursor: text;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #00000075;
    border: 1px solid #fff;
  }

  .units-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    .unit {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
      padding: 3px 10px;
      background-color: #d5d5d5;

      input {
        border: none;
        background-color: transparent;
        font-size: 20px;
      }
      .icon {
        font-size: 16px;
      }
    }

    .icon.plus {
      font-size: 25px;
    }

  }

}

</style>