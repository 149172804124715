<template>

<!--  <div>-->

  <div v-show="viewType === 'grid'" class="card grid">
      <!-- Image -->
      <div class="image-wrapper" @click="showMobile ? goToMobileJourney(journey) : goToJourney(journey)">
        <font-awesome-icon v-if="(journey.hasOwnProperty('Consent') && journey.Consent)
              || (journey.IsCurrentUserAuthor || journey.IsCurrentUserCoAuthor)"
                           class="icon play"
                           :icon="['far', 'circle-play']"/>
        <div v-if="journey.hasOwnProperty('PictureURL')"
             class="card-image"
             :style="{ backgroundImage: `url( ${journey.PictureURL} )` }"
        >

        </div>

        <div v-else
             class="card-image"
        >
        </div>

<!--        <img-->
<!--            v-if="journey.hasOwnProperty('PictureURL')"-->
<!--            :src="journey.PictureURL"-->
<!--            alt="..."-->
<!--            class="card-image"-->
<!--        />-->
<!--        <img-->
<!--            v-else-->
<!--            src="@/assets/img/avatars/projects/project-1.jpg"-->
<!--            alt="..."-->
<!--            class="card-image"-->
<!--        />-->
      </div>


      <!-- Body -->
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <!-- Title -->
            <h4 class="mb-2 name">
              <span>{{ journey.Name }}</span>
            </h4>

            <!-- Subtitle -->
            <p class="card-text small text-muted">
              {{ journey.Description }}
            </p>
          </div>
          <div class="col-auto">
            <div class="side-by-side">

              <div class="icon"
                  @click="showMobile ? goToMobileJourneyMap(journey.ID) : goToJourneyMap(journey.ID)"
              >
             
                <font-awesome-icon icon="map-location-dot" title="Journey Map"/>
              </div>

              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && !showMobile"
                   class="icon"
                   @click="openJourneyUserManagementModal(journey.ID)"
              >
                <font-awesome-icon icon="user" title="Journey Users"/>
              </div>

              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && journey.Active && !showMobile"
                   class="icon"
                   @click="updateJourneyActiveStatus(journey)"
              >
                <font-awesome-icon icon="eye" title="Journey Status"/>
              </div>
              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && !journey.Active && !showMobile"
                   class="icon"
                   @click="updateJourneyActiveStatus(journey)"
              >
                <font-awesome-icon icon="eye-slash" title="Journey Status"/>
              </div>

              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && !showMobile"
                   class="icon"
                   @click="isJourneyReusableTemplateModalDisplayed = true"
              >
                <font-awesome-icon icon="clone" title="Reusable Template"/>
              </div>

            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>

      <!-- Footer -->
      <div class="card-footer card-footer-boxed">
        <div class="row align-items-center">
          <div v-if="journey.hasOwnProperty('ParticipantProgressPercentage')" class="col">
            <div class="row align-items-center g-0">
              <div class="col-auto">
                <!-- Value -->
                <div class="small me-2">{{ formattedNumber(journey.ParticipantProgressPercentage, 2) }}%</div>
              </div>
              <div class="col">
                <!-- Progress -->
                <div class="progress progress-sm">
                  <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{ width: journey.ParticipantProgressPercentage + '%' }"
                      :aria-valuenow="journey.ParticipantProgressPercentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div class="journey-role" v-if="!journey.hasOwnProperty('ParticipantProgressPercentage')">
            <span v-if="journey.IsCurrentUserAuthor">Author</span>
            <span v-if="journey.IsCurrentUserCoAuthor">Co-Author</span>
            <span v-if="journey.IsCurrentUserManager">Manager</span>
            <span v-if="journey.IsCurrentUserAssistant">Assistant</span>
          </div>
          <!--                    <div class="col-auto">-->
          <!--                      &lt;!&ndash; Avatar group &ndash;&gt;-->
          <!--                      <div class="avatar-group">-->
          <!--                        <a-->
          <!--                            class="avatar avatar-xs"-->
          <!--                            data-bs-toggle="tooltip"-->
          <!--                            title="Ab Hadley"-->
          <!--                        >-->
          <!--                          <img-->
          <!--                              style="max-height:40px"-->
          <!--                              src="@/assets/img/avatars/profiles/avatar-2.jpg"-->
          <!--                              alt="..."-->
          <!--                              class="avatar-img rounded-circle"-->
          <!--                          />-->
          <!--                        </a>-->
          <!--                        <a-->
          <!--                            class="avatar avatar-xs"-->
          <!--                            data-bs-toggle="tooltip"-->
          <!--                            title="Adolfo Hess"-->
          <!--                        >-->
          <!--                          <img-->
          <!--                              style="max-height:40px"-->
          <!--                              src="@/assets/img/avatars/profiles/avatar-3.jpg"-->
          <!--                              alt="..."-->
          <!--                              class="avatar-img rounded-circle"-->
          <!--                          />-->
          <!--                        </a>-->
          <!--                      </div>-->
          <!--                    </div>-->
        </div>
        <!-- / .row -->
      </div>
    </div>

  <div v-show="viewType === 'list'" class="card list mb-3">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- Avatar -->
            <div class="image-wrapper" @click="showMobile ? goToMobileJourney(journey) : goToMobileJourney(journey)">
              <font-awesome-icon v-if="(journey.hasOwnProperty('Consent') && journey.Consent)
              || (journey.hasOwnProperty('IsCurrentUserAuthor') && journey.IsCurrentUserAuthor)"
                                 class="icon play"
                                 :icon="['far', 'circle-play']"/>
              <img
                  v-if="journey.hasOwnProperty('PictureURL')"
                  :src="journey.PictureURL"
                  alt="..."
                  class="card-image avatar-img rounded"
                  style="width: 5.33333rem; height: 4rem;"
              />
              <img
                  v-else
                  src="@/assets/img/avatars/projects/project-1.jpg"
                  alt="..."
                  class="card-image avatar-img rounded"
                  style="width: 5.33333rem; height: 4rem;"
              />
            </div>
          </div>
          <div class="col ms-n2">
            <!-- Title -->
            <h4 class="mb-1 name">
              <a>{{ journey.Name }}</a>
            </h4>

            <!-- Text -->
            <p class="card-text small text-muted mb-1">
              <time datetime="2018-06-21">{{ journey.Description }}</time>
            </p>

            <!-- Progress -->
            <div v-if="journey.hasOwnProperty('ParticipantProgressPercentage')" class="row align-items-center g-0">
              <div class="col-auto">
                <!-- Value -->
                <div class="small me-2">{{ formattedNumber(journey.ParticipantProgressPercentage, 2) }}%</div>
              </div>
              <div class="col">
                <!-- Progress -->
                <div class="progress progress-sm">
                  <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{ width: journey.ParticipantProgressPercentage + '%' }"
                      :aria-valuenow="journey.ParticipantProgressPercentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div class="journey-role" v-if="!journey.hasOwnProperty('ParticipantProgressPercentage')">
              <span v-if="journey.IsCurrentUserAuthor">Author</span>
              <span v-if="journey.IsCurrentUserCoAuthor">Co-Author</span>
              <span v-if="journey.IsCurrentUserManager">Manager</span>
              <span v-if="journey.IsCurrentUserAssistant">Assistant</span>
            </div>
            <!-- / .row -->
          </div>

          <div class="col-auto">
            <div class="side-by-side">

              <div class="icon"
                    @click="showMobile ? goToMobileJourneyMap(journey.ID) : goToJourneyMap(journey.ID)"
              >
                <font-awesome-icon icon="map-location-dot" title="Journey Map"/>
              </div>

              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && !showMobile"
                   class="icon"
                   @click="openJourneyUserManagementModal(journey.ID)"
              >
                <font-awesome-icon icon="user" title="Journey Users"/>
              </div>

              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && journey.Active && !showMobile"
                   class="icon"
                   @click="updateJourneyActiveStatus(journey)"
              >
                <font-awesome-icon icon="eye" title="Journey Status"/>
              </div>
              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && !journey.Active && !showMobile"
                   class="icon"
                   @click="updateJourneyActiveStatus(journey)"
              >
                <font-awesome-icon icon="eye-slash" title="Journey Status"/>
              </div>

              <div v-if="(currentUserRoles.TenantAdmin || currentUserRoles.Facilitator) && journey.IsCurrentUserAuthor && !showMobile"
                   class="icon"
                   @click="isJourneyReusableTemplateModalDisplayed = true"
              >
                <font-awesome-icon icon="clone" title="Reusable Template"/>
              </div>

            </div>

          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .card-body -->
    </div>

  <transition name="fade">

      <div class="custom_modal journey-user-management" v-if="isJourneyUserManagementModalDisplayed">

        <div class="custom_modal__content">

<!--          CoAuthors-->
          <div v-if="hasModalContentLoaded" class="table-wrapper">

            <h2>Journey Co-Authors</h2>

            <form @submit.prevent="saveNewUsersToJourney(newCoAuthors, 'Co-Author', $event)">

              <table id="journey-coauthors">

                <thead>

                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>E-mail</th>
                  <th>Address</th>
                  <th>Position</th>
                  <!--                  <th>Company</th>-->
                  <!--                  <th>Job Title</th>-->
                  <!--                  <th>Phone</th>-->
                  <!--                  <th>Reports To</th>-->
                  <th>Note</th>
                  <th>Expiration Date</th>
                  <th>Role</th>
                  <th class="sticky-column">Actions</th>
                </tr>

                </thead>

                <tbody>

                <tr v-for="(journeyUser, indexJourneyUser) in journeyCoAuthors"
                    :key="indexJourneyUser"
                >

                  <td>
                    <span v-if="journeyUser.hasOwnProperty('given_name')">{{ journeyUser.given_name }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('family_name')">{{ journeyUser.family_name }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('email')">{{ journeyUser.email }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('address')">{{ journeyUser.address }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('custom:position')">{{ journeyUser['custom:position'] }}</span>
                  </td>
                  <td>
                    <textarea v-if="journeyUser.hasOwnProperty('Note')" v-model="journeyUser.Note" cols="20" rows="3" :disabled="!journeyUser.IsEdit" class="table-textarea"></textarea>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('custom:expiration_date')">{{ journeyUser['custom:expiration_date'] }}</span>
                  </td>
                  <td>
                    <select v-show="journeyUser.IsEdit" v-model="journeyUser.JourneyRole">
                      <option v-for="(journeyRole, indexJourneyRole) in journeyRoles"
                              :key="indexJourneyRole"
                              :value="journeyRole">
                        {{ journeyRole }}
                      </option>
                    </select>
                    <span v-show="!journeyUser.IsEdit">{{ journeyUser.JourneyRole }}</span>
                  </td>
                  <td class="sticky-column">
                    <div class="actions-wrapper">
                      <div class="icon delete-icon"
                           @click="toggleConfirmationModal('existingUser', journeyUser.JourneyParticipantLinkID, indexJourneyUser, 'Co-Author')">
                        <font-awesome-icon icon="xmark" class="red-xmark"/>
                      </div>
                      <div class="icon edit-icon" @click="journeyUser.IsEdit = !journeyUser.IsEdit">
                        <font-awesome-icon icon="edit"/>
                      </div>
                      <div v-show="journeyUser.IsEdit" class="icon save-icon" @click="updateExistingUser(journeyUser, indexJourneyUser, 'Co-Author')">
                        <font-awesome-icon icon="floppy-disk"/>
                      </div>
                    </div>
                  </td>

                </tr>

                <tr>
                  <td colspan="9">
                    <font-awesome-icon class="icon add-user" icon="plus" @click="displayRemainingFacilitators($event, 0, 'coauth')" id="plus-sign-journey-coauth"/>
                    <font-awesome-icon class="icon add-user" icon="minus" @click="displayRemainingFacilitators($event, 1, 'coauth')" id="minus-sign-journey-coauth"/>
                  </td>
                </tr>

                <tr class="remaining-facilitators">

                  <td colspan="9">

                    <div class="checkboxes-wrapper">

                      <div v-for="(remainingFacilitator, indexRemainingFacilitator) in remainingFacilitators"
                           :key="indexRemainingFacilitator"
                           class="checkbox-wrapper">
                        <label class="custom-checkmark-container">
                          <input type="checkbox"
                                 class="checkbox"
                                 :value="remainingFacilitator"
                                 @change="addNewUserToJourney('Co-Author', remainingFacilitator, $event)"
                          >
                          <span class="checkmark"></span>
                          {{remainingFacilitator.given_name}} {{remainingFacilitator.family_name}}
                        </label>
                      </div>

                    </div>

                  </td>

                </tr>


                </tbody>

              </table>

              <button type="submit"
                      :class="{'btn': true, 'btn-primary': true, 'disabled': newCoAuthors.length === 0}"
              >
                Add Co-Authors
              </button>

            </form>

          </div>

<!--          Managers-->
<!--          <div v-if="hasModalContentLoaded" class="table-wrapper">-->

<!--            <h2>Journey Managers</h2>-->

<!--            <form @submit.prevent="saveNewUsersToJourney(newManagers, 'Manager', $event)">-->

<!--              <table id="journey-managers">-->

<!--                <thead>-->

<!--                <tr>-->
<!--                  <th>First Name</th>-->
<!--                  <th>Last Name</th>-->
<!--                  <th>E-mail</th>-->
<!--                  <th>Note</th>-->
<!--                  <th>Expiration Date</th>-->
<!--                  <th>Role</th>-->
<!--                  <th class="sticky-column">Actions</th>-->
<!--                </tr>-->

<!--                </thead>-->

<!--                <tbody>-->

<!--                <tr v-for="(journeyUser, indexJourneyUser) in journeyManagers"-->
<!--                    :key="indexJourneyUser"-->
<!--                >-->

<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('given_name')">{{ journeyUser.given_name }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('family_name')">{{ journeyUser.family_name }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('email')">{{ journeyUser.email }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <textarea v-model="journeyUser.Note" cols="20" rows="3" :disabled="!journeyUser.IsEdit" class="table-textarea"></textarea>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('custom:expiration_date')">{{ journeyUser['custom:expiration_date'] }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <select v-show="journeyUser.IsEdit" v-model="journeyUser.JourneyRole">-->
<!--                      <option v-for="(journeyRole, indexJourneyRole) in journeyRoles"-->
<!--                              :key="indexJourneyRole"-->
<!--                              :value="journeyRole">-->
<!--                        {{ journeyRole }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                    <span v-show="!journeyUser.IsEdit">{{ journeyUser.JourneyRole }}</span>-->
<!--                  </td>-->
<!--                  <td class="sticky-column">-->
<!--                    <div class="actions-wrapper">-->
<!--                      <div class="icon delete-icon"-->
<!--                           @click="toggleConfirmationModal('existingUser', journeyUser.JourneyParticipantLinkID, indexJourneyUser, 'Manager')">-->
<!--                        <font-awesome-icon  icon="xmark" class="red-xmark"/>-->
<!--                      </div>-->
<!--                      <div class="icon edit-icon" @click="journeyUser.IsEdit = !journeyUser.IsEdit">-->
<!--                        <font-awesome-icon icon="edit"/>-->
<!--                      </div>-->
<!--                      <div v-show="journeyUser.IsEdit" class="icon save-icon" @click="updateExistingUser(journeyUser, indexJourneyUser, 'Manager')">-->
<!--                        <font-awesome-icon icon="floppy-disk"/>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </td>-->

<!--                </tr>-->

<!--                <tr>-->
<!--                  <td colspan="7">-->
<!--                    <font-awesome-icon class="icon add-user" icon="plus" @click="displayRemainingFacilitators($event, 0, 'manager')" id="plus-sign-journey-manager"/>-->
<!--                    <font-awesome-icon class="icon add-user" icon="minus" @click="displayRemainingFacilitators($event, 1, 'manager')" id="minus-sign-journey-manager"/>-->
<!--                  </td>-->
<!--                </tr>-->

<!--                <tr class="remaining-facilitators">-->

<!--                  <td colspan="7">-->


<!--                    <div class="checkboxes-wrapper">-->

<!--                      <div v-for="(remainingFacilitator, indexRemainingFacilitator) in remainingFacilitators"-->
<!--                           :key="indexRemainingFacilitator"-->
<!--                           class="checkbox-wrapper">-->
<!--                        <label class="custom-checkmark-container">-->
<!--                          <input type="checkbox"-->
<!--                                 class="checkbox"-->
<!--                                 :value="remainingFacilitator"-->
<!--                                 @change="addNewUserToJourney('Manager', remainingFacilitator, $event)"-->
<!--                          >-->
<!--                          <span class="checkmark"></span>-->
<!--                          {{remainingFacilitator.given_name}} {{remainingFacilitator.family_name}}-->
<!--                        </label>-->
<!--                      </div>-->

<!--                    </div>-->

<!--                  </td>-->

<!--                </tr>-->


<!--                </tbody>-->

<!--              </table>-->

<!--              <button type="submit"-->
<!--                      :class="{'btn': true, 'btn-primary': true, 'disabled': newManagers.length === 0}"-->
<!--              >-->
<!--                Add Managers-->
<!--              </button>-->

<!--            </form>-->

<!--          </div>-->

<!--          Assistants-->
<!--          <div v-if="hasModalContentLoaded" class="table-wrapper">-->

<!--            <h2>Journey Assistants</h2>-->

<!--            <form @submit.prevent="saveNewUsersToJourney(newAssistants, 'Assistant', $event)">-->

<!--              <table id="journey-assistants">-->

<!--                <thead>-->

<!--                <tr>-->
<!--                  <th>First Name</th>-->
<!--                  <th>Last Name</th>-->
<!--                  <th>E-mail</th>-->
<!--                  <th>Note</th>-->
<!--                  <th>Expiration Date</th>-->
<!--                  <th>Role</th>-->
<!--                  <th class="sticky-column">Actions</th>-->
<!--                </tr>-->

<!--                </thead>-->

<!--                <tbody>-->

<!--                <tr v-for="(journeyUser, indexJourneyUser) in journeyAssistants"-->
<!--                    :key="indexJourneyUser"-->
<!--                >-->

<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('given_name')">{{ journeyUser.given_name }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('family_name')">{{ journeyUser.family_name }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('email')">{{ journeyUser.email }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <textarea v-model="journeyUser.Note" cols="20" rows="3" :disabled="!journeyUser.IsEdit" class="table-textarea"></textarea>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <span v-if="journeyUser.hasOwnProperty('custom:expiration_date')">{{ journeyUser['custom:expiration_date'] }}</span>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <select v-show="journeyUser.IsEdit" v-model="journeyUser.JourneyRole">-->
<!--                      <option v-for="(journeyRole, indexJourneyRole) in journeyRoles"-->
<!--                              :key="indexJourneyRole"-->
<!--                              :value="journeyRole">-->
<!--                        {{ journeyRole }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                    <span v-show="!journeyUser.IsEdit">{{ journeyUser.JourneyRole }}</span>-->
<!--                  </td>-->
<!--                  <td class="sticky-column">-->
<!--                    <div class="actions-wrapper">-->
<!--                      <div class="icon delete-icon"-->
<!--                           @click="toggleConfirmationModal('existingUser', journeyUser.JourneyParticipantLinkID, indexJourneyUser, 'Assistant')">-->
<!--                        <font-awesome-icon  icon="xmark" class="red-xmark"/>-->
<!--                      </div>-->
<!--                      <div class="icon edit-icon" @click="journeyUser.IsEdit = !journeyUser.IsEdit">-->
<!--                        <font-awesome-icon icon="edit"/>-->
<!--                      </div>-->
<!--                      <div v-show="journeyUser.IsEdit" class="icon save-icon" @click="updateExistingUser(journeyUser, indexJourneyUser, 'Assistant')">-->
<!--                        <font-awesome-icon icon="floppy-disk"/>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </td>-->

<!--                </tr>-->

<!--                <tr>-->
<!--                  <td colspan="7">-->
<!--                    <font-awesome-icon class="icon add-user" icon="plus" @click="displayRemainingFacilitators($event, 0, 'assistants')" id="plus-sign-journey-assistants"/>-->
<!--                    <font-awesome-icon class="icon add-user" icon="minus" @click="displayRemainingFacilitators($event, 1, 'assistants')" id="minus-sign-journey-assistants"/>-->
<!--                  </td>-->
<!--                </tr>-->

<!--                <tr class="remaining-facilitators">-->

<!--                  <td colspan="7">-->

<!--                    <div class="checkboxes-wrapper">-->

<!--                      <div v-for="(remainingFacilitator, indexRemainingFacilitator) in remainingFacilitators"-->
<!--                           :key="indexRemainingFacilitator"-->
<!--                           class="checkbox-wrapper">-->
<!--                        <label class="custom-checkmark-container">-->
<!--                          <input type="checkbox"-->
<!--                                 class="checkbox"-->
<!--                                 :value="remainingFacilitator"-->
<!--                                 @change="addNewUserToJourney('Assistant', remainingFacilitator, $event)"-->
<!--                          >-->
<!--                          <span class="checkmark"></span>-->
<!--                          {{remainingFacilitator.given_name}} {{remainingFacilitator.family_name}}-->
<!--                        </label>-->
<!--                      </div>-->

<!--                    </div>-->

<!--                  </td>-->

<!--                </tr>-->


<!--                </tbody>-->

<!--              </table>-->

<!--              <button type="submit"-->
<!--                      :class="{'btn': true, 'btn-primary': true, 'disabled': newAssistants.length === 0}"-->
<!--              >-->
<!--                Add Assistants-->
<!--              </button>-->

<!--            </form>-->

<!--          </div>-->

<!--          Participants-->
          <div v-if="hasModalContentLoaded" class="table-wrapper">

            <div class="title-wrapper">
              <h2>Journey Participants</h2>
              <div class="icon reset-icon"
                   title="Resend Unconfirmed Users Temporary Password"
                   @click="openTemporaryPasswordResendModal(false, null)">
                <font-awesome-icon icon="clock-rotate-left"/>
              </div>
            </div>

            <form @submit.prevent="verifyDuplicateEmailsAndCalculatePrice(newParticipants, 'Participant', $event)">

              <table id="journey-participants">

                <thead>

                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>E-mail</th>
                  <th>Address</th>
                  <th>Position</th>
<!--                  <th>Company</th>-->
<!--                  <th>Job Title</th>-->
<!--                  <th>Phone</th>-->
<!--                  <th>Reports To</th>-->
                  <th>Note</th>
                  <th>Expiration Date</th>
                  <th>Role</th>
                  <th class="sticky-column">Actions</th>
                </tr>

                </thead>

                <tbody>

                <tr v-for="(journeyUser, indexJourneyUser) in journeyParticipants"
                    :key="indexJourneyUser"
                >

                  <td>
                    <span v-if="journeyUser.hasOwnProperty('given_name')">{{ journeyUser.given_name }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('family_name')">{{ journeyUser.family_name }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('email')">{{ journeyUser.email }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('address')">{{ journeyUser.address }}</span>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('custom:position')">{{ journeyUser['custom:position'] }}</span>
                  </td>
                  <td>
                    <textarea v-if="journeyUser.hasOwnProperty('Note')" v-model="journeyUser.Note" cols="20" rows="3" :disabled="!journeyUser.IsEdit" class="table-textarea"></textarea>
                  </td>
                  <td>
                    <span v-if="journeyUser.hasOwnProperty('custom:expiration_date')">{{ journeyUser['custom:expiration_date'] }}</span>
                  </td>
                  <td>
<!--                    <select v-show="journeyUser.IsEdit" v-model="journeyUser.JourneyRole">-->
<!--                      <option v-for="(journeyRole, indexJourneyRole) in journeyRoles"-->
<!--                              :key="indexJourneyRole"-->
<!--                              :value="journeyRole">-->
<!--                        {{ journeyRole }}-->
<!--                      </option>-->
<!--                    </select>-->
                    <span v-if="journeyUser.hasOwnProperty('JourneyRole')">{{ journeyUser.JourneyRole }}</span>
                  </td>
                  <td class="sticky-column">
                    <div class="actions-wrapper">
                      <div class="icon delete-icon"
                           @click="toggleConfirmationModal('existingUser', journeyUser.JourneyParticipantLinkID, indexJourneyUser, 'Participant')">
                        <font-awesome-icon  icon="xmark" class="red-xmark"/>
                      </div>
                      <div class="icon edit-icon" @click="journeyUser.IsEdit = !journeyUser.IsEdit">
                        <font-awesome-icon icon="edit"/>
                      </div>
                      <div v-show="journeyUser.IsEdit" class="icon save-icon" @click="updateExistingUser(journeyUser, indexJourneyUser, 'Participant')">
                        <font-awesome-icon icon="floppy-disk"/>
                      </div>
                      <div v-if="journeyUser.hasOwnProperty('email') && journeyUser.hasOwnProperty('UserStatus') && journeyUser.UserStatus !== 'CONFIRMED'"
                           class="icon reset-icon"
                           title="Resend Temporary Password"
                           @click="openTemporaryPasswordResendModal(true, journeyUser.email)">
                        <font-awesome-icon icon="clock-rotate-left"/>
                      </div>
                    </div>
                  </td>

                </tr>

                <tr>
                  <td colspan="9">
                    <font-awesome-icon class="icon add-user" icon="plus" @click="addNewUserToJourney('Participant')"/>
                  </td>
                </tr>

                <tr v-for="(newUser, indexNewUser) in newParticipants"
                    :key="indexNewUser"
                >

                  <td>
                    <input v-if="newUser.hasOwnProperty('given_name')" type="text" v-model="newUser.given_name" required>
                  </td>
                  <td>
                    <input v-if="newUser.hasOwnProperty('family_name')" type="text" v-model="newUser.family_name" required>
                  </td>
                  <td>
                    <input v-if="newUser.hasOwnProperty('email')" type="email" v-model="newUser.email" required>
                  </td>
                  <td>
                    <input v-if="newUser.hasOwnProperty('address')" type="text" v-model="newUser.address">
                  </td>
                  <td>
                    <input v-if="newUser.hasOwnProperty('custom:position')" type="text" v-model="newUser['custom:position']">
                  </td>
                  <td>
                    <textarea v-if="newUser.hasOwnProperty('Note')" v-model="newUser.Note" cols="20" rows="3" class="table-textarea"></textarea>
                  </td>
                  <td></td>
                  <td>
                    <span v-if="newUser.hasOwnProperty('JourneyRole')">{{ newUser.JourneyRole }}</span>
<!--                    <select v-model="newUser.JourneyRole">-->
<!--                      <option v-for="(journeyRole, indexJourneyRole) in journeyRoles"-->
<!--                              :key="indexJourneyRole"-->
<!--                              :value="journeyRole">-->
<!--                        {{ journeyRole }}-->
<!--                      </option>-->
<!--                    </select>-->
                  </td>
                  <td class="sticky-column">
                    <div class="icon delete-icon" @click="removeNewUserFromJourney(indexNewUser, 'Participant')">
                      <font-awesome-icon  icon="xmark" class="red-xmark"/>
                    </div>
                  </td>

                </tr>

                </tbody>

              </table>

              <span v-if="multipleEmailsErrorMessage" class="error-message"> {{multipleEmailsErrorMessage}}</span>

              <div class="buttons-wrapper">
                <button type="submit"
                        :class="{'btn': true, 'btn-primary': true, 'disabled': newParticipants.length === 0}"
                >
                  Add Participants
                </button>
                <button type="button"
                        :class="{'btn': true, 'btn-primary': true, 'disabled': journeyParticipants.length < 1}"
                        @click="sendEmailToParticipants()"
                >
                  Send Email
                </button>
              </div>

            </form>

            <ImportUsers v-if="hasModalContentLoaded"
                         :userType="'Participants'"
                         :fromWhere="'JourneyCard'"
                         :userTypeKey="'participant'"
                         @csvImportComplete="csvImportCompleteHandler"/>

          </div>

        </div>

        <div class="backdrop" @click="closeJourneyUserManagementModal()"></div>

      </div>

    </transition>

  <transition name="fade">

    <div class="custom_modal journey-reusable-template_modal" v-if="isJourneyReusableTemplateModalDisplayed">

      <div class="custom_modal__content">

        <h3>How would you like to proceed?</h3>

        <label class="option">
          <input v-model="selectedReusableOption" type="radio" name="reusable-option" value="copy" :style="{ 'accent-color': store.state.tenant.tenantinfo.primarycolor }">
          Make a copy (without users) to use as a template for a new journey
        </label>

        <label class="option">
          <input v-model="selectedReusableOption" type="radio" name="reusable-option" value="send" :style="{ 'accent-color': store.state.tenant.tenantinfo.primarycolor }">
          Send a copy to admin (without users) to generate a template that can be used by all
        </label>

        <div class="buttons-wrapper">
          <button class="btn btn-primary" type="button" @click="reusableTemplatesModalConfirmHandler(journey)">OK</button>
          <button class="btn btn-primary" type="button" @click="isJourneyReusableTemplateModalDisplayed = false">Cancel</button>
        </div>

      </div>

      <div class="backdrop" @click="isJourneyReusableTemplateModalDisplayed = false"></div>

    </div>

  </transition>

  <div class="custom_modal add-new-users-modal" v-if="isAddNewUsersModalDisplayed">

    <div class="custom_modal__content">

      <h2>Are you sure you want to add these users?</h2>

      <div v-show="paymentOption !== 'FR'" class="balance-wrapper">
        <p v-if="paymentOption === 'T'">Your credits: {{ tenantAmount }}</p>
        <p v-if="paymentOption === 'TF'">Your credits: {{ userAmount }}</p>
        <p>To pay: {{ priceToPay }}</p>
        <p>Remaining: {{ remainingAmount }}</p>
        <p v-show="remainingAmount < 0" class="error-message">You do not have enough CC.</p>
      </div>

      <div class="buttons-wrapper">
        <button class="btn btn-primary" type="button" :disabled="remainingAmount < 0" @click="saveNewUsersToJourney(newParticipants, 'Participant')">SAVE</button>
        <button class="btn btn-primary" type="button" @click="closeAddNewUsersModal()">CANCEL</button>
      </div>

    </div>

    <div class="backdrop" @click="closeAddNewUsersModal()"></div>

  </div>


  <ConfirmationModal title="WARNING!"
                     message="Are you sure you want to remove this user from this journey?"
                     :isOpened="isConfirmationModalOpened"
                     @confirm="deleteUser()"
                     @cancel="toggleConfirmationModal(null)"
  />

  <ConfirmationModal title="WARNING!"
                     :message="temporaryPasswordResendModalPayload.Message"
                     :isOpened="temporaryPasswordResendModalPayload.IsOpened"
                     @confirm="resendUnconfirmedParticipantsTemporaryPassword()"
                     @cancel="closeTemporaryPasswordResendModal()"
  />

<!--  </div>-->

</template>

<script>

import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import ImportUsers from "@/components/users/ImportUsers.vue";
import $ from 'jquery';
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import {calculateDailyPrice, calculateDaysBetween} from "@/helpers/priceCalculations";

export default {

  name: 'JourneyCard',

  components: {
    ConfirmationModal,
    ImportUsers
  },

  props: {
    journey: {
      type: Object,
      required: true,
    },
    currentUserRoles: {
      type: Object,
      required: true,
    },
    viewType: {
      type: String,
      default: 'grid'
    },
    showMobile: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {

    const newTenantAdmins = ref([]);

    const store = useStore();

    const router = useRouter();

    const selectedReusableOption = ref('copy');

    const isConfirmationModalOpened = ref(false);

    const userDeleteType = ref(null);

    const participantToDeleteLinkID = ref(null);

    const userToDeleteIndex = ref(null);

    const userToDeleteJourneyRole = ref(null);

    const goToJourney = (journey) => {


      if ((journey.hasOwnProperty('Consent') && journey.Consent) || (journey.IsCurrentUserAuthor || journey.IsCurrentUserCoAuthor)) {
        router.push({
          name: 'JourneyView',
          params: {
            id: journey.ID
          }
        });
      } else {
        router.push({
          name: 'JourneyMap',
          params: {
            id: journey.ID
          }
        });
      }

    };

    const goToMobileJourney = (journey) => {


      if ((journey.hasOwnProperty('Consent') && journey.Consent) || (journey.IsCurrentUserAuthor || journey.IsCurrentUserCoAuthor)) {
        router.push({
          name: 'MobileJourneyView',
          params: {
            id: journey.ID
          }
        });
      } else {
        router.push({
          name: 'MobileJourneyMap',
          params: {
            id: journey.ID
          }
        });
      }

    };

    const makeRoundInteger = (number) => {
      if (number !== null && number !== undefined && number !== '') {
        return Math.round(number);
      }
      return null;
    };

    const formattedNumber = (number, nrOfDecimals) => {

      if (number !== null && number !== undefined && number !== 0 && number !== '') {

        if (nrOfDecimals > 0) {

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: nrOfDecimals,
            useGrouping: false
          });

        } else {

          number = makeRoundInteger(number);

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            useGrouping: false
          });
        }

        number = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      }

      return number;

    };

    const updateJourneyActiveStatus = async (journey) => {
      await store.dispatch('journey/updateActiveStatus', journey);
    };

    const editJourney = (journey) => {
      router.push({
        name: 'JourneyCreation',
        params: {
          id: journey.ID
        }
      });
    };

    const goToJourneyMap = (journeyID) => {
      router.push({
        name: 'JourneyMap',
        params: {
          id: journeyID
        }
      });
    };
    const goToMobileJourneyMap = (journeyID) => {
      router.push({
        name: 'MobileJourneyMap',
        params: {
          id: journeyID
        }
      });
    };


    // journey user management modal

    const currentUserID = computed(() => store.getters["auth/getCurrentUserID"]);

    const isJourneyUserManagementModalDisplayed = ref(false);

    const hasModalContentLoaded = ref(false);

    // const linkedUsersToJourney = ref([]);

    const journeyCoAuthors = ref([]);
    const journeyManagers = ref([]);
    const journeyAssistants = ref([]);
    const journeyParticipants = ref([]);

    const newCoAuthors = ref([]);
    const newManagers = ref([]);
    const newAssistants = ref([]);
    const newParticipants = ref([]);
    const multipleEmailsErrorMessage = ref('');

    const allFacilitators = ref([]);

    const remainingFacilitators = computed(() => {

      let facilitators = [];

      facilitators = allFacilitators.value.filter((facilitator) => {

        let facilitatorIncludedInJourneyCoAuthors = journeyCoAuthors.value.some((linkedUser) => linkedUser.sub === facilitator.sub);
        let facilitatorIncludedInJourneyManagers = journeyManagers.value.some((linkedUser) => linkedUser.sub === facilitator.sub);
        let facilitatorIncludedInJourneyAssistants = journeyAssistants.value.some((linkedUser) => linkedUser.sub === facilitator.sub);
        let facilitatorIncludedInJourneyParticipants = journeyParticipants.value.some((linkedUser) => linkedUser.sub === facilitator.sub);

        // if (!facilitator.hasOwnProperty('Checked')) {
        //   facilitator.Checked = false;
        // }

        return (!facilitatorIncludedInJourneyCoAuthors
            && !facilitatorIncludedInJourneyManagers
            && !facilitatorIncludedInJourneyAssistants
            && !facilitatorIncludedInJourneyParticipants
            && facilitator.sub !== currentUserID.value
        );
      });

      facilitators.sort((a, b) => {
        if (a.given_name < b.given_name) return -1;
        if (a.given_name > b.given_name) return 1;
        return 0;
      });

      return facilitators;
    });

    const journeyRoles = ['Co-Author', 'Manager', 'Assistant', 'Participant'];

    const priceToPay = ref(0);

    const remainingAmount = ref(0);

    const tenantAmount = computed(() => store.getters["internalCurrency/getTenantAmount"]);

    const userAmount = computed(() => store.getters["internalCurrency/getUserAmount"]);

    const newUsersGroupToAdd = ref('');

    const isAddNewUsersModalDisplayed = ref(false);

    const paymentOption = computed(() => store.getters["tenant/getPaymentOption"]);

    const userRoleCost = computed(() => store.getters["internalCurrency/getUserRoleCost"]);

    const separateLinkedUsersByJourneyRole = (linkedUsersToJourney) => {
      journeyCoAuthors.value = linkedUsersToJourney.filter((user) => user.JourneyRole === 'Co-Author');
      journeyManagers.value = linkedUsersToJourney.filter((user) => user.JourneyRole === 'Manager');
      journeyAssistants.value = linkedUsersToJourney.filter((user) => user.JourneyRole === 'Assistant');
      journeyParticipants.value = linkedUsersToJourney.filter((user) => user.JourneyRole === 'Participant');
    };

    const openJourneyUserManagementModal = async (journeyID) => {
      isJourneyUserManagementModalDisplayed.value = true;
      await store.dispatch('loading/setLoading', true);
      let payloadLinkedUsersToJourney = {
        JourneyID: journeyID,
        IsFacilitatorView: true,
      }
      let linkedUsersToJourney = await store.dispatch('journey/getUsersLinkedToJourney', payloadLinkedUsersToJourney);
      allFacilitators.value = await store.dispatch('users/getUsersByRole', {
        Roles: ['TenantAdmin', 'Facilitator'],
      });
      separateLinkedUsersByJourneyRole(linkedUsersToJourney);
      hasModalContentLoaded.value = true;
      await store.dispatch('loading/setLoading', false);
    };

    const closeJourneyUserManagementModal = () => {
      isJourneyUserManagementModalDisplayed.value = false;
    };

    const addNewUserToJourney = async (journeyRole, newUser = {}, event = {}) => {

      let newUserObject = {};

      switch (journeyRole) {

        case 'Co-Author': {

          if (event.target.checked) {

            newUserObject = {
              sub: newUser.sub,
              given_name: newUser.given_name,
              family_name: newUser.family_name,
              email: newUser.email,
              address: newUser.address,
              'custom:position': newUser['custom:position'],
              Note: '',
              JourneyRole: journeyRole,
            };

            newCoAuthors.value.push(newUserObject);
          }
          else {
            newCoAuthors.value = newCoAuthors.value.filter((user) => user.sub !== newUser.sub)
          }
          break;

        }
        case 'Manager': {

          if (event.target.checked) {

            newUserObject = {
              sub: newUser.sub,
              given_name: newUser.given_name,
              family_name: newUser.family_name,
              email: newUser.email,
              address: newUser.address,
              'custom:position': newUser['custom:position'],
              Note: '',
              JourneyRole: journeyRole,
            };

            newManagers.value.push(newUserObject);
          }
          else {
            newManagers.value = newManagers.value.filter((user) => user.sub !== newUser.sub)
          }
          break;

        }
        case 'Assistant': {

          if (event.target.checked) {

            newUserObject = {
              sub: newUser.sub,
              given_name: newUser.given_name,
              family_name: newUser.family_name,
              email: newUser.email,
              address: newUser.address,
              'custom:position': newUser['custom:position'],
              Note: '',
              JourneyRole: journeyRole,
            };

            newAssistants.value.push(newUserObject);
          }
          else {
            newAssistants.value = newAssistants.value.filter((user) => user.sub !== newUser.sub)
          }
          break;

        }
        case 'Participant': {
          newUserObject = {
            given_name: '',
            family_name: '',
            email: '',
            address: '',
            'custom:position': '',
            Note: '',
            JourneyRole: 'Participant',
            IsEdit: false,
          }
          newParticipants.value.push(newUserObject);
          break;
        }
        default: {

          break;
        }

      }

    };

    const removeNewUserFromJourney = async (indexNewUser, journeyRole) => {
      await store.dispatch('loading/setLoading', true);

      switch (journeyRole) {

        case 'Co-Author': {
          newCoAuthors.value.splice(indexNewUser, 1);
          break;
        }
        case 'Manager': {

          break;
        }
        case 'Assistant': {

          break;
        }
        case 'Participant': {
          newParticipants.value.splice(indexNewUser, 1);
          break;
        }
        default: {

          break;
        }

      }
      setTimeout(function(){
        store.dispatch('loading/setLoading', false);
      }, 1000);
    };

    // const openAddNewUsersModal = (newUsers, group) => {
    //
    //   // let numberOfNewUsers = newUsers.length;
    //   // let nrOfMonths = 12;
    //
    //   switch (group) {
    //
    //     // case 'TenantAdmin': {
    //     //   // first 3 tenant admins are free
    //     //   let numberOfExistingTenantAdmins = tenantAdmins.value.length;
    //     //   if (numberOfExistingTenantAdmins < numberOfFreeTenantAdmins.value) {
    //     //     let freeTenantAdmins = numberOfFreeTenantAdmins.value - numberOfExistingTenantAdmins;
    //     //     numberOfNewUsers -= freeTenantAdmins;
    //     //   }
    //     //   priceToPay.value = numberOfNewUsers * userRoleCost.value.TenantAdmin * nrOfMonths;
    //     //   break;
    //     // }
    //     // case 'Facilitator': {
    //     //   priceToPay.value = numberOfNewUsers * userRoleCost.value.Facilitator * nrOfMonths;
    //     //   break;
    //     // }
    //     case 'Participant': {
    //       console.log('participant')
    //       priceToPay.value += 360;
    //       break;
    //     }
    //     default: {
    //
    //       break;
    //     }
    //
    //   }
    // };

    const calculateParticipantsPrice = (users) => {

      users.forEach(user => {

        if(!user.Exists){
          const monthlyPrice = userRoleCost.value.Participant ;
          const startDate = new Date();
          const endDate = new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());

          const dailyPrice = calculateDailyPrice(monthlyPrice);
          const daysBetween = calculateDaysBetween(startDate, endDate);
          let totalPrice = dailyPrice * daysBetween;
          totalPrice = totalPrice.toFixed(2);
          priceToPay.value += parseFloat(totalPrice);
        }
        else {
          if (user.hasOwnProperty('ExpirationDate') && user.ExpirationDate !== '') {
            const monthlyPrice = userRoleCost.value.Participant ;
            const currentDate = new Date();
            const startDate = new Date(user.ExpirationDate);
            const endDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

            const dailyPrice = calculateDailyPrice(monthlyPrice);
            const daysBetween = calculateDaysBetween(startDate, endDate);
            let totalPrice = dailyPrice * daysBetween;
            totalPrice = totalPrice.toFixed(2);
            priceToPay.value += parseFloat(totalPrice);
          }

        }
        // else {
        //   if (user.ExpirationDate) {
        //     const expire_date = user.ExpirationDate.split(' ')
        //     const expire_month  = months.value.indexOf(expire_date[1]);
        //     const expire_day  = Number(expire_date[2]);
        //     const expire_year  = Number(expire_date[3]);
        //
        //     const today = new Date().toString()
        //     const today_date = today.split(' ');
        //     const today_month  = months.value.indexOf(today_date[1]);
        //     const today_day  = Number(today_date[2]);
        //     const today_year  = Number(today_date[3]);
        //
        //     console.log(expire_date);
        //     console.log(today_date);
        //
        //     let remaining_months_this_year = 0;
        //     let remaining_days_next_year = 0;
        //     let remaining_days_this_year = 0;
        //     let total_remaining_days = 0;
        //     let middle_months_days = 0;
        //
        //     if (expire_year > today_year) {
        //       remaining_months_this_year = 12 - (today_month + 1);
        //       remaining_days_next_year = (expire_month - 1 ) * 30 + expire_day;
        //       remaining_days_this_year = remaining_months_this_year * 30 + (30 - today_day);
        //       total_remaining_days = remaining_days_this_year + remaining_days_next_year;
        //       if (expire_year - today_year > 1) {
        //         total_remaining_days = total_remaining_days + 360 * (expire_year - today_year - 1)
        //       }
        //     }else {
        //       if (expire_month > today_month) {
        //         remaining_months_this_year = expire_month - today_month - 1;
        //         middle_months_days = remaining_months_this_year * 30;
        //         total_remaining_days = (30 - today_day) + expire_day + middle_months_days;
        //       }
        //
        //       if (expire_month === today_month) {
        //         if (expire_day > today_day) {
        //           total_remaining_days = 360 - (expire_day - today_day)
        //         }
        //         if (expire_day <= today_day){
        //           total_remaining_days = 360;
        //         }
        //
        //       }
        //
        //       if (expire_month < today_month || expire_year < today_year) {
        //         total_remaining_days = 360;
        //       }
        //     }
        //
        //     console.log('total_remaining_days', total_remaining_days);
        //     priceToPay.value += total_remaining_days;
        //
        //   }
        // }
      });

      if (paymentOption.value === 'T') {
        let difference = tenantAmount.value - priceToPay.value;
        difference = difference.toFixed(2);
        remainingAmount.value = parseFloat(difference);
      }
      if (paymentOption.value === 'TF') {
        let difference = userAmount.value - priceToPay.value;
        difference = difference.toFixed(2);
        remainingAmount.value = parseFloat(difference);
      }
      isAddNewUsersModalDisplayed.value = true;
    }

    const verifyExistingEmails = async(participants) => {
      const emailsArray = [];
      participants.forEach(paricipant => {
        emailsArray.push(paricipant.email)
      })
      const payload = {'UsersEmails': emailsArray};
      let checkedUsers = await store.dispatch('users/checkExistingUsers', payload);
      return checkedUsers;
    }

    const isMultipleEmails = () => {
      const emailCounts = {};
      for (let obj of newParticipants.value) {
        const email = obj.email;
        if (emailCounts[email]) {
          emailCounts[email]++;
        } else {
          emailCounts[email] = 1;
        }
      }

      const values = Object.values(emailCounts);
      const keys = Object.keys(emailCounts);

      for (let i = 0; i < values.length; i++) {
        if (values[i] > 1) {
          localStorage.setItem('multipleEmail', keys[i])
          return true
        }
      }
      return false
    }

    const verifyDuplicateEmailsAndCalculatePrice = async (participants, type, event) => {
      if(participants.length > 0) {
        if (isMultipleEmails()){
          multipleEmailsErrorMessage.value = 'Email \"' + localStorage.getItem('multipleEmail') + ' \" has been found for multiple users'
        } else {
          multipleEmailsErrorMessage.value = '';
          localStorage.removeItem('multipleEmail')
          if (participants && type && event) {
            // saveNewUsersToJourney(participants, type, event);
            let checkedUsers = await verifyExistingEmails(participants);
            calculateParticipantsPrice(checkedUsers);
          }
        }
      }
    }

    const closeAddNewUsersModal = () => {
      priceToPay.value = 0;
      remainingAmount.value = 0;
      newUsersGroupToAdd.value = '';
      isAddNewUsersModalDisplayed.value = false;
    };

    const csvImportCompleteHandler = (importedUsers, userTypeKey) => {

      let formattedImportedUsers = [];

      importedUsers.forEach(importedUser => {
        formattedImportedUsers.push({
          given_name: importedUser.given_name,
          family_name: importedUser.family_name,
          email: importedUser.email,
          address: importedUser.country,
          'custom:position': importedUser.status,
          Note: '',
          JourneyRole: 'Participant',
          IsEdit: false,
        });
      });

      switch (userTypeKey) {
        case 'participant':
          newParticipants.value.push(...formattedImportedUsers);
          break;
        default:
          break;
      }

    };

    const saveNewUsersToJourney = async (newUsers, journeyRole, ev = null) => {
      await store.dispatch('loading/setLoading', true);

      let payload = {
        JourneyID: props.journey.ID,
        NewUsers: newUsers
      };

      let addedUsersInJourney = await store.dispatch('journey/addUserToJourney', payload);

      switch (journeyRole) {

        case 'Co-Author': {
          // journeyCoAuthors.value = journeyCoAuthors.value.concat(addedUsersInJourney);
          $(ev.target).find('.checkbox').prop('checked', false);
          newCoAuthors.value = [];
          break;
        }
        case 'Manager': {
          // journeyManagers.value = journeyManagers.value.concat(addedUsersInJourney);
          $(ev.target).find('.checkbox').prop('checked', false);
          newManagers.value = [];
          break;
        }
        case 'Assistant': {
          // journeyAssistants.value = journeyAssistants.value.concat(addedUsersInJourney);
          $(ev.target).find('.checkbox').prop('checked', false);
          newAssistants.value = [];
          break;
        }
        case 'Participant': {
          // journeyParticipants.value = journeyParticipants.value.concat(addedUsersInJourney);
          newParticipants.value = [];
          break;
        }
        default: {

          break;
        }

      }

      await openJourneyUserManagementModal(props.journey.ID);

      await store.dispatch('internalCurrency/getAmount');

      closeAddNewUsersModal();

      await store.dispatch('loading/setLoading', false);

    };

    const removeExistingUserFromJourney = async (JourneyParticipantLinkID, indexJourneyUser, journeyRole) => {

      await store.dispatch('loading/setLoading', true);

      const payload = {
        "JourneyParticipantLinkID": JourneyParticipantLinkID,
      }

      await store.dispatch('journey/removeUserFromJourney', payload);

      switch (journeyRole) {

        case 'Co-Author': {
          journeyCoAuthors.value.splice(indexJourneyUser, 1);
          break;
        }
        case 'Manager': {
          journeyManagers.value.splice(indexJourneyUser, 1);
          break;
        }
        case 'Assistant': {
          journeyAssistants.value.splice(indexJourneyUser, 1);
          break;
        }
        case 'Participant': {
          journeyParticipants.value.splice(indexJourneyUser, 1);
          break;
        }
        default: {

          break;
        }

      }
      await store.dispatch('loading/setLoading', false);
    };

    const updateExistingUser = async (journeyUser, indexJourneyUser, journeyRole) => {
      await store.dispatch('loading/setLoading', true);

      const payload = {
        "JourneyParticipantLinkID": journeyUser.JourneyParticipantLinkID,
        "JourneyID": props.journey.ID,
        "JourneyRole": journeyUser.JourneyRole,
        "Note": journeyUser.Note,
      };

      await store.dispatch('journey/updateParticipantByAuthor', payload);

      switch (journeyRole) {

        case 'Co-Author': {
          journeyCoAuthors.value.splice(indexJourneyUser, 1);
          break;
        }
        case 'Manager': {
          journeyManagers.value.splice(indexJourneyUser, 1);
          break;
        }
        case 'Assistant': {
          journeyAssistants.value.splice(indexJourneyUser, 1);
          break;
        }
        case 'Participant': {
          journeyParticipants.value.splice(indexJourneyUser, 1);
          break;
        }
        default: {

          break;
        }

      }

      switch (payload.JourneyRole) {

        case 'Co-Author': {
          journeyCoAuthors.value.push(journeyUser);
          break;
        }
        case 'Manager': {
          journeyManagers.value.push(journeyUser);
          break;
        }
        case 'Assistant': {
          journeyAssistants.value.push(journeyUser);
          break;
        }
        case 'Participant': {
          journeyParticipants.value.push(journeyUser);
          break;
        }
        default: {

          break;
        }

      }

      await store.dispatch('loading/setLoading', false);
    };

    const displayRemainingFacilitators = async (ev, sign, id) => {
      const parentTableElement = $(ev.target).closest('table');
      const remainingFacilitatorsElement = parentTableElement.find('.remaining-facilitators');

      const isremainingFacilitatorsElementVisible = remainingFacilitatorsElement.is(":visible");
      if (isremainingFacilitatorsElementVisible) {
        remainingFacilitatorsElement.fadeOut();
      } else {
        remainingFacilitatorsElement.fadeIn();
      }

      const plus = document.getElementById('plus-sign-journey-' + id);
      const minus = document.getElementById('minus-sign-journey-' + id);
      if( plus && minus ){
        if (sign === 0) {
          plus.style.display = 'none';
          minus.style.display = 'inline-block';
        } else {
          plus.style.display = 'inline-block';
          minus.style.display = 'none';
        }
      }

    };

    const sendEmailToParticipants = () => {

      const recipientsEmails = journeyParticipants.value.map(obj => obj.email).join('; ');
      // const subject = encodeURIComponent('Subject');
      // const body = encodeURIComponent('');
      const mailtoUrl = `mailto:${recipientsEmails}`;

      // Open in a new tab
      window.open(mailtoUrl, '_blank');
    };

    // temporary participant password resend
    const temporaryPasswordResendModalPayload = ref({
      IsOpened: false,
      Message: '',
    });
    const openTemporaryPasswordResendModal = (isIndividual, userEmail) => {
      if (isIndividual) {
        temporaryPasswordResendModalPayload.value = {
          IsOpened: true,
          IsIndividual: true,
          Message: `Are you sure you want to resend the temporary password to ${userEmail}?`,
          UserEmail: userEmail
        }
      }
      else {
        temporaryPasswordResendModalPayload.value = {
          IsOpened: true,
          IsIndividual: false,
          Message: `Are you sure you want to resend the temporary password to all participants that didn't confirm their accounts yet?`,
        }
      }
    };
    const closeTemporaryPasswordResendModal = () => {
      temporaryPasswordResendModalPayload.value = {
        IsOpened: false,
        Message: '',
      };
    };
    const resendUnconfirmedParticipantsTemporaryPassword = async () => {

      await store.dispatch('loading/setLoading', true);

      // resend temporary password individually
      if (temporaryPasswordResendModalPayload.value !== null && temporaryPasswordResendModalPayload.value.IsIndividual) {
        let payload = {
          IsAdminAllUsers: false,
          UsersEmails: [temporaryPasswordResendModalPayload.value.UserEmail]
        };
        await store.dispatch('users/resendUsersTemporaryPassword', payload);
      }
      // resend temporary password to all users that still need to change their temporary password
      if (temporaryPasswordResendModalPayload.value !== null && !temporaryPasswordResendModalPayload.value.IsIndividual) {
        let participantsUnconfirmedEmails = [];
        journeyParticipants.value.forEach((journeyParticipant) => {
          if (journeyParticipant.hasOwnProperty('email') && journeyParticipant.hasOwnProperty('UserStatus') && journeyParticipant.UserStatus !== 'CONFIRMED') {
            participantsUnconfirmedEmails.push(journeyParticipant.email);
          }
        });

        let payload = {
          IsAdminAllUsers: false,
          UsersEmails: participantsUnconfirmedEmails
        };
        await store.dispatch('users/resendUsersTemporaryPassword', payload);
      }

      closeTemporaryPasswordResendModal();
      await store.dispatch('loading/setLoading', false);
    };
    // end of temporary participant password resend

    // journey reusable templates

    const isJourneyReusableTemplateModalDisplayed = ref(false);
    const reusableTemplatesModalConfirmHandler = async (journey) => {

      if (selectedReusableOption.value === 'copy') {

        let payload = {
          "Name": journey.Name,
          "Description": journey.Description,
          "CategoryID": journey.CategoryID,
          "Picture": journey.Picture,
          "Overview": journey.Overview,
          "Structure": journey.Structure,
          "JourneyID": journey.ID,
        };

        let newJourney = await store.dispatch('journey/copyJourney', payload);

        await router.push({
          name: 'JourneyMap',
          params: {
            id: newJourney.ID
          }
        });

      }

      if (selectedReusableOption.value === 'send') {
        await store.dispatch('journeyReusableTemplates/addToPendingReusableTemplates', journey);
      }

      isJourneyReusableTemplateModalDisplayed.value = false;

    };

    const toggleConfirmationModal = (deleteType, JourneyParticipantLinkID = null, indexJourneyUser = null, journeyRole = null) => {

      isConfirmationModalOpened.value = !isConfirmationModalOpened.value;

      userDeleteType.value = deleteType;

      participantToDeleteLinkID.value = JourneyParticipantLinkID;

      userToDeleteIndex.value = indexJourneyUser;

      userToDeleteJourneyRole.value = journeyRole;

    };

    const deleteUser = () => {

      if (userDeleteType.value === 'existingUser') {
        removeExistingUserFromJourney(participantToDeleteLinkID.value, userToDeleteIndex.value, userToDeleteJourneyRole.value);
      }
      else if (userDeleteType.value === 'newUser') {
        removeNewUserFromJourney(userToDeleteIndex.value, userToDeleteJourneyRole.value);
      }

      toggleConfirmationModal(null);

    };

    onMounted(async() => {

    });

    return {
      store,
      isJourneyUserManagementModalDisplayed,
      journeyRoles,
      currentUserID,
      multipleEmailsErrorMessage,
      selectedReusableOption,
      isConfirmationModalOpened,

      goToJourney,
      goToMobileJourney,
      goToJourneyMap,
      goToMobileJourneyMap,
      formattedNumber,
      updateJourneyActiveStatus,
      editJourney,
      updateExistingUser,

      // journey users management
      hasModalContentLoaded,
      remainingFacilitators,
      openJourneyUserManagementModal,
      closeJourneyUserManagementModal,
      saveNewUsersToJourney,
      verifyDuplicateEmailsAndCalculatePrice,
      removeExistingUserFromJourney,
      addNewUserToJourney,
      removeNewUserFromJourney,
      displayRemainingFacilitators,
      sendEmailToParticipants,

      temporaryPasswordResendModalPayload,
      openTemporaryPasswordResendModal,
      closeTemporaryPasswordResendModal,
      resendUnconfirmedParticipantsTemporaryPassword,

      journeyCoAuthors,
      newCoAuthors,

      journeyManagers,
      newManagers,

      journeyAssistants,
      newAssistants,

      journeyParticipants,
      newParticipants,
      csvImportCompleteHandler,
      // end of journey users management

      // journey reusable templates
      isJourneyReusableTemplateModalDisplayed,
      reusableTemplatesModalConfirmHandler,
      // end of journey reusable templates

      toggleConfirmationModal,
      deleteUser,
      isAddNewUsersModalDisplayed,
      tenantAmount,
      userAmount,
      priceToPay,
      paymentOption,
      remainingAmount,
      // openAddNewUsersModal,
      newTenantAdmins,
      // saveNewUsers,
      closeAddNewUsersModal,
    }

  },

}

</script>

<style lang="scss" scoped>


.add-new-users-modal {
  .custom_modal__content {
    display: flex;
    min-height: fit-content;
    button {
      width: 100% !important;
    }
  }
}

.card {
  .card-image {
    background-color: #aaa;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.grid {
    //max-width: 500px;
    height: 100%;

    .image-wrapper {
      position: relative;

      .card-image {
        height: 300px;
        cursor: pointer;
      }

      .icon.play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        opacity: 0.3;
        font-size: 160px;
      }
    }
  }

  &.list {
    .image-wrapper {
      position: relative;

      .card-image {
        cursor: pointer;
      }

      .icon.play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        opacity: 0.3;
        font-size: 50px;
      }
    }
  }

  .dropdown-item {
    cursor: pointer;
  }

  .card-footer {
    height: 40px;

    .progress-bar {
      background-color: var(--primary-color);
    }
  }

  .journey-role {
    font-size: 14px;
    --bs-text-opacity: 1;
    color: #6c757d
  }
}

.custom_modal {
  &__content {
    display: block;
    width: 80%;
    min-height: 80%;
    max-height: 80%;
    border: none;
    box-shadow: 0px 0px 20px transparentize(black, 0.5);

    .error-message {
      width: 100%;
      display: block;
      color: #d13602;
      margin-bottom: 3%;
    }

    .custom-checkmark-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: var(--primary-color-disabled) !important;
      border-radius: 3px;
    }

    .custom-checkmark-container:hover input ~ .checkmark, .custom-checkmark-container input:checked ~ .checkmark {
      background-color: var(--primary-color) !important;
    }

    .checkmark:after {
      content: "";
      position: relative;
      display: none;
    }

    .custom-checkmark-container input:checked ~ .checkmark:after {
      display: block;
    }

    .custom-checkmark-container .checkmark:after {
      left: 7px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    h2 {
      color: var(--primary-color)
    }

    button {
      width: 20% !important;
    }

    .option {
      width: 100%;
      text-align: left;
      margin-bottom: 5px;
      cursor: pointer;
    }

    .buttons-wrapper {
      margin-top: 10px;
    }

    .table-wrapper {
      //width: 90%;
      margin: 0 auto 30px;

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 5px;

        h2 {
          margin: 0;
        }

        .icon.reset-icon {
          font-size: 22px;
          color: var(--primary-color);
        }

      }

      #minus-sign-journey-assistants, #minus-sign-journey-manager, #minus-sign-journey-coauth {
        display: none;
      }

      table {
        display: block;
        margin: 15px 0;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: 0.25rem;

        thead {
          position: relative;
          top: unset;
          width: 100%;
        }

        td, th {
          border: none;
        }

        td {
          width: 18.5%;

          .red-xmark {
            color: #d13602;
          }
        }

        .remaining-facilitators {
          display: none;
        }

        .checkboxes-wrapper{
          width: 20%;
          margin: 0 auto;

          .checkbox-wrapper {
            display: flex;
            margin: 0 auto;

            label, input {
              cursor: pointer;
            }

            label {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }
        }

        .icon.add-user {
          font-size: 25px;
        }

        .sticky-column {
          position: sticky;
          right: -1px;
        }

        .actions-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          font-size: 20px;
        }
      }
    }
  }
}
.journey-reusable-template_modal {
 .custom_modal__content {
   min-height: unset;
   width: fit-content;
 }
}

@media only screen and (max-width: 768px) {
  .custom_modal {
    &__content {
      width: 95%;
      padding: 15px;
      font-size: 15px;

      .table-wrapper {
        form {
          .btn.btn-primary {
            width: 60% !important;
          }
        }
      }
    }
  }
}

</style>
