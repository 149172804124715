<template>
  <div></div>
</template>

<script>

import {useStore} from "vuex";
import {onMounted, onUnmounted} from "vue";
import {useRouter} from "vue-router";

export default {

  name: 'PaymentCanceled',

  setup() {

    const store = useStore();

    const router = useRouter();

    const redirectToPage = async (fromPage) => {
      switch (fromPage) {
        case 'Admin': {
          await router.push({
            name: 'Admin'
          });
          break;
        }
        case 'Account': {
          await router.push({
            name: 'Account'
          });
          break;
        }
        default: {

        }
      }
    }

    onUnmounted(() => {
      localStorage.removeItem('invoiceItems_amount')
      localStorage.removeItem('invoiceItems_country')
    })

    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);

      const fromPage = localStorage.getItem('fromPage');

      await store.dispatch('internalCurrency/onPaymentCancel');

      await store.dispatch('internalCurrency/setIsReturnToBilling', true);

      await redirectToPage(fromPage);

    });

    return {

    }
  }

}

</script>

<style lang="scss" scoped>

</style>
