<template>
  <CentralLayout>
    <template v-slot:content>

<!--      <UserJourneys/>-->

<!--      &lt;!&ndash; MAIN CONTENT &ndash;&gt;-->
      <div id="dashboard-page" class="main-content">
        <!-- HEADER -->
<!--        <div class="header">-->
<!--          <div class="container mb-3">-->
<!--            &lt;!&ndash; Body &ndash;&gt;-->
<!--            <div class="header-body">-->
<!--              <div class="row align-items-end">-->
<!--                <div class="col">-->
<!--                  &lt;!&ndash; Pretitle &ndash;&gt;-->
<!--                  <small class="text-capitalize">Overview</small>-->

<!--                  &lt;!&ndash; Title &ndash;&gt;-->
<!--                  <h1 class="header-title">-->
<!--                    Dashboard-->
<!--                  </h1>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; / .row &ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; / .header-body &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
        <!-- / .header -->
        <!-- CONTENT -->
<!--        <div>-->
          <div class="container">
<!--            <div class="header-tools">-->
<!--&lt;!&ndash;              <div class="col-12 col-sm-6 col-md-3 col-lg mb-2">&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Form &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <form>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div&ndash;&gt;-->
<!--&lt;!&ndash;                    class="input-group input-group-lg input-group-merge input-group-reverse"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    &lt;!&ndash; Input &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                    <input&ndash;&gt;-->
<!--&lt;!&ndash;                      class="form-control list-search"&ndash;&gt;-->
<!--&lt;!&ndash;                      type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                      placeholder="Search"&ndash;&gt;-->
<!--&lt;!&ndash;                    />&ndash;&gt;-->

<!--&lt;!&ndash;                    &lt;!&ndash; Prepend &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="input-group-text">&ndash;&gt;-->
<!--&lt;!&ndash;                      <Icon icon="mdi-light:magnify" />&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </form>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->

<!--&lt;!&ndash;              <div class="col-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Navigation (button group) &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="nav btn-group">&ndash;&gt;-->

<!--&lt;!&ndash;                  <button :class="{'btn': true, 'btn-lg': true, 'btn-white': true, 'active': contentDisplayed === 'UserJourneys'}"&ndash;&gt;-->
<!--&lt;!&ndash;                          @click="changeContentDisplayed('UserJourneys')"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <font-awesome-icon class="icon" icon="map"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  </button>&ndash;&gt;-->

<!--&lt;!&ndash;                  <button :class="{'btn': true, 'btn-lg': true, 'btn-white': true, 'active': contentDisplayed === 'TreasureChest'}"&ndash;&gt;-->
<!--&lt;!&ndash;                          @click="changeContentDisplayed('TreasureChest')">&ndash;&gt;-->
<!--&lt;!&ndash;                    <font-awesome-icon class="icon" icon="gem"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  </button>&ndash;&gt;-->

<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                &lt;!&ndash; / .nav &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->

<!--&lt;!&ndash;              <div class="col-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Navigation (button group) &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="nav btn-group">&ndash;&gt;-->

<!--&lt;!&ndash;                  <button :class="{'btn': true, 'btn-lg': true, 'btn-white': true, 'active': viewType === 'grid'}"&ndash;&gt;-->
<!--&lt;!&ndash;                          @click="changeViewType('grid')"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="fe fe-grid"></span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <Icon icon="mdi-light:view-module" height="28px" />&ndash;&gt;-->
<!--&lt;!&ndash;                  </button>&ndash;&gt;-->

<!--&lt;!&ndash;                  <button :class="{'btn': true, 'btn-lg': true, 'btn-white': true, 'active': viewType === 'list'}"&ndash;&gt;-->
<!--&lt;!&ndash;                          @click="changeViewType('list')"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <Icon icon="mdi-light:format-list-bulleted" height="28px" />&ndash;&gt;-->
<!--&lt;!&ndash;                  </button>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                &lt;!&ndash; / .nav &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; Button &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <router-link :to="{ name: 'JourneyMap' }" class="btn btn-lg btn-white">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="btn-white">&ndash;&gt;-->
<!--&lt;!&ndash;                    <Icon icon="mdi-light:plus-circle" height="28px"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="row mb-4">-->
<!--              <div class="col-auto">-->
<!--                Tags:-->
<!--              </div>-->
<!--              <div class="col d-flex justify-content-start">-->
<!--                <a class="badge bg-primary-soft ms-1">Sales</a>-->
<!--                <a class="badge bg-primary-soft ms-1">Dubai</a>-->
<!--                <a class="badge bg-primary-soft ms-1">COMMOVIS</a>-->
<!--              </div>-->
<!--            </div>-->
            <!-- / .row -->

<!--            <button @click="addTenantIdToEveryTable()">Update</button>-->

            <UserJourneys v-show="contentDisplayed === 'UserJourneys'" :viewType="viewType"></UserJourneys>

            <div v-show="viewType === 'list'"
                 :style="{ 'color': primary_color }"
                 class="icon icon-view gird-view"
                 title="grid-view"
                 @click="changeViewType('grid')"
            >
              <font-awesome-icon icon="fa-magnifying-glass-plus"/>
            </div>
            <div v-show="viewType === 'grid'"
                 :style="{ 'color': primary_color }"
                 class="icon icon-view list-view"
                 title="list-view"
                 @click="changeViewType('list')"
            >
              <font-awesome-icon icon="fa-magnifying-glass-minus"/>
            </div>

          </div>
<!--        </div>-->

      </div>

<!--      &lt;!&ndash; / .main-content &ndash;&gt;-->
    </template>
  </CentralLayout>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';

import CentralLayout from '@/layouts/CentralLayout';

import UserJourneys from "@/components/journey/UserJourneys.vue";

// Import component
import { Icon, addCollection } from '@iconify/vue';

// Import requires bundler that can import JSON files
import mdiIcons from '@iconify/json/json/mdi-light.json';
addCollection(mdiIcons)

// Import icon data.
// You can use any variable name instead of 'homeIcon'
// because exports are not named
import homeIcon from '@iconify-icons/mdi-light/home';
import ImportUsers from "@/components/users/ImportUsers.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";


export default {
  components: {
    FontAwesomeIcon,
    ImportUsers,
    CentralLayout,

    Icon,
    UserJourneys,
  },
  setup() {

    const store = useStore();
    const hasContentLoaded = ref(false);
    const userJourneys = computed(() => store.getters['journey/getUserJourneys']);

    const viewType = ref('grid');

    const contentDisplayed = ref('UserJourneys');

    const primary_color = store.state.tenant.tenantinfo.primarycolor;

    const changeViewType = (newViewType) => {
      viewType.value = newViewType;
    };

    const changeContentDisplayed = (newContentDisplayed) => {
      contentDisplayed.value = newContentDisplayed;
    };

    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);
      if (!store.getters['journey/getLastUpdated']) {
        await store.dispatch('journey/listUserJourneys');
      }
      store.dispatch('journey/listUserJourneys'); // Update in the background
      hasContentLoaded.value = true;
      await store.dispatch('loading/setLoading', false);
    });

    return {
      icons: {
        // Assign homeIcon to icons.home
        home: homeIcon
      },
      viewType,
      contentDisplayed,
      primary_color,
      changeViewType,
      changeContentDisplayed,
      hasContentLoaded,
      userJourneys,
    }
  }
}
</script>

<style lang="scss" scoped>

#dashboard-page {
  padding: 20px;

  .header-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .icon {
      font-size: 28px;
    }
  }

  .icon.icon-view {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 2;
    font-size: 30px;
    opacity: 0.9;
    cursor: pointer;
  }

}

@media only screen and (max-width: 768px) {
  #dashboard-page {
    padding: 10px;
  }
}

</style>