<template>

  <div v-if="hasContentLoaded" class="linked-assets">

<!--        <div class="title" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">Assets</div>-->

        <div class="actions">

<!--          <div class="search-wrapper">-->
<!--            <font-awesome-icon class="icon icon-search" icon="magnifying-glass"/>-->
<!--            <input id="search" name="search" type="text" v-model="searchFilter">-->
<!--          </div>-->

          <div v-if="memoryCards.length > 0" class="languages-wrapper">
            <label for="language">HB Memo Cards:</label>
            <select id="language" name="language" v-model="selectedLanguage">
              <option
                  v-for="(language, indexLanguage) in allLanguages"
                  :key="indexLanguage"
                  :value="language"
              >
                {{ language }}
              </option>
            </select>
          </div>

        </div>

        <div v-if="assets.length > 0" class="linked-assets-items">

          <div class="assets_wrapper">

            <div v-for="(linkedAsset, indexLinkedAsset) in expeditionLogs"
                 :key="indexLinkedAsset"
                 class="asset_wrapper">
              <div class="expedition_logs">
                <ExpeditionLogCard :item="linkedAsset"/>
              </div>
            </div>

            <div v-for="(linkedAsset, indexLinkedAsset) in memoryCards"
                 :key="indexLinkedAsset"
                 class="asset_wrapper">
              <div class="memory_card">
                <MemoryCard :card="linkedAsset"/>
              </div>
            </div>

            <div v-for="(linkedAsset, indexLinkedAsset) in attachments"
                 :key="indexLinkedAsset"
                 class="asset_wrapper">
              <div v-if="linkedAsset.Type === 'Attachment'"
                   class="attachment-asset"
                   @click="downloadAsset(linkedAsset)">
                <div class="icon attachment">
                  <font-awesome-icon icon="paperclip"/>
                </div>
                <span>{{ linkedAsset.Label }}</span>
              </div>
            </div>

          </div>

          <!--          <div v-for="(memoryCard, indexLinkedAsset) in displayedTreasureChestAssets"-->
          <!--               :key="indexLinkedAsset"-->
          <!--               class="memory-cards">-->
          <!--            <MemoryCard :card="memoryCard"-->
          <!--                        :isCheckmarkDisplayed="isCheckmarkDisplayed"-->
          <!--                        :isMemoryCardChecked="isMemoryCardChecked(card)"-->
          <!--                        :isGemDisplayed="isMemoryCardAlreadyInJourney(card)"-->
          <!--                        @checkMemoryCard="checkMemoryCardHandler($event)"-->
          <!--                        @uncheckMemoryCard="uncheckMemoryCardHandler($event)"-->
          <!--                        v-if="memoryCard.Type === 'MemoryCard"-->
          <!--            />-->
          <!--          </div>-->

          <!--            <MemoryCards :displayAllMemoryCards="false"-->
          <!--                         :isCheckmarkDisplayed="false"-->
          <!--                         :isHeaderDisplayed="false"-->
          <!--                         :searchValue="searchFilter"-->
          <!--                         :displayedMemoryCards="displayedTreasureChestAssets"-->
          <!--            />-->

          <!--          <Tabs :tabsToSkip="getSkippedTabsTitles(treasureChestAssets)">-->

          <!--            <Tab title="Attachments">-->

          <!--              <div class="attachments-assets">-->
          <!--                <div v-for="(linkedAsset, indexLinkedAsset) in treasureChestAssets"-->
          <!--                     :key="indexLinkedAsset"-->
          <!--                     class="attachment-asset-wrapper">-->
          <!--                  <div v-if="linkedAsset.Type === 'Attachment'"-->
          <!--                       class="attachment-asset"-->
          <!--                       @click="downloadAsset(linkedAsset)"-->
          <!--                  >-->
          <!--                    <div class="icon attachment">-->
          <!--                      <font-awesome-icon icon="paperclip"/>-->
          <!--                    </div>-->
          <!--                    <span>{{ linkedAsset.Label }}</span>-->
          <!--                  </div>-->
          <!--                </div>-->

          <!--              </div>-->

          <!--            </Tab>-->

          <!--            <Tab title="HB Memo Cards">-->

          <!--              <MemoryCards :displayAllMemoryCards="false"-->
          <!--                           :isCheckmarkDisplayed="false"-->
          <!--                           :displayedMemoryCards="treasureChestAssets"-->
          <!--              />-->

          <!--            </Tab>-->

          <!--          </Tabs>-->

        </div>

      </div>

</template>

<script>

import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import CentralLayout from '@/layouts/CentralLayout';
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Cards from "@/assets/memory_cards/cardDetails.js";
import MemoryCard from "@/components/common/MemoryCard.vue";
import ExpeditionLogCard from "@/components/common/ExpeditionLogCard.vue";
import _ from "lodash";

export default {

  name: 'LinkedAssets',

  props: {
    assets: {
      type: Array,
      default: [],
    }
  },

  components: {
    ExpeditionLogCard,
    MemoryCard,
    FontAwesomeIcon,
    CentralLayout,
    Tabs,
    Tab,
  },

  setup(props) {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isFirstPageEnter = ref(true);

    const ExpeditionLogs = computed(() => store.getters["tenantSpecificAssets/getterExpeditionLogs"]);

    const attachments = ref([]);
    const memoryCards = ref([]);
    const expeditionLogs = ref([]);

    // const treasureChestAssets = ref([]);

    // const filters = ref({
    //   Types: [],
    //   Sources: [],
    //   Tags: []
    // });

    // const selectedFilters = ref({
    //   Types: [],
    //   Sources: [],
    //   Tags: []
    // });

    // const areFiltersDisplayed = ref(false);

    // const searchFilter = ref('');

    // const journeysNames = ref([]);

    // const displayedTreasureChestAssets = computed(() => {
    //
    //   let displayedAssets = treasureChestAssets.value.filter((treasureChestAsset) => {
    //
    //     // return (
    //     //     (forecast.Plant_Name && forecast.Plant_Name.toString().toLowerCase().includes(mySearch))
    //     //     || (forecast.Group_Account && forecast.Group_Account.toString().toLowerCase().includes(mySearch))
    //     //     || (forecast.Customer_Sold_To_ID && forecast.Customer_Sold_To_ID.toString().toLowerCase().includes(mySearch))
    //     // )
    //
    //     let searchMatch = true;
    //     let mySearch = searchFilter.value.toLowerCase();
    //     if (mySearch !== '') {
    //       if (treasureChestAsset.Type === 'Attachment') {
    //         searchMatch = treasureChestAsset.Label.toString().toLowerCase().includes(mySearch);
    //       }
    //       if (treasureChestAsset.Type === 'MemoryCard') {
    //         const frontTitle = treasureChestAsset.frontTitle[selectedLanguage.value];
    //         const frontBottomText = treasureChestAsset.frontBottomText[selectedLanguage.value];
    //
    //         const matchTitle = frontTitle.toString().toLowerCase().includes(mySearch);
    //         const matchBottomText = frontBottomText.toString().toLowerCase().includes(mySearch);
    //
    //         searchMatch =  matchTitle || matchBottomText;
    //       }
    //     }
    //
    //     let typeMatch = true;
    //     if (selectedFilters.value.Types.length !== 0) {
    //       let foundMatch = selectedFilters.value.Types.find((filterType) => filterType.Value === treasureChestAsset.Type);
    //       typeMatch = (foundMatch !== undefined)
    //     }
    //
    //     let sourceMatch = true;
    //     if (selectedFilters.value.Sources.length !== 0) {
    //       let foundMatch = selectedFilters.value.Sources.find((filterSource) => {
    //         return treasureChestAsset.Sources.Journeys.includes(filterSource.ID);
    //       });
    //       sourceMatch = (foundMatch !== undefined);
    //     }
    //
    //
    //     return searchMatch && typeMatch && sourceMatch;
    //
    //   });
    //
    //   return displayedAssets;
    //
    // });

    const allMemoryCards = ref([]);
    allMemoryCards.value = Cards;

    const allLanguages = ref(['bg', 'cz', 'de', 'en', 'fr', 'ho', 'hr', 'hu', 'it', 'mx', 'no', 'ro', 'rs', 'se', 'si', 'tr', 'us', 'pl']);
    const selectedLanguage = ref();
    watch(selectedLanguage, async (newValue, oldValue) => {

      if (!isFirstPageEnter.value) {
        await store.dispatch('userPreferences/updateLanguage', newValue);
      }

    });

    watch(() => _.cloneDeep(props.assets), (newValue, oldValue) => {
          nextTick(() => {
            separateByType();
          });
        },
        {deep: true}
    );

    const downloadAsset = async (linkedAsset) => {

      let url = null;

      if (linkedAsset.hasOwnProperty('Url')) {
        url = linkedAsset.Url;
      }
      else {

        let payload = {
          file_name: linkedAsset.Name,
          file_label: linkedAsset.Label,
          folder_name: linkedAsset.FolderName
        };

        url = await store.dispatch('file/getFile', payload);
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', linkedAsset.Label);
      link.click();
    };

    const separateByType = () => {
      attachments.value = props.assets.filter((asset) => asset.Type === 'Attachment');
      memoryCards.value = props.assets.filter((asset) => asset.Type === 'MemoryCard');
      expeditionLogs.value = props.assets.filter((asset) => asset.Type === 'ExpeditionLog');
    }

    // const getFilters = () => {
    //
    //   treasureChestAssets.value.forEach((treasureChestAsset) => {
    //
    //     // get type filters
    //     let alreadyExists = (filters.value.Types.find((typeFilter) => typeFilter.Value === treasureChestAsset.Type)) !== undefined;
    //
    //     if (!alreadyExists) {
    //
    //       let typeFilterObject = {
    //         Value: treasureChestAsset.Type,
    //       }
    //
    //       switch (typeFilterObject.Value) {
    //
    //         case 'MemoryCard': {
    //           typeFilterObject.Label = 'Memo Card'
    //           break;
    //         }
    //
    //         default: {
    //           typeFilterObject.Label = typeFilterObject.Value
    //           break;
    //         }
    //
    //       }
    //
    //       filters.value.Types.push(typeFilterObject);
    //     }
    //
    //     // get sources filters
    //     treasureChestAsset.Sources.Journeys.forEach((journeyID) => {
    //
    //       let alreadyExists = (filters.value.Sources.find((sourceFilter) => sourceFilter.ID === journeyID)) !== undefined;
    //
    //       if (!alreadyExists) {
    //
    //         let journeyObject = journeysNames.value.find((journey) => journey.ID === journeyID);
    //
    //         if (journeyObject !== undefined) {
    //           filters.value.Sources.push(journeyObject);
    //         }
    //       }
    //     })
    //
    //   });
    // };

    onMounted(async () => {

      selectedLanguage.value = store.state.userPreferences.userPreferences.Language;

      // treasureChestAssets.value = await store.dispatch('treasureChest/getTreasureChestForUser');

      separateByType();

      memoryCards.value.forEach((asset) => {
        let memo_card = allMemoryCards.value.find((card) => card.batchNumber === asset.BatchNumber && card.memoryCardNumber === asset.MemoryCardNumber);
        Object.assign(asset, asset, memo_card);
      });

      expeditionLogs.value.forEach((asset) => {
        let expedition_log = ExpeditionLogs.value.find((expedition_log) => expedition_log.ExpeditionLogNumber === asset.ExpeditionLogNumber);
        Object.assign(asset, asset, expedition_log);
      });

      // props.assets.forEach((asset) => {
      //   if (asset.Type === 'MemoryCard') {
      //     hasMemoryCards.value = true;
      //     let memo_card = allMemoryCards.value.find((card) => card.batchNumber === asset.BatchNumber && card.memoryCardNumber === asset.MemoryCardNumber);
      //     Object.assign(asset, asset, memo_card);
      //   }
      //
      //   if (asset.Type === 'ExpeditionLog') {
      //     let expedition_log = allMemoryCards.value.find((expedition_log) => expedition_log.ExpeditionLogNumber === asset.ExpeditionLogNumber);
      //     Object.assign(asset, asset, expedition_log);
      //   }
      //
      // });

      // journeysNames.value = await store.dispatch('journey/listAllJourneysNames');

      // getFilters();

      hasContentLoaded.value = true;

      await store.dispatch('loading/setLoading', false);

      isFirstPageEnter.value = false;

    });

    return {
      store,
      hasContentLoaded,
      selectedLanguage,
      allLanguages,
      attachments,
      memoryCards,
      expeditionLogs,
      downloadAsset,
    }

  }

}

</script>

<style lang="scss" scoped>

.linked-assets {
  padding: 20px 0;

  .title {
    font-size: 30px;
    text-align: left;
    margin-bottom: 10px;
  }

  .linked-assets-items {
    display: flex;
    align-items: flex-start;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    //.search-wrapper {
    //  position: relative;
    //
    //  .icon.icon-search {
    //    position: absolute;
    //    top: 50%;
    //    left: 5px;
    //    transform: translateY(-50%);
    //    opacity: 0.5;
    //    width: 15px;
    //  }
    //
    //  input {
    //    padding: 5px 25px;
    //    border: 1px solid gray;
    //    border-radius: 5px;
    //    width: 100%;
    //  }
    //
    //}

    .languages-wrapper {

      display: flex;
      align-items: center;
      gap: 5px;

      select {
        padding: 5px 10px;
        border: 1px solid gray;
        border-radius: 5px;
        text-transform: uppercase;
        cursor: pointer;
      }

    }

    //.icon.filter {
    //  position: relative;
    //  width: fit-content;
    //  justify-content: flex-start;
    //
    //  .icon-filter {
    //    font-size: 20px;
    //  }
    //
    //  .filters_wrapper {
    //    position: absolute;
    //    top: 100%;
    //    left: 100%;
    //    z-index: 1;
    //    background-color: #ffca9f;
    //    max-width: 300px;
    //    min-width: 200px;
    //    border-radius: 10px;
    //    padding: 10px 15px;
    //    cursor: default;
    //
    //    .filter_wrapper {
    //      margin: 5px 0;
    //
    //      .filter-title {
    //        font-weight: bold;
    //      }
    //
    //      .filter_option {
    //        margin: 10px 0;
    //
    //        label {
    //          display: flex;
    //          align-items: flex-start;
    //          gap: 5px;
    //          line-height: 1;
    //          cursor: pointer;
    //        }
    //        input {
    //          cursor: pointer;
    //        }
    //      }
    //
    //    }
    //
    //  }
    //
    //}

  }

  .assets_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 40px;

    .asset_wrapper {


      .attachment-asset {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        color: var(--bs-body-color);
        text-decoration: unset;
        background-color: #fafafa;
        width: fit-content;
        padding: 15px;
        border-radius: 10px;
        cursor: pointer;
        -webkit-box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.3);
        min-width: 130px;

        .icon.attachment {
          font-size: 30px;
        }

        .attachment-tags {
          width: 90%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          gap: 3px;

          .tag {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 3px;
            background-color: lightgrey;
            border-radius: 5px;
            font-size: 13px;
            width: fit-content;
            padding: 5px;
          }
        }

        .icon {

          &.tags {
            position: absolute;
            top: 7px;
            left: 35px;
            font-size: 21px;
            align-self: flex-start;
            cursor: default;
          }

          &.tag {
            font-size: 10px;
            color: black;
            padding: 0;
          }
        }
      }

    }

  }

  //.languages-wrapper {
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //  gap: 10px;
  //  margin-bottom: 10px;
  //
  //  select {
  //    padding: 5px 10px;
  //    border: 1px solid gray;
  //    border-radius: 5px;
  //    text-transform: uppercase;
  //  }
  //}
  //
  //.search-wrapper {
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //  gap: 10px;
  //  margin-bottom: 10px;
  //
  //  input {
  //    padding: 5px 10px;
  //    border: 1px solid gray;
  //    border-radius: 5px;
  //    width: 10%;
  //  }
  //}
  //
  //.cards-wrapper {
  //  display: flex;
  //  align-items: center;
  //  justify-content: flex-start;
  //  flex-wrap: wrap;
  //  gap: 20px;
  //  margin-top: 10px;
  //  padding: 20px 0;
  //}
}

@media only screen and (max-width: 768px) {
  #treasure-chest {
    padding: 0;

    .languages-wrapper {
      text-align: left;
    }

    //.search-wrapper {
    //  input {
    //    width: 40%;
    //  }
    //}
    //
    //.cards-wrapper {
    //  justify-content: center;
    //}
  }
}

</style>
