<template>
  <div v-if="hasContentLoaded" class="logon-wrapper d-flex align-items-center min-vh-100 " :style="tenantcolor">
    <div class="container h-100">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 text-center">
          <img v-if="tenantLogoURL !== null" class="tenant_logo" :src="tenantLogoURL" alt="Commovis" />
          <img v-else class="tenant_logo" :src="commovisLogo" alt="Commovis" />

          <amplify-authenticator username-alias="email">
            <!-- hide-toast -->
            <!-- //:initial-auth-state="authenticatorState" -->
            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
            <!-- eslint-disable -->
            <amplify-sign-in
              slot="sign-in"
              username-alias="email"
              :header-text="tenantwelcome"
              :hide-sign-up="true"
            >
              <!-- eslint-enable-->
            </amplify-sign-in>
          </amplify-authenticator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, ref} from 'vue';
import { useStore } from 'vuex';
import { Hub } from 'aws-amplify'
import {useRouter} from "vue-router";
import { useDevice } from '@/composables/useDevice';
import commovisLogo from "@/assets/logo_long.png";

export default {

  setup() {

    const store = useStore();

    const router = useRouter();
    const { isMobile } = useDevice();

    const hasContentLoaded = ref(false);

    const isAuthenticated = computed(() => {
      return store.state.auth.loggedIn
    });

    const tenantLogoURL = ref(null);

    const tenantname = computed(() => {
      return store.state.tenant.tenantinfo.title
    });
    //console.log(store.state.tenant.tenantinfo)

    const tenantcolor = computed(() => {
      return {
        '--primary-color': store.state.tenant.tenantinfo.primarycolor
      }
    });
    const tenantwelcome = computed(() => {
      return 'Sign in to ' + tenantname.value
    });

    const getTenantLogoURL = async() => {
      if (store.state.tenant.tenantinfo.logo !== null && store.state.tenant.tenantinfo.logo !== '' && store.state.tenant.tenantinfo.logo !== undefined) {
        tenantLogoURL.value = await store.dispatch('file/getTenantLogoFile');
      }
      else {
        tenantLogoURL.value = null;
      }
    };


    onMounted(async () => {
      await getTenantLogoURL();
      await store.dispatch('loading/setLoading', false);
      hasContentLoaded.value = true;
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
            console.log('The user signed in now!');
            // router.push({
            //   name: 'Dashboard'
            // });
            router.push({ name: isMobile.value ? 'MobileDashboard' : 'Dashboard' });
            break;
        }
      })
    })

    return {
      tenantname,
      tenantwelcome,
      tenantcolor,
      isAuthenticated,
      hasContentLoaded,
      tenantLogoURL,
      commovisLogo,
    }
  }
}
</script>

<style lang="scss" scoped>

.logon-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
}

</style>

<style>

amplify-authenticator {
  --container-height: 400px;
  --amplify-primary-color: var(--primary-color);
  --amplify-secondary-color: black;
  --amplify-primary-tint: black;
  --amplify-primary-shade: var(--primary-color);
  --amplify-secondary-contrast: white;
}

.tenant_logo {
  margin-top: 8vh;
  margin-bottom: 5vh;
  width: 100%;
  max-width: 380px;
}
.toast {
  position: relative !important;
}
</style>
