<template>
  <MobileLayout>
    <template v-slot:default>
      <div v-if="hasContentLoaded" class="mobile-dashboard">
        <div class="title" :style="{ 'color': primary_color }">My Journeys</div>
        <div v-show="viewType === 'list'"
             :style="{ 'color': primary_color }"
             class="icon icon-view list-view"
             title="list-view"
             @click="changeViewType('list')"
        >
        </div>
        <UserJourneys v-show="contentDisplayed === 'UserJourneys'" :viewType="viewType" :showCreateNew="false" :showMobile="true" :userJourneys="userJourneys"></UserJourneys>
      </div>
   
    </template>
  </MobileLayout>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import MobileLayout from '@/layouts/MobileLayout.vue';
import UserJourneys from "@/components/journey/UserJourneys.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: 'MobileDashboard',
  components: {
    MobileLayout,
    UserJourneys,
    FontAwesomeIcon
  },
  setup() {
    const store = useStore();
    const viewType = ref('list');
    const contentDisplayed = ref('UserJourneys');
    const primary_color = ref(store.state?.tenant?.tenantinfo?.primarycolor || '#000000'); // Default to black if undefined
    const hasContentLoaded = ref(false);
    const userJourneys = computed(() => store.getters['journey/getUserJourneys']);

    const changeViewType = (newViewType) => {
      viewType.value = newViewType;
    };

    const changeContentDisplayed = (newContentDisplayed) => {
      contentDisplayed.value = newContentDisplayed;
    };

    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);
      if (!store.getters['journey/getLastUpdated']) {
        await store.dispatch('journey/listUserJourneys');
      }
      await store.dispatch('journey/listUserJourneys'); // Update in the background

      // Wait for all images to load
      await store.dispatch('file/loadAllImages', userJourneys.value);

      hasContentLoaded.value = true;
      await store.dispatch('loading/setLoading', false);
    });

    return {
      viewType,
      contentDisplayed,
      primary_color,
      hasContentLoaded,
      changeViewType,
      changeContentDisplayed,
      userJourneys
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-dashboard {
  padding: 20px;
  padding-bottom: 60px;
}
.title {
    font-size: 30px;
    text-align: left;
    margin-bottom: 10px;
  }

.icon.icon-view {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 2;
  font-size: 30px;
  opacity: 0.9;
  cursor: pointer;
}
</style>