import axios from 'axios';
import axiosRetry from "axios-retry";

const getDefaultState = () => {
    return {
        isUploadVisible: false
    }
}

// initial state
const state = getDefaultState()

const getters = {
    getIsUploadVisible: state => state.isUploadVisible,
}

const actions = {

    // checkPicture({ commit, dispatch, state, rootState }, payload) {
    //
    //     const payload_checkPicture = {
    //         name: payload.name,
    //         ext: payload.ext,
    //         type: payload.type
    //     }
    //
    //     const baseAPI = rootState.tenant.tenantinfo.baseAPI;
    //
    //     const imageName =`${payload.name}.${payload.ext}`;
    //
    //     // Works with custom axios instances
    //     const client = axios.create({
    //         baseURL: baseAPI
    //     })
    //     axiosRetry(client, {
    //         retries: 3,
    //         retryDelay: axiosRetry.exponentialDelay
    //     })
    //
    //     let config = {
    //         headers: {
    //             Authorization: rootState.auth.bearer
    //         }
    //     }
    //
    //     try {
    //
    //         const picture_payload = {
    //             picture: imageName
    //         }
    //
    //
    //         let response = client
    //             .post('file/get-upload-signed-url', payload_checkPicture, config)
    //             .then(result => {
    //                 // console.log('retry OK')
    //                 // console.log(result.data)
    //                 result.data // 'ok'
    //
    //                 if (payload.folder_name === 'profile') {
    //                     dispatch('auth/setUserPicture', picture_payload, { root: true })
    //                 }
    //
    //             })
    //             .catch(error => {
    //                 // The first request fails
    //                 console.log('retry error')
    //                 console.log(error)
    //             })
    //     } catch (err) {
    //         alert(err)
    //         console.log(err)
    //         commit('loading/setLoading', false, {
    //             root: true
    //         })
    //         commit('setUploadVisible', false)
    //     }
    // },

    async uploadFile({ commit, dispatch, state, rootState }, payload) {

        const baseAPI = rootState.tenant.tenantinfo.baseAPI;

        axios.defaults.baseURL = baseAPI;

        let config = {
            headers: {
                Authorization: rootState.auth.bearer
            }
        }
        try {
            const payload_signedURL = {
                name: payload.name,
                ext: payload.ext,
                type: payload.type,
                folder_name: payload.folder_name
            }

            let response = await axios.post(
                'v2/file/get-upload-signed-url',
                payload_signedURL,
                config
            )

            const payload_file = payload.file;

            await axios.put(response.data.uploadURL, payload_file);
            // await dispatch('checkPicture', payload_signedURL);

            if (payload.folder_name === 'profile') {

                const imageName =`${payload.name}.${payload.ext}`;

                const picture_payload = {
                    picture: imageName
                }

                await dispatch('auth/setUserPicture', picture_payload, { root: true })
            }

        } catch (err) {
            commit('loading/setLoading', false, { root: true })
            commit('setUploadVisible', false)
        }
    },

    async getFile({commit, dispatch, rootState}, payload) {

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },

            };

            let payloadAPI = {};

            if (payload.hasOwnProperty('full_key')) {
                payloadAPI = {
                    full_key: payload.full_key
                }
            }
            else {
                payloadAPI = {
                    file_name: payload.file_name,
                    folder_name: payload.folder_name
                };
            }

            if (payload.hasOwnProperty('file_label')) {
                payloadAPI.file_label = payload.file_label;
            }

            const response = await axios.post('/v2/file/get-download-signed-url', payloadAPI, config);

            return response.data.downloadURL;

        }
        catch (err) {
            // throw new Error(err);
        }

    },
    async loadAllImages({ dispatch }, journeys) {
    const imageLoadPromises = journeys.map(async (journey) => {
      if (journey.Picture) {
        journey.PictureURL = await dispatch('getFile', {
          file_name: journey.Picture,
          folder_name: `journey/${journey.ID}`
        });
      }
    });

    await Promise.all(imageLoadPromises);
  },


    async getTenantLogoFile({commit, dispatch, rootState}) {

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },

            };

            let payloadAPI = {
                file_name: rootState.tenant.tenantinfo.logo,
                tenant_id: rootState.tenant.tenantinfo.id
            };

            const response = await axios.post('/v2/file/get-tenant-logo-signed-url', payloadAPI, config);

            return response.data.downloadURL;

        }
        catch (err) {
            // throw new Error(err);
        }

    },

    async getSVG({commit, dispatch, rootState}, data) {

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },

            };

            let payload = {
                file_name: data.file_name,
                folder_name: data.folder_name
            };

            const response = await axios.post('/v2/file/get-download-signed-url', payload, config);

            let signedURL = response.data.downloadURL;

            const svgResponse = await axios.get(signedURL, { responseType: 'text' })

            return svgResponse.data;

        }
        catch (err) {
            // throw new Error(err);
        }

    },

    async deleteFile({ commit, state, rootState }, payload) {
        commit('loading/setLoading', true, { root: true });
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        let config = {
            headers: {
                Authorization: rootState.auth.bearer
            },
            data: {
                folder_name: payload.folder_name,
                file_name: payload.file_name
            },
        }
        try {
            await axios.delete('/v2/file/delete-file', config)

            commit('loading/setLoading', false, { root: true })
        } catch (err) {
            commit('loading/setLoading', false, { root: true })
            throw new Error(err);
        }
    },

    async copyFileBetweenS3Folders({commit, dispatch, rootState}, payload) {

        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        let config = {
            headers: {
                Authorization: rootState.auth.bearer
            },
        };

        let payloadAPI = {
            source_key: payload.source_key,
            folder_name: payload.folder_name,
            file_name: payload.file_name,
        };
        try {
            await axios.post('/v2/file/copy-file-to-S3-folder', payloadAPI, config)
            commit('loading/setLoading', false, { root: true });

        } catch (err) {

            commit('loading/setLoading', false, { root: true })
            throw new Error(err);
        }

    }

}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setUploadVisible(state, status) {
        state.isUploadVisible = status
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
