<template>
  <CentralLayout>
    <template v-slot:content>

      <div id="settings-page">

          <small class="text-capitalize">Overview</small>
          <h2>Settings</h2>

          <Tabs>
            <Tab title="Profile">
              <Profile/>
            </Tab>
            <Tab title="Security">
              <Security/>
            </Tab>
            <Tab title="Notification">
              <Notification/>
            </Tab>
          </Tabs>

      </div>

    </template>
  </CentralLayout>
</template>

<script>
import CentralLayout from "@/layouts/CentralLayout.vue";
import MainNav from "@/components/MainNav.vue";
import Tab from "@/components/common/Tab.vue";
import Tabs from "@/components/common/Tabs.vue";
import Profile from "@/components/settings/Profile.vue";
import Security from "@/components/settings/Security.vue";
import Notification from "@/components/settings/Notification.vue";
import {useStore} from "vuex";
import {onMounted} from "vue";

export default {

  name: 'Settings',

  components: {
    CentralLayout,
    MainNav,
    Tabs,
    Tab,
    Profile,
    Security,
    Notification,
  },

  setup() {

    const store = useStore();

    onMounted(() => {
      store.dispatch('loading/setLoading', false)
    });

    return {

    }
  },

}

</script>

<style lang="scss" scoped>

#settings-page {
  padding: 20px;
}

</style>