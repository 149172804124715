<template>

  <div :style="{'max-height': maxHeight + 'px'}" id="journey-content">
    <div class="journey-title">

      <div class="menu-icon" v-if="!isSidebarDisplayed" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }" @click="toggleSidebar()">

        <font-awesome-icon icon="list-ul" title="Open Sidebar"/>

      </div>

      <div class="title" :style="{ 'color': store.state.tenant.tenantinfo.primarycolor }">{{ journeyDetails.Name }}</div>

    </div>

    <div :class="{ 'content-wrapper': !isSidebarDisplayed, 'content-wrapper opened': isSidebarDisplayed }">

      <div v-for="(structureItem, indexStructureItem) in journeyDetails.Structure"
           :key="indexStructureItem"
           class="structure-item"
           v-show="activeStructureItem.structureItemID === structureItem.ID"
      >
        <div v-if="structureItem.Type === 'Chapter'" class="chapter">

          <div v-for="(unit, indexUnit) in structureItem.Units"
               :key="indexUnit"
               class="unit"
               v-show="activeStructureItem.indexUnit === indexUnit"
          >

            <div class="content-header">

              <h2 class="content-title">{{ structureItem.Name }} - {{ unit.Name }}</h2>

              <div class="separator"></div>

            </div>

            <div v-for="(block, indexBlock) in unit.Blocks"
                 :key="indexBlock"
                 class="block"
            >

              <div v-for="(element, indexElement) in block.Elements"
                   :key="indexElement"
                   class="element"
                   v-html="convertToHTML(element)"
              >
              </div>

            </div>

<!--            <div class="notes-wrapper">-->

<!--              <div class="icon add-note">-->
<!--                <font-awesome-icon icon="note-sticky"/>-->
<!--              </div>-->

<!--            </div>-->

<!--            unit assets-->
            <div v-if="activeStructureItem.indexUnit === indexUnit && unit.LinkedAssets.length > 0" class="existing-assets">

              <div class="separator"></div>

              <LinkedAssets :assets="unit.LinkedAssets"/>

            </div>

            <div class="bottom-buttons" :style="{ 'backgroundColor': store.state.tenant.tenantinfo.primarycolor }">

              <!--          previous button-->
              <div class="navigation_button_wrapper previous">

                <div v-if="getPreviousItemName(structureItem, indexStructureItem, indexUnit) !== null"
                     class="button"
                     @click="goToPreviousItem(structureItem, indexStructureItem, indexUnit)">

                  <div class="button-icon">
                    <font-awesome-icon icon="circle-chevron-left"/>
                  </div>

                  <div class="button-label">
                    <span>{{ getPreviousItemName(structureItem, indexStructureItem, indexUnit) }}</span>
                  </div>

                </div>

              </div>

              <!--          progress button-->
              <div class="button journey-progress">

                <div class="progress-icon"
                     v-if="journeyDetails.Structure[indexStructureItem].Units[indexUnit].Completed === true">
                  <font-awesome-icon :icon="['fas', 'circle-check']"/>
                </div>

                <div class="progress-icon" v-else
                     @click="checkmarkClickHandler(structureItem, indexStructureItem, unit)">
                  <font-awesome-icon :icon="['far', 'circle-check']"/>
                </div>

                <div v-if="journeyDetails.hasOwnProperty('ParticipantProgressPercentage')" class="progress progress-sm">
                  <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{ 'width': journeyDetails.ParticipantProgressPercentage + '%', 'backgroundColor': '#fff' }"
                      :aria-valuenow="journeyDetails.ParticipantProgressPercentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                  ></div>
                </div>

                <div v-if="journeyDetails.hasOwnProperty('ParticipantProgressPercentage')" class="progress-value">
                  {{ formattedNumber(journeyDetails.ParticipantProgressPercentage, 2) }} % Complete
                </div>

              </div>

              <!--          next button-->
              <div class="navigation_button_wrapper next">

                <div v-if="getNextItemName(structureItem, indexStructureItem, indexUnit) !== null"
                     class="button"
                     @click="goToNextItem(structureItem, indexStructureItem, indexUnit)">

                  <div class="button-label">
                    <span>{{ getNextItemName(structureItem, indexStructureItem, indexUnit) }}</span>
                  </div>

                  <div class="button-icon">
                    <font-awesome-icon icon="circle-chevron-right"/>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div v-if="structureItem.Type === 'Event'" class="event">

          <div class="content-header">

            <h2 class="content-title">{{ structureItem.Name }}</h2>

            <div class="separator"></div>

          </div>

          <div class="periods_wrapper">

            <div v-if="structureItem.Options.IsModule && journeyDetails.hasOwnProperty('ParticipantProgress')">
              <progress :value="Math.floor(calculateModulesPercentage[journeyModules.findIndex(module => module.ID === structureItem.ID)].moduleProgressPercentage)" max="100"></progress>
              <span class="progress-bar-value">{{Math.floor(calculateModulesPercentage[journeyModules.findIndex(module => module.ID === structureItem.ID)].moduleProgressPercentage)}}%</span>
            </div>
            <div v-else>
              <progress value="100" max="100"></progress>
              <span class="progress-bar-value">100%</span>
            </div>


            <div class="icons-wrapper">
              <div class="calendar">
                <font-awesome-icon icon="calendar" v-if="structureItem.Options.IsMeeting"/>
              </div>
              <div class="locker">
                <font-awesome-icon icon="unlock" v-if="structureItem.Options.IsUnlock"/>
              </div>
            </div>

            <div class="details-wrapper">
              <div class="to-from">
                <div class="date-time_wrapper" v-if="structureItem.Options.IsMeeting">
                  <label>From:</label>
                  {{getFormattedDate(structureItem.Periods.From)}}
                </div>

                <div class="date-time_wrapper" v-if="structureItem.Options.IsMeeting">
                  <label>To:</label>
                  {{getFormattedDate(structureItem.Periods.To)}}
                </div>
              </div>
              <div class="unlock">
                <div class="date-time_wrapper" v-if="structureItem.Options.IsUnlock">
                  {{getFormattedDate(structureItem.Periods.Unlock)}}
                </div>
              </div>
            </div>

          </div>

          <div class="event-details_wrapper">
            <div v-for="(element, indexElement) in structureItem.Details.Elements"
                 :key="indexElement"
                 class="element"
                 v-html="convertToHTML(element)"
            >
            </div>
          </div>

          <div v-if="activeStructureItem.structureItemID === structureItem.ID && structureItem.LinkedAssets.length > 0" class="existing-assets">

            <div class="separator"></div>

            <LinkedAssets :assets="structureItem.LinkedAssets"/>

          </div>

          <div class="bottom-buttons" :style="{ 'backgroundColor': store.state.tenant.tenantinfo.primarycolor }">

            <!--          previous button-->
            <div class="navigation_button_wrapper previous">

              <div v-if="getPreviousItemName(structureItem, indexStructureItem, null) !== null"
                   class="button"
                   @click="goToPreviousItem(structureItem, indexStructureItem, null)">

                <div class="button-icon">
                  <font-awesome-icon icon="circle-chevron-left"/>
                </div>

                <div class="button-label">
                  <span>{{ getPreviousItemName(structureItem, indexStructureItem, null) }}</span>
                </div>

              </div>

            </div>

            <!--          progress button-->
            <div class="button journey-progress">

              <div v-if="journeyDetails.hasOwnProperty('ParticipantProgressPercentage')" class="progress progress-sm">
                <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ 'width': journeyDetails.ParticipantProgressPercentage + '%', 'backgroundColor': '#fff' }"
                    :aria-valuenow="journeyDetails.ParticipantProgressPercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
              </div>

              <div v-if="journeyDetails.hasOwnProperty('ParticipantProgressPercentage')" class="progress-value">
                {{ formattedNumber(journeyDetails.ParticipantProgressPercentage, 2) }} % Complete
              </div>

            </div>

            <!--          next button-->
            <div class="navigation_button_wrapper next">

              <div v-if="getNextItemName(structureItem, indexStructureItem, null) !== null"
                   class="button"
                   @click="goToNextItem(structureItem, indexStructureItem, null)">

                <div class="button-label">
                  <span>{{ getNextItemName(structureItem, indexStructureItem, null) }}</span>
                </div>

                <div class="button-icon">
                  <font-awesome-icon icon="circle-chevron-right"/>
                </div>

              </div>
            </div>

          </div>

        </div>

        <div v-if="structureItem.Type === 'Assignment'" class="assignment">

          <div class="content-header">

            <h2 class="content-title">{{ structureItem.Name }}</h2>

            <div class="separator"></div>

          </div>

          <div class="tasks">

            <div v-for="(task, indexTask) in structureItem.Tasks"
                 :key="indexTask"
                 class="task_wrapper"
            >

<!--        for participant journey role-->
              <input v-if="journeyDetails.hasOwnProperty('JourneyRole') && journeyDetails.JourneyRole === 'Participant'"
                     type="checkbox"
                     v-model="task.IsDone"
                     @change="updateParticipantAssignmentTask(structureItem, task, indexStructureItem, indexTask)"
              >

<!--        for other journey roles-->
              <input v-else type="checkbox" v-model="task.IsDone">

              <div
                  :class="{ 'element-wrapper': true, 'is-done': task.IsDone }"
              >

                <div v-for="(element, indexElement) in task.Content.Elements"
                     :key="indexElement"
                     class="element"
                     v-html="convertToHTML(element)"
                >
                </div>

              </div>

            </div>

          </div>

          <div v-if="activeStructureItem.structureItemID === structureItem.ID && structureItem.LinkedAssets.length > 0" class="existing-assets">

            <div class="separator"></div>

            <LinkedAssets :assets="structureItem.LinkedAssets"/>

          </div>

          <div class="bottom-buttons" :style="{ 'backgroundColor': store.state.tenant.tenantinfo.primarycolor }">

            <!--          previous button-->
            <div class="navigation_button_wrapper previous">

              <div v-if="getPreviousItemName(structureItem, indexStructureItem, null) !== null"
                   class="button"
                   @click="goToPreviousItem(structureItem, indexStructureItem, null)">

                <div class="button-icon">
                  <font-awesome-icon icon="circle-chevron-left"/>
                </div>

                <div class="button-label">
                  <span>{{ getPreviousItemName(structureItem, indexStructureItem, null) }}</span>
                </div>

              </div>

            </div>

            <!--          progress button-->
            <div class="button journey-progress">

              <div v-if="journeyDetails.hasOwnProperty('ParticipantProgressPercentage')" class="progress progress-sm">
                <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ 'width': journeyDetails.ParticipantProgressPercentage + '%', 'backgroundColor': '#fff' }"
                    :aria-valuenow="journeyDetails.ParticipantProgressPercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
              </div>

              <div v-if="journeyDetails.hasOwnProperty('ParticipantProgressPercentage')" class="progress-value">
                {{ formattedNumber(journeyDetails.ParticipantProgressPercentage, 2) }} % Complete
              </div>

            </div>

            <!--          next button-->
            <div class="navigation_button_wrapper next">

              <div v-if="getNextItemName(structureItem, indexStructureItem, null) !== null"
                   class="button"
                   @click="goToNextItem(structureItem, indexStructureItem, null)">

                <div class="button-label">
                  <span>{{ getNextItemName(structureItem, indexStructureItem, null) }}</span>
                </div>

                <div class="button-icon">
                  <font-awesome-icon icon="circle-chevron-right"/>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

  <transition name="fade">

    <div class="backdrop" v-if="isSidebarDisplayed && isMobileOrLaptopScreen" @click="toggleSidebar()"></div>

  </transition>

</template>

<script>

import {useStore} from "vuex";
import {computed, inject, onMounted, ref} from "vue";
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import AttachmentUpload from "@/components/journey/AttachmentUpload.vue";
import MemoryCards from "@/components/common/MemoryCards.vue";
import LinkedAssets from "@/components/journey/LinkedAssets.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import $ from 'jquery';

export default {

  name: 'JourneyContent',

  components: {
    FontAwesomeIcon,
    LinkedAssets,
    Tabs,
    Tab,
    AttachmentUpload,
    MemoryCards,
  },

  props: {
    isSidebarDisplayed: {
      type: Boolean
    },
    journeyModules: {
      type: Array,
      required: true,
    }
  },

  setup(props) {

    const isMobileOrLaptopScreen = ref(false);

    const store = useStore();

    const journeyDetails = inject('journeyDetails');

    const isSidebarDisplayed = inject('isSidebarDisplayed');

    const activeStructureItem = inject('activeStructureItem');

    const maxHeight = computed(() => store.getters["layout/getContainerMaxHeight"]);

    const handleResize = () => {

      isMobileOrLaptopScreen.value = window.innerWidth < 768;

    };

    const goToPreviousItem = async (structureItem, indexStructureItem, indexUnit = null) => {

      let previousStructureItem = {};

      // case Chapter
      if (indexUnit !== null) {

        await updateParticipantProgress(structureItem, structureItem.Units[indexUnit]);

        // first unit in chapter
        if (indexUnit === 0) {

          // if current item not the first structure item
          if (indexStructureItem !== 0) {
            previousStructureItem = journeyDetails.value.Structure[indexStructureItem - 1];

            // if previous is chapter, go to last unit
            if (previousStructureItem.Type === 'Chapter') {

              let lastUnitIndex = previousStructureItem.Units.length - 1;

              activeStructureItem.value = {
                structureItemID: previousStructureItem.ID,
                indexUnit: lastUnitIndex
              }
            }
            // if previous is event or assignment, directly to structure item
            else {
              activeStructureItem.value = {
                structureItemID: previousStructureItem.ID,
                indexUnit: null
              }
            }

          }

        }
        // a unit somewhere within the chapter, but not the first
        else {
          activeStructureItem.value = {
            structureItemID: structureItem.ID,
            indexUnit: indexUnit - 1
          }
        }

      }

      // case Event or Assignment
      else {
        if (indexStructureItem !== 0) {
          previousStructureItem = journeyDetails.value.Structure[indexStructureItem - 1];

          // if previous is chapter, go to last unit
          if (previousStructureItem.Type === 'Chapter') {

            let lastUnitIndex = previousStructureItem.Units.length - 1;

            activeStructureItem.value = {
              structureItemID: previousStructureItem.ID,
              indexUnit: lastUnitIndex
            }
          }
          // if previous is event or assignment, directly to structure item
          else {
            activeStructureItem.value = {
              structureItemID: previousStructureItem.ID,
              indexUnit: null
            }
          }

        }

        else {
          return null;
        }
      }

      $('#journey-content').animate({
        scrollTop: 0
      }, 0);

    };

    const getPreviousItemName = (structureItem, indexStructureItem, indexUnit = null) => {

      let previousStructureItem = {};

      // case Chapter
      if (indexUnit !== null) {

        // first unit in chapter
        if (indexUnit === 0) {

          // if current item not the first structure item
          if (indexStructureItem !== 0) {
            previousStructureItem = journeyDetails.value.Structure[indexStructureItem - 1];
            return previousStructureItem.Name;
          }

        }
        // a unit somewhere within the chapter, but not the first
        else {
          return structureItem.Units[indexUnit - 1].Name;
        }

      }

      // case Event or Assignment
      else {

        if (indexStructureItem !== 0) {
          previousStructureItem = journeyDetails.value.Structure[indexStructureItem - 1];
          return previousStructureItem.Name;
        }

      }

      return null;
    }

    const goToNextItem = async (structureItem, indexStructureItem, indexUnit = null) => {

      let nextStructureItem = {};
      let numberOfStructureItems = journeyDetails.value.Structure.length;

      // case chapter
      if (indexUnit !== null) {

        await updateParticipantProgress(structureItem, structureItem.Units[indexUnit]);

        let chapterNumberOfUnits = structureItem.Units.length;

        // last unit in chapter
        if (indexUnit === (chapterNumberOfUnits - 1)) {

          // if not the last structure item
          if (indexStructureItem !== (numberOfStructureItems - 1)) {

            nextStructureItem = journeyDetails.value.Structure[indexStructureItem + 1];

            // if next item not locked
            if ((nextStructureItem.hasOwnProperty('IsLocked') && !nextStructureItem.IsLocked) || !nextStructureItem.hasOwnProperty('IsLocked')) {

              // if next item is Chapter, go to first unit
              if (nextStructureItem.Type === 'Chapter') {
                activeStructureItem.value = {
                  structureItemID: nextStructureItem.ID,
                  indexUnit: 0
                }
              }

              // if next item is Event or Assignment, go directly to structure item
              else {
                activeStructureItem.value = {
                  structureItemID: nextStructureItem.ID,
                  indexUnit: null
                }
              }
            }
          }
        }
        // a unit somewhere within the chapter, but not the last
        else {
          activeStructureItem.value = {
            structureItemID: structureItem.ID,
            indexUnit: indexUnit + 1
          }
        }

      }

      // case event or assignment
      else {
        // if not the last structure item
        if (indexStructureItem !== (numberOfStructureItems - 1)) {

          nextStructureItem = journeyDetails.value.Structure[indexStructureItem + 1];

          // if next item not locked
          if ((nextStructureItem.hasOwnProperty('IsLocked') && !nextStructureItem.IsLocked) || !nextStructureItem.hasOwnProperty('IsLocked')) {

            // if next item is Chapter, go to first unit
            if (nextStructureItem.Type === 'Chapter') {
              activeStructureItem.value = {
                structureItemID: nextStructureItem.ID,
                indexUnit: 0
              }
            }

            // if next item is Event or Assignment, go directly to structure item
            else {
              activeStructureItem.value = {
                structureItemID: nextStructureItem.ID,
                indexUnit: null
              }
            }
          }
        }
      }

      $('#journey-content').animate({
        scrollTop: 0
      }, 0);
    };

    const getNextItemName = (structureItem, indexStructureItem, indexUnit = null) => {

      let nextStructureItem = {};
      let numberOfStructureItems = journeyDetails.value.Structure.length;

      // case chapter
      if (indexUnit !== null) {
        let chapterNumberOfUnits = structureItem.Units.length;

        // last unit in chapter
        if (indexUnit === (chapterNumberOfUnits - 1)) {

          // if not the last structure item
          if (indexStructureItem !== (numberOfStructureItems - 1)) {

            nextStructureItem = journeyDetails.value.Structure[indexStructureItem + 1];

            // if next item not locked
            if ((nextStructureItem.hasOwnProperty('IsLocked') && !nextStructureItem.IsLocked) || !nextStructureItem.hasOwnProperty('IsLocked')) {
              return nextStructureItem.Name;
            }
          }
        }
        // a unit somewhere within the chapter, but not the last
        else {
          return structureItem.Units[indexUnit + 1].Name;
        }

      }

      // case Event or Assignment
      else {
        // if not the last structure item
        if (indexStructureItem !== (numberOfStructureItems - 1)) {

          nextStructureItem = journeyDetails.value.Structure[indexStructureItem + 1];

          // if next item not locked
          if ((nextStructureItem.hasOwnProperty('IsLocked') && !nextStructureItem.IsLocked) || !nextStructureItem.hasOwnProperty('IsLocked')) {
            return nextStructureItem.Name;
          }
        }
      }

      return null;
    };

    const toggleSidebar = () => {
      isSidebarDisplayed.value = !isSidebarDisplayed.value;
    };

    const convertToHTML = (element) => {

      let convertedElement = '';

      switch (element.type) {

        case 'paragraph': {

          convertedElement =
              `<p style="text-align: ${element.tunes.anyTuneName.alignment}">
                ${element.data.text}
              </p>`

          break;
        }

        case 'header': {
          convertedElement =
              `<h${element.data.level} style="text-align: ${element.tunes.anyTuneName.alignment}">
                ${element.data.text}
              </h${element.data.level}>`
          break;
        }

        case 'quote': {
          convertedElement =
              `<figure class="quote ${element.tunes.textVariant}">
                   <blockquote>
                     ${element.data.text}
                   </blockquote>
                 <figcaption>
                   &mdash; <span>${element.data.caption}</span>
                 </figcaption>
               </figure>`
          break;
        }

        case 'delimiter': {
          convertedElement =
            `<div class="delimiter ${element.tunes.textVariant}">
            </div>`
          break;
        }

        case 'list': {
          convertedElement += processListItems(element.data.items, element.data.style);
          break;
        }

        case 'table': {
          convertedElement += `<table style="margin: 0 auto" class="${element.tunes.textVariant}">`;
          if (element.data.withHeadings) {
            let rowHeader = element.data.content[0];
            convertedElement += `<thead>`;
            convertedElement += '<tr>';
            rowHeader.forEach((data) => {
              convertedElement += `<th>${data}</th>`;
            });

            convertedElement += `</tr>`;
            convertedElement += `</thead>`;

            convertedElement += `<tbody>`;
            for (let i = 1; i < element.data.content.length; i++) {
              let rowBody =  element.data.content[i];
              convertedElement += '<tr>';
              rowBody.forEach((data) => {
                convertedElement += `<td>${data}</td>`;
              });
              convertedElement += '</tr>';
            }
            convertedElement += `</tbody>`;


          }
          else {
            convertedElement += `<tbody>`;
            element.data.content.forEach((row) => {
              convertedElement += '<tr>';
              row.forEach((data) => {
                convertedElement += `<td>${data}</td>`;
              });
              convertedElement += '</tr>';
            });
            convertedElement += `</tbody>`;
          }
          break;
        }

        case 'checklist': {
          convertedElement += `<div class="checklist" class="${element.tunes.textVariant}">`;

          element.data.items.forEach((item) => {
            convertedElement += `<div class="checkbox-wrapper">`;

            convertedElement += `<input type="checkbox"`;
            if (item.checked) {
              convertedElement += `checked>`;
            }
            else {
              convertedElement += `>`;
            }
            convertedElement += `<label>${item.text}</label>`;

            convertedElement += `</div>`;

          });



          convertedElement += `</div>`;
          break;
        }

        case 'embed': {
          convertedElement =
              `<div class="iframe-wrapper">` +
                `<iframe src=${element.data.embed} height=${element.data.height} width=${element.data.width} title=${element.data.caption}></iframe>` +
              `</div>`
          break;
        }

        case 'image': {

          convertedElement =
              `<img src=${element.data.file.url}>`

          break;
        }

        case 'reusableImage': {

          convertedElement =
              `<img src=${element.data.file.url}>`

          break;
        }

        default : {
          convertedElement = '';
          break;
        }

      }

      return convertedElement;

    };

    const processListItems = (items, listType) => {

      let convertedElement = '';

      if (listType === 'ordered') {
        convertedElement += '<ol class="${element.tunes.textVariant}">'
      }
      if (listType === 'unordered') {
        convertedElement += '<ul class="${element.tunes.textVariant}">'
      }


      items.forEach((item) => {
        convertedElement += `<li><span>${item.content}<span>`;
        if (item.items.length > 0) {
          convertedElement += processListItems(item.items, listType);
        }
        convertedElement += `</li>`
      })


      if (listType === 'ordered') {
        convertedElement += '</ol>'
      }
      if (listType === 'unordered') {
        convertedElement += '</ul>'
      }

      return convertedElement;
    };

    const updateParticipantProgress = async (chapter, unit) => {

      await store.dispatch('loading/setLoading', true);

      unit.Completed = true;

      if (journeyDetails.value.hasOwnProperty('ParticipantProgress')) {

        let uncompletedUnit = chapter.Units.find((unit) => unit.Completed === false);

        // all units in chapter completed
        if (uncompletedUnit === undefined) {
          let progress = journeyDetails.value.ParticipantProgress.find((progress) => progress.ChapterID === chapter.ID);
          let isChapterAlreadyCompleted = progress.Completed;

          if (!isChapterAlreadyCompleted) {

            await store.dispatch('journey/updateParticipantProgress', {
              JourneyID: journeyDetails.value.ID,
              ChapterID: chapter.ID,
            });

            let updatedData = await store.dispatch('journey/getUserJourneyProgress', journeyDetails.value.ID);

            journeyDetails.value.ParticipantProgress = updatedData.ParticipantProgress;
            journeyDetails.value.ParticipantProgressPercentage = updatedData.ParticipantProgressPercentage;
          }
        }
      }

      await store.dispatch('loading/setLoading', false);

    };

    const checkmarkClickHandler = async (chapter, indexStructureItem, unit) => {
      unit.Completed = true;
      // only mark chapter as completed if all units in chapter are completed
      if (journeyDetails.value.Structure[indexStructureItem].hasOwnProperty('Units') && journeyDetails.value.Structure[indexStructureItem].Units.every(obj => obj.Completed === true)) {
        await updateParticipantProgress(chapter, unit);
      }
    };

    const changeElementBackgroundColor = () => {
      const styleTag = document.createElement('style');

      styleTag.innerHTML = `
        .unit .block .element li:before {
          background: ${store.state.tenant.tenantinfo.primarycolor} linear-gradient(to bottom right, ${store.state.tenant.tenantinfo.primarycolor} 25%, white);
          text-shadow: ${store.state.tenant.tenantinfo.primarycolor};
        }
        .unit .block .element li:after {
          background-color: ${store.state.tenant.tenantinfo.primarycolor};
        }
       `;

      document.head.appendChild(styleTag);
    }

    const makeRoundInteger = (number) => {
      if (number !== null  && number !== undefined && number !== '') {
        return Math.round(number);
      }
      return null;
    };

    const formattedNumber = (number, nrOfDecimals) => {

      if (number !== null && number !== undefined && number !== 0 && number !== '') {

        if (nrOfDecimals > 0) {

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: nrOfDecimals,
            useGrouping: false
          });

        }
        else {

          number = makeRoundInteger(number);

          number = number.toLocaleString('en-US', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            useGrouping: false
          });
        }

        number = number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      }

      return number;

    };

    const updateParticipantAssignmentTask = async (participantAssignment, task, indexStructureItem, indexTask) => {
      let payload = {
        JourneyID: journeyDetails.value.ID,
        JourneyParticipantLinkID: journeyDetails.value.JourneyParticipantLinkID,
        AssignmentID: participantAssignment.ID,
        Task: {
          ID: task.ID,
          IsDone: task.IsDone
        },
        indexStructureItem: indexStructureItem,
        indexTask: indexTask,
      }
      await store.dispatch('journey/updateParticipantAssignment', payload);
    };

    const getFormattedDate = (date) => {
      const dateObject = new Date(date);

      const day = (dateObject.getDate()).toString().padStart(2, '0');
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
      const year = dateObject.getFullYear();
      const hours = (dateObject.getHours()).toString().padStart(2, '0');
      const minutes = (dateObject.getMinutes()).toString().padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}`
    }
    const calculateModulesPercentage = computed(() => {

      const modulesPercentage = [];

      props.journeyModules.forEach(module => {

        let completedChapters = 0;

        if (journeyDetails.value.hasOwnProperty('ParticipantProgress')) {

          module.Chapters.forEach(chapter => {
            const chapterInModule = journeyDetails.value.ParticipantProgress.find(progress => progress.ChapterID === chapter.ID);
            if (chapterInModule && chapterInModule.Completed) {
              completedChapters++;
            }
          });

          const percentage = module.Chapters.length === 0 ? 100 : (completedChapters / module.Chapters.length) * 100;

          modulesPercentage.push({
            moduleID: module.ID,
            moduleProgressPercentage: percentage.toFixed(2)
          });

        }

      });

      return modulesPercentage;

    });
    const getSkippedTabsTitles = (linkedAssets) => {

      let skippedTabsTitles = [];

      let oneUnitAttachment = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'Attachment');
      if (oneUnitAttachment === undefined) {
        skippedTabsTitles.push('Attachments');
      }

      let oneUnitMemoryCard = linkedAssets.find((linkedAsset) => linkedAsset.Type === 'MemoryCard');
      if (oneUnitMemoryCard === undefined) {
        skippedTabsTitles.push('HB Memo Cards');
      }

      return skippedTabsTitles;

    };


    onMounted(() => {

      handleResize();

      changeElementBackgroundColor();

    });

    return {
      isMobileOrLaptopScreen,
      store,
      journeyDetails,
      isSidebarDisplayed,
      maxHeight,
      activeStructureItem,
      calculateModulesPercentage,
      toggleSidebar,
      convertToHTML,
      updateParticipantProgress,
      formattedNumber,
      updateParticipantAssignmentTask,
      getSkippedTabsTitles,
      getFormattedDate,
      checkmarkClickHandler,
      goToPreviousItem,
      getPreviousItemName,
      goToNextItem,
      getNextItemName,
    }
  }

}

</script>

<style lang="scss" scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#journey-content {
  overflow: auto;
  padding: 0 15px 15px 15px;

  .journey-title {
    position: sticky;
    top: 0;
    background-color: white;
    height: 50px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;

    .menu-icon {
      display: inline-block;
      vertical-align: top;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 30px;
      color: #f0f4fa;
      font-size: 16px;
      cursor: pointer;
      transition: .3s ease;

      &.sidebar-displayed {
        position: absolute;
        z-index: 11;

        .close-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .title {
      font-size: 32px;
      color: inherit;
      flex-grow: 1;
      text-align: left;
      margin-left: 10px;
    }
  }

  .content-wrapper {
    padding: 0 30px 50px 30px;

    .content-header {
      position: sticky;
      top: 50px;
      background-color: white;
      z-index: 1;
    }

    .unit {
      margin-top: 10px;
      margin-left: 16px;
    }

    &.opened {
      padding: 0 0 50px 0;
      .unit {
        margin-left: 10px;
      }
    }

    .modules_path-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: 2px solid black;
      padding-top: 15px;
      margin: 35px 0;

      .circle-wrapper {
        position: relative;
        background-color: #ffffff;
        margin-top: -40px;
        width: 48px;
        border-radius: 50%;

        .circular-chart {
          position: relative;
          font-weight: bold;
          margin-top: -40px;
          width: 45px;
          height: 45px;
          border-radius: 50%;
        }

        .circle {
          width: 100%;
          height: 100%;

          .circle-background {
            fill: none;
            stroke-width: 3.5;
          }

          .circle-outline {
            fill: white;
            stroke-linecap: round;
            stroke: forestgreen;

            &.active-module {
              fill: #fff2b0;
            }
          }
        }

        .circle-element {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          color: black;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .structure-item {

      .chapter {
        .chapter-title {
          text-align: center;
          margin-bottom: 50px;
        }

        .unit {

          .separator {
            width: 100%;
            height: 5px;
            margin: 20px 0;
            background-color: var(--primary-color);
          }

          .existing-assets {
            margin: 10px 0;
          }
        }

        .chapter-event {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          margin-top: 20px;

          .periods_wrapper {
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            width: fit-content;
            padding: 20px;
            margin-bottom: 20px;
            border: 1px solid black;
            border-radius: 15px;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

            svg {
              width: 90px;
              height: 90px;
            }

            .vertical-separator {
              width: 1px;
              height: 100px;
              background-color: black;
            }

            .periods-details {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              flex-direction: column;
              gap: 20px;
              width: fit-content;

              .date-time_wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
              }
            }
          }

          .event-details_wrapper {
            margin-top: 20px;
          }
        }
      }

      .event {
        margin: 10px;
        .separator {
          width: 100%;
          height: 5px;
          margin: 20px 0;
          background-color: var(--primary-color);
        }

        .periods_wrapper {
          width: 100%;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          padding: 20px;
          margin-bottom: 20px;
          border: none;
          border-radius: 15px;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

          progress {
            border: none;
            width: 100%;
            height: 20px;
          }
        .progress-bar-value {
          width: 100%;
          text-align: center;
          display: block;
          margin-top: -28px;
          font-weight: bold;
          color: white;
        }
          progress::-webkit-progress-bar {
            background-color: #CDC1CC;
            border-radius: 20px;
          }
          progress::-webkit-progress-value {
            background-color: var(--primary-color);
            border-radius: 20px;
            color: black
          }

          .icons-wrapper {
            font-size: 25px;
            display: flex;
            color: var(--primary-color);
            .calendar {
              width: 50%;
              text-align: left;
            }
            .locker {
              width: 50%;
              text-align: right;
            }
          }
          .details-wrapper {
            display: flex;
            .to-from, .unlock {
              width: 50%;
              label {
                color: var(--primary-color);
                font-weight: bold;
              }
            }
            .unlock {
              text-align: right;
            }
          }
        }

        .event-details_wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .element {
            img {
              display: block;
              margin: auto;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .notes-wrapper {
        margin: 20px 0;

        .icon.add-note {
          font-size: 20px;
          justify-content: flex-start;
          cursor: pointer;
        }
      }

      .bottom-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        padding: 10px 0;

        .navigation_button_wrapper {
          display: flex;
          align-items: center;
          width: 33%;

          &.previous {
            justify-content: flex-start;
          }

          &.next {
            justify-content: flex-end;
          }

        }

        .button {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          text-align: center;
          padding: 10px;
          color: white;
          cursor: pointer;

          .button-label {
            display: flex;
            flex-direction: column;
          }

          .button-icon {
            display: flex;
            font-size: 20px;
          }

          &.journey-progress {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 33%;

            .progress-icon {
              color: white;
              font-size: 20px;
            }

            .progress-sm {
              width: 25%;
              display: flex;
              height: 1rem;
              overflow: hidden;
              font-size: .75rem;
              //background-color: #e9ecef;
              border-radius: 0.25rem;
              border: 1px solid white;
              background-color: unset;
            }

            .progress-value {
              text-transform: uppercase;
              color: white;
            }
          }

        }

      }

    }
  }
}

.backdrop {
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 50px;
  left: 25%;
  z-index: 10;
  background-color: rgba(39, 48, 68, .5);
  cursor: pointer;
  @media only screen and (max-width: 425px) {
    top: 102px
  }
}

@media only screen and (max-width: 768px) {
  #journey-content {
    .journey-title {
      display: unset;
      .title {
        margin-left: 25px;
      }
    }

    .content-wrapper {
      padding: 10px;

      .structure-item {
        .bottom-buttons {
          .button {
            padding: 0 5px;

            .previous-unit, .next-unit, .next-chapter {
              gap: 0;
            }
          }
        }
      }
    }
  }

  .backdrop {
    left: 60%;
  }
}

</style>

<style lang="scss">

#journey-content {

  .chapter {

    .unit {
      .block {
        .element {
          margin: 20px 0;

          img {
            width: 100%;
          }

          ol {
            list-style: none;
            width: 600px;
            max-width: 90%;

            li {
              display: block;
              clear: both;
              counter-increment: list;
              position: relative;
              margin: 0 0 10px 0;

              &::before {
                content: counter(list);
                width: 30px;
                height: 30px;
                margin-right: 20px;
                color: #fdfdfd;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                shape-outside: ellipse();
                z-index: 1;
              }

              &::after {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                z-index: -1;
                border-top-left-radius: 1px;
              }
            }
          }

          .checklist {
            background-color: #eee;
            width: 100px;
            height: 180px;
            border-radius: 10px;
            position: relative;
            box-shadow: 0 10px 30px rgba(65, 72, 86, 0.05);
            padding: 30px 85px;
            display: grid;
            align-items: center;
            justify-content: center;

            .checkbox-wrapper {
              display: flex;
              align-items: center;

              input {
                -webkit-appearance: none;
                -moz-appearance: none;
                position: relative;
                height: 15px;
                width: 15px;
                cursor: pointer;
                background: white;
                display: grid;
                align-items: center;
                margin: 0 20px 0 0;

                &::before,
                &::after {
                  content: "";
                  position: absolute;
                  height: 2px;
                  top: auto;
                  background: green;
                  border-radius: 2px;
                }

                &::before {
                  width: 0;
                  right: 60%;
                  transform-origin: right bottom;
                }

                &::after {
                  width: 0;
                  left: 40%;
                  transform-origin: left bottom;
                }

                &:checked {
                  background-color: white;
                }

                &:checked::before {
                  animation: check-01 0.4s ease forwards;
                }

                &:checked::after {
                  animation: check-02 0.4s ease forwards;
                }

                &:checked + label,
                &:checked + label mark {
                  color: grey;
                  animation: move 0.3s ease 0.1s forwards;
                }

                &:checked + label {
                  text-decoration: line-through;
                }

                @keyframes move {
                  50% {
                    padding-left: 8px;
                    padding-right: 0;
                  }

                  100% {
                    padding-right: 4px;
                  }
                }

                @keyframes slice {
                  60% {
                    width: 100%;
                    left: 40px;
                  }

                  100% {
                    width: 100%;
                    left: -20px;
                    padding-left: 0;
                  }
                }

                @keyframes check-01 {
                  0% {
                    width: 4px;
                    top: auto;
                    transform: rotate(0);
                  }

                  50% {
                    width: 0;
                    top: auto;
                    transform: rotate(0);
                  }

                  51% {
                    width: 0;
                    top: 8px;
                    transform: rotate(45deg);
                  }

                  100% {
                    width: 5px;
                    top: 8px;
                    transform: rotate(45deg);
                  }
                }

                @keyframes check-02 {
                  0% {
                    width: 4px;
                    top: auto;
                    transform: rotate(0);
                  }

                  50% {
                    width: 0;
                    top: auto;
                    transform: rotate(0);
                  }

                  51% {
                    width: 0;
                    top: 8px;
                    transform: rotate(-45deg);
                  }

                  100% {
                    width: 10px;
                    top: 8px;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
          }
        }
      }

      .element {
        .call-out {
          border: 1px solid rgb(255, 135, 135);
          padding: 8px 16px;
          border-radius: 6px;
          box-shadow: 0 0 0 5px rgba(255, 120, 84, 0.16);
        }

        .citation {
          font-style: italic;
        }

        .details {
          font-size: 0.84em;
        }

        .quote {
          position: relative;
          width: 100%;
          margin: auto;
          padding: 1rem;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

          &::before,
          &::after {
            position: absolute;
            z-index: -1;
            display: block;
            width: 3rem;
            height: 4rem;
            content: "";
            font-size: 90px;
            font-family: Georgia, Times, Garamond, serif;
            color: var(--primary-color)
          }

          &::before {
            top: -2.5rem;
            left: 0.5rem;
            content: open-quote;
          }

          &::after {
            bottom: -1rem;
            right: 0;
            content: close-quote;
          }

          &::before,
          &::after,
          & blockquote {
            text-shadow: -2px 2px #fff, -1.5px 1.5px #fff, -1px 1px #fff, -0.5px 0.5px #fff;
          }

          & figcaption,
          & blockquote {
            margin: 20px;
          }

          & figcaption {
            text-align: right;
          }
        }

        .delimiter {
          text-align: center;

          &:before {
            display: inline-block;
            content: "***";
            font-size: 30px;
            line-height: 65px;
            height: 30px;
            letter-spacing: 0.2em;
          }
        }
      }
    }

    .chapter-event {
      .event-details_wrapper {
        .element {
          margin: 20px 0;

          img {
            width: 100%;
          }

          ol {
            list-style: none;
            width: 600px;
            max-width: 90%;

            li {
              display: block;
              clear: both;
              counter-increment: list;
              position: relative;
              margin: 0 0 10px 0;

              &::before {
                content: counter(list);
                width: 30px;
                height: 30px;
                margin-right: 20px;
                color: #fdfdfd;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                shape-outside: ellipse();
                z-index: 1;
              }

              &::after {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                z-index: -1;
                border-top-left-radius: 1px;
              }
            }
          }

          .checklist {
            background-color: #eee;
            width: 100px;
            height: 180px;
            border-radius: 10px;
            position: relative;
            box-shadow: 0 10px 30px rgba(65, 72, 86, 0.05);
            padding: 30px 85px;
            display: grid;
            align-items: center;
            justify-content: center;

            .checkbox-wrapper {
              display: flex;
              align-items: center;

              input {
                -webkit-appearance: none;
                -moz-appearance: none;
                position: relative;
                height: 15px;
                width: 15px;
                cursor: pointer;
                background: white;
                display: grid;
                align-items: center;
                margin: 0 20px 0 0;

                &::before,
                &::after {
                  content: "";
                  position: absolute;
                  height: 2px;
                  top: auto;
                  background: green;
                  border-radius: 2px;
                }

                &::before {
                  width: 0;
                  right: 60%;
                  transform-origin: right bottom;
                }

                &::after {
                  width: 0;
                  left: 40%;
                  transform-origin: left bottom;
                }

                &:checked {
                  background-color: white;
                }

                &:checked::before {
                  animation: check-01 0.4s ease forwards;
                }

                &:checked::after {
                  animation: check-02 0.4s ease forwards;
                }

                &:checked + label,
                &:checked + label mark {
                  color: grey;
                  animation: move 0.3s ease 0.1s forwards;
                }

                &:checked + label {
                  text-decoration: line-through;
                }

                @keyframes move {
                  50% {
                    padding-left: 8px;
                    padding-right: 0;
                  }

                  100% {
                    padding-right: 4px;
                  }
                }

                @keyframes slice {
                  60% {
                    width: 100%;
                    left: 40px;
                  }

                  100% {
                    width: 100%;
                    left: -20px;
                    padding-left: 0;
                  }
                }

                @keyframes check-01 {
                  0% {
                    width: 4px;
                    top: auto;
                    transform: rotate(0);
                  }

                  50% {
                    width: 0;
                    top: auto;
                    transform: rotate(0);
                  }

                  51% {
                    width: 0;
                    top: 8px;
                    transform: rotate(45deg);
                  }

                  100% {
                    width: 5px;
                    top: 8px;
                    transform: rotate(45deg);
                  }
                }

                @keyframes check-02 {
                  0% {
                    width: 4px;
                    top: auto;
                    transform: rotate(0);
                  }

                  50% {
                    width: 0;
                    top: auto;
                    transform: rotate(0);
                  }

                  51% {
                    width: 0;
                    top: 8px;
                    transform: rotate(-45deg);
                  }

                  100% {
                    width: 10px;
                    top: 8px;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
          }
        }
      }

      .element {
        .call-out {
          border: 1px solid rgb(255, 135, 135);
          padding: 8px 16px;
          border-radius: 6px;
          box-shadow: 0 0 0 5px rgba(255, 120, 84, 0.16);
        }

        .citation {
          font-style: italic;
        }

        .details {
          font-size: 0.84em;
        }

        .quote {
          position: relative;
          width: 100%;
          margin: auto;
          padding: 1rem;
          background-color: transparent;
          background-position: top left, 0.2em 0.2em;
          background-repeat: no-repeat;
          filter: drop-shadow(0 0 15px #0005);

          &::before,
          &::after {
            position: absolute;
            z-index: -1;
            display: block;
            width: 3rem;
            height: 4rem;
            content: "";
            font-size: 90px;
            font-family: Georgia, Times, Garamond, serif;
          }

          &::before {
            top: -2.5rem;
            left: 0.5rem;
            content: open-quote;
          }

          &::after {
            bottom: -1rem;
            right: 0;
            content: close-quote;
          }

          &::before,
          &::after,
          & blockquote {
            text-shadow: -2px 2px #fff, -1.5px 1.5px #fff, -1px 1px #fff, -0.5px 0.5px #fff;
          }

          & figcaption,
          & blockquote {
            margin: 20px;
          }

          & figcaption {
            text-align: right;
          }
        }

        .delimiter {
          text-align: center;

          &:before {
            display: inline-block;
            content: "***";
            font-size: 30px;
            line-height: 65px;
            height: 30px;
            letter-spacing: 0.2em;
          }
        }
      }
    }
  }

  .event {
    .event-details_wrapper {
      .element {
        img {
          display: block;
          margin: auto;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .assignment {
    margin: 10px;

    .separator {
      width: 100%;
      height: 5px;
      margin: 20px 0;
      background-color: var(--primary-color);;
    }

    .tasks {
      margin: 30px 0;

      .task_wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 20px 0;

        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          display: grid;
          align-items: center;
          height: 20px;
          width: 20px;
          cursor: pointer;
          background: white;
          border: 1px solid #ccc;
          border-radius: 3px;

          &::before,
          &::after {
            content: "";
            position: absolute;
            background: transparent;
            border-bottom: 2px solid green;
            display: none;
          }

          &:checked::before,
          &:checked::after {
            display: block;
          }

          &::before {
            width: 25%;
            top: 45%;
            left: 10%;
            transform: rotate(-45deg);
          }

          &::after {
            width: 50%;
            top: 80%;
            left: 25%;
            transform: rotate(45deg);
          }

          &:checked {
            background-color: white;
          }

          &:checked::before {
            animation: check-01 0.4s ease forwards;
          }

          &:checked::after {
            animation: check-02 0.4s ease forwards;
          }

          &:checked + .element-wrapper .element,
          &:checked + .element-wrapper .element {
            color: grey;
            animation: move 0.3s ease 0.1s forwards;
          }

          &:checked + .element-wrapper .element {
            text-decoration: line-through;
          }

          @keyframes move {
            50% {
              padding-left: 8px;
              padding-right: 0;
            }

            100% {
              padding-right: 4px;
            }
          }

          @keyframes slice {
            60% {
              width: 100%;
              left: 40px;
            }

            100% {
              width: 100%;
              left: -20px;
              padding-left: 0;
            }
          }

          @keyframes check-01 {
            0% {
              width: 4px;
              top: auto;
              transform: rotate(0);
            }

            50% {
              width: 0;
              top: auto;
              transform: rotate(0);
            }

            51% {
              width: 0;
              top: 8px;
              transform: rotate(45deg);
            }

            100% {
              width: 5px;
              top: 8px;
              transform: rotate(45deg);
            }
          }

          @keyframes check-02 {
            0% {
              width: 4px;
              top: auto;
              transform: rotate(0);
            }

            50% {
              width: 0;
              top: auto;
              transform: rotate(0);
            }

            51% {
              width: 0;
              top: 8px;
              transform: rotate(-45deg);
            }

            100% {
              width: 10px;
              top: 8px;
              transform: rotate(-45deg);
            }
          }
        }

        .element-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 15px;
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 10px;

          &.is-done {
            background-color: #eee;

            .element {
              img {
                opacity: 0.5;
              }
            }
          }

          .element {
            p {
              margin: 0;
            }

            .checklist {
              .checkbox-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
              }
            }

            img {
              display: block;
              margin: auto;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

</style>
