<template>
  <CentralLayout>
    <template v-slot:content>

      <div v-if="hasContentLoaded" id="account-page">

        <small class="text-capitalize">Overview</small>
        <h2>My Account</h2>

        <Tabs :preselectedTab="preselectedTab">
          <Tab title="User Management">
            <UserManagement/>
          </Tab>
          <Tab title="Journey Management">
            <JourneyManagementMyAccount/>
          </Tab>
          <Tab title="Billing">
            <Billing/>
          </Tab>
        </Tabs>

      </div>

    </template>
  </CentralLayout>
</template>

<script>
import {onMounted, computed, ref} from 'vue';
import {useStore} from 'vuex';

import CentralLayout from '@/layouts/CentralLayout';
import MainNav from '@/components/MainNav';
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import Billing from '@/components/account/Billing';
import UserManagement from "@/components/account/UserManagement.vue";
import MemoryCards from "@/components/settings/MemoryCards.vue";
import JourneyManagementMyAccount from "@/components/account/JourneyManagementMyAccount";

export default {
  components: {
    JourneyManagementMyAccount,
    MemoryCards,
    CentralLayout,
    MainNav,
    Tabs,
    Tab,
    UserManagement,
    Billing,
  },
  setup() {

    const store = useStore();

    const hasContentLoaded = ref(false);

    const isBillingActive = computed(() => store.getters["internalCurrency/getIsReturnToBilling"]);

    const preselectedTab = ref('');

    onMounted(async () => {
      await store.dispatch('loading/setLoading', true);

      if (isBillingActive.value) {
        preselectedTab.value = 'Billing';
      }

      hasContentLoaded.value = true;

      await store.dispatch('internalCurrency/setIsReturnToBilling', false);

      await store.dispatch('loading/setLoading', false);
    });
    return {
      hasContentLoaded,
      preselectedTab,
    }
  }
}
</script>

<style lang="scss" scoped>

#account-page {
  padding: 20px;
}

</style>
