import { ref, onMounted, onUnmounted, watchEffect } from 'vue';

export function useDevice() {
  const isMobile = ref(false);
  const os = ref('');
  const isPwaInstalled = ref(false);

  function updateDeviceInfo() {
    isMobile.value = isMobileDevice();
    os.value = getOS();
    isPwaInstalled.value = checkPwaInstallation();
  }

  onMounted(() => {
    updateDeviceInfo();
    window.addEventListener('resize', updateDeviceInfo);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateDeviceInfo);
  });

  // Ensure values are updated on initialization
  watchEffect(() => {
    updateDeviceInfo();
  });

  return {
    isMobile,
    os,
    isPwaInstalled,
  };
}

function isMobileDevice() {
  const ua = navigator.userAgent;
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const isSmallScreen = window.matchMedia("(max-width: 600px)").matches;
  const isMobileUA = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua);

  return isTouchDevice && isSmallScreen && isMobileUA;
}

function getOS() {
  const ua = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(ua)) {
    return 'Windows Phone';
  }
  if (/android/i.test(ua)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return 'iOS';
  }
  return 'Other';
}

function checkPwaInstallation() {
  return window.matchMedia('(display-mode: standalone)').matches;
}
