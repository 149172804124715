<template>
  <div class="row justify-content-between align-items-center mb-5">
    <div class="col-12 col-md-12 col-xl-12">
      <!-- Heading -->
      <h2 class="mb-2">
        Change your password
      </h2>

      <!-- Text -->
      <p class="text-muted mb-xl-0">
        We will email you a confirmation when changing your password, so please
        expect that email after submitting.
      </p>
    </div>
  </div>
  <!-- / .row -->

  <div class="row">
    <div class="col-12 col-md-6 order-md-2">
      <!-- Card -->
      <div class="card bg-light border ms-md-4">
        <div class="card-body">
          <!-- Text -->
          <p class="mb-2">
            Password requirements
          </p>

          <!-- Text -->
          <p class="small text-muted mb-2">
            To create a new password, you have to meet all of the following
            requirements:
          </p>

          <!-- List group -->
          <ul class="small text-muted ps-4 mb-0">
            <li :class="minChar ? 'green' : 'red'">
              Minimum 8 character
            </li>
            <li :class="specialChar ? 'green' : 'red'">
              At least one special character
            </li>
            <li :class="oneNum ? 'green' : 'red'">
              At least one number
            </li>

            <li :class="uppercaseLetter ? 'green' : 'red'">
              At least one uppercase letter
            </li>
            <li :class="lowercaseLetter ? 'green' : 'red'">
              one lowercase letter
            </li>
            <li :class="noPrevious ? 'green' : 'red'">
              Can’t be the same as a previous password
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <!-- Form -->
      <form @submit.prevent="onSubmit">
        <!-- Password -->
        <div class="form-group">
          <!-- Label -->
          <label class="form-label" for="old_password">
            Current password
          </label>

          <input
            id="old_password"
            name="old_password"
            type="password"
            v-model="old_password"
            class="form-control"
            :class="[old_passwordError ? 'is-invalid' : '']"
          />
          <div class="invalid-feedback">{{ old_passwordError }}</div>
        </div>

        <!-- New password -->
        <div class="form-group">
          <!-- Label -->
          <label for="password" class="form-label">
            New password
          </label>

          <!-- Input -->

          <input
            id="password"
            name="password"
            type="password"
            v-model="password"
            class="form-control"
            :class="[passwordError ? 'is-invalid' : '']"
          />
          <div class="invalid-feedback">{{ passwordError }}</div>
        </div>

        <!-- Confirm new password -->
        <div class="form-group">
          <!-- Label -->
          <label for="passwordConfirmation" class="form-label">
            Confirm new password
          </label>

          <!-- Input -->

          <input
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            v-model="passwordConfirmation"
            class="form-control"
            :class="[passwordConfirmationError ? 'is-invalid' : '']"
          />
          <div class="invalid-feedback">{{ passwordConfirmationError }}</div>
        </div>

        <!-- Submit -->
        <button class="btn mt-5 w-100 btn-primary lift" type="submit">
          Update password
        </button>
      </form>
    </div>
  </div>
  <!-- / .row -->
  <div
    v-if="pwd_status"
    class="alert my-5"
    :class="pwdchangeerror ? 'alert-danger' : 'alert-info'"
  >
    <i class="fe fe-info me-1"></i> {{ pwdchangeerror_description }}
  </div>
  <!-- Divider -->
  <div v-if="devicelist">
    <hr class="my-5" />

    <div class="row justify-content-between align-items-center mb-5">
      <div class="col-12 col-md-12 col-xl-12">
        <h2 class="mb-2">
          Device Login History
        </h2>

        <p class="text-muted">
          If you see a device here that you believe wasn’t you, please contact
          our account <a href="#!">fraud department</a> immediately.
        </p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="list-group list-group-flush my-n6">
          <div
            class="list-group-item"
            v-for="item in devicelist"
            :key="item.osname"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <i class="fe fe-smartphone h1"></i>
              </div>
              <div class="col ms-n5">
                <h4 class="mb-1">
                  {{ item.devicemodel }} {{ item.osname }}
                  <span class="fw-normal"
                    >{{ item.browser }} {{ item.browserversion }}</span
                  >
                </h4>

                <small class="text-muted small_device">
                  Last IP ·
                  {{ item.lastip }}

                  {{ item.osname }} · {{ item.osversion }} </small
                ><br />
                <small class="text-muted small_device">
                  {{ item.devicelastauthenticateddate }}
                </small>
              </div>
              <div class="col-auto"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { Auth } from 'aws-amplify'
import { useForm, useField } from 'vee-validate'

export default {
  setup() {
    const store = useStore()
    const minChar = ref(false)
    const specialChar = ref(false)
    const oneNum = ref(false)
    const uppercaseLetter = ref(false)
    const lowercaseLetter = ref(false)
    const noPrevious = ref(false)
    const pwd_status = ref(false)
    const pwdchangeerror = ref(false)
    const pwdchangeerror_description = ref('')

    const devicelist = reactive(store.state.auth.devicelist)

    const profileSchema = {
      old_password(value) {
        if (!value) {
          return 'your current password is required'
        }
        var r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/
        if (value && value.length < 8 && !r.test(value)) {
          return `Should be at least 8 characters`
        }

        return true
      },
      password(value) {
        minChar.value = false
        specialChar.value = false
        oneNum.value = false
        uppercaseLetter.value = false
        lowercaseLetter.value = false
        noPrevious.value = false
        if (!value) {
          return 'your new password is required'
        }
        if (value.length >= 8) minChar.value = true

        var r_special = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
        if (r_special.test(value)) specialChar.value = true

        var r_num = /[0-9]+/
        if (r_num.test(value)) oneNum.value = true

        var r_uppercase = /[A-Z]+/
        if (r_uppercase.test(value)) uppercaseLetter.value = true

        var r_lowercase = /[a-z]+/
        if (r_lowercase.test(value)) lowercaseLetter.value = true

        if (value !== old_password.value) noPrevious.value = true

        var r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/
        if (value && !r.test(value)) {
          return `Should be at least 8 characters`
        }
        if (value && value == old_password.value) {
          return `Can’t be the same as your previous password`
        }

        return true
      },
      passwordConfirmation(value) {
        if (!value) {
          return 'your new password is required'
        }

        var r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/

        if (value && !r.test(value)) {
          return `Should be at least 8 characters`
        }
        if (value != password.value) {
          return `password doesn't match`
        }

        return true
      }
    }
    const { handleSubmit } = useForm({
      validationSchema: profileSchema
    })

    const { value: old_password, errorMessage: old_passwordError } = useField(
      'old_password'
    )
    const { value: password, errorMessage: passwordError } = useField(
      'password'
    )
    const {
      value: passwordConfirmation,
      errorMessage: passwordConfirmationError
    } = useField('passwordConfirmation')
    const onSubmit = handleSubmit((values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2))
      // console.log('values')
      // console.log(values)
      // //NULL values aren't allowed
      // let check_values = {}
      // for (var prop in values) {
      //   if (values[prop] == undefined) {
      //     check_values[prop] = ''
      //   } else {
      //     check_values[prop] = values[prop]
      //   }
      // }
      store.dispatch('loading/setLoading', true)
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, values.old_password, values.password)
        })
        .then(data => {
          //console.log(data)
          pwd_status.value = true
          pwdchangeerror.value = false
          pwdchangeerror_description.value = 'Password sucessfully change'

          // setValues({
          //   old_password: '',
          //   password: '',
          //   passwordConfirmation: ''
          // })
          resetForm()
          store.dispatch('loading/setLoading', false)
        })
        .catch(err => {
          //console.log(err)
          pwd_status.value = true
          pwdchangeerror.value = true
          if (err.code == 'NotAuthorizedException')
            pwdchangeerror_description.value =
              'Your current password is wrong. please use the password reset option if you have forgotten your password'
          else pwdchangeerror_description.value = err.message

          // setValues({
          //   old_password: '',
          //   password: '',
          //   passwordConfirmation: ''
          // })
          resetForm()
          store.dispatch('loading/setLoading', false)
        })
      // //console.log(check_values)
      // store.dispatch('loading/setLoading', true)
      // //alert(JSON.stringify(values, null, 2))

      // // dispatch with a payload
      // store.dispatch('auth/setUserDetails', check_values)
    })

    return {
      store,
      old_password,
      old_passwordError,
      password,
      passwordError,
      passwordConfirmation,
      passwordConfirmationError,
      onSubmit,

      minChar,
      specialChar,
      oneNum,
      uppercaseLetter,
      lowercaseLetter,
      noPrevious,
      pwdchangeerror,
      pwdchangeerror_description,
      pwd_status,

      devicelist
    }
  }
}
</script>

<style scoped>
.green {
  color: green;
}
.red {
  color: red;
}
.small_device {
  font-size: 0.5em !important;
}
</style>
